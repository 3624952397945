<div class="mmc-modal-wrapper edit-attendee-modal">
    <div class="mmc_flex--container header-primary  p-4 edit-header">
      <div class="mmc_flex__item mmc_flex__item--vcenter edit-header-title">
        <span class="heading" style="color: #ffffff;">{{"ATTENDEEINFO.Details of the" | optimotranslate}} {{"ATTENDEEINFO."+attendeeType | optimotranslate}}</span>
      </div>
      <div class="close-btn" style="align-items:center;display:flex;margin-right: -8px;">
        <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;"(click)="dialogRef.close()">close</mat-icon>
      </div>
    </div>
    <div class="mmc-modal-body  px-4 py-0 pt-3 " id="no-scroll-division" style="max-height:300px;">
      <div class="w-100
        mmc_flex--container
        space-between pb-2">
        <div class="mmc_flex__item mmc_flex__item--vcenter" bis_skin_checked="1">
          <h5 class="opt-fontweight__bolder title px-0">{{"ATTENDEEINFO."+attendeeType | optimotranslate }}</h5>
        </div>
        <div class=" mmc_flex__item mmc_flex__item--vcenter
          mmc_just-content-end" *ngIf="displayChangeToHostBtn && canEdit">
          <button mat-button class="mmc-btn-secondary mr-2" (click)="changeToHost()">
         {{"ATTENDEEINFO.Change in to host form" | optimotranslate}}
          </button>
        </div>
      </div>
      <form class="form-container" [formGroup]="attendeeForm">
        <div class="container-fluid">
          <div class="mb-3 row pt-3">
            <label for="previousattentee" class="col-sm-3 col-form-label">{{"ATTENDEEINFO.Previous Attendee" | optimotranslate}}</label>
            <div class="col-sm-9" style="padding-right:10px;">
              <div class="pw-search-cage">
                  <mat-form-field appearance="outline" style="min-width: 100%;" *ngIf="PanelState">
                    <input
                      #addressInputField
                      (ngModelChange)="previousattendeengmodelchange($event)"
                      KeyboardInteraction
                      [ngModel]="previousattendee.id"
                      [formControl]="selectedpreviousattendee"
                      [matAutocomplete]="auto"
                      [value]="previousAttendeeSearch"
                      placeholder="{{'ATTENDEEINFO.Search Previous Attendee' | optimotranslate}}"
                      matInput/>
                    <mat-autocomplete #auto="matAutocomplete" class="previousAttendee"   (optionSelected)="attendeeNameSelected($event)">
                        <mat-option *ngFor="let option of filteredAttendeeOptions  | async" [value]="option.firstName">
                          {{ option.firstName }}
                        </mat-option>
                    </mat-autocomplete>
                    <span class="material-icons" matSuffix > search </span>
                  </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" *ngFor="let field of attendeeCaptureProfile.attendeeCaptureProfileFields">
          <div class="mb-3 row">
            <label [attr.for]="field.internalName" class="col-sm-3 col-form-label" >{{"ATTENDEEINFO."+field.label | optimotranslate}} <span style="color:red"
                *ngIf="field.mandatory">*</span></label>
            <ng-container [ngSwitch]="field.fieldType">
              <div class="col-sm-9" style="padding-right:10px;">
                <ng-container *ngSwitchCase="'TextBox'">
                    <input class=" form-control" [ngClass]="addErrorValidation(field.internalName)"
                      [formControlName]="field.internalName" type="text" [placeholder]="('ATTENDEEINFO.'+ field.displayName)| optimotranslate"
                      [id]="field.internalName">
                </ng-container>
                <ng-container *ngSwitchCase="'ComboBox'">
                  <select class="form-control" [ngClass]="addErrorValidation(field.internalName)"
                    [formControlName]="field.internalName" [placeholder]="('ATTENDEEINFO.'+ field.displayName)| optimotranslate">->
                    <option value="">{{"ATTENDEEINFO."+field.displayName | optimotranslate}}</option>
                    <option [value]="o.id" *ngFor="let o of field.selectionOptions">{{"ATTENDEEINFO."+o.name | optimotranslate}} </option>
                  </select>
                </ng-container>
                <ng-container *ngSwitchCase="'ucCommunicationInfo'">
                  <input class=" form-control" [ngClass]="addErrorValidation(field.internalName)" type="text"
                    [placeholder]="('ATTENDEEINFO.'+ field.displayName)| optimotranslate" [id]="field.internalName" [formControlName]="field.internalName">
                </ng-container>
                <ng-container *ngSwitchCase="'DateTimePicker'">
                  <input class=" form-control" [ngClass]="addErrorValidation(field.internalName)" type="datetime"
                    [placeholder]="('ATTENDEEINFO.'+ field.displayName)| optimotranslate" [id]="field.internalName" [formControlName]="field.internalName">
                </ng-container>
                <ng-container *ngSwitchCase="'TextArea'">
                  <textarea class="form-control" [ngClass]="addErrorValidation(field.internalName)" type="text"
                    [placeholder]="('ATTENDEEINFO.'+ field.displayName) | optimotranslate" [id]="field.internalName"
                    [formControlName]="field.internalName"></textarea>
                </ng-container>
                <div *ngIf="formControllerError(field.internalName) && canShowErrorValidation" class="alert-m-container">
                  <span
                    *ngIf="formControllerError(field.internalName) && formControllerError(field.internalName).required">
                    {{"ATTENDEEINFO."+ field.label | optimotranslate}} {{"ATTENDEEINFO.is required" | optimotranslate}}
                  </span>
                  <div
                    *ngIf="formControllerError(field.internalName) && !formControllerError(field.internalName).required && formControllerError(field.internalName).whitespace">
                    {{"ATTENDEEINFO.Please enter valid data" | optimotranslate}}</div>
                  <span *ngIf="formControllerError(field.internalName) && formControllerError(field.internalName).email">
                    {{"ATTENDEEINFO.Invalid mail" | optimotranslate}}
                  </span>
                  <span *ngIf="formControllerError(field.internalName) && formControllerError(field.internalName).pattern
                  && formControllerError(field.internalName).pattern.requiredPattern == mobileValidationPattern">
                    {{"ATTENDEEINFO.Invalid mobile" | optimotranslate}}
                  </span>
                  <span
                    *ngIf="formControllerError(field.internalName) && !!formControllerError(field.internalName)?.maxlength">
                    {{"ATTENDEEINFO."+ field.displayName| optimotranslate}} {{"ATTENDEEINFO.can be max" | optimotranslate}} {{ formControllerError(field.internalName).maxlength.requiredLength }} {{"ATTENDEEINFO.characters long." | optimotranslate}}
                  </span>
                  <span
                    *ngIf="formControllerError(field.internalName) && !!formControllerError(field.internalName)?.min">
                    {{"ATTENDEEINFO.The number of guests cannot be less than 1" | optimotranslate}}
                  </span>
                  <span
                    *ngIf="formControllerError(field.internalName) && !!formControllerError(field.internalName)?.max">
                    {{"ATTENDEEINFO.The number of guests cannot be greater than the remaining number of guests" | optimotranslate}}({{ formControllerError(field.internalName).max.max }})
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
    <div class="mmc-modal-footer p-4">
      <div class="mmc_flex--container mmc_just-content-end att-footer-wrapper">
        <div class="row  mmc_flex__item--pcenter">
          <div class="col-12 col-md-6 alert-m-container">
            <span *ngIf="!this.attendeeForm.valid && isEditHost" class="pb-2">{{"ATTENDEEINFO.The required fields have not been completely filled." | optimotranslate}}</span>
          </div>
          <div class="col-12 col-md-6 att-footer-btn">
            <button mat-button class="mmc-btn-primary mr-2" type="reset" (click)="dialogRef.close(response)">
              {{"ATTENDEEINFO.Cancel" | optimotranslate}}
            </button>
            <button id="submitBtn" (click)="onSubmit()" *ngIf="canEdit" mat-button class="mmc-btn-secondary" style="margin-right:unset;">
              {{"ATTENDEEINFO.Save" | optimotranslate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  