export class PublicBookingSettings {
  repeatPolicy: RepeatPolicy;
  lateRegistrationChargePolicy: LateRegistrationChargePolicy;
  deliveryMethodSetting: DeliveryMethodSetting;
  enableCapturingAddressAtThePointOfMakingPayment: boolean;
  regionalSetting: RegionalSetting;
  publicBookingType: number;
  publicBookingCBType: number;
  configurationTypes: ConfigurationType;
  defaultClient: string;
  defaultBookingFlowOptionId: number;
  availabilityConfiguration: AvailabilityConfiguration;
  physicalTicketBookingCancellationMessage: string
  editBookingButtonConfiguration: EditBookingButtonConfiguration;
  enableFacilityWall : boolean;
  priceBandFilterConfiguration : PriceBandFilterConfiguration;
  checkoutPageConfiguration: CheckoutPageConfiguration;
  paymentStatusPageConfiguration: PaymentStatusPageConfiguration;
  dateAndSessionPageConfiguration: DateAndSessionPageConfiguration;
  upsellPageConfiguration: UpsellPageConfiguration;
  timeShowingConfiguration: TimeShowingConfiguration;
  navigationConfirmationMessages : NavigationConfirmationMessages;
  enableNavigationConfirmationMessageNewBooking : boolean;
  enableNavigationConfirmationMessageEditBooking :boolean;
  enableUpsell : boolean;
  paginationSetting: PaginationSetting;
  socialMediaLogin: boolean;
  selectedSeatIconType : number;
  showHideButton : boolean;
  showClientActionsButtons : boolean;
  requestChangeContactNumber: string;
  venueMapFinalZoomLevelConfiguration: boolean;
  phrases: Phrases;
  toasterMessages: ToasterMessages;
  openWordAsPDF: boolean;
  enableNewDesignForLoginPage: boolean;
  enableNewDesignForBookingCartPage: boolean;
  enableNewDesignForPackageListPage: boolean;
  enableNewDesignForMyBookingPage: boolean;
  enableNewDesignForTransactionStatus: boolean;
  enableNewDesignForCheckoutPage:  boolean;
  enableBackgroundImage: boolean;
  navigateToPaymentSuccessfulPageAfterPayment: boolean;
  continueShoppingFromTransactionPage: string;
  searchOptionFromHomePage: number;
  enableAvailabilityInBookingCart: boolean;
}

export class AvailabilityConfiguration {
  availabilityIndicatorOption: AVAILABILITY_INDICATOR_OPTION
  showExactAvailabilityNumberFrom: number
  showExactAvailabilityOfSoldOutPackage: boolean
  availabilityThresholds: AvailabilityThresholds[]
}

export enum AVAILABILITY_INDICATOR_OPTION {
  EXACT_AVAILABILITY = "EXACT_AVAILABILITY",
  AVAILABILITY_LABELS = "AVAILABILITY_LABELS",
}

export enum TIME_INDICATOR_OPTION {
  STARTTIME_ONLY = 1,
  STARTTIME_ENDTIME = 2,
}

export class AvailabilityThresholds {
    availabilityFrom: number;
    availabilityTo: number;
    thresholdColor: string;
    thresholdTerm: string;
}

export class EditBookingButtonConfiguration {
  bookingListButton: ButtonProperty
  addNewBookingButton: ButtonProperty
  editBookingButton: ButtonProperty
  deleteBookingButton: ButtonProperty
  continueShoppingEditBooking: ButtonProperty
  changeYourSeatsEditBooking: ButtonProperty
  requestChangeEditBooking: ButtonProperty
  continueShoppingNewBooking: ButtonProperty
  editDeliveryEmailEditBooking: ButtonProperty
  editDeliveryEmailNewBooking: ButtonProperty
  changeYourSeatsNewBooking: ButtonProperty
  requestChangeNewBooking: ButtonProperty
  redeemArea: ButtonProperty
  specialNoteAreaNewBooking: ButtonProperty
  specialNoteAreaEditBooking: ButtonProperty
  questionnaire: ButtonProperty
  hostGuest: ButtonProperty
  addOns:ButtonProperty
  commonAddOns:ButtonProperty
  eventBanner:ButtonProperty
  downLoadTicket : ButtonProperty;
}

export class PriceBandFilterConfiguration {
    defaultFilterOptions: ButtonProperty;
    priceSlider: ButtonProperty;
    packageType: ButtonProperty;
    priceBand: ButtonProperty;
    packageCategory: ButtonProperty;
}

export class CheckoutPageConfiguration {
    backgroundImage: ButtonProperty;
    privacyAndTerms: ButtonProperty;
    paymentSessionTimer: ButtonProperty;
    backButton: ButtonProperty;
    checkoutStep: ButtonProperty;
    checkoutInfo: ButtonProperty;
    paypalPaymentMethod: ButtonProperty;
}

export class PaymentStatusPageConfiguration {
  EmailAddress: ButtonProperty;
  successContent : Content;
  failedContent : Content;
}

export class DateAndSessionPageConfiguration{
  eventListView: number;
  eventBanner: ButtonProperty;
  filterPanel: ButtonProperty;
}

export class UpsellPageConfiguration{
  filterPanel: ButtonProperty;
}
export class TimeShowingConfiguration{
  eventLevel: PageLevel;
  packageLevel: PageLevel;
  sessionLevel: PageLevel;
}

export class PageLevel{
  timeIndicatorOption: TIME_INDICATOR_OPTION;
  visible: boolean
}

export class ButtonProperty {
  visible: boolean
}

export class Content {
  value: string[];
}


export class ConfigurationType {
    seating: string;
}

export class RegionalSetting {
    startDayOfWeek: string;
    defaultDateFormat: string;
    defaultCurrencyFormat: string;
    defaultCurrencySymbol: string;
}

export class LateRegistrationChargePolicy {
    chargeInFull: string;
    chargeProportionally: string;
}

export class RepeatPolicy {
    startDateOnly: string;
    endDateOnly: string;
    repeatAllDays: string;
    allowIndividualDays: string;
}

export class EmailFields {
    field: Field[];
}

export class PostalFields {
    field: Field[];
}

export class Field {
    name: string;
    placeHolder: string;
    label: string;
    type: string;
    required: boolean;
    col: number;
    canUpdate: boolean;
}

export class DeliveryMethodSetting {
    nonCommonDeliveryMethodMessage: string;
    emailFields: EmailFields;
    postalFields: PostalFields;
    entityConfigurationProfile: number;
}

export class NavigationConfirmationMessages {
    massages: Massage[];
}

export class Massage {
    name: string;
    id: string;
    value: string;
    isEnabled: boolean;
}

export class Phrases {
    bookingTermAndConditionMessage: string;
    termsAndConditionContents: TermsAndConditionContents;
    purchasePolicyExitRedirectionUrl: string;
    purchaseAgreementExitRedirectionUrl: string;
}

export class TermsAndConditionContents {
    signUpContent: string;
    footerContent: string;
}

export class ToasterMessages {
  bookingConfirm : Scope;
}

export class Scope {
  successContent : Content;
  failedContent : Content;
}
export class PaginationSetting {
    isEnable: boolean;
    minimumRequiredPageSize: number;
    pageSizeOptions: PageSizeOption[];
}

export class PageSizeOption {
    isDisable: boolean;
    value: number;
}
