import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, from } from 'rxjs';
import { Attendee, AttendeeCaptureProfile, AttendeeDisplay, Host } from 'src/modules/models/booking/Attendee';
import * as PreviousAttendee from 'src/modules/models/attendee/attendee';
import { CustomerService } from 'src/modules/services/customer.service';
import { PublicBookingService } from 'src/modules/services/public-web/public-booking.service';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { BaseComponent } from 'src/modules/shared/base.component';
import { Store } from 'src/modules/store';
import { Utility } from 'src/modules/utility';
import * as _ from "lodash";
import { translate } from '@angular/localize/src/utils';
import { TranslateService } from '@ngx-translate/core';
export interface AttendeeListResponse { skipAttendeeCapture: boolean, attendee: Attendee, host: Host, previousAttendees: Attendee[], deleteSuccess: boolean, needToSelectNewHost: boolean, deleteHost: Host, isNewlyHost: boolean,remainingGuestsCount: number }
import { PBUtility } from 'src/modules/public-web/pb-utility';
import { ATTENDEE_CAPTURE_TYPE } from 'src/modules/models/public-web/enum';
import { groupBy, mergeMap, reduce, toArray } from 'rxjs/operators';
@Component({
  selector: 'opt-attendee-list-modal',
  templateUrl: './attendee-list-modal.component.html'
})
export class AttendeeListModalComponent extends BaseComponent implements OnInit {
  hosts: Host[];
  attendeeDisplayCards: AttendeeDisplay[]
  guestList: Attendee[]
  postPerPage: number;
  pageNumber: number;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10];
  filteredGuest: Attendee[]
  maxPages = 10
  pager: { totalItems: number; currentPage: number; pageSize: number; totalPages: number; startPage: number; endPage: number; startIndex: number; endIndex: number; pages: number[]; };
  response: AttendeeListResponse;
  bookingId;
  bookingPackageDetail: { id: string, quantity: number, deleteCount: number }[] = [];
  cart$: any;
  isLoggedIn: boolean;
  clientId: any;
  isDeleteAttendee = false;
  selectAllAttendeeForDelete = false;
  removedAttendees: any[];
  isSelectNewHost: boolean;
  selectedNewHost: Attendee;
  selectedHostForDelete: Host;
  switchGuestToHostAPIs = [];
  canEdit: boolean = true;
  isAttendeeEdited: boolean = false;
  remainingGuestsCount: number = 0;
  ATTENDEE_CAPTURE_TYPE = ATTENDEE_CAPTURE_TYPE;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AttendeeListModalComponent>,
    private bookingService: PublicBookingService,
    private store: Store<any>,
    public snackBar: MatSnackBar,
    private customerService: CustomerService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super()
    dialogRef.disableClose = true;
    this.isSelectNewHost = false;
    this.hosts = data.hosts;
    this.bookingId = data.bookingId;
    this.canEdit = data.canEdit;
    this.isDeleteAttendee = !!data.isDeleteAttendee;
    if(data.isAttendeeEdited) {
      this.isAttendeeEdited = true;
    }
    this.isSelectNewHost = false;
    this.bookingPackageDetail = data.bookingPackageDetail;
    this.cart$ = this.store.select("cart");
    this.guestList = []
    this.filteredGuest = []
    this.attendeeDisplayCards = [];
    this.mapAttendeeIntoCard()
    if (this.isDeleteAttendee) {
      this.selectUnNameAttendeeForDelete();
      this.removedAttendees = [];
    }
    this.response = {
      attendee: undefined,
      skipAttendeeCapture: false,
      host: undefined,
      previousAttendees: [],
      deleteSuccess: false,
      deleteHost: undefined,
      needToSelectNewHost: false,
      isNewlyHost: false,
      remainingGuestsCount: 0
    }
  }
  getTranslate(data: any) {
    var translatedData = data.replace(/[^A-Za-z]/g, '');
    if (translatedData === 'Guest' || translatedData === 'Host') {
      this.translate.get("GUESTCHECKOUTINFO." + translatedData).safeSubscribe(this, (res: string) => {
        data = data.replace(translatedData, res);
      });
    }
    return data;
  }

  ngOnInit() {
    this.cart$.safeSubscribe(this, (state) => {
      this.isLoggedIn =
        state.contact != null &&
          state.contact.client != null &&
          state.contact.client.id != null &&
          state.contact.firstName != null &&
          state.contact.firstName != ""
          ? true
          : false;
      if (this.isLoggedIn) {
        this.clientId = state.contact.client.id
      }
    });
    if (this.isLoggedIn && this.clientId != undefined) {
      this.getClientAttendee()
    }
    // this.hosts.forEach(h => this.guestList.push(...h.guests))
    // this.setPage(1)
  }
  getAttendeGuestList(hosts: Host[]) {
    // get guest list from host
    let guestList = [];
    _.forEach(hosts, (value, key) => { guestList.push(...value.guests) })
    // guestlist sort by hostGuest is false
    guestList = _.sortBy(guestList, 'hostGuest');
    var guestListHostGuest = _.filter(guestList, { hostGuest: true });
    if (guestListHostGuest.length > 1) {
      let namedhost = [];
      let nonnamedhost = [];
      let defaultNamedhost = [];
      guestListHostGuest.forEach(host => {
        //host first name includes with 'Host' Word
        if(host.firstName) {
          if (host.firstName.includes('Host') || host.firstName.includes('host') || host.firstName.includes('HOST')) {
            defaultNamedhost.push(host);
          }
          else {
            namedhost.push(host);
          }
        } else {
          nonnamedhost.push(host);
        }
      })
      //sort host named by last digit
      if (defaultNamedhost.length > 0) {
        defaultNamedhost = defaultNamedhost.sort((a, b) => (+a.firstName.substr(a.firstName.length - 1)) - (+b.firstName.substr(b.firstName.length - 1)))
      }
      if (namedhost.length > 0) {
        namedhost = namedhost.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1)
      }
      guestListHostGuest = [...namedhost, ...defaultNamedhost, ...nonnamedhost]
    }

    var guestListNonHostGuest = _.filter(guestList, { hostGuest: false });
    if (guestListNonHostGuest.length > 1) {
      let namedguest = [];
      let nonnamedguest = [];
      guestListNonHostGuest.forEach(guest => {
        //guest first name includes with 'Guest' Word
        if (guest.firstName.includes('Guest') || guest.firstName.includes('guest') || guest.firstName.includes('GUEST')) {
          nonnamedguest.push(guest);
        }
        else {
          namedguest.push(guest);
        }
      })
      //renamed the guest's name start with 'Guest 1'
      if (nonnamedguest.length > 0) {
        nonnamedguest = nonnamedguest.sort((a, b) => (+a.firstName.substr(5, a.firstName.length - 1)) - (+b.firstName.substr(5, b.firstName.length - 1)));
      }
      if (namedguest.length > 0) {
        namedguest = namedguest.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1)
      }
      guestListNonHostGuest = [...namedguest, ...nonnamedguest]
    }
    guestList = [...guestListHostGuest, ...guestListNonHostGuest];
    return guestList;
  }

  close() {
    this.dialogRef.close(this.response)
  }
  getPackageDate(s: Date, e: Date) {
    if (s === e) {
      return s
    }
    return s + ' - ' + e;
  }
  isMultiDayHost(s: Date, e: Date) {
    let v = false;
    if (s != e) {
      return true;
    }
    return v
  }
  mapAttendeeIntoCard() {
    this.hosts.forEach(host => {
      const isExist = this.attendeeDisplayCards.findIndex(a => a.id === host.bookingPackage.id);
      if (isExist !== -1) {
        const isHostEx = this.attendeeDisplayCards[isExist].hosts.findIndex(x => x.id === host.id);
        if (isHostEx === -1) {
          if(host.bookingPackage?.attendeeCaptureProfile?.captureHost && !host.bookingPackage?.attendeeCaptureProfile?.captureGuest) {
            host.guests = host.guests.filter(g => g.hostGuest);
          } else { 
            host.guests.sort((a, b) => {
              return Number(b.hostGuest) - Number(a.hostGuest)
            })
          }
          this.attendeeDisplayCards[isExist].hosts.push(host)
          if(!this.attendeeDisplayCards[isExist].attendeeCaptureType) {
            this.attendeeDisplayCards[isExist].attendeeCaptureType = PBUtility.getAttendeeCaptureType(host?.bookingPackage?.attendeeCaptureProfile);
          }
        }
      } else {
        const attendeeDisplayCard = new AttendeeDisplay();
        attendeeDisplayCard.id = host.bookingPackage.id;
        attendeeDisplayCard.bookingPackage = host.bookingPackage;
        attendeeDisplayCard.hosts = [host];
        attendeeDisplayCard.attendeeCaptureType = PBUtility.getAttendeeCaptureType(host?.bookingPackage?.attendeeCaptureProfile);
        if (attendeeDisplayCard.hosts && attendeeDisplayCard.hosts.length > 0) {
          attendeeDisplayCard.hosts.forEach(h => {
            if(host.bookingPackage?.attendeeCaptureProfile?.captureHost && !host.bookingPackage?.attendeeCaptureProfile?.captureGuest) {
              h.guests = h.guests.filter(g => g.hostGuest);
            } else { 
              h.guests.sort((a, b) => {
                return Number(b.hostGuest) - Number(a.hostGuest)
              })
            }
          })
        }
        this.attendeeDisplayCards.push(attendeeDisplayCard)
      }
    })
    if(this.attendeeDisplayCards.length > 0)
    {
      this.attendeeDisplayCards.forEach(cart => {
        cart.remainingGuestsCount = cart.hosts ? this.getRemainingGuests(cart.hosts) : 0;
      })
    }
  }
  //#region  Change Guest To Host and delete Host
  switchTheGuestToHost() {
    this.removedAttendees.forEach(removedAttendee => {
      removedAttendee.deleteAttendee.forEach(attendee => {
        if (attendee.hostGuest) {
          if (removedAttendee.selectedNewHost) {
            this.switchGuestToHostAPIs.push(this.bookingService.SwitchGuestToHost(this.bookingId, attendee.id, removedAttendee.selectedNewHost.id))
          }
        }
      })
    })
    forkJoin(this.switchGuestToHostAPIs).safeSubscribe(this, res => {
      this.deleteSelectedAttendee()
    })
  }
  //#endregion
  //#region Attendee Edit
  getClientAttendee() {
    this.customerService.getClientAttendees(this.clientId).safeSubscribe(this, (attendees: PreviousAttendee.Attendee[]) => {
      let previousAttendees = [];
      if (attendees?.length > 0) {
        const groupedSessions$ = from(attendees).pipe(
          groupBy(
            (attendee) =>
            attendee.firstName && attendee.lastName
          ),
          mergeMap((group) =>
            group.pipe(
              reduce(
                (acc, cur) => {
                  acc.values.push(cur);
                  return acc;
                },
                { key: group.key, values: [] }
              )
            )
          ),
          toArray()
        );
    
        groupedSessions$.forEach((element) => {
          element.forEach((value: any, key) => {
            previousAttendees.push(value.values[0]);
          });
        });
      }
      this.response.previousAttendees = previousAttendees;
    })
  }
  continueName() {
    // let hosts = [];
    // this.hosts.forEach(x => {
    //   hosts.push({
    //     id: x.id,
    //     count: x.guests.filter(g => (g.namedHostGuest &&
    //       (Object(g)["firstName"] &&
    //         (!Object(g)["firstName"].toLowerCase().includes("host") && !Object(g)["firstName"].toLowerCase().includes("guest")))
    //     )).length,
    //     needAnswerCount: Math.ceil(x.guests.length / 3)
    //   })
    // })
    let Continue: string = this.translate.instant('ATTENDEEINFO.Continue')
    let Skip: string = this.translate.instant('ATTENDEEINFO.Skip')
    //return hosts.length === hosts.filter(x => x.count >= x.needAnswerCount).length ? `${Continue} ` : `${Skip}`
    return this.isAttendeeEdited ? `${Continue} ` : `${Skip}`;
  }
  private openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: [message],
      duration: 4000,
      verticalPosition: 'top',
    });
  }
  skipAttendees() {
    this.response.skipAttendeeCapture = true;
    this.close()
  }
  getAttendeeName(guest: Attendee, hosts: Host[]) {
    //find host of guest from guests
    let host = hosts.find(x => x.guests.find(g => g.id === guest.id));
    let name = guest.firstName;
    if (!!host.bookingPackage && !!(host.bookingPackage as any).attendeeCaptureProfile) {
      const attendeeCaptureProfile: AttendeeCaptureProfile = (host.bookingPackage as any).attendeeCaptureProfile;
      if (guest.hostGuest) { //&& attendeeCaptureProfile.captureHost
        name = host.firstName;
      }
      if (!guest.namedHostGuest || !name || (name && name.toLowerCase().includes("guest")) || (name && name.toLowerCase().includes("host"))) {
        const guestIndex = host.guests.findIndex(x => x.id === guest.id)
        if (attendeeCaptureProfile.captureGuest && !attendeeCaptureProfile.captureHost) {
          if (((name && name.toLowerCase().includes("guest")) || (name && name.toLowerCase().includes("host")))) {
            name = "Attendee " + (guestIndex + 1)
          }
        }
        if (!attendeeCaptureProfile.captureGuest && attendeeCaptureProfile.captureHost) {
          name = "Host " + (guestIndex + 1)
        }
        if (attendeeCaptureProfile.captureGuest && attendeeCaptureProfile.captureHost && !name) {
          if (guest.hostGuest) {
            name = "Host " + (guestIndex + 1)
          } else {
            name = "Guest " + (guestIndex)
          }
        }
      }
    }
    return name
  }

  getAttendeeInfo(guest: Attendee, hosts: Host[]) {
    //find host of guest from guests
    let host = hosts.find(x => x.guests.find(g => g.id === guest.id));
    let firstName = guest.firstName;
    let lastName = guest.lastName;
    let companyName = guest.companyName;
    let attendees = 0;
    let isEdited = false;
    if (!!host.bookingPackage && !!(host.bookingPackage as any).attendeeCaptureProfile) {
      const attendeeCaptureProfile: AttendeeCaptureProfile = (host.bookingPackage as any).attendeeCaptureProfile;
      if (guest.hostGuest) {
        firstName = host.firstName;
        lastName = host.lastName;
        companyName = host.companyName;
        attendees = host.attendees;
        isEdited = host.namedHost ? host.namedHost : false;
      }
      if ((((firstName && firstName.toLowerCase().includes("guest")) || (firstName && firstName.toLowerCase().includes("host"))) && !guest.namedHostGuest) || !firstName) {
        const guestIndex = host.guests.findIndex(x => x.id === guest.id)
        if (attendeeCaptureProfile.captureGuest && !attendeeCaptureProfile.captureHost) {
          if (((firstName && firstName.toLowerCase().includes("guest")) || (firstName && firstName.toLowerCase().includes("host")))) {
            firstName = "Attendee " + (guestIndex + 1)
          }
        }
        if (!attendeeCaptureProfile.captureGuest && attendeeCaptureProfile.captureHost) {
          firstName = "Host " + (guestIndex + 1)
        }
        if (attendeeCaptureProfile.captureGuest && attendeeCaptureProfile.captureHost && !firstName) {
          if (guest.hostGuest) {
            firstName = "Host " + (guestIndex + 1)
          } else {
            firstName = "Guest " + (guestIndex)
          }
        }
      }
    }
    return {firstName, lastName, companyName, attendees, isEdited}
  }

  private setPage(page: number) {
    this.pager = Utility.paginate(this.guestList.length, page, this.pageSize, this.maxPages);
    this.filteredGuest = this.guestList.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  onPaginate(pageEvent: PageEvent) {
    this.setPage(pageEvent.pageIndex + 1)
    // this.postPerPage = +pageEvent.pageSize;
    // this.pageNumber = +pageEvent.pageIndex + 1;
  }
  onEditAttendee(attendee: Attendee, hosts: Host[], remainingGuestsCount: number) {
    let host = hosts.find(x => x.guests.find(g => g.id === attendee.id));
    if (this.isDeleteAttendee) {

    } else {
      this.response.attendee = attendee;
      this.response.host = host;
      this.response.remainingGuestsCount = remainingGuestsCount;
      this.close()
    }
  }
  //#endregion
  //#region Attendee Delete
  get enableDeleteButton() {
    let isE = false;
    for (const a of this.attendeeDisplayCards) {
      for (const h of a.hosts) {
        if (h.guests.filter(x => x.isSelectedForDelete).length > 0) {
          isE = true;
          break;
        }
      }
      if (isE) {
        break;
      }
    }
    return isE;
  }
  selectUnNameAttendeeForDelete() {
    this.attendeeDisplayCards.forEach(a => a.hosts.forEach(h => {
      const packageDetail = this.bookingPackageDetail.find(x => x.id == a.id);
      if (!!packageDetail) {
        let deleteQuantity = packageDetail.deleteCount;
        h.guests.forEach(g => {
          if (!g.namedHostGuest && !g.hostGuest && deleteQuantity > 0) {
            g.isSelectedForDelete = true;
            deleteQuantity--;
          }
        })
      }
    }))
  }
  onSelectAllAttendeeForDelete() {
    this.attendeeDisplayCards.forEach(x => x.hosts.forEach(h => h.guests.forEach(g => g.isSelectedForDelete = this.selectAllAttendeeForDelete)))
  }
  getPackageDeleteCount(attendeeDisplayCard: AttendeeDisplay) {
    const packageDeleteQuantity = this.bookingPackageDetail.find(x => x.id === attendeeDisplayCard.bookingPackage.id)
    if (packageDeleteQuantity && packageDeleteQuantity.deleteCount > 0) {
      let selectedCount = 0
      // get Selected Count
      attendeeDisplayCard.hosts.forEach(x => {
        selectedCount += x.guests.filter(y => y.isSelectedForDelete).length;
      })
      const count = packageDeleteQuantity.deleteCount - selectedCount;
      if (count > 0) {
        return `${count} need to be delete`
      }
    }
    return ''
  }
  removeSelectedAttendee() {
    this.attendeeDisplayCards.forEach(a => a.hosts.forEach(h => {
      h.guests.forEach(g => {
        if (g.isSelectedForDelete) {
          const hostAttendee = [];
          const guests = [];
          a.hosts.forEach(x => { hostAttendee.push(...x.guests.filter(y => y.hostGuest && !y.isSelectedForDelete)); guests.push(...x.guests.filter(y => !y.hostGuest && !y.isSelectedForDelete)); });
          const isExist = this.removedAttendees.findIndex(x => x.bookingPackage.id === a.bookingPackage.id);
          if (isExist != -1) {
            const attExist = this.removedAttendees[isExist].deleteAttendee.findIndex(d => d.id === g.id);
            if (attExist == -1) {
              this.removedAttendees[isExist].deleteAttendee.push(g)
            }
          } else {
            this.removedAttendees.push({ bookingPackage: a.bookingPackage, deleteAttendee: [g], hostAttendee: hostAttendee, guests: guests, host: h })
          }
        }
      })
    }))
    this.removedAttendees.forEach(att => {
      att.deleteAttendee.forEach(guest => {
        this.attendeeDisplayCards.forEach(a => a.hosts.forEach(h => {
          const index = h.guests.findIndex(x => x.id == guest.id);
          let bookingPackageDetail = this.bookingPackageDetail.find(x => x.id == a.id);
          if (index != -1) {
            h.guests.splice(index, 1)
            bookingPackageDetail.deleteCount--;
          }
        })
        )
      })
    })

  }
  removeDeleteSaveBTN() {
    let deleteCount = 0;
    this.bookingPackageDetail.forEach(x => deleteCount += x.deleteCount)

    return deleteCount <= 0;
  }
  onDeleteAttendeeSave() {
    let newHostSelected = false;
    this.removedAttendees.forEach(removedAttendee => {
      removedAttendee.deleteAttendee.forEach(attendee => {
        if (attendee.hostGuest) {
          if (removedAttendee.hostAttendee.length > 1) {
            const newHosts = removedAttendee.hostAttendee.filter(h => h.id !== attendee.id);
            if (newHosts.length > 0 && !newHostSelected) {
              this.switchGuestToHostAPIs.push(this.bookingService.SwitchGuestToHost(this.bookingId, attendee.id, newHosts[0].id))
              newHostSelected = true
            }
          } else {
            if (removedAttendee.bookingPackage) {
              if ((removedAttendee.bookingPackage.attendeeCaptureProfile as AttendeeCaptureProfile).captureGuest && (removedAttendee.bookingPackage.attendeeCaptureProfile as AttendeeCaptureProfile).captureHost) {
                if (removedAttendee.guests.length > 0) {
                  if (removedAttendee.guests.length === 1) {
                    if (!newHostSelected) {
                      this.switchGuestToHostAPIs.push(this.bookingService.SwitchGuestToHost(this.bookingId, attendee.id, removedAttendee.guests[0].id))
                      newHostSelected = true
                    }
                  } else {
                    this.isSelectNewHost = true;
                  }
                }
              } else if ((removedAttendee.bookingPackage.attendeeCaptureProfile as AttendeeCaptureProfile).captureGuest) {
                if (removedAttendee.guests.length > 0) {
                  this.switchGuestToHostAPIs.push(this.bookingService.SwitchGuestToHost(this.bookingId, attendee.id, removedAttendee.guests[0].id))
                }
              }
            }
          }
        }
      })
    })
    if (!this.isSelectNewHost && this.switchGuestToHostAPIs.length > 0) {
      forkJoin(this.switchGuestToHostAPIs).safeSubscribe(this, res => {
        this.deleteSelectedAttendee()
      })
    } else if (!this.isSelectNewHost && this.switchGuestToHostAPIs.length === 0) {
      this.deleteSelectedAttendee()
    }
  }

  deleteSelectedAttendee() {
    const selectedAttendeeIds = [];
    this.removedAttendees.forEach(x => {
      x.deleteAttendee.forEach(att => {
        selectedAttendeeIds.push(att.id)
      })
    })
    if (selectedAttendeeIds.length > 0) {
      this.bookingService.deleteAttendee(this.bookingId, selectedAttendeeIds).subscribe((responses: []) => {
        let success = true;
        responses.forEach((r: any) => {
          if (r && r.id && !r.status) {
            success = false;
          }
        })
        this.response.deleteSuccess = success;
        this.dialogRef.close(this.response)
      },
        error => {
          this.response.deleteSuccess = false;
          this.dialogRef.close(this.response)
        })
    } else {
      this.response.deleteSuccess = false;
      this.dialogRef.close(this.response)
    }
  }
  //#endregion

  getRemainingGuests(hosts : Host[]) {
    let totalAttendees = hosts[0].bookingPackage?.attendees;
    let totalHostAssignedGuests = 0;
    let totalHostUnassignGuests = 0;
    hosts.forEach(host => {
      totalHostAssignedGuests += host.attendees;
    })
    if (totalAttendees > 0 && totalHostAssignedGuests > 0) {
      totalHostUnassignGuests = totalAttendees - totalHostAssignedGuests;
    }
    return totalHostUnassignGuests;
  }

  addNewHost(attendeeDisplayCard: AttendeeDisplay) {
    this.response.host = attendeeDisplayCard.hosts[0];
    this.response.isNewlyHost  = true;
    this.response.remainingGuestsCount = attendeeDisplayCard.remainingGuestsCount;
    this.close();

  }

  getHosts(hosts : Host[]){
    let filteredHosts : Host[] = [];
    const groupedHostByBookingPackage$ = from(hosts).pipe(
      groupBy(
        (host) =>
        host?.bookingPackage?.id
      ),
      mergeMap((group) =>
        group.pipe(
          reduce(
            (acc, cur) => {
              acc.values.push(cur);
              return acc;
            },
            { key: group.key, values: [] }
          )
        )
      ),
      toArray()
    );
    let groupedHosts : Host[];
    groupedHostByBookingPackage$.forEach((element) => {
      element.forEach((value: any, key) => {
        groupedHosts = value.values;
        const groupedHostByPackageDate$ = from(groupedHosts).pipe(
          groupBy(
            (host) =>
            host?.packageDate.id
          ),
          mergeMap((group) =>
            group.pipe(
              reduce(
                (acc, cur) => {
                  acc.values.push(cur);
                  return acc;
                },
                { key: group.key, values: [] }
              )
            )
          ),
          toArray()
        );
        
        groupedHostByPackageDate$.forEach((element) => {
          filteredHosts.push(...element[0].values);
        })
      });
    });
    return filteredHosts;
  }

}
