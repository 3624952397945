<div class="body-wrapper bg-transparent"  *ngIf="enableNewDesignForBookingCartPage">
  <opt-nav-bar-layout [havePendingChanges]="isAnyPendingChanges"
                      [bookingDisplayCart]="bookingDisplayCart"
                      [isClearCart]="false">
  </opt-nav-bar-layout>
  <div class="modal fade" id="cancelBookingModal" tabindex="-1" role="dialog" aria-labelledby="cancelBookingModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="border:unset !important;">
        <div class="mmc-modal-wrapper cancel-booking-modal2">
          <div class="mmc_flex--container mmc-modal-header header-primary p-4">
            <div class="mmc_flex__item mmc_flex__item--vcenter" style="margin-left: -4px;">
              <span class="heading" style="color: #ffffff;font-size: 20px;font-weight: bolder;">
                {{cartType==BOOKING_CART.NEW_BOOKING ? ('BOOKINGCART.Clear your Cart'| optimotranslate) :
                ('BOOKINGCART.Cancel Booking' | optimotranslate)}}</span>
            </div>
            <div class="close-btn" style="align-items:center;display:flex;">
              <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;"
                (click)="enableCustomCancelMessage=false;isCancelModalOpen=false;" type="submit" data-dismiss="modal">
                close</mat-icon>
            </div>
          </div>
          <div class="mmc-modal-body">
            <div class="mmc_flex--container inner" *ngIf="cartType==BOOKING_CART.EDIT_BOOKING">
              <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" *ngIf="!enableCustomCancelMessage">
                {{'BOOKINGCART.A payment has already been made for this booking, Are you sure want to cancel this booking?' | optimotranslate}}
              </div>
              <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" *ngIf="enableCustomCancelMessage">
                {{'BOOKINGCART.If you remove all items from your booking, it will be cancelled. Are you sure you want to proceed?' |
                optimotranslate}}
              </div>
              <div *ngIf="!enableNewDesignForBookingCartPage" class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2">
                {{'BOOKINGCART.The cancellation fee will be' | optimotranslate }} <span> &nbsp;{{cancellationFee | PBCurrencyPipe}}</span>
              </div>
              <div *ngIf="!enableNewDesignForBookingCartPage" class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2 tnc-para">
                {{'BOOKINGCART.Any refunds will be based on the' | optimotranslate}}&nbsp; <a [href]="purchasePolicyUrl" target="_blank">
                  {{'BOOKINGCART.Terms & Conditions' | optimotranslate}}</a>
              </div>
              <div *ngIf="enableNewDesignForBookingCartPage" class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2 tnc-para"
                style="display:inline">
                {{'BOOKINGCART.The refunds are based on the agreed' | optimotranslate}}
                <a [href]="purchasePolicyUrl" target="_blank"> {{'BOOKINGCART.Purchase Policy' | optimotranslate}}</a>
              </div>
              <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2 tnc-para">
                <mat-radio-group aria-labelledby="cancel-radio-group-label" class="cancel-radio-group"
                  [(ngModel)]="selectedCancellation">
                  <mat-radio-button class="cancel-radio-button" *ngFor="let reason of cancellationReason"
                    [value]="reason">
                    {{'BOOKINGCART.'+reason.name | optimotranslate}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2 tnc-para">
                <div class="form-group" style="width: 100%;">
                  <label for="customCancellationReason">{{'BOOKINGCART.Any suggestions you may have? (Optional)' |
                    optimotranslate}}</label>
                  <textarea type="email" class="form-control" rows="1" id="customCancellationReason"
                    [(ngModel)]="customCancellationReason" style="min-width: 100%;"></textarea>
                </div>
              </div>

            </div>
            <div class="mmc_flex--container inner" *ngIf="cartType==BOOKING_CART.NEW_BOOKING">
              <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" *ngIf="enableCustomCancelMessage">
                {{'BOOKINGCART.If you remove all items from the booking, it will be cancelled. Are you sure you want to proceed?' | optimotranslate}}
              </div>
              <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" *ngIf="!enableCustomCancelMessage">
                {{'BOOKINGCART.Are you sure want to cancel this booking?' | optimotranslate}}
              </div>
            </div>

            <div class="cancel-booking-btn-wrapper">
              <button type="submit"
                class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter cancel-booking-btn"
                data-dismiss="modal" mat-button (click)="cancelBooking(cartType)"
                [disabled]="!selectedCancellation && cartType === BOOKING_CART.EDIT_BOOKING">
                {{'BOOKINGCART.Yes, cancel this booking'| optimotranslate}}
              </button>
              <button type="submit" class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter close-btn"
                data-dismiss="modal" (click)="enableCustomCancelMessage=false;isCancelModalOpen=false;" mat-button>
                {{"BOOKINGCART.No, I don't want to cancel" | optimotranslate}}
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="booking_cart mb-5">

    <!-- This section will be displayed only in the mobile device -->
    <div class="mobile_only_total_card" *ngIf="noOfSeats > 0">
      <div class="total">
        <div>
          <p class="title">{{'BOOKINGCART.Total' | optimotranslate}}</p>
          <p class="seat_count">({{noOfSeats}} {{noOfSeats > 1
            ? ('BOOKINGCART.Seats' | optimotranslate)
            : ('BOOKINGCART.Seat' | optimotranslate)}}
            {{ "BOOKINGCART.selected" | optimotranslate }})
          </p>
        </div>
        <p class="total_amount">{{ booking?.grossAmount | PBCurrencyPipe }}</p>
      </div>
      <button class="pay_btn" *ngIf="(booking?.invoicedAmount == 0 && booking?.grossAmount != 0) && ((countPendingInvoices > 0 || isPaymentEnabled) && PaymentPortalUrl  && !(deliveryMethods && deliveryMethods.length==0)) " (click)="onCheckout()">
        {{'BOOKINGCART.Confirm & Pay'| optimotranslate }}
      </button>
    </div>




    <!-- this section will be displayed only in the desktop view  -->
    <div class="cancel-booking">
      <h6 class="main_title">{{'BOOKINGCART.Order details' | optimotranslate }}</h6>
      <div class="d-flex justify-content-end">
        <button style="display: none !important;"
          class="mdc-button mdc-button--outlined bd-highlight editBooking-button w-bg-button edit-booking-btn mr-2"
          id="clearBasketBtn" data-toggle="modal" (click)="isCancelModalOpen = true"
          data-target="#cancelBookingModal"> {{'BOOKINGCART.Cancel Booking' | optimotranslate }}
        </button>

        <ng-container *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
          <button *ngIf="isBookingEditable && editBookingButtonConfiguration.deleteBookingButton.visible"
            class="mdc-button mdc-button--outlined bd-highlight editBooking-button w-bg-button edit-booking-btn mr-2"
            style="margin-top:0px !important;"
            (click)="getCancellationFeeAndReasons();enableCustomCancelMessage=false">
            {{'BOOKINGCART.Cancel Booking' | optimotranslate }}
          </button>
          <button style="display: none !important;" *ngIf="isBookingEditable"
            class="mdc-button mdc-button--outlined bd-highlight editBooking-button w-bg-button edit-booking-btn mr-2"
            data-toggle="modal" data-target="#cancelBookingModal" id="cancelBookingModalTrigger"
            matTooltip="{{'BOOKINGCART.Cancel Booking' | optimotranslate }}" matTooltipPosition="above">
            <i class="fa fa-trash mdc-font" aria-hidden="true"></i>
          </button>
        </ng-container>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-8">

        <div *ngIf="isLoading">
          <opt-loader></opt-loader>
        </div>
        <div *ngIf="(bookedEvents?.length > 0 && !isLoading) else elseBlock">
          <div *ngFor="let event of bookedEvents">
            <div class="flex-column title_card" *ngIf="editBookingButtonConfiguration.eventBanner.visible">
              <div class="col-12 versus" [innerHTML]="event?.shortDescription | safeHtml">
              </div>
              <div class="col-12 place_and_time">
                <p class="place">{{event?.name}}</p>
                <p class="venue">{{event?.venueName | uppercase}}</p>
                <div *ngIf="(event?.eventDates.length > 0) else elseEventDate" class="time">
                  <p *ngIf="isSameDate(event, 'event')" class="time">
                    <span>{{event?.eventDates[0]?.startTime | PbCustomDate:'EE · dd/MMM/yyyy'}}</span><br/>
                    <span *ngIf="eventLevelTimeShowingConfiguration && eventLevelTimeShowingConfiguration?.visible && eventLevelTimeShowingConfiguration?.timeIndicatorOption==TIME_INDICATOR_OPTION.STARTTIME_ONLY">{{event?.eventDates[0]?.startTime | PbCustomDate:'h:mm a'}}</span>
                    <span *ngIf="eventLevelTimeShowingConfiguration && eventLevelTimeShowingConfiguration?.visible && eventLevelTimeShowingConfiguration?.timeIndicatorOption==TIME_INDICATOR_OPTION.STARTTIME_ENDTIME">{{event?.eventDates[0]?.startTime | PbCustomDate:'h:mm a'}} - {{event?.eventDates[0]?.endTime | PbCustomDate:'h:mm a'}}</span>
                  </p>
                  <p *ngIf="!isSameDate(event, 'event')" class="time">
                    <span>{{event?.eventDates[0]?.startTime | PbCustomDate:'EE · dd/MMM/yyyy'}} - {{event?.eventDates[event?.eventDates.length - 1]?.startTime | PbCustomDate:'EE · dd/MMM/yyyy'}}</span><br/>
                    <span *ngIf="eventLevelTimeShowingConfiguration && eventLevelTimeShowingConfiguration?.visible && eventLevelTimeShowingConfiguration?.timeIndicatorOption==TIME_INDICATOR_OPTION.STARTTIME_ONLY">{{event?.eventDates[0]?.startTime | PbCustomDate:'h:mm a'}}</span>
                    <span *ngIf="eventLevelTimeShowingConfiguration && eventLevelTimeShowingConfiguration?.visible && eventLevelTimeShowingConfiguration?.timeIndicatorOption==TIME_INDICATOR_OPTION.STARTTIME_ENDTIME">{{event?.eventDates[0]?.startTime | PbCustomDate:'h:mm a'}} - {{event?.eventDates[event?.eventDates.length - 1]?.endTime | PbCustomDate:'h:mm a'}}</span>
                  </p>
                </div>
                <ng-template #elseEventDate>
                  <div class="time"><p class="time">{{event?.eventStartTime | PbCustomDate:'EE · dd/MMM/yyyy'}}</p></div>
                </ng-template>
              </div>
            </div>
            <div *ngIf="bookingDisplayCart && bookingDisplayCart.length > 0 && bookingDisplayCart[0].eventid">
              <div *ngFor="let package of bookingDisplayCart | filter : 'eventid' : event?.id ; index as i;">
                <div class="package_card">

                  <div class="package pr-3">
                    <img class="package_img" src={{package.image}}>
                    <div class="col-md-9 col-sm-8 col-12 main_content">
                      <div class="heading_row">
                        <p class="name">
                          <span>{{package.name}}</span>
                          <span class="date">
                            {{ isSameDate(package,'package') ? (package.startDate | PbCustomDate:'EE · dd/MMM/yyyy') : setDateFormat(package.startDate,package.endDate) }}
                            <span *ngIf="packageLeveltimeShowingConfiguration && packageLeveltimeShowingConfiguration?.visible && packageLeveltimeShowingConfiguration?.timeIndicatorOption==TIME_INDICATOR_OPTION.STARTTIME_ONLY">{{package.startDate | PbCustomDate:'h:mm a'}}</span>
                            <span *ngIf="packageLeveltimeShowingConfiguration && packageLeveltimeShowingConfiguration?.visible && packageLeveltimeShowingConfiguration?.timeIndicatorOption==TIME_INDICATOR_OPTION.STARTTIME_ENDTIME">{{package.startDate | PbCustomDate:'h:mm a'}} - {{package.endDate | PbCustomDate:'h:mm a'}}</span>
                          </span>
                        </p>
                        <div style="flex: 1" *ngIf="isEditablePack(package.cartPackages[0])">
                          <opt-seat-input [cartType]="cartType" [min]="1" [package]="package" *ngIf="!package.hasLinkedPackage" (valueChange)="onItemQuantityChange($event, i)"
                          [(value)]="package.cartPackages[0].quantity"></opt-seat-input>
                          <!-- [disableIncreaseBtnD]=""  [disableReduceBtnD]="disableReduceButton"-->
                        </div>
                        <!-- <div *ngIf="!isEditablePack(package.cartPackages[0])">
                            {{package.cartPackages[0].quantity}}
                        </div> -->
                        <p *ngIf="cartType === BOOKING_CART.NEW_BOOKING" class="price ml-auto">{{package.totalPrice | PBCurrencyPipe}}</p>
                        <p *ngIf="cartType === BOOKING_CART.EDIT_BOOKING" class="price ml-auto">{{ getPackageTotalPrice(package.bookingPackageId) | PBCurrencyPipe }}</p>

                        <img *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible" class="delete"
                        (click)="$event.stopPropagation();deletePackages(package.id, package)"
                          src="../dist/assets/images/trash-delete.png">
                      </div>
                      <div>
                        <div class="ticket_row" *ngFor="let item of package.cartPackages">
                          <p *ngIf="package.hasLinkedPackage" class="type">{{item.quantity}} X {{item.name}} @ {{item.unitPrice | PBCurrencyPipe}}</p>
                          <p *ngIf="!package.hasLinkedPackage" class="type">{{item.quantity}} X {{item.unitPrice | PBCurrencyPipe}}</p>
                          <div *ngIf="isEditablePack(package.cartPackages[0]) && package.hasLinkedPackage">
                              <opt-seat-input  [min]="1" [cartType]="cartType" [package]="package" (valueChange)="onItemQuantityChange($event, i)" [(value)]="item.quantity">
                              </opt-seat-input>
                          </div>
                          <!-- <div *ngIf="!isEditablePack(package.cartPackages[0])">
                            {{item.quantity}}
                          </div> -->
                          <!-- [disableIncreaseBtnD]="cartType === BOOKING_CART.EDIT_BOOKING" [disableReduceBtnD]="cartType === BOOKING_CART.EDIT_BOOKING"-->
                          <div style="width: 110px">
                            <p *ngIf="package.hasLinkedPackage && package.cartPackages.length > 1" class="amount">{{item.totalPrice | PBCurrencyPipe }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="action_btns">
                        <i class="material-icons"
                          *ngIf="editBookingButtonConfiguration.questionnaire.visible">help_outlined</i>
                        <img src="../dist/assets/images/more-people.png"
                          *ngIf="editBookingButtonConfiguration.hostGuest.visible" />
                        <!-- <img *ngIf="cartType === BOOKING_CART.NEW_BOOKING && editBookingButtonConfiguration.addOns.visible" src="../dist/assets/images/cart.png" (click)="addAddonsForBookingPackage(package)" />
                        <img class="ml-0" *ngIf="!isEditablePack(package.cartPackages[0]) && cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible" (click)="editTicket(package.cartPackages[0].id,null,package)"
                          src="../dist/assets/images/swapseat-icon.png" /> -->
                      </div>

                      <div class="d-flex pill-btn-set">
                        <div class="d-flex pill-btn yellow"
                          *ngIf="!isEditablePack(package.cartPackages[0]) && cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                          (click)="editTicket(package.cartPackages[0].id,null,package)">
                            <div class="pill-ico">
                              <img src="{{imagePath}}/assets/images/publicportal/Seat.svg">
                            </div>
                            <div class="pill-txt">
                              <p>{{'BOOKINGCART.Edit Seats' | optimotranslate}}</p>
                            </div>
                        </div>
                        <div class="d-flex pill-btn blue"
                          *ngIf="cartType === BOOKING_CART.NEW_BOOKING && (enableUpsellConfiguration && editBookingButtonConfiguration.addOns.visible) && package.isShowAddExtra"
                          (click)="addAddonsForBookingPackage(package)">
                          <div class="pill-ico">
                            <img src="{{imagePath}}/assets/images/publicportal/file.svg">
                          </div>
                          <div class="pill-txt">
                            <p>{{'BOOKINGCART.Add Extras' | optimotranslate}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="upsell accordian-set pr-3">

                    <!-- seat details -->
                    <mat-expansion-panel class="mat-elevation-z0 cart-expansion-panel col-12 p-0" *ngIf="!isEditablePack(package.cartPackages[0])"
                    [expanded]="expandedBookingPackageIndex === i && selectedBookingPackageID === package?.bookingPackageId && seatPanelExpanded">
                      <mat-expansion-panel-header class="exp-header" style="padding-left: 10px;" (click)="openExpansion(i, package.bookingPackageId, 'seatPanel')">
                        <mat-panel-title>
                          <div class="d-flex flex-row">
                            <div class="exp-header-title">
                              {{'BOOKINGCART.Seat Details' | optimotranslate}}
                            </div>
                            <div class="cart-expansion-header-pill" *ngIf="package.seats?.length > 0">
                              {{package.seats?.length}} {{'BOOKINGCART.Added' | optimotranslate}}
                            </div>
                          </div>

                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="exp-cont-main">
                        <div class="exp-cont table-header">
                          <div class="d-flex justify-content-around">
                            <div class="col-3 bold">Row</div>
                            <div class="col-2 bold">Seat</div>
                            <div class="col-2 bold">Block</div>
                            <div class="col-3 bold">Value</div>
                            <div class="col-2 bold d-flex justify-content-center" *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible && package.hasEditableSeats">Action</div>


                          </div>
                        </div>
                        <div class="exp-cont" *ngFor="let seat of package.seats; index as i">
                          <div class="d-flex justify-content-around">
                            <div class="col-3">{{ seat.seatRowName }}</div>
                            <div class="col-2">{{ seat.seatColumnName }}</div>
                            <div class="col-2">{{ seat.eventBlock.name }}</div>
                            <div class="col-3">{{ seat?.assignedPackage?.unitPrice | PBCurrencyPipe }}</div>
                            <div class="col-2 trash-icon" *ngIf="!seat.eventBlock.private && cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible">
                              <img src="{{imagePath}}/assets/images/publicportal/trash.svg"  (click)="onRemoveBookingSeat(package,seat)">
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>

                    <!-- extras -->
                    <mat-expansion-panel class="mat-elevation-z0 cart-expansion-panel col-12 p-0" *ngIf="(enableUpsellConfiguration && editBookingButtonConfiguration.addOns.visible) && ((cartType === BOOKING_CART.NEW_BOOKING && (package.cartItems.length > 0 || package.isShowAddExtra)) || cartType === BOOKING_CART.EDIT_BOOKING)"
                      [expanded]="expandedBookingPackageIndex === i && selectedBookingPackageID === package?.bookingPackageId && upsellPanelExpanded">
                      <mat-expansion-panel-header class="exp-header" style="padding-left: 10px;" (click)="openExpansion(i, package.bookingPackageId, 'upsellPanel')">
                        <mat-panel-title>
                          <div class="d-flex flex-row">
                            <div class="exp-header-title">
                              {{'BOOKINGCART.Extras' | optimotranslate}}
                            </div>
                            <div class="cart-expansion-header-pill" *ngIf="package.cartItems.length > 0">
                              {{package.cartItems.length}} {{'BOOKINGCART.Added' | optimotranslate}}
                            </div>
                          </div>

                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="exp-cont-main" *ngIf="package.cartItems.length > 0 else noUpSell">
                        <div class="exp-cont" *ngFor="let upsell of package.cartItems">
                          <div [ngSwitch]="upsell.repeatPolicy">
                            <div *ngSwitchCase="upsell.child.length>0 && upsell.repeatPolicy==repeatPolicies.allowIndividualDays ? repeatPolicies.allowIndividualDays: 0">
                              <div *ngIf="(upsell.hasChildrenAreSameQuantity && upsell.child.length == getDateDiff(package.startDate,package.endDate,false)) else IndividualUpsell">
                                <div class="d-flex align-items-center">
                                  <div class="col-4 col-md-4 px-1" matTooltip="{{upsell.name}}" matTooltipPosition="below">{{upsell.name.length > 20 ? (upsell.name | slice:0:20) + '...' : upsell.name}}</div>
                                  <div class="col-2 col-md-3 px-1">
                                    <div class="upsell-date">
                                      <span class="upsell-days">
                                        {{ isSameDate(upsell,'item') ? (upsell.startTime | PbCustomDate) :
                                        setDateFormat(upsell.startTime,upsell.endTime)}}
                                      </span>
                                      <span class="upsell-days">
                                        <strong style="font-weight: 600;">{{upsell.child.length > 1 ?
                                          upsell.child.length + ' ' + ('BOOKINGCART.days' | optimotranslate) :
                                          upsell.child.length+ ' ' + ('BOOKINGCART.day' | optimotranslate)}}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="col-2 col-md-2 px-1">{{upsell.quantity}} X {{(upsell.unitPrice*upsell.child.length*upsell.units) | PBCurrencyPipe}}</div>
                                  <div class="col-3 col-md-2 px-1 counter-input">
                                    <opt-pw-number-input
                                      [height]="20"
                                      [(value)]="upsell.quantity"
                                      (valueChange)="onRepeatItemQuantityChange($event, i, upsell)"
                                      *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                                      [disableIncreaseBtnD]="checkIncreaseBtnDisable(package,upsell).increaseBtnDisable"
                                      [max]="checkIncreaseBtnDisable(package,upsell).maximumInput"
                                      [min]="1">
                                    </opt-pw-number-input>
                                  </div>
                                  <div class="col-1 col-md-1 px-1 trash-icon">
                                    <img *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                                      src="{{imagePath}}/assets/images/publicportal/trash.svg" (click)="$event.stopPropagation();deleteItems(upsell, package)">
                                  </div>
                                </div>
                              </div>
                              <ng-template #IndividualUpsell>
                                <mat-expansion-panel class="mat-elevation-z0 cart-expansion-panel col-12 p-0" *ngIf="(enableUpsellConfiguration && editBookingButtonConfiguration.addOns.visible)">
                                  <mat-expansion-panel-header style="padding-left: 0px; border: 0.5px solid #e6e6e6!important;">
                                    <mat-panel-title>
                                      <div class="d-flex flex-row align-items-center" style="justify-content: space-between; width: 100%;">
                                        <div class="col-4 col-md-4 px-1" matTooltip="{{upsell.name}}" matTooltipPosition="below">{{upsell.name.length > 20 ? (upsell.name | slice:0:20) + '...' : upsell.name}}</div>
                                        <div class="col-2 col-md-3 px-1">
                                          <div class="upsell-date">
                                            <span class="upsell-days">
                                              {{ isSameDate(upsell,'item') ? (upsell.startTime | PbCustomDate) :
                                              setDateFormat(upsell.startTime,upsell.endTime)}}
                                            </span>
                                            <span class="upsell-days">
                                              <strong style="font-weight: 600;">{{upsell.child.length > 1 ?
                                                upsell.child.length + ' ' + ('BOOKINGCART.days' | optimotranslate) :
                                                upsell.child.length+ ' ' + ('BOOKINGCART.day' | optimotranslate)}}</strong>
                                            </span>
                                          </div>
                                        </div>
                                        <div class="col-2 col-md-2 px-1">{{ getRepeatDisplayPolicyChildTotal(upsell,package) | PBCurrencyPipe}}</div>
                                        <div class="col-3 col-md-2 px-1 counter-input">
                                        </div>
                                        <div class="col-1 col-md-1 px-1 trash-icon">
                                          <img *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                                            src="{{imagePath}}/assets/images/publicportal/trash.svg" (click)="$event.stopPropagation();deleteItems(upsell, package)">
                                        </div>
                                      </div>
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <div class="exp-conts" *ngFor="let item of upsell.child">
                                    <div class="d-flex align-items-center">
                                      <div class="col-4 col-md-4 px-1" matTooltip="{{item.name}}" matTooltipPosition="below">{{item.name.length > 20 ? (item.name | slice:0:20) + '...' : item.name}}</div>
                                      <div class="col-2 col-md-3 px-1">
                                        {{ isSameDate(item,'item') ? (item.startTime | PbCustomDate) : setDateFormat(item.startTime,item.endTime)}}
                                      </div>
                                      <div class="col-2 col-md-2 px-1">{{item.quantity}} X {{item.unitPrice | PBCurrencyPipe}}</div>
                                      <div class="col-3 col-md-2 px-1 counter-input">
                                        <opt-pw-number-input
                                          [height]="20"
                                          [(value)]="item.quantity"
                                          (valueChange)="onItemQuantityChange($event, i)"
                                          *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                                          [disableIncreaseBtnD]="checkIncreaseBtnDisable(package,item).increaseBtnDisable"
                                          [max]="checkIncreaseBtnDisable(package,item).maximumInput"
                                          [min]="1">
                                        </opt-pw-number-input>
                                      </div>
                                      <div class="col-1 col-md-1 px-1 trash-icon">
                                        <img *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                                          src="{{imagePath}}/assets/images/publicportal/trash.svg" (click)="$event.stopPropagation();deleteItems(item, bookingPackage)">
                                      </div>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </ng-template>
                            </div>
                            <div *ngSwitchCase="repeatPolicies.repeatAllDays">
                              <div class="d-flex align-items-center">
                                <div class="col-4 col-md-4 px-1" matTooltip="{{upsell.name}}" matTooltipPosition="below">{{upsell.name.length > 20 ? (upsell.name | slice:0:20) + '...' : upsell.name}}</div>
                                <div class="col-2 col-md-3 px-1">
                                  {{ isSameDate(upsell,'item') ? (upsell.startTime | PbCustomDate) : setDateFormat(upsell.startTime,upsell.endTime)}}
                                </div>
                                <div class="col-2 col-md-2 px-1">{{upsell.quantity}} X {{(upsell.unitPrice*upsell.units) | PBCurrencyPipe}}</div>
                                <div class="col-3 col-md-2 px-1 counter-input">
                                  <opt-pw-number-input
                                    [height]="20"
                                    [(value)]="upsell.quantity"
                                    (valueChange)="onItemQuantityChange($event, i)"
                                    *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                                    [disableIncreaseBtnD]="checkIncreaseBtnDisable(package,upsell).increaseBtnDisable"
                                    [max]="checkIncreaseBtnDisable(package,upsell).maximumInput"
                                    [min]="1">
                                  </opt-pw-number-input>
                                </div>
                                <div class="col-1 col-md-1 px-1 trash-icon">
                                  <img *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                                    src="{{imagePath}}/assets/images/publicportal/trash.svg" (click)="$event.stopPropagation();deleteItems(upsell, package)">
                                </div>
                              </div>
                            </div>
                            <div *ngSwitchDefault>
                              <div class="d-flex align-items-center">
                                <div class="col-4 col-md-4 px-1" matTooltip="{{upsell.name}}" matTooltipPosition="below">{{upsell.name.length > 20 ? (upsell.name | slice:0:20) + '...' : upsell.name}}</div>
                                <div class="col-2 col-md-3 px-1">
                                  {{ isSameDate(upsell,'item') ? (upsell.startTime | PbCustomDate) : setDateFormat(upsell.startTime,upsell.endTime)}}
                                </div>
                                <div class="col-2 col-md-2 px-1">{{upsell.quantity}} X {{(upsell.unitPrice*upsell.units) | PBCurrencyPipe}}</div>
                                <div class="col-3 col-md-2 px-1 counter-input">
                                  <opt-pw-number-input
                                    [height]="20"
                                    [(value)]="upsell.quantity"
                                    (valueChange)="onItemQuantityChange($event, i)"
                                    *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                                    [disableIncreaseBtnD]="checkIncreaseBtnDisable(package,upsell).increaseBtnDisable"
                                    [max]="checkIncreaseBtnDisable(package,upsell).maximumInput"
                                    [min]="1">
                                  </opt-pw-number-input>
                                </div>
                                <div class="col-1 col-md-1 px-1 trash-icon">
                                  <img *ngIf="cartType === BOOKING_CART.NEW_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible"
                                    src="{{imagePath}}/assets/images/publicportal/trash.svg" (click)="$event.stopPropagation();deleteItems(upsell, package)">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="exp-cont">
                          <div class="d-flex">
                            <div class="col-3">Lunch Reserve</div>
                            <div class="col-2">01-Apr-2024</div>
                            <div class="col-3 counter-input">
                              <opt-pw-number-input>
                              </opt-pw-number-input>
                            </div>
                            <div class="col-3">1 × €600.00</div>
                            <div class="col-1">
                              <img src="{{imagePath}}/assets/images/publicportal/trash.svg">
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <ng-template #noUpSell>
                        <div class="else_block">
                          <p class="text-center else_block_txt">{{'BOOKINGCART.There are no items in this cart' | optimotranslate}}</p>
                        </div>
                      </ng-template>
                    </mat-expansion-panel>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="delivery_card" *ngIf="noOfSeats > 0">
            <div class="delivery_icon">
              <img src="../dist/assets/images/tickets-img.png">
            </div>

            <div class="delivery_content">
              <div *ngIf="(deliveryMethods?.length > 0) else noDeliveryMethods">
                <p class="title">{{'BOOKINGCART.Delivery'| optimotranslate }}</p>
                <mat-select class="delivery_options max-w-200" [disabled]="cartType === BOOKING_CART.EDIT_BOOKING && !editBookingButtonConfiguration.editBookingButton.visible" [(ngModel)]="selectedDeliveryMethodID" (ngModelChange)="deliveryMethodChange()">
                  <mat-option *ngFor="let deliveryMethod of deliveryMethods" [value]="deliveryMethod.id">
                    {{ deliveryMethod.name }}
                  </mat-option>
                </mat-select>
              </div>
              <ng-template #noDeliveryMethods>
                <p class="no_delivery_options_content">
                  <b>*</b>{{ (bookingDisplayCart && bookingDisplayCart.length>1) ? ("BOOKINGCART.Enter a package into separate Bookings" | optimotranslate) : ("BOOKINGCART.No Delivery Methods" | optimotranslate)}}
                </p>
              </ng-template>
              <div class="d-flex align-items-center">
                <p class="mail" *ngIf="!editEmail">{{shippingMailAddress}}</p>
                <mat-icon class="edit-icon ml-2"
                  *ngIf="isLoggedIn && ((cartType === BOOKING_CART.EDIT_BOOKING && editBookingButtonConfiguration.editDeliveryEmailEditBooking.visible) ||
                              (cartType === BOOKING_CART.NEW_BOOKING && editBookingButtonConfiguration.editDeliveryEmailNewBooking.visible))"
                  (click)="editEmail = !editEmail" style="display:block" matTooltip="{{'BOOKINGCART.Edit' | optimotranslate}}"
                  matTooltipPosition="below">create</mat-icon>
              </div>
              <div *ngIf="editEmail" class="d-flex align-items-center">
                <form [formGroup]="emailForm">
                  <input class="input-box" type="email" id="email" [(ngModel)]="shippingMailAddress" formControlName="email" >
                </form>
                <button class="cancel-save-button" (click)="onSubmit(); editEmail = !editEmail" [disabled]="hasEmailError || shippingMailAddress == ''">
                  <mat-icon class="cancel-save-icon"
                    matTooltip="{{'BOOKINGCART.Save' | optimotranslate}}" matTooltipPosition="below">
                    check_circle_outline
                  </mat-icon>
                </button>
                <button class="cancel-save-button" (click)="close(); editEmail = !editEmail">
                  <mat-icon class="cancel-save-icon"
                    matTooltip="{{'BOOKINGCART.Cancel' | optimotranslate}}" matTooltipPosition="below">
                    highlight_off
                  </mat-icon>
                </button>
              </div>
              <mat-error *ngIf="(shippingMailAddress == '' && editEmail)">
                {{ 'CHECKOUTINFO.Email is required' | optimotranslate }}
              </mat-error>
              <mat-error *ngIf="(hasEmailError && editEmail && (shippingMailAddress != ''))">
                {{'BOOKINGCART.Invalid email format' | optimotranslate}}
              </mat-error>


            </div>
          </div>
          <div class="card-disable" *ngIf="enableSpinner">
            <div id="patchPreviewSpinner"></div>
          </div>
        </div>
        <ng-template #elseBlock>
          <div class="else_block" *ngIf="!isLoading">
            <p class="text-center else_block_txt">{{'BOOKINGCART.There are no items in this cart' | optimotranslate}}</p>
          </div>
        </ng-template>
      </div>
      <div class="col-sm-4 right_side mb-3 mb-md-0">
        <div class="total_card" *ngIf="noOfSeats > 0">
          <div class="total">
            <div>
              <p class="title">{{'BOOKINGCART.Total'| optimotranslate}}</p>
              <p class="seat_count">({{noOfSeats}} {{noOfSeats > 1
                ? ('BOOKINGCART.Seats' | optimotranslate)
                : ('BOOKINGCART.Seat' | optimotranslate) }}
                {{ "BOOKINGCART.selected" | optimotranslate }})
              </p>
            </div>
            <p  class="total_amount">{{ booking?.grossAmount | PBCurrencyPipe }}</p>
            <!-- <p *ngIf="cartType === BOOKING_CART.EDIT_BOOKING" class="total_amount">{{ getFreezedPackageTotalPrice(bookingPackage.bookingPackageId) | PBCurrencyPipe }}</p> -->
          </div>

          <div class="col-12 p-0" *ngIf="getIsHaveToPayOrRefund">
            <div class="payment-history">
              <div class="previous-total" *ngIf="canEditBookingCart">
                {{'BOOKINGCART.Previous Total' | optimotranslate}}: <span>{{ getFreezedBookingTotal |PBCurrencyPipe
                  }}</span>
              </div>
              <div class="updated-total" *ngIf="canEditBookingCart">
                {{'BOOKINGCART.Updated Total' | optimotranslate}}: <span>{{getUpdatedBookingTotal| PBCurrencyPipe}}</span>
              </div>
              <div class="total_amount" *ngIf="booking?.grossAmount != getDueAmount">
                {{'BOOKINGCART.Due Amount' | optimotranslate}}: <span>{{ getDueAmount | PBCurrencyPipe}}</span>
              </div>
            </div>
          </div>
          <button (click)="onCheckout()" [disabled]="hasEmailError" *ngIf="(booking?.invoicedAmount == 0 && booking?.grossAmount != 0) && ((countPendingInvoices > 0 || isPaymentEnabled) && PaymentPortalUrl  && !(deliveryMethods && deliveryMethods.length==0)) " class="pay_btn">{{'BOOKINGCART.Confirm & Pay'| optimotranslate }}</button>
        </div>

        <div class="guide_card" *ngIf="noOfSeats > 0">
          <h5 class="title">{{'BOOKINGCART.You also can'| optimotranslate }}</h5>
          <div class="guide" *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && editBookingButtonConfiguration.specialNoteAreaEditBooking.visible) ||
          (cartType === BOOKING_CART.NEW_BOOKING && editBookingButtonConfiguration.specialNoteAreaNewBooking.visible)">
            <div class="guide_icon">
              <img src="../dist/assets/images/specialRequest.png" />
            </div>
            <h6 class="heading" (click)="addSpecialRequest()">{{'BOOKINGCART.Add special request'| optimotranslate }}</h6>
          </div>
          <div class="guide" *ngIf="editBookingButtonConfiguration.questionnaire.visible">
            <div class="guide_icon">
              <i class="material-icons">help_outlined</i>
            </div>
            <div>
              <h6 class="heading">Answer questions</h6>
              <p class="hint">(1 question remaining)</p>
            </div>
          </div>
          <div class="guide" *ngIf="(enableUpsellConfiguration && editBookingButtonConfiguration.commonAddOns.visible)">
            <div class="guide_icon">
              <img src="{{imagePath}}/assets/images/publicportal/file-lg.svg" />
            </div>
            <h6 class="heading">Add extras </h6>
          </div>

          <div class="guide" *ngIf="getGlobalEditSeatEnable() &&
          ((cartType === BOOKING_CART.EDIT_BOOKING && editBookingButtonConfiguration.changeYourSeatsEditBooking.visible) ||
          (cartType === BOOKING_CART.NEW_BOOKING && editBookingButtonConfiguration.changeYourSeatsNewBooking.visible))">
            <div class="guide_icon">
              <img src="{{imagePath}}/assets/images/publicportal/Seat-lg.svg" />
            </div>
            <h6 class="heading" (click)="changeSeats()">{{'BOOKINGCART.Edit seats' | optimotranslate }}</h6>
          </div>

          <div class="guide" *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && editBookingButtonConfiguration.continueShoppingEditBooking.visible) ||
                                    (cartType === BOOKING_CART.NEW_BOOKING && editBookingButtonConfiguration.continueShoppingNewBooking.visible)">
            <div class="guide_icon">
              <img src="../dist/assets/images/calendar.png" />
            </div>
            <h6 class="heading" (click)="continueShopping()">{{'BOOKINGCART.Continue shopping'| optimotranslate }}</h6>
          </div>

          <div class="guide" *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && editBookingButtonConfiguration.requestChangeEditBooking.visible) ||
                                    (cartType === BOOKING_CART.NEW_BOOKING && editBookingButtonConfiguration.requestChangeNewBooking.visible)">
            <div class="guide_icon">
              <img src="../dist/assets/images/img/cart/phone.png" />
            </div>
            <h6 class="heading underline-none">{{'BOOKINGCART.Request Change'| optimotranslate }}<br>
              <a [href]="'tel:' + requestChangeContactNumber" class="txt-primary-color">{{requestChangeContactNumber}}</a></h6>
          </div>

          <div class="guide" *ngIf="editBookingButtonConfiguration.hostGuest.visible">
            <div class="guide_icon">
              <img src="../dist/assets/images/more-people.png" />
            </div>
            <h6 class="heading">Invite participants</h6>
          </div>
          <div class="guide" *ngIf="editBookingButtonConfiguration.redeemArea.visible">
            <div class="guide_icon">
              <img src="../dist/assets/images/voucher.png" />
            </div>
            <h6 class="heading">Redeem a voucher</h6>
          </div>
        </div>
        <div class="guide_card" *ngIf="noOfSeats == 0">
          <div class="guide">
            <div class="guide_icon">
              <img src="../dist/assets/images/calendar.png" />
            </div>
            <h6 class="heading" (click)="continueShopping()">{{'BOOKINGCART.Continue shopping'| optimotranslate }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!enableNewDesignForBookingCartPage">
  <div class="body-wrapper public-booking-cart">
    <img src="{{imageSource}}" class="bg-img cart-bg-img">
    <opt-nav-bar-layout [havePendingChanges]="isAnyPendingChanges"
                        [bookingDisplayCart]="bookingDisplayCart"
                        [isClearCart]="false">
    </opt-nav-bar-layout>
    <div class="modal fade" id="cancelBookingModal" tabindex="-1" role="dialog" aria-labelledby="cancelBookingModalLabel"
      aria-hidden="true" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="border:unset !important;">
          <div class="mmc-modal-wrapper cancel-booking-modal2">
            <div class="mmc_flex--container mmc-modal-header header-primary p-4" style="background:#d7c378;">
              <div class="mmc_flex__item mmc_flex__item--vcenter" style="margin-left: -4px;">
                <span class="heading" style="color: #ffffff;font-size: 20px;font-weight: bolder;">
                  {{cartType==BOOKING_CART.NEW_BOOKING ? ('BOOKINGCART.Clear your Cart'| optimotranslate) : ('BOOKINGCART.Cancel
                  Booking' | optimotranslate)}}</span>
              </div>
              <div class="close-btn" style="align-items:center;display:flex;">
                <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;"
                  (click)="enableCustomCancelMessage=false;isCancelModalOpen=false;" type="submit" data-dismiss="modal">
                  close</mat-icon>
              </div>
            </div>
            <div class="mmc-modal-body">
              <div class="mmc_flex--container inner" *ngIf="cartType==BOOKING_CART.EDIT_BOOKING">
                <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" *ngIf="!enableCustomCancelMessage">
                  {{'BOOKINGCART.A payment has already been made for this booking, Are you sure want to cancel this
                  booking?' |
                  optimotranslate}}
                </div>
                <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" *ngIf="enableCustomCancelMessage">
                  {{'BOOKINGCART.If you remove all items from your booking, it will be cancelled. Are you sure you want to
                  proceed?' |
                  optimotranslate}}
                </div>
                <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2">
                  {{'BOOKINGCART.The cancellation fee will be' | optimotranslate }} <span> &nbsp;{{cancellationFee |
                    PBCurrencyPipe
                    }}</span>
                </div>
                <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2 tnc-para">
                  {{'BOOKINGCART.Any refunds will be based on the' | optimotranslate}}&nbsp; <a> {{'BOOKINGCART.Terms &
                    Conditions' | optimotranslate}}</a>
                </div>
                <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2 tnc-para">
                  <mat-radio-group aria-labelledby="cancel-radio-group-label" class="cancel-radio-group"
                    [(ngModel)]="selectedCancellation">
                    <mat-radio-button class="cancel-radio-button" *ngFor="let reason of cancellationReason"
                      [value]="reason">
                      {{reason.name}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2 tnc-para">
                  <div class="form-group" style="width: 100%;">
                    <label for="customCancellationReason">{{'BOOKINGCART.Any suggestions you may have? (Optional)' |
                      optimotranslate}}</label>
                    <textarea type="email" class="form-control" rows="1" id="customCancellationReason"
                      [(ngModel)]="customCancellationReason" style="min-width: 100%;"></textarea>
                  </div>
                </div>

              </div>
              <div class="mmc_flex--container inner" *ngIf="cartType==BOOKING_CART.NEW_BOOKING">
                <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" *ngIf="enableCustomCancelMessage">
                  {{'BOOKINGCART.If you remove all items from the booking, it will be cancelled. Are you sure you want to
                  proceed?' |
                  optimotranslate}}
                </div>
                <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-b2" *ngIf="!enableCustomCancelMessage">
                  {{'BOOKINGCART.Are you sure want to cancel this booking?' | optimotranslate}}
                </div>
              </div>

              <div class="cancel-booking-btn-wrapper">
                <button type="submit" mat-button
                  class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter cancel-booking-btn"
                  data-dismiss="modal" (click)="cancelBooking(cartType)"
                  [disabled]="!selectedCancellation && cartType === BOOKING_CART.EDIT_BOOKING">
                  {{'BOOKINGCART.Yes, cancel this booking'| optimotranslate}}
                </button>
                <button type="submit" mat-button class="mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter close-btn"
                  data-dismiss="modal" (click)="enableCustomCancelMessage=false;isCancelModalOpen=false;">
                  {{"BOOKINGCART.No, I don't want to cancel" | optimotranslate}}
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="newBookingConformationModal" tabindex="-2" role="dialog"
      aria-labelledby="newBookingConformationModalLabel" aria-hidden="true"  *ngIf="booking">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="border:none;">
            <div class="mmc-modal-wrapper cancel-booking-modal2 navigation-modal" style="border:unset !important;">
              <div class="mmc_flex--container mmc-modal-header header-primary p-4" style="background:#00507c;">
                <div class="mmc_flex__item mmc_flex__item--vcenter" style="margin-left: -4px;">
                  <span class="heading" style="color: #ffffff;font-size: 20px;font-weight: bolder;">
                    {{'BOOKINGCART.Navigation Confirmation' | optimotranslate}}</span>
                </div>
                <div class="close-btn" style="align-items:center;display:flex;">
                  <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;margin-right: -10px;"
                    data-dismiss="modal">close</mat-icon>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pt-5" *ngIf="booking else elseBlock">
      <div class="row">
        <div class="col-md-12 col-lg-9">
          <div class="row mobileView header-row">
            <div class="cartList">
              <div class="row pb-3 pl-3 pr-3 cardList-header">
                <div class="col-6 p-0 d-flex align-items-center">
                  <h3 class="title p-0 text-primary mobile-view">{{'BOOKINGCART.My Booking' | optimotranslate}} -
                    {{booking.bookingReference}}</h3>
                </div>
                <div class="col-6 p-0 text-right" style="display: flex;justify-content: end; height: 40px; right: 5px;">
                  <button *ngIf="cartType === BOOKING_CART.NEW_BOOKING"
                    class="mdc-button mdc-button--outlined bd-highlight editBooking-button w-bg-button edit-booking-btn mr-2"
                    id="clearBasketBtn" data-toggle="modal" (click)="isCancelModalOpen = true"
                    data-target="#cancelBookingModal">
                    {{'BOOKINGCART.Cancel Booking' | optimotranslate}}
                  </button>
                  <ng-container *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                    <button *ngIf="editBookingButtonConfiguration.bookingListButton.visible"
                      class="mdc-button mdc-button--outlined bd-highlight editBooking-button w-bg-button edit-booking-btn mr-2"
                      (click)="gotomyBooking()" data-toggle="modal"
                      matTooltip="{{'BOOKINGCART.Booking List' | optimotranslate}}" matTooltipPosition="above">
                      <i aria-hidden="true" class="fa fa-list-ul mdc-font"></i>
                    </button>
                    <button *ngIf="editBookingButtonConfiguration.addNewBookingButton.visible && PaymentPortalUrl && canAccess"
                      class="mdc-button mdc-button--outlined bd-highlight editBooking-button w-bg-button edit-booking-btn mr-2"
                      (click)="addnewBooking()" data-toggle="modal" data-target="#newBookingConformationModal"
                      matTooltip="{{'BOOKINGCART.Add New Booking' | optimotranslate}}" matTooltipPosition="above">
                      <!-- <mat-icon class="mdc-font">calendar_add_on</mat-icon> -->
                      <i aria-hidden="true" class="fa fa-calendar-plus-o mdc-font"></i>
                    </button>
                    <button
                      *ngIf="isBookingEditable && editBookingButtonConfiguration.editBookingButton.visible && PaymentPortalUrl && canAccess"
                      [ngClass]="{'editBooking-button-onSelect': canEditBookingCart === true}" (click)="editBookingCard()"
                      class="mdc-button mdc-button--outlined bd-highlight edit-booking-btn editBooking-button mr-2"
                      matTooltip="{{'BOOKINGCART.Edit Booking' | optimotranslate}}" matTooltipPosition="above">
                      <!-- <mat-icon class="mdc-font">edit</mat-icon> -->
                      <i aria-hidden="true" class="fa fa-pencil mdc-font"></i>
                    </button>
                    <button *ngIf="isBookingEditable && editBookingButtonConfiguration.deleteBookingButton.visible && canAccess"
                      class="mdc-button mdc-button--outlined bd-highlight editBooking-button w-bg-button edit-booking-btn mr-2"
                      (click)="getCancellationFeeAndReasons();enableCustomCancelMessage=false"
                      matTooltip="{{'BOOKINGCART.Cancel Booking' | optimotranslate }}" matTooltipPosition="above">
                      <!-- <mat-icon class="mdc-font">delete</mat-icon> -->
                      <i aria-hidden="true" class="fa fa-trash mdc-font"></i>
                    </button>
                    <button style="display: none !important;" *ngIf="isBookingEditable"
                      class="mdc-button mdc-button--outlined bd-highlight editBooking-button w-bg-button edit-booking-btn mr-2"
                      data-toggle="modal" data-target="#cancelBookingModal" id="cancelBookingModalTrigger"
                      matTooltip="{{'BOOKINGCART.Cancel Booking' | optimotranslate }}" matTooltipPosition="above">
                      <i class="fa fa-trash mdc-font" aria-hidden="true"></i>
                    </button>
                  </ng-container>
                </div>
              </div>

              <div class="table-group showInWeb">
                <div class="row no-gutters">
                  <div class="headerCard item-desc booking-header-panel">
                    <div class="cart-header-panel row">
                      <div class="col-lg-3 col-md-12" style="display: flex; justify-content:center;">
                        {{'BOOKINGCART.Description' | optimotranslate}}
                      </div>
                      <div class="col-lg-2" style="display: flex;justify-content:center">
                        {{'BOOKINGCART.Date & Time' | optimotranslate}}
                      </div>

                      <div class="col-lg-2">{{'BOOKINGCART.Unit Price' | optimotranslate}} <br><span *ngIf="!booking.showPriceWithTax" style="font-size: 10px;">({{'BOOKINGCART.excl.' | optimotranslate}} {{ 'BOOKINGCART.' + TaxLabelToDisplayName | optimotranslate }})</span></div>
                      <div class="col-lg-2">{{'BOOKINGCART.Quantity' | optimotranslate}}</div>
                      <div class="col-lg-2">{{'BOOKINGCART.Total' | optimotranslate}} <span *ngIf="!booking.showPriceWithTax" style="font-size: 10px; text-align: center;">({{'BOOKINGCART.excl.' | optimotranslate}} {{ 'BOOKINGCART.' + TaxLabelToDisplayName | optimotranslate }})</span></div>

                    </div>
                  </div>
                </div>

                <!-- -----------------------Mobile-View--------------------------------->

                <div class="showInMobile"   *ngIf="isMobileViewOpen">
                  <div class="mobile-card-wrapper">
                    <div class="mobile-card-tem-list">
                      <mat-accordion>
                        <mat-expansion-panel *ngFor="let bookingPackage of bookingDisplayCart; index as i;first as isFirst" (opened)="panelOpenStateMobile = true"
                          [expanded]="expandedBookingPackageIndex === i" (closed)="panelOpenStateMobile = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <div class="mobile-panel-wrapper">
                                <div class="mobile-description-image">
                                  <div class="dec-loader" *ngIf="!isImgLoaded">
                                    <span id="imageWaitingLoader"></span>
                                  </div>
                                  <img class="dec-image" alt="des-image" [hidden]="!isImgLoaded" (load)="onImgLoad()"
                                    [src]="bookingPackage.image != '' ? bookingPackage.image : '../dist/assets/images/no-image.png'" (error)="onImgError($event)" />
                                </div>
                                <div class="mobile-information-wrapper pb-2">
                                  <div class="mobile-information-title">
                                    {{'BOOKINGCART.Description' | optimotranslate}}:
                                  </div>

                                  <div class="mobile-information-name">
                                    <a class="mobile-information-name package-description"
                                      (click)="openDescriptionModal(bookingPackage,'package')">
                                      {{ bookingPackage.name }}
                                    </a>
                                    <div *ngIf="bookingPackage.isSeatAvailable && bookingPackage.seats && !isEditablePack(bookingPackage.cartPackages[0])">
                                      <div
                                        *ngIf="(bookingPackage.seats.length==1 && !bookingPackage.seats[0].includes(',')) else lengthExceed">
                                        <div>{{bookingPackage.seats[0].slice(0,bookingPackage.seats[0].indexOf('Seats'))}}<br>
                                          {{bookingPackage.seats[0].slice(bookingPackage.seats[0].indexOf('Seats'),bookingPackage.seats[0].length)}}</div>
                                      </div>
                                      <ng-template #lengthExceed>
                                        <mat-icon *ngIf="bookingPackage.seats.length != 0"
                                          (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
                                          [matTooltip]="'View Seat Numbers'">info</mat-icon>
                                      </ng-template>
                                    </div>
                                    <mat-menu #menu="matMenu">
                                      <div *ngFor="let seat of bookingPackage.seats">{{seat}}</div>
                                    </mat-menu>
                                  </div>


                                </div>
                                <div class="mobile-information-wrapper pb-2">
                                  <div class="mobile-information-title">
                                    {{'BOOKINGCART.Date & Time' | optimotranslate}} :
                                  </div>
                                  <div class="mobile-information-name-wrapper">
                                    <span class="mobile-information-sub">
                                      {{ isSameDate(bookingPackage,'package') ? (bookingPackage.startDate | PbCustomDate)
                                      : setDateFormat(bookingPackage.startDate,bookingPackage.endDate) }}
                                    </span>
                                    <span class="mobile-information-sub">
                                      {{ isSameDate(bookingPackage,'package') ? ((bookingPackage.startDate | date: "HH:mm")+' - '+(bookingPackage.endDate | date: "HH:mm"))
                                      : ((bookingPackage.startDate | date: "HH:mm")+' - '+(bookingPackage.endDate | date: "HH:mm")) }}
                                    </span>
                                  </div>
                                </div>
                                <div class="mobile-information-wrapper pb-2">
                                  <div class="mobile-information-title">
                                    Total :
                                  </div>
                                  <div class="mobile-information-name">
                                    <div class="booking-total-price-wrapper">
                                      <div
                                        [ngClass]="booking.cancelled == true ? 'booking-total-price-cancelled' : 'booking-total-price' "
                                        class="edited-item-color"
                                        *ngIf="cartType === BOOKING_CART.EDIT_BOOKING && ((getPackageTotalPrice(bookingPackage.bookingPackageId)| PBCurrencyPipe) != (getFreezedPackageTotalPrice(bookingPackage.bookingPackageId)| PBCurrencyPipe) )">
                                        {{ getPackageTotalPrice(bookingPackage.bookingPackageId) | PBCurrencyPipe }}
                                      </div>
                                      <div
                                        [ngClass]="booking.cancelled == true ? 'booking-total-price-cancelled' : 'booking-total-price'"
                                        [ngStyle]="{'text-decoration':  ((getPackageTotalPrice(bookingPackage.bookingPackageId)| PBCurrencyPipe) != (getFreezedPackageTotalPrice(bookingPackage.bookingPackageId)| PBCurrencyPipe) ) ? 'line-through' : 'none'}"
                                        *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                        {{ getFreezedPackageTotalPrice(bookingPackage.bookingPackageId) |PBCurrencyPipe }}
                                      </div>
                                      <div
                                        [ngClass]="booking.cancelled == true ? 'booking-total-price-cancelled' : 'booking-total-price'"
                                        *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                        <span *ngIf="bookingPackage.cartPackages.length > 1">
                                          {{ getPackageTotalPrice(bookingPackage.bookingPackageId) | PBCurrencyPipe }}
                                        </span>
                                        <span *ngIf="bookingPackage.cartPackages.length == 1">
                                          {{ (booking.showPriceWithTax ? bookingPackage.grossAmount : bookingPackage.netAmount) | PBCurrencyPipe }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mobile-information-icon-wrapper pb-3">
                                  <div class="mobile-information-icon">
                                    <img class="mobile-delete-icon" src="../dist/assets/images/trash.png" alt=""
                                      *ngIf="canEditBookingCart"
                                      (click)="$event.stopPropagation();deletePackages(bookingPackage.id, bookingPackage)" />
                                  </div>
                                  <div class="mobile-information-icon"
                                    *ngIf="bookingPackage.hasQuestionnaire && (!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))"
                                    (click)="$event.stopPropagation();popupQuestionPkg(bookingPackage)">
                                    <img class="mobile-icon" src="../dist/assets/images/help.png" alt="" />
                                  </div>
                                  <button class="mobile-information-icon"
                                    *ngIf="bookingPackage.hasHostGuest && (!editBookingButtonConfiguration.hostGuest || (editBookingButtonConfiguration.hostGuest && editBookingButtonConfiguration.hostGuest.visible))"
                                    (click)="$event.stopPropagation();openPkgAttendeeList(bookingPackage)">
                                    <img class="mobile-icon" src="../dist/assets/images/guests.png" alt="" />
                                  </button>
                                </div>
                              </div>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="mobile-inside-panel mobile-inside-panel-inner "
                            *ngFor="let pkg of bookingPackage.cartPackages;index as x">
                            <div class="mobile-information-wrapper pb-2">
                              <div class="mobile-information-title">
                                {{'BOOKINGCART.Description' | optimotranslate}}:
                              </div>
                              <div class="mobile-card-description-wrapper">
                                <i aria-hidden="true" class="fa fa-gift ng-star-inserted"></i>
                                <div class="mobile-information-name">
                                  <a class="mobile-information-name"
                                    (click)="openDescriptionModal(bookingPackage,'package')" style="font-weight:700;">
                                    {{ pkg.name }}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="mobile-information-wrapper pb-2">
                              <div class="mobile-information-title">
                                {{'Date & Time' | optimotranslate }} :
                              </div>
                              <div class="mobile-information-name">
                                <span class="mobile-information-sub">
                                  {{ isSameDate(bookingPackage,'package') ? (bookingPackage.startDate |
                                  PbCustomDate) : setDateFormat(bookingPackage.startDate,bookingPackage.endDate) }}
                                </span>
                              </div>
                            </div> -->
                            <div class="mobile-information-wrapper pb-2">
                              <div class="mobile-information-title">
                                {{'BOOKINGCART.Unit Price'| optimotranslate}} :
                              </div>
                              <div class="mobile-information-name">
                                {{ pkg.unitPrice | PBCurrencyPipe }}
                              </div>
                            </div>
                            <div class="mobile-information-wrapper pb-2">
                              <div class="mobile-information-title" style="width: 40% !important;">
                                {{'BOOKINGCART.Quantity' | optimotranslate}} :
                                <!-- <span >{{pkg.quantity}}</span> -->
                              </div>
                              <div class="" *ngIf="!canEditBookingCart">
                                {{ pkg.quantity }}
                              </div>
                              <div class="quantity-controls" style="margin:0 !important; padding: 0;" data-quantity=""
                                *ngIf="canEditBookingCart">
                                <div *ngIf="isEditablePack(pkg) else elseMobileQut">
                                  <opt-pw-number-input [reduceButtonTooltip]="reduceButtonTooltip"
                                    [disableReduceBtnD]="disableReduceButton" [(value)]="pkg.quantity" [min]="1"
                                    [isCart]="true" [height]="20" (valueChange)="onItemQuantityChange($event, i)">
                                  </opt-pw-number-input>
                                </div>
                                <ng-template #elseMobileQut>
                                  <div style="display: flex; justify-content: center; font-weight: 600;">
                                    <span style="padding-right: 5px;">{{ pkg.quantity }}</span> <mat-icon class="edit-icon" style="position: relative; top: 3px;">launch</mat-icon><span class="edit-txt" (click)="editTicket(pkg.id)">{{ "Edit" }}</span>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                            <div class="mobile-information-wrapper pb-2">
                              <div class="mobile-information-title">
                                {{'BOOKINGCART.Total' | optimotranslate}} :
                              </div>
                              <div class="booking-total-price-wrapper">
                                <div class="mobile-information-name edited-item-color"
                                  *ngIf="(pkg.quantity * pkg.unitPrice) != getPkgUnitPrice(pkg) && cartType === BOOKING_CART.EDIT_BOOKING">
                                  <strong>{{ (pkg.quantity * pkg.unitPrice) |PBCurrencyPipe }}</strong>
                                </div>
                                <div class="mobile-information-name"
                                  [ngStyle]="{'text-decoration': ((pkg.quantity * pkg.unitPrice) != getPkgUnitPrice(pkg)) ? 'line-through' : 'none'}"
                                  *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                  <strong>{{ getPkgUnitPrice(pkg) | PBCurrencyPipe }}</strong>
                                </div>
                                <div class="mobile-information-name" *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                  <strong>{{ (pkg.quantity * pkg.unitPrice) | PBCurrencyPipe}}</strong>
                                </div>
                              </div>

                            </div>
                            <div class="mobile-information-icon-wrapper pb-3">
                              <div class="mobile-information-icon">
                                <img class="mobile-delete-icon" src="../dist/assets/images/trash.png" alt=""
                                  *ngIf="canEditBookingCart"
                                  (click)="deletePackages(pkg.bookingPackageId, bookingPackage)" />
                              </div>
                              <div class="mobile-information-icon" *ngIf="pkg.hasQuestionnaire"
                                (click)="popupQuestionPkgLevel(pkg,bookingPackage)">
                                <img class="mobile-icon" src="../dist/assets/images/help.png" alt=""
                                  *ngIf="(!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))" />
                              </div>
                            </div>
                          </div>
                          <div *ngFor=" let item of bookingPackage.cartItems; index as y">
                            <div [ngSwitch]="item.repeatPolicy">
                              <div
                                *ngSwitchCase="item.child.length>0 && item.repeatPolicy==repeatPolicies.allowIndividualDays ? repeatPolicies.allowIndividualDays: 0">
                                <mat-accordion>
                                  <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                      <div class="mobile-inside-panel p-0">
                                        <div class="mobile-inside-upsell-panel mobile-inside-upsell-panel-inner">
                                          <div class="mobile-information-upsell-wrapper pb-2">
                                            <div class="mobile-information-title">
                                              {{'BOOKINGCART.Description' | optimotranslate}} :
                                            </div>
                                            <div class="mobile-information-upsell-image-wrapper">
                                              <div class="mobile-upsell-image">
                                                <mat-icon>local_mall</mat-icon>
                                              </div>
                                              <div class="mobile-upsell-image-name item-description"
                                                (click)="openDescriptionModal(item,'upsell')">
                                                {{ item.name }}
                                              </div>
                                            </div>
                                          </div>
                                          <div class="mobile-information-wrapper pb-2">
                                            <div class="mobile-information-title">
                                              {{'BOOKINGCART.Date' | optimotranslate}} :
                                            </div>
                                            <div class="mobile-information-name mobile-information-name-dates">
                                              <span class="mobile-information-sub">
                                                {{ isSameDate(item,'item') ? (item.startTime | PbCustomDate) :
                                                setDateFormat(item.startTime,item.endTime)}}
                                              </span>
                                              <span class="mobile-information-sub">
                                                {{isSameDate(item,'item') ? (item.startTime | date: "HH:mm") :
                                                (item.endTime | date: "HH:mm")+' - '+(item.endTime | date: "HH:mm") }}
                                              </span>
                                              <span class="mobile-information-sub">
                                                {{getDateDiff(item.startTime,item.endTime)}}
                                              </span>
                                              <span class="mobile-information-sub">
                                                {{item.unitPrice |PBCurrencyPipe }} ×
                                                {{getDateDiff(item.startTime,item.endTime)}}
                                              </span>
                                            </div>
                                          </div>
                                          <!-- <div class="mobile-information-wrapper pb-2">
                                            <div class="mobile-information-title">
                                              {{'Unit Price' | optimotranslate}} :
                                            </div>
                                            <div class="mobile-information-name">
                                              {{item.unitPrice/item.units |PBCurrencyPipe }}
                                            </div>
                                          </div> -->
                                          <!-- <div class="mobile-information-wrapper pb-2">
                                            <div class="mobile-information-title" style="width: 43% !important;">
                                              {{'Quantity' | optimotranslate}} : <span *ngIf="!canEditBookingCart">{{item.quantity}}</span>
                                            </div>
                                            <div class="mobile-quantity-controls mb-0" style="
                                                margin-top: unset !important;
                                                padding: 0 !important;
                                                margin-top: -5px !important;
                                              " data-quantity="">
                                              <opt-pw-number-input  [reduceButtonTooltip]="reduceButtonTooltip"  [disableReduceBtnD]="disableReduceButton" *ngIf="canEditBookingCart" [min]="1"
                                                [(value)]="item.quantity" [isCart]="true" [height]="20"
                                                (valueChange)="onItemQuantityChange($event)">
                                              </opt-pw-number-input>
                                            </div>
                                          </div> -->
                                          <div class="mobile-information-wrapper pb-2">
                                            <div class="mobile-information-title" style="width:40%;">
                                              {{'BOOKINGCART.Total' | optimotranslate}} :
                                            </div>
                                            <div class="mobile-information-name">
                                              <div class="booking-total-price-wrapper">
                                                <div class="upsell-booking-child-total-price edited-item-color"
                                                  *ngIf="cartType === BOOKING_CART.EDIT_BOOKING && getRepeatPolicyChildTotal(item,bookingPackage)  != getRepeatDisplayPolicyChildTotal(item,bookingPackage)">
                                                  {{ getRepeatDisplayPolicyChildTotal(item,bookingPackage) |
                                                  PBCurrencyPipe }}
                                                </div>
                                                <div class="upsell-booking-child-total-price"
                                                  [ngStyle]="{'text-decoration': getRepeatPolicyChildTotal(item,bookingPackage) != getRepeatDisplayPolicyChildTotal(item,bookingPackage) ? 'line-through' : 'none'}"
                                                  *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                                  {{ getRepeatPolicyChildTotal(item,bookingPackage) | PBCurrencyPipe }}
                                                </div>
                                                <div class="upsell-booking-child-total-price"
                                                  *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                                  {{ getRepeatDisplayPolicyChildTotal(item,bookingPackage) |
                                                  PBCurrencyPipe}}
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                          <div class="mobile-information-icon-wrapper pb-3">
                                            <div class="mobile-information-icon" *ngIf="canEditBookingCart"
                                              (click)="deleteItems(item, bookingPackage)">
                                              <img class="mobile-delete-icon" src="../dist/assets/images/trash.png"
                                                alt="" />
                                            </div>
                                            <div class="mobile-information-icon" *ngIf="item.hasQuestionnaire"
                                              (click)="popupQuestionItmLevel(item,bookingPackage)">
                                              <img class="mobile-icon" src="../dist/assets/images/help.png" alt=""
                                                *ngIf="item.hasQuestionnaire && (!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))"
                                                (click)="$event.stopPropagation();popupQuestionItmLevel(item,bookingPackage)" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </mat-expansion-panel-header>
                                    <div class="mobile-inside-upsell-panel" *ngFor="let itm of item.child">
                                      <div class="mobile-information-upsell-wrapper pb-2">
                                        <div class="mobile-information-title">
                                          {{'BOOKINGCART.Description'| optimotranslate}} :
                                        </div>
                                        <div class="mobile-information-upsell-image-wrapper">
                                          <div class="mobile-upsell-image">
                                            <mat-icon>local_mall</mat-icon>
                                          </div>
                                          <!-- <div class="mobile-upsell-image-name item-description" (click)="openDescriptionModal(itm,'upsell')"> -->
                                          <div class="mobile-upsell-image-name">
                                            {{ itm.name }}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="mobile-information-wrapper pb-2">
                                        <div class="mobile-information-title">
                                          {{'BOOKINGCART.Date'| optimotranslate}} :
                                        </div>
                                        <div class="mobile-information-name">
                                          <span class="mobile-information-sub">
                                            {{ isSameDate(itm,'item') ? (itm.startTime | PbCustomDate) :
                                            setDateFormat(itm.startTime,itm.endTime)}}
                                          </span>
                                          <span class="mobile-information-sub">
                                            {{isSameDate(itm,'item') ? (itm.startTime | date: "HH:mm") : (itm.endTime |
                                            date: "HH:mm")+' - '+(itm.endTime | date: "HH:mm")}}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="mobile-information-wrapper pb-2">
                                        <div class="mobile-information-title">
                                          {{'BOOKINGCART.Unit Price' | optimotranslate}} :
                                        </div>
                                        <div class="mobile-information-name">
                                          {{itm.unitPrice/itm.units | PBCurrencyPipe }}
                                        </div>

                                      </div>
                                      <div class="mobile-information-wrapper pb-2">
                                        <div class="mobile-information-title">
                                          {{'BOOKINGCART.Quantity' | optimotranslate}} :
                                        </div>
                                        <div class="mobile-information-name" *ngIf="!canEditBookingCart">
                                          {{itm.quantity }}
                                        </div>
                                        <div *ngIf="canEditBookingCart" class="mobile-quantity-controls mb-0" style="
                                            margin-top: unset !important;
                                            padding: 0 !important;
                                            margin-top: -5px !important;
                                          " data-quantity="">
                                          <opt-pw-number-input [reduceButtonTooltip]="reduceButtonTooltip"
                                            [disableReduceBtnD]="disableReduceButton" *ngIf="canEditBookingCart" [min]="1"
                                            [(value)]="itm.quantity" [isCart]="true" [height]="20"
                                            (valueChange)="onItemQuantityChange($event, i)">
                                          </opt-pw-number-input>
                                        </div>
                                      </div>
                                      <div class="mobile-information-wrapper pb-2">
                                        <div class="mobile-information-title">
                                          {{'BOOKINGCART.Total' | optimotranslate}} :
                                        </div>
                                        <div class="booking-total-price-wrapper">
                                          <div class="upsell-booking-total-price edited-item-color"
                                            *ngIf="(itm.quantity * itm.unitPrice) != getItmUnitPrice(itm,bookingPackage.bookingPackageId) && cartType === BOOKING_CART.EDIT_BOOKING">
                                            {{ (itm.quantity * itm.unitPrice) | PBCurrencyPipe }}
                                          </div>
                                          <div class="upsell-booking-total-price"
                                            [ngStyle]="{'text-decoration': ((itm.quantity * itm.unitPrice) != getItmUnitPrice(itm,bookingPackage.bookingPackageId)) ? 'line-through' : 'none'}"
                                            *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                            {{ (getItmUnitPrice(itm,bookingPackage.bookingPackageId)) | PBCurrencyPipe }}
                                          </div>
                                          <div class="upsell-booking-total-price"
                                            *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                            {{ (itm.quantity * itm.unitPrice) | PBCurrencyPipe }}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="mobile-information-icon-wrapper pb-3">
                                        <div class="mobile-information-icon">
                                          <img class="mobile-delete-icon" src="../dist/assets/images/trash.png" alt=""
                                            *ngIf="canEditBookingCart"
                                            (click)="$event.stopPropagation();deleteItems(itm, bookingPackage)" />
                                        </div>
                                        <div class="mobile-information-icon">
                                          <!-- //item -->
                                          <img class="mobile-icon" src="../dist/assets/images/help.png" alt=""
                                            *ngIf="itm.hasQuestionnaire && (!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))"
                                            (click)="$event.stopPropagation();popupQuestionItmLevel(itm,bookingPackage)" />
                                        </div>
                                      </div>
                                    </div>

                                  </mat-expansion-panel>
                                </mat-accordion>
                              </div>
                              <div *ngSwitchDefault>
                                <div class="mobile-inside-upsell-panel">
                                  <div class="mobile-information-upsell-wrapper pb-2">
                                    <div class="mobile-information-title">
                                      {{'BOOKINGCART.Description' | optimotranslate}} :
                                    </div>
                                    <div class="mobile-information-upsell-image-wrapper">
                                      <div class="mobile-upsell-image">
                                        <mat-icon>local_mall</mat-icon>
                                      </div>
                                      <div class="mobile-upsell-image-name item-description"
                                        (click)="openDescriptionModal(item,'upsell')">
                                        {{ item.name }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mobile-information-wrapper pb-2">
                                    <div class="mobile-information-title">
                                      {{'BOOKINGCART.Date' | optimotranslate}} :
                                    </div>
                                    <div class="mobile-information-name">
                                      <span class="mobile-information-sub">
                                        {{ isSameDate(item,'item') ? (item.startTime | PbCustomDate) :
                                        setDateFormat(item.startTime,item.endTime)}}
                                      </span>
                                      <span class="mobile-information-sub">
                                        {{isSameDate(item,'item') ? (item.startTime | date: "HH:mm")
                                        : (item.endTime | date: "HH:mm")+' - '+(item.endTime | date: "HH:mm") }}
                                      </span>
                                    </div>
                                  </div>
                                  <div class="mobile-information-wrapper pb-2">
                                    <div class="mobile-information-title">
                                      {{'BOOKINGCART.Unit Price' | optimotranslate}} :
                                    </div>
                                    <div class="mobile-information-name">
                                      {{item.unitPrice/ item.units |PBCurrencyPipe }}
                                    </div>

                                  </div>
                                  <div class="mobile-information-wrapper pb-2">
                                    <div class="mobile-information-title" style="width: 40% !important;">
                                      {{'BOOKINGCART.Quantity' | optimotranslate}} :
                                    </div>
                                    <div class="" *ngIf="!canEditBookingCart">
                                      {{item.quantity }}
                                    </div>
                                    <div class="mobile-quantity-controls mb-0" *ngIf="canEditBookingCart" style="
                                        margin-top: unset !important;
                                        padding: 0 !important;
                                        margin-top: -5px !important;
                                      " data-quantity="">
                                      <opt-pw-number-input [reduceButtonTooltip]="reduceButtonTooltip"
                                        [disableReduceBtnD]="disableReduceButton" *ngIf="canEditBookingCart" [min]="1"
                                        [(value)]="item.quantity" [isCart]="true" [height]="20"
                                        (valueChange)="onItemQuantityChange($event, i)">
                                      </opt-pw-number-input>
                                    </div>
                                  </div>
                                  <div class="mobile-information-wrapper pb-2">
                                    <div class="mobile-information-title" style="width:40%;">
                                      {{'BOOKINGCART.Total' | optimotranslate}} :
                                    </div>
                                    <div class="booking-total-price-wrapper">
                                      <div class="upsell-booking-total-price edited-item-color"
                                        *ngIf=" (item.quantity * item.unitPrice) != getItmUnitPrice(item,bookingPackage.bookingPackageId) && cartType === BOOKING_CART.EDIT_BOOKING">
                                        {{ (item.quantity * item.unitPrice) | PBCurrencyPipe }}
                                      </div>
                                      <div class="upsell-booking-total-price"
                                        [ngStyle]="{'text-decoration': ((item.quantity * item.unitPrice) != getItmUnitPrice(item,bookingPackage.bookingPackageId)) ? 'line-through' : 'none'}"
                                        *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                        {{ (getItmUnitPrice(item,bookingPackage.bookingPackageId)) | PBCurrencyPipe }}
                                      </div>
                                      <div class="upsell-booking-total-price"
                                        *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                        {{ item.quantity * item.unitPrice | PBCurrencyPipe }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mobile-information-icon-wrapper pb-3">
                                    <div class="mobile-information-icon">
                                      <img class="mobile-delete-icon" src="../dist/assets/images/trash.png" alt=""
                                        *ngIf="canEditBookingCart"
                                        (click)="$event.stopPropagation();deleteItems(itm, bookingPackage)" />
                                    </div>
                                    <div class="mobile-information-icon">
                                      <img class="mobile-icon" src="../dist/assets/images/help.png" alt=""
                                        *ngIf="item.hasQuestionnaire && (!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))"
                                        (click)="$event.stopPropagation();popupQuestionItmLevel(item,bookingPackage)" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mobile-extra-row pl-4 add-extra-row ">
                            <div style="display: flex;">
                              <div class="add-extra" (click)="addAddonsForBookingPackage(bookingPackage)"
                                *ngIf="(cartType !== BOOKING_CART.EDIT_BOOKING || (cartType === BOOKING_CART.EDIT_BOOKING && canEditBookingCart )) && (enableUpsellConfiguration && editBookingButtonConfiguration.addOns.visible && bookingPackage.isShowAddExtra)">
                                <i class="bi bi-plus-circle-fill"></i> {{'BOOKINGCART.Add Extras' | optimotranslate}}
                              </div>
                            </div>
                            <!-- According to the pagination BRD, we drop the 'Apply Changes' and 'Discard Changes'-->
                            <!-- <div class="apply-changes-card-wrapper"
                              *ngIf="cartType === BOOKING_CART.EDIT_BOOKING && canEditBookingCart && getPackageChangesForEditBooking(bookingPackage) !== 0">
                              <div class="number-of-changes">
                                {{getPackageChangesForEditBooking(bookingPackage)==1 ?
                                getPackageChangesForEditBooking(bookingPackage)+" Pending Change" :
                                getPackageChangesForEditBooking(bookingPackage)+" Pending Changes"}}
                              </div>
                              <div class="apply-cancel">
                                <div class="apply-changes-btn-wrapper">
                                  <button mat-button class="apply-changes-btn" (click)="applyEditChange(bookingPackage)"
                                    aria-label="Show an example snack-bar">{{'BOOKINGCART.Apply Changes' |
                                    optimotranslate}}</button>
                                </div>
                                <div class="cancel-changes-btn-wrapper">
                                  <button mat-button class="cancel-changes-btn"
                                    (click)="cancelChanges(bookingPackage)">{{'BOOKINGCART.Cancel Changes' |
                                    optimotranslate}}</button>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                    <!-- Add New Package -->
                    <div class="pt-2" *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && canEditBookingCart )">
                      <mat-card class="add-new-package">
                        <div class="d-flex" style="justify-content: space-between;">
                          <div class="d-flex add-new-package-name">
                            <div class="add-new-package-icon">
                              <i class="fa fa-gift" aria-hidden="true"></i>
                            </div>
                            <div class="add-new-package-text">
                              {{'BOOKINGCART.Add New Package' | optimotranslate}}
                            </div>
                          </div>
                          <div class="add-new-package-btn" (click)="continueShopping()">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                    <!-- End Add New Package -->
                    <div class="b-search-pagi search-pagi" [hidden]="!paginationSetting.isEnable || (bookingPackagesLength <= paginationSetting.minimumRequiredPageSize)"
                      matTooltip="You have the pending changes. Please save or discard it." [matTooltipDisabled]="!pendingPatch" matTooltipPosition="above">
                      <mat-paginator #BookingPaginator
                                     [length]="bookingPackagesLength"
                                     [pageSize]="pageSize"
                                     [disabled]="pendingPatch"
                                     [pageSizeOptions]="getPageSizeOptions()"
                                     aria-label="Select page"
                                     [pageIndex]="pageIndex"
                                     (page)="pageClickEvent($event)"
                                     class="booking-cart-paginator">
                      </mat-paginator>
                    </div>

                    <div class="delivery-method-item pt-2" *ngIf="displayDeliveryMethodList">
                      <mat-accordion>
                        <mat-expansion-panel (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title class="mobile-delivery-method">
                              <div class="delivery-card-wrapper d-flex align-items-center custom-title-wrapper">
                                <div class="delivery-card-icon">
                                  <img class="delivery-icon" src="../dist/assets/images/noun_delivery_4011143.png"
                                    alt="delivery-icon" />
                                </div>
                                <div class="delivery-card-name">
                                  {{selectedDeliveryMethod ? selectedDeliveryMethod.name : 'No Delivery Method' |
                                  optimotranslate}}:
                                </div>
                              </div>
                              <div class="title-row-wrapper" *ngIf="selectedDeliveryMethod">
                                <div class="despatched-post">
                                </div>
                                <div class="despatched-price">
                                  {{(selectedDeliveryMethod && booking && !booking.cancelled) ?
                                  (selectedDeliveryMethod.price | PBCurrencyPipe ) : 0.00 | PBCurrencyPipe}}
                                </div>
                              </div>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="mobile-inside-delivery">
                            <div class="first-section">
                              <div class="mobile-despatch-wrapper pb-2">
                                <div class="dispatch-title-wrapper" *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                  <div class="mobile-indside-delivery-title">
                                    {{'BOOKINGCART.Despatch Status' | optimotranslate}} :
                                  </div>
                                  <div class="mobile-despatch-name" (click)="openDispatchTable()">
                                    {{dispatchStatus && dispatchStatus.dispatchable ? ('BOOKINGCART.Dispatch Status' |
                                    optimotranslate) : ('BOOKINGCART.Not Applicable' | optimotranslate)}}
                                  </div>
                                </div>
                                <div class="mobile-second-title pb-2">
                                  {{'BOOKINGCART.Select a Different Delivery Method' | optimotranslate}}
                                </div>
                                <div class="mobile-delivery-table-wrapper pb-2">
                                  <div class="mobile-delivery-table-title">
                                    {{'BOOKINGCART.Delivery Method' | optimotranslate}}
                                  </div>
                                  <div class="mobile-delivery-table-title">
                                    {{'BOOKINGCART.Price' | optimotranslate}}
                                  </div>
                                </div>
                                <div class="mobile-delivery-radio-wrapper">
                                  <div class="inside-delivery-method-disable" *ngIf="enableDeliveryMethodSpinner">
                                    <div id="deliveryMethodPatchPreviewSpinner"></div>
                                  </div>
                                  <div class="mobile-delivery-radio-btn">
                                    <mat-radio-group aria-label="Select an option"
                                      [disabled]="BOOKING_CART.NEW_BOOKING || (!canEditBookingCart && BOOKING_CART.EDIT_BOOKING)"
                                      [(ngModel)]="selectedDeliveryMethod" (ngModelChange)="deliveryMethodChange()">
                                      <div *ngFor="let deliveryMethod of deliveryMethods">
                                        <mat-radio-button [value]="deliveryMethod">{{deliveryMethod.name}}
                                        </mat-radio-button>
                                      </div>
                                    </mat-radio-group>
                                  </div>
                                  <div class="mobile-delivery-radio-price">
                                    <div class="mobile-radio-price" *ngFor="let deliveryMethod of deliveryMethods">
                                      {{deliveryMethod.price | PBCurrencyPipe }}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                    <!-- show Invoices and Documents -->
                    <div class="delivery-method-item pt-2" *ngIf="((bookingInvoices && bookingInvoices.length > 0) || (bookingDocuments && bookingDocuments.length > 0))">
                      <mat-accordion>
                        <mat-expansion-panel (opened)="invoicePanelOpenState = true" (closed)="invoicePanelOpenState = false">
                          <mat-expansion-panel-header>
                            <mat-panel-title class="mobile-delivery-method">
                              <div class="delivery-card-wrapper d-flex align-items-center custom-title-wrapper">
                                <div class="delivery-card-icon">
                                  <img class="document-icon" src="../dist/assets/images/file.png"
                                    alt="delivery-icon" />
                                </div>
                                <div class="delivery-card-name">
                                  {{'BOOKINGCART.Invoices and Documents' | optimotranslate}}
                                </div>
                              </div>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="mobile-inside-delivery">
                            <div class="row">
                              <!-- display the documents -->
                              <div class="col-md-12 invoice-row-space" *ngIf="bookingDocuments && bookingDocuments.length > 0">
                                <div class="radio-btn-wrapper">
                                  <div class="mmc_expansion__body_document--row my-bookings-expansion-body" *ngFor="let doc of bookingDocuments">
                                    <div class="doc-image"><img class="doc-img" src="../dist/assets/images/doc.png" alt="Documents"></div>
                                    <div class="doc-name pw-txt-s1 pw-text-color__tertiary">
                                      <a (click)="onDownloadDocument(doc)">
                                        {{ doc.name }}
                                      </a>
                                    </div>
                                    <div class="doc-date pw-txt-s1"><span>{{ doc?.auditInfo?.insertedTime | PbCustomDate:'dd MMM Y' }}</span></div>
                                    <div class="doc-cell"></div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 invoice-row-space" *ngIf="bookingInvoices && bookingInvoices.length > 0">
                                <div class="radio-btn-wrapper">
                                  <div class="mmc_expansion__body--row my-bookings-expansion-body" *ngFor="let inv of bookingInvoices">
                                        <div class="invoice-detail-cell">
                                            <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Invoice'| optimotranslate}}</div>
                                            <div class="invoice-cell">
                                              <div class="link-primary" (click)="onInvoiceView(inv)" (keydown.enter)="onInvoiceView(inv)">{{inv.InvoiceRef}}</div>
                                              <div class="status"   [ngClass]="inv.Status=='Unpaid' ? 'unpaid': 'paid'">{{inv.Cancelled ? ('INVOICEINFO.Cancelled' | optimotranslate | uppercase) : ('INVOICEINFO.' + inv.Status | optimotranslate | uppercase )}}</div>
                                            </div>
                                        </div>
                                        <div class="invoice-detail-cell">
                                            <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Due Date' | optimotranslate}}</div>
                                            <div class="pw-txt-s2 invoice-cell">{{inv.DueDate | PbCustomDate:'dd MMM Y'}}</div>
                                        </div>
                                        <div class="invoice-detail-cell">
                                            <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Invoice Amount' | optimotranslate}}</div>
                                            <div class="pw-txt-s2 invoice-cell inv-amount">{{inv.InvoiceAmount | CurrencyFormat}}</div>
                                        </div>
                                        <div class="invoice-detail-cell">
                                            <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Outstanding' | optimotranslate}}</div>
                                            <div class="pw-txt-s2 invoice-cell b-outstanding">{{inv.Outstanding | CurrencyFormat}}</div>
                                        </div>
                                        <div class="invoice-detail-cell">
                                            <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Date Paid' | optimotranslate}}</div>
                                            <div *ngIf="inv.DatePaid; else emptyDate" class="pw-txt-s2 invoice-cell">{{inv.DatePaid | PbCustomDate:'dd MMM Y'}}</div>
                                            <ng-template #emptyDate>
                                              <div class="pw-txt-s2 invoice-cell" style="font-size: 30px !important;">&#8722;</div>
                                            </ng-template>
                                        </div>
                                        <!-- <div class="invoice-detail-cell">
                                            <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'MYBOOKING.Action' | optimotranslate}}</div>
                                            <div class="btn-wrapper invoice-cell">
                                                <button type="button" mat-button class="mmc-btn-secondary--small main mmc_flex__item--vcenter" role="button" [ngClass]="inv.Outstanding<=0 || inv.Cancelled || bk.Cancelled? 'dissabled':''" [disabled]="inv.Outstanding<=0 ||inv.Cancelled || bk.Cancelled" *ngIf="PaymentPortalUrl"  (click)="RedirectToPayment(inv.InvoiceRef)" (keydown.enter)="RedirectToPayment(inv.InvoiceRef)" style="width: 75% !important; height: 25px !important;">{{"MYBOOKING.Pay Now" | optimotranslate }}</button>
                                            </div>
                                        </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </div>

                <div class="card-wrapper showInWeb2" *ngIf="!isMobileViewOpen">
                  <div class="patch-preview-validation-message" *ngIf="validationError && validationError.length > 0">
                    <div *ngFor="let error of validationError" class="pb-1">
                      <div *ngIf="!error.isAdultDelete">
                        <div [ngSwitch]="error.type">
                          <div class="error" *ngSwitchCase="ErrorType.ERROR">
                            <div class="d-flex flex-row postiion-relative">
                              <div class="p-2 icon-wrapper">
                                <mat-icon>
                                  close
                                </mat-icon>
                              </div>
                              <div class="p-2" class="message">
                                <span>{{error.description}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="success" *ngSwitchCase="ErrorType.SUCCESS">
                            <div class="d-flex flex-row postiion-relative">
                              <div class="p-2 icon-wrapper">
                                <mat-icon>
                                  check_circle_outline
                                </mat-icon>
                              </div>
                              <div class="p-2" class="message">
                                <span>{{error.description}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="warning" *ngSwitchCase="ErrorType.WARNING">
                            <div class="d-flex flex-row postiion-relative">
                              <div class="p-2 icon-wrapper">
                                <mat-icon>
                                  priority_high
                                </mat-icon>
                              </div>
                              <div class="p-2" class="message">
                                <span>{{error.description}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="card-item-list">
                    <mat-accordion>
                      <mat-expansion-panel *ngFor="let bookingPackage of bookingDisplayCart; index as i;first as isFirst"
                        [expanded]="expandedBookingPackageIndex === i && selectedBookingPackageID === bookingPackage?.bookingPackageId && cartPanelExpanded">
                        <mat-expansion-panel-header (click)="openExpansion(i, bookingPackage.bookingPackageId, 'cartPanel')">
                          <mat-panel-title>
                            <div class="panel-wrapper">
                              <div class="panel-wrapper-row m-0 row">
                                <div class="col-3" style="display: flex;">
                                  <div class="description-wrapper">
                                    <div class="dec-loader" *ngIf="!isImgLoaded">
                                      <span id="imageWaitingLoader"></span>
                                    </div>
                                    <img class="dec-image" alt="des-image" [hidden]="!isImgLoaded" (load)="onImgLoad()"
                                      [src]="bookingPackage.image != '' ? bookingPackage.image : '../dist/assets/images/no-image.png'" (error)="onImgError($event)" />
                                    <span class="description-name ">
                                      <a class="description-name package-description"
                                        (click)="openDescriptionModal(bookingPackage,'package')">
                                        {{ bookingPackage.name }}
                                      </a>
                                      <br>
                                      <div *ngIf="bookingPackage.isSeatAvailable && bookingPackage.seats && !isEditablePack(bookingPackage.cartPackages[0])">
                                        <div
                                          *ngIf="(bookingPackage.seats.length==1 && !bookingPackage.seats[0].includes(',')) else lengthExceed">
                                          <div class="seat-details">
                                            {{bookingPackage.seats[0].slice(0,bookingPackage.seats[0].indexOf('Seats'))}}<br>
                                              {{bookingPackage.seats[0].slice(bookingPackage.seats[0].indexOf('Seats'),bookingPackage.seats[0].length)}}
                                            </div>
                                          </div>
                                        <ng-template #lengthExceed>
                                          <mat-icon *ngIf="bookingPackage.seats.length != 0"
                                            (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu"
                                            [matTooltip]="'View Seat Numbers'">info</mat-icon>
                                        </ng-template>
                                      </div>
                                      <mat-menu #menu="matMenu">
                                        <div *ngFor="let seat of bookingPackage.seats">{{seat}}</div>
                                      </mat-menu>
                                      <div *ngIf="bookingPackage.hasLinkedPackage && !isEditablePack(bookingPackage.cartPackages[0]) && canEditBookingCart" class="card-description-name-icon">
                                        <mat-icon class="edit-icon">launch</mat-icon><span class="edit-txt" (click)="editTicket(bookingPackage.cartPackages[0].id)">{{ "Edit" }}</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-2" style="padding-left: 0px; text-align:center;">
                                  <div class="datetime-wrapper">
                                    <div class="booking-date">
                                      <span class="mobile-information-sub">
                                        {{ isSameDate(bookingPackage,'package') ? (bookingPackage.startDate |
                                        PbCustomDate) : setDateFormat(bookingPackage.startDate,bookingPackage.endDate) }}
                                      </span>
                                      <p class="card-booking-days"
                                        style="font-size:12px;font-weight: bold !important;padding: 0 0 0 10px;">
                                        {{getDateDiff(bookingPackage.startDate,bookingPackage.endDate)}}
                                      </p>
                                    </div>
                                    <div class="booking-time">
                                      {{ isSameDate(bookingPackage,'package') ? ((bookingPackage.startDate | date: "HH:mm")+' - '+(bookingPackage.endDate | date: "HH:mm")) :
                                      ((bookingPackage.startDate | date: "HH:mm")+' - '+(bookingPackage.endDate | date: "HH:mm")) }}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-2">
                                  <div class="qut" *ngIf="bookingPackage.hasLinkedPackage && canEditBookingCart">{{ getPackageQuantityIncludeLinkendPackages(bookingPackage.cartPackages)}}</div>
                                  <div *ngIf="bookingPackage.hasLinkedPackage && !isEditablePack(bookingPackage.cartPackages[0]) && canEditBookingCart" class="card-description-name-icon" style="justify-content: space-around;">
                                    <mat-icon class="edit-icon">launch</mat-icon><span class="edit-txt" (click)="editTicket(bookingPackage.cartPackages[0].id)">{{ "Edit" }}</span>
                                  </div>
                                </div>
                                <div class="col-3" style="display: flex; flex-direction:row">
                                  <div class="col-6">
                                    <div
                                      [ngClass]="booking.cancelled == true ? 'booking-total-price-cancelled' : 'booking-total-price'"
                                      class="edited-item-color"
                                      *ngIf="cartType === BOOKING_CART.EDIT_BOOKING && ((getPackageTotalPrice(bookingPackage.bookingPackageId)| PBCurrencyPipe) != (getFreezedPackageTotalPrice(bookingPackage.bookingPackageId)| PBCurrencyPipe) )">
                                      {{ getPackageTotalPrice(bookingPackage.bookingPackageId) | PBCurrencyPipe }}
                                    </div>
                                    <div
                                      [ngClass]="booking.cancelled == true ? 'booking-total-price-cancelled' : 'booking-total-price'"
                                      [ngStyle]="{'text-decoration':  ((getPackageTotalPrice(bookingPackage.bookingPackageId)| PBCurrencyPipe) != (getFreezedPackageTotalPrice(bookingPackage.bookingPackageId)| PBCurrencyPipe) ) ? 'line-through' : 'none'}"
                                      *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                      {{ getFreezedPackageTotalPrice(bookingPackage.bookingPackageId) |PBCurrencyPipe }}
                                    </div>
                                    <div
                                      [ngClass]="booking.cancelled == true ? 'booking-total-price-cancelled' : 'booking-total-price'"
                                      *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                      <span *ngIf="bookingPackage.cartPackages.length > 1">
                                        {{ getPackageTotalPrice(bookingPackage.bookingPackageId) | PBCurrencyPipe }}
                                      </span>
                                      <span *ngIf="bookingPackage.cartPackages.length == 1">
                                        {{ (booking.showPriceWithTax ? bookingPackage.grossAmount : bookingPackage.netAmount) | PBCurrencyPipe }}
                                      </span>
                                    </div>
                                  </div>

                                  <div class="col-6" style="display: flex; flex-direction:row">
                                    <div class="panel-icon-image">
                                      <img class="panel-icon" src="../dist/assets/images/trash.png" alt=""
                                        *ngIf="canEditBookingCart"
                                        (click)="$event.stopPropagation();deletePackages(bookingPackage.id, bookingPackage)" />
                                    </div>
                                    <div class="panel-icon-image">
                                      <img class="panel-icon" src="../dist/assets/images/help.png" alt=""
                                        *ngIf="bookingPackage.hasQuestionnaire && (!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))"
                                        (click)="$event.stopPropagation();popupQuestionPkg(bookingPackage)" />
                                    </div>
                                    <div class="panel-icon-guest-image">
                                      <img class="panel-icon" src="../dist/assets/images/guests.png" alt=""
                                        *ngIf="bookingPackage.hasHostGuest && (!editBookingButtonConfiguration.hostGuest || (editBookingButtonConfiguration.hostGuest && editBookingButtonConfiguration.hostGuest.visible))"
                                        (click)="$event.stopPropagation();openPkgAttendeeList(bookingPackage)" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="card-item-wrapper" *ngFor="let pkg of bookingPackage.cartPackages;index as x">
                          <div class="card-item-row m-0" style="align-items: center;">
                            <div class="col-3" style="padding-left: 0px;">
                              <div class="card-description-wrapper pl-4" style="padding-left: 16px;">
                                <i *ngIf="!pkg.isChild" class="fa fa-gift" aria-hidden="true"></i>
                                <i *ngIf="pkg.isChild" class="fa fa-gift child" aria-hidden="true"></i>
                                <div class="card-description-name pl-4 pr-2">
                                  <div class="card-description-name-icon">{{ pkg.name }}</div>
                                  <div *ngIf="!bookingPackage.hasLinkedPackage && !isEditablePack(pkg) && canEditBookingCart" class="card-description-name-icon">
                                    <mat-icon class="edit-icon">launch</mat-icon><span class="edit-txt" (click)="editTicket(pkg.id)">{{ "Edit" }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="card-datetime-wrapper">
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="card-unit-price"
                                [ngClass]="booking.cancelled == true ? 'card-unit-price-cancelled' : 'card-unit-price'">
                                {{ pkg.unitPrice | PBCurrencyPipe }}
                                <!-- <span *ngIf="!canEditBookingCart">&nbsp; x {{pkg.quantity}}</span> -->
                              </div>
                            </div>
                            <div class="col-2" style="padding-left: 35px;">
                              <div *ngIf="!canEditBookingCart" style="width: 100% !important;">
                                &nbsp; x &nbsp; {{pkg.quantity}}
                              </div>
                              <div class="p-0 flex align-items-center justify-content-center">
                                <div class="quantity-controls mb-0" *ngIf="canEditBookingCart"
                                style="margin-top: unset !important; padding: 0 !important;" data-quantity="">
                                  <div *ngIf="isEditablePack(pkg) else elseQut">
                                    <opt-pw-number-input [reduceButtonTooltip]="reduceButtonTooltip"
                                      [disableReduceBtnD]="disableReduceButton" [(value)]="pkg.quantity" [min]="1"
                                      [isCart]="true" [height]="20" [disableIncreaseBtnD]="pkg.availability==0" (valueChange)="onItemQuantityChange($event, i)">
                                    </opt-pw-number-input>
                                  </div>
                                  <ng-template #elseQut>
                                    <div style="display: flex; justify-content: center; font-weight: 600;">
                                      {{ pkg.quantity }}
                                    </div>
                                  </ng-template>
                                </div>
                                <div class="quantity-controls mb-0" *ngIf="canEditBookingCart && enableAvailability" style="margin-top: unset !important; padding: 0 !important;">
                                  <div class="available" [style.background-color]="pkg.backgroundColor" *ngIf="canEditBookingCart">
                                    {{pkg.availability +'&nbsp;' +('BOOKINGCART.Available' | optimotranslate | lowercase ) }}
                                  </div>
                                </div>
                                <div *ngIf="!bookingPackage.hasLinkedPackage && !isEditablePack(pkg) && canEditBookingCart" class="quantity-controls mb-0"
                                  style="height:auto !important;">
                                  <mat-icon class="edit-icon">launch</mat-icon><span class="edit-txt"
                                    (click)="editTicket(pkg.id)">{{ "Edit" }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-3" style="display: flex; padding-left:0px">
                              <div class="col-6" style="padding-left: 27px;">
                                <div
                                  [ngClass]="booking.cancelled == true ? 'booking-total-price-cancelled' : 'booking-total-price'"
                                  class="edited-item-color"
                                  *ngIf="(pkg.quantity * pkg.unitPrice) != getPkgUnitPrice(pkg) && cartType === BOOKING_CART.EDIT_BOOKING">
                                  {{ (pkg.quantity * pkg.unitPrice) | PBCurrencyPipe}}
                                </div>
                                <div
                                  [ngClass]="booking.cancelled == true ? 'booking-total-price-cancelled' : 'booking-total-price'"
                                  [ngStyle]="{'text-decoration': ((pkg.quantity * pkg.unitPrice) != getPkgUnitPrice(pkg)) ? 'line-through' : 'none'}"
                                  *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                  <span>{{ getPkgUnitPrice(pkg) | PBCurrencyPipe }}</span>
                                </div>
                                <div
                                  [ngClass]="booking.cancelled == true ? 'booking-total-price-cancelled' : 'booking-total-price'"
                                  *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                  {{ (pkg.quantity * pkg.unitPrice) | PBCurrencyPipe}}
                                </div>
                              </div>
                              <div class="col-6" style="padding-left: 18px;">
                                <div class="panel-icon-image">
                                  <img class="panel-icon" src="../dist/assets/images/trash.png" alt=""
                                    *ngIf="canEditBookingCart"
                                    (click)="deletePackages(pkg.bookingPackageId, bookingPackage)" />
                                </div>
                                <div class="panel-icon-image" *ngIf="pkg.hasQuestionnaire"
                                  (click)="popupQuestionPkgLevel(pkg,bookingPackage)">
                                  <img class="panel-icon" src="../dist/assets/images/help.png" alt=""
                                    *ngIf="(!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))" />
                                </div>

                                <div class="panel-icon-guest-image"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngFor="let item of bookingPackage.cartItems;index as y">
                          <div [ngSwitch]="item.repeatPolicy">
                            <div
                              *ngSwitchCase="item.child.length>0 && item.repeatPolicy==repeatPolicies.allowIndividualDays ? repeatPolicies.allowIndividualDays: 0">
                              <mat-accordion>
                                <mat-expansion-panel>
                                  <mat-expansion-panel-header style="background: white;padding-left: 25px;">
                                    <mat-panel-title>
                                      <div class="upsell-card-item-wrapper upsell-card-item-inner-wrapper">
                                        <div class="upsell-card-item-row upsell-card-item-row-inner m-0">
                                          <div class="p-0">
                                            <div class="upsell-card-description-wrapper  pl-4">
                                              <mat-icon>local_mall</mat-icon>
                                              <div class="upsell-card-description-name pl-4 pr-2 item-description"
                                                (click)="openDescriptionModal(item,'upsell')">
                                                {{ item.name }}
                                              </div>
                                            </div>
                                          </div>
                                          <div class="p-0 d-flex align-items-center">
                                            <div class="upsell-card-datetime-wrapper">
                                              <div class="upsell-card-booking-date">
                                                <span class="mobile-information-sub">
                                                  {{ isSameDate(item,'item') ? (item.startTime | PbCustomDate) :
                                                  setDateFormat(item.startTime,item.endTime)}}
                                                </span>
                                                <span class="mobile-information-sub">
                                                  <strong style="font-weight: 600;">{{item.child.length > 1 ?
                                                    item.child.length + ' ' + ('BOOKINGCART.days' | optimotranslate) :
                                                    item.child.length+ ' ' + ('BOOKINGCART.day' | optimotranslate)}}</strong>
                                                </span>
                                                <span class="mobile-information-sub">
                                                  <strong>{{item.child.length > 1 ? (item.unitPrice |PBCurrencyPipe)+ ' x'
                                                    +item.child.length + ' ' + ('BOOKINGCART.days' | optimotranslate) :
                                                    item.child.length+ ' ' + ('BOOKINGCART.day' | optimotranslate)}}</strong>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="p-0 d-flex align-items-center">
                                          </div>
                                          <div class="p-0 d-flex align-items-center justify-content-center">
                                            <div class="quantity-controls mb-0"
                                              style="margin-top: unset !important; padding: 0 !important;display:none"
                                              data-quantity="">
                                            </div>
                                          </div>
                                          <div class="p-0 d-flex align-items-center">
                                            <div class="booking-total-price-wrapper">
                                              <div class="upsell-booking-child-total-price edited-item-color"
                                                *ngIf="cartType === BOOKING_CART.EDIT_BOOKING && getRepeatPolicyChildTotal(item,bookingPackage)  != getRepeatDisplayPolicyChildTotal(item,bookingPackage)">
                                                {{ getRepeatDisplayPolicyChildTotal(item,bookingPackage) | PBCurrencyPipe
                                                }}
                                              </div>
                                              <div class="upsell-booking-child-total-price"
                                                [ngStyle]="{'text-decoration': getRepeatPolicyChildTotal(item,bookingPackage) != getRepeatDisplayPolicyChildTotal(item,bookingPackage) ? 'line-through' : 'none'}"
                                                *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                                {{ getRepeatPolicyChildTotal(item,bookingPackage) | PBCurrencyPipe }}
                                              </div>
                                              <div class="upsell-booking-child-total-price"
                                                *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                                {{ getRepeatDisplayPolicyChildTotal(item,bookingPackage) | PBCurrencyPipe
                                                }}
                                              </div>
                                            </div>

                                            <div class="panel-icon-wrapper">
                                              <div class="panel-icon-image">
                                                <img class="panel-icon" src="../dist/assets/images/trash.png" alt=""
                                                  *ngIf="canEditBookingCart"
                                                  (click)="$event.stopPropagation();deleteItems(item, bookingPackage)" />
                                              </div>

                                              <div class="panel-icon-image" *ngIf="item.hasQuestionnaire">
                                                <img class="panel-icon" src="../dist/assets/images/help.png" alt=""
                                                  (click)="$event.stopPropagation();popupQuestionItmLevel(item,bookingPackage)"
                                                  *ngIf="(!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))" />
                                              </div>

                                              <div class="panel-icon-guest-image"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </mat-panel-title>


                                  </mat-expansion-panel-header>
                                  <div class="upsell-card-item-wrapper" *ngFor="let itm of item.child">
                                    <div class="upsell-card-item-row m-0 ">
                                      <div class="p-0">
                                        <div
                                          class="upsell-card-description-wrapper upsell-card-description-inner-wrapper pl-4">
                                          <!-- <div class="upsell-card-description-name pl-4 pr-2 sub-upsell-item-description item-description" (click)="openDescriptionModal(itm,'upsell')"> -->
                                          <div class="no-img" style="width:12% !important;">

                                          </div>
                                          <div class="upsell-card-description-name pl-4 pr-2 sub-upsell-item-description">
                                            {{itm.name}}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="p-0 d-flex align-items-center">
                                        <div class="upsell-card-datetime-wrapper">
                                          <div class="upsell-card-booking-date">
                                            <span class="mobile-information-sub">
                                              {{ isSameDate(itm,'item') ? (itm.startTime | PbCustomDate) :
                                              setDateFormat(itm.startTime,itm.endTime)}}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="p-0 d-flex align-items-center">
                                        <div class="upsell-card-unit-price">
                                          {{itm.unitPrice |PBCurrencyPipe }}
                                          <!-- <span *ngIf="!canEditBookingCart">&nbsp; x {{itm.quantity}}</span> -->
                                        </div>
                                      </div>
                                      <div class="p-0 d-flex align-items-center justify-content-center">
                                        <div *ngIf="!canEditBookingCart" style="width: 100% !important;">
                                          &nbsp; x &nbsp; {{itm.quantity}}
                                        </div>
                                        <div class="quantity-controls mb-0"
                                          style="margin-top: unset !important; padding: 0 !important;" data-quantity="">
                                          <opt-pw-number-input [reduceButtonTooltip]="reduceButtonTooltip"
                                            [disableReduceBtnD]="disableReduceButton" *ngIf="canEditBookingCart" [min]="1"
                                            [(value)]="itm.quantity" [isCart]="true" [height]="20"
                                            (valueChange)="onItemQuantityChange($event, i)">
                                          </opt-pw-number-input>
                                        </div>
                                      </div>
                                      <div class="p-0 d-flex align-items-center">
                                        <div class="booking-total-price-wrapper">
                                          <div class="upsell-booking-total-price edited-item-color"
                                            *ngIf="(itm.quantity * itm.unitPrice) != getItmUnitPrice(itm,bookingPackage.bookingPackageId) && cartType === BOOKING_CART.EDIT_BOOKING">
                                            {{ (itm.quantity * itm.unitPrice) | PBCurrencyPipe }}
                                          </div>
                                          <div class="upsell-booking-total-price"
                                            [ngStyle]="{'text-decoration': ((itm.quantity * itm.unitPrice) != getItmUnitPrice(itm,bookingPackage.bookingPackageId)) ? 'line-through' : 'none'}"
                                            *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                            {{ (getItmUnitPrice(itm,bookingPackage.bookingPackageId)) | PBCurrencyPipe }}
                                          </div>
                                          <div class="upsell-booking-total-price"
                                            *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                            {{ (itm.quantity * itm.unitPrice) | PBCurrencyPipe }}
                                          </div>
                                        </div>

                                        <div class="panel-icon-wrapper">
                                          <div class="panel-icon-image">
                                            <img class="panel-icon" src="../dist/assets/images/trash.png" alt=""
                                              *ngIf="canEditBookingCart"
                                              (click)="$event.stopPropagation();deleteItems(itm, bookingPackage)" />
                                          </div>

                                          <div class="panel-icon-image">
                                            <img class="panel-icon" src="../dist/assets/images/help.png" alt=""
                                              *ngIf="itm.hasQuestionnaire && (!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))"
                                              (click)="$event.stopPropagation();popupQuestionItmLevel(itm,bookingPackage)" />
                                          </div>

                                          <div class="panel-icon-guest-image"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                            <div *ngSwitchCase="repeatPolicies.repeatAllDays">
                              <div class="upsell-card-item-wrapper">
                                <div class="upsell-card-item-row m-0 upsell-card-item-inner-row">
                                  <div class="p-0">
                                    <div
                                      class="upsell-card-description-wrapper upsell-card-description-inner-wrapper pl-4">
                                      <mat-icon>local_mall</mat-icon>
                                      <div class="upsell-card-description-name pl-4 pr-2 item-description"
                                        (click)="openDescriptionModal(item,'upsell')">
                                        {{ item.name }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center">
                                    <div class="upsell-card-datetime-wrapper">
                                      <div class="upsell-card-booking-date">
                                        <span class="mobile-information-sub">
                                          {{ isSameDate(item,'item') ? (item.startTime | PbCustomDate) :
                                          setDateFormat(item.startTime,item.endTime)}}
                                        </span>
                                        <span class="mobile-information-sub">
                                          <strong style="font-weight: 600">{{item.units > 1 ? item.units + ' ' +
                                            ('BOOKINGCART.days' | optimotranslate) :item.units + ' ' + ('BOOKINGCART.day' |
                                            optimotranslate)}}</strong>
                                        </span>
                                        <span class="mobile-information-sub">
                                          <strong>{{item.units > 1 ? (item.unitPrice/item.units |PBCurrencyPipe)+ ' x
                                            '+item.units + ' ' + ('BOOKINGCART.days' | optimotranslate) : item.units + ' ' +
                                            ('BOOKINGCART.day' | optimotranslate)}}</strong>
                                        </span>

                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center">
                                    <div class="upsell-card-unit-price">
                                      {{item.unitPrice |PBCurrencyPipe }}
                                      <!-- <span *ngIf="!canEditBookingCart">&nbsp; x {{item.quantity}}</span> -->
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center justify-content-center">
                                    <div *ngIf="!canEditBookingCart" style="width: 100% !important;">
                                      &nbsp; x &nbsp; {{itm.quantity}}
                                    </div>
                                    <div class="quantity-controls mb-0"
                                      style="margin-top: unset !important; padding: 0 !important;" data-quantity="">
                                      <opt-pw-number-input [reduceButtonTooltip]="reduceButtonTooltip"
                                        [disableReduceBtnD]="disableReduceButton" [(value)]="item.quantity" [min]="1"
                                        *ngIf="canEditBookingCart" [isCart]="true" [height]="20"
                                        (valueChange)="onItemQuantityChange($event, i)">
                                      </opt-pw-number-input>
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center">
                                    <div class="booking-total-price-wrapper">
                                      <div class="upsell-booking-total-price edited-item-color"
                                        *ngIf="(item.quantity * item.unitPrice) != getItmUnitPrice(item,bookingPackage.bookingPackageId) && cartType === BOOKING_CART.EDIT_BOOKING">
                                        {{ (item.quantity * item.unitPrice) | PBCurrencyPipe }}
                                      </div>
                                      <div class="upsell-booking-total-price"
                                        [ngStyle]="{'text-decoration': ((item.quantity * item.unitPrice) != getItmUnitPrice(item,bookingPackage.bookingPackageId)) ? 'line-through' : 'none'}"
                                        *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                        {{ (getItmUnitPrice(item,bookingPackage.bookingPackageId)) | PBCurrencyPipe }}
                                      </div>
                                      <div class="upsell-booking-total-price"
                                        *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                        {{ (item.quantity * item.unitPrice) | PBCurrencyPipe }}
                                      </div>

                                    </div>

                                    <div class="panel-icon-wrapper">
                                      <div class="panel-icon-image">
                                        <img class="panel-icon" src="../dist/assets/images/trash.png" alt=""
                                          *ngIf="canEditBookingCart"
                                          (click)="$event.stopPropagation();deleteItems(item, bookingPackage)" />
                                      </div>

                                      <div class="panel-icon-image">
                                        <img class="panel-icon" src="../dist/assets/images/help.png" alt=""
                                          *ngIf="item.hasQuestionnaire && (!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))"
                                          (click)="$event.stopPropagation();popupQuestionItmLevel(item,bookingPackage)" />
                                      </div>

                                      <div class="panel-icon-guest-image"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngSwitchDefault>
                              <!-- UPSELL ITEM -->
                              <div class="upsell-card-item-wrapper" *ngIf="item.itemType == 'UPSELL'">
                                <div class="upsell-card-item-row m-0 upsell-card-item-inner-row">
                                  <div class="p-0">
                                    <div
                                      class="upsell-card-description-wrapper upsell-card-description-inner-wrapper pl-4">
                                      <mat-icon>local_mall</mat-icon>
                                      <div class="upsell-card-description-name pl-4 pr-2 item-description"
                                        (click)="openDescriptionModal(item,'upsell')">
                                        {{ item.name }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center">
                                    <div class="upsell-card-datetime-wrapper">
                                      <div class="upsell-card-booking-date">
                                        <span class="mobile-information-sub">
                                          {{ isSameDate(item,'item') ? (item.startTime | PbCustomDate) :
                                          setDateFormat(item.startTime,item.endTime)}}
                                        </span>
                                        <span class="mobile-information-sub" *ngIf="!isSameDate(item,'item')">
                                          {{item.unitPrice/item.units |PBCurrencyPipe }} x
                                          {{getDateDiff(item.startTime,item.endTime) }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center">
                                    <div class="upsell-card-unit-price">
                                      {{item.unitPrice |PBCurrencyPipe }}
                                      <!-- <span *ngIf="!canEditBookingCart">&nbsp; x {{item.quantity}}</span> -->
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center justify-content-center">
                                    <div *ngIf="!canEditBookingCart" style="width: 100% !important;">
                                      &nbsp; x &nbsp; {{item.quantity}}
                                    </div>
                                    <div class="quantity-controls mb-0" *ngIf="canEditBookingCart"
                                      style="margin-top: unset !important; padding: 0 !important;width: 100% !important;" data-quantity="">
                                      <opt-pw-number-input [reduceButtonTooltip]="reduceButtonTooltip"
                                        [disableReduceBtnD]="disableReduceButton" [(value)]="item.quantity" [min]="1"
                                        *ngIf="canEditBookingCart" [isCart]="true" [height]="20"
                                        [disableIncreaseBtnD]="item.availability==0"
                                        (valueChange)="onItemQuantityChange($event, i)">
                                      </opt-pw-number-input>
                                    </div>
                                    <div [style.background-color]="item.backgroundColor"
                                      *ngIf="enableAvailability && (canEditBookingCart && item.availability!='Indeterminable' && (item.availability)!='NULL' && (item.availability!=-1))"
                                      class="available">
                                      {{item.availability +'&nbsp;' +('BOOKINGCART.Available' | optimotranslate | lowercase )}}
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center">
                                    <div class="booking-total-price-wrapper">
                                      <div class="upsell-booking-total-price edited-item-color"
                                        *ngIf=" (item.quantity * item.unitPrice) != getItmUnitPrice(item,bookingPackage.bookingPackageId) && cartType === BOOKING_CART.EDIT_BOOKING">
                                        {{ (item.quantity * item.unitPrice) | PBCurrencyPipe }}
                                      </div>
                                      <div class="upsell-booking-total-price"
                                        [ngStyle]="{'text-decoration': ((item.quantity * item.unitPrice) != getItmUnitPrice(item,bookingPackage.bookingPackageId)) ? 'line-through' : 'none'}"
                                        *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                        {{ (getItmUnitPrice(item,bookingPackage.bookingPackageId)) | PBCurrencyPipe }}
                                      </div>
                                      <div class="upsell-booking-total-price"
                                        *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                        {{ item.quantity * item.unitPrice | PBCurrencyPipe }}
                                      </div>
                                    </div>
                                    <div class="panel-icon-wrapper" style="padding-left: 0.9rem;">
                                      <div class="panel-icon-image">
                                        <img class="panel-icon" src="../dist/assets/images/trash.png" alt=""
                                          *ngIf="canEditBookingCart"
                                          (click)="$event.stopPropagation();deleteItems(item, bookingPackage)" />
                                      </div>

                                      <div class="panel-icon-image">
                                        <img class="panel-icon" src="../dist/assets/images/help.png" alt=""
                                          *ngIf="item.hasQuestionnaire && (!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))"
                                          (click)="$event.stopPropagation();popupQuestionItmLevel(item,bookingPackage)" />
                                      </div>

                                      <div class="panel-icon-guest-image"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- DISCOUNT ITEM -->
                              <div class="upsell-card-item-wrapper" *ngIf="item.itemType == 'DISCOUNT'">
                                <div class="upsell-card-item-row m-0 upsell-card-item-inner-row discount-item-color">
                                  <div class="p-0">
                                    <div
                                      class="upsell-card-description-wrapper upsell-card-description-inner-wrapper pl-4">
                                      <mat-icon class="discount-item-color">discount</mat-icon>
                                      <div class="upsell-card-description-name pl-4 pr-2">
                                        {{ item.name }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center">

                                  </div>
                                  <div class="p-0 d-flex align-items-center">

                                  </div>
                                  <div class="p-0 align-items-center justify-content-center">
                                    <div class="quantity-controls mb-0"
                                      style="margin-top: unset !important; padding: 0 !important;" data-quantity="">
                                      <!-- <opt-pw-number-input [reduceButtonTooltip]="reduceButtonTooltip"
                                        [disableReduceBtnD]="disableReduceButton" [(value)]="item.quantity" [min]="1"
                                        *ngIf="canEditBookingCart" [isCart]="true" [height]="20"
                                        (valueChange)="onItemQuantityChange($event, i)">
                                      </opt-pw-number-input> -->
                                    </div>
                                  </div>
                                  <div class="p-0 d-flex align-items-center">
                                    <div class="booking-total-price-wrapper">
                                      <div class="upsell-booking-total-price edited-item-color">
                                        <span
                                          *ngIf="(item.quantity * item.unitPrice) != getItmUnitPrice(item,bookingPackage.bookingPackageId)
                                            && cartType === BOOKING_CART.EDIT_BOOKING
                                            && !(canEditBookingCart && getPackageChangesForEditBooking(bookingPackage) !== 0)">
                                          {{ (item.quantity * item.unitPrice) | PBCurrencyPipe }}
                                        </span>
                                        <span style="font-weight: 700; font-size: 11px; margin-right: 2rem;"
                                          *ngIf="canEditBookingCart && getPackageChangesForEditBooking(bookingPackage) !== 0">
                                            Pending...
                                        </span>
                                      </div>
                                      <div class="upsell-booking-total-price"
                                        [ngStyle]="{'text-decoration': ((item.quantity * item.unitPrice) != getItmUnitPrice(item,bookingPackage.bookingPackageId)) ? 'line-through' : 'none'}"
                                        *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                        <span class="discount-item-style">{{ (getItmUnitPrice(item,bookingPackage.bookingPackageId)) | PBCurrencyPipe }}</span>
                                      </div>
                                      <div class="upsell-booking-total-price"
                                        *ngIf="cartType === BOOKING_CART.NEW_BOOKING">
                                        <span class="discount-item-style">{{ item.quantity * item.unitPrice | PBCurrencyPipe }}</span>
                                      </div>
                                    </div>
                                    <!-- <div class="panel-icon-wrapper"> -->
                                      <!-- <div class="panel-icon-image">
                                        <img class="panel-icon" src="../dist/assets/images/trash.png" alt=""
                                          *ngIf="canEditBookingCart"
                                          (click)="$event.stopPropagation();deleteItems(item, bookingPackage)" />
                                      </div>

                                      <div class="panel-icon-image">
                                        <img class="panel-icon" src="../dist/assets/images/help.png" alt=""
                                          *ngIf="item.hasQuestionnaire && (!editBookingButtonConfiguration.questionnaire || (editBookingButtonConfiguration.questionnaire && editBookingButtonConfiguration.questionnaire.visible))"
                                          (click)="$event.stopPropagation();popupQuestionItmLevel(item,bookingPackage)" />
                                      </div>

                                      <div class="panel-icon-guest-image"></div> -->
                                    <!-- </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row extra-row pl-4 add-extra-row">
                          <div style="display: flex;" class="col-3">
                            <div class="add-extra" (click)="addAddonsForBookingPackage(bookingPackage)"
                              *ngIf="(cartType !== BOOKING_CART.EDIT_BOOKING || (cartType === BOOKING_CART.EDIT_BOOKING && canEditBookingCart )) && (enableUpsellConfiguration && editBookingButtonConfiguration.addOns.visible && bookingPackage.isShowAddExtra)">
                              <i class="bi bi-plus-circle-fill"></i> {{'BOOKINGCART.Add Extras' | optimotranslate}}
                            </div>
                          </div>
                          <!-- According to the pagination BRD, we drop the 'Apply Changes' and 'Discard Changes'-->
                          <!-- <div class="apply-changes-card-wrapper col-9"
                            *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && canEditBookingCart && getPackageChangesForEditBooking(bookingPackage) !== 0 )">
                            <div class="number-of-changes"
                              *ngIf="(getPackageTotalPrice(bookingPackage.bookingPackageId)) != (getFreezedPackageTotalPrice(bookingPackage.bookingPackageId))">
                              {{getPackageChangesForEditBooking(bookingPackage) ==1 ?
                              getPackageChangesForEditBooking(bookingPackage)+" " + ("BOOKINGCART.Pending Change" |
                              optimotranslate) :
                              getPackageChangesForEditBooking(bookingPackage)+" " + ("BOOKINGCART.Pending Changes" |
                              optimotranslate)}}
                            </div>
                            <div class="apply-changes-btn-wrapper">
                              <button mat-button class="apply-changes-btn" (click)="applyEditChange(bookingPackage)"
                                aria-label="Show an example snack-bar">{{'BOOKINGCART.Apply Changes' |
                                optimotranslate}}</button>
                            </div>
                            <div class="cancel-changes-btn-wrapper">
                              <button mat-button class="cancel-changes-btn"
                                (click)="cancelChanges(bookingPackage)">{{'BOOKINGCART.Cancel Changes' |
                                optimotranslate}}</button>
                            </div>
                          </div> -->
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <!-- Add New Package -->
                  <div class="pt-2" *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && canEditBookingCart )">
                    <mat-card class="add-new-package">
                      <div class="d-flex" style="justify-content: space-between;">
                        <div class="d-flex add-new-package-name">
                          <div class="add-new-package-icon">
                            <i class="fa fa-gift" aria-hidden="true"></i>
                          </div>
                          <div class="add-new-package-text">
                            {{'BOOKINGCART.Add New Package' | optimotranslate}}
                          </div>
                        </div>
                        <div class="add-new-package-btn" (click)="continueShopping()">
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </div>
                      </div>
                    </mat-card>
                  </div>
                  <!-- End Add New Package -->
                  <div class="b-search-pagi search-pagi" [hidden]="!paginationSetting.isEnable || (bookingPackagesLength <= paginationSetting.minimumRequiredPageSize)"
                    matTooltip="You have the pending changes. Please save or discard it." [matTooltipDisabled]="!pendingPatch" matTooltipPosition="above">
                    <mat-paginator #BookingPaginator
                                   [length]="bookingPackagesLength"
                                   [pageSize]="pageSize"
                                   [disabled]="pendingPatch"
                                   [pageSizeOptions]="getPageSizeOptions()"
                                   aria-label="Select page"
                                   [pageIndex]="pageIndex"
                                   (page)="pageClickEvent($event)"
                                   class="booking-cart-paginator">
                    </mat-paginator>
                  </div>
                  <div class="delivery-method-item pt-2" *ngIf="displayDeliveryMethodList">
                    <mat-accordion>
                      <mat-expansion-panel (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                        <mat-expansion-panel-header (click)="getDispatchStatus()" style="margin-left: 16px; margin-right:18px">
                          <mat-panel-title>
                            <div class="delivery-card-wrapper d-flex align-items-center custom-title-wrapper">
                              <div class="d-flex icon-with-text">
                                <div class="delivery-card-icon">
                                  <img class="delivery-icon" src="../dist/assets/images/noun_delivery_4011143.png"
                                    alt="delivery-icon" />
                                </div>
                                <div class="delivery-card-name">
                                  {{'BOOKINGCART.Delivery Method' | optimotranslate}} : {{selectedDeliveryMethod ?
                                  selectedDeliveryMethod.name : 'BOOKINGCART.No Delivery Method' | optimotranslate}}
                                </div>
                              </div>
                              <div [ngClass]="booking.cancelled == true ? 'delivery-card-name-price-cancelled' : 'delivery-card-name-price'">
                                {{(selectedDeliveryMethod && booking && !booking.cancelled) ?
                                (selectedDeliveryMethod.price | PBCurrencyPipe ) : 0.00 | PBCurrencyPipe}}
                              </div>
                            </div>
                            <!-- </div> -->
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="inside-delivery-method">
                          <div class="inside-delivery-method-disable" *ngIf="enableDeliveryMethodSpinner">
                            <div id="deliveryMethodPatchPreviewSpinner"></div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="radio-btn-wrapper">
                                <mat-radio-group [(ngModel)]="selectedDeliveryMethod"
                                  [disabled]="BOOKING_CART.NEW_BOOKING || (!canEditBookingCart && BOOKING_CART.EDIT_BOOKING)"
                                  (ngModelChange)="deliveryMethodChange()">
                                  <div *ngFor="let deliveryMethod of deliveryMethods" class="delivery-method-content">
                                    <div class="mat-radio-button-item">
                                      <mat-radio-button [value]="deliveryMethod">
                                        <span class="method-name">{{deliveryMethod.name}}</span>
                                      </mat-radio-button>
                                    </div>
                                    <div [ngClass]="booking.cancelled == true ? 'price-last-cancelled' : 'price-last'">
                                      {{deliveryMethod.price | PBCurrencyPipe }} </div>
                                    <div>
                                    </div>
                                  </div>
                                </mat-radio-group>
                              </div>
                            </div>

                            <div class="col-md-6 column-wrapper">
                              <div class="text-right">
                              </div>
                              <div class="text-right" *ngIf="cartType === BOOKING_CART.EDIT_BOOKING">
                                <p><span class="despatch-title">{{'BOOKINGCART.Despatch Status' | optimotranslate}}:</span><span
                                    class="despatch-name" (click)="openDispatchTable()"
                                    [style.cursor]="(dispatchStatus && dispatchStatus.dispatchable) ? 'pointer' : ''">
                                    {{dispatchStatus && dispatchStatus.dispatchable ? ('BOOKINGCART.Despatch Status' |
                                    optimotranslate) : ('BOOKINGCART.Not Applicable'
                                    | optimotranslate)}} </span></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <!-- show Invoices and Documents -->
                  <div class="delivery-method-item pt-2" *ngIf="((bookingInvoices && bookingInvoices.length > 0) || (bookingDocuments && bookingDocuments.length > 0))">
                    <mat-accordion>
                      <mat-expansion-panel (opened)="invoicePanelOpenState = true" (closed)="invoicePanelOpenState = false">
                        <mat-expansion-panel-header  style="margin-left: 16px; margin-right:18px">
                          <mat-panel-title>
                            <div class="delivery-card-wrapper d-flex align-items-center custom-title-wrapper">
                              <div class="d-flex icon-with-text">
                                <div class="delivery-card-icon">
                                  <img class="document-icon" src="../dist/assets/images/file.png"
                                    alt="document-icon" />
                                </div>
                                <div class="delivery-card-name">
                                  {{'BOOKINGCART.Invoices and Documents' | optimotranslate}}
                                </div>
                              </div>
                            </div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="inside-delivery-method">
                          <div class="row">
                            <!-- display the documents -->
                            <div class="col-md-12 invoice-row-space" *ngIf="bookingDocuments && bookingDocuments.length > 0">
                              <div class="radio-btn-wrapper">
                                <div class="mmc_expansion__body--row my-bookings-expansion-body" *ngFor="let doc of bookingDocuments">
                                  <div class="doc-image"><img class="doc-img" src="../dist/assets/images/doc.png" alt="Documents"></div>
                                  <div class="doc-name pw-txt-s1 pw-text-color__tertiary">
                                    <a (click)="onDownloadDocument(doc)">
                                      {{ doc.name }}
                                    </a>
                                  </div>
                                  <div class="doc-date pw-txt-s1"><span>{{ doc?.auditInfo?.insertedTime | PbCustomDate:'dd MMM Y' }}</span></div>
                                  <div class="doc-cell"></div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 invoice-row-space" *ngIf="bookingInvoices && bookingInvoices.length > 0">
                              <div class="radio-btn-wrapper">
                                <div class="mmc_expansion__body--row my-bookings-expansion-body">
                                      <div class="cell-space">
                                          <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Invoice'| optimotranslate}}</div>
                                      </div>
                                      <div class="cell-space">
                                          <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Due Date' | optimotranslate}}</div>
                                      </div>
                                      <div class="cell-space">
                                          <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Invoice Amount' | optimotranslate}}</div>
                                      </div>
                                      <div class="cell-space">
                                          <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Outstanding' | optimotranslate}}</div>
                                      </div>
                                      <div class="cell-space">
                                          <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'INVOICEINFO.Date Paid' | optimotranslate}}</div>
                                      </div>
                                      <!-- <div class="cell-space">
                                          <div class="pw-txt-s1 pw-text-color__tertiary invoice-header-cell">{{'MYBOOKING.Action' | optimotranslate}}</div>
                                      </div> -->
                                </div>
                                <div class="mmc_expansion__body--row my-bookings-expansion-body" *ngFor="let inv of bookingInvoices">
                                      <div class="invoice-detail-cell">
                                          <div class="link-primary" (click)="onInvoiceView(inv)" (keydown.enter)="onInvoiceView(inv)">{{inv.InvoiceRef}}</div>
                                          <div class="status"   [ngClass]="inv.Status=='Unpaid' ? 'unpaid': 'paid'">{{inv.Cancelled ? ('INVOICEINFO.Cancelled' | optimotranslate | uppercase) : ('INVOICEINFO.' + inv.Status | optimotranslate | uppercase )}}</div>
                                      </div>
                                      <div class="invoice-detail-cell">
                                          <div class="pw-txt-s2">{{inv.DueDate | PbCustomDate:'dd MMM Y'}}</div>
                                      </div>
                                      <div class="invoice-detail-cell">
                                          <div class="pw-txt-s2 inv-amount">{{inv.InvoiceAmount | CurrencyFormat}}</div>
                                      </div>
                                      <div class="invoice-detail-cell">
                                          <div class="pw-txt-s2 b-outstanding">{{inv.Outstanding | CurrencyFormat}}</div>
                                      </div>
                                      <div class="invoice-detail-cell">
                                          <div *ngIf="inv.DatePaid; else emptyDate" class="pw-txt-s2">{{inv.DatePaid | PbCustomDate:'dd MMM Y'}}</div>
                                          <ng-template #emptyDate>
                                            <div class="pw-txt-s2" style="font-size: 30px !important;">&#8722;</div>
                                          </ng-template>
                                        </div>
                                      <!-- <div class="invoice-detail-cell">
                                          <div class="btn-wrapper">
                                              <button type="button" mat-button class="mmc-btn-secondary--small main mmc_flex__item--vcenter" role="button" [ngClass]="inv.Outstanding<=0 || inv.Cancelled || bk.Cancelled? 'dissabled':''" [disabled]="inv.Outstanding<=0 ||inv.Cancelled || bk.Cancelled" *ngIf="PaymentPortalUrl"  (click)="RedirectToPayment(inv.InvoiceRef)" (keydown.enter)="RedirectToPayment(inv.InvoiceRef)" style="width: 75% !important; height: 25px !important;">{{"MYBOOKING.Pay Now" | optimotranslate }}</button>
                                          </div>
                                      </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <div class="card-disable" *ngIf="enableSpinner">
                  <div id="patchPreviewSpinner"></div>
                </div>
              </div>

            </div>
            <div class="row" *ngIf="!isFromMyBooking && cartType === BOOKING_CART.NEW_BOOKING">
              <a (click)="continueShopping()" [ngClass]="{'disableButton': !validatedBooking}"
                [disabled]="!validatedBooking">
                <h6 class="text-primary ml-3 mb-4 p-2 cursor-pointer continue-shopping-btn">
                  {{'BOOKINGCART.Continue Shopping' | optimotranslate}}
                </h6>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 right-side">
          <mat-card class="d-block d-lg-block d-md-block pb-18">
            <!-- To Do RCW -->
            <!-- <div class="card session-timer showInWebOnly"
              *ngIf="paymentSessionTime.isSessionTimerStart && isHaveToPayDueAmount">
              <div class="session-timer-content">
                <div class="session-timer-msg">{{'BOOKINGCART.Please make your payment immediately' | optimotranslate}}</div>
                <div class="timer-date">
                  ({{'BOOKINGCART.Before' | optimotranslate}} {{sessionEndDate | date: 'shortTime'}})
                </div>
              </div>
            </div> -->

            <!-- Booking Cancelled Notification -->
            <!-- <div class="card refund-wrapper"
              *ngIf="cartType==BOOKING_CART.EDIT_BOOKING && !isHaveToPayDueAmount && !getIsPaymentContinueDisable && booking && !booking.cancelled">
              <div class="refund-icon">
                <mat-icon>published_with_changes</mat-icon>
              </div>
              <div class="refund-content">
                <p>{{'BOOKINGCART.Your refund will be processed in ' | optimotranslate}} <span>3</span> {{'BOOKINGCART.days' |
                  optimotranslate}}</p>
              </div>
            </div> -->
            <!-- Booking Cancelled Notification End -->

            <!-- <div class="card cancel-wrapper "
              *ngIf="cartType==BOOKING_CART.EDIT_BOOKING && !isHaveToPayDueAmount && !getIsPaymentContinueDisable && booking && booking.cancelled">
              <div class="cancel-icon">
                <mat-icon>close</mat-icon>
              </div>
              <div class="cancel-content">
                <div class="refund-title">
                {{'My Booking' | optimotranslate}} - {{booking.bookingReference}} - <span>CANCELLED</span>
              </div>
                <div class="refund-body" style="color: white;text-align: center;">
                  {{'BOOKINGCART.Your booking has been cancelled. Your refund will be processed in 3 days.' | optimotranslate}}
                </div>
              </div>
            </div> -->
            <div class="order-summary">
              <h5><strong> {{'BOOKINGCART.Order Summary' | optimotranslate}} </strong></h5>
            </div>
            <div class="card">
              <!-- Total Before Discount  -->
              <div class="row pb-1" *ngIf="(!booking.showPriceWithTax && booking.discountAmountExcludingTax !== 0) || (booking.showPriceWithTax && booking.discountAmountIncludingTax !== 0)">
                <div class="col-7 pt-2">
                  <strong class="order-total">{{'BOOKINGCART.Total Before Discount'| optimotranslate}} </strong>
                  <span><br /> </span>
                </div>
                <div class="col-5 text-right">
                  <strong class="total-price-show order-summary-mobile-price">{{
                    (booking.showPriceWithTax
                      ? (booking.grossAmount -  booking.discountAmountIncludingTax)
                      : (booking.netAmount -  booking.discountAmountExcludingTax)) | PBCurrencyPipe }}</strong>
                </div>
                <hr />
              </div>
              <!-- Discount -->
              <div class="row pb-1" *ngIf="(!booking.showPriceWithTax && booking.discountAmountExcludingTax !== 0) || (booking.showPriceWithTax && booking.discountAmountIncludingTax !== 0)">
                <div class="col-7 pt-2">
                  <strong class="order-total">{{'BOOKINGCART.Discount'| optimotranslate}} </strong>
                  <span><br /> </span>
                </div>
                <div class="col-5 text-right">
                  <strong class="total-price-show order-summary-mobile-price">{{ (booking.showPriceWithTax ? booking.discountAmountIncludingTax : booking.discountAmountExcludingTax) | PBCurrencyPipe }}</strong>
                </div>
                <hr />
              </div>
              <!-- Net Total -->
              <div class="row pb-1" *ngIf = "!booking.showPriceWithTax">
                <div class="col-7 pt-2">
                  <strong class="order-total">{{'BOOKINGCART.Net Total'| optimotranslate}} </strong>
                  <span><br /> </span>
                </div>
                <div class="col-5 text-right">
                  <strong class="total-price-show order-summary-mobile-price">{{ booking.netAmount |PBCurrencyPipe }}</strong>
                </div>
                <hr />
              </div>
              <!-- Tax -->
              <div class="row pb-1" *ngIf = "!booking.showPriceWithTax">
                <div class="col-7 pt-2">
                  <strong class="order-total">{{ 'BOOKINGCART.' + TaxLabelToDisplayName | optimotranslate }} </strong>
                  <span><br /> </span>
                </div>
                <div class="col-5 text-right">
                  <strong class="total-price-show order-summary-mobile-price">{{ booking.taxAmount |PBCurrencyPipe }}</strong>
                </div>
                <hr />
              </div>
              <!-- Order Total -->
              <div class="row pb-1">
                <div class="col-7 pt-2">
                  <strong class="order-total">{{'BOOKINGCART.Order Total'| optimotranslate}} </strong>
                  <span *ngIf = "booking.showPriceWithTax"
                    class="font-10 second-order-text">({{ 'BOOKINGCART.incl.' | optimotranslate }} {{ 'BOOKINGCART.' + TaxLabelToDisplayName | optimotranslate }} {{ 'BOOKINGCART.of' | optimotranslate }} {{ booking.taxAmount |PBCurrencyPipe }})
                  </span>
                  <span><br /> </span>
                </div>
                <div class="col-5 text-right">
                  <strong class="total-price-show order-summary-mobile-price">{{ booking.grossAmount |PBCurrencyPipe }}</strong>
                </div>
                <hr />
              </div>

              <div class="col-12 p-0" *ngIf="getIsHaveToPayOrRefund">
                <div class="payment-history">
                  <div class="previous-total" *ngIf="canEditBookingCart">
                    {{'BOOKINGCART.Previous Total' | optimotranslate}}: <span>{{ getFreezedBookingTotal |PBCurrencyPipe
                      }}</span>
                  </div>
                  <div class="updated-total" *ngIf="canEditBookingCart">
                    {{'BOOKINGCART.Updated Total' | optimotranslate}}: <span>{{getUpdatedBookingTotal| PBCurrencyPipe}}</span>
                  </div>
                  <div class="due-total">
                    {{'BOOKINGCART.Due Amount' | optimotranslate}}: <span>{{getDueAmount | PBCurrencyPipe}}</span>
                  </div>
                </div>
              </div>

              <div class="col-12 p-0"
                *ngIf="!editBookingButtonConfiguration.redeemArea || (editBookingButtonConfiguration.redeemArea && editBookingButtonConfiguration.redeemArea.visible)">
                <div class="input-group">
                  <input type="text" class="form-control m-w-70 w-70"
                    placeholder="{{'BOOKINGCART.Voucher' | optimotranslate}}" />
                  <span
                    class="form-control input-group-addon promoBtn-primary m-w-30 w-30 right-side-btn">{{'BOOKINGCART.Redeem'
                    | optimotranslate}}</span>
                </div>
              </div>

              <div class="col-12 p-0"
              *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && editBookingButtonConfiguration.specialNoteAreaEditBooking.visible) ||
              (cartType === BOOKING_CART.NEW_BOOKING && editBookingButtonConfiguration.specialNoteAreaNewBooking.visible)">
                <br />
                <div class="card-header p-0 req-title">
                  <span>{{'BOOKINGCART.Special Requests' | optimotranslate}}</span>
                  <span style="position: absolute;
                   right: 5px;">
                    <ng-container *ngIf="cartType === BOOKING_CART.EDIT_BOOKING && !canEditBookingCart">
                      <i *ngIf="cartType === BOOKING_CART.EDIT_BOOKING && !canEditCardlessContent && !booking.cancelled"
                        aria-hidden="true" matTooltip="{{'BOOKINGCART.Edit' | optimotranslate}}" matTooltipPosition="above"
                        (click)="EditCardlessContentEnable()" class="fa fa-pencil-square-o"></i>
                      <span *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && canEditCardlessContent)">
                        <i aria-hidden="true" style="margin-right: 5px;"
                          matTooltip="{{'BOOKINGCART.Discard Changes' | optimotranslate}}" matTooltipPosition="above"
                          (click)="EditCardlessContentDisable()" class="fa fa-ban"></i>
                        <i *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && canEditCardlessContent && tempSpecialNote !== booking.specialNote)"
                          aria-hidden="true" matTooltip="{{'BOOKINGCART.Save' | optimotranslate}}" matTooltipPosition="above"
                          (click)="SaveCardlessContent()" class="fa fa-floppy-o"></i>
                      </span>
                    </ng-container>
                  </span>
                </div>
              </div>
              <div class="card-header p-0 req-text"
              *ngIf="(cartType === BOOKING_CART.EDIT_BOOKING && editBookingButtonConfiguration.specialNoteAreaEditBooking.visible) ||
              (cartType === BOOKING_CART.NEW_BOOKING && editBookingButtonConfiguration.specialNoteAreaNewBooking.visible)">
                <textarea class="form-control w-100 bg-light-grey border-0 m-w-100" [(ngModel)]="booking.specialNote"
                  [disabled]="cartType == BOOKING_CART.EDIT_BOOKING && !canEditCardlessContent" name="" id=""
                  rows="3"></textarea>
              </div>

              <br />
              <div class="row" *ngIf="cartType === BOOKING_CART.NEW_BOOKING && clientLoginIsRequired && !getIsClientPaymentTermHasCredit && !(deliveryMethods && deliveryMethods.length==0)">
                <div class="col term_and_condition_checkbox">
                  <mat-checkbox class="check-box custom-padding-style" [(ngModel)]="isTermsAndConditionChecked">
                  </mat-checkbox>
                  <div class="term_and_condition_text"><a [href]="purchaseAgreementUrl" target="_blank" class="href-style">{{"BOOKINGCART.Accept terms & conditions"| optimotranslate}}</a></div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button style="width: 100% !important;" type="button"
                    *ngIf="isConfirmAndPayBtn && (editBookingButtonConfiguration.confirmAndPay && editBookingButtonConfiguration.confirmAndPay.visible)"
                    (click)="onCheckout()" [disabled]="getIsPaymentContinueDisable"
                    class="btn btn-primary promoBtn btn-lg btn-block btn-coupon w-100">
                    {{
                    cartType === BOOKING_CART.EDIT_BOOKING
                    ?getIsPaymentContinueDisable
                    ? ('BOOKINGCART.Fully Paid' | optimotranslate )
                    : isHaveToPayDueAmount
                    ? ('BOOKINGCART.Confirm & Pay' | optimotranslate )
                    : ('BOOKINGCART.Refund' | optimotranslate )
                    :('BOOKINGCART.Confirm & Pay' | optimotranslate)
                    }}
                  </button>
                  <button style="width: 100% !important;" type="button"
                    *ngIf="cartType === BOOKING_CART.NEW_BOOKING && clientLoginIsRequired && !(deliveryMethods && deliveryMethods.length==0)" (click)="bookingConfirm()"
                    class="btn btn-primary promoBtn btn-lg btn-block btn-coupon w-100"
                    [disabled]="!isTermsAndConditionChecked">
                    {{ getIsClientPaymentTermHasCredit ? ('BOOKINGCART.Confirm & Pay' | optimotranslate ) : ('BOOKINGCART.Confirm' | optimotranslate) }}
                  </button>
                  <button style="width: 100% !important;" type="button"
                    *ngIf="countPendingInvoices > 0 && !PaymentPortalUrl" (click)="goback()"
                    class="btn btn-primary promoBtn btn-lg btn-block btn-coupon w-100">
                    {{countPendingInvoices > 1 ? ('BOOKINGCART.View Invoices' | optimotranslate) : ('BOOKINGCART.View Invoice' |
                    optimotranslate)}}
                  </button>
                  <p class="pt-3" *ngIf="deliveryMethods && deliveryMethods.length==0">
                    <b>*</b>{{ (bookingDisplayCart && bookingDisplayCart.length>1) ? ("BOOKINGCART.Enter a package into
                    separate Bookings" | optimotranslate) : ("BOOKINGCART.No Delivery Methods" | optimotranslate)}}
                  </p>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div *ngIf="enablePublicLoader || isLoading">
        <opt-loader></opt-loader>
      </div>
    </div>
  </div>

  <ng-template #elseBlock>
    <div class="container" *ngIf="fromHomePage && !isLoading">
      <div style="width: auto; height: 300px;">
        <div class="row mobileView justify-content-center" style="position: relative; margin-top: 200px;">
          <div class="col-md-3">
            <div class="cartList">
              <a (click)="continueShopping()">
                <p class="text-center no-items">{{'BOOKINGCART.There are no items in this cart' | optimotranslate}}</p>
                <h6 class="text-primary m-3 p-2 cursor-pointer" style="text-align: center;">
                  {{'BOOKINGCART.Continue Shopping' | optimotranslate}}
                </h6>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isLoading">
      <opt-loader></opt-loader>
    </div>
  </ng-template>
</div>


