import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TimeSlot } from "src/modules/public-web/booking-application/models/BookingEvent";

@Injectable({
  providedIn: 'root'
})
export class PublicBookingSelectService {

  constructor() { }

  displayedBookingPackages$ = new BehaviorSubject<any[]>([]);
  displayedFreezedBookingPackages$ = new BehaviorSubject<any[]>([]);
  venueMapDisplayedInternalBookingPackageId$ = new BehaviorSubject<string>("");
  venueMapDisplayedInternalBookingPackageIds$ = new BehaviorSubject<string[]>([]);

  eventConfigurationTimeslots$ = new BehaviorSubject<TimeSlot[]>([]);

  setValue(packages: any[]) {
    this.displayedBookingPackages$.next(packages);
  }

  getValue() {
    return this.displayedBookingPackages$.asObservable();
  }

  setdisplayedFreezedBookingPackages(packages: any[]) {
    this.displayedFreezedBookingPackages$.next(packages);
  }

  getdisplayedFreezedBookingPackages() {
    return this.displayedFreezedBookingPackages$.asObservable();
  }

  setVenueMapDisplayedInternalBookingPackageId(id : string) {
    this.venueMapDisplayedInternalBookingPackageId$.next(id);
  }

  getVenueMapDisplayedInternalBookingPackageId() {
    return this.venueMapDisplayedInternalBookingPackageId$.asObservable();
  }

  
  setVenueMapDisplayedInternalBookingPackageIds(ids : string[]) {
    this.venueMapDisplayedInternalBookingPackageIds$.next(ids);
  }

  getVenueMapDisplayedInternalBookingPackageIds() {
    return this.venueMapDisplayedInternalBookingPackageIds$.asObservable();
  }

  setEventConfigurationTimeslots(timeslots : TimeSlot[]) {
    this.eventConfigurationTimeslots$.next(timeslots);
  }

  getEventConfigurationTimeslots() {
    return this.eventConfigurationTimeslots$.asObservable();
  }

  resetValues() {
    this.displayedBookingPackages$.next([]);
    this.displayedFreezedBookingPackages$.next([]);
    this.venueMapDisplayedInternalBookingPackageId$.next("");
  }
}
