<div class="temples-container main-layout-container card-list-layout" [id]="elementID"
  (window:resize)="onResize($event)">
  <div class="container-wrapper">
    <div class="card-container container">
      <h3 *ngIf="layoutAttribute.title" style="z-index: 1; padding-left: 6px;" class="card-title-header">
        {{ layoutAttribute.title }}
      </h3>
      <div class="cart-toggle-container" *ngIf="layoutAttribute.isMapView && layoutAttribute.cardLayouts.length > 0 "
        [style.padding-top]="layoutAttribute.title.length > 0 ? paddingTop : 0">
        <div class="btn-group">
          <div class="btn" [ngClass]="{
              active: selectedCardListView === CARD_LIST_TOGGLE.MAP_VIEW
            }" (click)="onCardViewChange(CARD_LIST_TOGGLE.MAP_VIEW)">
            <span class="material-icons"> location_on </span>
          </div>
          <div class="btn" [ngClass]="{
              active: selectedCardListView === CARD_LIST_TOGGLE.CARD_VIEW
            }" (click)="onCardViewChange(CARD_LIST_TOGGLE.CARD_VIEW)">
            <span class="material-icons">format_list_bulleted</span>
          </div>
        </div>
      </div>
      <div class="row card-List" *ngIf="selectedCardListView === CARD_LIST_TOGGLE.CARD_VIEW"
        [style.padding-top]="paddingTop" [style.padding-bottom]="paddingBottom" infinite-scroll
        (scrolled)="onScroll($event)">
        <div #cardContainerRef></div>
        <div class="no-data-message" *ngIf="this.layoutAttribute.cardLayouts.length == 0" style="color:black !important">
          {{'No data matched for the search criteria!' | uppercase | optimotranslate}}
          <div style="display: none;">{{GET_RESULT_COUNT}}</div>
        </div>
        <div id="mobile-view" class="pagination-container" [ngClass]="layoutAttribute.paginationPosition" *ngIf="
            layoutAttribute.paging &&
            layoutAttribute.isPagination &&
            selectedCardListView === CARD_LIST_TOGGLE.CARD_VIEW
          ">

          <mat-paginator id="paginator-mobile" [length]="layoutAttribute.paging.totalResultCount"
            [pageSize]="layoutAttribute.paging.size" [pageSizeOptions]="[5, 10, 15]" (page)="pageEvents($event)" [pageIndex]="pageNo">
          </mat-paginator>

        </div>
      </div>
      <div class="map-view"
        *ngIf="layoutAttribute.cardLayouts.length > 0 && selectedCardListView === CARD_LIST_TOGGLE.MAP_VIEW"
        [style.padding-top]="paddingTop" [style.padding-bottom]="paddingBottom">
        <agm-map *ngIf="canShowMap" class="h-100" [latitude]="rootLat" [longitude]="rootLng" [zoom]="15"
          [disableDefaultUI]="false" [zoomControl]="false" [fitBounds]="true">
          <agm-marker *ngFor="let v of venues" [latitude]="(v.latitude)" [longitude]="(v.longitude)" [title]="v.name"
            [agmFitBounds]="true" (markerClick)="onVenueLocationSelect(v.id, infoWindow)">
            <agm-info-window #infoWindow (isOpen)="isInfoWindowOpen(v.id)">
              <div class="agm-window-container">
                <!-- <div class="row my-2" *ngFor="let e of eventsForVenue">
                  <div class="col-6">{{ e.name }}</div>
                  <div class="col-6">
                    <button class="btn m-auto" (click)="bookEvent(e)">Select</button>
                  </div>
                </div> -->
                <!-- Topic Cards -->

                <div id="cards_landscape_wrap-2">
                  <div class="row" style="padding-bottom: 15px;" *ngFor="let e of eventsForVenue">
                    <div class="col-md-12 col-sm-12 col-xs-12 col-xl-12" style="padding: 0px 2px 0px 2px;">
                      <div class="card-flyer">
                        <div class="text-box">
                          <div class="image-box">
                            <img class="imgCard" *ngIf="e.documents && e.documents.length > 0; else noImage"
                              [src]="e.documents[0].documentFile" alt="" />
                            <ng-template #noImage>
                              <img
                                src="https://cdn-5fcbf5c4c1ac1a221c18568d.closte.com/wp-content/themes/ryse/assets/images/no-image/No-Image-Found-400x264.png"
                                class="imgCard" />
                            </ng-template>
                          </div>
                          <div class="text-container">
                            <h6 style="padding-bottom: 15px; color: #0ed10e;">{{ e.name }}</h6>
                            <div class="row">
                              <div class="col-md-6 col-sm-12 col-xs-6 col-xl-6">
                                <span class="align-middle">
                                  <a style="
                                      font-size: 10px !important;
                                      color: gray;
                                    ">from</a>
                                  $<strong>{{+ e.priceMinimum | number :
                                    '1.2-2'}}</strong></span>
                              </div>
                              <div class="col-md-6 col-sm-12 col-xs-6 col-xl-6 fullwidthBtn">
                                <button class="btn bg-green" style="min-width: 100%; color: white;" type="button"
                                  (click)="bookEvent(e)">
                                  <strong>
                                    {{'Buy Tickets' | uppercase | optimotranslate}}
                                  </strong>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
    </div>
  </div>
</div>
