import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';


import {BehaviorSubject, Observable } from "rxjs";

import { HttpResponse, HttpResponseBase, HttpEvent } from '@angular/common/http';
import { map, catchError, switchMap, debounceTime } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';

import { RecurrenceOption } from 'src/modules/models/regular-portal/recurrence-option';
import { BaseParam } from '../models/base-param';
import { AdHocDocument } from '../models/booking/adhoc-document';
import { QuestionSummary } from 'src/modules/models/booking/question-summary';
import { Question } from '../models/item/question'
import { Booking, DeliveryMethod } from 'src/modules/models/booking/booking';
import { BookingSearchResult } from 'src/modules/models/booking/booking-search/booking-search-result';
import { InvoiceSearchResult, InvoicePreviewResult } from 'src/modules/models/booking/booking-search/invoice-search-result';
import { DocumentPreviewResult, DocumentSearchResult } from 'src/modules/models/booking/booking-search/document-search-result';
import { ParameterMapper } from 'src/modules/services/base/parameter-mapper';
import { jsonIgnoreReplacer } from 'json-ignore';
import { BookingFilter } from '../models/booking/booking-search/booking-filter';
import { filter } from 'lodash';
import { environment } from 'src/environments/environment';
import { FILE_TYPE } from '../models/public-web/enum';
import { AppSetting } from '../models/settings/casual-portal/app-setting';
import {EnquiryBooking} from '../models/enquiry/enquiryBooking';


@Injectable({
  providedIn: 'root'
})

export class BookingService {
  appSetting: AppSetting;
  private bookingRefSource = new BehaviorSubject<string>(null);
  currentBookingRef = this.bookingRefSource.asObservable();
  constructor(private _baseService: BaseService) {
    this.appSetting = environment.AppSetting as AppSetting
  }
  BookingEligibleDeliveryMethods(bookingId: string): Observable<DeliveryMethod[]> {
    return this._baseService.Internal_WebAPI_GET("api/booking/" + bookingId + "/eligible-delivery-methods").pipe(map((response: any) => {
      return response.body.data && response.body.data?.data.length > 0 ? response.body.data.data.map(x => new DeliveryMethod().initialize(x)) : [];
    }));
  }
  BookingSave(postdata: Booking, orderItemName: string[], baseParam?: BaseParam): Observable<any> {

    let changingOrder = [];
    changingOrder.push("PrivateEventFacilityTimeslot");
    changingOrder.push("Allocation");
    if (orderItemName.length > 0) {
      orderItemName.forEach(a => changingOrder.push(a));
    }
    changingOrder.push("BookingPackage");

    //Task 29217
    var filter = "";
    if (baseParam) {
      filter = ParameterMapper.mapFilters(baseParam.filter);
    }

    var json = new Serializer().serialize(JSON.parse(JSON.stringify(postdata, jsonIgnoreReplacer)));


    json.included.sort((a, b) => changingOrder.indexOf(a.type) - changingOrder.indexOf(b.type));
    json.included.reverse();
    console.log(JSON.stringify(json));

    //return this._baseService.WebAPIPOST("api/V4.1/bookings?include=BookingQuestionnaires,bookingquestions,question,bookingPackages,package,bookingitems,item", json).pipe(map(response => {
    //    var booking = (new Deserializer().deserialize(response));
    //    console.log("BookingSave object ", booking)
    //    return booking;
    //}));


    return this._baseService.Internal_WebAPI_POST("api/booking/invoke?id=106&" + "&include=BookingQuestionnaires,bookingquestions,question,bookingPackages,package,bookingitems,item,answerChoices,BookingAnswerChoice,bookingQuestionChoices&" + filter, json).pipe(map((response: HttpResponse<any>) => {
      let retObj = {};
      var JsonData = JSON.parse(response.body['data']);

      let resp: any = JsonData

      if (resp && resp.data)
        retObj['booking'] = (new Deserializer().deserialize(resp));
      if (resp && resp.errors && resp.errors.length > 0)
        retObj['errors'] = resp.errors[0].detail;

      return retObj;
    }));
  }
  //BookingNotePatch(postdata: Booking): Observable<any> {
  //    return this._baseService.Internal_WebAPI_PATCH("api/booking/note-patch", postdata).pipe(map(response => {
  //        console.log("Note Patch", response)
  //        return response;
  //    }));
  //}

  BookingPatch(id: string, postdata: Booking, isClientBookingApproval: boolean = false, clientEmail: string = null, baseParam?: BaseParam): Observable<any> {
    console.log(postdata);

    let url = `api/booking/${id}`;

    let queryParams = [];

    if (clientEmail != null) {
      queryParams.push(`clientEmail=${clientEmail}`);
    }

    if (isClientBookingApproval) {
      queryParams.push(`isClientBookingApproval=${isClientBookingApproval}`);
    }

    if (baseParam) {
      let filter = ParameterMapper.mapFilters(baseParam.filter);
      queryParams.push(filter);
    }

    if (queryParams.length > 0) {
      url += '?' + queryParams.join('&');
    }

    return this._baseService.Internal_WebAPI_PATCH(url, postdata).pipe(map(response => {
      console.log("Booking Patch", response);
      return response;
    }));
  }


  BookingSearch(baseParam: BaseParam): Observable<BookingSearchResult> {

    var filter = "";
    if (baseParam) {
      filter = ParameterMapper.mapFilters(baseParam.filter);
      filter = ParameterMapper.mapFilters(baseParam.paging, filter);
      if (baseParam.sort) {
        if (filter.length > 0) filter += "&";
        filter += "sort=" + baseParam.sort;
      }
    }
    return this._baseService.Internal_WebAPI_GET("api/booking/?" + filter).pipe(
      map((response: HttpResponse<any>) => {
        var res = new BookingSearchResult();
        if (response.ok) {
          res.data = response.body.Data;
          res.total = response.body.Total;
          res.isError = response.body.IsError;
          if (response.body.Error) {
            res.errorMessage = response.body.Error.ErrorMessage;
            res.errorCode = response.body.Error.ErrorCode;
          } else if (response.body.Errors) {
            res.errorMessage = response.body.Errors[0].ErrorMessage;
            res.errorCode = response.body.Errors[0].ErrorCode;
          }
        }
        return res;
      })
    );
  }
  InvoiceSearch(id: string, baseParam: BaseParam): Observable<InvoiceSearchResult> {

    var filter = "";
    if (baseParam) {
      filter = ParameterMapper.mapFilters(baseParam.filter);
      filter = ParameterMapper.mapFilters(baseParam.paging, filter);
      if (baseParam.sort) {
        if (filter.length > 0) filter += "&";
        filter += "sort=" + baseParam.sort;
      }
    }
    return this._baseService.Internal_WebAPI_GET("api/booking/" + id + "/invoices/?" + filter).pipe(
      map((response: HttpResponse<any>) => {
        var res = new InvoiceSearchResult();
        if (response.ok) {
          res.data = response.body.Data;
          res.total = response.body.Total;
          res.isError = response.body.IsError;
          if (response.body.Error) {
            res.errorMessage = response.body.Error.ErrorMessage;
            res.errorCode = response.body.Error.ErrorCode;
          }
        }
        return res;
      })
    );
  }
  InvoiceView(id: string): Observable<InvoicePreviewResult> {

    return this._baseService.Internal_WebAPI_GET("api/booking/invoices/" + id).pipe(
      map((response: HttpResponse<any>) => {
        var res = new InvoicePreviewResult();
        if (response.ok) {
          res.data = response.body.Data;
          res.isError = response.body.IsError;
          if (response.body.Error) {
            res.errorMessage = response.body.Error.ErrorMessage;
            res.errorCode = response.body.Error.ErrorCode;
          }
        }
        return res;
      })
    );
  }

  BookingDocumentSearch(id: string): Observable<DocumentSearchResult> {
    let url = "api/booking/" + id + "/documents";
    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((response: HttpResponse<any>) => {
        var res = new DocumentSearchResult();
        if (response.ok) {
          res.data = response.body.data;
          res.total = response.body.total;
          res.isError = response.body.isError;
          if (response.body.Error) {
            res.errorMessage = response.body.Error.ErrorMessage;
            res.errorCode = response.body.Error.ErrorCode;
          }
        }
        return res;
      })
    );
  }

  DocumentView(bookingId: string, documentId): Observable<InvoicePreviewResult> {

    return this._baseService.Internal_WebAPI_GET("api/booking/" + bookingId + "/documents/" + documentId).pipe(
      map((response: HttpResponse<any>) => {
        var res = new DocumentPreviewResult();
        if (response.ok) {
          res.data = response.body.Data;
          res.isError = response.body.IsError;
          if (response.body.Error) {
            res.errorMessage = response.body.Error.ErrorMessage;
            res.errorCode = response.body.Error.ErrorCode;
          }
        }
        return res;
      })
    );
  }

  DocumentDownload(bookingId, doc) {
    let openWordAsPDF = this.appSetting.OpenWordAsPDF;
    if((doc.fileType.toLowerCase() == FILE_TYPE.DOC || doc.fileType.toLowerCase() == FILE_TYPE.DOCX)) {
      if(openWordAsPDF) {
        this.DocumentView(bookingId, doc.id).subscribe((res) => {
          if (res.data) {
            window.open(res.data, '_blank');
          }
        })
      } else {
        window.open(doc.documentFileURL, '_blank');
      }
    } else {
      window.open(doc.documentFileURL, '_blank');
    }
  }

  GetBookingPayamentSchedule(bookingId) {
    return this._baseService.InternalWebAPIGET("api/booking/" + bookingId + "/payment-schedule&disableSpinner").pipe(
      map((response : any) => {
        return response.data;
      })
    );
  }

  BookingSelect(id: string, _filter?: BookingFilter, Venues = []): Observable<any> {

    var any = Venues.findIndex(id => id==0)
    if(any>=0){
      Venues = [];
    }

    var filters ="";
        if(_filter){
            filters = ParameterMapper.mapFilters(_filter);
        }

    return this._baseService.InternalWebAPIGET("api/booking/" + id +"?"+filters+"&venues=" +Venues ).pipe(
      map((response) => {
        return response;
      })
    );
  }
  //need to remove use invoice search
  BookingInvoiceSearch(id: string): Observable<any> {

    return this._baseService.InternalWebAPIGET("api/booking/" + id + "/Invoices").pipe(
      map((response) => {
        return response;
      })
    );
  }

  BookingInvoiceSave(bookingId: string): Observable<any> {
    return this._baseService.Internal_WebAPI_POST("api/booking/" + bookingId + "/invoices", null).pipe(

      map((response: HttpResponse<any>) => {
        var res = new InvoiceSearchResult();
        if (response.ok) {
          res.data = response.body.data;
          res.isError = response.body.isError;
          if (response.body.data.errors != null) {
            res.errorMessage = response.body.errors[0].errorMessage;
            res.errorCode = response.body.errors[0].errorCode;
          }
        }
        return res;
      })
    );
  }

  // temporry - to cross check the values
  // BookingSelectWebapi(id: string): Observable<any> {
  //     return this._baseService.WebAPIGet("api/v4.1/bookings/" + id + "?include=bookingitems,allocations,timeslot,asset,BookingQuestionnaires,bookingquestions,question,BookingItemAssociations").pipe(
  //         map((response) => {
  //             var json = new Deserializer().deserialize(response);
  //             console.log("bookingselect Webapi -", json)
  //             return json;
  //         })
  //     );
  // }

  BookingDocumentsSave(id: string, postdata: AdHocDocument): Observable<any> {
    var json = new Serializer().serialize(JSON.parse(JSON.stringify(postdata, jsonIgnoreReplacer)));
    return this._baseService.Internal_WebAPI_POST("api/booking/invoke?id=113&" + "bookingId=" + id, json).pipe(map((response: HttpResponse<any>) => {
      // var JsonData = JSON.parse(response.body['data']);
      // var data = (new Deserializer().deserialize(JsonData));
      // console.log("BookingDocumentSave", data)
      return response;
    }));
  }

    UploadBookingDocuments(postdata: AdHocDocument,isDelete:boolean): Observable<any> {
        var json = new Serializer().serialize(JSON.parse(JSON.stringify(postdata, jsonIgnoreReplacer)));
        return this._baseService.Internal_WebAPI_POST("api/booking/UploadBookingDocument?isDelete="+isDelete, json).pipe(map((response: HttpResponse<any>) => {
            return response;
        }));
    }

    ////Not in use, currently used as internal Function in MVC
    // SendBookingEnquiryConfirmationEmail(id: string, email: string, BookingRef: string, fromEmail: string, packageClassId: string): Observable<any> {
    //     return this._baseService.InternalWebAPIGET("api/Booking/SendBookingConfimationEmail?email=" + email + "&BookingId=" + id + "&BookingRef=" + BookingRef + "&fromEmail=" + fromEmail + "&packageClassId=" + packageClassId).pipe(
    //         map((response) => {
    //             return response;
    //         })
    //     );

  // }

  BookingCancelPatch(id: string, bookingCancelPatchObject: Booking): Observable<any> {
    return this._baseService.Internal_WebAPI_PATCH("api/Booking/" + id + "/cancel", bookingCancelPatchObject).pipe(
      debounceTime(2000),
      map((response) => {
        //var JsonData = JSON.parse(response['data']);
        //var data = (new Deserializer().deserialize(JsonData));
        return response;
      })
    );
  }

    PostQuestionAndAnswer(questionSummaries: QuestionSummary[], bookingID: string) {
        return this._baseService.Internal_WebAPI_POST("api/booking/answer-post/" + bookingID, questionSummaries).pipe(
            map((response: HttpResponse<any>) => {
                return response;
            })
        );
    }


    PostEnquiryBooking(enquiryBooking: EnquiryBooking){
    return this._baseService.Internal_WebAPI_POST("api/enquiry" , enquiryBooking).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      })
    );
    }

  PostBulkQuestionAndAnswer(questionSummaries: QuestionSummary[], bookingID: string) {
    return this._baseService.Internal_WebAPI_POST("api/booking/" + bookingID + "/answers", questionSummaries).pipe(
      map((response) => {
        return response;
      })
    );
  }

  PostCartDetails(cart: any) {

    return this._baseService.Internal_WebAPI_POST("api/booking/cartData-post", cart).pipe(
        map((response) => {
            return response;
        })
    );
}

  //BookingSearchUisngContactRef(id: string, pagenumber: number, pagesize: number, sort: string, bookingRef: string, bookingDateFrom: string, bookingDateTo: string, eventDateFrom: string, eventDateTo: string, statusIds: string, isPendingPayments: boolean): Observable<Booking[]> {
  BookingPackages(id: string): Observable<any> {
    return this._baseService.InternalWebAPIGET("api/booking/invokeGet?id=114&" + "&BookingId=" + id + "&include=" + "bookingpackages,package").pipe(
      map((response: HttpResponse<any>) => {
        var JsonData = JSON.parse(response['data']);
        var json = new Deserializer().deserialize(JsonData);

        return json;

      })
    );
  }


  PostTask(jsonObj) {

    var json = new Serializer().serialize(jsonObj);


    return this._baseService.Internal_WebAPI_POST("api/booking/task-post", json).pipe(
      map((response: any) => {
        var JsonData = JSON.parse(response.body.data);
        var result = new Deserializer().deserialize(JsonData);
        return result;
      })
    )

  }

  BooingExport(bookingId, _filter?: BookingFilter, venues = []): Observable<any> {

    var any = venues.findIndex(id => id==0)
    if(any>=0){
      venues = [];
    }

    var filters = "";
    if(_filter){
        filters = ParameterMapper.mapFilters(_filter);
    }
    //  var json = "";
    return this._baseService.InternalWebAPIGET("api/booking/booking-export/" + bookingId + "?isCSV=1&"+ filters+ "&venues=" +venues.toString()).pipe(
        map((response) => {
            //var json = new Deserializer().deserialize(response);

            return response;
        })
    )



}

  passBookingRef(bookingRef: string) {
    this.bookingRefSource.next(bookingRef);
  }



  //    var _datefilter = '';
  //    var _bookingReffilter = '';
  //    var _bookingStatusfilter = '';
  //    var _pendingPaymentsfilter = '';

  //    if (isPendingPayments) {
  //        _pendingPaymentsfilter = `&InvoiceStatus=1,2&PaymentStatus=3,2`;

  //    }

  //    if (bookingRef) {
  //        _bookingReffilter = `&BookingRef=${bookingRef}`;
  //    }

  //    if (statusIds) {
  //        _bookingStatusfilter = `&BookingStatusId=${statusIds}`;
  //    }

  //    if ((bookingDateFrom && bookingDateFrom.length) && (bookingDateTo && bookingDateTo.length) && (eventDateFrom && eventDateFrom.length) && (eventDateTo && eventDateTo.length)) {
  //        //all dates filter applied
  //        _datefilter = `&EventDateFrom=${eventDateFrom}&EventDateTo=${eventDateTo}&BookingDateFrom=${bookingDateFrom}&BookingDateTo=${bookingDateTo}`;

  //    }
  //    else if ((bookingDateFrom && bookingDateFrom.length) && (bookingDateTo && bookingDateTo.length)) {
  //        //all booking date filter applied
  //        _datefilter = `&BookingDateFrom=${bookingDateFrom}&BookingDateTo=${bookingDateTo}`;

  //    } else if ((eventDateFrom && eventDateFrom.length) && (eventDateTo && eventDateTo.length)) {
  //        //all event date filter applied
  //        _datefilter = `&EventDateFrom=${eventDateFrom}&EventDateTo=${eventDateTo}`;

  //    }
  //    else if (bookingDateFrom && bookingDateFrom.length) {
  //        //only booking Start date
  //        _datefilter = `&BookingDateFrom=${bookingDateFrom}`;


  //    } else if (bookingDateTo && bookingDateTo.length) {
  //        //only booking End Date
  //        _datefilter = `&BookingDateTo=${bookingDateTo}`;


  //    } else if (eventDateFrom && eventDateFrom.length) {
  //        //only Event Start date
  //        _datefilter = `&EventDateFrom=${eventDateFrom}`;


  //    } else if (eventDateTo && eventDateTo.length) {
  //        //only Event End Date
  //        _datefilter = `&EventDateTo=${eventDateTo}`;


  //    }


  //    return this._baseService.WebAPIGet('api/v4.1/bookings?ContactRef=' + id + '&page.number=' + pagenumber + '&page.size=' + pagesize + '&sort=' + sort + _datefilter + _bookingReffilter + _bookingStatusfilter + _pendingPaymentsfilter).pipe(
  //        map((response) => {
  //            var json = new Deserializer().deserialize(response);
  //            return json;
  //        })
  //    );


  //}

  //GetAllInvoiceForSelectedBooking(bookingID: string): Observable<any[]> {
  //    return this._baseService.WebAPIGet("api/v4.1/bookings/" + bookingID + "/invoices").pipe(
  //        map((response) => {
  //            var json = new Deserializer().deserialize(response);

  //            return json;
  //        })
  //    );
  //}
}
