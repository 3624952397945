import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ErrorLog } from "src/modules/models/public-web/error";
import { SearchBaseFilter } from "src/modules/models/public-web/filter";
import { ICart, Store } from "src/modules/store";
import { SetPageConfigurations } from "../../store/public-web/public-web-actions";
import { PAGE_TYPE } from "../../models/public-web/enum";
import { BaseService } from "../base/base.service";
import { RenderComponentService } from "./render-component.service";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PageLayoutService {
  cart$: Observable<ICart>;
  clientCategoryId: string;

  constructor(
    private renderService: RenderComponentService,
    private _baseService: BaseService,
    private route: Router,
    private store: Store<any>) {

    this.cart$ = this.store.select("cart");
    this.cart$.subscribe((state) => {
      if (
        state.contact &&
        state.contact.client &&
        state.contact.client.clientCategoryId
      ) {
        this.clientCategoryId = state.contact.client.clientCategoryId.toString();
      }
    });
  }

  onLoadPageLayout(pageType: PAGE_TYPE, searchBaseFilter: SearchBaseFilter = undefined, eventCategoryId = 0, eventId = 0, packageId = 0, sessionCode = '', searchText = '') {
    let url = "api/public/layout/" + pageType + "?";
    if (!searchBaseFilter) {
      searchBaseFilter = new SearchBaseFilter();
    }

    url = searchBaseFilter.addFilter(url);
    switch (pageType) {
      case PAGE_TYPE.EVENT_LIST:
        url += `&filters.EventCategoryID=${eventCategoryId}`;
        break;
      case PAGE_TYPE.EVENT_INFO:
        url += eventCategoryId !== 0 ? `&filters.EventCategoryID=${eventCategoryId}` : "";
        url += eventId !== 0 ? `&filters.EventID=${eventId}` : "";
        break;
      case PAGE_TYPE.PACKAGE_LIST:
        if (eventId != null)
          url += eventId !== 0 ? `&filters.EventID=${eventId}` : "";
        if (searchText != '')
          url += `&searchFilter.SearchText=${searchText}`;
        url += packageId !== 0 ? `&filters.PackageID=${packageId}` : "";
        url += sessionCode != '' ? `&filters.sessionCode=${sessionCode}` : "";
        break;
      case PAGE_TYPE.PACKAGE_INFO:
        url += packageId !== 0 ? `&filters.PackageID=${packageId}` : "";
        break;
      case PAGE_TYPE.DATE_AND_SESSION:
        url += eventId !== 0 ? `&filters.EventID=${eventId}` : "";
        break;
      case PAGE_TYPE.UPSELLS:
        url += packageId !== 0 ? `&filters.PackageID=${packageId}` : "";
        break;
    }
    if (searchBaseFilter.paging.size && searchBaseFilter.paging.number)
      url += `&page.size=${searchBaseFilter.paging.size}&page.number=${searchBaseFilter.paging.number}`

    return this._baseService.Internal_WebAPI_GET(url).subscribe((data) => {
      let pageLayoutTemplates = data.body.data;
      if (pageLayoutTemplates != null && pageLayoutTemplates.length > 0) {
        this.store.dispatch(new SetPageConfigurations(pageLayoutTemplates[0].configurations));
        this.renderService.createLayoutForPage(pageLayoutTemplates[0].layoutObjects, searchBaseFilter);
      } else {
        this.returnErrorLog(url);
      }
    });
  }

  getPageLayout(pageType: PAGE_TYPE, searchBaseFilter: SearchBaseFilter = undefined, eventCategoryId = 0,
    eventId = 0,
    packageId = 0,
    disableSpinner = false
  ): Observable<any> {
    let url = "api/public/layout/" + pageType + "/configuration?";
    if (!searchBaseFilter) {
      searchBaseFilter = new SearchBaseFilter();
    }
    if (this.clientCategoryId) {
      searchBaseFilter.clientCategoryId = this.clientCategoryId;
    }
    url = searchBaseFilter.addFilter(url);
    if (eventCategoryId) {
      url += `&filters.EventCategoryID=${eventCategoryId}`;
    }
    if (disableSpinner) {
      url += '&disableSpinner'
    }
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((response: any) => {
      return response.body;
    }));
  }

  private returnErrorLog(url: string) {
    //get route
    let route = this.route.url;
    //check if route has string event-list
    if (route.includes("event-list")) {
      //navigate to page not found with a parameter
      this.route.navigate(["/page-not-found"], {
        //set errorMessage to url parameter
        queryParams: {
          errorMessage: "NO LAYOUTS FOUND!",
          errorCode: "400",
        },
      });
    } else {
      const error = new ErrorLog();
      error.message = "no content return from page layout return";
      error.url = url;
      // error.loggedInUser =
      // this.errorService.insertError(error).subscribe((mess) => console.log(mess));
      this.route.navigate(["/page-not-found"]);
    }
  }
}
