import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseParam, SearchBaseFilter } from 'src/modules/models/public-web/filter';
import { Package } from 'src/modules/models/public-web/Package';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { BaseService } from '../base/base.service';

import { Paging } from 'src/modules/models/base-param';
import { Item } from 'src/modules/models/public-web/item/item';
import { ICart, Store } from 'src/modules/store';
import { AttendeeCaptureProfile } from 'src/modules/models/booking/Attendee';
import { PackageSession } from 'src/modules/models/public-web/PackageSession';
import { PBUtility } from 'src/modules/public-web/pb-utility';

@Injectable({
  providedIn: 'root'
})
export class PublicPackageService {

  cart$: Observable<ICart>;
  clientID: string;
  paging: Paging;
  clientCategoryId: string;
  isAgentAccess: boolean;
  clientSalesChannelId: number;
  appSetting: AppSetting;

  constructor(private _baseService: BaseService, private store: Store<any>) {
    this.appSetting = environment.AppSetting as AppSetting;
    this.cart$ = this.store.select("cart");
    this.cart$.subscribe((state) => {
      if (state.contact && state.contact.client && state.contact.client.clientCategoryId) {
        this.clientID = state.contact.client.id;
        this.clientCategoryId = state.contact.client.clientCategoryId.toString();
        this.clientSalesChannelId = state.contact.client.salesChannelId;
        this.isAgentAccess = PBUtility.canAgentAccess(state.contact);
      }

    });
  }
  private getPortalConcessions(): any[] {
    let portalConcessions = [];
    if (environment.AppSetting) {
      const appSett = environment.AppSetting as AppSetting;
      if (appSett.BookingConfig && appSett.BookingConfig.PriceConcessions) {
        appSett.BookingConfig.PriceConcessions.forEach(pc => { portalConcessions.push(pc.Id) });
      }
    }
    return portalConcessions;
  }

  getPublicPackages(filter: BaseParam, searchFiler: SearchBaseFilter, disableSpinner = false): Observable<any[]> {
    let url = 'api/public/public-package?';
    if (searchFiler) {
      if(this.appSetting.ClientLoginIsRequired && this.isAgentAccess && this.clientSalesChannelId) {
        searchFiler.salesChannelId = this.clientSalesChannelId;
      } else {
        searchFiler.salesChannelId = PBUtility.getDefaultSalesChannelId();
      }
      url = searchFiler.addFilter(url, 'filters')
    }
    url += filter.eventID ? `&filters.EventID=${filter.eventID}` : '';
    url += filter.packageID ? `&filters.PackageID=${filter.packageID}` : '';
    if (disableSpinner) {
      url += '&disableSpinner';
    }

    return this._baseService.Internal_WebAPI_GET(url).pipe(map((resp: any) => {
        if(resp.isError) {
          return undefined
        }else if(resp.body){
          return resp.body.data.data.map(d => {
            return new Package().initialize(d)
        })
      }}))
  }

  getPublicPackageSession(filter: BaseParam, searchFiler: SearchBaseFilter): Observable<any[]> {
    let url = 'api/public/package-session?';
    if (searchFiler) {
      if(this.appSetting.ClientLoginIsRequired && this.isAgentAccess && this.clientSalesChannelId) {
        searchFiler.salesChannelId = this.clientSalesChannelId;
      } else if(searchFiler.salesChannelId != 0) {
        searchFiler.salesChannelId = PBUtility.getDefaultSalesChannelId();
      }
      url = searchFiler.addFilter(url, 'filters')
    }
    url += filter.eventID ? `&filters.EventID=${filter.eventID}` : '';
    url += filter.packageID ? `&filters.PackageID=${filter.packageID}` : '';

    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((d: any) => {
        if (!!d.body && d.body.data.length > 0)
          return d.body.data.map(s => {
            return new PackageSession().initialize(s)
          })
        else return []
      }))
  }

  searchUpsellItems(filter: BaseParam, searchParams: SearchBaseFilter, disableSpinner=false): Observable<any> {
    let url = `api/public/upsell-items?page.number=${searchParams.paging.number}&page.size=${searchParams.paging.size}&filters.itemName=${searchParams.itemName}&`;
    url += filter.packageID ? `filters.PackageID=${filter.packageID}` : '';
    url += searchParams.fromDate != '' ? `&filters.SessionFromDate=${searchParams.fromDate}` : '';
    url += searchParams.toDate != '' ? `&filters.SessionToDate=${searchParams.toDate}` : '';

    if (disableSpinner) {
      url += '&disableSpinner';
    }

    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((response: any) => {
        const totalResults = response.body.data.meta.totalResults
        const json = response.body.data.data;
        const lst: Item[] = [];
        const portalConcessions = this.getPortalConcessions();
        if (json)
          json.forEach((c, i) => {
            if (c) {
              var item = (c as Item);
              if (c.itemSalesChannels) {
                let salesChannel = c.itemSalesChannels.filter(salesChannel => salesChannel.Id == "5" && salesChannel.allowImmediateConfirmedBookings == 1);
                item.allowImmediateConfirmedBookings = (salesChannel.length > 0) ? 1 : 0;
              }
              item.qty = 0;
              item.maxQuantity = 10
              // item.maxQuantity = c.qtyPerDay

              item.question = c.itemQuestion;
              if (c.itemPriceGroups)
                item.itemPriceGroups = c.itemPriceGroups.filter(ipg => ipg.concessionId && portalConcessions.indexOf(ipg.concessionId.toString()) >= 0);
              else
                item.itemPriceGroups = [];

              if (item.itemPriceGroups.length > 0) {
                lst.push(item);
              }
            }
          });
        return { "lst": lst, "totalResults": totalResults }
      })
    );
  }

  selectPackage(id, searchFiler: SearchBaseFilter = new SearchBaseFilter()): Observable<any> {
    let url = `api/public/public-package/${id}`;
    url = searchFiler.addFilter(url, 'filters')
    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((response: any) => {
        if (response.body.data)
          return new Package().initialize(response.body.data)
        else return undefined;
      })
    );
  }
  selectPackageAttendeeCaptureProfile(packageID): Observable<AttendeeCaptureProfile> {
    let url = `api/public/public-package/${packageID}/attendee-capture-profile`;
    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((response: any) => {
        return response.body.data || undefined
      })
    );
  }


}
