import { Injectable } from '@angular/core';
import { env } from 'process';
import { environment } from 'src/environments/environment';
import { SystemService } from 'src/modules/services/system.service';
import { ClientRegistration, Placeholder, Address, AddressField, Corporate, CorporateField } from 'src/modules/models/settings/casual-portal/client-registration-config';
import { SystemOption } from './models/public-web/SystemOption';
import _ from 'lodash';

@Injectable()
export class SystemConfig {

    constructor(public systemService: SystemService) { }

    async loadAppConfiguration() {
        if (!environment.AppSettingsLoaded) {
            return await this.systemService.loadConfiguration().toPromise().then(res => {

                environment.LeadDaysForBooking = res.BookingConfig.LeadDaysForBooking;
                environment.DisableDatePickerForLeadDays = res.BookingConfig.DisableDatePickerForLeadDays;
                environment.BookingLeadDayValidationMessage = res.BookingConfig.BookingLeadDayValidationMessage;

                if (res.DateFormat != null && res.DateFormat.length > 0) environment.DateFormat = res.DateFormat;
                if (res.TimeFormat != null && res.TimeFormat.length > 0) environment.TimeFormat = res.TimeFormat;
                if (res.DateTimeFormat != null && res.DateTimeFormat.length > 0) environment.DateTimeFormat = res.DateTimeFormat;

                environment.SalesChannel = res.BookingConfig.SalesChannel;
                environment.Currency = res.BookingConfig.Currency;
                environment.BookingStatus = res.BookingConfig.BookingStatus;
                environment.BookingApproveStatus = res.BookingConfig.BookingApproveStatus;
                environment.BookingExpireStatus = res.BookingConfig.BookingExpireStatus;

                environment.PriceDisplayOption = res.BookingConfig.PriceDisplayOption;
                environment.PriceMinConsessionId = res.BookingConfig.PriceMinConsessionId;
                environment.PriceMaxConsessionId = res.BookingConfig.PriceMaxConsessionId;
                environment.DefaultBussinessAreaId = res.BookingConfig.DefaultBussinessAreaId;
                environment.DefaultPackageClassId = res.BookingConfig.DefaultPackageClassId;
                environment.PriceTypeId = res.BookingConfig.PriceTypeId;
                environment.BookingExpiryInMinutes = res.BookingConfig.BookingExpiryInMinutes;

                //environment.BookingEntityConfigurationProfileId = res.BookingConfig.BookingEntityConfigurationProfileId.toString();
                //environment.PackageEntityConfigurationProfileId = res.BookingConfig.PackageEntityConfigurationProfileId.toString();
                //environment.ItemEntityConfigurationProfileId = res.BookingConfig.ItemEntityConfigurationProfileId.toString();

                environment.GoogleAPIKEY = res.BookingConfig.GoogleAPIKEY;
                environment.PackageClasses = res.BookingConfig.PackageClases;
                environment.MinimumBookingDuration = res.BookingConfig.MinimumBookingDuration;
                environment.BookingSearchDescriptionTextLimitHigh = res.BookingConfig.BookingSearchDescriptionTextLimitHigh
                environment.BookingSearchDescriptionTextLimitMedium = res.BookingConfig.BookingSearchDescriptionTextLimitMedium
                environment.BookingSearchDescriptionTextLimitLow = res.BookingConfig.BookingSearchDescriptionTextLimitLow
                environment.IsDisableInputsInPayment = res.IsDisableInputsInPayment
                environment.AllowToDoManualInvoiceSearch = res.AllowToDoManualInvoiceSearch
                environment.IsDisableInputsInPayment = res.IsDisableInputsInPayment
                //environment.TermsandConditionLink = res.BookingConfig.TermsandConditionLink;
                environment.ReCaptchaSiteKey = res.ClientRegistrationConfig.ReCaptchaSiteKey;
                environment.DefaultCurrencyId = res.ClientRegistrationConfig.Currency.Id;
                environment.LatestFutureBookingDate = res.BookingConfig.LatestFutureBookingDate;
                environment.LatestFutureBookingDateErrorMessage = res.BookingConfig.LatestFutureBookingDateErrorMessage;
                environment.AllowToDoBookingForNonOperationalHours = (res.BookingConfig.AllowToDoBookingForNonOperationalHours && res.BookingConfig.AllowToDoBookingForNonOperationalHours.toLocaleLowerCase() == 'true');
                environment.BookingTermAndConditionMessage = res.BookingConfig.BookingTermAndConditionMessage;
                environment.CommunityClassAlertMessage = res.BookingConfig.CommunityClassAlertMessage;
                environment.IsBookingSearchByClient = res.BookingConfig.IsBookingSearchByClient;
                environment.FacilityFilters = res.BookingConfig.FacilityFilters;
                environment.BookingRestrictions = res.BookingConfig.BookingRestrictions;
                // Price calculation hours
                //environment.HalfDayFrom = (+res.BookingConfig.PriceTypeFilters.HalfDayHours.split(',')[0]);
                //environment.HalfDayTo = (+res.BookingConfig.PriceTypeFilters.HalfDayHours.split(',')[1]);
                //environment.FullDayFrom = (+res.BookingConfig.PriceTypeFilters.FullDayHours.split(',')[0]);
                //environment.FullDayTo = (+res.BookingConfig.PriceTypeFilters.FullDayHours.split(',')[1]);
                //environment.SessionFrom = (+res.BookingConfig.PriceTypeFilters.SessionHours.split(',')[0]);
                //environment.SessionTo = (+res.BookingConfig.PriceTypeFilters.SessionHours.split(',')[1]);

                // environment.UpsellPrice = res.BookingConfig.UpsellPrice;
                environment.BookingStates = res.BookingConfig.BookingStates;

                environment.CommunicationTypeValidation = res.ClientRegistrationConfig.CommunicationTypeValidation
                environment.DefaultCountryId = res.ClientRegistrationConfig.DefaultCountryId.Id;
                environment.DefaultCountryName = res.ClientRegistrationConfig.DefaultCountryId.Name;
                environment.PriceConcessionQuestionText = res.ClientRegistrationConfig.PriceConcessionQuestionText;
                environment.PriceConcessionAnswerOptions = res.ClientRegistrationConfig.PriceConcessionAnswerOptions;
                environment.IsEnableToCreateAdditionalDetailsSectionForIndividualClients = res.ClientRegistrationConfig.IsEnableToCreateAdditionalDetailsSectionForIndividualClients;
                environment.IsEnableToCreateAdditionalDetailsSectionForCorporateClients = res.ClientRegistrationConfig.IsEnableToCreateAdditionalDetailsSectionForCorporateClients;
                environment.IsEnableToUpdateAdditionalDetailsSectionForIndividualClients = res.ClientRegistrationConfig.IsEnableToUpdateAdditionalDetailsSectionForIndividualClients;
                environment.IsEnableToUpdateAdditionalDetailsSectionForCorporateClients = res.ClientRegistrationConfig.IsEnableToUpdateAdditionalDetailsSectionForCorporateClients;
                environment.IsEnableToCreateAdditionalDocumentSectionForIndividualClients = res.ClientRegistrationConfig.IsEnableToCreateAdditionalDocumentSectionForIndividualClients;
                environment.IsEnableToCreateAdditionalDocumentSectionForCorporateClients = res.ClientRegistrationConfig.IsEnableToCreateAdditionalDocumentSectionForCorporateClients;
                environment.IsEnableToUpdateAdditionalDocumentSectionForIndividualClients = res.ClientRegistrationConfig.IsEnableToUpdateAdditionalDocumentSectionForIndividualClients;
                environment.IsEnableToUpdateAdditionalDocumentSectionForCorporateClients = res.ClientRegistrationConfig.IsEnableToUpdateAdditionalDocumentSectionForCorporateClients;
                environment.IsPriceConcessionQuestionRequired = res.ClientRegistrationConfig.IsPriceConcessionQuestionRequired;
                environment.EnableWebPortalAccess = res.ClientRegistrationConfig.EnableWebPortalAccess;
                environment.EnableProfileImageUpload = res.ClientRegistrationConfig.EnableProfileImageUpload;

                environment.AutoPopulateUsernameFromEmail = res.ClientRegistrationConfig.AutoPopulateUsernameFromEmail;
                environment.DisableUsernameTextBox = res.ClientRegistrationConfig.DisableUsernameTextBox;

                environment.RequestToAccessReqularBookingHeaderText = res.ClientRegistrationConfig.RequestToAccessReqularBookingHeaderText;
                environment.RequestToAccessReqularBookingAcceptText = res.ClientRegistrationConfig.RequestToAccessReqularBookingAcceptText;
                environment.ReqularBookingAccessRequestHeaderText = res.ClientRegistrationConfig.ReqularBookingAccessRequestHeaderText;
                environment.ReqularBookingAccessRequestSubHeaderText = res.ClientRegistrationConfig.ReqularBookingAccessRequestSubHeaderText;
                environment.ReqularBookingAccessRequestReviewText = res.ClientRegistrationConfig.ReqularBookingAccessRequestReviewText;
                environment.ReqularBookingAccessRequestCompleteText = res.ClientRegistrationConfig.ReqularBookingAccessRequestCompleteText;
                environment.ProfileImageHeaderTextForIndividualClients = res.ClientRegistrationConfig.ProfileImageHeaderTextForIndividualClients;
                environment.ProfileImageHeaderTextForCorporateClients = res.ClientRegistrationConfig.ProfileImageHeaderTextForCorporateClients;

                environment.IsBookingSearchByClient = res.BookingConfig.IsBookingSearchByClient;
                environment.IsGroupByFacilityNameInBookingView = res.BookingConfig.IsGroupByFacilityNameInBookingView;
                if (res.BookingConfig.Messages != undefined && res.BookingConfig.Messages != null) {
                    environment.Messages.BookingDetailsPage = res.BookingConfig.Messages.BookingDetailsPage;
                    environment.Messages.BookingCartPageTopNavPannel = res.BookingConfig.Messages.BookingCartPageTopNavPannel;
                    environment.InsufficientSelectedTime = res.BookingConfig.Messages.InsufficientSelectedTime.replace("#LeadDays#", environment.LeadDaysForBooking.toString());
                }
                environment.IsAllowMultiplePriceConsession = (res.BookingConfig.IsAllowMultiplePriceConsession && res.BookingConfig.IsAllowMultiplePriceConsession.toLocaleLowerCase() == 'true');
                environment.IsShowSetupTimeInCalendar = (res.BookingConfig.IsShowSetupTimeInCalendar && res.BookingConfig.IsShowSetupTimeInCalendar.toLocaleLowerCase() == 'true');

                if (res.BookingConfig.ImmediateConfirmation != undefined && res.BookingConfig.ImmediateConfirmation != null) {
                    environment.ImmediateConfirmation = res.BookingConfig.ImmediateConfirmation;
                }
                environment.AppSettingsLoaded = true;
                environment.ExternalIntegrationIdForPaymentGateway = res.ExternalIntegrationIdForPaymentGateway;

                environment.ConfigurationSelection.Enabled = res.BookingConfig.ConfigurationSelection.Enabled;
                environment.ConfigurationSelection.DisplayMode = res.BookingConfig.ConfigurationSelection.DisplayMode;
                environment.CheckAvailability.EnableAutoAvailabilityCheck = res.BookingConfig.CheckAvailability.EnableAutoAvailabilityCheck;
                environment.CheckAvailability.EnableTimeLineAdvancedMode = res.BookingConfig.CheckAvailability.EnableTimeLineAdvancedMode;
                environment.CheckAvailability.TimeLineColors = res.BookingConfig.CheckAvailability.TimeLineColors;
                environment.CheckAvailability.DisplayPreliminarySlotAsUnavailble = res.BookingConfig.CheckAvailability.DisplayPreliminarySlotAsUnavailble;

                environment.ConfigurationSelection.DisplayBumpInOutNotification = res.BookingConfig.ConfigurationSelection.DisplayBumpInOutNotification;
                environment.ForgetPasswordPlaceHolder = res.ClientRegistrationConfig.ForgetPasswordPlaceHolder;
                environment.ForgetPasswordMessage = res.ClientRegistrationConfig.ForgetPasswordMessage;
                environment.ForgotPasswordLink = res.ClientRegistrationConfig.ForgotPasswordLink;

                environment.PriceConcessions = res.BookingConfig.PriceConcessions;
                environment.ShowMapView = res.ShowMapView;
                environment.SortOptions = res.BookingConfig.SortOptions;
                environment.SubmitEnquiryMessage = res.SubmitEnquiryConfig.Message;
                environment.ExternalIntegrationIdForAddress = res.ExternalIntegrationIdForAddress;

                //Set the XML values to result





                if (res.ClientRegistrationConfig.Placeholder == null) {
                    res.ClientRegistrationConfig.Placeholder = new Placeholder();
                }


                if (res.ClientRegistrationConfig.Placeholder.Address == null) {
                    res.ClientRegistrationConfig.Placeholder.Address = new Address();
                    res.ClientRegistrationConfig.Placeholder.Address.AddressLine1 = { Value: "510/583", IsMandatory: "0", Label: "Address Line 1" };
                    res.ClientRegistrationConfig.Placeholder.Address.AddressLine2 = { Value: "St Kida Road", IsMandatory: "0", Label: "Address Line 2" };
                    res.ClientRegistrationConfig.Placeholder.Address.AddressLine3 = { Value: "line 3", IsMandatory: "0", Label: "Address Line 3" };
                    res.ClientRegistrationConfig.Placeholder.Address.City = { Value: "City", IsMandatory: "0", Label: "City" };
                    res.ClientRegistrationConfig.Placeholder.Address.PostCode = { Value: "3004", IsMandatory: "0", Label: "Post Code" };
                    res.ClientRegistrationConfig.Placeholder.Address.State = { Value: "VIC", IsMandatory: "0", Label: "State" };
                    res.ClientRegistrationConfig.Placeholder.Address.Country = { Value: "Australia", IsMandatory: "0", Label: "Country" };
                }

                const AddressLine1 = res.ClientRegistrationConfig.Placeholder.Address.AddressLine1
                configureField( AddressLine1, "0", "Address Line 1", "510/583" );

                const AddressLine2 = res.ClientRegistrationConfig.Placeholder.Address.AddressLine2
                configureField( AddressLine2, "0", "Address Line 2", "St Kida Road" );

                const AddressLine3 = res.ClientRegistrationConfig.Placeholder.Address.AddressLine3
                configureField( AddressLine3, "0", "Address Line 3", "line 3" );

                const City = res.ClientRegistrationConfig.Placeholder.Address.City
                configureField( City, "0", "City", "city" );

                const Country = res.ClientRegistrationConfig.Placeholder.Address.Country
                configureField( Country, "0", "Country", "Australia" );

                const State = res.ClientRegistrationConfig.Placeholder.Address.State
                configureField( State, "0", "State", "VIC" );


                function configureField(field: AddressField, isMandatory: string, label: string, value: string) {

                    if (!field) {
                        field = new AddressField();
                    }

                    if (field.IsMandatory == null || field.IsMandatory === '') {
                        field.IsMandatory = isMandatory;
                    }

                    if (field.Label == null || field.Label === '') {
                        field.Label = label;
                    }

                    if (field.Value == null || field.Value === '') {
                        field.Value = value;
                    }

                }


                //setting up corparte field XML
                if (res.ClientRegistrationConfig.Placeholder.Corporate == null) {
                    res.ClientRegistrationConfig.Placeholder.Corporate = new Corporate();
                }

                //email
                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail == null) {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail = new CorporateField();
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail.IsMandatory == null || res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail.IsMandatory == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail.IsMandatory = "0";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail.Label == null || res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail.Label == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail.Label = "Company Email";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail.Value == null || res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail.Value == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyEmail.Value = "";
                }

                //Phonenumber
                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber == null) {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber = new CorporateField();
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.IsMandatory == null || res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.IsMandatory == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.IsMandatory = "0";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.Label == null || res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.Label == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.Label = "Company Phone";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.Value == null || res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.Value == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyPhoneNumber.Value = "";
                }

                //First NAme
                if (res.ClientRegistrationConfig.Placeholder.Corporate.FirstName == null) {
                    res.ClientRegistrationConfig.Placeholder.Corporate.FirstName = new CorporateField();
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.FirstName.IsMandatory == null || res.ClientRegistrationConfig.Placeholder.Corporate.FirstName.IsMandatory == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.FirstName.IsMandatory = "0";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.FirstName.Label == null || res.ClientRegistrationConfig.Placeholder.Corporate.FirstName.Label == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.FirstName.Label = "First Name";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.FirstName.Value == null || res.ClientRegistrationConfig.Placeholder.Corporate.FirstName.Value == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.FirstName.Value = "";
                }

                //Last NAme
                if (res.ClientRegistrationConfig.Placeholder.Corporate.LastName == null) {
                    res.ClientRegistrationConfig.Placeholder.Corporate.LastName = new CorporateField();
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.LastName.IsMandatory == null || res.ClientRegistrationConfig.Placeholder.Corporate.LastName.IsMandatory == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.LastName.IsMandatory = "0";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.LastName.Label == null || res.ClientRegistrationConfig.Placeholder.Corporate.LastName.Label == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.LastName.Label = "Last Name";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.LastName.Value == null || res.ClientRegistrationConfig.Placeholder.Corporate.LastName.Value == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.LastName.Value = "";
                }

                //Company Name
                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName == null) {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName = new CorporateField();
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName.IsMandatory == null || res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName.IsMandatory == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName.IsMandatory = "1";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName.Label == null || res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName.Label == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName.Label = "Company Name";
                }

                if (res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName.Value == null || res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName.Value == '') {
                    res.ClientRegistrationConfig.Placeholder.Corporate.CompanyName.Value = "";
                }

                environment.AppSetting = res;

            });
        }
    }
    async loadRegularBookingConfiguration() {
        if (!environment.RegularBookingSettingLoaded) {
            return await this.systemService.loadRegularBookingConfiguration().toPromise().then(res => {

                environment.RegularBookingSetting = res;
                environment.RegularBookingSettingLoaded = true;
            })
        }
    }

    async loadWasteBookingConfiguration() {
        if (!environment.WasteBookingSettingLoaded) {
            return await this.systemService.loadWasteBookingConfiguration().toPromise().then(res => {
                environment.WasteBookingSetting = res;
                environment.WMInitialBookingStatus = String(res.bookingSetting.initialBookingStatusId);
                environment.WasteBookingSettingLoaded = true;
            });
        }
    }
    async loadPublicBookingConfiguration() {
        if (!environment.PublicBookingSettingLoaded) {
            return await this.systemService.loadPublicBookingConfiguration().toPromise().then(res => {
                environment.PublicBookingSetting = res;
                environment.PublicBookingSettingLoaded = true;
            });
        }
    }

    async loadSystemOption() {
        return await this.systemService.loadSystemOptions().toPromise().then(res => {
            environment.SystemOption.SessionTimers.bookingSession = _.cloneDeep(res.find((x) => x.keyName === "LapsTime"));
            // environment.SystemOption.SessionTimers.bookingSession['bonusSessionTime'] = 1;
            // environment.SystemOption.SessionTimers.bookingSession['displayValue'] = 2;
            // environment.SystemOption.SessionTimers.bookingSession['keyValue'] = 2;
            // environment.SystemOption.SessionTimers.bookingSession['minutes'] = '2';

            environment.SystemOption.SessionTimers.paymentSession = _.cloneDeep(res.find((x) => x.keyName === "InternetPaymentInProgressBookingsLapsTime"));
            // environment.SystemOption.SessionTimers.paymentSession['bonusSessionTime'] = 1;
            // environment.SystemOption.SessionTimers.paymentSession['displayValue'] = 2;
            // environment.SystemOption.SessionTimers.paymentSession['keyValue'] = 2;
            // environment.SystemOption.SessionTimers.paymentSession['minutes'] = '2';

            environment.SystemOption.SessionTimers.globalSession = _.cloneDeep(res.find((x) => x.keyName === "LapsTime"));
            environment.SystemOption.SessionTimers.globalSession['bonusSessionTime'] = 5;
            environment.SystemOption.SessionTimers.globalSession['displayValue'] = 120;
            environment.SystemOption.SessionTimers.globalSession['keyValue'] = 120;
            environment.SystemOption.SessionTimers.globalSession['minutes'] = '120';

            environment.SystemOption.taxLabel = res.find((x) => x.keyName === "TaxLabelToDisplay")?.keyValue.toString();
            environment.SystemOption.defaultCallCenterContactID = res.find((x) => x.keyName === "DefaultCallCenterContactID")?.keyValue.toString();
            environment.SystemOption.defaultContactTitleID = res.find((x) => x.keyName === "SystemContactTitleID")?.keyValue;
            environment.SystemOption.defaultInternetBookingStatus = res.find((x) => x.keyName === "InternetBookingStatus")?.keyValue;
            environment.SystemOption.isShowPriceWithTax = (res.find((x) => x.keyName === "IsShowPriceWithTax")?.keyValue.toString() == "True");
            environment.SystemOption.isSecureInvoiceEnabled = (res.find((x) => x.keyName === "UseSecureReferenceForInvoiceLink")?.keyValue.toString().toLowerCase() == "true");
        });
    }

    async loadLoginUserData() {
            return await this.systemService.getLoginUserAccessRights().toPromise().then(res => {
                if (res.find((r) => r.accessRightName === "Allowchangebookingstatusmanuallyinbooking") || res.find((r) => r.accessRightNo === 1036)) {
                    environment.UserAccessRights.isAllowToChangeBookingStatus = true;
                }
            })
    }
}
