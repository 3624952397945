import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { ILayout } from "../../models/interfaces";
import { ButtonLayout } from "../../models/buttonLayout.model";
import { BOOKING_FLOW_OPTION, BUTTON_ACTION, BUTTON_STYLE, ROUTE_LIST } from "../../models/enums";
import { Router } from "@angular/router";
import { Theme } from "../../models/globalTheme";
import * as d3 from "d3";
import { ThemeService } from "src/modules/services/public-web/theme.service";

import { PAGE_TYPE } from "src/modules/models/public-web/enum";
import { Store } from "@ngrx/store";
import { BaseComponent } from "src/modules/shared/base.component";
import { Observable, of } from "rxjs";
import { IDateAndSession, IPublicEvent, IPublicPackage } from "../../../../../store/public-web/public-web-reducers";
import {
  AddPackage,
  SelectPackageSessions,
  SetBookingFlow,
  SetRedirectionPoint,
  SetSelectedSession,
  UpdatePackage,
} from "../../../../../store/public-web/public-web-actions";
import { LinkPackageListComponent } from "../../modals/link-package-list/link-package-list.component";
import { environment } from "src/environments/environment";
import { Utility } from "src/modules/utility";
import { ICart, SignOut } from "src/modules/store";
import { SessionModalComponent } from "../../modals/session-modal/session-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { MultiDaySessionComponent } from "../../../modals/multi-day-session/multi-day-session";
import { BaseParam, SearchBaseFilter } from "src/modules/models/public-web/filter";
import { PublicPackageService } from "src/modules/services/public-web/public-package.service";
import { Package } from "src/modules/models/public-web/Package";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertMessageComponent } from "src/modules/shared/alert-message/alert-message.component";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';
import { dispatch } from "rxjs/internal/observable/pairs";
import { event } from "jquery";
import { AppSetting } from "src/modules/models/settings/casual-portal/app-setting";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "opt-button-layout",
  templateUrl: "./button-layout.component.html",
})
export class ButtonLayoutComponent
  extends BaseComponent
  implements OnInit, ILayout, AfterViewInit {
  @Input() isView: boolean;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() layoutAttribute: ButtonLayout;
  @Input() elementID;

  parentRef: any;
  theme: Theme;
  isHtml = false;
  showEditOption: boolean;
  currentPageType: PAGE_TYPE;
  pageType$: Observable<PAGE_TYPE>;
  publicCart$;
  publicPackage: IPublicPackage;
  cart$: Observable<ICart>;
  clientCategoryId;
  publicCards: IPublicPackage[];
  selectedEvent: IPublicEvent;
  appSetting: AppSetting;
  constructor(
    private router: Router,
    private themeService: ThemeService,
    private store: Store<any>,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private publicPackageService: PublicPackageService,
  ) {
    super();
    this.pageType$ = this.store.select(PBSelector.selectPageType);
    this.publicCart$ = this.store.select(PBSelector.selectedPublicPackageCarts);
    this.cart$ = this.store.select("cart");
    this.appSetting = environment.AppSetting as AppSetting;
  }

  ngOnInit(): void {
    this.themeService.theme$.safeSubscribe(this, (theme) => {
      this.theme = theme;
    });
    this.pageType$.safeSubscribe(this, (type) => {
      this.currentPageType = type;
      if(this.currentPageType == PAGE_TYPE.EVENT_LIST) {
        if (this.layoutAttribute.navigationURL.includes("date-and-session")) {
          const eventID = this.layoutAttribute.navigationURL.replace(
            "date-and-session/",
            ""
          );
          this.store.select(PBSelector.selectedPublicEventById(eventID.toString())).safeSubscribe(this, (event : IPublicEvent) => {
            if (event) {
              this.selectedEvent = event;
            }
          })
        }
      }
    });
    this.publicCart$.safeSubscribe(this, (carts: IPublicPackage[]) => {
      this.publicCards = carts;
      var packageID = this.layoutAttribute.navigationURL.replace(
        "date-and-session/",
        ""
      );
      var packageID = this.layoutAttribute.navigationURL.replace("upsell/", "");
      this.publicPackage = this.publicCards.find(
        (x) => x.id === packageID
      );
    });
    this.cart$.subscribe((state) => {
      if (
        state.contact &&
        state.contact.client &&
        state.contact.client.clientCategoryId
      ) {
        this.clientCategoryId =
          state.contact.client.clientCategoryId.toString();
      }
    });
  }
  get disablePublicSelect() {
    let isLinkedPackageHaveQuantity = 0;
    if (
      this.publicPackage &&
      this.publicPackage.isHaveLinkPackage &&
      this.publicPackage.linkPackages &&
      this.publicPackage.linkPackages.length > 0
    ) {
      this.publicPackage.linkPackages.forEach(
        (l) => (isLinkedPackageHaveQuantity += l.attendees)
      );
    }
    if (
      this.currentPageType === PAGE_TYPE.PACKAGE_LIST &&
      this.publicPackage &&
      this.publicPackage.isNotMemberPackage
    ) {
      return false;
    } else if (
      this.currentPageType === PAGE_TYPE.PACKAGE_LIST &&
      this.publicPackage &&
      !this.publicPackage.isHaveLinkPackage
    ) {
      return this.publicPackage.quantity == 0;
    }
    return false;
    // else if (
    //   this.currentPageType === PAGE_TYPE.PACKAGE_LIST &&
    //   this.publicPackage &&
    //   this.publicPackage.isHaveLinkPackage &&
    //   this.publicPackage.isLinkPackageExpand &&
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }
  }
  get selecttionPannelName() {
    if (
      this.publicPackage &&
      this.publicPackage.isNotMemberPackage &&
      this.publicPackage.clientCategories &&
      !this.publicPackage.clientCategories.find(
        (c) => c.id === this.clientCategoryId
      )
    ) {
      return "Login to Proceed";
    } else {
      return this.layoutAttribute.buttonName;
    }
  }
  ngAfterViewInit(): void {
    this.initializeAttributes();
  }
  get isExpandButton() {
    if (!this.layoutAttribute.componentClass.includes("ignore-route")) {
      if (this.publicPackage && this.publicPackage.isNotMemberPackage) {
        return false;
      }
      if (this.publicPackage && this.publicPackage.isHaveLinkPackage) {
        return true;
      }
    }
    return false;
  }
  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      d3.select(contentElement.closest(".button-layout"))
        .data([this.layoutAttribute.gridColumn])
        if(this.currentPageType !== PAGE_TYPE.EVENT_CATEGORY){
          d3.select(contentElement.closest(".button-layout"))
          .style("padding", this.theme.card.margin + "px");
        }
      d3.select(contentElement.parentElement).attr(
        "class",
        `col-md-${this.layoutAttribute.gridColumn}
            col-lg-${this.layoutAttribute.gridColumn}
            col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
      );
    }
  }

  get BUTTON_STYLE() {
    return BUTTON_STYLE;
  }
  get BUTTON_ACTION() {
    return BUTTON_ACTION;
  }

  buttonAction() {
    switch (this.layoutAttribute.buttonAction) {
      case BUTTON_ACTION.URL:
        this.goToUrl();
        break;
      case BUTTON_ACTION.ROUTE:
        this.goToRoute();
        // this.store.dispatch(new SelectSession(undefined));
        break;
      case BUTTON_ACTION.SCRIPT:
        break;
      case BUTTON_ACTION.SESSION:
        this.goToSessions();
        break;
      default:
        break;
    }
  }
  goToSessions() {
    let eventID;
    let packageID;
    const values = this.layoutAttribute.navigationURL.split("/");
    if (values.length > 0) {
      eventID = values[1];
      packageID = values[3];
    }
    this.openDateAndSessionModal(eventID, packageID);
    console.log(eventID, packageID);
  }
  goToUrl() {
    const regex =
      /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    const pattern = new RegExp(regex);
    const goUrl = pattern.test(this.layoutAttribute.navigationURL);
    if (goUrl) {
      window.open(this.layoutAttribute.navigationURL, "_blank");
    } else {
      window.open("http://" + this.layoutAttribute.navigationURL, "_blank");
    }
  }
  goToRoute() {
    switch (this.currentPageType) {
      case PAGE_TYPE.EVENT_LIST || PAGE_TYPE.EVENT_INFO:
        let eventID;
        let bookingFlowOptionId = this.layoutAttribute.bookingFlowOptionId;
        this.store.dispatch(new SetBookingFlow(bookingFlowOptionId));
        if (this.layoutAttribute.navigationURL.includes("package-list")) {
          eventID = this.layoutAttribute.navigationURL.replace(
            "package-list/",
            ""
          );
          this.router.navigate(["/" + this.layoutAttribute.navigationURL]);
        }
        if (this.layoutAttribute.navigationURL.includes("date-and-session")) {
          eventID = this.layoutAttribute.navigationURL.replace(
            "date-and-session/",
            ""
          );
          if (this.selectedEvent) {
            if (this.selectedEvent.packageSessions.length > 0) {
              if (this.selectedEvent.packageSessions.length > 1) {
                this.router.navigate(["/" + this.layoutAttribute.navigationURL]);
              } else {
                if (bookingFlowOptionId && bookingFlowOptionId == BOOKING_FLOW_OPTION.SessionPackageListVenueMap) {
                  const session: IDateAndSession = {
                    id: this.selectedEvent.packageSessions[0].id.toString(),
                    startTime: Utility.convertToISO(Utility.convertISOToDate(this.selectedEvent?.packageSessions[0]?.startTime)),
                    endTime: Utility.convertToISO(Utility.convertISOToDate(this.selectedEvent?.packageSessions[0]?.endTime)),
                    availability: this.selectedEvent.packageSessions[0].availability,
                    eventDate: this.selectedEvent.packageSessions[0].packageDate.eventDate,
                    packages: [],
                    durationRange: this.selectedEvent.packageSessions[0].packageDate.packageDurationRange ? this.selectedEvent.packageSessions[0].packageDate.packageDurationRange : null,
                  };
                  this.store.dispatch(new SetSelectedSession(session));
                  this.store.dispatch(new SelectPackageSessions([session]));
                  if(this.appSetting.SeatSelectionWithOnePackage && this.selectedEvent.maximumCountOfPackages == 1 && this.selectedEvent.hasTicketing) {
                    this.router.navigate(["seating-view/" + eventID], {
                      queryParams: {
                        sessionId: this.selectedEvent.packageSessions[0].id,
                      },
                    });
                  } else {
                    this.router.navigate(["package-list/" + eventID], {
                      queryParams: {
                        sessionID: this.selectedEvent.packageSessions[0].id,
                      },
                    });
                  }
                }  else if (bookingFlowOptionId && bookingFlowOptionId == BOOKING_FLOW_OPTION.SessionVenueMapPackageList) {
                  const session: IDateAndSession = {
                    id: this.selectedEvent.packageSessions[0].id.toString(),
                    startTime: Utility.convertToISO(Utility.convertISOToDate(this.selectedEvent?.packageSessions[0]?.startTime)),
                    endTime: Utility.convertToISO(Utility.convertISOToDate(this.selectedEvent?.packageSessions[0]?.endTime)),
                    availability: this.selectedEvent.packageSessions[0].availability,
                    eventDate: this.selectedEvent.packageSessions[0].packageDate.eventDate,
                    packages: [],
                  };
                  this.store.dispatch(new SetSelectedSession(session));
                  this.store.dispatch(new SelectPackageSessions([session]));
                  if (this.selectedEvent.hasTicketing) {
                    this.router.navigate(["seating-view/" + eventID], {
                      queryParams: {
                        sessionId: session.id,
                      },
                    });
                  } else {
                    this.router.navigate(["package-list/" + eventID], {
                      queryParams: {
                        sessionID: this.selectedEvent.packageSessions[0].id,
                      },
                    });
                  }
                }
              }
            } else {
              this.router.navigate(["/" + this.layoutAttribute.navigationURL]);
            }
          } else {
            this.router.navigate(["/" + this.layoutAttribute.navigationURL]);
          }
        }
        // if (eventID) this.store.dispatch(new AddEvent(eventID));

        break;
      case PAGE_TYPE.EVENT_INFO:
        this.router.navigate(["/" + this.layoutAttribute.navigationURL]);

        break;
      case PAGE_TYPE.PACKAGE_LIST:
        if (!this.layoutAttribute.componentClass.includes("ignore-route")) {
          // this.packageSelection();
        } else {
          this.router.navigate(["/" + this.layoutAttribute.navigationURL], {
            queryParamsHandling: "preserve",
          });
        }
        break;

      case PAGE_TYPE.PACKAGE_INFO:
        if (!this.layoutAttribute.componentClass.includes("ignore-route")) {
          this.packageSelection();
        } else {
          this.router.navigate(["/" + this.layoutAttribute.navigationURL]);
        }
        break;
      default:
        if (this.layoutAttribute.navigationURL.includes("private")) {
          var ul = environment.ApiUrl + "#/facility";
          window.open(ul, "_self");
        } else {
          this.router.navigate(["/" + this.layoutAttribute.navigationURL]);
        }
        break;
    }
  }
  popupLinkPackage() { }
  goToScript() {
    return null;
  }
  get linkPackageTotalPrice() {
    let amount = 0;
    if (
      this.publicPackage &&
      this.publicPackage.isHaveLinkPackage &&
      this.publicPackage.linkPackages &&
      this.publicPackage.linkPackages.length > 0
    ) {
      this.publicPackage.linkPackages.forEach((x) => {
        amount += x.variablePriceIncludingTax * x.quantity;
      });
    }
    return amount;
  }
  packageSelection() {
    if (
      this.publicPackage &&
      this.publicPackage.isNotMemberPackage &&
      this.publicPackage.clientCategories &&
      !this.publicPackage.clientCategories.find(
        (c) => c.id === this.clientCategoryId
      )
    ) {
      this.store.dispatch(new SignOut());
      // localStorage.setItem("PublicWebRedirection", this.router.url);
      window.open(
        environment.ApiUrl + "#/client/login?DirectLoginFromPublicWeb=1&ReturnUrl="+this.router.url,
        "_self"
      );
    } else {
      this.store.dispatch(
        new SetRedirectionPoint(this.layoutAttribute.navigationURL)
      );
      if (
        this.publicPackage &&
        this.publicPackage.isHaveLinkPackage &&
        !this.publicPackage.isLinkPackageExpand
      ) {
        this.publicPackage.isLinkPackageExpand = true;
        this.store.dispatch(new UpdatePackage(this.publicPackage));
      } else {
        this.store.dispatch(new AddPackage(this.publicPackage.id));
      }
    }
  }
  openAddSessionModel() {
    this.dialog.open(SessionModalComponent, {
      data: {},
      panelClass: [
        "custom-dialog-container",
        "w50modal",
        "modal-width",
        "extrapop",
      ],
      height: "auto",
      width: "50%",
    });
  }

  openDateAndSessionModal(eventID, packageID) {
    let searchFilter: SearchBaseFilter = new SearchBaseFilter();
    const params: BaseParam = new BaseParam();
    params.eventID = eventID;
    params.packageID = packageID;
    this.publicPackageService
      .getPublicPackages(params, searchFilter)
      .safeSubscribe(this, (sessionFromData: Package[]) => {
        // let sessionFromData={
        //   multiDayAvailabilityCount : 0,
        //   packageSession : [],
        //   multiDayEventName : data[0].name,
        //   packageDates : data[0].packageDates,
        //   packageSessions : data[0].packageSessions
        // }
        this.dialog.open(MultiDaySessionComponent, {
          data: {
            eventID: eventID,
            packageID: packageID,
            isMultiDaySession: true,
            sessionFromData: sessionFromData
          },
          panelClass: ["custom-dialog-container", "modal-width", "extrapop", "multiday-session"],
          //height: "90%",
          //increased max height from 90% due to butn issue on calander layout
          width: "80%",
          minWidth: "60%",
          height: "fit-content",
          minHeight: "200px",
          maxHeight: "95%",
        });
      }, (error) => {
        //show error
        this.openSnackBarError(["Your payment has been failed. Please try again"]);
      });
  }
  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 3000,
      verticalPosition: 'top',
    });
  }
}
