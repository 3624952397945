import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'opt-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss']
})
export class WarningMessageComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string[],
              private snackBarRef: MatSnackBarRef<WarningMessageComponent>) {
  }

  ngOnInit(): void {
  }

  closeMessage(): void {
    this.snackBarRef.dismiss();
  }

}
