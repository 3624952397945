import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseComponent } from "src/modules/shared/base.component";
import { Store } from "src/modules/store";
import { BookingDispatchStatus, DispatchStatus } from "../../layout/models/bookingCart.model";


@Component({
  selector: "opt-dispatch-status",
  templateUrl: "./dispatch-status.component.html",
})
export class DispatchStatusModalComponent extends BaseComponent implements OnInit {
  dispatchStatus: BookingDispatchStatus;
  constructor(
    public dialogRef: MatDialogRef<DispatchStatusModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    // dialogRef.disableClose = true;
  }
  displayedColumns: string[] = ['Item', 'Qty', 'Print Status', 'Dispatch Status', 'Delivery Method', 'Tracking No', 'Despatch Note'];
  dispatchItems: DispatchItem[] = [];
  ngOnInit() {
    console.log(this.data.dispatchStatus.dispatchableItems);
    if (this.data && this.data.dispatchStatus && this.data.dispatchStatus.dispatchableItems) {
      this.data.dispatchStatus.dispatchableItems.forEach(item => {
        let dispatchItem:DispatchItem = new DispatchItem();
        if(item && item.bookingItem && item.bookingItem.item){
          let itm=item.bookingItem.item;
          dispatchItem.id=itm.id;
          dispatchItem.item=itm.name;
          dispatchItem.qty=item.quantity;
          dispatchItem.printStatus= item.tickets.length > 0 ? item.tickets[0].printed : "Not Set";
          dispatchItem.dispatchStatus=this.setDispatchStatus(item.dispatchStatusID);
          dispatchItem.deliveryMethod=this.data.dispatchStatus.deliveryMethod ? this.data.dispatchStatus.deliveryMethod.name : "Not Set";
          dispatchItem.trackingNo= item.tickets.length > 0 ? (item.tickets[0].trackingNumber? item.tickets[0].trackingNumber : "Not Set"):"Not Set";
          dispatchItem.despatchNote=item.tickets.length > 0 ? (item.tickets[0].note? item.tickets[0].note: "Not Set"):"Not Set";
          this.dispatchItems.push(dispatchItem);
        }
      })
    }
  }

  setDispatchStatus(id){
    switch(id){
      case 1:
        return "Not Despatched";
      case 2:
        return "Fully Despatched";
      case 3:
        return "Partial Despatched";
      default:
    }
  }

}

export class DispatchItem {
  id: string;
  item: string;
  qty: number;
  printStatus: string;
  dispatchStatus: String;
  deliveryMethod: string;
  trackingNo: string;
  despatchNote: string;
}
