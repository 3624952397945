import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SignalRService } from '../../../services/signal-r.service';
import { Router } from "@angular/router";

@Component({
  selector: 'opt-ba-package-selection',
  templateUrl: './ba-package-selection.component.html',
  styleUrls: ['./ba-package-selection.component.scss']
})
export class BaPackageSelectionComponent implements OnInit {
  parentRadioSelection = "";
  childRadioSelection = "";
  color ='black';
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<BaPackageSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store<any>,
    public signalR:SignalRService,
    public router:Router) { }

  ngOnInit(): void {
    this.parentRadioSelection = this.data.parentRadioSelection
    this.childRadioSelection = this.data.childRadioSelection
    this.color = this.data.priceBands.filter(x=>x.id === this.data.packages[0].packageItems[0].priceBandId.toString())[0].color;
    this.data.packages.forEach(pack => {
      if(pack.linkedPackages){
        const linkedPackPrices = pack.linkedPackages.map(linkPack => linkPack.variablePriceIncludingTax)
        pack.priceRange = {
          min: Math.min(...linkedPackPrices),
          max: Math.max(...linkedPackPrices)
        }
      }
    })
  }

  changeCommonParentPack(pack){
    if(pack.linkedPackages){
      this.childRadioSelection = pack.linkedPackageType.id
    }
  }

  closeDialog(){
    this.dialogRef.close()
  }

  applyChanges(){
    let selectedPackageId;
    let selectedChildPackId = null;
    if (this.parentRadioSelection !== null) {
      selectedPackageId=this.parentRadioSelection;
    }
    let pack = this.data.packages.find(x=>x.id == selectedPackageId);
    if(pack.linkedPackages?.length > 0){
      selectedChildPackId = pack.linkedPackages.find(x=>x.linkedPackageType.id == this.childRadioSelection)?.id;
    }
    this.dialogRef.close({ parentID: selectedPackageId, childPackID: selectedChildPackId });
  }

  navigateToPackageDetails(packId) {
    this.router.navigate(['/package-info'], { queryParams: { packageID : packId} });
    this.closeDialog();
   }
}
