import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { MonthViewDay, CalendarEvent } from "calendar-utils";
import { isWithinThreshold, trackByEventId } from "../common/util";
import { PlacementArray } from "positioning";
import { AdditionalEventsComponent } from "../modals/additional-events/additional-events.component";
import {
  IDateAndSession,
  IPBReducer,
} from "../../../../store/public-web/public-web-reducers";
import { Store } from "src/modules/store";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AddPackage,
  RemovePackageSession,
  SelectPackageSessions,
  SetSelectedSession,
  SetRedirectionPoint,
} from "src/modules/store/public-web/public-web-actions";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { BaseComponent } from "src/modules/shared/base.component";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { BOOKING_FLOW_OPTION } from "src/modules/public-web/components/layout/models/enums";
import { Utility } from "src/modules/utility";
import { AppSetting } from "src/modules/models/settings/casual-portal/app-setting";
import { PackageSession } from "src/modules/models/public-web/PackageSession";
import { PublicBookingSettings } from "src/modules/models/settings/public-booking/public-booking-setting";

@Component({
  selector: "mwl-calendar-month-cell",
  template: `
    <ng-template
      #defaultTemplate
      let-day="day"
      let-openDay="openDay"
      let-locale="locale"
      let-tooltipPlacement="tooltipPlacement"
      let-highlightDay="highlightDay"
      let-unhighlightDay="unhighlightDay"
      let-eventClicked="eventClicked"
      let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody"
      let-tooltipDelay="tooltipDelay"
      let-trackByEventId="trackByEventId"
      let-validateDrag="validateDrag"
    >
      <div
        class="cal-cell-top"
        [attr.aria-label]="
          { day: day, locale: locale } | calendarA11y: 'monthCell'
        "
      >
        <div aria-hidden="true" class="cal-day">
          <!-- <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
            day.badgeTotal
          }}</span> -->
          <span class="cal-day-number">{{
            day.date | calendarDate: "monthViewDayNumber":locale
          }}</span>
        </div>
        <div
          *ngFor="let event of day.events | slice: 0:2; let i = index"
          class="cal-event-badge"
          [ngStyle]="{ 'border-left': '3px solid ' + event?.color?.secondary }"
          (click)="selectSession(event)"
        >
          <div
            class="cal-event-bg"
            [ngStyle]="{ 'background-color': event?.color?.primary }"
          ></div>
          <div *ngIf="!isMultiDayEvent" class="content">
            <span>{{ getTimeTranslate(event?.session?.startTime | date: " h:mm a")}} - {{ getTimeTranslate(event?.session?.endTime | date: " h:mm a")}}</span
            >&nbsp;
            <span>
              {{"DATEANDSESSION.from"| translate}}
              {{
                event?.session?.totalPriceIncludingTax | PBCurrencyPipe
              }}</span
            >
          </div>
          <div *ngIf="isMultiDayEvent" class="content">
            <span>{{getTimeTranslate(event?.start | date: " h:mm a") }}</span
            >&nbsp;
            <span>
            {{"DATEANDSESSION.from"| translate}}
              {{ event?.title | PBCurrencyPipe }}</span
            >
          </div>
        </div>
        <div
          *ngIf="day.badgeTotal > 2"
          class="cal-event-badge-btn"
          (mwlClick)="openAdditionalEvents()"
        >
          <span class="material-icons"> expand_more </span>
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        day: day,
        openDay: openDay,
        locale: locale,
        tooltipPlacement: tooltipPlacement,
        highlightDay: highlightDay,
        unhighlightDay: unhighlightDay,
        eventClicked: eventClicked,
        tooltipTemplate: tooltipTemplate,
        tooltipAppendToBody: tooltipAppendToBody,
        tooltipDelay: tooltipDelay,
        trackByEventId: trackByEventId,
        validateDrag: validateDrag
      }"
    >
    </ng-template>
  `,
  host: {
    class: "cal-cell cal-day-cell",
    "[class.cal-past]": "day.isPast",
    "[class.cal-today]": "day.isToday",
    "[class.cal-future]": "day.isFuture",
    "[class.cal-weekend]": "day.isWeekend",
    "[class.cal-in-month]": "day.inMonth",
    "[class.cal-out-month]": "!day.inMonth",
    "[class.cal-has-events]": "day.events.length > 0",
    "[class.cal-open]": "day === openDay",
    "[class.cal-event-highlight]": "!!day.backgroundColor",
  },
})
export class CalendarMonthCellComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input() isMultiDayEvent: boolean = false;

  @Input('hasTicket') set hastTicketing(value) {
    this.hasTicket = value;
    this.redirectToBookingApplication = this.hasTicket;
  }

  @Input('bookingFlowOption') set flowOption(value) {
    this.bookingFlowOption = value;
  }

  @Input('allSessions') set sessions(values) {
    this.allPackageSessions = values;
  }

  @Input() day: MonthViewDay;

  @Input() openDay: MonthViewDay;

  @Input() locale: string;

  @Input() tooltipPlacement: PlacementArray;

  @Input() tooltipAppendToBody: boolean;

  @Input() customTemplate: TemplateRef<any>;

  @Input() tooltipTemplate: TemplateRef<any>;

  @Input() tooltipDelay: number | null;

  @Output() highlightDay: EventEmitter<any> = new EventEmitter();

  @Output() unhighlightDay: EventEmitter<any> = new EventEmitter();

  @Output() eventClicked = new EventEmitter<{
    event: CalendarEvent;
    sourceEvent: MouseEvent;
  }>();

  trackByEventId = trackByEventId;

  validateDrag = isWithinThreshold;
  hasTicket: boolean = false;
  packageId: string;
  selectedSession: IDateAndSession;
  PBReducer$: Observable<IPBReducer>;
  redirectToBookingApplication: boolean;
  bookingFlowOption: BOOKING_FLOW_OPTION;
  allPackageSessions: PackageSession[];
  appSetting: AppSetting;
  enableUpsellConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).enableUpsell;
  eventID: number;

  constructor(
    public dialog: MatDialog,
    private store: Store<any>,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();
    this.PBReducer$ = this.store.select("PBReducer");
    this.appSetting = environment.AppSetting as AppSetting;
  }
  getTimeTranslate(data: any) {
    this.translate.get('AM').safeSubscribe(this, (res: string) => {
      data = data.replace(RegExp('AM', 'g'), res);
    });
    this.translate.get('PM').safeSubscribe(this, (res: string) => {
      data = data.replace(RegExp('PM', 'g'), res);
    });
    return data;
  }
  ngOnInit() {
    this.route.params.safeSubscribe(this, (params) => {
      this.eventID = +params["eventID"];
    });
    this.route.queryParams.safeSubscribe(this, (params) => {
      this.packageId = params.packageId;
    });
  }
  ngAfterViewInit(): void {
    this.PBReducer$.safeSubscribe(this, (d: IPBReducer) => {
      const selectedSessions = _.cloneDeep(d.selectedSessions);
      if (this.packageId) {
        this.selectedSession = selectedSessions.find(
          (x) => x.id == "-1" && x.packages.find((y) => y.id === this.packageId)
        );
      }
    });
  }
  openAdditionalEvents() {
    this.dialog.open(AdditionalEventsComponent, {
      data: { day: this.day },
      // panelClass: ["custom-dialog-container"],
      height: "auto",
      width: "40%",
    });
  }

  selectSession(e: any) {
    if (!this.isMultiDayEvent) {
      if (this.redirectToBookingApplication) {
        let packagesForSelectedSession = []
        if (!!this.selectedSession) {
          this.store.dispatch(new RemovePackageSession(this.selectedSession));
          packagesForSelectedSession = this.selectedSession.packages.length > 0 ? this.selectedSession.packages : [];
        }
        if(this.bookingFlowOption && 
          (this.bookingFlowOption == BOOKING_FLOW_OPTION.SessionVenueMapPackageList || this.bookingFlowOption == BOOKING_FLOW_OPTION.PackageListSessionVenueMap)
          ) {
          const session: IDateAndSession = {
            id: e.session.id.toString(),
            startTime: e.session.startTime,
            endTime: e.session.endTime,
            availability: e.session.availability,
            eventDate: e.session.packageDate.eventDate,
            packages: packagesForSelectedSession,
          };
          this.store.dispatch(new SetSelectedSession(session));
          this.store.dispatch(new SelectPackageSessions([session]));
          if (this.packageId) {
            this.router.navigate(["seating-view/" + this.eventID], {
              queryParams: {
                sessionId: session.id,
                packageId: this.packageId
              },
            });
          } else {
            this.router.navigate(["seating-view/" + this.eventID], {
              queryParams: {
                sessionId: session.id,
              },
            });
          }
        } else if (this.bookingFlowOption && this.bookingFlowOption == BOOKING_FLOW_OPTION.SessionPackageListVenueMap) {
          let sessionsOnSelectedSession = this.allPackageSessions.filter(s => Utility.convertToISO(Utility.convertISOToDate(s.startTime)) === Utility.convertToISO(Utility.convertISOToDate(e.session.startTime)) &&
          Utility.convertToISO(Utility.convertISOToDate(s.endTime)) === Utility.convertToISO(Utility.convertISOToDate(e.session.endTime)) &&
          s.availability > 0)
          const session: IDateAndSession = {
            id: e.session.id.toString(),
            startTime: e.session.startTime,
            endTime: e.session.endTime,
            availability: e.session.availability,
            eventDate: e.session.packageDate.eventDate,
            packages: [],
          };
          this.store.dispatch(new SetSelectedSession(session));
          this.store.dispatch(new SelectPackageSessions([session]));
          if(this.appSetting.SeatSelectionWithOnePackage && sessionsOnSelectedSession && sessionsOnSelectedSession?.length == 1) {
            this.router.navigate(["seating-view/" + this.eventID], {
              queryParams: {
                sessionId: e.session.id,
              },
            });
          } else {
            this.router.navigate(["package-list/" + this.eventID], {
              queryParams: {
                sessionID: session.id,
              },
            });
          }
        }
      } else if (this.packageId && this.selectedSession) {
        const session = _.cloneDeep(this.selectedSession);
        session.id = e.session.id.toString();
        session.startTime = e.session.startTime;
        session.availability = e.session.availability;
        session.endTime = e.session.endTime;
        if (this.enableUpsellConfiguration) {
          this.store.dispatch(new SetRedirectionPoint(`upsell/${this.packageId}`));
        } else {
            this.store.dispatch(new SetRedirectionPoint("booking-cart"));
        }
        this.store.dispatch(new RemovePackageSession(this.selectedSession));
        this.store.dispatch(new SetSelectedSession(session));
        this.store.dispatch(new SelectPackageSessions([session]));
        this.store.dispatch(new AddPackage(this.packageId));
      }else {
        const session: IDateAndSession = {
          id: e.session.id.toString(),
          startTime: e.session.startTime,
          endTime: e.session.endTime,
          availability: e.session.availability,
          packages: [],
        };
        this.store.dispatch(new SetSelectedSession(session));
        this.store.dispatch(new SelectPackageSessions([session]));
        this.router.navigate(["package-list/" + this.eventID], {
          queryParams: {
            sessionID: session.id,
          },
        });
      }
    }
  }
}
