import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/modules/shared/base.component';

@Component({
  selector: 'opt-booking-validation-confirmation-modal',
  templateUrl: './booking-validation-confirmation-modal.component.html'
})
export class BookingValidationConfirmationModalComponent implements OnInit {
  message = 'Message Undefined '
  title = "Undefined"
  constructor(
    public dialogRef: MatDialogRef<BookingValidationConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.message = data.message;
    this.title = data.title;
  }

  ngOnInit(): void {
  }
  sayNo() {
    this.dialogRef.close(false)
  }
  sayYes() {
    this.dialogRef.close(true)
  }
}
