import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';

declare let gtag: Function;

@Component({
  selector: 'opt-secure-pay',
  templateUrl: './secure-pay.component.html'

})
export class SecurePayComponent implements OnInit {
    paymentUrl: SafeResourceUrl;
    InvoiceRef: "";
    amount: "";
    bookingId: "";
    bookingTypeId:string = "";
    iframeClass = 'iFrame-default';
    theme:string;
    billerCode:string= "";
    iframeHeight: string;
    appSetting: AppSetting;
    immediate:string = "";
    isFromMyBooking:string = "";
	private sub: any;

  constructor(private sanitizer: DomSanitizer, private router: ActivatedRoute, private routers: Router,private titleService: Title,) {
    this.sub = this.router.queryParams.subscribe((params) => {
      this.InvoiceRef = params['InvoiceRef'];
      this.amount = params['amount'];
      this.bookingId = params['bookingId'];
      this.billerCode = params['billerCode'];
      this.bookingTypeId = params['bookingTypeId'];
      this.immediate = params['immediate'];
      this.isFromMyBooking = params['IsFromMyBooking'];
      console.log("params ", params)
      console.log(this.amount);
    });
    this.appSetting = environment.AppSetting as AppSetting;
    this.theme = environment.Theme;
    this.routers.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_title: this.appSetting.PageTitle + ' | Card Payments',
          page_path: event.urlAfterRedirects,
          page_location: window.location.href
        })
      }
    });

        var url = environment.ApiUrl + ((environment.ApiUrl.endsWith('/')) ? "":"/")+"Payment/Pay?";
        if (this.InvoiceRef != undefined && this.InvoiceRef.length>0)
            var url = url + "InvoiceRef=" + this.InvoiceRef;
        if (this.amount != undefined && this.amount.length>0)
            var url = url + "&amount=" + this.amount;
        if (this.bookingId != undefined && this.bookingId != "") {
            url = url + "&bookingId=" + this.bookingId;
        }
        if (this.billerCode != undefined && this.billerCode != "") {
            url = url + "&billerCode=" + this.billerCode;
        }
        if (this.bookingTypeId != undefined && this.bookingTypeId != "") {
            url = url + "&bookingTypeId=" + this.bookingTypeId;
        }
        if (this.immediate != undefined && this.immediate != "") {
            url = url + "&immediate=" + this.immediate;
        }
        if (this.isFromMyBooking != undefined && this.isFromMyBooking != "") {
            url = url + "&IsFromMyBooking=" + this.isFromMyBooking;
        }
            this.paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
           // this.sanitizer.bypassSecurityTrustResourceUrl();
    }


   ngOnInit()
   {
     if (this.appSetting.PageTitle) {
       this.titleService.setTitle(this.appSetting.PageTitle + ' | Card Payments');
     }
     switch (environment.ExternalIntegrationIdForPaymentGateway) {
      case '21':
        this.iframeClass = `iFrame-bPoint${this.theme ? '-' + this.theme : ''}`;
        break;
      case '32':
        this.iframeClass = `iFrame-globalPay${this.theme ? '-' + this.theme : ''}`;
        break;
      case '33':
        this.iframeClass = `iFrame-adyen${this.theme ? '-' + this.theme : ''}`;
        break;
      default:
        this.iframeClass = `iFrame-default${this.theme ? '-' + this.theme : ''}`;
        break;
    }
   }
   ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
