import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Deserializer, Serializer } from 'json-api-format';
import { BehaviorSubject, Observable } from "rxjs";
import { Paging } from "src/modules/models/base-param";
import { PAGE_TYPE } from "src/modules/models/public-web/enum";
import { SearchBaseFilter } from "src/modules/models/public-web/filter";
import { CardLayout } from "src/modules/public-web/components/layout/models/cardLayout.model";
import { CardListLayout } from "src/modules/public-web/components/layout/models/cardListLayout.model";
import { BaseService } from "../base/base.service";
import { ICart } from "src/modules/store";
@Injectable({
  providedIn: "root",
})
export class CardLayoutService {
  cart$: Observable<ICart>;
  clientCategoryId: string;
  clientId: string;
  canAgentAccess: boolean = false;
  private pageType;
  private cardTemplate: CardListLayout;
  private categoryId;
  private eventId;
  private paging: Paging;
  searchCardResult = new BehaviorSubject<CardLayout[]>([]);
  constructor(private _baseService: BaseService, private store: Store<any>) { }

  set setCardTemplate(card) { this.cardTemplate = card }
  set setPageType(type) { this.pageType = type }
  set setCategoryID(id) { this.categoryId = id }
  set setPaging(id) { this.paging = id }
  set setEventId(id) { this.eventId = id }
  get getPageType() {
    return this.pageType;
  }
  getCardLayout(searchBaseFilter: SearchBaseFilter) {
    
    if (this.cardTemplate && this.pageType) {
      let url ='';
      if (searchBaseFilter.paging.size && searchBaseFilter.paging.number)
        url = `api/public/card-layout/${this.pageType}?page.size=${searchBaseFilter.paging.size}&page.number=${searchBaseFilter.paging.number}`;
      else
        url = `api/public/card-layout/${this.pageType}?page.size=${this.paging.size}&page.number=${this.paging.number}`;

      if (this.pageType == PAGE_TYPE.PACKAGE_LIST) {
        if (this.eventId) {
          url += `&filters.eventId=${this.eventId}`;
        }
        if (!!searchBaseFilter.searchText && searchBaseFilter.searchText != '') {
          url += `&filters.searchText=${searchBaseFilter.searchText}`;
        }
      } else {
        url += this.categoryId ? `&filters.EventCategoryID=${this.categoryId}` : '';
      }
      // if (searchBaseFilter.sessionFromDate && searchBaseFilter.sessionToDate) {
      //   url += `&filters.sessionFromDate=${searchBaseFilter.sessionFromDate}&filters.sessionToDate=${searchBaseFilter.sessionToDate}`;
      // }
      if (!!searchBaseFilter) url = searchBaseFilter.addFilter(url, 'filters');

      const dd = new Serializer().serialize(this.cardTemplate);
      this._baseService.Internal_WebAPI_POST(url, dd).subscribe(d => {
        if (d.body && d.body.layoutObjects && d.body.layoutObjects.length > 0) {
          const cardList = d.body.layoutObjects.map(c => new CardLayout(c));
          this.searchCardResult.next(cardList);
          //this.store.dispatch(new LoadingCompleted());
        }
      });
    } else {
    }

  }
}
