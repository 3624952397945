import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ICart, Store } from "..";
import {
  IBooingPackage,
  IBooingPackageBookedSeat,
  IDateAndSession,
  IPBReducer,
  IPublicPackage,
} from "./public-web-reducers";
import { Booking } from "src/modules/models/booking/booking";
import { BookingPackages } from "src/modules/models/booking/booking-packages";
import { PublicBookingService } from "src/modules/services/public-web/public-booking.service";
import { EventService } from "src/modules/public-web/booking-application/services/event.service";
import {
  AddNewSession,
  AddPublicInternalBooking,
  BookingFailed,
  BookingPatchPreview,
  CheckBookingHasClient,
  PatchBooking,
  PatchUpSellQuantity,
  PBActionType,
  PostBooking,
  RemovePackageSession,
  ResetAllPackageQuantity,
  ResetPublicWebState,
  ResetSelectedPackageStatus,
  SetBookedPackage,
  SelectPackageSessions,
  SetBooking,
  SetBookingType,
  SetPatchPreviewStatus,
  SetRedirectionPoint,
  SetSelectedBookingPackage,
  SetSelectedSession,
  StartBookingSession,
  StartGlobalSession,
  UpdatePackage,
  UpdateSelectedBooking,
} from "./public-web-actions";
import { BookingSaveCompleted, } from "../loading";
import { Router } from "@angular/router";

import { BookingContact } from "src/modules/models/booking/booking-contact";
import { Utility } from "src/modules/utility";
import { PublicPackageService } from "src/modules/services/public-web/public-package.service";
import { Package } from "src/modules/models/public-web/Package";
import { AlertMessageComponent } from "src/modules/shared/alert-message/alert-message.component";
import { EMPTY, forkJoin } from "rxjs";
import { ErrorLog } from "src/modules/models/public-web/error";
import * as _ from "lodash";

import { BaseParam, SearchBaseFilter } from "src/modules/models/public-web/filter";
import { PAGE_CONFIGURATION, BOOKING_FLOW_OPTION } from "src/modules/public-web/components/layout/models/enums";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { PublicBookingSettings } from "src/modules/models/settings/public-booking/public-booking-setting";
import { PBUtility } from "src/modules/public-web/pb-utility";
import { BOOKING_CART } from "src/modules/models/public-web/enum";
import { PublicBookingSelectService } from "src/modules/services/public-web/public-booking-select.service";
import { LoadTimeslot, ResetConfigurationState } from "../booking-application/configuration.action";
import { BookingItems } from "src/modules/models/booking/booking-items";
import * as configurationAction from "src/modules/store/booking-application/configuration.action";
import { PatchItems } from "src/modules/public-web/components/pages/public-booking-cart/public-booking-cart.component";
import * as fromConfiguration from "src/modules/store/booking-application/configuration.selector";
import { AppSetting, MenuSettings } from "src/modules/models/settings/casual-portal/app-setting";
import { BookingStatus } from "src/modules/models/booking-status/booking-status";

@Injectable()
export class PublicWebEffects {

  @Effect({})
  postBooking$ = this.actions$.pipe(
    ofType(PBActionType.POST_PUBLIC_BOOKING),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      const publicBookingSetting = environment.PublicBookingSetting as PublicBookingSettings;
      const appSetting = environment.AppSetting as AppSetting;
      const cart = storeState.cart as ICart;
      const isSessionSelected = PBReducer.selectedSessions.length > 0;
      let isClientLoginRequired = appSetting.ClientLoginIsRequired ? appSetting.ClientLoginIsRequired : false;
      let menu: MenuSettings = appSetting.MenuSettings ? appSetting.MenuSettings.find(menu => menu.Id == 'PublicBooking') : null;
      if (isSessionSelected) {
        const booking = new Booking();
        booking.confirmed = false;
        booking.id = "-1";
        booking.cancelled = false;
        booking.margin = 0;
        booking.bookingPackages = [];
        booking.isTemporary = true;
        PBReducer.selectedSessions.forEach((session) => {
          if (!!session.packages) {
            session.packages.forEach((pk) => {
              if (!pk.isBooked) {
                if (
                  pk.isHaveLinkPackage &&
                  pk.linkPackages &&
                  pk.linkPackages.length > 0
                ) {
                  const selectedLinkPk = pk.linkPackages.filter(
                    (p) => p.quantity > 0 && !pk.isBooked
                  );
                  selectedLinkPk.forEach((lpk) => {
                    booking.bookingPackages = PBUtility.generateBookingPackage(
                      lpk,
                      session,
                      booking.bookingPackages
                    );
                  });
                } else {
                  booking.bookingPackages = PBUtility.generateBookingPackage(
                    pk,
                    session,
                    booking.bookingPackages
                  );
                }
              }
            });
          }
        });
        if (
          !!PBReducer.selectedClient &&
          !!PBReducer.selectedClient.contactId
        ) {
          booking.contact = new BookingContact();
          booking.contact.id = PBReducer.selectedClient.contactId;
        } else if (cart.contact.id) {
          booking.contact = new BookingContact();
          booking.contact.id = cart.contact.id;
        }

        if (cart.contact.id && menu && menu?.BookingPermittedClientCategoryIds?.Value?.split(',').includes(cart.contact.client?.clientCategoryId.toString()) && isClientLoginRequired) {
          booking.bookingTypeId = publicBookingSetting.publicBookingCBType;
          booking.temporary = true;
          if (cart.contact?.client?.salesChannelId) {
            booking.salesChannelId = cart.contact?.client?.salesChannelId;
          } else {
            booking.salesChannelId = 1;
          }

          if (environment.SystemOption.defaultInternetBookingStatus) {
            booking.bookingStatus = new BookingStatus();
            booking.bookingStatus.id = environment.SystemOption.defaultInternetBookingStatus;
          }
        } else {
          booking.salesChannelId = 5;
          booking.bookingTypeId = publicBookingSetting.publicBookingType;
        }
        const bookingResult = this.bookingService.postBooking(booking);
        return bookingResult.pipe(
          map((b) => {

            if (b.isError) {
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(PBReducer.selectedSessions, []);
              return new BookingFailed();
            } else {
              const cBooking = b.data as Booking;
              this.store.dispatch(new ResetSelectedPackageStatus());
              this.store.dispatch(new ResetAllPackageQuantity());
              this.store.dispatch(new SetBookingType(BOOKING_CART.NEW_BOOKING))
              return new SetBooking(cBooking);
            }
          }),
          catchError((error, caught) => {
            this.store.dispatch(new ResetSelectedPackageStatus());
            this.store.dispatch(new BookingSaveCompleted());
            this.store.dispatch(new ResetSelectedPackageStatus());

            return EMPTY;
          })
        );
      } else {
        this.openSnackBarError(["Session or Package is not selected"]);
      }
      return EMPTY;
    })
  );


  @Effect({})
  patchBooking$ = this.actions$.pipe(
    ofType(PBActionType.PATCH_BOOKING),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      const cart = storeState.cart as ICart;
      if (PBReducer.booking) {
        const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBody(
          PBReducer.selectedSessions,
          PBReducer,
          cart
        );
        const bookingResult = this.bookingService.BookingPatch(
          newBooking,
          bookingItemTypeNames
        );
        return bookingResult.pipe(
          map((b) => {
            if (b.isError) {
              this.store.dispatch(new ResetSelectedPackageStatus());
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(
                PBReducer.selectedSessions,
                PBReducer.booking.bookingPackages
              );
              return new BookingFailed();
            } else {
              const cbooking = b.data.booking as Booking;
              this.store.dispatch(new SetBooking(cbooking))
              this.store.dispatch(new ResetAllPackageQuantity());
              this.store.dispatch(new ResetSelectedPackageStatus());
              return new UpdateSelectedBooking();
            }
          }),
          catchError((error, caught) => {
            this.store.dispatch(new ResetSelectedPackageStatus());

            //  this.store.dispatch(new BookingSaveCompleted());
            let errorData = [];
            if (error.status == 500) {
              if (error.statusText) errorData.push(error.statusText);
              if (error.detail) errorData.push(error.detail);
            }

            if (
              error.error != undefined &&
              error.error.errors != null &&
              error.error.errors.length > 0
            ) {
              error.error.errors.forEach((error, i) => {
                if (error.detail) errorData.push(error.detail);
              });
            }

            this.snackBar.openFromComponent(AlertMessageComponent, {
              duration: 3000,
              verticalPosition: "top",
              data: errorData,
            });
            this.openSnackBarError(["Booking Failed"]);
            // this.bookingError(error.error.errors);
            return caught;
          })
        );
      } else {
        this.openSnackBarError(["Session is not selected"]);
      }
      return EMPTY;
    })
  );


  @Effect({ dispatch: false })
  bookingPatchPreview$ = this.actions$.pipe(
    ofType("BOOKING_PATCH_PREVIEW"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      const cart = storeState.cart as ICart;
      let bPackages = [];
      let PatchItemDetails: PatchItems[] = [];
      this._publicBookingSelectService.getValue().subscribe(bookingpacks => {
        if (bookingpacks) {
          bPackages = bookingpacks;
        }
      })
      if (PBReducer.booking) {
        const sessions = PBUtility.makeFullBookingSession(PBReducer.selectedSessions
        );
        const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(
          sessions,
          PBReducer,
          cart,
          bPackages
        );

        if (newBooking.bookingPackages?.length > 0) {
          newBooking.bookingPackages.forEach(bookingPackage => {
            if (bookingPackage.bookingItems?.length > 0) {
              bookingPackage.bookingItems.forEach(bookingItem => {
                let patchItem = new PatchItems
                if (PatchItemDetails.find(pi => pi.bookingItemId == bookingItem.id)) {
                  PatchItemDetails.find(pi => pi.bookingItemId == bookingItem.id).quantity = bookingItem.quantity;
                } else {
                  patchItem.bookingPackageId = bookingPackage.id;
                  patchItem.itemId = bookingItem.item.id;
                  patchItem.bookingItemId = bookingItem.id;
                  patchItem.quantity = bookingItem.quantity;
                  PatchItemDetails.push(patchItem);
                }
              });
            }
          });
          this.store.dispatch(new PatchUpSellQuantity(PatchItemDetails));
        }

        const bookingResult = this.bookingService.BookingPatchPreview(
          newBooking,
          PBReducer.patchPreviewId,
          bookingItemTypeNames
        );
        return bookingResult.pipe(
          map((b) => {
            if (b.isError) {
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(
                PBReducer.selectedSessions,
                PBReducer.booking.bookingPackages
              );
              this.store.dispatch(new SetPatchPreviewStatus(true));
            } else {
              const cbooking = b.data as Booking;
              this.setBookingAfterPatchPreview(cbooking, bPackages);
              // this._publicBookingSelectService.setValue(b.data?.bookingPackages);
              // this.store.dispatch(new ResetAllPackageQuantity());
              // this.store.dispatch(new ResetSelectedPackageStatus());
              // this.store.dispatch(new AddBooking(cbooking));

            }
            return [action, storeState];
          }),
          catchError((e) => {
            this.mapPackagesIntoSessions(
              PBReducer.selectedSessions,
              PBReducer.booking.bookingPackages
            );
            return [action, storeState];
          })
        );
      }
      return [action, storeState];
    })
  );


  @Effect({ dispatch: false })
  addPackage$ = this.actions$.pipe(
    ofType("ADD_PACKAGE"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      const letestselectedSession = PBReducer.selectedSessionTime;
      const selectedSessions = PBReducer.selectedSessions;
      const bookingFlowOption = PBReducer.bookingFlowOption;
      const cart = storeState.cart as ICart;
      let isAgentAccess = false;
      let portalSalesChannelId = 5;
      if (cart.contact && cart.contact?.id) {
        isAgentAccess = PBUtility.canAgentAccess(cart.contact);
        if (isAgentAccess && cart.contact?.client?.salesChannelId) {
          portalSalesChannelId = cart.contact?.client?.salesChannelId;
        } else {
          portalSalesChannelId = PBUtility.getDefaultSalesChannelId();
        }
      }
      let letestpackageId;
      if (PBReducer.booking) {
        if(PBReducer.packageIds.length > 0) {
          letestpackageId = PBReducer.packageIds[PBReducer.packageIds.length - 1]
          if (letestpackageId && letestselectedSession && letestselectedSession?.packages?.find(p => p?.id == letestpackageId)) {
            if (letestselectedSession?.packages?.find(p => p?.id == letestpackageId)?.packageSessions.filter(x => x.salesChannelId == portalSalesChannelId).length > 1 &&
            !!bookingFlowOption && bookingFlowOption == BOOKING_FLOW_OPTION.PackageListSessionVenueMap
            ) {
            let prevSession = selectedSessions.find(
              (s) =>
                  Utility.convertToISO(Utility.convertISOToDate(s.startTime)) === Utility.convertToISO(Utility.convertISOToDate(letestselectedSession.startTime)) &&
                  Utility.convertToISO(Utility.convertISOToDate(s.endTime)) === Utility.convertToISO(Utility.convertISOToDate(letestselectedSession.endTime)) &&
                  (!!s.bookingPackages && s.bookingPackages.find(y => y.packageId == letestpackageId) !== undefined)
            );
            const session = _.cloneDeep(letestselectedSession);
            let previousSession;
            if (prevSession) {
              previousSession = _.cloneDeep(prevSession);
              let packageIndex = previousSession.packages.findIndex(x => x?.id == letestpackageId)
              if (packageIndex != -1) {
                let packageIndex = letestselectedSession.packages.findIndex(x => x.id == letestpackageId);
                if (packageIndex != -1) {
                  if (previousSession.packages[packageIndex].isHaveLinkPackage && letestselectedSession.packages[packageIndex]?.isHaveLinkPackage) {
                    previousSession.packages[packageIndex].linkPackages.forEach((lp) => {
                      letestselectedSession.packages[packageIndex]?.linkPackages.forEach((publicLinkedPackage) => {
                              if (lp.id === publicLinkedPackage.id) {
                                  lp.quantity += publicLinkedPackage.quantity;
                                  lp.isBooked = false;
                              }
                          });
                      });
                      previousSession.packages[packageIndex].isBooked = false;
                  } else {
                    previousSession.packages[packageIndex].quantity += letestselectedSession.packages[packageIndex]?.quantity;
                    previousSession.packages[packageIndex].isBooked = false;
                  }
                  this.store.dispatch(new RemovePackageSession(session));
                  this.store.dispatch(new RemovePackageSession(prevSession));
                  this.store.dispatch(new SetSelectedSession(previousSession));
                  this.store.dispatch(new SelectPackageSessions([previousSession]));
                }
              }
            } else {
              if (PBReducer.booking.bookingPackages.length > 0) {
                let packageInExistingBooking = PBReducer.booking.bookingPackages.find(bp => bp?.package?.id.toString() == letestpackageId &&
                  (Utility.convertToISO(Utility.convertISOToDate(bp?.startDate)) === Utility.convertToISO(Utility.convertISOToDate(letestselectedSession.startTime))) &&
                    (Utility.convertToISO(Utility.convertISOToDate(bp?.endDate)) === Utility.convertToISO(Utility.convertISOToDate(letestselectedSession.endTime))));
                if (packageInExistingBooking) {
                  let packageIndex = letestselectedSession.packages.findIndex(x => x.id == letestpackageId);
                  if (packageIndex != -1) {
                    letestselectedSession.packages[packageIndex].quantity = letestselectedSession.packages[packageIndex].quantity + Number(packageInExistingBooking.quantity);
                  }
                  this.store.dispatch(new RemovePackageSession(session));
                  this.store.dispatch(new SetSelectedSession(letestselectedSession));
                  this.store.dispatch(new SelectPackageSessions([letestselectedSession]));
                }
              }
            }
            }
          }
        }
        if (PBReducer.bookingMode === BOOKING_CART.EDIT_BOOKING) {
          if (PBReducer.sessionTimers) {
            if (PBReducer.sessionTimers.bookingSession && !PBReducer.sessionTimers.bookingSession.isSessionTimerStart) {
              this.store.dispatch(new StartBookingSession(new Date()));
              this.store.dispatch(new StartGlobalSession(new Date()));
            }
          }
          this.store.dispatch(new BookingPatchPreview());
        } else {
          this.store.dispatch(new PatchBooking());
        }
      } else {
        this.store.dispatch(new PostBooking());
      }
      return [action, storeState];
    })
  );


  @Effect({ dispatch: false })
  setClient$ = this.actions$.pipe(ofType(PBActionType.SET_SECONDARY_CLIENT),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      if (PBReducer.booking && !!PBReducer.selectedClient) {
        this.store.dispatch(new CheckBookingHasClient());
      }
      return [action, storeState];
    })
  );


  @Effect({ dispatch: false })
  checkBookingHasClient$ = this.actions$.pipe(ofType(PBActionType.CHECK_BOOKING_HAS_CLIENT),
    withLatestFrom(this.store.source),
    switchMap(([action, state]) => {
      const PBReducer = state.PBReducer as IPBReducer;
      const booking = PBReducer.booking;
      const cart = state.cart as ICart;
      const newBooking = new Booking();
      let canUpdate = false;
      if (booking) {
        newBooking.id = booking.id;
        newBooking.contact = new BookingContact();
        if (cart && cart.contact && cart.contact.id) {
          if (!!PBReducer.selectedClient && booking.contact.id !== PBReducer.selectedClient.contactId) {
            canUpdate = true;
            newBooking.contact.id = PBReducer.selectedClient.contactId;
          } else if (booking.contact.id !== cart.contact.id &&
            booking.contact.id === (environment.PublicBookingSetting as PublicBookingSettings).defaultClient // default client id
          ) {
            canUpdate = true;
            newBooking.contact.id = cart.contact.id;
          }
        } else if (PBReducer.guestContact.id &&
          (booking.contact.id === (environment.PublicBookingSetting as PublicBookingSettings).defaultClient ||
            booking.contact.id !== PBReducer.guestContact.id)
        ) {
          canUpdate = true;
          newBooking.contact.id = PBReducer.guestContact.id;
        }

        if (canUpdate) {
          let bookingResult = this.bookingService.BookingPatch(newBooking);
          return bookingResult.pipe(
            map((result) => {
              if (!result.isError) {
                let cbooking = result.data.booking as Booking;
                this.store.dispatch(new SetBooking(cbooking))
                // this.store.dispatch(new BookingSaveCompleted());
                return {
                  type: PBActionType.SET_BOOKING,
                  payload: cbooking,
                };
              } else {
                this.openSnackBarError(["Session or Package is not selected"]);
              }
            }),
            catchError((error: any) => {
              this.bookingError(error.error.errors);
              return null;
            })
          );
        }
      }
      return [action, state];
    })
  );


  @Effect({ dispatch: false })
  addBooking$ = this.actions$.pipe(ofType(PBActionType.SET_BOOKING),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      if ((PBReducer.redirectionPoint && PBReducer.redirectionPoint.length > 3) || PBReducer.redirectionPoint == "") {
        if (PBReducer.pageConfiguration.includes(PAGE_CONFIGURATION.CONFIG_CAN_SELECT_MULTI_SESSION)) {
          if ((PBReducer.redirectionPoint && PBReducer.redirectionPoint != ""))
            this.router.navigate(["/" + PBReducer.redirectionPoint]);
        } else {

          if (!!PBReducer.booking) {
            if (!!PBReducer.booking && PBReducer.bookingMode === BOOKING_CART.NEW_BOOKING) {
              this.store.dispatch(new StartBookingSession(new Date()));
              this.store.dispatch(new StartGlobalSession(new Date()));
            }
            if (PBReducer.bookingMode === BOOKING_CART.NEW_BOOKING && PBReducer.booking.bookingPackages.length > 0 &&
              PBReducer.booking.bookingPackages.filter(pack => (+pack.quantity == 0))?.length > 0) {
              this.deletePackageFromBooking(PBReducer.booking);
            } else {
              if (PBReducer.bookingMode === BOOKING_CART.NEW_BOOKING && storeState.PBReducer?.bookedPackages && storeState.PBReducer?.bookedPackages?.filter(pack => !!pack.name)?.length > 0) {
                storeState.PBReducer.booking?.bookingPackages?.forEach(bp => {
                  let pack = storeState.PBReducer?.bookedPackages?.find(pack => pack.id == bp.package.id)
                  if (pack) {
                    bp.package = pack;
                  }
                })
              }
              if (PBReducer.booking.bookingPackages.filter(pack => !!pack.name)?.length > 0) {
                this.renderBookedPackages(PBReducer.booking, PBReducer.bookedPackages);
              }
              const patchUpSellQuantity = PBReducer.PatchItems;
              let isUpdated = false;
              if (patchUpSellQuantity.length > 0) {
                PBReducer.booking.bookingPackages.forEach(bookingPackage => {
                  if (bookingPackage?.bookingItems?.length > 0) {
                    bookingPackage.bookingItems.forEach(bookingItem => {
                      let item = patchUpSellQuantity.find(x => x.bookingItemId == bookingItem.id)
                      if (item && item?.quantity != bookingItem.quantity) {
                        bookingItem.quantity = item.quantity;
                        isUpdated = true;
                      }
                    });
                  }
                });
                if (isUpdated) {
                  this.store.dispatch(new PatchUpSellQuantity([]));
                  this.store.dispatch(new SetBooking(PBReducer.booking));
                }
              }
            }
          }
          if (PBReducer.redirectionPoint.toLocaleLowerCase().includes("upsell")) {
            let bookedPackageIds =[];
            let bookingPackageIds = [];
            PBReducer.bookedPackages.forEach(bp => {
              if(!!bp.name){
                bookedPackageIds.push(bp.id);
                if(bp?.linkedPackages?.length > 0){
                  bp.linkedPackages.forEach(lp => {bookedPackageIds.push(lp.id)})
                }
              }
            });
            PBReducer.booking.bookingPackages.forEach(bp => {bookingPackageIds.push(bp.package.id)});
            bookedPackageIds = [...new Set(bookedPackageIds)];
            bookingPackageIds = [...new Set(bookingPackageIds)];

            if (bookingPackageIds.every(element => bookedPackageIds.includes(element))) {
              const nav = PBReducer.redirectionPoint.toLocaleLowerCase().toString();
              let id = nav.replace("upsell/", "");
              if (id && PBReducer.selectedSessionTime) {
                const sessionStart = Utility.convertToISO(
                  Utility.convertISOToDate(
                    PBReducer.selectedSessionTime.startTime
                  )
                );
                const sessionEnd = Utility.convertToISO(
                  Utility.convertISOToDate(
                    PBReducer.selectedSessionTime.endTime
                  )
                );
                const bookingPK = PBReducer.booking.bookingPackages.find(
                  (bp) =>
                    bp.package.id === id &&
                    bp.startDate === sessionStart &&
                    bp.endDate === sessionEnd
                );
                if (bookingPK) {
                  this.router.navigate(["/" + PBReducer.redirectionPoint], {
                    queryParams: {
                      BookingPackage: bookingPK.id,
                    },
                  });
                } else {
                  this.router.navigate(["/" + PBReducer.redirectionPoint]);
                }
              } else {
                this.router.navigate(["/" + PBReducer.redirectionPoint]);
              }
            }
          } else if (PBReducer.redirectionPoint.toLocaleLowerCase().includes("booking-cart")) {
            let bookedPackageIds =[];
            let bookingPackageIds = [];
            PBReducer.bookedPackages.forEach(bp => {
              if(!!bp.name){
                bookedPackageIds.push(bp.id);
                if(bp?.linkedPackages?.length > 0){
                  bp.linkedPackages.forEach(lp => {bookedPackageIds.push(lp.id)})
                }
              }
            });
            PBReducer.booking.bookingPackages.forEach(bp => {bookingPackageIds.push(bp.package.id)});
            bookedPackageIds = [...new Set(bookedPackageIds)];
            bookingPackageIds = [...new Set(bookingPackageIds)];

            if (bookingPackageIds.every(element => bookedPackageIds.includes(element))) {
                // console.log('timeout navigation');
                window.open(environment.PublicWebPortalUrl + "#/booking-cart", "_self");
            }
          } else {
            if ((PBReducer.redirectionPoint && PBReducer.redirectionPoint.length > 0))
              this.router.navigate(["/" + PBReducer.redirectionPoint]);
          }
        }
      }

      return [action, storeState];
    })
  );


  @Effect({})
  updateBooking$ = this.actions$.pipe(
    ofType("UPDATE_SELECTED_BOOKING"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      if (PBReducer.booking) {
        let bookingSub = this.bookingService.selectBooking(
          PBReducer.booking.id,
          false,
          false,
          true
        );
        return bookingSub.pipe(
          map((b) => {

            if (b.isError) {
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(
                PBReducer.selectedSessions,
                PBReducer.booking.bookingPackages
              );
            } else {
              const cbooking = b.data as Booking;
              return {
                type: PBActionType.SET_BOOKING,
                payload: cbooking,
              };
            }
          }),
          catchError((error: any) => {

            this.bookingError(error.error.errors);
            return null;
          })
        );
      }
      return EMPTY;
    })
  );


  @Effect({})
  translatedupdateBooking$ = this.actions$.pipe(
    ofType("UPDATE_TRANSLATE_SELECTED_BOOKING"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      if (PBReducer.booking) {
        let bookingSub = this.bookingService.selectBooking(
          PBReducer.booking.id
        );
        return bookingSub.pipe(
          map((b) => {

            if (b.isError) {
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(
                PBReducer.selectedSessions,
                PBReducer.booking.bookingPackages
              );
            } else {
              const translatedBooking = b.data as Booking;
              const existingBooking = PBReducer.booking;
              existingBooking?.bookingPackages?.forEach((pkg) => {
                var index = translatedBooking.bookingPackages.findIndex(x => x.id === pkg.id);
                if (index != -1) {
                  if (pkg.name) {
                    pkg.name = translatedBooking.bookingPackages[index].name;
                    if (pkg?.package?.id === translatedBooking.bookingPackages[index].package.id) {
                      pkg.package.name = translatedBooking.bookingPackages[index].package.name;
                      pkg.package.description = translatedBooking.bookingPackages[index].package.description;
                      pkg.package.nameOnReport = translatedBooking.bookingPackages[index].package.nameOnReport;
                      pkg.package.webDescription = translatedBooking.bookingPackages[index].package.webDescription;
                    }
                  } else {
                    let pack = new BookingPackages();
                    pack.id = pkg.id;
                    pack.type = pkg.type;
                    pack.name = translatedBooking.bookingPackages[index].name;
                    pack.package = new Package();
                    pack.package.name = translatedBooking.bookingPackages[index].package.name;
                    pack.package.description = translatedBooking.bookingPackages[index].package.description;
                    pack.package.nameOnReport = translatedBooking.bookingPackages[index].package.nameOnReport;
                    pack.package.webDescription = translatedBooking.bookingPackages[index].package.webDescription;
                    pkg = pack;
                  }
                  pkg?.bookingItems?.forEach((item) => {
                    var itemindex = translatedBooking.bookingPackages[index].bookingItems.findIndex(i => i.id === item.id);
                    if (itemindex != -1) {
                      item.name = translatedBooking.bookingPackages[index].bookingItems[itemindex].name;
                    }
                  })
                }
              })
              return {
                type: PBActionType.SET_BOOKING,
                payload: existingBooking,
              };
            }
          }),
          catchError((error: any) => {
            console.table("booking Errors", error.message);
            return null;
          })
        );
      }
      return EMPTY;
    })
  );


  @Effect({ dispatch: false })
  updatePackageCart$ = this.actions$.pipe(ofType(PBActionType.SET_PACKAGE_LIST),
    withLatestFrom(this.store.source),
    switchMap(([action, state]) => {

      let packages: IPublicPackage[] = (state.PBReducer.publicPackages as IPublicPackage[]).filter((x) =>
        x.isHaveLinkPackage && !x.hasTicketing && (x.linkPackages === undefined || x.linkPackages.length === 0)
      );
      const packageIDs = [];
      const flitter = new SearchBaseFilter();

      packages.forEach((x) => {
        packageIDs.push(x.id);
      });
      flitter.packageIDs = packageIDs;
      if (packageIDs.length > 0) {
        return this._packageService.getPublicPackages(new BaseParam(), flitter).pipe(map((pk: Package[]) => {
            if (pk.length > 0) {
              pk.forEach((p) => {
                const pkg = packages.find((xd) => xd.id === p.id);
                if (p && p.linkedPackages && !!pkg) {
                  p.linkedPackages = Array.from(
                    new Set(p.linkedPackages.map((a) => a.id))
                  ).map((id) => {
                    return p.linkedPackages.find((a) => a.id === id);
                  });
                  let linkPackage = _.cloneDeep(p.linkedPackages);
                  p.linkedPackages = [];
                  linkPackage.push(p);
                  p.linkedPackages.reverse();
                  pkg.linkPackages = linkPackage;
                  pkg.maximumAttendees = p.maximumAttendees;
                  pkg.linkPackages = pkg.linkPackages
                    .sort((x, y) => x.linkedPackageType.id > y.linkedPackageType.id ? -1 : 1)
                    .sort((x, y) => x.linkedPackageType.adult ? -1 : 1)
                  this.store.dispatch(new UpdatePackage(pkg));
                }
              });
            }
            return EMPTY;
          }),
          catchError((error) => {
            console.log(error);
            return EMPTY;
          })
        );
      }
      return [action, state];
    })
  );


  @Effect({ dispatch: false })
  updateBookedPackage$ = this.actions$.pipe(ofType(PBActionType.SET_BOOKED_PACKAGES),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const bookedPackages = (storeState.PBReducer as IPBReducer).bookedPackages;
      let eventPackages = [];
      this.store.select(fromConfiguration.selectEventPackages).subscribe((eventPacks) => {
        if (eventPacks) {
        eventPackages = eventPacks;
        }
      });
      const isInternalUser = environment.IsInternalUser;
      if (!isInternalUser) {
        const param = new SearchBaseFilter();
        param.packageIDs = bookedPackages.filter(p => !p.name).map(x => x.id);
        if (param.packageIDs.length > 0) {
          const getPackagesObs = this._packageService.getPublicPackages(new BaseParam(), param)
          return getPackagesObs.pipe(map((pk) => {
            if (pk) {
              const packages = bookedPackages.filter(p => !!p.name)
              pk.forEach(p => {
                if (eventPackages.length > 0) {
                  let pack = eventPackages.find(ep => ep.id == p.id)
                  if (pack && pack.upsellItem) {
                    p.upsellItem = pack.upsellItem;
                  }
                }
                packages.push(p);
                if (p.linkedPackages && p.linkedPackages.length > 0) {
                  packages.push(...p.linkedPackages)
                }
              })
              this.store.dispatch(new SetBookedPackage(packages));
              storeState.PBReducer.booking.bookingPackages.forEach(bp => {
                let pack = packages.find(pack => pack.id == bp.package.id)
                if (pack) {
                  bp.package = pack;
                }
              })
              if (storeState.PBReducer.redirectionPoint.toLocaleLowerCase().includes("upsell")) {
                const nav = storeState.PBReducer.redirectionPoint
                  .toLocaleLowerCase()
                  .toString();
                let id = nav.replace("upsell/", "");
                if (id && storeState.PBReducer.selectedSessionTime) {
                  const sessionStart = Utility.convertToISO(
                    Utility.convertISOToDate(
                      storeState.PBReducer.selectedSessionTime.startTime
                    )
                  );
                  const sessionEnd = Utility.convertToISO(
                    Utility.convertISOToDate(
                      storeState.PBReducer.selectedSessionTime.endTime
                    )
                  );
                  const bookingPK = storeState.PBReducer.booking.bookingPackages.find(
                    (bp) =>
                      bp.package.id === id &&
                      bp.startDate === sessionStart &&
                      bp.endDate === sessionEnd
                  );
                  const bookedPackagess = storeState.PBReducer.bookedPackages;
                  if (bookingPK) {
                    this.router.navigate(["/" + storeState.PBReducer.redirectionPoint], {
                      queryParams: {
                        BookingPackage: bookingPK.id,
                      },
                    });
                  } else {
                    this.router.navigate(["/" + storeState.PBReducer.redirectionPoint]);
                  }
                } else {
                  this.router.navigate(["/" + storeState.PBReducer.redirectionPoint]);
                }
              } else if (storeState.PBReducer.redirectionPoint.toLocaleLowerCase().includes("booking-cart")) {
                window.open(environment.PublicWebPortalUrl + "#/booking-cart","_self");
              }

              return null;
            } else {
              return null;
            }
          }))
        } else if (bookedPackages.filter(p => p.name).length === bookedPackages.length) {
          if (storeState.PBReducer.booking?.bookingPackages?.filter(bp => !!bp.name)?.length > 0) {
            const sessions = PBUtility.convertBookingIntoPBSessionData(
              storeState.PBReducer.booking,
              storeState.PBReducer.bookedPackages
            );
            if (sessions.length > 0) {
              this.store.dispatch(new AddNewSession(sessions))
              return EMPTY;
            }
          } else {
            return EMPTY;
          }
        }
      } else {
        return EMPTY;
      }
    })
  );


  @Effect({ dispatch: false })
  selectBooking$ = this.actions$.pipe(ofType(PBActionType.SET_SELECTED_BOOKING),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const selectBooking = (storeState.PBReducer as IPBReducer).selectedBooking;
      const booking = (storeState.PBReducer as IPBReducer).booking;
      const isInternalUser = environment.IsInternalUser;
      const selectedInternalBookingPackageId = this._publicBookingSelectService.getVenueMapDisplayedInternalBookingPackageId();
      if (selectBooking.id && isInternalUser) {
        if (!booking || booking?.id != selectBooking.id) {
          this.bookingService.selectInternalBooking(selectBooking.id, false, false).subscribe((d) => {
            if (!d.isError && d.data) {
              let booking = d.data;
              this.store.dispatch(new AddPublicInternalBooking(d.data));
            }
          });
        }
      }
      return [action, storeState];
    })
  );

  @Effect({ dispatch: false })
  addInternalBooking$ = this.actions$.pipe(ofType(PBActionType.ADD_PUBLIC_INTERNAL_BOOKING),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const booking = (storeState.PBReducer as IPBReducer).booking;
      const redirectionPoint = (storeState.PBReducer as IPBReducer).redirectionPoint;
      const isInternalUser = environment.IsInternalUser;
      const selectedbookedPackages: IBooingPackageBookedSeat[] = [];
      const bookingPackages: BookingPackages[] = [];
      let bookingPackagesCount : number = 0;
      if (isInternalUser) {
        if (redirectionPoint === "/coprateBooking") {
          //here implement the redirection part

          this.store.dispatch(new ResetPublicWebState());
          this.store.dispatch(new ResetConfigurationState());
          this.store.dispatch(new SetRedirectionPoint(''));

          this._publicBookingSelectService.resetValues();
          const cbRedirectionUrl = `${environment.CorporateBookingUrl}/#/booking-details/${booking.id}`;
          setTimeout(() => {
            window.open(cbRedirectionUrl, '_self', 'noopener,noreferrer');
          }, 0);
        } else {
          this._publicBookingSelectService.getVenueMapDisplayedInternalBookingPackageIds().subscribe(async (values) => {
            if (!!booking && values.length > 0) {
              await values.forEach(async value => {
                if (booking.bookingPackages && booking.bookingPackages?.find(bp => bp.id == value)) {
                  let request = [];
                  let errors = [];
                  let bookingPackage: BookingPackages;
                  let bookingItems: BookingItems[];
                  request.push(this.bookingService.GetBookingPackageForBooking(booking.id, value));
                  request.push(this.bookingService.GetBookingItemForBookingPackage(booking.id, value));
                  bookingPackagesCount++;
                  await new Promise((resolve) => {
                    const observable = forkJoin(request);
                    observable.subscribe((results: any[]) => {
                      results.forEach((res, i) => {
                        if (res.isError) {
                          errors.push(res.message)
                        } else {
                          if (res.data instanceof Array) {
                            bookingItems = res.data;
                          } else {
                            bookingPackage = res.data;
                            const selectedbookedPackage: IBooingPackageBookedSeat = {
                              id: bookingPackage?.id,
                              packageId: bookingPackage?.package?.id,
                              seat: null,
                              quantity:0
                            }
                            selectedbookedPackages.push(selectedbookedPackage);
                          }
                        }
                      })
                      if (bookingItems && bookingPackage) {
                        bookingPackage.bookingItems = bookingItems;
                        bookingPackages.push(bookingPackage);
                      }
                      if (bookingPackagesCount == bookingPackages.length) {
                        this._publicBookingSelectService.setValue(bookingPackages);
                        this.store.dispatch(new SetSelectedBookingPackage(selectedbookedPackages));
                      }
                      resolve(results)
                    })
                  })
                }
              })
            }
          });
        }
      }
      return [action, storeState];
    })
  );

  @Effect({ dispatch: false })
  selectedSession$ = this.actions$.pipe(ofType(PBActionType.SET_SELECTED_SESSION),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const selectedSession = (storeState.PBReducer as IPBReducer).selectedSessionTime;
      if (selectedSession && selectedSession.eventDate) {
        let eventDate = selectedSession.eventDate as any;
        this.store.dispatch(new configurationAction.SetEventDate(eventDate));
        const isInternalUser = environment.IsInternalUser;
        if (!!selectedSession && isInternalUser) {
          this.store.dispatch(new LoadTimeslot())
        }
      }
      return EMPTY;
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private bookingService: PublicBookingService,
    private _packageService: PublicPackageService,
    private router: Router,
    public snackBar: MatSnackBar,
    private _publicBookingSelectService: PublicBookingSelectService,
    private eventService: EventService) {

  }

  public renderBookedPackages(booking: Booking, bookedPackages: Package[]) {
    const packages: Package[] = [];
    if (booking.bookingPackages) {
      booking.bookingPackages.forEach(bp => {
        const isExist = bookedPackages.findIndex(p => p.id === bp.package.id);
        if (isExist != -1) {
          packages.push(bookedPackages[isExist])
        } else {
          const d = new Package()
          d.id = bp.package.id;
          packages.push(d)
        }

        if (!!bp.package.primaryPackage) {
          const isExistPrimary = bookedPackages.findIndex(p => p.id === bp.package?.primaryPackage?.id);
          if (isExistPrimary != -1) {
            packages.push(bookedPackages[isExist])
          } else {
            const d = new Package()
            d.id = bp.package?.primaryPackage?.id;
            packages.push(d)
          }
        }
      })
    }
    this.store.dispatch(new SetBookedPackage(packages))
  }

  public deletePackageFromBooking(booking: Booking) {
    let request = [];
    let errors = [];
    booking.bookingPackages.forEach(bp => {
      if (bp.id && (+bp.quantity == 0)) {
        request.push(this.bookingService.deleteBookingPackage(booking.id, bp.id, false));
      }
    })
    const observable = forkJoin(request);
    observable.subscribe((results: any[]) => {
      const isError = false;
      results.forEach(result => {
        if (result.body.isError) {
          errors.push(result.body.message);
        } else {
          this.store.dispatch(new SetBooking(result.body.data));
        }
      })
      if (errors.length > 0) {

      }
    });
  }

  // }
  mapErrorMassage(errors: any[]) {
    const errorMassage = [];
    errors.forEach((e) => {
      if (e.detail) errorMassage.push(e.detail);
    });
    return errorMassage;
  }

  private mapPackagesIntoSessions(
    sessions: IDateAndSession[],
    bookingPackages: BookingPackages[]
  ) {
    bookingPackages.forEach((bPack) => {
      const findPackage = (x: IPublicPackage, i) =>
        x.isHaveLinkPackage && x.linkPackages.length > 0
          ? !!x.linkPackages.find((p) => p.id === bPack.package.id)
          : x.id === bPack.package.id;
      let sessionIndex = sessions.findIndex(
        (ses) =>
          Utility.convertToISO(Utility.convertISOToDate(ses.startTime)) ===
          bPack.startDate &&
          Utility.convertToISO(Utility.convertISOToDate(ses.endTime)) ===
          bPack.endDate &&
          !!ses.packages.find(findPackage)
      );
      if (sessionIndex !== -1) {
        const bookedItems = bPack.bookingItems.filter((x) => x.upsell);
        const packIndex = sessions[sessionIndex].packages.findIndex(findPackage);
        if (packIndex !== -1) {
          if (sessions[sessionIndex].packages[packIndex].isHaveLinkPackage) {
            sessions[sessionIndex].packages[packIndex].linkPackages.forEach(
              (lp) => {
                if (lp.id === bPack.package.id) {
                  lp.quantity = +bPack.quantity;
                }
              }
            );
          } else if (
            sessions[sessionIndex].packages[packIndex].id === bPack.package.id
          ) {
            sessions[sessionIndex].packages[packIndex].quantity =
              +bPack.quantity;
          }
          if (bookedItems.length === 0) {
            sessions[sessionIndex].packages[packIndex].addOns = []
          } else {
            bookedItems.forEach((x) => {
              if (x && x.item) {
                const item: any = _.cloneDeep(x.item);
                item.qty = x.quantity;
                if (!sessions[sessionIndex].packages[packIndex].addOns) {
                  sessions[sessionIndex].packages[packIndex].addOns = [x.item];
                } else {
                  const isEx = sessions[sessionIndex].packages[
                    packIndex
                    ].addOns.findIndex((i) => i.id === item.id);
                  if (isEx !== -1) {
                    sessions[sessionIndex].packages[packIndex].addOns[isEx] =
                      item;
                  } else {
                    sessions[sessionIndex].packages[packIndex].addOns.push(item);
                  }
                }
              }
            });
          }
        }
        if (!sessions[sessionIndex].bookingPackages) {
          sessions[sessionIndex].bookingPackages = [];
        }
        const bPackage = sessions[sessionIndex].bookingPackages.find(
          (b) => b.id === bPack.id && b.packageId === bPack.package.id
        );
        if (!bPackage) {
          const bp: IBooingPackage = {
            id: bPack.id,
            packageId: bPack.package.id,
            quantity: Number(bPack.quantity)
          };
          sessions[sessionIndex].bookingPackages.push(bp);
        }
      }
    });
    if (!bookingPackages || bookingPackages.length === 0) {
      sessions.forEach((s) => {
        s.packages = [];
      });
      sessions = [];
    }
    this.store.dispatch(new SelectPackageSessions(sessions));
  }

  // private generateBookingPatchBody(PBReducer, publicCart, cart) {
  //   const newBooking = new Booking();
  //   const bookingItemTypeNames = [];
  //   const repeatPolicies = (
  //     environment.PublicBookingSetting as PublicBookingSettings
  //   ).repeatPolicy;
  //   newBooking.id = PBReducer.booking.id;
  //   // Create Booking Package for Post And Patch
  //   if (publicCart && publicCart.publicPackages) {
  //     newBooking.bookingPackages = [];
  //     PBReducer.selectedSessions.forEach((session: IDateAndSession) => {
  //       if (!!session.packages) {
  //         const sessionStart = Utility.convertToISO(
  //           Utility.convertISOToDate(session.startTime)
  //         );
  //         const sessionEnd = Utility.convertToISO(
  //           Utility.convertISOToDate(session.endTime)
  //         );
  //         session.packages.forEach((pk) => {
  //           if (!pk.isBooked) {
  //             if (
  //               pk.isHaveLinkPackage &&
  //               pk.linkPackages &&
  //               pk.linkPackages.length > 0
  //             ) {
  //               const selectedLinkPk = pk.linkPackages.filter(
  //                 (p) => !pk.isBooked
  //               );
  //               selectedLinkPk.forEach((lpk) => {
  //                 newBooking.bookingPackages = this.generateBookingPackage(
  //                   lpk,
  //                   session,
  //                   newBooking.bookingPackages,
  //                   PBReducer.booking.bookingPackages.filter(
  //                     (apk) => apk.package.id === lpk.id
  //                   )
  //                 );
  //               });
  //               // }
  //             } else {
  //               newBooking.bookingPackages = this.generateBookingPackage(
  //                 pk,
  //                 session,
  //                 newBooking.bookingPackages,
  //                 PBReducer.booking.bookingPackages.filter(
  //                   (apk) => apk.package.id === pk.id
  //                 )
  //               );
  //             }
  //             pk.isBooked = true;
  //           }
  //           if (!!pk.addOns) {
  //             pk.addOns.forEach((item) => {
  //               if (item.isNeedToBeUpdate) {
  //                 let packageID = pk.id;
  //                 let bookingPackage: BookingPackages;
  //                 let durationDates = [];
  //                 if (
  //                   session.durationRange &&
  //                   session.durationRange.packageDates
  //                 ) {
  //                   durationDates = _.cloneDeep(
  //                     session.durationRange.packageDates
  //                   );
  //                   durationDates.sort((x, y) =>
  //                     x.eventDate.date > y.eventDate.date ? 1 : 0
  //                   );
  //                 }
  //                 if (PBReducer.booking.bookingPackages) {
  //                   const selectedPackage = _.cloneDeep(pk);
  //                   // * if it have linked package
  //                   if (
  //                     selectedPackage &&
  //                     selectedPackage.isHaveLinkPackage &&
  //                     selectedPackage.linkPackages &&
  //                     selectedPackage.linkPackages.length > 0
  //                   ) {
  //                     const select = selectedPackage.linkPackages.filter(
  //                       (x) =>
  //                         !!PBReducer.booking.bookingPackages.find(
  //                           (p) => p.package.id === x.id
  //                         ) && x.quantity > 0
  //                     );
  //                     if (select.length > 0) {
  //                       const val = select.sort(
  //                         (a, b) =>
  //                           parseInt(a.linkedPackageType.id, 10) -
  //                           parseInt(b.linkedPackageType.id, 10)
  //                       );
  //                       packageID = val[0].id;
  //                     }
  //                   }
  //                 }
  //                 const bookingPK =
  //                   PBReducer.booking.bookingPackages.find(
  //                     (bp) =>
  //                       bp.package.id === packageID &&
  //                       Utility.convertToISO(
  //                         Utility.convertISOToDate(bp.startDate)
  //                       ) === sessionStart &&
  //                       Utility.convertToISO(
  //                         Utility.convertISOToDate(bp.endDate)
  //                       ) === sessionEnd
  //                   );
  //                 // *Create Booking Package For Booking Item
  //                 if (bookingPK) {
  //                   bookingPackage = new BookingPackages();
  //                   bookingPackage.id = bookingPK.id;
  //                   bookingPackage.startDate = bookingPK.startDate;
  //                   bookingPackage.endDate = bookingPK.endDate;
  //                   // if (!!bookingPK.quantity && bookingPK.quantity !== '0') {
  //                   //   bookingPackage.quantity = bookingPK.quantity;
  //                   // }
  //                   bookingPackage.package = new Package();
  //                   bookingPackage.package.id = packageID;
  //                   bookingPackage.bookingItems = bookingPK.bookingItems
  //                     .filter((i) => i !== null && i.upsell && i.item)
  //                     .map((i) => {
  //                       const bookingItem = new BookingItems();
  //                       bookingItem.id = i.id;
  //                       bookingItem.type = i.type;
  //                       bookingItem.item.id = i.item.id;
  //                       bookingItem.item.type = i.item.type;
  //                       bookingItem.quantity = i.quantity;
  //                       return bookingItem;
  //                     });
  //                   if (newBooking.bookingPackages === undefined) {
  //                     newBooking.bookingPackages = [bookingPackage];
  //                   } else {
  //                     const bpIndex = newBooking.bookingPackages.findIndex(
  //                       (bp) =>
  //                         bp.package.id === packageID &&
  //                         Utility.convertToISO(
  //                           Utility.convertISOToDate(bp.startDate)
  //                         ) === sessionStart &&
  //                         Utility.convertToISO(
  //                           Utility.convertISOToDate(bp.endDate)
  //                         ) === sessionEnd
  //                     );
  //                     if (bpIndex !== -1) {
  //                       newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
  //                     } else {
  //                       newBooking.bookingPackages.push(bookingPackage);
  //                     }
  //                   }
  //                   bookingPackage = newBooking.bookingPackages.find(
  //                     (bp) =>
  //                       bp.package.id === packageID &&
  //                       Utility.convertToISO(
  //                         Utility.convertISOToDate(bp.startDate)
  //                       ) === sessionStart &&
  //                       Utility.convertToISO(
  //                         Utility.convertISOToDate(bp.endDate)
  //                       ) === sessionEnd
  //                   );
  //                 }
  //                 if (bookingPackage) {
  //                   bookingPackage.bookingItems =
  //                     bookingPackage.bookingItems === undefined
  //                       ? []
  //                       : bookingPackage.bookingItems;
  //                   let bookingItem = bookingPackage.bookingItems.find(
  //                     (i) => i.item.id === item.id
  //                   );
  //                   bookingPackage.bookingItems = []; // Temp by Thuveepan
  //                   if (bookingItem === undefined) {
  //                     bookingItem = new BookingItems();
  //                     bookingItem.quantity = 0;
  //                     let biCount = 0;
  //                     newBooking.bookingPackages.forEach(
  //                       (b) =>
  //                         !!b.bookingItems && (biCount += b.bookingItems.length)
  //                     );
  //                     bookingItem.id = ((biCount + 1) * -1).toString();
  //                   }
  //                   var tp =
  //                     this.getItemPrefix(item.type.toLowerCase()) + item.type;
  //                   if (item.type.toLowerCase() == "assetitem") {
  //                     tp = "PublicBookingGeneralAdmissionItem";
  //                   }
  //                   bookingItemTypeNames.push(tp);
  //                   bookingItem.type = tp;
  //                   bookingItem.quantity = item.qty;
  //                   bookingItem.name = item.name;
  //                   bookingItem.upsell = true;
  //                   bookingItem.item.id = item.id;
  //                   bookingItem.item.type = item.type;
  //                   if (durationDates.length > 0) {
  //                     switch (item.repeatPolicy) {
  //                       case repeatPolicies.startDateOnly:
  //                         bookingItem.startTime =
  //                           durationDates[0].eventDate.startTime;
  //                         bookingItem.endTime =
  //                           durationDates[0].eventDate.endTime;
  //                         break;
  //                       case repeatPolicies.endDateOnly:
  //                         bookingItem.startTime =
  //                           durationDates[
  //                             durationDates.length - 1
  //                           ].eventDate.startTime;
  //                         bookingItem.endTime =
  //                           durationDates[
  //                             durationDates.length - 1
  //                           ].eventDate.endTime;
  //                         break;
  //                       case repeatPolicies.repeatAllDays:
  //                         bookingItem.startTime = sessionStart;
  //                         bookingItem.endTime = sessionEnd;
  //                         break;
  //                       case repeatPolicies.allowIndividualDays:
  //                         // need to do
  //                         break;
  //                       default:
  //                         bookingItem.startTime =
  //                           durationDates[0].eventDate.startTime;
  //                         bookingItem.endTime =
  //                           durationDates[
  //                             durationDates.length - 1
  //                           ].eventDate.endTime;
  //                         break;
  //                     }
  //                   } else {
  //                     bookingItem.startTime = sessionStart;
  //                     bookingItem.endTime = sessionEnd;
  //                   }

  //                   const itemIndex = bookingPackage.bookingItems.findIndex(
  //                     (it) => it.id === bookingItem.id
  //                   );
  //                   if (itemIndex !== -1) {
  //                     bookingPackage.bookingItems[itemIndex] =
  //                       _.cloneDeep(bookingItem);
  //                   } else {
  //                     bookingPackage.bookingItems.push(
  //                       _.cloneDeep(bookingItem)
  //                     );
  //                   }
  //                   const ind = newBooking.bookingPackages.findIndex(
  //                     (b) => b.id === bookingPackage.id
  //                   );
  //                   if (ind !== -1) {
  //                     newBooking.bookingPackages[ind] = bookingPackage;
  //                   } else {
  //                     newBooking.bookingPackages.push(bookingPackage);
  //                   }
  //                   // });
  //                 } else {
  //                   this.openSnackBarError([
  //                     "Booking Package is not Available for this package",
  //                   ]);
  //                 }
  //                 // item.isNeedToBeUpdate = false;
  //               }
  //             });
  //           }
  //         });
  //         // Only work for Multi day package with upsell item repeat policy for individual date's
  //         // !!make sure validate that
  //         if (session.durationRange && session.durationRange.packageDates && session.durationRange.packageDates.length > 0) {
  //           session.durationRange.packageDates.forEach(packageDate => {
  //             const itemSessionStart = Utility.convertToISO(
  //               Utility.convertISOToDate(packageDate.eventDate.startTime)
  //             );
  //             const itemSessionEnd = Utility.convertToISO(
  //               Utility.convertISOToDate(packageDate.eventDate.endTime)
  //             );
  //             if (packageDate && packageDate.items && packageDate.items.length > 0) {
  //               const UPDATED_ITEMS = packageDate.items.filter(x => x.item.isNeedToBeUpdate && x.item.qty > 0);
  //               if (UPDATED_ITEMS && UPDATED_ITEMS.length > 0) {
  //                 UPDATED_ITEMS.forEach(d => {
  //                   let packageID = d.packageID.toString();
  //                   let bookingPackage: BookingPackages;
  //                   const item = d.item;
  //                   const selectedPackage = _.cloneDeep(session.packages.find(x => x.id == packageID));
  //                   if (selectedPackage) {
  //                     if (PBReducer.booking.bookingPackages) {
  //                       // * if it have linked package
  //                       if (
  //                         selectedPackage &&
  //                         selectedPackage.isHaveLinkPackage &&
  //                         selectedPackage.linkPackages &&
  //                         selectedPackage.linkPackages.length > 0
  //                       ) {
  //                         const select = selectedPackage.linkPackages.filter(
  //                           (x) =>
  //                             !!PBReducer.booking.bookingPackages.find(
  //                               (p) => p.package.id === x.id
  //                             ) && x.quantity > 0
  //                         );
  //                         if (select.length > 0) {
  //                           const val = select.sort(
  //                             (a, b) =>
  //                               parseInt(a.linkedPackageType.id, 10) -
  //                               parseInt(b.linkedPackageType.id, 10)
  //                           );
  //                           packageID = val[0].id;
  //                         }
  //                       }
  //                       // find a booking package for Up sell
  //                       const previousBookingPackage =
  //                         PBReducer.booking.bookingPackages.find(
  //                           (bp) => {
  //                             const s =  Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.startDate)
  //                             ) ;
  //                             const e =  Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.endDate)
  //                             )
  //                             return bp.package.id === packageID.toString() &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.startDate)
  //                             ) === sessionStart &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.endDate)
  //                             ) === sessionEnd
  //                           }

  //                         );
  //                       if (previousBookingPackage) {
  //                         bookingPackage = new BookingPackages();
  //                         bookingPackage.id = previousBookingPackage.id;
  //                         bookingPackage.startDate = previousBookingPackage.startDate;
  //                         bookingPackage.endDate = previousBookingPackage.endDate;
  //                         bookingPackage.package = new Package();
  //                         bookingPackage.package.id = packageID;
  //                         bookingPackage.bookingItems = previousBookingPackage.bookingItems
  //                           .filter((i) => i !== null && i.upsell && i.item)
  //                           .map((i) => {
  //                             const bookingItem = new BookingItems();
  //                             bookingItem.id = i.id;
  //                             bookingItem.type = i.type;
  //                             bookingItem.item.id = i.item.id;
  //                             bookingItem.item.type = i.item.type;
  //                             bookingItem.quantity = i.quantity;
  //                             bookingItem.startTime = Utility.convertToISO(
  //                               Utility.convertISOToDate(i.startTime)
  //                             );
  //                             bookingItem.endTime = Utility.convertToISO(
  //                               Utility.convertISOToDate(i.endTime)
  //                             );;
  //                             return bookingItem;
  //                           });
  //                         console.log("bookingPackage", bookingPackage);
  //                         if (newBooking.bookingPackages === undefined || newBooking.bookingPackages.length == 0) {
  //                           newBooking.bookingPackages = [bookingPackage];
  //                         } else {
  //                           const bpIndex = newBooking.bookingPackages.findIndex(
  //                             (bp) =>
  //                               bp.package.id === packageID &&
  //                               Utility.convertToISO(
  //                                 Utility.convertISOToDate(bp.startDate)
  //                               ) === sessionStart &&
  //                               Utility.convertToISO(
  //                                 Utility.convertISOToDate(bp.endDate)
  //                               ) === sessionEnd
  //                           );
  //                           if (bpIndex !== -1) {
  //                             if (!newBooking.bookingPackages[bpIndex].bookingItems) {
  //                               //   let bookingItemIndex = newBooking.bookingPackages[bpIndex].bookingItems.findIndex(
  //                               //     (i) => i.item.id === item.id &&
  //                               //       Utility.convertToISO(
  //                               //         Utility.convertISOToDate(i.startTime)
  //                               //       ) === itemSessionStart &&
  //                               //       Utility.convertToISO(
  //                               //         Utility.convertISOToDate(i.endTime)
  //                               //       ) === itemSessionEnd
  //                               //   );
  //                               //   if (bookingItemIndex == -1) {
  //                               //     newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
  //                               //   }
  //                               // } else {
  //                               newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
  //                             }
  //                           } else {
  //                             newBooking.bookingPackages.push(bookingPackage);
  //                           }
  //                         }
  //                         bookingPackage = newBooking.bookingPackages.find(
  //                           (bp) =>
  //                             bp.package.id === packageID &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.startDate)
  //                             ) === sessionStart &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.endDate)
  //                             ) === sessionEnd
  //                         );
  //                       }
  //                       if (bookingPackage) {
  //                         bookingPackage.bookingItems =
  //                           bookingPackage.bookingItems === undefined
  //                             ? []
  //                             : bookingPackage.bookingItems;
  //                         let bookingItem = bookingPackage.bookingItems.find(
  //                           (i) => i.item.id === item.id &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(i.startTime)
  //                             ) === itemSessionStart &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(i.endTime)
  //                             ) === itemSessionEnd
  //                         );
  //                         // bookingPackage.bookingItems = []; // Temp by Thuveepan
  //                         if (bookingItem === undefined) {
  //                           bookingItem = new BookingItems();
  //                           bookingItem.quantity = 0;
  //                           let biCount = 0;
  //                           newBooking.bookingPackages.forEach(
  //                             (b) =>
  //                               !!b.bookingItems && (biCount += b.bookingItems.length)
  //                           );
  //                           bookingItem.id = ((biCount + 1) * -1).toString();
  //                         }
  //                         var tp =
  //                           this.getItemPrefix(item.type.toLowerCase()) + item.type;
  //                         if (item.type.toLowerCase() == "assetitem") {
  //                           tp = "PublicBookingGeneralAdmissionItem";
  //                         }
  //                         bookingItemTypeNames.push(tp);
  //                         bookingItem.type = tp;
  //                         bookingItem.quantity = item.qty;
  //                         bookingItem.name = item.name;
  //                         bookingItem.upsell = true;
  //                         bookingItem.item.id = item.id;
  //                         bookingItem.item.type = item.type;
  //                         bookingItem.startTime = itemSessionStart;
  //                         bookingItem.endTime = itemSessionEnd;

  //                         const itemIndex = bookingPackage.bookingItems.findIndex(
  //                           (it) => it.id === bookingItem.id
  //                         );
  //                         if (itemIndex !== -1) {
  //                           bookingPackage.bookingItems[itemIndex] =
  //                             _.cloneDeep(bookingItem);
  //                         } else {
  //                           bookingPackage.bookingItems.push(
  //                             _.cloneDeep(bookingItem)
  //                           );
  //                         }
  //                         const ind = newBooking.bookingPackages.findIndex(
  //                           (b) => b.id === bookingPackage.id
  //                         );
  //                         if (ind !== -1) {
  //                           newBooking.bookingPackages[ind] = bookingPackage;
  //                         } else {
  //                           newBooking.bookingPackages.push(bookingPackage);
  //                         }
  //                         // });
  //                       } else {
  //                         this.openSnackBarError([
  //                           "Booking Package is not Available for this package: " + selectedPackage.id,
  //                         ]);
  //                       }
  //                     }
  //                   } else {
  //                     console.error(`Package not selected for Individual day upsell: ${item.name} Date: ${packageDate.eventDate.startTime}`);
  //                   }
  //                 })
  //               }
  //             }
  //           })
  //         }
  //       }
  //     });
  //   }
  //   if (
  //     !!PBReducer.selectedClient &&
  //     !!PBReducer.selectedClient.contactId
  //   ) {
  //     newBooking.contact = new BookingContact();
  //     newBooking.contact.id = PBReducer.selectedClient.contactId;
  //   } else if (
  //     cart.contact.id &&
  //     cart.contact.id !== PBReducer.booking.contact.id &&
  //     PBReducer.booking.contact.id === (
  //       environment.PublicBookingSetting as PublicBookingSettings
  //     ).defaultClient
  //   ) {
  //     newBooking.contact = new BookingContact();
  //     newBooking.contact.id = cart.contact.id;
  //   }
  //   if (!!newBooking.bookingPackages && newBooking.bookingPackages.length == 0)
  //     newBooking.bookingPackages = undefined;
  //   else {
  //     let removeIDs = [];
  //     newBooking.bookingPackages.forEach((bp) => {
  //       const id = parseInt(bp.id, 10);
  //       if (id > 0) {
  //         bp.startDate = undefined;
  //         bp.endDate = undefined;
  //       }
  //       if (id < 0 && +bp.quantity === 0) {
  //         removeIDs.push(bp.id);
  //       }
  //     });
  //     removeIDs.forEach((id) => {
  //       let index = newBooking.bookingPackages.findIndex((x) => x.id === id);
  //       if (index !== -1) {
  //         newBooking.bookingPackages.splice(index, 1);
  //       }
  //     });
  //   }

  //   return { newBooking, bookingItemTypeNames };

  private setBookingAfterPatchPreview(booking: Booking, bPackages) {
    let bookingPackages = [];
    booking?.bookingPackages.forEach(bpack => {
      if (+bpack.id < 0) {
        bPackages.push(bpack);
      } else if (+bpack.id > 0) {
        let packIndex = bPackages.findIndex(bp => bp.id == bpack.id);
        if (packIndex != -1) {
          if (bPackages[packIndex].attendees != bpack.attendees || bPackages[packIndex].totalAmount != bpack.totalAmount) {
            bPackages[packIndex] = bpack;
          }
        }
      }
    })
    this._publicBookingSelectService.setValue(bPackages);

    this.store.dispatch(new ResetAllPackageQuantity());
    this.store.dispatch(new ResetSelectedPackageStatus());
    this.store.dispatch(new SetBooking(booking));
  }

  // private generateBookingPackage(
  //   pk: any,
  //   session: IDateAndSession,
  //   bookingPackages: any[],
  //   bookedPackage = []
  // ) {
  //   const bkpackages = new BookingPackages();
  //   bkpackages.bookingItems = undefined;
  //   bkpackages.attendees = pk.quantity;
  //   bkpackages.quantity = pk.quantity.toString();
  //   bkpackages.startDate = Utility.convertToISO(
  //     Utility.convertISOToDate(session.startTime)
  //   );
  //   bkpackages.endDate = Utility.convertToISO(
  //     Utility.convertISOToDate(session.endTime)
  //   );
  //   bkpackages.package = new Package();
  //   bkpackages.package.id = pk.id;
  //   if (bookedPackage.length > 0) {
  //     for (const apk of bookedPackage) {
  //       const st = Utility.convertToISO(
  //         Utility.convertISOToDate(apk.startDate)
  //       );
  //       const et = Utility.convertToISO(Utility.convertISOToDate(apk.endDate));
  //       if (bkpackages.startDate === st && bkpackages.endDate === et) {
  //         bkpackages.id = apk.id;
  //         // bkpackages.attendees = +bkpackages.attendees + +apk.attendees;
  //         // bkpackages.quantity = (
  //         //   +bkpackages.quantity + +apk.quantity
  //         // ).toString();
  //         break;
  //       } else {
  //         bkpackages.id = ((bookingPackages.length + 1) * -1).toString();
  //         continue;
  //       }
  //     }
  //   } else {
  //     bkpackages.id = ((bookingPackages.length + 1) * -1).toString();
  //   }
  //   bookingPackages.push(bkpackages);
  //   return bookingPackages;

  // }
  private bookingError(errors: any[]) {
    if (errors.length > 0) {
      let e: string[] = [];
      console.table(errors);
      errors.forEach((ex) => {
        e.push(ex.detail);
      });
      console.table("booking Errors", e);
      this.openSnackBarError(e);
    }
  }

  private openSnackBarError(message) {
    const error = new ErrorLog();
    error.message = message.toString();
    error.url = "";
    // this.errorService
    //   .insertError(error)
    //   .subscribe((mess) => console.log('Error Ers ', mess));
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: "top",
    });
  }
}
