import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ILayout } from "../../models/interfaces";
import { Theme } from "../../models/globalTheme";
import { RowLayout } from "../../models/rowLayout.model";
import * as d3 from "d3";
import { EmptyColumn } from "../../models/gridLayout.model";
import { LAYOUT_TYPE } from "../../models/enums";
import { ThemeService } from "src/modules/services/public-web/theme.service";
import { NameLayoutComponent } from "../name-layout/name-layout.component";
import { NameLayout } from "../../models/nameLayout.model";
import { ImageLayoutComponent } from "../image-layout/image-layout.component";
import { ImageLayout } from "../../models/image.model";
import { Button } from "protractor";
import { ButtonLayoutComponent } from "../button-layout/button-layout.component";
import { DescriptionLayoutComponent } from "../description-layout/description-layout.component";
import { DescriptionLayout } from "../../models/descriptionLayout.model";
import { ButtonLayout } from "../../models/buttonLayout.model";
import { HTMLLayout } from "../../models/htmlLayout.model";
import { HtmlLayoutComponent } from "../html-layout/html-layout.component";
import { LayoutObject } from "../../models/layoutObject.model";
import { EmptyLayoutComponent } from "../empty-layout/empty-layout.component";
import { SearchBoxLayout } from "../../models/searchboxLayout.model";
import { CardListLayout } from "../../models/cardListLayout.model";
import { SearchBoxLayoutComponent } from "../searchbox-layout/search-box-layout.component";
import { CardListLayoutComponent } from "../card-list-layout/card-list-layout.component";
import { InputLayout, PackageSelectionAreaLayout } from "../../models/main";
import { InputLayoutComponent } from "../input-layout/input-layout.component";
import { PackageSelectionAreaLayoutComponent } from "../package-selection-area-layout/package-selection-area-layout.component";
import { FilterLayoutComponent } from "../filter-layout/filter-layout.component";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "opt-row-layout",
  templateUrl: "./row-layout.component.html",
  styleUrls: ["./row-layout.component.scss"],
})
export class RowLayoutComponent implements OnInit, ILayout, AfterViewInit {
  @ViewChild("colTemplateRef", { read: ViewContainerRef })
  VCR: ViewContainerRef;

  @Input() layoutAttribute: RowLayout;
  @Input() isView: boolean;
  @Input() elementID;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() isGrid = true;

  parentRef: any;
  showEditOption: boolean;
  theme: Theme;
  colID = 0;
  childCol = 12;
  selectedLayout;
  selectedType: LAYOUT_TYPE;

  constructor(
    private CFR: ComponentFactoryResolver,
    private themeService: ThemeService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.themeService.theme$.subscribe((theme) => {
      this.theme = theme;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeAttributes();
    });

    this.layoutAttribute.rowChildLayouts.forEach((c) => {
      this.onCreateChildComponentOnLoad(c);
    });
    if (this.isGrid) {
      const contentElement = document.getElementById(this.elementID);

      if (this.layoutAttribute.componentClass.includes('grid')) {
        let componentClass = this.layoutAttribute.componentClass.split(',');
        d3.select(contentElement.parentElement).attr("class", `${componentClass[0].replace('grid', '')}`);
        // Temp fix for Bug 149528
        if (this.elementID.trim() === 'view_rowLayout_l_107e2bee-c8c0-4333-ac96-6c23a2ea79ac' || this.elementID.trim() === 'view_rowLayout_l_1f55555c-4c12-47ee-a7fb-7dfa63f44d40') {
          d3.select(contentElement).attr("id", `${this.elementID}rendered`);
        }
        if (componentClass.length > 1) {
          this.layoutAttribute.componentClass = componentClass[1];
        } else {
          this.layoutAttribute.componentClass = '';
        }
        this.cd.detectChanges();
      } else {
        d3.select(contentElement.parentElement).attr(
          'class',
          `col-md-${this.layoutAttribute.gridColumn}
                    col-lg-${this.layoutAttribute.gridColumn}
                    col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
        );
        this.cd.detectChanges();
      }
    }
  }

  onCreateEmptyColumn(e: EmptyColumn) {
    const contentElement = document.getElementById(this.elementID);
    const rowSelector = d3.select(contentElement).select(".row");
    rowSelector
      .append("div")
      .attr("id", e.componentKey)
      .data([e.gridColumn])
      .attr("class", `col-md-${e.gridColumn} col-lg-${e.gridColumn} empty-col`);
  }

  onCreateChildComponentOnLoad(c) {
    if (this.VCR) {
      const componentFactory = this.CFR.resolveComponentFactory(
        this.getComponentInstanceForCreate(c)
      );
      const childComponentRef = this.VCR.createComponent(componentFactory);
      const childComponent: any = childComponentRef.instance;
      childComponent.unique_key = c.componentKey;
      childComponent.isView = this.isView;
      childComponent.gridColumn = c.gridColumn;
      childComponent.isGrid = c.isGrid;
      childComponent.type = this.selectedType;
      childComponent.layoutAttribute = c;
      childComponent.elementID =
        "view_" + childComponent.type + "_" + childComponent.unique_key;
    }
  }

  getComponentInstanceForCreate(layout): any {
    switch (true) {
      case layout instanceof NameLayout:
        this.selectedType = LAYOUT_TYPE.NAME;
        return NameLayoutComponent;
      case layout instanceof ImageLayout:
        this.selectedType = LAYOUT_TYPE.IMAGE;
        return ImageLayoutComponent;
      case layout instanceof DescriptionLayout:
        this.selectedType = LAYOUT_TYPE.DESCRIPTION;
        return DescriptionLayoutComponent;
      case layout instanceof ButtonLayout:
        this.selectedType = LAYOUT_TYPE.BUTTON;
        return ButtonLayoutComponent;
      case layout instanceof HTMLLayout:
        this.selectedType = LAYOUT_TYPE.HTML;
        return HtmlLayoutComponent;
      case layout instanceof EmptyColumn:
        this.selectedType = LAYOUT_TYPE.EMPTY;
        return EmptyLayoutComponent;
      case layout instanceof RowLayout:
        this.selectedType = LAYOUT_TYPE.ROW;
        return RowLayoutComponent;
      case layout instanceof SearchBoxLayout:
        this.selectedType = LAYOUT_TYPE.SEARCHBOX;
        // return SearchboxLayoutComponent;
        //Temp Change for ui
        return FilterLayoutComponent
      case layout instanceof CardListLayout:
        this.selectedType = LAYOUT_TYPE.CARD_LIST;
        return CardListLayoutComponent;
      case layout instanceof InputLayout:
        this.selectedType = LAYOUT_TYPE.INPUT;
        return InputLayoutComponent;
      case layout instanceof PackageSelectionAreaLayout:
        this.selectedType = LAYOUT_TYPE.PACKAGE_SELECTION_AREA_LAYOUT;
        return PackageSelectionAreaLayoutComponent;
      default:
        break;
    }
  }

  arrangeEmptyColumns(data: any[]) {
    data.forEach((e) => {
      const contentElement = document.getElementById(this.elementID);
      const rowSelector = d3.select(contentElement).select(".row");
      const rowElement = contentElement.querySelector(".row");
      if (e.isEmptyLayout) {
        const emptyNode = rowElement.querySelector("#" + e.componentKey);
        const afID = e.afterElementID;
        const afterElement = rowElement.querySelector("#" + afID).parentElement;
        rowElement.insertBefore(emptyNode as any, afterElement as any);
      }
    });
  }

  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      // d3.select(contentElement)
      //   .style('margin', this.theme.global.margin ? this.theme.global.margin + 'px' : '0');
    }
  }
}
