import { Injectable } from "@angular/core";
import { BaseService } from "../base/base.service";
import { catchError, map } from "rxjs/operators";
import { PublicEventTimeslot } from "src/modules/models/public-web/PublicEventTimeSlot";
import { SearchBaseFilter, SearchFilterInclude } from "src/modules/models/public-web/filter";
import { ErrorLog } from "src/modules/models/public-web/error";
import { AlertMessageComponent } from "src/modules/shared/alert-message/alert-message.component";
import { EMPTY } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

interface filter {
  eventID;
}

@Injectable({
  providedIn: "root",
})
export class PublicEventService {

  constructor(private _baseService: BaseService, public snackBar: MatSnackBar) {
  }

  getEventTimeslot(filter: filter) {
    let url = "api/public/event-timeslot?";
    url += filter.eventID ? `&filters.EventID=${filter.eventID}` : "";
    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((d: any) => {
        return d.body.data.map((d) => {
          return new PublicEventTimeslot().inilialize(d);
        });
      })
    );
  }

  searchEvent(filter: SearchBaseFilter) {
    let url = "api/public/events?";
    url = filter.addFilter(url, 'filters');
    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((d: any) => {
        if (d.body.data && d.body.data.length > 0) {
          return d.body.data.map((d) => {
            return d
          });
        } else {
          return []
        }
      })
    );
  }

  getEventName(filter: SearchBaseFilter) {
    let url = "api/public/events?";
    url += filter.eventId ? `&filters.EventID=${filter.eventId}` : "";
    url += filter.eventData ? `&filters.EventData=${filter.eventData}` : "";
    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((d: any) => {
        return d.body.data
      })
    );
  }

  getEventGroup(filter?: SearchBaseFilter) {
    let url = `api/public/event-groups?`;
    if (filter)
      url = filter.addFilter(url, 'filters');
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data.map((d) => ({ id: d.id, name: d.name }))
    }));
  }

  selectEvent(id, onlyName = false) {
    let url = "api/public/events/" + id + "?onlyName=" + onlyName;
    return this._baseService.Internal_WebAPI_GET(url).pipe(
      map((d: any) => {
          return d.body.data
        },
        catchError(error => {
          console.log(error);
          this.openSnackBarError(error)
          return EMPTY;
        })
      )
    );
  }

  getEventByPriceRange(filter?: SearchBaseFilter) {
    let url = `api/public/package-price-range?`;
    if (filter)
      url = filter.addFilter(url, 'filters');
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body;
    }));
  }

  getAgeGroup(filter?: SearchBaseFilter) {
    let url = `api/public/age-groups?`;
    if (filter)
      url = filter.addFilter(url, 'filters');
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body;
    }));
  }

  getEventSalesCategory(filter?: SearchBaseFilter) {
    let url = `api/public/sales-category?`;
    if (filter)
      url = filter.addFilter(url, 'filters');
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data.map((d) => ({ id: d.id, name: d.name }))
    }));
  }

  getBusinessArea(filter?: SearchBaseFilter) {
    let url = `api/public/businessAreas?`;
    if (filter)
      url = filter.addFilter(url, 'filters');
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data
    }));
  }

  getHasTicketing(id) {
    let url = `api/public/hasTicketing/${id}`;
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data
    }));
  }

  getFilterOption(filter: SearchBaseFilter, includes: SearchFilterInclude) {
    let url = "api/public/filter-options?";
    if (filter) {
      url = filter.addFilter(url, 'searchFilter');
    }
    if (includes) {
      url = includes.addFilter(url, 'Includes');
    }
    url+="&disableSpinner"
    return this._baseService.Internal_WebAPI_GET(url).pipe(map((data: any) => {
      return data.body.data
    }));
  }

  private openSnackBarError(message) {
    const error = new ErrorLog();
    error.message = message.toString();
    error.url = '';
    // this.errorService
    //   .insertError(error)
    //   .subscribe((mess) => console.log('Error Ers ', mess));
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: 'top',
    });
  }

}
