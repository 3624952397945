<div class="flex-container">
    <div class="header-container">
        <div class="header-text-container">
            <span class="heading">{{data.heading}}</span>
        </div>
        <div class="close-btn">
            <mat-icon (click)="cancelClick()">close</mat-icon>
        </div>
    </div>
    <div class="body-container">
        <mat-form-field>
            <textarea matInput [(ngModel)]="specialNote"></textarea>
        </mat-form-field>
    </div>
    <div class="footer-container">
        <button type="submit" class="cancel-btn" (click)="cancelClick()">{{data.noText}}</button>
        <button type="submit" class="save-btn" (click)="saveRequest()">{{data.yesText}}</button>
    </div>
</div>