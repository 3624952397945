import { Paging } from "../base-param";

export class SearchBaseFilter {
  searchText: string = "";
  eventName: string = "";
  businessArea: string = "";
  eventGroup: string = "";
  venue: string = "";
  date: string;
  fromDate: string = "";
  toDate: string = "";
  sessionFromDate: string;
  sessionToDate: string;
  paging: Paging = new Paging();
  itemName: string = "";
  clientID: string = "";
  clientCategoryId: string = "";
  packageIDs: string[] = [];
  eventCategoryID: string;
  isAddon: Boolean;
  addOnSearch: string = "";
  venueType: string[] = [];
  days: number;
  parkSearch: string = "";
  access: Boolean;
  accommodation: Boolean;
  availability: number;
  eventData: boolean;
  eventId: number;
  minPrice: string = "";
  activeStatus: string = "";
  maxPrice: string = "";
  ageGroupId: string[] = [];
  eventSalesCategory: string = "";
  hasSeating: boolean;
  publicEventTimeSlotId: number;
  salesChannelId: number;
  timeSlotIds: string[] = [];
  packageDurationRangeId: number;
  addFilter(url, filterS = "searchFilter") {
    url +=
      this.publicEventTimeSlotId !== undefined
        ? `&${filterS}.publicEventTimeSlotId=${this.publicEventTimeSlotId}`
        : "";
    url +=
      this.hasSeating !== undefined
        ? `&${filterS}.hasSeating=${this.hasSeating}`
        : "";
    url +=
      this.venue && this.venue.length > 0
        ? `&${filterS}.Venue=${this.venue}`
        : "";
    url +=
      this.venueType && this.venueType.length > 0
        ? `&${filterS}.VenueTypeIDs=` +
        this.venueType.join(`&${filterS}.VenueTypeIDs=`)
        : "";
    url +=
      this.availability && this.availability > 0
        ? `&${filterS}.Available=${this.availability}`
        : "";
    url +=
      this.eventName && this.eventName.length > 0
        ? `&${filterS}.EventName=${this.eventName}`
        : "";

    url +=
      this.businessArea && this.businessArea.length > 0
        ? `&${filterS}.BusinessArea=${this.businessArea}`
        : "";
    url +=
      this.fromDate && this.fromDate.length > 0
        ? `&${filterS}.EventDateFrom=${this.fromDate}`
        : "";
    url +=
      this.activeStatus && this.activeStatus.length > 0
        ? `&${filterS}.ActiveStatus=${this.activeStatus}`
        : "";
    url +=
      this.toDate && this.toDate.length > 0
        ? `&${filterS}.EventDateTo=${this.toDate}`
        : "";
    url +=
      this.sessionFromDate && this.sessionFromDate.length > 0
        ? `&${filterS}.SessionFromDate=${this.sessionFromDate}`
        : "";
    url +=
      this.sessionToDate && this.sessionToDate.length > 0
        ? `&${filterS}.SessionToDate=${this.sessionToDate}`
        : "";
    url +=
      this.eventCategoryID && this.eventCategoryID.length > 0
        ? `&${filterS}.eventCategoryID=${this.eventCategoryID}`
        : "";
    url +=
        this.clientID && this.clientID.length > 0
          ? `&${filterS}.ClientId=${this.clientID}`
          : "";
    url +=
      this.clientCategoryId && this.clientCategoryId.length > 0
        ? `&${filterS}.ClientCategoryId=${this.clientCategoryId}`
        : "";
    url +=
      this.packageIDs.length > 0
        ? `&${filterS}.PackageIDs=` +
        //this.packageIDs.join(`&${filterS}.PackageIDs=`)
        this.packageIDs.join(`,`)
        : "";
    url +=
      this.eventGroup && this.eventGroup.length > 0
        ? `&${filterS}.eventGroup=${this.eventGroup}`
        : "";
    url +=
      this.minPrice && this.minPrice.length > 0
        ? `&${filterS}.MinPrice=${this.minPrice}`
        : "";
    url +=
      this.maxPrice && this.maxPrice.length > 0
        ? `&${filterS}.MaxPrice=${this.maxPrice}`
        : "";
    url +=
      this.eventSalesCategory && this.eventSalesCategory.length > 0
        ? `&${filterS}.eventSalesCategory=${this.eventSalesCategory}`
        : "";
    url +=
      this.ageGroupId && this.ageGroupId.length > 0
        ? `&${filterS}.ageGroupID=` +
        this.ageGroupId.join(`&${filterS}.ageGroupID=`)
        : "";
    url +=
      this.salesChannelId !== undefined
        ? `&${filterS}.SalesChannelId=${this.salesChannelId}`
        : "";
    url +=
      this.timeSlotIds.length > 0
        ? `&${filterS}.timeSlotIds=` +
        this.timeSlotIds.join(`,`)
        : "";
    url +=
      this.packageDurationRangeId !== undefined
        ? `&${filterS}.PackageDurationRangeId=${this.packageDurationRangeId}`
        : "";
    return url;
  }
}

export class SearchFilterInclude{
  Venues: boolean;
  VenueTypes: boolean;
  BusinessAreas: boolean;
  AgeGroups: boolean;
  EventGroups: boolean;
  SalesCategories: boolean;
  PriceRange: boolean;


  addFilter(url, Includes = "Includes") {
    url +=
    this.Venues !== undefined
      ? `&${Includes}.Venues=true`
      : "";
    url +=
    this.VenueTypes !== undefined
      ? `&${Includes}.VenueTypes=true`
      : "";
    url +=
    this.BusinessAreas !== undefined
      ? `&${Includes}.BusinessAreas=true`
      : "";
    url +=
    this.AgeGroups !== undefined
      ? `&${Includes}.AgeGroups=true`
      : "";
    url +=
    this.EventGroups !== undefined
      ? `&${Includes}.EventGroups=true`
      : "";
    url +=
    this.SalesCategories !== undefined
      ? `&${Includes}.SalesCategories=true`
      : "";
    url +=
    this.PriceRange !== undefined
      ? `&${Includes}.PriceRange=true`
      : "";
    return url;
  }
}

export class BaseParam {
  categoryID: number;
  eventID: number;
  packageID: number;
}
