<div class="mmc-modal-wrapper package-duration-modal">
  <!-- IF ITS PACKAGE DESCRIPTION START -->
  <div *ngIf="!isUpsell">
    <div class="mmc-modal-header mmc_flex--container" style="height:45px;background:#00507c;">
      <div class="mmc_flex__item mmc_flex__item--vcenter pl-3" style="color: white;font-size:20px;">
        <span class="section-title" style="color: white;font-size:20px;">{{'Package' | optimotranslate}} : {{publicPackage.name}}</span>
      </div>
      <div class="close-btn" style="align-items:center;display:flex;padding-right: 13px;justify-content: center;">
        <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;justify-content: flex-end;"
          (click)="dialogRef.close()">close</mat-icon>
      </div>
    </div>

    <div class="mmc-modal-body">
      <!-- sample image -->

      <div class="image text-center" style="justify-content: center; padding-top: 10px;">
        <img [src]="bookingPackage.image" alt="">
      </div>
      <div class="description">
        <p style="text-align: justify;margin-top: 5px;margin-left:20px; margin-right:20px;font-size: 18px !important;" id="description"></p>
      </div>
    </div>
  </div>



  <!-- IF ITS PACKAGE DESCRIPTION END -->

  <!-- IF ITS UPSELL DESCRIPTION START -->
  <div *ngIf="isUpsell">
    <div class="mmc-modal-header mmc_flex--container" style="height:45px;background:#00507c;">
      <div class="mmc_flex__item mmc_flex__item--vcenter pl-3" style="color: white;font-size:20px;">
        <span class="section-title" style="color: white;font-size:20px;">{{'Add-On' | optimotranslate}} : {{upSell.name}}</span>      
      </div>
      <div class="close-btn" style="align-items:center;display:flex;padding-right: 13px;justify-content: center;">
        <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;justify-content: flex-end;"(click)="dialogRef.close()">close</mat-icon>
      </div>
    </div>
    <div class="mmc-modal-body">
     <!-- sample image -->
      <div class="image" style="justify-content: center;">
        <img style="width:100%; max-width: 100% ;" [src]="upSell.item.webShortDescription" [alt]="upSell.name">
      </div>
      <div class="description">
        <p style="text-align: justify;margin-top: 5px;margin-left:20px; margin-right:20px;font-size: 18px !important;" id="upSelldescription"></p>
      </div>
    </div>
  </div>
  <!-- IF ITS UPSELL DESCRIPTION END -->
  <div class="mmc-modal-footer p-3" style="padding:10px 15px 10px 15px !important">
    <div class="button-row">
      <div class="row m-0">
        <div class="col-sm-12" style="padding-right:0px !important;">
          <div class="mmc_flex--container mmc_just-content-end ">
            <div class="mmc_flex__item--inherit">
              <button mat-button class="mmc-btn-primary btn btn-primary primaryBtn mr-2"
                style="margin-right:1px !important;" (click)="dialogRef.close()">
                {{"Close" | optimotranslate}} 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--<div class="mmc-modal-wrapper package-duration-modal" >
  <// IF ITS PACKAGE DESCRIPTION START >
  <div *ngIf="!isUpsell">
    <div class="mmc-modal-header mmc_flex--container" style="height:45px;background:#00507c;">
      <div class="mmc_flex__item mmc_flex__item--vcenter pl-3" style="color: white;font-size:20px;">
        <span class="section-title" style="color: white;font-size:20px;">Package : {{publicPackage.name}}</span>      
      </div>
      <div class="package-close-btn close-btn" style="align-items:center;display:flex;padding-right: 13px;justify-content: center;">
        <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;justify-content: flex-end;"(click)="dialogRef.close()">close</mat-icon>
      </div>
    </div>
    <div class="mmc-modal-body p-3">
     <// sample image >
      <div class="image text-center" style="padding-bottom: 15px;display: flex;justify-content: center;">
        <img  [src]="bookingPackage.image" alt="">
      </div>
      <div class="description">
        <p style="text-align: justify;">{{description}}</p>
      </div>
    </div>
  </div>
  <// IF ITS PACKAGE DESCRIPTION END >

<// IF ITS UPSELL DESCRIPTION START >
  <div *ngIf="isUpsell">
    <div class="mmc-modal-header mmc_flex--container" style="height:45px;background:#00507c;">
      <div class="mmc_flex__item mmc_flex__item--vcenter pl-3" style="color: white;font-size:20px;">
        <span class="section-title" style="color: white;font-size:20px;">Add-On : {{upSell.name}}</span>      
      </div>
      <div class="close-btn package-close-btn" style="align-items:center;display:flex;padding-right: 13px;justify-content: center;">
        <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;justify-content: flex-end;"(click)="dialogRef.close()">close</mat-icon>
      </div>
    </div>
    <div class="mmc-modal-body p-3">
     <// sample image >
      <div class="image" style="padding-bottom: 15px;display: flex;height: 300px;justify-content: center;">
        <img style="width: 300px;" [src]="upSell.item.webShortDescription" [alt]="upSell.name">
      </div>
      <div class="description">
        <p style="text-align: justify;">{{upSell.item.webLongDescription}}</p>
      </div>
    </div>
  </div>
  <// IF ITS UPSELL DESCRIPTION END >
  <div class="mmc-modal-footer p-3" style="padding:10px 15px 10px 15px !important">
    <div class="button-row">
      <div class="row m-0">
        <div class="col-sm-12" style="padding-right:0px !important;">
          <div class="mmc_flex--container mmc_just-content-end ">
            <div class="mmc_flex__item--inherit">
              <button mat-button class="mmc-btn-primary btn btn-primary primaryBtn mr-2 package-close" style="margin-right:1px !important;" (click)="dialogRef.close()">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->