import {
  ComponentFactoryResolver,
  Injectable,
  ViewContainerRef,
} from "@angular/core";
import {
  LAYOUT_TYPE,
  PAGE_CONFIGURATION,
} from "../../public-web/components/layout/models/enums";
import { HeaderLayoutComponent } from "../../public-web/components/layout/components/header-layout/header-layout.component";
// tslint:disable-next-line: import-spacing
import { CardListLayoutComponent } from "../../public-web/components/layout/components/card-list-layout/card-list-layout.component";
import { SearchBoxLayout } from "src/modules/public-web/components/layout/models/searchboxLayout.model";
import { HeaderLayout } from "src/modules/public-web/components/layout/models/headerLayout.model";
import { CardListLayout } from "src/modules/public-web/components/layout/models/cardListLayout.model";
import { RowLayout } from "src/modules/public-web/components/layout/models/rowLayout.model";
import { RowLayoutComponent } from "src/modules/public-web/components/layout/components/row-layout/row-layout.component";
import { LayoutObject } from "src/modules/public-web/components/layout/models/layoutObject.model";
import { FooterLayout } from "src/modules/public-web/components/layout/models/footerLayout.model";
import { FooterLayoutComponent } from "src/modules/public-web/components/layout/components/footer-layout/footer-layout.component";
import { NavBarLayoutComponent } from "src/modules/public-web/components/layout/components/nav-bar-layout/nav-bar-layout.component";
import {
  NavBarLayout,
  PackageSelectionAreaLayout,
} from "src/modules/public-web/components/layout/models/main";
import { Store } from "src/modules/store";
import { PackageSelectionAreaLayoutComponent } from "src/modules/public-web/components/layout/components/package-selection-area-layout/package-selection-area-layout.component";
import { PAGE_TYPE } from "src/modules/models/public-web/enum";
import { EmptyLayoutComponent } from "src/modules/public-web/components/layout/components/empty-layout/empty-layout.component";
import { EmptyColumn } from "src/modules/public-web/components/layout/models/gridLayout.model";
import { SearchBaseFilter } from "src/modules/models/public-web/filter";
import { FilterLayoutComponent } from "src/modules/public-web/components/layout/components/filter-layout/filter-layout.component";
import { HtmlLayoutComponent } from "src/modules/public-web/components/layout/components/html-layout/html-layout.component";
import { HTMLLayout } from "src/modules/public-web/components/layout/models/htmlLayout.model";

@Injectable({
  providedIn: "root",
})
export class RenderComponentService {
  private VCR: ViewContainerRef;
  private selectedLayout: any;
  private selectedObject: LayoutObject;
  // private pageType: PAGE_TYPE;
  // private pageConfiguration: string[];
  constructor(
    private CFR: ComponentFactoryResolver,
    private store: Store<any>
  ) {
    this.VCR = undefined;
    this.store.select("PBReducer").subscribe((d) => {
      // this.pageType = d.pageType;
      // this.pageConfiguration = d.pageConfiguration;
    });
  }

  set vcrSet(vcr: ViewContainerRef) {
    this.VCR = vcr;
  }

  createLayoutForPage(pageData: any, searchBaseFilter = undefined) {
    if (this.VCR) {
      this.VCR.clear();
      pageData.forEach((layout) => {
        this.selectedLayout = undefined;
        this.selectedObject = undefined;
        this.selectComponentForCreate(layout);
        if (this.selectedObject && this.selectedLayout && this.canCreateComponent(layout)) {
          this.createComponent(searchBaseFilter);
        }
      });
    }
  }

  private createComponent(searchBaseFilter: SearchBaseFilter = undefined) {
    const componentFactory = this.CFR.resolveComponentFactory(
      this.selectedLayout
    );
    const componentRef = this.VCR.createComponent(componentFactory);
    const component: any = componentRef.instance;

    component.layoutAttribute = this.selectedObject;
    component.unique_key = this.selectedObject.componentKey;
    component.isView = true;
    component.type = this.selectedObject.layoutType;
    component.elementID = "view_" + this.selectedObject.layoutType + "_" + this.selectedObject.componentKey;
    component.canLoad = true;
    searchBaseFilter ? component.searchBaseFilter = searchBaseFilter : null;
  }

  private selectComponentForCreate(layout) {

    switch (layout.type) {
      case LAYOUT_TYPE.HEADER:
        this.selectedLayout = HeaderLayoutComponent;
        this.selectedObject = new HeaderLayout(layout);
        break;
      case LAYOUT_TYPE.SEARCHBOX:
        //this.selectedLayout = SearchboxLayoutComponent;
        //Temp Change For UI
        this.selectedLayout = FilterLayoutComponent;
        this.selectedObject = new SearchBoxLayout(layout);
        break;
      case LAYOUT_TYPE.CARD_LIST:
        this.selectedLayout = CardListLayoutComponent;
        this.selectedObject = new CardListLayout(layout);
        break;
      case LAYOUT_TYPE.FOOTER:
        this.selectedLayout = FooterLayoutComponent;
        this.selectedObject = new FooterLayout(layout);
        break;
      case LAYOUT_TYPE.ROW:
        this.selectedLayout = RowLayoutComponent;
        this.selectedObject = new RowLayout(layout);
        break;
      case LAYOUT_TYPE.NAVBAR:
        this.selectedLayout = NavBarLayoutComponent;
        this.selectedObject = new NavBarLayout(layout);
        break;
      case LAYOUT_TYPE.PACKAGE_SELECTION_AREA_LAYOUT:
        console.log(layout);
        this.selectedLayout = PackageSelectionAreaLayoutComponent;
        this.selectedObject = new PackageSelectionAreaLayout(layout);
        break;
      case LAYOUT_TYPE.HTML:
        this.selectedLayout = HtmlLayoutComponent;
        this.selectedObject = new HTMLLayout(layout);
        break;
      default:
        this.selectedLayout = EmptyLayoutComponent;
        this.selectedObject = new EmptyColumn(layout);
        break;
    }
  }
  private canCreateComponent(layout) {
    switch (layout.type) {
      case LAYOUT_TYPE.CARD_LIST:
          return true;
//         if (this.pageType === PAGE_TYPE.EVENT_LIST) {
//
//           let hasCampingEvent = this.pageConfiguration.includes(PAGE_CONFIGURATION.CAMPING_CONFIGURATION);
//           let canDisplayEventList = this.pageConfiguration.includes(PAGE_CONFIGURATION.CAN_DISPLAY_EVENT_CARD);
//
//           if (hasCampingEvent && !canDisplayEventList) {
//             const rowLayoutTemp = {
//               nextComponentKey: "",
//               pageLayoutTemplateID: "0",
//               layoutTypeID: "5",
//               parentComponentKey: "",
//               isGrid: true,
//               isHead: false,
//               isEmptyLayout: false,
//               componentKey: "l_d37f86dc-f32b-4715-93d2-fd98567cb117",
//               height: 0,
//               gridColumn: 12,
//               componentClass: "text-center",
//               childLayout: [
//                 {
//                   height: 600,
//                   imageID: "",
//                   image: {
//                     id: "",
//                     file: "https://i0.wp.com/www.ecommerce-nation.com/wp-content/uploads/2017/08/How-to-Give-Your-E-Commerce-No-Results-Page-the-Power-to-Sell.png?fit=1000%2C600&ssl=1",
//                     commonImageType: "JPEG",
//                     width: 1000,
//                     x: 0,
//                     y: 0,
//                     rotate: 0,
//                     opacity: 1,
//                     height: 600,
//                     type: "image",
//                   },
//                   pageLayoutTemplateID: "0",
//                   layoutTypeID: "7",
//                   parentComponentKey: "l_d37f86dc-f32b-4715-93d2-fd98567cb117",
//                   nextComponentKey: "l_64c95a6b-5308-4123-931a-6283d5b55e09",
//                   isGrid: false,
//                   isHead: true,
//                   isEmptyLayout: false,
//                   componentKey: "l_7348d12a-3c4f-4e14-8ff6-0cc0caed1ac2",
//                   gridColumn: 12,
//                   id: "123",
//                   type: "imageLayout",
//                 }, {
//                   height: 165,
//                   content: `<div class="html- content" style="height: 165px; width: 100%; position: absolute; top: 250px; left: 0; "><div class="typewriter" style="margin: 10px 10px 0 10px"><div class="row" style="
// width: 100%;"><div class="col-4"></div><div class="col-4"> <h4 style="user-select: none;overflow: unset;">Enter Your Search Criteria...</h4></div><div class="col-4"></div></div></div></div>`,
//                   sizeType: "Fit_to_Content",
//                   pageLayoutTemplateID: "1",
//                   layoutTypeID: "16",
//                   parentComponentKey: "l_d37f86dc-f32b-4715-93d2-fd98567cb117",
//                   isGrid: false,
//                   isHead: true,
//                   isEmptyLayout: false,
//                   componentKey: "l_ba2add65-8d26-4462-a2f8-0a22095d45df",
//                   gridColumn: 0,
//                   id: "125",
//                   type: "htmlLayout"
//                 }
//               ],
//               id: "122",
//               type: "rowLayout",
//             };
//
//             this.selectedLayout = RowLayoutComponent;
//             this.selectedObject = new RowLayout(rowLayoutTemp);
//             return true;
//           } else {
//             return true;
//           }
//         } else {
//           return true;
//         }
      default:
        return true;
    }
  }
}
