import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'opt-select-event',
  templateUrl: './select-event.component.html',
  styleUrls: ['./select-event.component.scss']
})
export class SelectEventComponent implements OnInit {
  selectedEvent: string = '';
  bookedEvents;

  constructor(public matDialogRef: MatDialogRef<SelectEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.bookedEvents = data.bookedEvents;
  }

  ngOnInit(): void {

  }

  cancelClick() {
    this.matDialogRef.close();
  }

  continue() {
    if (this.selectedEvent) {
      this.matDialogRef.close(this.selectedEvent);
    }
  }

}
