import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Event, EventDate, PublicEvent, TimeSlot } from "../models/BookingEvent";
import { Utility } from "src/modules/utility";
import { BaseService } from "src/modules/services/base/base.service";
import Jsona from "jsona";

@Injectable({
  providedIn: "root",
})
export class EventService {

  constructor(private baseService: BaseService) {
  }

  getEvent(id: string, forPublicEvents:boolean = false, IncludeVenueMapLayout:boolean = true): Observable<any> {
    let url = `api/public/events/${id}?`;
    if (IncludeVenueMapLayout) {
      url += `IncludeVenueMapLayout=true`;
    }

    return this.baseService.Internal_WebAPI_GET(url).pipe(
      map((d: any) => {
        if (!d.body.data?.isError) {
          if(forPublicEvents) {
            return new PublicEvent().initialize(d.body.data?.data);
          } else {
            return new Event().initialize(d.body.data?.data);
          }
        }
        return undefined;
      })
    );
  }

  getEventDates(eventID: string, fromDate: any = undefined, toDate: any = undefined): Observable<EventDate[]> {
    let url = '';
    if (fromDate && toDate) {
      fromDate = Utility.convertToISO(new Date(fromDate));
      toDate = Utility.convertToISO(new Date(toDate));
      url = `api/public/event/${eventID}/event-date?FromDate=${fromDate}&ToDate=${toDate}`;
    } else {
      url = `api/public/event/${eventID}/event-date`;
    }
    return this.baseService.InternalWebAPIGET(url).pipe(map((d: any) => {
        if (d.data?.data) {
          return d.data?.data.map((e) => new EventDate().initialize(e));
        }
        return undefined;
      })
    );
  }

  getEventConfigurations(eventId: string, eventDate: EventDate, allocatedFacilityIds: any[]): Observable<TimeSlot[]> {
    const date = Utility.convertToDate(Utility.convertISOToDate(eventDate.endTime));

    let url = `api/public/events/${eventId}/event-date/${eventDate.id}/event-configurations`;
    url = url + `?${allocatedFacilityIds.map((x) => `&filters.assetId=${x}`).join("")}&filters.date=${date}`;

    return this.baseService.InternalWebAPIGET(url).pipe(
      map((d: any) => {
        if (d.data?.data) {
          const start = Date.now();
          // const dataFormatter = new Jsona();
          // const resp = dataFormatter.deserialize(d.data?.data);
          const result = d.data?.data
            .filter((d) => d.configuration.configurationTypeId === 3)
            ?.map((d) => new TimeSlot().initialize(d));
          console.log('time taken for serialization: ', Date.now() - start);
          return result;
        }
        return undefined;
      })
    );
  }
}
