<div class="mmc-modal-wrapper attendee-list-modal">
  <div class="mmc_flex--container mmc-modal-header header-primary p-4">
    <div class="mmc_flex__item mmc_flex__item--vcenter">
      <span class="heading" style="color: #ffffff;">{{(isDeleteAttendee && !isSelectNewHost ? "ATTENDEEINFO.Delete":isSelectNewHost?'': "ATTENDEEINFO.Add") | optimotranslate}}
        {{(isSelectNewHost ? 'ATTENDEEINFO.Select a new host': "ATTENDEEINFO.Attendee") | optimotranslate}} {{"ATTENDEEINFO.Information" | optimotranslate}}</span>
    </div>
    <div class="close-btn" style="align-items:center;display:flex;margin-right: -8px;">
      <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;"(click)="dialogRef.close()">close</mat-icon>
    </div>
  </div>
  <div class="mmc-modal-body  px-4 py-0" style="max-height: 300px;">
    <div class="body" *ngIf="attendeeDisplayCards && attendeeDisplayCards.length > 0">
      <div *ngIf="!isDeleteAttendee">
        <div class="attendee-list" *ngFor="let attendeeDisplayCard of attendeeDisplayCards">
          <div class="opt-fontweight__bolder ">{{attendeeDisplayCard.bookingPackage.name}} - {{getPackageDate(attendeeDisplayCard.bookingPackage.startDate | date,attendeeDisplayCard.bookingPackage.endDate | date)}} </div>
          <div class="add_attendee">
            <button id="submitBtn" mat-button class="mmc-btn-primary"
              *ngIf="attendeeDisplayCard.remainingGuestsCount > 0" (click)="addNewHost(attendeeDisplayCard)">
              {{"ATTENDEEINFO.Add"| optimotranslate}} {{"ATTENDEEINFO.Host"| optimotranslate}}
            </button>
          </div>
          <div *ngIf="attendeeDisplayCard.attendeeCaptureType && attendeeDisplayCard.attendeeCaptureType === ATTENDEE_CAPTURE_TYPE.HOST_ONLY else attendeeInfo" class="attendee-list" >
            <div class="attendee" *ngFor="let g of getAttendeGuestList(attendeeDisplayCard.hosts)" (click)="onEditAttendee(g, attendeeDisplayCard.hosts, attendeeDisplayCard.remainingGuestsCount)" >
              <div class="if-has-host row" *ngIf="getAttendeeInfo(g, attendeeDisplayCard.hosts).isEdited">
                <div class="col-5 p-0">{{getTranslate(getAttendeeInfo(g, attendeeDisplayCard.hosts).firstName)}} {{getTranslate(getAttendeeInfo(g, attendeeDisplayCard.hosts).lastName)}}</div>
                <div class="col-5 p-0">{{getAttendeeInfo(g, attendeeDisplayCard.hosts).companyName}}</div>
                <div class="col p-0">{{getAttendeeInfo(g, attendeeDisplayCard.hosts).attendees}}</div>
                <div class="col p-0"><i *ngIf="g.hostGuest" class="fa fa-user"></i></div>
              </div>
              <div class="if-has-host" *ngIf="!getAttendeeInfo(g, attendeeDisplayCard.hosts).isEdited">
                <span>{{getTranslate(getAttendeeInfo(g, attendeeDisplayCard.hosts).firstName)}}</span>
                <span><i *ngIf="g.hostGuest" class="fa fa-user"></i></span>
              </div>
            </div>
          </div>
          <ng-template #attendeeInfo>
            <div class="attendee-list" >
              <!-- *ngFor="let host of getAttendeGuestList(attendeeDisplayCard.hosts)" -->
            <!-- <div *ngIf="isMultiDayHost(attendeeDisplayCard.bookingPackage.startDate | date, attendeeDisplayCard.bookingPackage.endDate | date)">{{host.eventDate | date}} </div> -->
              <div class="attendee if-has-host" *ngFor="let g of getAttendeGuestList(attendeeDisplayCard.hosts)" (click)="onEditAttendee(g, attendeeDisplayCard.hosts, attendeeDisplayCard.remainingGuestsCount)" >
                {{getTranslate(getAttendeeName(g, attendeeDisplayCard.hosts))}}
                <!-- {{g.hostGuest ? '*':''}} --><i *ngIf="g.hostGuest" class="fa fa-user"></i>
              </div>
            </div>
          </ng-template>
          <div *ngIf="attendeeDisplayCard.remainingGuestsCount > 0">
            You Have Remaining {{attendeeDisplayCard.remainingGuestsCount}} Guests.
          </div>
        </div>
      </div>
      <!-- For delete -->
      <div class="mmc_flex--container pb-2" *ngIf="isDeleteAttendee && !isSelectNewHost ">
        <div class="mmc_flex__item mmc_flex__item">
          <mat-checkbox [(ngModel)]="selectAllAttendeeForDelete" (change)="onSelectAllAttendeeForDelete()"> {{"ATTENDEEINFO.Select All" | optimotranslate}}
          </mat-checkbox>
        </div>
        <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end" *ngIf="enableDeleteButton">
          <button id="submitBtn" mat-button class="mmc-btn-primary" (click)="removeSelectedAttendee()">
            {{"ATTENDEEINFO.Delete" | optimotranslate}}
          </button>
        </div>
      </div>
      <div *ngIf="isDeleteAttendee && !isSelectNewHost ">
        <div class="attendee-list-delete" *ngFor="let attendeeDisplayCard of attendeeDisplayCards">
          <div>{{attendeeDisplayCard.bookingPackage.name}} - {{attendeeDisplayCard.bookingPackage.startDate | date}}
          </div>
          <div class="py-2 alert-m-container">
            <span>{{getPackageDeleteCount(attendeeDisplayCard)}}</span>
          </div>

          <ng-container *ngFor="let host of attendeeDisplayCard.hosts">
            <div class="attendee" *ngFor="let g of host.guests">
              <mat-checkbox [(ngModel)]="g.isSelectedForDelete"> {{getAttendeeName(g, host)}}
                <!-- {{g.hostGuest ? '*':''}} -->
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- For new Host selection -->
      <div class="mmc_flex--container pb-2" *ngIf="isSelectNewHost">
        <div class="mmc_flex__item mmc_flex__item">
          {{"ATTENDEEINFO.Select a new host" | optimotranslate}}
        </div>
        <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end" *ngIf="selectedNewHost">
          <button id="submitBtn" mat-button class="mmc-btn-primary" (click)="SelectForHost()">
            {{"ATTENDEEINFO.Select" | optimotranslate}}
          </button>
        </div>
      </div>
      <div *ngIf="isSelectNewHost">
        <div class="attendee-list-delete" *ngFor="let removedAttendee of removedAttendees">
          <div>{{removedAttendee.bookingPackage.name}} - {{removedAttendee.bookingPackage.startDate | date}}
          </div>
          <mat-radio-group [(ngModel)]="removedAttendee.selectedNewHost">
            <div class="attendee" *ngFor="let g of removedAttendee.guests">
              <mat-radio-button class="example-radio-button" [value]="g">
                {{g.firstName}}
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>
    <!-- <mat-paginator *ngIf=" !isDeleteAttendee" [length]="guestList.length" [pageSize]="pageSize"
      aria-label="Attendee select " (page)="onPaginate($event)">
    </mat-paginator> -->
  </div>
  <div class="mmc-modal-footer p-4">
    <div class="mmc_flex--container mmc_just-content-end">
      <div class="mmc_flex__item--inherit">
        <button mat-button class="mmc-btn-primary mr-2" (click)="dialogRef.close(false)">
          {{"ATTENDEEINFO.Cancel" | optimotranslate}}
        </button>
        <button id="submitBtn" mat-button class="mmc-btn-secondary" (click)="skipAttendees()" *ngIf="!isDeleteAttendee && canEdit" style="margin-right:unset; " [disabled]="remainingGuestsCount > 0">
          {{continueName()}}
        </button>
        <button id="submitBtn" mat-button class="mmc-btn-primary"
          *ngIf="isDeleteAttendee && !isSelectNewHost &&  removeDeleteSaveBTN()" (click)="onDeleteAttendeeSave()">
          {{"ATTENDEEINFO.Save"| optimotranslate}}
        </button>
        <button id="submitBtn" mat-button class="mmc-btn-primary" *ngIf="isSelectNewHost"
          (click)="switchTheGuestToHost()">
          {{"ATTENDEEINFO.Save"| optimotranslate}}
        </button>
      </div>
    </div>
  </div>
</div>
