<div class="mmc-modal-wrapper">
  <div class="mmc_flex--container mmc-modal-header header-primary p-4">
    <div class="mmc_flex__item mmc_flex__item--vcenter" >
      <span class="heading" style="color: #ffffff;font-size: 20px;font-weight: bolder;">{{title}}</span>
    </div>
    <div class="close-btn" style="align-items:center;display:flex;">
      <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;"(click)="dialogRef.close()">close</mat-icon>
    </div>
  </div>
  <div class="mmc-modal-body con-body" style="padding:25px;">
    <div class="body pk-remove-body"[innerHTML]="message"></div>
  </div>
  <div class="mmc-modal-footer">
    <div class="button-row">
      <div class="row m-0">
        <div class="col-sm-12 py-2 con-p">
          <div class="mmc_flex--container mmc_just-content-end">
            <div class="mmc_flex__item--inherit">
              <button mat-button class="mmc-btn-primary mr-2" (click)="sayNo()">
                {{"DELETEPOPUPINFO.No" | optimotranslate}}
              </button>
              <button id="submitBtn" mat-button class="mmc-btn-secondary con-yes" (click)="sayYes()" style="background:#00507C;color:#ffffff;">
                {{"DELETEPOPUPINFO.Yes" | optimotranslate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
