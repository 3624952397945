import { LAYOUT_TYPE, PUBLIC_PAGE_DATA_TYPE, BOOKING_FLOW_OPTION } from "./enums";
import { RowLayout } from "./rowLayout.model";
import { LayoutObject } from "./layoutObject.model";
import { PackageDurationRange } from "src/modules/models/public-web/Package";
import { PackageSession } from "src/modules/models/public-web/PackageSession";

export class CardLayout extends LayoutObject {
  childLayout: any[] = [];
  height: number;
  cardDataType: PUBLIC_PAGE_DATA_TYPE;
  referenceID;
  isHaveLinkPackage: boolean;
  isNotMemberPackage: boolean;
  singleDayPackage: boolean;
  availableToBook: boolean;
  durationRange: PackageDurationRange[] = [];
  clientCategories: any[];
  packageSessions:PackageSession[];
  bookingFlowOptionId : BOOKING_FLOW_OPTION;
  attendeesPerPackage: number;
  reference: string;
  eventId: string;
  constructor(data) {
    super(data);
    this.cardDataType = data.entityType;
    this.referenceID = data.entityReferenceId;
    this.isHaveLinkPackage = data.isHaveLinkPackage;
    this.clientCategories = data.clientCategories;
    this.isNotMemberPackage = data.isNotMemberPackage;
    this.singleDayPackage = data.singleDayPackage;
    if(data.packageSessions && data.packageSessions.length > 0){
    this.packageSessions = data.packageSessions;
    }else{
      this.packageSessions = [];
    }
    this.attendeesPerPackage = data.attendeesPerPackage ? data.attendeesPerPackage : 1;
    this.reference = data.reference ? data.reference : "";
    this.eventId = data.eventId ? data.eventId : "";
    if(data.packageDurationRanges && data.packageDurationRanges.length > 0) {
      this.durationRange = data.packageDurationRanges;
    }
    if (data.availableToBook == undefined) {
      this.availableToBook = true;
    } else {
      this.availableToBook = data.availableToBook;
    }
    this.bookingFlowOptionId = data.bookingFlowOptionId ? data.bookingFlowOptionId : BOOKING_FLOW_OPTION.SessionVenueMapPackageList
    this.childLayout = data.childLayout
      ? data.childLayout.map((c) => this.mapLayout(c))
      : [];
  }

  mapLayout(cardLayout) {
    switch (cardLayout.type) {
      case LAYOUT_TYPE.ROW:
        return new RowLayout(cardLayout);
      default:
        break;
    }
  }
}
