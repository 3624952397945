<div class="main-container">
    <div class="package_content">
        <div class="header-container">
            <div class="header-text-container">
                <span class="heading">{{'VENUE_MAP_LAYOUT_DETAILS.Select Package' | optimotranslate}}</span>
            </div>
            <div class="close-btn" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <div class="p-3">
            <mat-radio-group [(ngModel)]="parentRadioSelection">
                <div class="w-100" *ngFor="let package of data.packages">
                    <mat-radio-button (change)="changeCommonParentPack(package)" [style.--borderColorX]="color" [value]="package.id">
                        <span class="package-name">{{package.name}}</span>
                        <!-- <i class="material-icons" (click)=" navigateToPackageDetails(package.id)">info_outline</i> -->
                        <span *ngIf="!package.linkedPackages" class="package-price">{{ package.variablePriceIncludingTax| PBCurrencyPipe }}</span>
                        <span *ngIf="package.linkedPackages && package.id !== parentRadioSelection" class="package-price">{{package.priceRange?.min | PBCurrencyPipe}} - {{package.priceRange?.max | PBCurrencyPipe}}</span>
                    </mat-radio-button>

                    <mat-radio-group *ngIf="package.linkedPackages && package.id === parentRadioSelection"
                        [(ngModel)]="childRadioSelection" class="child_package">
                        <mat-radio-button [style.--borderColorX]="color" [value]="package.linkedPackageType.id">
                            <span class="package-name">{{package.linkedPackageType.name}}</span>
                            <span class="package-price">{{ package.variablePriceIncludingTax | PBCurrencyPipe}}</span>
                        </mat-radio-button>

                        <mat-radio-button [style.--borderColorX]="color"
                            *ngFor="let linkedPackage of package.linkedPackages"
                            [value]="linkedPackage.linkedPackageType.id">
                            <span class="package-name">{{linkedPackage.linkedPackageType.name}}</span>
                            <span class="package-price">{{ linkedPackage.variablePriceIncludingTax | PBCurrencyPipe
                                }}</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </mat-radio-group>

            <div class="package_action">
                <button class="apply_btn" (click)="applyChanges()">{{'VENUE_MAP_LAYOUT_DETAILS.Apply' | optimotranslate}}</button>
            </div>
        </div>
    </div>
</div>
