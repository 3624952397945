import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { BookingPackages } from "src/modules/models/booking/booking-packages";
import { SystemOption } from "src/modules/models/public-web/SystemOption";
import { BOOKING_CART, PAGE_TYPE } from "src/modules/models/public-web/enum";
import { AppSetting, Condition, MenuSettings } from "src/modules/models/settings/casual-portal/app-setting";
import { PBUtility } from "src/modules/public-web/pb-utility";
import { CustomerService } from "src/modules/services/customer.service";
import { PublicBookingSelectService } from "src/modules/services/public-web/public-booking-select.service";
import { BaseComponent } from "src/modules/shared/base.component";
import { OptimoTranslate } from "src/modules/shared/opt-translate/optimo-translate.pipe";
import { ICart, SignOut } from "src/modules/store";
import {
  ResetBlock,
  ResetConfigurationState,
  ResetSeat
} from "src/modules/store/booking-application/configuration.action";
import {
  ResetPublicWebState,
  ResetPackages,
  SetRedirectionPoint,
  AddBookingSession
} from "../../../../../store/public-web/public-web-actions";
import { IPBReducer, IPublicWebSession } from "../../../../../store/public-web/public-web-reducers";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';
import { selectSelectedEventCategoryId } from '../../../../../store/public-web/public-web-selectors';
import { ClientSearchModalComponent } from "../../../modals/client-search-modal/client-search-modal.component";
import {
  NavigationConfirmationComponent
} from "../../../modals/navigation-confirmation/navigation-confirmation.component";
import { BookingCart } from "../../models/bookingCart.model";
import { Theme } from "../../models/globalTheme";
import { ILayout } from "../../models/interfaces";
import { NavBarLayout } from "../../models/main";
import { PublicBookingSettings } from "src/modules/models/settings/public-booking/public-booking-setting";

@Component({
  selector: "opt-nav-bar-layout",
  templateUrl: "./nav-bar-layout.component.html",
})
export class NavBarLayoutComponent extends BaseComponent implements OnInit, ILayout, AfterViewInit {

  @Input() layoutAttribute: NavBarLayout;
  @Input() isView: boolean;
  @Input() elementID;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() isGrid = false;
  @Input() havePendingChanges: boolean;
  @Input() isClearCart: boolean;
  @Input() bookingDisplayCart: BookingCart[] = [];

  cart$: Observable<ICart>;
  publicWebSession$: Observable<IPublicWebSession>;
  publicConfig$: Observable<IPBReducer>;

  bookingSession: SystemOption;
  paymentSession: SystemOption;
  isLoggedIn: boolean;
  imgUrl: string;
  maxAmount: number = 0;
  conformationModel: string;
  hasBookingInCart: boolean = false;
  message: string;
  heading: string;
  yesText: string;
  noText: string;
  packageClassId: number;
  selectedClient: any;
  isScroll: boolean = false;
  appSetting: AppSetting;
  isHome: any = false;
  HomeBaseUrl: string;
  pageType: PAGE_TYPE;
  isPackageListPage: boolean = false;
  selectedEventCategory: string;
  isPackageListRoute: boolean = false;
  isDateSessionRoute: boolean = false;
  appData: PublicBookingSettings;
  isShowClientActionsButtons: boolean;
  bookingPackages: BookingPackages[];

  navigationMessageNewBooking = (environment.PublicBookingSetting as PublicBookingSettings).enableNavigationConfirmationMessageNewBooking;
  navigationMessageEditBooking = (environment.PublicBookingSetting as PublicBookingSettings).enableNavigationConfirmationMessageEditBooking;
  editBookingButtonConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).editBookingButtonConfiguration;

  BOOKING_CART = BOOKING_CART;
  parentRef: any;
  theme: Theme;
  bookingType: BOOKING_CART;
  isClientLoginIsRequired: boolean = false;
  clientCategory: number;

  constructor(
    private router: Router,
    private store: Store<any>,
    private dialog: MatDialog,
    private translate: TranslateService,
    private customerService: CustomerService,
    private optimoTranslate: OptimoTranslate,
    private _publicBookingSelectService: PublicBookingSelectService,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.publicWebSession$ = this.store.select(PBSelector.selectSessionTimers);
    this.cart$ = this.store.select("cart");
    this.publicConfig$ = this.store.select("PBReducer");

    this.maxAmount = 0;
    this.appSetting = environment.AppSetting as AppSetting;
    this.HomeBaseUrl = environment.PublicPortalHomeUrl;

    this.publicConfig$.subscribe((d) => {
      this.pageType = d.pageType;
      this.bookingType = d.bookingMode;
    });
    if (this.pageType === PAGE_TYPE.PACKAGE_LIST) {
      this.isPackageListPage = true;
    }
    this.appData = environment.PublicBookingSetting as PublicBookingSettings
  }

  get getConfirmationMessage() {
    let confirmationMessage = ''
    const message = PBUtility.getConfirmationMessage(this.bookingType);
    if (message) {
      if (message.name) {
        this.translate.get("NAVIGATIONCONFIRMATIONMESSAGE." + message.name).subscribe((res: string) => {
          if (res && res != null) {
            confirmationMessage = res;
            if (document.getElementById('confirmationMessages')) {
              document.getElementById('confirmationMessages').innerHTML = res;
            }
          }
        });
      } else if (message.value) {
        confirmationMessage = message.value
        if (document.getElementById('confirmationMessages')) {
          document.getElementById('confirmationMessages').innerHTML = confirmationMessage;
        }
      }
    }
    return confirmationMessage;
  }

  initializeAttributes() {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.getPopUpContent();

    this.cart$.safeSubscribe(this,(state) => {
      this.isLoggedIn = state.contact != null && state.contact.firstName != null && state.contact.firstName != "";
      this.isClientLoginIsRequired = this.appSetting.ClientLoginIsRequired ? this.appSetting.ClientLoginIsRequired : false;
      this.clientCategory = state.contact && state.contact?.client && state.contact.client?.clientCategoryId ? state.contact.client?.clientCategoryId : null;
      
      if (!!state.contact?.profilePicture && state.contact?.profilePicture != "") {
        this.imgUrl = state?.contact?.profilePicture;
      } else if (!!state?.contact?.client?.profilePicture && state?.contact?.client?.profilePicture != "") {
        this.imgUrl = state?.contact?.client?.profilePicture;
      } else {
        this.imgUrl = "../dist/assets/images/user-icon.png";
      }

    });

    this.publicWebSession$.safeSubscribe(this,(config) => {
      if (config.bookingSession) {
        this.bookingSession = config.bookingSession;
        // this.paymentSession = config.paymentSession;
        //get url of current page
        // if (!this.router.url.includes('make-payment')) {
        // //   if (!this.bookingSession.timerPaused) {
        // //     this.store.dispatch(new PauseBookingSession())
        // //     if (config && config.paymentSession && config.paymentSession.timerPaused) {
        // //       this.store.dispatch(new ResumePaymentSession())
        // //     }
        // //   }
        // // } else {
        //   // if(this.router.url.includes('booking-checkout') && !paymentSession.timerPaused && paymentSession.isSessionTimerStart && this.bookingSession.timerPaused ) {
        //   // }
        //   // if (this.bookingSession.timerPaused && this.bookingSession.sessionEndDate && !this.paymentSession.isSessionTimerStart) {
        //   //   this.store.dispatch(new ResumeBookingSession());
        //   //   this.store.dispatch(new PausePaymentSession());
        //   // }
        // }
      }
      if (config.paymentSession) {
        this.paymentSession = config.paymentSession;
      }
    });

    this.publicConfig$.safeSubscribe(this,(config) => {
      this.maxAmount = 0;
      this.bookingType = config.bookingMode;
      this.selectedClient = config.selectedClient;

      if (this.havePendingChanges === undefined) {
        if (this.bookingType == this.BOOKING_CART.NEW_BOOKING) {
          this.havePendingChanges = true;
        } else if (this.bookingType == this.BOOKING_CART.EDIT_BOOKING && config.booking?.dueAmount != config.freezedBooking?.dueAmount) {
          this.havePendingChanges = true;
        } else {
          this.havePendingChanges = false;
        }
      }

      if (config.booking && config.booking.bookingPackages && config.booking.bookingPackages.length > 0) {
        this.bookingPackages = config.booking.bookingPackages;
        this.maxAmount = config.booking.grossAmount;
        this.hasBookingInCart = true;
      } else {
        this.bookingPackages = [];
        this.bookingDisplayCart = [];
        this.hasBookingInCart = false;
      }
        if (this.hasBookingInCart && !this.havePendingChanges && !this.isClearCart && !(config.booking?.invoicedAmount == 0 && config.booking?.dueAmount > 0)){
        this.maxAmount = 0;
      }
    });

    if ((typeof this.isClearCart === 'undefined') &&
      !(
        (this.bookingType === BOOKING_CART.EDIT_BOOKING && this.editBookingButtonConfiguration.continueShoppingEditBooking.visible) ||
        (this.bookingType === BOOKING_CART.NEW_BOOKING && this.editBookingButtonConfiguration.continueShoppingNewBooking.visible)
      ) &&
      !(this.hasBookingInCart && this.havePendingChanges)) {
      this.store.dispatch(new ResetPackages());
      this.store.dispatch(new ResetSeat());
      this.store.dispatch(new ResetBlock());
      this.onBookingSessionReset();

      if (this.bookingType === BOOKING_CART.EDIT_BOOKING) {
        this.store.dispatch(new ResetPublicWebState());
      }

    }

    if (this.router.url.includes('package-list')) {
      this.isPackageListRoute = true;
    }
    if (this.router.url.includes('date-and-session')) {
      this.isDateSessionRoute = true;
    }
    this.isShowClientActionsButtons = this.appData?.showClientActionsButtons;
  }

  getPopUpContent() {
    if (this.bookingType === BOOKING_CART.EDIT_BOOKING) {
      this.message = this.optimoTranslate.transform('NAVMODEL.Are you sure you want to navigate from the booking cart page?')
      this.message += '<br>' + this.optimoTranslate.transform('NAVMODEL.If you click ');
      this.message += '<strong>"' + this.optimoTranslate.transform('NAVMODEL.Yes, Navigate') + '"</strong>';
      this.message += ' ' + this.optimoTranslate.transform('NAVMODEL.any changes you have made will be discarded.');

      this.heading = this.optimoTranslate.transform('NAVMODEL.Navigation Confirmation');
      this.yesText = this.optimoTranslate.transform('NAVMODEL.Yes, Navigate');
      this.noText = this.optimoTranslate.transform('NAVMODEL.Close');
    } else if (this.bookingType === BOOKING_CART.NEW_BOOKING) {
      this.message = this.optimoTranslate.transform('NAVIGATIONCONFIRMATIONMESSAGE.New Booking Confirmation Message');
      this.heading = this.optimoTranslate.transform('NAVMODEL.Navigation Confirmation');
      this.yesText = this.optimoTranslate.transform('NAVMODEL.Yes, Navigate');
      this.noText = this.optimoTranslate.transform('NAVMODEL.Close');
    }
  }

  gotoClientLogin() {
    this.store.dispatch(new SetRedirectionPoint(this.router.url));
    if (this.isLoggedIn) {
      if (this.hasBookingInCart && this.havePendingChanges &&
        ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING)
          || (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {

        const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
          data: {
            heading: this.heading,
            message: this.message,
            yesText: this.yesText,
            noText: this.noText
          },
          panelClass: [],
          height: 'auto',
          width: 'auto',
        });
        dialogRef.afterClosed().subscribe(res => {
          if (res == true) {
            if (this.getMenuVisibility('MyProfile')) {
              window.open(environment.ApiUrl + "#/my-profile", "_self");
            }
          }
        });
      } else {
        if (this.getMenuVisibility('MyProfile')) {
          window.open(environment.ApiUrl + "#/my-profile", "_self");
        }
      }
    } else {
      window.open(environment.ApiUrl + "#/client/login?DirectLoginFromPublicWeb=1&ReturnUrl=" + this.router.url, "_self");
    }
  }

  clearSession() {
    this.store.dispatch(new ResetPackages());
    this.store.dispatch(new ResetPublicWebState());
    this.store.dispatch(new ResetConfigurationState());
    this._publicBookingSelectService.resetValues();
  }

  goToMyBookingPage() {
    if (this.hasBookingInCart && this.havePendingChanges &&
      ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING) ||
        (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {

      const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
        data: {
          heading: this.heading,
          message: this.message,
          yesText: this.yesText,
          noText: this.noText
        },
        panelClass: [],
        height: 'auto',
        width: 'auto',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == true) {
          this.clearSession();
          window.open(environment.ApiUrl + "#/bookings", "_self");
        }
      });

    } else {
      window.open(environment.ApiUrl + "#/bookings", "_self");
    }
  }

  changePassword() {
    if (this.hasBookingInCart && this.havePendingChanges && ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING) || (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {
      const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
        data: {
          heading: this.heading,
          message: this.message,
          yesText: this.yesText,
          noText: this.noText
        },
        panelClass: [],
        height: 'auto',
        width: 'auto',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == true) {
          this.clearSession();
          var ul = environment.ApiUrl + "#/change-password";
          window.open(ul, "_self");
        }
      });
    } else {
      var ul = environment.ApiUrl + "#/change-password";
      window.open(ul, "_self");
    }
  }

  signOut() {
    if (this.hasBookingInCart && this.havePendingChanges && ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING) || (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {
      const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
        data: {
          heading: this.heading,
          message: this.message,
          yesText: this.yesText,
          noText: this.noText
        },
        panelClass: [],
        height: 'auto',
        width: 'auto',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == true) {
          this.customerService.DeleteSession().subscribe(x => {
            if (x == true) {
              this.clearSession();
              this.store.dispatch(new SignOut());
              this.gotoAfterSignOut();
            }
          });
        }
      });
    } else {
      this.customerService.DeleteSession().subscribe(x => {
        if (x == true) {
          this.clearSession();
          this.store.dispatch(new SignOut());
          this.gotoAfterSignOut();
        }
      });
    }
  }

  gotoAfterSignOut() {
    if (this.appSetting.AnyPortalSignOutRedirectToSignIn) {
      var ul = environment.ApiUrl + "#/client/login";
      window.open(ul, "_self");
    } else {
      if (environment.PublicPortalHomeUrl.trim()) {
        window.location.href = environment.PublicPortalHomeUrl;
        ;
      } else {
        this.router.navigate(["/event-category"]);
      }
    }
  }

  goToHome() {
    if (this.hasBookingInCart && this.havePendingChanges &&
      ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING)
        || (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {
      const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
        data: {
          heading: this.heading,
          message: this.message,
          yesText: this.yesText,
          noText: this.noText
        },
        panelClass: [],
        height: 'auto',
        width: 'auto',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == true) {
          this.clearSession();
          if (this.HomeBaseUrl.trim()) {
            window.location.href = this.HomeBaseUrl;
          } else {
            this.router.navigate(["/event-category"]);
          }
        }
      });
    } else {
      if (this.HomeBaseUrl.trim()) {
        window.location.href = this.HomeBaseUrl;
      } else if (this.router.url == '/event-category' || this.router.url == 'event-category') {
        this.isHome = true;
        return null;
      } else {
        this.router.navigate(["/event-category"]);
      }
    }
  }

  goToCart() {
    this.router.navigate(["/booking-cart"]);
  }

  openSearchClient() {
    const dialogRef = this.dialog.open(ClientSearchModalComponent, {
      panelClass: ["w30modal"],
      height: "auto",
      width: "90%",
      data: {
        title: "Client Search",
        URL: environment.PublicWebClientSearchUrl,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  openAddClient() {
    const dialogRef = this.dialog.open(ClientSearchModalComponent, {
      panelClass: ["w30modal"],
      height: "auto",
      width: "90%",
      data: { title: "Client Add", URL: environment.PublicWebClientAddUrl },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      document.body.scrollTop > 18 ||
      document.documentElement.scrollTop > 18
    ) {
      this.isScroll = true;
    } else {
      this.isScroll = false;
    }
  }

  getMenuName(id, defaultValue) {
    let menu: MenuSettings = this.appSetting.MenuSettings ? this.appSetting.MenuSettings.find(menu => menu.Id == id) : null;

    if (menu)
      if (menu.Title != null && menu.Title != '')
        return 'NAVBAR.' + menu.Title;
      else
        return 'NAVBAR.' + defaultValue;
    else
      return 'NAVBAR.' + defaultValue;
  }

  getMenuVisibility(id) {
    let menu: MenuSettings = this.appSetting.MenuSettings ? this.appSetting.MenuSettings.find(menu => menu.Id == id) : null;
    if (menu)
      if (menu.Disabled == Condition.TRUE) {
        return false;
      } else if (menu.Disabled == Condition.FALSE) {
        return true;
      } else if (menu.Disabled == Condition.CONDITIONAL) {
        if (menu?.BookingPermittedClientCategoryIds && this.clientCategory != null && this.isClientLoginIsRequired) {
          return menu?.BookingPermittedClientCategoryIds?.Value?.split(',').includes(this.clientCategory.toString());
        } else {
          return true;
        }
      } else {
        return false;
      }
    else
      return true;
  }

  viewAllEvents() {
    this.store.select(selectSelectedEventCategoryId).safeSubscribe(this,(id: string) => {
      if (!!id) {
        if (this.isPackageListPage || this.isDateSessionRoute) {
          this.router.navigate(["/event-list", id]);
        }
      }
    });
  }

  onBookingSessionReset() {
    if(!!this.bookingSession) {
      this.bookingSession.minutes = "0";
      this.bookingSession.seconds = "0";
      this.bookingSession.isSessionExpired = true;
      this.bookingSession.sessionExpended = false;
      this.bookingSession.isSessionTimerStart = false;
      this.bookingSession.sessionKey = undefined;
      this.bookingSession.sessionStartDate = undefined;
      this.bookingSession.popupShowed = false;
      this.bookingSession.timerPauseTime = null;
      this.bookingSession.timerPaused = false;
      this.bookingSession.sessionEndTime = 0;
      this.store.dispatch(new AddBookingSession(this.bookingSession));
    }
  }

}
