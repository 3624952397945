import { BookingPackages } from 'src/modules/models/booking/booking-packages';
import _ from 'lodash';
import { IPackageDeliveryMethod } from '../../../../store/public-web/public-web-reducers';
import { PublicBookingSettings, RepeatPolicy } from 'src/modules/models/settings/public-booking/public-booking-setting';
import { environment } from 'src/environments/environment';
import { BookingItem } from 'src/modules/models/regular-portal/booking/save-booking/booking-item';
import { Package } from 'src/modules/models/public-web/Package';

export class BookingCart {
  id: string;
  attendees: number;
  bookingPackageId: string;
  image: string;
  name: string;
  totalPrice: number;
  grossAmount: number;
  netAmount: number;
  startDate: string;
  endDate: string;
  cartPackages: CartPackage[];
  cartItems: CartItem[];
  isQuestionnaire = false;
  hasQuestionnaire = false;
  hasHostGuest = false;
  packageDeliveryMethods: IPackageDeliveryMethod[] = [];
  isSeatAvailable = false;
  seats = [];
  hasEditableSeats = true;
  isMerged = false;
  availability = 0;
  capacity = 0;
  isShowAddExtra = true;
  hasLinkedPackage = false;
  quantity;
  eventid;
  constructor(bookingPackage: BookingPackages, bookedPack: Package, showPriceWithTax) {
    this.id = bookingPackage.id;
    this.bookingPackageId = bookingPackage.id;
    this.image = '';
    this.name = bookingPackage.name;
    this.totalPrice = bookingPackage.totalAmount;
    this.grossAmount = bookingPackage.grossAmount;
    this.netAmount = bookingPackage.netAmount;
    this.startDate = bookingPackage.startDate;
    this.endDate = bookingPackage.endDate;
    this.quantity = bookingPackage.quantity;
    this.hasLinkedPackage = bookingPackage.package && (bookingPackage.package.primaryPackage || bookingPackage.package.linkedPackages) ? true : false;
    this.cartPackages = [new CartPackage(bookedPack, bookingPackage, showPriceWithTax)];
    if (bookingPackage?.bookingItems?.length > 0) {
      this.cartItems = this.mapItem(_.cloneDeep(bookingPackage.bookingItems), showPriceWithTax, bookingPackage.singleDayPackage);
    } else {
      this.cartItems = [];
    }
    this.eventid = bookingPackage?.package?.publicEvent?.id;
  }

  get repeatPolicies() {
    return (environment.PublicBookingSetting as PublicBookingSettings).repeatPolicy;
  }

  mapItem(bookingItems, showPriceWithTax, singleDayPackage) {
    const sortedItems: CartItem[] = [];
    //Get Unique Upsell Items on bookingItems
    let uniqueItem: any[] = []
    bookingItems.forEach(item => {
      if (uniqueItem.length > 0 && item.upsell && item.type !== 'bookingDiscountItem' && (item.item.repeatPolicy || (!item.item.repeatPolicy && !singleDayPackage))) {
        let isExist = false;
        uniqueItem.forEach(uniq => {
          if (uniq.item
            && uniq.item.id === item.item.id
            && (uniq.item.repeatPolicy === item.item.repeatPolicy || !singleDayPackage)
            && uniq.upsell) {
            isExist = true;
            if (uniq.child && uniq.child.length > 0) {
              item.child = []
              uniq.child.push(new CartItem(item, showPriceWithTax));
            }
            else {
              uniq.child = []
              item.child = []
              uniq.child.push(new CartItem(item, showPriceWithTax));
            }
          }
          uniq.repeatPolicy = uniq.item && uniq.item.repeatPolicy ? uniq.item.repeatPolicy : null;
        })
        if (!isExist) {
          uniqueItem.push(item);
        }
      }
      else {
        uniqueItem.push(item);
      }
    });

    uniqueItem.forEach(item => {
      if (item && item.upsell) {
        if (item.child && item.child.length > 0) {

          item.child.push(new CartItem(item, showPriceWithTax));
        } else {
          if (item.repeatPolicies === this.repeatPolicies.allowIndividualDays) {
            item.child = [];
            item.child.push(new CartItem(item, showPriceWithTax));
          }
        }
        let total = 0
        if (item.child && item.child.length > 0) {
          item.child.forEach(child => {
            total += (child.quantity * child.unitPrice)
          })
          item.grossAmount = total;
        }
        if (item.child && item.child.length > 0) {
          //sort item based on id ASC
          //item.child.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
          //sort item based on duration dates ASC
          item.child.sort((a: any, b: any) =>new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
          item.endTime = item.child[item.child.length - 1].endTime;
        }
        if(item.child && item.child.length > 0) {
          item.hasChildrenAreSameQuantity = this.hasChildAreSameQuantity(item.child);
        }
        // item.child.forEach(child=>{
        //   child=_.cloneDeep(new CartItem(child));
        // })
        if (item && item.child && item.child.length > 0) {
          item.child.forEach(child => {
            child.child = [];
          })
        }
        sortedItems.push(new CartItem(_.cloneDeep(item), showPriceWithTax));
      }
    });
    return sortedItems;
  }

  hasChildAreSameQuantity(child) : boolean{
    return child.every(element => element.quantity === child[0].quantity);
  }
}

export class CancellationReason {
  id: string;
  name: string;
}
export class CartPackage {
  id: string;
  name: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  isChild: boolean;
  isValidEdit: boolean;
  isDelete = false;
  isLinkedPackage: boolean;
  linkedPackageTypeName: string = '';
  primaryPkgId: string;
  bookingPackageId: string;
  hasQuestionnaire = false;
  originalAvailability = 0;
  availability = 0;
  capacity = 0;
  backgroundColor = '';
  hasSeating = false;

  constructor(pkg, bookingPkg?, showPriceWithTax?) {
    if (pkg) {
      this.id = pkg.id;
      this.name = pkg.name ? pkg.name : bookingPkg.name;
      this.unitPrice = (showPriceWithTax && pkg.name) ? (pkg.variablePriceIncludingTax + pkg.fixedPriceIncludingTax) : (pkg.variablePriceExcludingTax + pkg.fixedPriceExcludingTax);
      this.isLinkedPackage = pkg.primaryPackage || pkg.linkedPackages ? true : false;
      this.isChild = pkg.primaryPackage ? true : false;
      this.linkedPackageTypeName = (this.isChild && pkg.linkedPackageType?.name) ? pkg.linkedPackageType?.name : '';
      this.primaryPkgId = pkg.primaryPackage ? pkg.primaryPackage.id : '';
      if(bookingPkg) {
        this.quantity = bookingPkg.quantity;
        this.totalPrice = this.unitPrice * bookingPkg.quantity;
        this.bookingPackageId = bookingPkg.id;
        this.hasSeating = (bookingPkg.package && bookingPkg.package.publicEvent && bookingPkg.package.publicEvent.hasSeating) ? bookingPkg.package.publicEvent.hasSeating : false;
      }
    }
    // if(bookingPkg.bookingItems.length > 0){
    //   bookingPkg.bookingItems.forEach(bItem => {
    //     if (!bItem.upsell) {
    //       if (bItem.units) {
    //         this.unitPrice += showPriceWithTax ? (bItem.unitPriceIncludingTax * bItem.units) : (bItem.unitPriceExcludingTax * bItem.units);
    //       }
    //     }
    //   })
    // }
    // else{
    //   this.unitPrice = (pkg.variablePriceExcludingTax/pkg.attendees);
    // }
  }
}

export class CartItem {
  id: string;
  name: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  item: any;
  bookingItemId: string;
  isValidEdit: boolean;
  isDelete = false;
  startTime: string;
  endTime: string;
  hasQuestionnaire = false;
  units;
  child: CartItem[] = [];
  repeatPolicy: string;
  itemType: ItemType;
  originalAvailability = -1;
  availability = -1;
  capacity = 0;
  hasChildrenAreSameQuantity = false;
  backgroundColor = '';

  constructor(itm, showPriceWithTax) {
    this.id = itm.id;
    this.name = itm.name;
    this.quantity = itm.quantity;
    this.units = itm.units ? itm.units : 1;
    this.unitPrice = showPriceWithTax ? itm.unitPriceIncludingTax : itm.unitPriceExcludingTax;
    this.totalPrice = (showPriceWithTax ? itm.unitPriceIncludingTax : itm.unitPriceExcludingTax) * this.units;
    this.item = itm.item && itm.item.itemQuestion ? itm.item : null;
    this.bookingItemId = itm.id;
    this.startTime = itm.startTime ? itm.startTime : '';
    this.endTime = itm.endTime ? itm.endTime : '';
    this.child = itm.child ? itm.child : [];
    this.repeatPolicy = itm.repeatPolicy ? itm.repeatPolicy : null;
    this.hasChildrenAreSameQuantity = itm.hasChildrenAreSameQuantity ? itm.hasChildrenAreSameQuantity : false;
    this.itemType = (itm.type == 'bookingDiscountItem') ? ItemType.DISCOUNT : ItemType.UPSELL;
  }
}

export class BookingDispatchStatus {
  booingID: string
  dispatchStatusID: number
  dispatchable: boolean
  dispatchableItems: BookingItem[] = []
  constructor(itm) {
    this.booingID = itm.bookingID;
    this.dispatchStatusID = itm.dispatchStatusID;
    this.dispatchable = itm.dispatchable;
    this.dispatchableItems = itm.dispatchableItems;
  }
}

export enum DispatchStatus {
  NotDespatched = 1,
  FullyDespatched = 2,
  PartialDespatched = 3
}

export enum ItemType {
  UPSELL = 'UPSELL',
  DISCOUNT = 'DISCOUNT'
}
