<div class="mmc-modal-wrapper questionnaire-modal">
  <div class="mmc_flex--container mmc-modal-header header-primary p-4">
    <div class="mmc_flex__item mmc_flex__item--vcenter">
      <span class="heading" style="color: #ffffff;">{{"QUESTIONPOPUP.Please Answer The Below Questions" | optimotranslate}}</span>
    </div>
    <div class="close-btn" style="align-items:center;display:flex;margin-right: -8px;">
      <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;"(click)="dialogRef.close()">close</mat-icon>
    </div>
  </div>
  <div class="mmc-modal-body" style="padding: 0px 19px 0 25px;">
    <div class="body">
      <div *ngIf="getUnAnsweredMendQues().length > 0" class="error-message pr-2" style="line-height: 3;">
        <b><span style="color: black;">
            {{getTotalAnsweredQuestionCount}}</span></b><span>({{getUnAnsweredMendQues()}})</span>
      </div>
      <!-- Activity Group Questions -->
      <div *ngIf="isHidden" class="pb-2">
        <span>{{"QUESTIONPOPUP.All the mandatory questions have not been answered" | optimotranslate}}</span>
      </div>

      <!-- <div *ngIf="IS_MULTI_SELECTION_SESSION; else singleSessionQus">
        <div *ngFor="let i of multiSessionQuestions">
          <div class="pw-txt-s3 mb-2">
            Start from
            {{ getSessionStartToEnd(i.sessions) }}
            {{ i.sessions.length }} Days
          </div>
          <mat-accordion class="booking-pk">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header class="pk-header">
                <span class="mat-content">
                  <div class="
                      w-100
                      mmc_flex--container
                      questionnaire-expansion-header
                    " bis_skin_checked="1">
                    <div class="w-100 mmc_flex__item mmc_flex__item--vcenter" bis_skin_checked="1">
                      <div class="
                          w-100
                          mmc_flex--container
                          space-between
                          questionnaire-expansion-header--container
                        " bis_skin_checked="1">
                        <div class="mmc_flex__item mmc_flex__item--vcenter" bis_skin_checked="1">
                          <div class="pw-txt-s1" bis_skin_checked="1">
                            {{ i.package.name }}
                            <span>| {{ getAnsweredPackageQuestionCount(i) }}</span>
                          </div>
                        </div>
                        <div class="
                            mmc_flex__item mmc_flex__item--vcenter
                            mmc_just-content-end
                          " bis_skin_checked="1" *ngIf="getUnaunsweredPackageMendQ(i) != 0">
                          <div class="expansion-header-icons mt-2" bis_skin_checked="1">
                            <span class="icon-p2-questions icon f-size-md"></span><span class="count">{{
                              getUnaunsweredPackageMendQ(i)
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="
                        mmc_flex__item
                        mmc_flex__item--vcenter
                        mmc_flex__item--inherit
                        mmc_just-content-end
                        icon__expand
                        wfixed
                      " bis_skin_checked="1">
                      <button class="down-arrow mat-icon-button mat-primary" color="primary" mat-icon-button=""
                        ng-reflect-disable-ripple="true" ng-reflect-color="primary">
                        <span class="mat-button-wrapper">
                          <mat-icon class="
                              mat-icon
                              notranslate
                              material-icons
                              mat-icon-no-color
                            " role="img" aria-hidden="true"></mat-icon>
                        </span>
                        <div class="
                            mat-button-ripple mat-ripple mat-button-ripple-round
                          " matripple="" ng-reflect-centered="true" ng-reflect-disabled="true"
                          ng-reflect-trigger="[object HTMLButtonElement]" bis_skin_checked="1"></div>
                        <div class="mat-button-focus-overlay" bis_skin_checked="1"></div>
                      </button>
                    </div>
                  </div>
                </span>
              </mat-expansion-panel-header>
              <div class="pk-content mt-2" *ngFor="let item of i.items">
                <mat-accordion class="item-qa-c">
                  <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header class="pk-header">
                      <span class="mat-content">
                        <div class="
                            w-100
                            mmc_flex--container
                            questionnaire-expansion-header
                          " bis_skin_checked="1">
                          <div class="w-100 mmc_flex__item mmc_flex__item--vcenter" bis_skin_checked="1">
                            <div class="
                                w-100
                                mmc_flex--container
                                space-between
                                questionnaire-expansion-header--container
                              " bis_skin_checked="1">
                              <div class="mmc_flex__item mmc_flex__item--vcenter" bis_skin_checked="1">
                                <div class="pw-txt-s1" bis_skin_checked="1">
                                  {{ item.name }}
                                  <span>|
                                    {{
                                    getAnsweredItemQuestionCount(
                                    item.bookingQuestions
                                    )
                                    }}</span>
                                </div>
                              </div>
                              <div class="
                                  mmc_flex__item mmc_flex__item--vcenter
                                  mmc_just-content-end
                                " bis_skin_checked="1" *ngIf="
                                  getUnAnswerMandotoryQuestion(
                                    item.bookingQuestions
                                  ) != 0
                                ">
                                <div class="expansion-header-icons mt-2" bis_skin_checked="1">
                                  <span class="icon-p2-questions icon f-size-md"></span><span class="count">{{
                                    getUnAnswerMandotoryQuestion(
                                    item.bookingQuestions
                                    )
                                    }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="
                              mmc_flex__item
                              mmc_flex__item--vcenter
                              mmc_flex__item--inherit
                              mmc_just-content-end
                              icon__expand
                              wfixed
                            " bis_skin_checked="1">
                            <button class="down-arrow mat-icon-button mat-primary" color="primary" mat-icon-button=""
                              ng-reflect-disable-ripple="true" ng-reflect-color="primary">
                              <span class="mat-button-wrapper">
                                <mat-icon class="
                                    mat-icon
                                    notranslate
                                    material-icons
                                    mat-icon-no-color
                                  " role="img" aria-hidden="true"></mat-icon>
                              </span>
                              <div class="
                                  mat-button-ripple
                                  mat-ripple
                                  mat-button-ripple-round
                                " matripple="" ng-reflect-centered="true" ng-reflect-disabled="true"
                                ng-reflect-trigger="[object HTMLButtonElement]" bis_skin_checked="1"></div>
                              <div class="mat-button-focus-overlay" bis_skin_checked="1"></div>
                            </button>
                          </div>
                        </div>
                      </span>
                    </mat-expansion-panel-header>
                    <div class="item-qa-content">
                      <mat-accordion [multi]="true">
                        <opt-question [isMandatory]="false" [questionNumber]="1" [questions]="item.bookingQuestions"
                          [answeredQuestionIds]="answeredQuestionIds"
                          (questionAnswerOutput)="questionAnswerOutput($event)"></opt-question>
                      </mat-accordion>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <ng-template #singleSessionQus> -->
      <div *ngFor="let i of sessionQuestions; let ind = index" class="mb-4">
        <mat-accordion class="booking-pk">
          <mat-expansion-panel [expanded]="ind===0">
            <mat-expansion-panel-header class="pk-header">
              <span class="mat-content">
                <div class="
                      w-100
                      mmc_flex--container
                      questionnaire-expansion-header
                    " bis_skin_checked="1">
                  <div class="w-100 mmc_flex__item mmc_flex__item--vcenter" bis_skin_checked="1">
                    <div class="
                          w-100
                          mmc_flex--container
                          space-between
                          questionnaire-expansion-header--container
                        " bis_skin_checked="1">
                      <div class="mmc_flex__item mmc_flex__item--vcenter" bis_skin_checked="1">
                        <div class="pw-txt-s1" bis_skin_checked="1">
                          {{ i.package.name }}
                          <span>| {{ getAnsweredPackageQuestionCount(i) }}</span>
                          <span *ngIf="i.sessions && (i.sessions.length > 0)">| {{getTranslate(i.sessions[0].startTime | date:
                            'medium')}}</span>
                        </div>
                      </div>
                      <div style="padding-right: 15px" bis_skin_checked="1" *ngIf="getUnaunsweredPackageMendQ(i) != 0">
                        <div class="expansion-header-icons mt-2" bis_skin_checked="1">
                          <!-- <span class="icon-p2-questions icon f-size-md"></span> -->
                          <mat-icon>help</mat-icon>
                          <span class="count">{{
                            getUnaunsweredPackageMendQ(i)
                            }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="
                        mmc_flex__item
                        mmc_flex__item--vcenter
                        mmc_flex__item--inherit
                        mmc_just-content-end
                        icon__expand
                        wfixed
                      " bis_skin_checked="1">
                    <button class="down-arrow mat-icon-button mat-primary" color="primary" mat-icon-button=""
                      ng-reflect-disable-ripple="true" ng-reflect-color="primary">
                      <span class="mat-button-wrapper">
                        <mat-icon class="
                              mat-icon
                              notranslate
                              material-icons
                              mat-icon-no-color
                            " role="img" aria-hidden="true"></mat-icon>
                      </span>
                      <div class="
                            mat-button-ripple mat-ripple mat-button-ripple-round
                          " matripple="" ng-reflect-centered="true" ng-reflect-disabled="true"
                        ng-reflect-trigger="[object HTMLButtonElement]" bis_skin_checked="1"></div>
                      <div class="mat-button-focus-overlay" bis_skin_checked="1"></div>
                    </button>
                  </div>
                </div>
              </span>
            </mat-expansion-panel-header>
            <div class="item-qa-content">
              <mat-accordion [multi]="true">
                <opt-question [isMandatory]="false" [questionNumber]="1" [questions]="i.bookingQuestions"
                  [answeredQuestionIds]="answeredQuestionIds" (questionAnswerOutput)="questionAnswerOutput($event)">
                </opt-question>
              </mat-accordion>
            </div>
            <div class="pk-content mt-2" *ngFor="let item of i.items">
              <mat-accordion class="item-qa-c">
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header class="pk-header">
                    <span class="mat-content">
                      <div class="
                            w-100
                            mmc_flex--container
                            questionnaire-expansion-header
                          " bis_skin_checked="1">
                        <div class="w-100 mmc_flex__item mmc_flex__item--vcenter" bis_skin_checked="1">
                          <div class="
                                w-100
                                mmc_flex--container
                                space-between
                                questionnaire-expansion-header--container
                              " bis_skin_checked="1">
                            <div class="mmc_flex__item mmc_flex__item--vcenter" bis_skin_checked="1">
                              <div class="pw-txt-s1" bis_skin_checked="1">
                                {{ item.name }}
                                <span>|
                                  {{
                                  getAnsweredItemQuestionCount(
                                  item.bookingQuestions
                                  )
                                  }}</span>
                              </div>
                            </div>
                            <div style="padding-right: 15px" *ngIf="
                                  getUnAnswerMandotoryQuestion(
                                    item.bookingQuestions
                                  ) != 0
                                ">
                              <div class="expansion-header-icons mt-2" bis_skin_checked="1">
                                <!-- <span class="icon-p2-questions icon f-size-md"></span> -->
                                <mat-icon>help</mat-icon>
                                <span class="count">{{
                                  getUnAnswerMandotoryQuestion(
                                  item.bookingQuestions
                                  )
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="
                              mmc_flex__item
                              mmc_flex__item--vcenter
                              mmc_flex__item--inherit
                              mmc_just-content-end
                              icon__expand
                              wfixed
                            " bis_skin_checked="1">
                          <button class="down-arrow mat-icon-button mat-primary" color="primary" mat-icon-button=""
                            ng-reflect-disable-ripple="true" ng-reflect-color="primary">
                            <span class="mat-button-wrapper">
                              <mat-icon class="
                                    mat-icon
                                    notranslate
                                    material-icons
                                    mat-icon-no-color
                                  " role="img" aria-hidden="true"></mat-icon>
                            </span>
                            <div class="
                                  mat-button-ripple
                                  mat-ripple
                                  mat-button-ripple-round
                                " matripple="" ng-reflect-centered="true" ng-reflect-disabled="true"
                              ng-reflect-trigger="[object HTMLButtonElement]" bis_skin_checked="1"></div>
                            <div class="mat-button-focus-overlay" bis_skin_checked="1"></div>
                          </button>
                        </div>
                      </div>
                    </span>
                  </mat-expansion-panel-header>
                  <div class="item-qa-content">
                    <mat-accordion [multi]="true">
                      <opt-question [isMandatory]="false" [questionNumber]="1" [questions]="item.bookingQuestions"
                        [answeredQuestionIds]="answeredQuestionIds"
                        (questionAnswerOutput)="questionAnswerOutput($event)"></opt-question>
                    </mat-accordion>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!-- </ng-template> -->
    </div>
  </div>
  <div class="mmc-modal-footer">
    <div class="button-row">
      <div class="row m-0">
        <div class="col-sm-12">
          <div class="mmc_flex--container mmc_just-content-end footer-btn">
            <!-- <div *ngIf="getUnAnsweredMendQues().length > 0" class="error-message pr-2" style="line-height: 3; display: none;">
              {{getUnAnsweredMendQues()}}</div> -->
              <!-- <div *ngIf="getUnAnsweredMendQues().length > 0" class="error-message pr-2" style="line-height: 3;">
                <b><span style="color: black;"></span></b><span>({{getUnAnsweredMendQues()}})</span>
              </div> -->
            <div class="mmc_flex__item--inherit">
              <button mat-button class="mmc-btn-secondary mr-2" (click)="close()" *ngIf="data.enableClose">
                {{"QUESTIONPOPUP.Cancel" | optimotranslate}}
              </button>
              <button mat-button class="mmc-btn-primary mr-2 btn-secondary" (click)="goToBookingCart()" *ngIf="enableToSkip" style="color: #00507c;background: none;">
                {{"QUESTIONPOPUP.Skip For Later" | optimotranslate}}
              </button>
              <button id="submitBtn" [disabled]="disableSubmit()" mat-button class="mmc-btn-primary"
                (click)="submitData()">
               {{"QUESTIONPOPUP.Continue" | optimotranslate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
