import { IDateAndSession } from "../../../store/public-web/public-web-reducers";
import { BookingQuestion } from "../../regular-portal/booking/question/booking-question";
import { Package } from "../Package";
import { Item } from "./item";

export class MultiSessionQuestion {
  sessions: IDateAndSession[];
  package: Package;
  bookingPackageID?: string;
  items: Item[];
  bookingQuestions: BookingQuestion[];
  constructor() {
    this.bookingQuestions = [];
    this.sessions = [];
    this.items = [];
  }
}
