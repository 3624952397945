import { BaseResource } from "../base-resource";

export class BookingStatus extends BaseResource{
    id: string;
    name: string;
    calenderColor: string;
    stockAllocated: boolean;
    precedence: number;
    parentStatus: string;
    isEditRestricted: boolean;
    type: string;
    constructor() {
      super();
      this.type = 'BookingStatus';
    }
    initialize(d) {
      if (!d) {
        return;
      }
      this.id = d.id;
      this.name = d.name;
      this.calenderColor = d.calenderColor ? d.calenderColor : '#F0F0F0';
      this.stockAllocated = d.stockAllocated ? d.stockAllocated : false;
      this.precedence = d.precedence ? d.precedence : null;
      this.parentStatus = d.parentStatus ? d.parentStatus.toString() : '';
      this.isEditRestricted = d.isEditRestricted ? d.isEditRestricted : false;
      return this;
    }
  }