import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CalendarEvent, MonthViewDay } from "calendar-utils";
import { BaseComponent } from "src/modules/shared/base.component";
import { SetSelectedSession } from "src/modules/store/public-web/public-web-actions";
import { IDateAndSession } from "src/modules/store/public-web/public-web-reducers";

@Component({
  selector: "opt-additional-events",
  templateUrl: "./additional-events.component.html",
  styleUrls: ["./additional-events.component.scss"],
})
export class AdditionalEventsComponent extends BaseComponent implements OnInit {
  viewDay: MonthViewDay;
  events: any[];
  eventID: string;
  constructor(
    public dialog: MatDialog,
    private store: Store<any>,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }
  ngOnInit() {
    this.viewDay = this.data.day;
    this.events = this.viewDay.events;

    console.log(this.events);

    this.eventID = this.router.url.replace("/date-and-session/", "");
  }

  selectSession(e: any) {
    const session: IDateAndSession = {
      id: e.id.toString(),
      startTime: e.session.startTime,
      endTime: e.session.endTime,
      availability: 0,
    };
    this.store.dispatch(new SetSelectedSession(session));
    this.dialog.closeAll();
    this.router.navigate(["package-list/" + this.eventID], {
      queryParams: {
        sessionID: session.id,
      },
    });
  }
}
