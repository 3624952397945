import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { forkJoin, from, Observable } from 'rxjs';
import { ICart, Store } from 'src/modules/store';
import { environment } from 'src/environments/environment';
import { SystemOption } from 'src/modules/models/public-web/SystemOption';
import { BaseComponent } from 'src/modules/shared/base.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PublicPackageService } from 'src/modules/services/public-web/public-package.service';
import * as fromConfiguration from "src/modules/store/booking-application/configuration.selector";
import {
  NavigationConfirmationComponent
} from 'src/modules/public-web/components/modals/navigation-confirmation/navigation-confirmation.component';

import { Package } from 'src/modules/models/public-web/Package';
import {
  BookingCart,
  BookingDispatchStatus,
  CancellationReason,
  CartItem,
  CartPackage,
} from '../../layout/models/bookingCart.model';
import { BookingPackages } from 'src/modules/models/booking/booking-packages';
import {
  SetBooking,
  AddBookingSession,
  SetPageConfigurations,
  AddPaymentSession,
  PatchUpSellQuantity,
  PatchBooking,
  ResetPublicWebState,
  SelectPackageSessions,
  SetSelectedSession,
  SetBookingType,
  SetFreezedBooking,
  SetPatchPreviewId,
  SetRedirectionPoint,
  SetSelectedBooking,
  SetSelectedBookingPackage,
  StartBookingSession,
  StartPaymentSession,
  UpdateSelectedBooking,
  UpdateTranslatedSelectedBooking,
  AddNewSession,
  SetBookedPackage,
  SetUpdatedBookingPackages,
  RemovePackageSession,
  SetBookingFlow,
} from '../../../../store/public-web/public-web-actions';
import * as _ from 'lodash';
import { PublicQuestionnaireComponent } from '../../modals/questionnaire/questionnaire.component';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { PublicBookingService } from 'src/modules/services/public-web/public-booking.service';
import {
  IBooingPackage,
  IBooingPackageBookedSeat,
  IDateAndSession,
  IPBReducer,
  IPublicEvent,
  ISelectBooking
} from '../../../../store/public-web/public-web-reducers';
import { Utility } from 'src/modules/utility';
import {
  FacilitySearchLoaded,
  FacilitySearchLoading,
  LoadingCompleted,
  LoadingStarted,
  PublicLoaded,
  PublicLoading,
} from 'src/modules/store/loading/actions';
import { FileUploadQuestion } from 'src/modules/models/regular-portal/booking/question/question';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  Attendee,
  AttendeeDisplay,
  Host,
} from 'src/modules/models/booking/Attendee';
import { AttendeeEditModalComponent } from '../../modals/attendee-edit-modal/attendee-edit-modal.component';
import { AttendeeListModalComponent } from '../../modals/attendee-list-modal/attendee-list-modal.component';
import { Booking, DeliveryMethod } from 'src/modules/models/booking/booking';
import { BookingPackage } from 'src/modules/models/regular-portal/booking/booking-package';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/map';

import { PublicBookingSettings, TIME_INDICATOR_OPTION } from 'src/modules/models/settings/public-booking/public-booking-setting';//DS_RepeatPolicy need to removed
import { ErrorType, ValidationError } from './booking-validation';
import { BookingValidationConfirmationModalComponent } from '../../modals/booking-validation-confirmation-modal/booking-validation-confirmation-modal.component';
import { MatAccordion } from '@angular/material/expansion';
import { SuccessMessageComponent } from 'src/modules/shared/success-message/success-message.component';
import moment from 'moment';
import { PBUtility } from 'src/modules/public-web/pb-utility';
import { BOOKING_CART, PAGE_TYPE, FILE_TYPE, PANEL_TYPE } from 'src/modules/models/public-web/enum';


import * as PBSelector from '../../../../store/public-web/public-web-selectors';
import { PrivacyTermsModalComponent } from '../../modals/privacy-terms/privacy-terms.component';
import { PackageDescriptionModalComponent } from '../../modals/package-description-modal/package-description-modal.component';
import { DispatchStatusModalComponent } from '../../modals/dispatch-status/dispatch-status.component';
import { BaseParam, Paging } from 'src/modules/models/base-param';
import {
  BaseParam as filterParam, SearchBaseFilter
} from "src/modules/models/public-web/filter";
import { BookingService } from 'src/modules/services/booking.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { getSelectedLanguage } from '../../../../store/public-web/select-language/language-selector';
import { SystemService } from 'src/modules/services/system.service';
import { AppSetting, MenuSettings } from 'src/modules/models/settings/casual-portal/app-setting';
import { PageLayoutService } from 'src/modules/services/public-web/page-layout.service';
import * as SearchBaseParam from "src/modules/models/public-web/filter";
import { PackageSession } from "src/modules/models/public-web/PackageSession";
import { groupBy, mergeMap, reduce, toArray } from "rxjs/operators";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PublicBookingSelectService } from 'src/modules/services/public-web/public-booking-select.service';
import {
  RemovedSeatsAfterPatch,
  ResetBlock,
  ResetConfigurationState,
  ResetEventPackages,
  ResetSeat,
  UpdateSeat
} from 'src/modules/store/booking-application/configuration.action';
import { CommunicationMethod } from 'src/modules/models/client/client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BookingContact } from "src/modules/models/booking/booking-contact";
import { BookingItems } from 'src/modules/models/booking/booking-items';
import { Allocation, AllocationType } from 'src/modules/models/public-web/booking/allocation';
import { PublicEventFacilityTimeslot } from 'src/modules/models/public-web/booking/publicEventFacilityTimeslot';
import { Asset } from "src/modules/models/booking/asset";
import { EventSeat, EventSeatingConfiguration } from "src/modules/models/public-web/booking/eventSeatingConfiguration";
import { BaPackageSelectionComponent } from 'src/modules/public-web/booking-application/components/modals/ba-package-selection/ba-package-selection.component';
import { SpecialRequestsComponent } from '../../modals/special-requests/special-requests.component';
import { OptimoTranslate } from "src/modules/shared/opt-translate/optimo-translate.pipe";
import { PriceBandService } from 'src/modules/public-web/booking-application/services/price-band.service';
import { SelectEventComponent } from '../../modals/select-event/select-event.component';
import { TimeSlot } from 'src/modules/public-web/booking-application/models/BookingEvent';
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { PublicEvent } from 'src/modules/models/public-web/PublicEvent';
import { GroupingBlockModel } from 'src/modules/public-web/booking-application/models/ConfigurationDetail';
import { BOOKING_FLOW_OPTION } from '../../layout/models/enums';
import { Title } from '@angular/platform-browser';
import { PaymentSchedule } from 'src/modules/models/booking/payment-schedule';
import { AttendeeAddModalComponent } from '../../modals/attendee-add-modal/attendee-add-modal.component';
declare let gtag: Function;
export class OverRideEventCategory {
  eventCategoryId: string;
  isOverRide: boolean;
}
export class PatchItems {
  bookingPackageId: string;
  itemId: string;
  bookingItemId: string;
  quantity: number;
}

@Component({
  selector: 'opt-public-booking-cart',
  templateUrl: './public-booking-cart.component.html',
  styleUrls: ['./public-booking-cart.component.scss']
})
export class PublicBookingCartComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('BookingPaginator') paginator: MatPaginator;

  previewsBookingPackageForCheck = null;
  config$: Observable<IPBReducer>;
  cart$: Observable<ICart>;
  PBReducer: IPBReducer;

  bookingSessionTimer$: Observable<SystemOption>;
  bookingPaymentTimer$: Observable<SystemOption>;
  paymentSessionTime: SystemOption;
  enableAvailability : boolean = false;
  timer: any;
  buttonTriggerPriviousTime: number = 0;
  bookingPaymentTimer: SystemOption;
  booking: any;
  freezedBooking: Booking;
  bookingTotalAmount = 0;
  bookedPackages: Package[];
  bookingPackages: any[] = [];
  freezedBookingPackages: any[] = [];
  updatedBookingPackages: any[] = [];
  bookingPackagesLength: number = 0;
  bookedEvents: PublicEvent[] = []; //to get the unique ids of booked event
  allowAllPackage = false;
  tempBookedPackage: Package[] = [];
  previousBooking;

  isLoggedIn: boolean = false;
  bookingDisplayCart: BookingCart[];
  canEditBookingCart: boolean = true;
  canEditCardlessContent = false;
  bookingSessions: IDateAndSession[];
  sortingVal = true;
  cardUpdated: boolean = false;
  message: string;
  heading: string;
  yesText: string;
  noText: string;
  canGetDeliveryMethod = true;
  triggerAttendeeCapture = false;
  triggerAttendeeCapture_After_Sign_in = false;
  trigger_Questionnaire_AfterAttendeeCapture = false;
  panelOpenState = false;
  panelOpenState1 = false;
  panelOpenStateMobile = false;
  invoicePanelOpenState = false;
  validatedBooking = false;
  enableSpinner = false;
  enablePublicLoader = false;
  bookingPatchTimerTriggered = false;
  triggerPayment = false;
  isFromMyBooking = false;
  enableDeliveryMethodSpinner = false;
  private editBookingSelectCalled = false;
  private pendingPatch = false;
  isPendingChanges: boolean = false;
  isCancelModalOpen: boolean = false;
  isPaymentContinueDisable: boolean = false;
  enableCustomCancelMessage: boolean = false;
  isBookingEditable: boolean = true;
  isRetrivedData = false;
  fromHomePage = true;
  hasEligibleDeliveryMethods = false;
  isHaveToPayDueAmount: boolean = false;
  isSeatsUpdate: boolean;
  bookingPackageIds = [];
  expandedBookingPackageIndex = -1;
  selectedBookingPackageID: string = '';
  deliveryMethods: DeliveryMethod[] = [];
  selectedDeliveryMethod: DeliveryMethod;
  currentDeliveryMethod: DeliveryMethod;
  pageSize = 0;
  pageIndex = 0;

  selectedDeliveryMethodID: string;

  selectedSessions$;
  cart: any;
  validationError: ValidationError[];
  selectedPkgIds;
  priceBands;
  isShipmentConfirm;
  bookingPatchTimerTriggerTimer = 0;
  // Timer for capture Every second
  bookingPatchTimer;
  BOOKING_CART = BOOKING_CART;
  cartType: BOOKING_CART;
  freezedBookingCarts: BookingCart[];
  conformationModel: string;
  selectedBookingId;
  cancellationReason: CancellationReason[] = [];
  selectedCancellation: CancellationReason;
  customCancellationReason: string;
  patchPreviewId: string;
  imageSource = 'https://i0.wp.com/donektools.com/wp-content/uploads/2018/12/geometric-3840x2160-shapes-dark-background-black-yellow-gradient-hd-4k-14372.jpg?ssl=1';
  editBookingButtonConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).editBookingButtonConfiguration;
  paginationSetting = (environment.PublicBookingSetting as PublicBookingSettings).paginationSetting;
  requestChangeContactNumber = (environment.PublicBookingSetting as PublicBookingSettings).requestChangeContactNumber;
  packageLeveltimeShowingConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).timeShowingConfiguration?.packageLevel;
  eventLevelTimeShowingConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).timeShowingConfiguration?.eventLevel;
  enableUpsellConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).enableUpsell;
  enableNewDesignForBookingCartPage = (environment.PublicBookingSetting as PublicBookingSettings).enableNewDesignForBookingCartPage ? (environment.PublicBookingSetting as PublicBookingSettings).enableNewDesignForBookingCartPage : false;
  tempSpecialNote: string = '';
  PaymentPortalUrl: string = environment.PaymentPortalUrl;
  countPendingInvoices: number = 0;
  bookingInvoices: any = [];
  bookingDocuments: any = [];
  selectedLanguage: string = '';
  TaxLabelToDisplayName: string = '';
  appSetting: AppSetting;
  seatPanelExpanded = false;
  upsellPanelExpanded = false;
  cartPanelExpanded = false;
  seatDetails: any[];
  bookingPackageUpdated: any;
  booking$;
  selectedBooking$: Observable<Booking>;
  cancellationFee;
  dispatchStatus: BookingDispatchStatus;
  bookingIds = [];
  overRideEventCategoryDetails: OverRideEventCategory[] = [];
  searchFilter: SearchBaseParam.SearchBaseFilter = new SearchBaseParam.SearchBaseFilter();
  packageSession: PackageSession[];
  allPackageSessions: PackageSession[];
  bookingPackageEventCategoryIds = [];
  isConfirmAndPayBtn: boolean = false;
  confirmationNavigationMessage: string = '';
  noOfSeats: number = 0;//Number of seats for booking
  editEmail: boolean = false;
  specialRequest: string = '';
  shippingMailAddress: string = '';
  emailAddress: string = '';
  emailForm: FormGroup;
  bookingMode: any;
  hasEmailError: boolean = false;
  seatRemovedTimer: any;
  seatRemovedTriggerPriviousTime: number = 0
  bookingPacksForRemoving: BookingCart[] = [];
  singlePackageModal: boolean = false;
  selectedSeat: any;
  eligiblePackages = [];
  packages: any;
  parentPackages = []
  packageDetails: any;

  parentRadioSelection = "";
  childRadioSelection = ""
  latestEventId;
  latestPackageId;
  isImg: boolean;
  purchasePolicyUrl: string;
  purchaseAgreementUrl: string;
  isImgLoaded: boolean = false;
  isMobileViewOpen: boolean = false;
  patchItem: PatchItems[] = [];

  loader$: Observable<ILoadingStatus>;
  isLoading: boolean = true;
  timeslots: TimeSlot[];
  selectedPrivateBox: GroupingBlockModel[] = [];
  bookingFlowOption: BOOKING_FLOW_OPTION;
  TIME_INDICATOR_OPTION = TIME_INDICATOR_OPTION;
  PANEL_TYPE = PANEL_TYPE;

  imagePath: string = environment.ApiUrl + environment.publishFolder;

  isPatchItemsAvailable: boolean = false;
  isAttendeeEdited:boolean = false;
  isAgent: boolean = false;
  clientLoginIsRequired: boolean = false;
  isTermsAndConditionChecked: boolean = false;
  bookingPaymentSchedule: PaymentSchedule[] = [];
  canAccess: boolean = false;
  get ErrorType() {
    return ErrorType;
  }

  closeSinglePackModal() {
    this.singlePackageModal = false
  }

  applySinglePackage() {
    let selectedPack = this.eligiblePackages.find(pack => pack.id === this.parentRadioSelection)

    if (this.childRadioSelection) {
      const selectedChildPack = selectedPack.linkedPackages?.find(pack => pack.linkedPackageType.id === this.childRadioSelection)
      if (selectedChildPack) selectedPack = selectedChildPack
    }

    this.packageDetails.seatDetails.forEach(seat => {
      if (seat.id === this.selectedSeat.id) {
        seat.package = selectedPack
      }
    })
    //    this.store.dispatch(new UpdateSeat(this.packageDetails.seatDetails));
    this.singlePackageModal = false
  }

  get repeatPolicies() {
    return (environment.PublicBookingSetting as PublicBookingSettings).repeatPolicy;
  }

  get sessionEndDate() {
    let translatedText = this.paymentSessionTime.sessionEndDate;
    let transDay = this.getTranslate(moment(this.paymentSessionTime.sessionEndDate).format('dddd'));
    let transMonth = this.getTranslate(moment(this.paymentSessionTime.sessionEndDate).format('MMMM'));
    translatedText = translatedText.replace(moment(this.paymentSessionTime.sessionEndDate).format('dddd'), transDay);
    translatedText = translatedText.replace(moment(this.paymentSessionTime.sessionEndDate).format('MMMM'), transMonth);
    translatedText = translatedText.replace(moment(this.paymentSessionTime.sessionEndDate).format('LT'), moment(this.paymentSessionTime.sessionEndDate).format('HH:mm'));
    return translatedText;
  }

  get displayDeliveryMethodList() {
    if (this.deliveryMethods && this.deliveryMethods.length > 0 && !(this.deliveryMethods.find(x => x.id === "0") && this.deliveryMethods.length == 1))
      return true;
    else
      return false
  }


  get getIsHaveToPayOrRefund() {
    let val = false;
    if (this.cartType === BOOKING_CART.EDIT_BOOKING && PBUtility.getBookingTotal(this.booking).toFixed(2) !== this.booking?.paidAmount.toFixed(2)) {
      val = true;
    }
    return val
  }

  get getFreezedBookingTotal() {
    return PBUtility.getBookingTotal(this.freezedBooking)

  }

  get getUpdatedBookingTotal() {
    let total = 0
    if (this.pendingPatch) {
      this.bookingDisplayCart.forEach(pkg => {
        pkg.cartPackages.forEach(pkg => {
          total += pkg.quantity * pkg.unitPrice
        })
        pkg.cartItems.forEach(itm => {
          if (itm.repeatPolicy === this.repeatPolicies.allowIndividualDays && itm.child.length > 0) {
            itm.child.forEach(i => {
              total += i.quantity * i.unitPrice
            })
          } else {
            total += itm.quantity * itm.unitPrice
          }
        })
      })
    } else {
      total = PBUtility.getBookingTotal(this.booking)
    }
    return total;
  }

  get getDueAmount() {
    let total = 0
    if (this.pendingPatch) {
      return this.booking.dueAmount.toFixed(2);
    } else {
      total = PBUtility.getBookingTotal(this.booking)
    }
    return (total - this.booking?.paidAmount).toFixed(2);
  }

  get getIsPaymentContinueDisable() {
    this.isPaymentContinueDisable = (this.cartType === BOOKING_CART.NEW_BOOKING && this.enableSpinner && !this.booking.deliveryMethod) || (this.cartType === BOOKING_CART.EDIT_BOOKING && PBUtility.getBookingTotal(this.booking) === this.booking?.paidAmount)
    this.isPaymentContinueDisable ? this.isPendingChanges = true : this.isPendingChanges = false;
    return this.isPaymentContinueDisable
  }

  get IS_HAVE_TO_PAY_DUE_AMOUNT() {
    this.isHaveToPayDueAmount = (PBUtility.getBookingTotal(this.booking) - this.booking?.paidAmount) > 0;
    this.isHaveToPayDueAmount ? this.isPendingChanges = true : this.isPendingChanges = false;
    return this.isHaveToPayDueAmount;
  }

  get isPaymentEnabled() {
    if (this.booking && this.booking.cancelled) {
      return false;
    }
    if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
      if (this.IS_HAVE_TO_PAY_DUE_AMOUNT && !this.pendingPatch) {
        return true;
      } else if (parseInt(this.getDueAmount) < 0 && !this.pendingPatch) {
        return true;
      } else if (this.getIsPaymentContinueDisable && !this.pendingPatch) {
        return false;
      } else if (!this.canEditBookingCart && this.pendingPatch) {
        return true;
      }
      return false
    }
    else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
      return true;
    }
    return false;
  }

  get disableReduceButton() {
    return this.cartType && this.cartType == BOOKING_CART.EDIT_BOOKING ?
      (this.booking && this.booking.deliveryMethod && this.selectedDeliveryMethod && this.booking.deliveryMethod.id === this.selectedDeliveryMethod.id) &&
        (new DeliveryMethod().initialize(this.selectedDeliveryMethod).postal ||
          new DeliveryMethod().initialize(this.selectedDeliveryMethod).collection)
        ? true : false
      : false;
  }

  get reduceButtonTooltip() {
    return this.disableReduceButton ? (environment.PublicBookingSetting as PublicBookingSettings).physicalTicketBookingCancellationMessage : ""
  }

  getTranslate(data: string) {
    this.translate.get("MONTHS_DAYS." + data).safeSubscribe(this, (res: string) => {
      data = data.replace(data, res);
    });
    return data;
  }

  getConfirmationMessage() {
    const message = PBUtility.getConfirmationMessage(this.cartType);
    if (message) {
      if (message.name) {
        this.translate.get("NAVIGATIONCONFIRMATIONMESSAGE." + message.name).safeSubscribe(this, (res: string) => {
          if (res && res != null) {
            this.confirmationNavigationMessage = res;
          }
        });
      } else if (message.value) {
        this.confirmationNavigationMessage = message.value
      }
    }
  }

  get isAnyPendingChanges(): boolean {
    if (this.cartType == this.BOOKING_CART.NEW_BOOKING) {
      return true;
    } else
      return this.cartType == this.BOOKING_CART.EDIT_BOOKING && this.booking?.dueAmount != this.freezedBooking?.dueAmount;
  }

  get getIsClientPaymentTermHasCredit(): boolean {
    if(this.isAgent && this.clientLoginIsRequired) {
      this.isConfirmAndPayBtn = false;
      if (this.bookingPaymentSchedule.length > 0 && this.bookingPaymentSchedule?.find(x => x.paymentTerm.id === this.booking.paymentTerm.id)){
        let paymentSchedule = this.bookingPaymentSchedule?.find(x => x.paymentTerm.id === this.booking.paymentTerm.id);
        paymentSchedule.paymentTerm.paymentTermDetails.sort((a,b) => a.sequance - b.sequance);
        if ((paymentSchedule.paymentTerm.paymentTermDetails[0].paymentDueDateID == 20 || paymentSchedule.paymentTerm.paymentTermDetails[0].paymentDueDate == 'Immediate') &&
            (paymentSchedule.paymentTerm.paymentTermDetails[0].invoiceDueDateID == 20 || paymentSchedule.paymentTerm.paymentTermDetails[0].invoiceDueDate == 'Immediate')) {
            this.isTermsAndConditionChecked = true;
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWindowWidth();
  }

  private checkWindowWidth(): void {
    //mobile view was displaying window width less than 577
    this.isMobileViewOpen = window.innerWidth < 577;
    this.cdr.detectChanges();  
  }

  constructor(
    private store: Store<any>,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private packageService: PublicPackageService,
    private bookingService: PublicBookingService,
    private _bookingService: BookingService,
    private translate: TranslateService,
    private sysOptions: SystemService,
    private pageLayoutService: PageLayoutService,
    private _publicBookingSelectService: PublicBookingSelectService,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private cdr: ChangeDetectorRef,
    private optimoTranslate: OptimoTranslate,
    private priceBandService: PriceBandService,
   ) {
     super();
    this.appSetting = environment.AppSetting as AppSetting;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_title: this.appSetting.PageTitle + ' | Cart',
          page_path: event.urlAfterRedirects,
          page_location: window.location.href
        })
      }
    });

    this.purchasePolicyUrl = (environment.PublicBookingSetting as PublicBookingSettings).phrases.purchasePolicyExitRedirectionUrl;
    this.purchaseAgreementUrl = (environment.PublicBookingSetting as PublicBookingSettings).phrases.purchaseAgreementExitRedirectionUrl;
    this.bookingSessionTimer$ = this.store.select(PBSelector.selectBookingSession);
    this.bookingPaymentTimer$ = this.store.select(PBSelector.selectPaymentSession);
    this.selectedSessions$ = this.store.select(PBSelector.selectedSessions);
    this.booking$ = this.store.select(PBSelector.selectBookingCartModel)
    this.selectedBooking$ = this.store.select(PBSelector.selectBooking)
    this.config$ = this.store.select('PBReducer');
    this.cart$ = this.store.select('cart');
    this.enableAvailability = (environment.PublicBookingSetting as PublicBookingSettings)?.enableAvailabilityInBookingCart;

    // This is for global page loader.
    this.loader$ = this.store.select("loader");
    this.loader$.safeSubscribe(this, (loader) => {
      if (loader.requestCounter == 0) {
        this.isLoading = loader.isPublicLoading
      } else {
        this.isLoading = true
      }
    });

    this.store.dispatch(new SetRedirectionPoint(''));


    // Delivery Email Address Validation.
    this.emailForm = this.formBuilder.group({
      email: [this.shippingMailAddress, [Validators.required, Validators.email]]
    });

    this.emailControl?.valueChanges.subscribe(() => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValidEmail = emailRegex.test(this.emailControl.value);
      this.hasEmailError = !isValidEmail && (this.emailControl.touched || this.emailControl.dirty);
    })


    this.pageSize = (this.paginationSetting && this.paginationSetting.pageSizeOptions.filter(pso => !pso.isDisable)?.length > 0) ?
      this.paginationSetting.pageSizeOptions.filter(pso => !pso.isDisable)[0].value : 0;

    this._publicBookingSelectService.getEventConfigurationTimeslots().safeSubscribe(this, (t) => {
      if (t && t.length > 0) {
        this.timeslots = t;
      }
    });

  }

  ngOnInit() {

    this.config$.safeSubscribe(this, (state: IPBReducer) => {
      this.PBReducer = state;
      this.cartType = this.PBReducer.bookingMode;
      if(this.PBReducer.PatchItems && this.PBReducer.PatchItems.length > 0) {
        this.patchItem = this.PBReducer.PatchItems;
        this.isPatchItemsAvailable = true;
      }
    });

    if (this.cartType === 1 && this.appSetting.PageTitle) {
      this.titleService.setTitle(this.appSetting.PageTitle + ' | Cart');
    } else if (this.appSetting.PageTitle) {
      this.titleService.setTitle(this.appSetting.PageTitle + ' | Order Details');
    }

    this.store.select(fromConfiguration.selectSelectedBlocks).safeSubscribe(this, (data) => {
      if (data?.length > 0) {
        this.selectedPrivateBox = data
      }
    })

    this.checkWindowWidth();

    this.cart$.safeSubscribe(this, (state) => {
      this.cart = state;
      this.isLoggedIn = state.contact != null && !!state.contact.firstName && state.contact.firstName !== '';
      this.clientLoginIsRequired = this.appSetting.ClientLoginIsRequired ? this.appSetting.ClientLoginIsRequired : false;
      //get login client was agent or not
      let menu: MenuSettings = this.appSetting.MenuSettings ? this.appSetting.MenuSettings.find(menu => menu.Id == 'PublicBooking') : null;
      if (menu) {
        this.isAgent = state.contact && state.contact?.client && state.contact.client?.clientCategoryId && !!menu?.BookingPermittedClientCategoryIds ?
                        menu?.BookingPermittedClientCategoryIds?.Value?.split(',').includes(state.contact.client?.clientCategoryId.toString()) : false
      }
      this.canAccess = this.clientLoginIsRequired ? this.isAgent : true;
      if (!(!!this.booking)) {
        if (this.shippingMailAddress == '') {
          this.emailAddress = state?.contact?.email;
          this.shippingMailAddress = state?.contact?.email;
        }
      }
      else {
        if (this.cartType === BOOKING_CART.NEW_BOOKING) {
          if (this.PBReducer && this.PBReducer?.booking) {
            const index = this.PBReducer?.booking?.deliveryCommunicationMethods?.findIndex(x => +x.communicationTypeID === 6)
            if (index && index != -1) {
              this.emailAddress = this.PBReducer.booking.deliveryCommunicationMethods[index].value
              this.shippingMailAddress = this.PBReducer.booking.deliveryCommunicationMethods[index].value
            }
          }
        }
      }

    });

    setTimeout(() => {
      this.translate.onLangChange.safeSubscribe(this, (event: LangChangeEvent) => {
        console.log("Language changed in the cart page");
        if (this.booking?.id && this.booking?.id != '' && this.booking?.id != null) {
          if (!this.selectedBookingId) {
            this.selectedBookingId = this.booking.id;
          }
          this.store.dispatch(new UpdateTranslatedSelectedBooking());
          if (!this.canGetDeliveryMethod) {
            this.getDeliveryMethod(); // get translated delivery methods
          }
          this.getConfirmationMessage();
          this.onBookingPackageSearch();
          if (this.selectedBookingPackageID != '' && this.expandedBookingPackageIndex != -1)
            this.openExpansion(this.expandedBookingPackageIndex, this.selectedBookingPackageID);
        }
      });
    }, 5000);   // TODO: Need to check why this is added with SetTimeout

    this.store.select(getSelectedLanguage).safeSubscribe(this, storedLanguage => {
      this.selectedLanguage = storedLanguage;
    });

    this.bookingPaymentTimer$.safeSubscribe(this, (config) => {
      if (config) {
        this.bookingPaymentTimer = config
      }
    });

    this.store.select(PBSelector.selectBookingId).subscribe((selectBooking: ISelectBooking) => {
      if (selectBooking) {
        this.selectedBookingId = selectBooking.id ? selectBooking.id : '';
        this.bookingPackagesLength = selectBooking.bookingpackageCount ? selectBooking.bookingpackageCount : 0;
      }
      if ((this.selectedBookingId != undefined || this.selectedBookingId != '') && !this.isLoggedIn && (this.cartType == undefined || this.cartType === BOOKING_CART.EDIT_BOOKING)) {
        this.store.dispatch(new ResetPublicWebState());

        if (this.appSetting.AnyPortalSignOutRedirectToSignIn) {
          var ul = environment.ApiUrl + "#/client/login";
          window.open(ul, "_self");
        }
        else {
          if (environment.PublicPortalHomeUrl.trim()) {
            window.location.href = environment.PublicPortalHomeUrl;
          } else {
            this.router.navigate(["/event-category"]);
          }
        }
      }
    });

    this.route.queryParamMap.safeSubscribe(this, (params) => {
      if (params.get('TriggerAttendeeList') == '1') {
        this.triggerAttendeeCapture_After_Sign_in = true;
      }
      if (params.get("payment") == "failed") {
        this.openSnackBarError(["Your payment has been failed. Please try again"]);
      }
    });

    this.bookingSessionTimer$.safeSubscribe(this, (config) => {
      if (config) {
        this.paymentSessionTime = config;
        const sessionDate = new Date(this.paymentSessionTime.sessionEndDate);
        this.paymentSessionTime.sessionEndDate = moment(sessionDate).format('LLLL');
      }
    });

    // this.bookingService.getCancellationReasons().safeSubscribe(this, (res) => {
    //   this.cancellationReason = res
    // })

    this.store.select(PBSelector.selectPatchPreviewId).safeSubscribe(this, (d) => {
      this.patchPreviewId = d;
    });

    this._publicBookingSelectService.getValue().safeSubscribe(this, (data) => {
      if (data.length > 0) {
        this.bookingPackages = data;
      }
    })

    this.store.select(PBSelector.selectBookingMode).safeSubscribe(this, (state: any) => {
      this.bookingMode = state;
    });

    this._publicBookingSelectService.getdisplayedFreezedBookingPackages().safeSubscribe(this, (bpacks) => {
      if (bpacks.length > 0) {
        this.freezedBookingPackages = bpacks;
      }
    });

    if (this.PBReducer.booking) {
      this.PBReducer.booking.bookingPackages.sort((a, b) => Number(a.id) - Number(b.id));
      this.latestPackageId = this.PBReducer.booking.bookingPackages[this.PBReducer.booking.bookingPackages.length - 1]?.package?.id;
      this.latestEventId = this.PBReducer.booking.bookingPackages[this.PBReducer.booking.bookingPackages.length - 1]?.package?.publicEvent?.id;
      if (this.latestEventId) {
        this.store.select(PBSelector.selectedPublicEventById(this.latestEventId.toString())).safeSubscribe(this, (event : IPublicEvent) => {
          if (event) {
            this.bookingFlowOption = event.bookingFlowOptionId;
          }
          if (!this.bookingFlowOption) {
            this.store.select(PBSelector.selectedBookingFlowOption).safeSubscribe(this, option => {
              if (option) {
                this.bookingFlowOption = option;
              }
            })
          }
        })
      }
    }
  }





  ngAfterViewInit(): void {

    this.selectedSessions$.safeSubscribe(this, (session) => {
      this.bookingSessions = session;
    });

    if (this.bookingPackagesLength > 0 && this.cartType == this.BOOKING_CART.EDIT_BOOKING) {
      if (this.bookingPackages.length == 0 || this.bookingPackages?.filter(bp => !!bp.name)?.length == 0) {
        this.onBookingPackageSearch();
      }
    }
    this.store.select(PBSelector.selectUpdatedBookingPackage).safeSubscribe(this, (d) => {
      if (d) {
        this.updatedBookingPackages = d;
      }
    });

    this.booking$.safeSubscribe(this, (data: any) => {

      if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
        this.booking = data.booking;
      } else {
        this.booking = this.setBooking(data.booking);
      }
      this.freezedBooking = data.freezedBooking;

      setTimeout(() => {
        if (this.booking?.id)
          this.fromHomePage = false;
      }, 20);

      if ((!this.booking || this.booking.id !== this.selectedBookingId) && this.selectedBookingId && !this.editBookingSelectCalled) {

        this.editBookingSelectCalled = true;

        this.bookingService.selectBooking(this.selectedBookingId, true, true).safeSubscribe(this, (d) => {
          if (!d.isError && d.data) {
            this.booking = this.setBooking(d.data);
            this.store.dispatch(new SetBooking(d.data))
            this.freezedBooking = d.data;
            this.store.dispatch(new SetFreezedBooking(d.data))
            this.store.dispatch(new SetPatchPreviewId(d.data.patchPreviewId))
            if (this.bookingPackagesLength == 0) {
              this.bookingPackagesLength = d.data.bookingPackages.length;
              this.onBookingPackageSearch();
            }
          }
          else {
            this.openSnackBarError(['booking undefined'])
            var ul = environment.ApiUrl + "#/bookings";
            window.open(ul, "_self");
          }
          setTimeout(() => {
            this.enableSpinner = false;
          }
            , 1000);
        }, (error) => {
          this.store.dispatch(new SetBookingType(null));
          this.continueShopping()
          this.openSnackBarError(["booking undefined"]);
        });
      } else this.editBookingSelectCalled = true;
      if (this.booking != data.booking && this.triggerPayment) {
        this.goToCheckout()
      }
      if (this.booking) {
        if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
          this.canEditBookingCart = false;
          const index = this.booking.deliveryCommunicationMethods.findIndex(x => +x.communicationTypeID === 6)
          if (index != -1) {
            this.emailAddress = this.booking.deliveryCommunicationMethods[index].value
            this.shippingMailAddress = this.booking.deliveryCommunicationMethods[index].value;
          }
          this.noOfSeats = 0;
          this.bookingPackages?.forEach(pack => {
            this.noOfSeats += pack.attendees;
          });
        } else {
          //for new booking
          let bPackages = this.booking.bookingPackages.filter(bp => !!bp.name);
          if (bPackages.length > 0) {
            this.bookingPackages = bPackages;
            this._publicBookingSelectService.setValue(this.bookingPackages);
          }
          this.noOfSeats = 0;
          this.booking?.bookingPackages.forEach(pack => {
            this.noOfSeats += pack.attendees;
          });
        }
        this.booking.bookingPackages.forEach((b: any) => {
          this.bookingPackageIds.push(b.id);
          if (b && b.attendeeCaptureProfile && !this.triggerAttendeeCapture) {
            if (this.cartType === BOOKING_CART.NEW_BOOKING) {
              this.triggerAttendeeCapture = b.attendeeCaptureProfile.promptAtCheckout ? true : b.attendeeCaptureProfile.promptAtBooking ? true : false;
            } else {
              this.triggerAttendeeCapture = b.attendeeCaptureProfile.promptAfterBooking;
            }
          }
        });

        if (this.triggerAttendeeCapture_After_Sign_in) {
          this.triggerAttendeeCapture_After_Sign_in = false;
          this.router.navigate(['booking-cart']);
          this.openAttendeeList();
        }
        this.bookingTotalAmount = PBUtility.getBookingTotal(this.booking);
        if (!this.canGetDeliveryMethod && this.booking.deliveryMethod) {
          this.currentDeliveryMethod = _.cloneDeep(this.booking.deliveryMethod);
        }
        if (this.canGetDeliveryMethod) {
          if (this.cartType == BOOKING_CART.EDIT_BOOKING) {
            this.canGetDeliveryMethod = false;
            this.deliveryMethods = this.PBReducer.eligibleDeliveryMethod;
            if (this.deliveryMethods?.length > 0) {
              this.hasEligibleDeliveryMethods = true;
              this.selectedDeliveryMethod = this.deliveryMethods.find(x => this.booking?.deliveryMethod && x.id === this.booking?.deliveryMethod?.id);
              this.currentDeliveryMethod = _.cloneDeep(this.selectedDeliveryMethod);
              if (!this.selectedDeliveryMethod) {
                this.selectedDeliveryMethod = this.deliveryMethods.find(x => x.defaultDeliveryMethod);
                this.patchDeliveryMethod()
              }
              this.selectedDeliveryMethodID = this.selectedDeliveryMethod?.id;
              this.bookingTotalAmount = PBUtility.getBookingTotal(this.booking);
            } else {
              this.hasEligibleDeliveryMethods = false;
              this.patchDeliveryMethod();
              this.bookingTotalAmount = PBUtility.getBookingTotal(this.booking);
            }
          } else {
            this.getDeliveryMethod();
          }
        }
        if (this.bookedPackages && this.bookedPackages) {
          if (this.previewsBookingPackageForCheck == null) {
            this.previewsBookingPackageForCheck = _.cloneDeep(this.bookingDisplayCart);
          }
        }

        //check whether need to have to pay due amount and visible the confirm and button
        this.isHaveToPayDueAmount = (PBUtility.getBookingTotal(this.booking) - this.booking?.paidAmount) > 0;
        this.isHaveToPayDueAmount ? this.isPendingChanges = true : this.isPendingChanges = false;

        if ((this.countPendingInvoices > 0 || this.isPaymentEnabled) && this.PaymentPortalUrl && !(this.deliveryMethods && this.deliveryMethods.length == 0) && this.isBookingEditable && !this.clientLoginIsRequired) {
          this.isConfirmAndPayBtn = true
        } else {
          this.isConfirmAndPayBtn = false
        }
        this.getConfirmationMessage();
      }
      if (data.bookedPackages && data.bookedPackages.length > 0 && this.booking) {
        this.bookedPackages = data.bookedPackages;
        if (this.bookedPackages.filter(p => !!p.name).map(x => x.id).length > 0) {
          this.booking = this.setBooking(this.booking)
          let bookedEvent = [...new Map(this.bookedPackages?.map(x => [x.publicEvent?.id, x.publicEvent])).values()];
          if (!bookedEvent.includes(undefined)) {
            this.bookedEvents = bookedEvent;//[...new Map(this.bookedPackages?.map(x =>[x.publicEvent?.id, x.publicEvent])).values()] ;
            if (this.cartType == BOOKING_CART.NEW_BOOKING) {
              this.bookingDisplayCart = this.createDisplayData(
                this.booking.bookingPackages,
                this.bookedPackages
              );
              if (this.freezedBooking) {
                this.freezedBookingCarts = this.createDisplayData(
                  this.freezedBooking.bookingPackages,
                  this.bookedPackages
                );
              }
            } else if (this.cartType == BOOKING_CART.EDIT_BOOKING) {
              if (this.bookingPackages.length == this.freezedBookingPackages.length) {
                if (this.bookingPackages && !this.validatedBooking) {
                  this.bookingDisplayCart = this.createDisplayData(
                    this.bookingPackages,
                    this.bookedPackages
                  );
                }
                if (this.freezedBookingPackages && !this.validatedBooking) {
                  this.freezedBookingCarts = this.createDisplayData(
                    this.freezedBookingPackages,
                    this.bookedPackages
                  );
                }
              }
            }
            //set the seat details in bookingCart
            setTimeout(() => {
              this.enableSeatDetails();
            }, 2000);
            //set over ride the packages which event layout configured a word as 'OVERRIDE_BOOKING_APP'
            if (this.isBookingEditable) {
              this.setEditableOverRidePackages();
            }
          }
        }
      }
    });

    this.TaxLabelToDisplayName = environment.SystemOption.taxLabel;

    if (this.cartType == BOOKING_CART.EDIT_BOOKING) {
      this.getInvoicesAndDocuments();
    } else if (this.cartType == BOOKING_CART.NEW_BOOKING && this.isAgent && this.clientLoginIsRequired) {
      this.getBookingPaymentSchedule();
    }
  }

  getInvoicesAndDocuments() {
    this._bookingService.InvoiceSearch(this.selectedBookingId, new BaseParam()).safeSubscribe(this, res => {
      if (!res.isError) {
        if (res.data.length > 0) {
          this.bookingInvoices = res.data;
          res.data.forEach((element: any) => {
            if (element.Outstanding > 0) {
              this.countPendingInvoices += 1
            }
          });
        }
      }
    });

    this._bookingService.BookingDocumentSearch(this.selectedBookingId).safeSubscribe(this, res => {
      if (!res.isError) {
        if (res.data.length > 0) {
          this.bookingDocuments = [];
          res.data.forEach((doc) => {
            if (doc != null && !(doc?.name?.startsWith('INVOICE'))) {
              this.bookingDocuments.push(doc);
            }
          })
        }
      }
    });
  }

  getBookingPaymentSchedule() {
    this._bookingService.GetBookingPayamentSchedule(this.booking.id).safeSubscribe(this, res => {
      if(res) {
        this.bookingPaymentSchedule = res;
        console.log(this.bookingPaymentSchedule[0]);
      }
    });
  }

  getBgColor(available, capacity) {
    let backgroundColor = '';
    const availablePercentage = Math.ceil(available / capacity * 100);
    const thresholds = (environment.PublicBookingSetting as PublicBookingSettings).availabilityConfiguration.availabilityThresholds;

    if (thresholds.length > 0) {
      thresholds.forEach(threshold => {
        if (availablePercentage >= threshold.availabilityFrom && availablePercentage <= threshold.availabilityTo) {
          backgroundColor = threshold.thresholdColor;
        }
        if (availablePercentage > 100 && threshold.availabilityTo == 100) {
          backgroundColor = threshold.thresholdColor;
        }
      })
    }
    return backgroundColor != '' ? backgroundColor : '#000000' ;
  }

  assignAvailability(packages: Package, cart: BookingCart) {
    if (packages.packageSessions && packages?.packageSessions.length == 1) {
      cart.cartPackages[0].availability = packages?.packageSessions[0].availability;
      cart.cartPackages[0].originalAvailability = packages?.packageSessions[0].availability;
      cart.cartPackages[0].capacity = packages?.packageSessions[0].capacity;
      cart.cartPackages[0].backgroundColor = this.getBgColor(packages?.packageSessions[0].capacity, packages?.packageSessions[0].capacity);
    } else if (packages.packageSessions && packages?.packageSessions.length > 1) {
      let index = packages?.packageSessions.findIndex(x => x?.packageDate?.eventDate?.startTime == cart.startDate && x?.packageDate?.eventDate?.endTime == cart.endDate);
      if (index != -1) {
        cart.cartPackages[0].availability = packages?.packageSessions[index].availability;
        cart.cartPackages[0].originalAvailability = packages?.packageSessions[index].availability;
        cart.cartPackages[0].capacity = packages.packageSessions[index].capacity;
        cart.cartPackages[0].backgroundColor = this.getBgColor(packages.packageSessions[index].availability, packages.packageSessions[index].capacity);
      } else {
        let index = packages?.packageSessions.findIndex(x => moment(x.startTime).toLocaleString() == moment(cart.startDate).toLocaleString() && moment(x.endTime).toLocaleString() == moment(cart.endDate).toLocaleString());
        if (index != -1) {
          cart.cartPackages[0].availability = packages?.packageSessions[index].availability;
          cart.cartPackages[0].originalAvailability = packages?.packageSessions[index].availability;
          cart.cartPackages[0].capacity = packages.packageSessions[index].capacity;
          cart.cartPackages[0].backgroundColor = this.getBgColor(packages.packageSessions[index].availability, packages.packageSessions[index].capacity);
        }
      }
    }
  }

  // toggleExpand() {
  //   this.expanded = !this.expanded
  // }

  createDisplayData(bookingPackages: BookingPackages[], bookedPackages: Package[]) {
    let bookingDisplayCart = [];
    this.isBookingEditable =
      (this.appSetting.BookingConfig.BookingEditDisableStates.split(',').includes(this.booking.bookingStatus.id) || this.isPastBooking(this.booking.eventEndDate))
        ? false
        : true;

    if (bookingPackages && bookedPackages?.filter(p => !!p.name).map(x => x.id).length > 0) {
      bookingPackages.forEach((bookingPackage) => {
        let bookedPack = bookedPackages.find(pack => pack.id == bookingPackage.package.id);
        const tempBookingDisplayCart = _.cloneDeep(new BookingCart(bookingPackage, bookedPack,this.booking.showPriceWithTax));
        if (tempBookingDisplayCart.cartPackages[0].isLinkedPackage) {
          if (!tempBookingDisplayCart.cartPackages[0].isChild) {
            const data = bookedPackages.find(
              (x) => x.id === tempBookingDisplayCart.cartPackages[0].id
            );
            if (data) {
              tempBookingDisplayCart.cartPackages[0].name = data.linkedPackageType.name;
              tempBookingDisplayCart.cartPackages[0].linkedPackageTypeName = data.linkedPackageType.name;
              tempBookingDisplayCart.image =
                !!data.packageMultimedia &&
                  data.packageMultimedia.length > 0 &&
                  data.packageMultimedia[0].documentFileURL
                  ? data.packageMultimedia[0].documentFileURL
                  : this.noImageFound();
              this.assignAvailability(data, tempBookingDisplayCart);

            }
          } else if (tempBookingDisplayCart.cartPackages[0].isChild) {
            const data = bookedPackages.find(
              (x) => x.id === tempBookingDisplayCart.cartPackages[0].primaryPkgId
            );
            if (data) {
              const linkedPkgData = data.linkedPackages.find(
                (x) => x.id === tempBookingDisplayCart.cartPackages[0].id
              );
              if (linkedPkgData) {
                tempBookingDisplayCart.cartPackages[0].name = linkedPkgData.linkedPackageType.name;
                tempBookingDisplayCart.cartPackages[0].linkedPackageTypeName = linkedPkgData.linkedPackageType.name;
                tempBookingDisplayCart.image = linkedPkgData.packageMultimedia
                  ? linkedPkgData.packageMultimedia[0].documentFileURL
                  : this.noImageFound();
                this.assignAvailability(linkedPkgData, tempBookingDisplayCart);
              }
            }
            if (data && data.packageDeliveryMethods && data.packageDeliveryMethods.length > 0) {
              tempBookingDisplayCart.packageDeliveryMethods = data.packageDeliveryMethods;
            }
          }
        } else if (tempBookingDisplayCart.cartPackages && tempBookingDisplayCart.cartPackages.length > 0) {
          const data = bookedPackages.find(x => x.id === tempBookingDisplayCart.cartPackages[0].id);
          if (data) {
            tempBookingDisplayCart.cartPackages[0].linkedPackageTypeName = data.linkedPackageType.name;
          }
          if (data && data.packageMultimedia && data.packageMultimedia.length > 0) {
            tempBookingDisplayCart.image = data.packageMultimedia[0].documentFileURL
              ? data.packageMultimedia[0].documentFileURL
              : this.noImageFound();
          }
          if (data) {
            this.assignAvailability(data, tempBookingDisplayCart);
          }
        }

        let pack = bookedPackages.findIndex(x => x.id == bookingPackage.package.id);
        if(pack != -1){
          tempBookingDisplayCart.isShowAddExtra = bookedPackages[pack]?.upsellItem?.findIndex(x => x.availabilityStatus?.toString() === "Indeterminable") != -1 ? true :
              bookedPackages[pack]?.upsellItem?.findIndex(x => x.availabilityStatus > 0) != -1 ? true : false;
          tempBookingDisplayCart.cartItems?.forEach(item => {
            if (!item.repeatPolicy) {
              item.repeatPolicy = !bookedPackages[pack].singleDayPackage ? '4': null;
            }
            let ind = bookedPackages[pack]?.upsellItem?.findIndex(x => x.name == item?.name);
            if (ind != -1) {
              if (bookedPackages[pack]?.upsellItem[ind].itemAvailabilityPublic?.length > 0) {
                item.availability = bookedPackages[pack]?.upsellItem[ind]?.availabilityStatus;
                item.originalAvailability = bookedPackages[pack]?.upsellItem[ind]?.availabilityStatus;
                item.capacity = bookedPackages[pack]?.upsellItem[ind]?.itemAvailabilityPublic[0].capacity;
                item.backgroundColor = this.getBgColor(item.availability, item.capacity);
              }
            }
          });
        }
        bookingDisplayCart.push(tempBookingDisplayCart);
      });
      bookingDisplayCart = this.mergeLinkedBookingPackages(bookingDisplayCart);
      bookingDisplayCart.forEach((bookingPkg) => {
        bookingPkg.isQuestionnaire = this.checkQuestion(bookingPkg.cartItems);
      });
      if (bookingDisplayCart.length > 1) {
        let otherbookingDisplayCart = [];
        let zerobookingDisplayCart = [];

        bookingDisplayCart.forEach(function (data) {
          if (data.totalPrice == 0) {
            zerobookingDisplayCart.push(data);
          }
          else {
            otherbookingDisplayCart.push(data);
          }
        });
        bookingDisplayCart = otherbookingDisplayCart.concat(zerobookingDisplayCart);
      }
      bookingDisplayCart = _.cloneDeep(bookingDisplayCart);
      bookingDisplayCart = this.setQuestionnaireValue(bookingDisplayCart);
    }
    return _.cloneDeep(bookingDisplayCart);
  }

  noImageFound() {
    return 'https://cdn-5fcbf5c4c1ac1a221c18568d.closte.com/wp-content/themes/ryse/assets/images/no-image/No-Image-Found-400x264.png';
  }

  getGlobalEditSeatEnable() {
    let isGlobalEditSeatEnable = false;
    this.bookingDisplayCart?.forEach(cart => {
      if (!isGlobalEditSeatEnable) {
        isGlobalEditSeatEnable = !this.isEditablePack(cart.cartPackages[0]);
      }
    });
    return isGlobalEditSeatEnable;
  }

  getDeliveryMethod() {
    this.canGetDeliveryMethod = false;
    this.bookingService.getBookingDeliveryMethods(this.booking.id).subscribe((res: any[]) => {
      if (!!res) {
        this.deliveryMethods = res.sort((a, b) => a.order - b.order);
        if (this.deliveryMethods?.length > 0) {
          this.hasEligibleDeliveryMethods = true;
          this.selectedDeliveryMethod = this.deliveryMethods.find(x => this.booking.deliveryMethod && x.id === this.booking.deliveryMethod.id);
          this.currentDeliveryMethod = _.cloneDeep(this.selectedDeliveryMethod);
          if (!this.selectedDeliveryMethod) {
            if (this.deliveryMethods.find(x => x.defaultDeliveryMethod)) {
              this.selectedDeliveryMethod = this.deliveryMethods.find(x => x.defaultDeliveryMethod);
            } else {
              this.selectedDeliveryMethod = this.deliveryMethods[0];
            }
            this.patchDeliveryMethod()
          }
          this.selectedDeliveryMethodID = this.selectedDeliveryMethod.id;
          this.bookingTotalAmount = PBUtility.getBookingTotal(this.booking);
        } else {
          this.hasEligibleDeliveryMethods = false
          this.patchDeliveryMethod();
          this.bookingTotalAmount = PBUtility.getBookingTotal(this.booking);
        }

      }
    }, (error) => {
      //show error
      this.hasEligibleDeliveryMethods = false
      this.openSnackBarError(["booking doesn't have eligible delivery method"]);
    });
  }

  groupSeatAllocation(seatDetails) {
    let seats = [];
    let seatsD = '';
    seatDetails = seatDetails?.sort((a, b) => (a.eventBlock?.id > b.eventBlock?.id) ? 1 : (b.eventBlock?.id > a.eventBlock?.id) ? -1 : 0);
    const blocks = [...new Set(seatDetails.map((item) => item?.eventBlock?.id))]; //unique Block
    blocks.forEach(block => {
      seatsD = seatsD + ' ' + 'Block: ' + block + ' ' + 'Seats: ';
      let blockSeats = seatDetails.filter(s => s?.eventBlock?.id == block); //block Data
      let rows = [...new Set(blockSeats.map((item) => item?.seatRowName))]; //Unique Row
      rows.forEach(row => {
        let rowSeats = blockSeats.filter(s => s?.seatRowName == row);
        rowSeats = rowSeats.sort((a, b) => (a.seatColumnNo > b.seatColumnNo) ? 1 : (b.seatColumnNo > a.seatColumnNo) ? -1 : 0);
        let seStart, seEnd = '';
        for (let index = 0; index < rowSeats.length; index++) {
          if (index == 0) {
            seStart = rowSeats[index].seatNumber;
          } else {
            let sequenceNumber = (rowSeats[index - 1].seatColumnNo + 1) == rowSeats[index].seatColumnNo;
            if (sequenceNumber) {
              seEnd = rowSeats[index].seatNumber;
            } else {
              if (seEnd != '')
                seatsD = seatsD + '' + seStart + '-' + seEnd + ', ';
              else
                seatsD = seatsD + '' + seStart + ', ';
              seEnd = '', seStart = '';
              seStart = rowSeats[index].seatNumber;
            }
          }
          if (rowSeats.length - 1 == index) {
            if (seEnd != '')
              seatsD = seatsD + '' + seStart + '-' + seEnd + ', ';
            else
              seatsD = seatsD + '' + seStart + ', ';
            seEnd = '', seStart = '';
          }
        }
      });
      seatsD = seatsD.slice(0, seatsD.length - 2);
      seats.push(seatsD);
      seatsD = '';
    });
    return seats;
  }

  editBookingCard() {
    this.validationError = [];
    this.bookingDisplayCart = this.createDisplayData(this.bookingPackages, this.bookedPackages);
    this.enableSeatDetails();
    this.cardUpdated = false;
    this.canEditBookingCart = !this.canEditBookingCart;
  }

  gotomyBooking(confirm = false) {
    this.message = this.optimoTranslate.transform('NAVMODEL.Are you sure you want to navigate from the booking cart page?')
    this.message += '<br>' + this.optimoTranslate.transform('NAVMODEL.If you click ');
    this.message += '<strong>"' + this.optimoTranslate.transform('NAVMODEL.Yes, Navigate') + '"</strong>';
    this.message += ' ' + this.optimoTranslate.transform('NAVMODEL.any changes you have made will be discarded.');

    this.heading = this.optimoTranslate.transform('NAVMODEL.Navigation Confirmation');
    this.yesText = this.optimoTranslate.transform('NAVMODEL.Yes, Navigate');
    this.noText = this.optimoTranslate.transform('NAVMODEL.Close');
    if (this.getIsHaveToPayOrRefund) {
      if (this.cartType = BOOKING_CART.EDIT_BOOKING) {
        if (this.booking?.dueAmount != this.freezedBooking?.dueAmount && !confirm) {
          const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
            data: {
              heading: this.heading,
              message: this.message,
              yesText: this.yesText,
              noText: this.noText
            },
            panelClass: [],
            height: 'auto',
            width: 'auto',
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res == true) {
              this.clearSession();
              var ul = environment.ApiUrl + "#/bookings";
              window.open(ul, "_self");
            }
          });
        } else {
          this.store.dispatch(new ResetPublicWebState());
          var ul = environment.ApiUrl + "#/bookings";
          window.open(ul, "_self");
        }
      } else {
        if (confirm) {
          this.store.dispatch(new ResetPublicWebState());
          var ul = environment.ApiUrl + "#/bookings";
          window.open(ul, "_self");
        } else {
          const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
            data: {
              heading: this.heading,
              message: this.message,
              yesText: this.yesText,
              noText: this.noText
            },
            panelClass: [],
            height: 'auto',
            width: 'auto',
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res == true) {
              this.clearSession();
              var ul = environment.ApiUrl + "#/bookings";
              window.open(ul, "_self");
            }
          });
        }
      }
    } else {
      this.store.dispatch(new ResetPublicWebState());
      var ul = environment.ApiUrl + "#/bookings";
      window.open(ul, "_self");
    }
  }

  addnewBooking(confirm = false) {
    if (this.getIsHaveToPayOrRefund) {
      if (this.cartType = BOOKING_CART.EDIT_BOOKING) {
        if (this.booking?.dueAmount != this.freezedBooking?.dueAmount && !confirm) {
          this.conformationModel = "addnewbookingModel";
        } else {
          this.store.dispatch(new ResetPublicWebState());
          window.location.href = environment.PublicWebPortalUrl
        }
      } else {
        if (confirm) {
          this.store.dispatch(new ResetPublicWebState());
          window.location.href = environment.PublicWebPortalUrl
        } else {
          this.conformationModel = "addnewbookingModel";
        }
      }
    } else {
      this.store.dispatch(new ResetPublicWebState());
      window.location.href = environment.PublicWebPortalUrl
    }
  }

  getPackageChangesForEditBooking(bookingPackage: BookingCart) {
    let changeCount = 0
    let items = [];
    const freezedBookingCart = this.freezedBookingCarts?.find(x => x.bookingPackageId === bookingPackage.bookingPackageId)
    if (freezedBookingCart) {
      freezedBookingCart.cartPackages.forEach(pk => {
        const previewsBookingPackage = this.bookingPackages.find(x => x.id === pk.bookingPackageId);
        if (previewsBookingPackage) {
          items = items.concat(previewsBookingPackage?.bookingItems);
        }
        const b = bookingPackage.cartPackages.find(x => x.bookingPackageId === pk.bookingPackageId)
        if (b && previewsBookingPackage && b.quantity != previewsBookingPackage.quantity) changeCount++;
      });
      freezedBookingCart.cartItems.forEach(ci => {
        const mainItem = bookingPackage.cartItems.find(x => x.bookingItemId === ci.bookingItemId)
        if (ci.child?.length > 0 && mainItem.child?.length > 0) {
          ci.child.forEach(cartItm => {
            const previewsBookingItem = items.find(x => x.id === cartItm.bookingItemId)
            const childItem = mainItem.child.find(x => x.id === cartItm.id) // need to change after the Krish changes
            if (childItem && previewsBookingItem && childItem.quantity != previewsBookingItem.quantity) changeCount++;
          })
        } else {
          const previewsBookingItem = items.find(x => x.id === ci.bookingItemId)
          if (mainItem && previewsBookingItem && mainItem.quantity != previewsBookingItem.quantity) changeCount++;
        }
      });
    } else if (parseInt(bookingPackage.id) < 0) { // new Booking Package added on the edit booking
      bookingPackage.cartPackages.forEach(pk => {
        const previewsBookingPackage = this.bookingPackages.find(x => x.id === pk.bookingPackageId);
        if (previewsBookingPackage) {
          items = items.concat(previewsBookingPackage?.bookingItems);
        }
        if (pk && previewsBookingPackage && pk.quantity != previewsBookingPackage.quantity) changeCount++;
      });
      bookingPackage.cartItems.forEach(ci => {
        if (ci.child?.length > 0 && ci.child?.length > 0) {
          ci.child.forEach(cartItm => {
            const previewsBookingItem = items.find(x => x.id === cartItm.bookingItemId)
            const childItem = ci.child.find(x => x.id === cartItm.id) // need to change after the Krish changes
            if (childItem && previewsBookingItem && childItem.quantity != previewsBookingItem.quantity) changeCount++;
          })
        } else {
          const previewsBookingItem = items.find(x => x.id === ci.bookingItemId)
          if (ci && previewsBookingItem && ci.quantity != previewsBookingItem.quantity) changeCount++;
        }
      });
    }
    if (changeCount > 0) {
      this.pendingPatch = true
    }
    return changeCount
  }


  enableSeatDetails() {
    if (!this.isSeatsUpdate && this.bookingDisplayCart && this.bookingDisplayCart?.length > 0) {
      for (let index = 0; index < this.bookingDisplayCart.length; index++) {
        let pack = this.bookingDisplayCart[index];
        pack.isSeatAvailable = this.checkAvailable(pack);
        if (pack.isSeatAvailable) {
          let seatDetails = this.openSeatDetail(pack, 'package');
          if (this.enableNewDesignForBookingCartPage) {
            pack.seats = seatDetails;
            const privateBlockIds = this.selectedPrivateBox?.map(pvBox => pvBox.block.id)
            pack.seats?.forEach(seat => {
              if (privateBlockIds?.includes(`block_${seat?.eventBlock?.id}`) && seat.eventBlock) {
                seat.eventBlock.private = true
              }
            })
            pack.hasEditableSeats = pack.seats.every(s => s.eventBlock?.private === true) ? false : true;
            let bookedPackage = this.bookedPackages.find(bp => bp.id == this.bookingPackages[index].package.id);
            this.bookingDisplayCart.forEach(bookedPack => {
              bookedPack.cartPackages.forEach(pack => {
                if (pack.id === bookedPackage.id) {
                  bookedPack.attendees = bookedPackage.attendees
                }
              })
            })
          } else {
            pack.seats = this.groupSeatAllocation(seatDetails);
          }
        }
      }
    }
  }

  cancelBooking(cartType = null) {
    if (cartType == BOOKING_CART.NEW_BOOKING) {
      if (this.bookingDisplayCart.length == 1 && !this.isCancelModalOpen) {
        //document.getElementById('clearBasketBtn')?.click()
        this.isCancelModalOpen = true;
        return 0;
      } else {
        this.isCancelModalOpen = false;
        this.enablePublicLoader = true;
        this.bookingService.cancelBooking(this.booking).safeSubscribe(
          this,
          (res) => {
            // Temp fix
            if (res.body && res.body.isError) {
              this.openSnackBarError(res.body.errors.map(x => x.detail));
            } else {
              this.booking = this.setBooking(res.body.data);
              this.enablePublicLoader = false;
              this.clearSession();
            }
          },
          (error) => {
            this.enablePublicLoader = false;
            this.openSnackBarError(error.body.errors.map(x => x.detail));
          }
        );
      }
    } else if (cartType == BOOKING_CART.EDIT_BOOKING) {
      //TO DO (NEED TO IMPLEMENT THE EDIT BOOKING) ITS JUST TEMPORARY
      this.booking.cancellationReasonId = this.selectedCancellation && this.selectedCancellation.id ? this.selectedCancellation.id : null;
      this.booking.cancellationNote = this.customCancellationReason ? this.customCancellationReason : null;
      this.bookingService.cancelBooking(this.booking).safeSubscribe(
        this,
        (res) => {
          if (res.body && res.body.isError) {
            this.openSnackBarError(res.body.map(x => x.detail));
          } else {
            const payedAmount = res.body.data.paidAmount;
            const refound = payedAmount - this.cancellationFee;
            if (this.enableNewDesignForBookingCartPage) {
              this.clearSession();
            }
            else {
              if (refound > 0)
                this.goToRefund(refound)
              else
                this.clearSession();
            }
          }
        },
        (error) => {
          this.openSnackBarError(error.body.errors.map(x => x.detail));
        }
      );
    }
  }

  applyEditChange(bookingPackage = null) {
    let id = bookingPackage ? bookingPackage.id : null;
    this.isSeatsUpdate = true;
    this.checkBookingPatchPreview(id);
  }

  cancelChanges(bookingPackage: any) {
    this.isSeatsUpdate = false;
    this.pendingPatch = false;
    //below will replace whole cart with the freezed cart
    if (bookingPackage && bookingPackage.id) {
      //get the package from freezed cart and replace it with the booking cart
      let selectedPackage = this.freezedBookingCarts?.find(x => x.id == bookingPackage.id);
      // Here no need to set value for availability when cancel changes
      // let index = this.bookedPackages.findIndex(x => x.id == selectedPackage?.cartPackages[0]?.id);
      // selectedPackage.cartPackages.forEach(pack => {
      //   let bIndex = this.tempBookedPackage[index]?.packageSessions.findIndex(x => x.packageDate.eventDate.endTime == selectedPackage.endDate && x.packageDate.eventDate.startTime == selectedPackage.startDate);
      //   //pack.availability = this.tempBookedPackage[index]?.packageSessions[bIndex]?.availability;
      // });
      let pack = this.bookedPackages.findIndex(x => x.name == selectedPackage?.name);
      selectedPackage?.cartItems?.forEach(item => {
        let ind = this.bookedPackages[pack]?.upsellItem?.findIndex(x => x.name == item?.name);
        if (ind != -1)
          if (this.bookedPackages[pack]?.upsellItem[ind].itemAvailabilityPublic?.length > 0)
            item.availability = this.bookedPackages[pack]?.upsellItem[ind]?.itemAvailabilityPublic[0].availability;
      });
      if (selectedPackage) {
        //replacing the package with our booking cart package
        let packageCartIndex = this.bookingDisplayCart.findIndex(x => x.id == selectedPackage.id);
        if (packageCartIndex > -1) {
          this.bookingDisplayCart[packageCartIndex] = _.cloneDeep(selectedPackage);
        }
      }
      else {
        let selectedPackage = this.previewsBookingPackageForCheck.find(x => x.id == bookingPackage.id);
        let index = this.bookedPackages.findIndex(x => x.id == selectedPackage?.cartPackages[0]?.id);
        let bIndex = this.tempBookedPackage[index].packageSessions.findIndex(x => x.packageDate.eventDate.endTime == selectedPackage.endDate && x.packageDate.eventDate.startTime == selectedPackage.startDate);
        selectedPackage.cartPackages[0].availability = this.tempBookedPackage[index].packageSessions[bIndex].availability;
        let packageCartIndex = this.bookingDisplayCart.findIndex(x => x.id == bookingPackage.id);
        if (packageCartIndex > -1) {
          this.bookingDisplayCart[packageCartIndex] = _.cloneDeep(selectedPackage);
        }

      }
      //below section will change the whole cart into freezedbooking if there are no booking package
    } else if (bookingPackage == undefined) {
      this.bookingDisplayCart = _.cloneDeep(this.freezedBookingCarts)
    }
  }

  checkBookingPatchPreview(packageId = null) {
    this.updateSeatCountBooking();
    if (this.tempBookedPackage.length == 0 && this.bookedPackages.length != 0)
      this.tempBookedPackage = _.cloneDeep(this.bookedPackages);
    this.validatedBooking = false;
    this.enableSpinner = true;
    //#region patch session Create
    let sessions;
    switch (this.cartType) {
      case BOOKING_CART.NEW_BOOKING:
        {
          sessions = _.cloneDeep(this.bookingSessions);
          this.bookingDisplayCart.forEach((db) => {
            const sessionStart = Utility.convertToISO(
              Utility.convertISOToDate(db.startDate)
            );
            const sessionEnd = Utility.convertToISO(
              Utility.convertISOToDate(db.endDate)
            );
            const index = sessions.findIndex(
              (s) =>
                Utility.convertToISO(Utility.convertISOToDate(s.startTime)) ===
                sessionStart &&
                Utility.convertToISO(Utility.convertISOToDate(s.endTime)) ===
                sessionEnd && s.packages.length > 0
            );
            if (index !== -1) {
              const session = _.cloneDeep(sessions[index]);
              db.cartPackages.forEach((pack) => {
                const bookingPackage = _.cloneDeep(
                  this.booking.bookingPackages.find((bp) => {
                    return (
                      bp.package.id === pack.id &&
                      Utility.convertToISO(Utility.convertISOToDate(bp.startDate)) ===
                      sessionStart &&
                      Utility.convertToISO(Utility.convertISOToDate(bp.endDate)) ===
                      sessionEnd
                    );
                  })
                );
                if (bookingPackage) {
                  let pkIndex;
                  if (pack.isChild) {
                    pkIndex = session.packages.findIndex(
                      (p) =>
                        p.isHaveLinkPackage &&
                        !!p.linkPackages.find((lp) => lp.id === pack.id)
                    );
                  } else {
                    pkIndex = session.packages.findIndex((p) => p.id === pack.id);
                  }
                  // Update Booking Package Quantity
                  if (bookingPackage.quantity !== pack.quantity) {
                    if (pkIndex !== -1) {
                      session.packages[pkIndex].isBooked = false;
                      if (session.packages[pkIndex].isHaveLinkPackage) {
                        session.packages[pkIndex].linkPackages.forEach((lp) => {
                          if (lp.id === pack.id) {
                            lp.isBooked = false;
                            lp.quantity = pack.quantity;
                            lp.availability = pack.availability;
                            let index = this.bookedPackages.findIndex(x => x.id === lp.id);
                            if (index != -1) {
                              let bIndex = this.bookedPackages[index].packageSessions.findIndex(x => x.packageDate.eventDate.endTime == db.endDate && x.packageDate.eventDate.startTime == db.startDate);
                              if (bIndex != -1)
                                this.bookedPackages[index].packageSessions[bIndex].availability = pack.availability;
                            }
                          }
                        });
                      } else {
                        session.packages[pkIndex].quantity = pack.quantity;
                        session.packages[pkIndex].availability = pack.availability;
                        let index = this.bookedPackages.findIndex(x => x.id === session.packages[pkIndex].id);
                        if (index != -1) {
                          let bIndex = this.bookedPackages[index].packageSessions.findIndex(x => x.packageDate.eventDate.endTime == db.endDate && x.packageDate.eventDate.startTime == db.startDate);
                          if (bIndex != -1) {
                            this.bookedPackages[index].packageSessions[bIndex].availability = pack.availability;
                          } else {
                            let sessionIndex = this.bookedPackages[index].packageSessions.findIndex(x => moment(x.startTime).toLocaleString() == moment(db.startDate).toLocaleString() && moment(x.endTime).toLocaleString() == moment(db.endDate).toLocaleString());
                            if (sessionIndex != -1) {
                              this.bookedPackages[index].packageSessions[sessionIndex].availability = pack.availability;
                            }
                          }
                        }

                      }
                    }
                  }
                  db.cartItems.forEach((addOn) => {
                    if (addOn.repeatPolicy && addOn.repeatPolicy.toString() === this.repeatPolicies.allowIndividualDays.toString()) {
                      if (addOn.child.length > 0) {
                        addOn.child.forEach(item => {
                          const bookingItem = bookingPackage.bookingItems.find((bItem) => {
                            return !!bItem && bItem.id === item.id;
                          });
                          if (bookingItem && bookingItem.quantity !== item.quantity) {
                            if (session.durationRange?.packageDates) {
                              let bItem;
                              const packageDate = session.durationRange?.packageDates.find(x =>
                                bItem = x.items.find(d => (d.bookingItemID === bookingItem.id))
                              )
                              if (packageDate && bItem) {
                                bItem.item.isNeedToBeUpdate = true;
                                bItem.item.qty = item.quantity;
                              }
                            }
                          }
                        })
                      } else {
                        const bookingItem = bookingPackage.bookingItems.find((bItem) => {
                          return !!bItem && bItem.id === addOn.id;
                        });
                        if (bookingItem && bookingItem.quantity !== addOn.quantity) {
                          if (session.durationRange?.packageDates) {
                            let bItem;
                            const packageDate = session.durationRange?.packageDates.find(x =>
                              bItem = x.items.find(d => (d.bookingItemID === bookingItem.id))
                            )
                            if (packageDate && bItem) {
                              bItem.item.isNeedToBeUpdate = true;
                              bItem.item.qty = addOn.quantity;
                            }
                          }
                        }
                      }
                    } else {
                      const bookingItem = bookingPackage.bookingItems.find((bItem) => {
                        return !!bItem && bItem.id === addOn.id;
                      });
                      if (bookingItem && bookingItem.quantity !== addOn.quantity) {
                        if (pkIndex !== -1) {
                          for (const key in session.packages[pkIndex].addOns) {
                            if (
                              Object.prototype.hasOwnProperty.call(
                                session.packages[pkIndex].addOns,
                                key
                              )
                            ) {
                              if (
                                session.packages[pkIndex].addOns[key].id ===
                                bookingItem.item.id
                              ) {
                                session.packages[pkIndex].addOns[key].isNeedToBeUpdate =
                                  true;
                                session.packages[pkIndex].addOns[key].qty =
                                  addOn.quantity;
                              }
                            }
                          }
                        }
                      }
                    }

                  });
                }
              });
              sessions[index] = session;
            }
          });
        }
        break;
      case BOOKING_CART.EDIT_BOOKING:
        {
          sessions = PBUtility.convertBookingPackageIntoPBSessionData(this.bookingPackages, this.bookedPackages);
          this.bookingDisplayCart.forEach((db) => {
            if (db.bookingPackageId === packageId || packageId === null) {
              const sessionStart = Utility.convertToISO(
                Utility.convertISOToDate(db.startDate)
              );
              const sessionEnd = Utility.convertToISO(
                Utility.convertISOToDate(db.endDate)
              );
              const index = sessions.findIndex(
                (s) =>
                  Utility.convertToISO(Utility.convertISOToDate(s.startTime)) ===
                  sessionStart &&
                  Utility.convertToISO(Utility.convertISOToDate(s.endTime)) ===
                  sessionEnd && s.packages.length > 0
              );
              if (index !== -1) {
                const session = _.cloneDeep(sessions[index]);
                db.cartPackages.forEach((pack) => {
                  const bookingPackage = _.cloneDeep(
                    this.bookingPackages.find((bp) => {
                      return (
                        bp.package.id === pack.id &&
                        Utility.convertToISO(Utility.convertISOToDate(bp.startDate)) ===
                        sessionStart &&
                        Utility.convertToISO(Utility.convertISOToDate(bp.endDate)) ===
                        sessionEnd
                      );
                    })
                  );
                  const freezedBookingPackage = _.cloneDeep(
                    this.freezedBookingPackages.find((bp) => {
                      return (
                        bp.package.id === pack.id &&
                        Utility.convertToISO(Utility.convertISOToDate(bp.startDate)) ===
                        sessionStart &&
                        Utility.convertToISO(Utility.convertISOToDate(bp.endDate)) ===
                        sessionEnd
                      );
                    }));
                  if (bookingPackage) {
                    let pkIndex;
                    if (pack.isChild) {
                      pkIndex = session.packages.findIndex(
                        (p) =>
                          p.isHaveLinkPackage &&
                          !!p.linkPackages.find((lp) => lp.id === pack.id)
                      );
                    } else {
                      pkIndex = session.packages.findIndex((p) => p.id === pack.id);
                    }
                    // Update Booking Package Quantity
                    if ((!freezedBookingPackage || parseInt(freezedBookingPackage.quantity) !== pack.quantity) || parseInt(bookingPackage.quantity) !== pack.quantity) {
                      if (pkIndex !== -1) {
                        session.packages[pkIndex].isBooked = false;
                        if (session.packages[pkIndex].isHaveLinkPackage) {
                          session.packages[pkIndex].linkPackages.forEach((lp) => {
                            if (lp.id === pack.id) {
                              lp.isBooked = false;
                              lp.quantity = pack.quantity;
                              lp.availability = pack.availability;
                              let index = this.bookedPackages.findIndex(x => x.id === lp.id);
                              if (index != -1) {
                                let bIndex = this.bookedPackages[index].packageSessions.findIndex(x => x.packageDate.eventDate.endTime == db.endDate && x.packageDate.eventDate.startTime == db.startDate);
                                if (bIndex != -1)
                                  this.bookedPackages[index].packageSessions[bIndex].availability = pack.availability;
                              }
                            }
                          });
                        } else {
                          session.packages[pkIndex].quantity = pack.quantity;
                          session.packages[pkIndex].availability = pack.availability;
                          let index = this.bookedPackages.findIndex(x => x.id === session.packages[pkIndex].id);
                          if (index != -1) {
                            let bIndex = this.bookedPackages[index].packageSessions.findIndex(x => x.packageDate.eventDate.endTime == db.endDate && x.packageDate.eventDate.startTime == db.startDate);
                            if (bIndex != -1) {
                              this.bookedPackages[index].packageSessions[bIndex].availability = pack.availability;
                            } else {
                              let sessionIndex = this.bookedPackages[index].packageSessions.findIndex(x => moment(x.startTime).toLocaleString() == moment(db.startDate).toLocaleString() && moment(x.endTime).toLocaleString() == moment(db.endDate).toLocaleString());
                              if (sessionIndex != -1) {
                                this.bookedPackages[index].packageSessions[sessionIndex].availability = pack.availability;
                              }
                            }
                          }
                        }
                      }
                    }
                    db.cartItems.forEach((addOn) => {
                      if (addOn.repeatPolicy && addOn.repeatPolicy.toString() === this.repeatPolicies.allowIndividualDays.toString()) {
                        if (addOn.child.length > 0) {
                          addOn.child.forEach(item => {
                            const bookingItem = bookingPackage.bookingItems.find((bItem) => {
                              return !!bItem && bItem.id === item.id;
                            });

                            if (bookingItem) {
                              const freezedBookingItem = freezedBookingPackage && freezedBookingPackage.bookingItems.find((bItem) => {
                                return !!bItem && bItem.id === item.id;
                              });
                              if ((!freezedBookingItem || freezedBookingItem.quantity !== item.quantity) || bookingItem.quantity !== item.quantity) {
                                if (session.durationRange?.packageDates) {
                                  let bItem;
                                  const packageDate = session.durationRange?.packageDates.find(x =>
                                    x.items ? bItem = x.items && x.items.find(d => (d.bookingItemID === bookingItem.id)) : false
                                  )
                                  if (packageDate && bItem) {
                                    bItem.item.isNeedToBeUpdate = true;
                                    bItem.item.qty = item.quantity;
                                  }
                                }
                              }
                            }
                          })
                        } else {
                          const bookingItem = bookingPackage.bookingItems.find((bItem) => {
                            return !!bItem && bItem.id === addOn.id;
                          });

                          if (bookingItem) {
                            const freezedBookingItem = freezedBookingPackage && freezedBookingPackage.bookingItems.find((bItem) => {
                              return !!bItem && bItem.id === addOn.id;
                            });
                            if ((!freezedBookingItem || freezedBookingItem.quantity !== addOn.quantity) || bookingItem.quantity !== addOn.quantity) {
                              if (session.durationRange?.packageDates) {
                                let bItem;
                                const packageDate = session.durationRange?.packageDates.find(x =>
                                  bItem = x.items.find(d => (d.bookingItemID === bookingItem.id))
                                )
                                if (packageDate && bItem) {
                                  bItem.item.isNeedToBeUpdate = true;
                                  bItem.item.qty = addOn.quantity;
                                }
                              }
                            }
                          }
                        }
                      } else {
                        const bookingItem = bookingPackage.bookingItems.find((bItem) => {
                          return !!bItem && bItem.id === addOn.id;
                        });

                        const freezedBookingItem = freezedBookingPackage && freezedBookingPackage.bookingItems.find((bItem) => {
                          return !!bItem && bItem.id === addOn.id;
                        });
                        if (bookingItem) {
                          if ((!freezedBookingItem || freezedBookingItem.quantity !== addOn.quantity) || bookingItem.quantity !== addOn.quantity) {
                            if (pkIndex !== -1) {
                              const ad = session.packages[pkIndex].addOns.filter(x => (bookingItem && bookingItem.item.id === x.id)
                                || freezedBookingItem && freezedBookingItem.item.id === x.id)
                              for (const key in session.packages[pkIndex].addOns) {
                                if (
                                  Object.prototype.hasOwnProperty.call(
                                    session.packages[pkIndex].addOns,
                                    key
                                  )
                                ) {
                                  if (
                                    (bookingItem && session.packages[pkIndex].addOns[key].id ===
                                      bookingItem.item.id)
                                    || (freezedBookingItem && session.packages[pkIndex].addOns[key].id ===
                                      freezedBookingItem.item.id)
                                  ) {
                                    session.packages[pkIndex].addOns[key].isNeedToBeUpdate =
                                      true;
                                    session.packages[pkIndex].addOns[key].qty =
                                      addOn.quantity;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      let pack = this.bookedPackages.findIndex(x => x.id == bookingPackage.package.id);
                      let ind = this.bookedPackages[pack]?.upsellItem?.findIndex(x => x.name == addOn?.name);
                      if (ind != -1) {
                        this.bookedPackages[pack].upsellItem[ind].availabilityStatus = addOn.availability;
                      }

                    });
                  }
                });
                sessions[index] = session;
              }
            }
          });
        }
        break;
      default:
        break;
    }

    let canUpdate = false;
    for (const s of sessions) {
      if (s.durationRange
        && s.durationRange.packageDates.filter(
          x => x.items && x.items.filter(y => y.item.isNeedToBeUpdate).length > 0
        ).length > 0) {
        canUpdate = true;
        break;
      }
      for (const pk of s.packages) {
        if (!pk.isBooked) {
          canUpdate = true;
          break;
        }
        if (pk.addOns && pk.addOns.filter(x => x.isNeedToBeUpdate).length > 0) {
          canUpdate = true;
          break;
        }
      }
    }
    if (this.booking.specialNote) {
      if (this.booking.specialNote != this.tempSpecialNote)
        canUpdate = true;
    }
    //#endregion
    if (canUpdate) {
      let PatchItemDetails: PatchItems[] = this.patchItem;
      switch (this.cartType) {
        case BOOKING_CART.NEW_BOOKING:
          {
            const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(
              sessions,
              this.PBReducer,
              this.cart,
              this.bookingPackages
            );
            const bookingResult = this.bookingService.BookingPatch(
              newBooking,
              bookingItemTypeNames,
              undefined,
              undefined,
              true
            ).safeSubscribe(this, (d) => {
              this.validationError = [];
              if (d.isError) {
                this.validatedBooking = false;
                // this.mapCardValidationError(d.errors);
                this.openSnackBarError([d.errors[0]?.detail])
                this.updateBookingCartWithBooking(this.booking)
                this.enableSpinner = false;
              } else {
                // this.updateBookingCartWithBooking(d.data)
                this.booking = this.setBooking(d.data.booking);
                this.store.dispatch(new SetBooking(this.booking))
                this.bookingDisplayCart = this.createDisplayData(
                  this.booking.bookingPackages,
                  this.bookedPackages
                );
                this.bookingDisplayCart.forEach(bp => {
                  bp.cartPackages.forEach(cp => {
                    if (cp.quantity === 0) {
                      this.deletePackages(cp.bookingPackageId, cp)
                    }
                  })
                });
                this.openExpansion(this.expandedBookingPackageIndex, this.selectedBookingPackageID);
                this.enableSeatDetails();
                this.validatedBooking = true;
                this.enableSpinner = false;
              }
            });
          }
          break;
        case BOOKING_CART.EDIT_BOOKING:
          {
            const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(
              PBUtility.makeFullBookingSession(sessions),
              this.PBReducer,
              this.cart,
              this.bookingPackages
            );
            if (newBooking.bookingPackages?.length > 0) {
              newBooking.bookingPackages.forEach(bookingPackage => {
                if (bookingPackage.bookingItems?.length > 0) {
                  bookingPackage.bookingItems.forEach(bookingItem => {
                    let patchItem = new PatchItems
                    if (PatchItemDetails.find(pi => pi.bookingItemId == bookingItem.id)) {
                      PatchItemDetails.find(pi => pi.bookingItemId == bookingItem.id).quantity = bookingItem.quantity;
                    } else {
                      patchItem.bookingPackageId = bookingPackage.id;
                      patchItem.itemId = bookingItem.item.id;
                      patchItem.bookingItemId = bookingItem.id;
                      patchItem.quantity = bookingItem.quantity;
                      PatchItemDetails.push(patchItem);
                    }
                  });
                }
              });
              this.patchItem = PatchItemDetails;
              this.store.dispatch(new PatchUpSellQuantity(PatchItemDetails));
            }



            const bookingResult = this.bookingService.BookingPatchPreview(
              newBooking,
              this.patchPreviewId,
              bookingItemTypeNames,
              true
            ).safeSubscribe(this, (d) => {
              this.validationError = [];
              this.pendingPatch = false;
              if (d.isError) {
                this.validatedBooking = false;
                this.mapCardValidationError(d.errors);
                this.updateBookingCartWithBooking(this.booking)
                this.enableSpinner = false;
              } else {
                // this.updateBookingCartWithBooking(d.data)
                //this.booking = d.data;
                this.store.dispatch(new StartBookingSession(new Date()));
                // this.store.dispatch(new AddBooking(d.data))
                // this.bookingDisplayCart = this.createDisplayData(
                //   this.booking.bookingPackages,
                //   this.bookedPackages
                // );
                this.setBookingAfterPatchORPatchPreview(d.data, packageId)
                this.enableSeatDetails();
                this.validatedBooking = true;
                this.enableSpinner = false;
                this.bookingPackageUpdated = true;
              }
            });
          }
          break;
        default:
          break;
      }
    } else {
      this.enableSpinner = false;
    }

  }

  updateSeatCountBooking() {
    this.noOfSeats = 0;
    this.bookingDisplayCart?.forEach((bookingPkg) => {
      bookingPkg.quantity = 0;
      bookingPkg.totalPrice = 0;
      bookingPkg.cartPackages?.forEach(x => {
        bookingPkg.quantity += x.quantity;
        bookingPkg.totalPrice += x.totalPrice;
      });
      this.noOfSeats = bookingPkg.quantity + this.noOfSeats;
    });
  }
  deliveryMethodChange() {
    this.selectedDeliveryMethod = this.deliveryMethods?.find(x => x.id == this.selectedDeliveryMethodID);
    this.bookingTotalAmount = PBUtility.getBookingTotal(this.booking);
    this.patchDeliveryMethod();
  }

  updateBookingCartWithBooking(booking: any) {
    this.bookingDisplayCart.forEach(bookingCart => {
      bookingCart.cartPackages.forEach(cartPackage => {
        const previousBookingPackage = this.previousBooking.bookingPackages.find(bp => bp.id === cartPackage.bookingPackageId)
        const updatedBookingPackage = booking.bookingPackages.find(bp => bp.id === cartPackage.bookingPackageId)
        if (previousBookingPackage && updatedBookingPackage) {
          if ((cartPackage.quantity != previousBookingPackage.quantity.toString())) {
            if (cartPackage.quantity === updatedBookingPackage.quantity.toString()) {
              cartPackage.isValidEdit = true;
            } else {
              cartPackage.quantity = previousBookingPackage.quantity.toString();
              cartPackage.availability = cartPackage.originalAvailability;
            }
          }
        }
      })
      bookingCart.cartItems.forEach(cartItem => {
        const previousBookingItem = this.previousBooking.bookingItems.find(bp => bp.id === cartItem.bookingItemId)
        const updatedBookingItem = booking.bookingItems.find(bp => bp.id === cartItem.bookingItemId)
        if (previousBookingItem && updatedBookingItem) {
          if ((cartItem.quantity != previousBookingItem.quantity.toString())) {
            if (cartItem.quantity === updatedBookingItem.quantity.toString()) {
              cartItem.isValidEdit = true;
            } else {
              cartItem.quantity = previousBookingItem.quantity.toString();
              cartItem.availability = cartItem.originalAvailability;
            }
          }
        }
      })
    })
    if (this.previousBooking !== booking) {
      this.previousBooking = _.cloneDeep(booking)
    }
  }

  EditCardlessContentEnable() {
    if (this.booking.specialNote)
      this.tempSpecialNote = this.booking.specialNote;
    this.canEditCardlessContent = true;
  }

  //save the special request of Booking
  SaveCardlessContent() {
    let nBooking: Booking;
    let bookingItemTypes
    if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
      const sessions = PBUtility.makeFullBookingSession(PBUtility.convertBookingIntoPBSessionData(this.booking, this.PBReducer.bookedPackages));
      const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(sessions, this.PBReducer, this.cart, this.bookingPackages);
      nBooking = newBooking;
      bookingItemTypes = bookingItemTypeNames;
    } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
      nBooking = new Booking();
      nBooking.id = this.booking.id;
    }
    if (this.booking.specialNote)
      nBooking.specialNote = this.booking.specialNote;
    this.tempSpecialNote = this.booking.specialNote;
    this.canEditCardlessContent = false;

    if (nBooking.id) {
      let bookingPatch;
      if (this.cartType === BOOKING_CART.EDIT_BOOKING && this.IS_HAVE_TO_PAY_DUE_AMOUNT) {
        bookingPatch = this.bookingService.BookingPatchPreview(nBooking, this.patchPreviewId, bookingItemTypes)
      }
      if (this.cartType === BOOKING_CART.EDIT_BOOKING && !this.IS_HAVE_TO_PAY_DUE_AMOUNT) {
        bookingPatch = this.bookingService.BookingPatch(nBooking, [])
      } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
        bookingPatch = this.bookingService.BookingPatch(nBooking, [])
      }
      bookingPatch.safeSubscribe(this, b => {
        if (b.isError) {
          this.openSnackBarError('Unable to save Special Request...');
        }
      });
    }
    // if (this.booking.specialNote)
    //   this.tempSpecialNote = this.booking.specialNote;
    // this.canEditCardlessContent = false;
    // this.checkBookingPatchPreview();
    // const sessions = PBUtility.makeFullBookingSession(PBUtility.convertBookingIntoPBSessionData(this.booking, this.PBReducer.bookedPackages));
    // const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(sessions, this.PBReducer, this.cart);
    // this.bookingService.BookingPatch(newBooking, bookingItemTypeNames).safeSubscribe(this, (d) => {
    //   if (d.isError) {
    //     this.openSnackBarError('Unable to save Special Request...');
    //   }
    // });
  }

  EditCardlessContentDisable() {
    this.booking.specialNote = this.tempSpecialNote;
    this.canEditCardlessContent = false;
  }

  setDateFormat(startDate, endDate) {
    let start = moment(startDate).format('MMM DD, YYYY');
    let end = moment(endDate).format('MMM DD, YYYY');

    if (start && end) {
      //get month
      let startMonth = moment(start).format('MMMM');
      let endMonth = moment(end).format('MMMM');

      //get Year
      let startYear = moment(start).format('YYYY');
      let endYear = moment(end).format('YYYY');

      //get day
      let startDay = moment(start).format('DD');
      let endDay = moment(end).format('DD');

      let startTransMonth = '';
      let endTransMonth = '';
      this.translate.get("MONTHS_DAYS." + startMonth).safeSubscribe(this, (startrest: string) => {
        this.translate.get("MONTHS_DAYS." + endMonth).safeSubscribe(this, (endrest: string) => {
          startTransMonth = startrest;
          endTransMonth = endrest;
        });
      });

      if (startMonth === endMonth && startYear === endYear) {
        return parseInt(startDay) + " - " + parseInt(endDay) + " " + startTransMonth + " " + startYear;
      }
      else if (startMonth !== endMonth && startYear === endYear) {
        return parseInt(startDay) + " " + startTransMonth + " - " + parseInt(endDay) + " " + endTransMonth + " " + startYear;
      }
      else {
        return parseInt(startDay) + " " + startTransMonth + " " + startYear + " - " + parseInt(endDay) + " " + endTransMonth + " " + endYear;
      }
    }
    return "Invalid Date";
  }

  mapCardValidationError(errors: any[], deleteAdultPackage?: any, session?: IDateAndSession) {
    this.validationError = [];
    errors.forEach(error => {
      const isWarning = error.detail.includes('Warning');
      if (isWarning) {
        error.detail = error.detail.replace('Warning: ', '');
      }
      let isAdultDelete = error.detail.includes('Adult package requeued');
      const canSwitchAdultForChild = error.detail.includes('Adult package requeued can switch Adult Package for child');
      if (canSwitchAdultForChild) {
        isAdultDelete = true;
      }
      const vError: ValidationError = {
        type: isWarning ? ErrorType.WARNING : ErrorType.ERROR,
        description: error.detail,
        isAdultDelete: isAdultDelete,
        canSwitchAdult: canSwitchAdultForChild
      };
      this.validationError.push(vError);
    });
    const adultRequeuedError = this.validationError.filter(x => x.isAdultDelete);
    if (adultRequeuedError.length > 0) {
      this.enableSpinner = true;
      let title, message;
      let isAdultDelete = false;
      let canSwitchAdult = false;

      for (const error of adultRequeuedError) {
        if (error.canSwitchAdult && error.isAdultDelete) {
          title = '';
          message = '';
          canSwitchAdult = true;
          break;
        } else
          if (error.isAdultDelete) {
            title = this.translate.instant('DELETEPOPUPINFO.Delete_Head');
            message = this.translate.instant('DELETEPOPUPINFO.Delete_Message_IMG');
            isAdultDelete = true;
            break;
          }
      }
      // isAdult
      if (isAdultDelete) {
        const dialog = this.dialog.open(BookingValidationConfirmationModalComponent, {
          data: {
            title: title,
            message: message
          },
          panelClass: [
            'custom-dialog-container',
            'w50modal',
            'modal-width',
            'extrapop',
          ],
          height: 'auto',
          width: '50%',
        });
        dialog.afterClosed().safeSubscribe(this, res => {
          if (res) {
            this.validatedBooking = true;
            const sessionStart = Utility.convertToISO(
              Utility.convertISOToDate(session.startTime)
            );
            const sessionEnd = Utility.convertToISO(
              Utility.convertISOToDate(session.endTime)
            );
            const bookingPackage = this.bookingDisplayCart.find(x =>
              Utility.convertToISO(
                Utility.convertISOToDate(x.startDate)
              ) === sessionStart &&
              Utility.convertToISO(
                Utility.convertISOToDate(x.endDate)
              ) === sessionEnd
            );
            if (bookingPackage) {
              bookingPackage.cartPackages.forEach(pk => {
                pk.quantity = 0;
                pk.isDelete = true;
              });
              bookingPackage.cartItems.forEach(item => {
                item.quantity = 0;
                item.isDelete = true;
                if (item.child.length > 0) {
                  item.child.forEach(child => {
                    child.quantity = 0;
                    child.isDelete = true;
                  });
                }
              });
              this.cardUpdated = true;

            }
            this.enableSpinner = false;
          } else {
            this.enableSpinner = false;
          }
        });
      } else {
        this.enableSpinner = false;
      }

    }
  }

  //#region Generate Booking Patch Preview Model





  // !not going to be call
  updateBookingCart() {
    const sessions = _.cloneDeep(this.bookingSessions);
    // console.log(`bookingSessions`, this.bookingSessions);
    this.bookingDisplayCart.forEach((db) => {
      const sessionStart = Utility.convertToISO(
        Utility.convertISOToDate(db.startDate)
      );
      const sessionEnd = Utility.convertToISO(
        Utility.convertISOToDate(db.endDate)
      );
      const index = sessions.findIndex(
        (s) =>
          Utility.convertToISO(Utility.convertISOToDate(s.startTime)) ===
          sessionStart &&
          Utility.convertToISO(Utility.convertISOToDate(s.endTime)) ===
          sessionEnd && s.packages.length > 0
      );
      if (index !== -1) {
        const session = _.cloneDeep(sessions[index]);
        db.cartPackages.forEach((pack) => {
          const bookingPackage = _.cloneDeep(
            this.booking.bookingPackages.find((bp) => {
              return (
                bp.package.id === pack.id &&
                Utility.convertToISO(Utility.convertISOToDate(bp.startDate)) ===
                sessionStart &&
                Utility.convertToISO(Utility.convertISOToDate(bp.endDate)) ===
                sessionEnd
              );
            })
          );
          if (bookingPackage) {
            let pkIndex;
            if (pack.isChild) {
              pkIndex = session.packages.findIndex(
                (p) =>
                  p.isHaveLinkPackage &&
                  !!p.linkPackages.find((lp) => lp.id === pack.id)
              );
            } else {
              pkIndex = session.packages.findIndex((p) => p.id === pack.id);
            }
            // Update Booking Package Quantity
            if (bookingPackage.quantity !== pack.quantity) {
              if (pkIndex !== -1) {
                session.packages[pkIndex].isBooked = false;
                if (session.packages[pkIndex].isHaveLinkPackage) {
                  session.packages[pkIndex].linkPackages.forEach((lp) => {
                    if (lp.id === pack.id) {
                      lp.isBooked = false;
                      lp.quantity = pack.quantity;
                    }
                  });
                } else {
                  session.packages[pkIndex].quantity = pack.quantity;
                }
              }
            }
            db.cartItems.forEach((addOn) => {
              const bookingItem = bookingPackage.bookingItems.find((bItem) => {
                return !!bItem && bItem.id === addOn.id;
              });
              if (bookingItem && bookingItem.quantity !== addOn.quantity) {
                if (pkIndex !== -1) {
                  for (const key in session.packages[pkIndex].addOns) {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        session.packages[pkIndex].addOns,
                        key
                      )
                    ) {
                      if (
                        session.packages[pkIndex].addOns[key].id ===
                        bookingItem.item.id
                      ) {
                        session.packages[pkIndex].addOns[key].isNeedToBeUpdate =
                          true;
                        session.packages[pkIndex].addOns[key].qty =
                          addOn.quantity;
                      }
                    }
                  }
                }
              }
            });
          }
        });
        sessions[index] = session;
      }
    });

    let canUpdate = false;
    for (const s of sessions) {
      for (const pk of s.packages) {
        if (!pk.isBooked) {
          canUpdate = true;
          break;
        }
        if (pk.addOns && pk.addOns.length > 0) {
          for (const ad of pk.addOns) {
            if (ad.isNeedToBeUpdate) {
              canUpdate = true;
              break;
            }
          }
        }
      }
    }
    if (canUpdate) {
      this.callBookingPatch();
      this.validateAttendeeCountBasedOnPackageCount(sessions);
      //   this.cardUpdated = false;
      //   this.store.dispatch(new SelectPackageSessions(sessions));
      //   this.store.dispatch(new BookingPatchPreview());
    }
    this.canEditBookingCart = false;
  }

  private validateAttendeeCountBasedOnPackageCount(
    sessions: IDateAndSession[]
  ) {
    // console.log(this.bookingDisplayCart)
    const bookingPackageIds = []; // Booking package ids to get Host & Guest Details
    sessions.forEach((session) => {
      if (session.bookingPackages) {
        session.bookingPackages.forEach((bookingPackage) => {
          if (this.booking && this.booking.bookingPackages) {
            const oldBookingPackage = this.booking.bookingPackages.find(
              (x) => x.id === bookingPackage.id
            );
            const IPackage = session.packages.find(
              (x) => x.id === bookingPackage.packageId
            );
            if (oldBookingPackage && IPackage) {
              const previousQuantity = oldBookingPackage.quantity;
              const currentQuantity = IPackage.quantity;
              if (
                !!oldBookingPackage.attendeeCaptureProfile &&
                currentQuantity < previousQuantity
              ) {
                bookingPackageIds.push({
                  id: oldBookingPackage.id,
                  quantity: currentQuantity,
                  deleteCount: previousQuantity - currentQuantity,
                });
              }
            }
          }
        });
      }
    });
    const refSer = this.bookingService
      .selectBookingHost(
        this.booking.id,
        bookingPackageIds.map((x) => x.id),
        this.patchPreviewId ? this.patchPreviewId : ''
      )
      .subscribe((hosts) => {
        const filteredHostForDelete = [];
        const mapAttendeeDisplayCard = (hosts, bookingPackages) => {
          const attendeeDisplayCards = [];
          hosts.forEach((host) => {
            const isExist = attendeeDisplayCards.findIndex(
              (a) => a.id === host.bookingPackage.id
            );
            const bookingPackage = bookingPackages.find(
              (p) => p.id == host.bookingPackage.id
            );
            if (bookingPackage) {
              const packageA = this.bookedPackages.find(x => x.id === bookingPackage.package.id)
              if (packageA && packageA.attendeeCaptureProfile) {
                bookingPackage.attendeeCaptureProfile = packageA.attendeeCaptureProfile
              }
              host.bookingPackage = bookingPackage;
            }
            if (isExist !== -1) {
              const isHostEx = attendeeDisplayCards[isExist].hosts.findIndex(
                (x) => x.id === host.id
              );
              if (isHostEx === -1) {
                attendeeDisplayCards[isExist].hosts.push(host);
              }
            } else {
              const attendeeDisplayCard = new AttendeeDisplay();
              attendeeDisplayCard.id = host.bookingPackage.id;
              attendeeDisplayCard.bookingPackage = host.bookingPackage;
              attendeeDisplayCard.hosts = [host];
              attendeeDisplayCards.push(attendeeDisplayCard);
            }
          });
          return attendeeDisplayCards;
        };
        hosts = PBUtility.GetEligibleHosts(hosts);
        const attendeeDisplayCards = mapAttendeeDisplayCard(hosts, this.booking.bookingPackages);
        attendeeDisplayCards.forEach((attendeeDisplay: AttendeeDisplay) => {
          const bookingPackageD = bookingPackageIds.find(
            (x) => x.id === attendeeDisplay.bookingPackage.id
          );
          if (bookingPackageD) {
            // getting un named attendee
            let unAllocateeAttendeeCount = 0;
            attendeeDisplay.hosts.forEach((host) => {
              unAllocateeAttendeeCount += host.guests.filter(
                (x) => !x.namedHostGuest
              ).length;
            });
            if (unAllocateeAttendeeCount < bookingPackageD.deleteCount) {
              filteredHostForDelete.push(...attendeeDisplay.hosts);
            }
          }
        });

        if (filteredHostForDelete.length > 0) {
          const dialogRef = this.dialog.open(AttendeeListModalComponent, {
            data: {
              bookingId: this.booking.id,
              hosts: hosts,
              isDeleteAttendee: true,
              bookingPackageDetail: bookingPackageIds,
              canEdit: !this.booking.cancelled
            },
            panelClass: [],
            height: 'auto',
            width: 'auto',
            maxHeight: '70%',
          });
          dialogRef.afterClosed().subscribe((res) => {
            this.cardUpdated = false;
            if (res.deleteSuccess) {
              this.store.dispatch(new SelectPackageSessions(sessions));
              this.store.dispatch(new PatchBooking());
              this.store.dispatch(new UpdateSelectedBooking());
              // this.callBookingPatch();
            }
          });
        } else {
          this.cardUpdated = false;
          this.store.dispatch(new SelectPackageSessions(sessions));
          // this.callBookingPatch();
          this.store.dispatch(new PatchBooking());
          this.store.dispatch(new UpdateSelectedBooking());
        }
      });
  }

  callBookingPatch() {
    const deletePackage: any[] = [];
    const deleteItem: any[] = [];
    this.bookingDisplayCart.forEach(bdc => {
      bdc.cartPackages.forEach(pc => {
        if (pc.isDelete) {
          const pg = {
            bookingPackageId: pc.bookingPackageId,
            isChild: pc.isChild
          };
          deletePackage.push(pg);
        }
      });
      bdc.cartItems.forEach(ic => {
        if (ic.isDelete) {
          const i = {
            bookingItemId: ic.bookingItemId,
          }
          deleteItem.push(i)
        }
      })
    });
    if (deletePackage.length > 0 || deleteItem.length > 0) {
      deletePackage.sort((x, y) => (x.isChild === y.isChild) ? 0 : x ? -1 : 1);
      const request = [];
      deletePackage.forEach(x => {
        request.push(this.bookingService.deleteBookingPackage(this.booking.id, x.bookingPackageId, true));
      });
      deleteItem.forEach(x => {
        request.push(this.bookingService.deleteBookingItem(this.booking.id, x.bookingItemId, true));
      });
      if (request.length > 0) {
        const observable = forkJoin(request);
        observable.safeSubscribe(this, (results: any[]) => {
          const isError = false;
          const errors = [];
          results.forEach(result => {
            if (result.isError) {
              errors.push(result.erorr);
            }
          });
          if (isError) {
            this.mapCardValidationError(errors);
          } else {
            this.openSnackBarSuccess(["Successfully Deleted"])
            this.validationError = [{
              description: 'Successfully Deleted',
              type: ErrorType.SUCCESS,
              isAdultDelete: false,
              canSwitchAdult: false
            }];
            setTimeout(() => {
              this.validationError = []
            }, 5000)
            this.store.dispatch(new PatchBooking());
            this.store.dispatch(new UpdateSelectedBooking());
          }
        });
      }
    } else {
      this.store.dispatch(new PatchBooking());
      this.store.dispatch(new UpdateSelectedBooking());
    }
    this.bookingDisplayCart = this.createDisplayData(
      this.booking.bookingPackages,
      this.bookedPackages
    );
    this.enableSeatDetails();
  }

  clearSession() {
    let cartType = this.PBReducer.bookingMode;
    this.store.dispatch(new ResetPublicWebState());
    // this.paymentSessionTime.minutes = "0";
    //   this.paymentSessionTime.seconds = "0";
    //   this.paymentSessionTime.isSessionExpired = true;
    //   this.paymentSessionTime.sessionExpended = false;
    //   this.paymentSessionTime.isSessionTimerStart = false;
    //   this.paymentSessionTime.sessionKey = undefined;
    //   this.paymentSessionTime.sessionStartDate = undefined;
    //   this.paymentSessionTime.sessionEndTime = 0;
    //   this.paymentSessionTime.timerPauseTime = null;
    //   this.paymentSessionTime.timerPaused = false;
    //   this.paymentSessionTime.popupShowed = false;
    // this.store.dispatch(new AddBookingSession(this.paymentSessionTime));
    this._publicBookingSelectService.resetValues();
    if (cartType === BOOKING_CART.NEW_BOOKING && this.bookingDisplayCart.length === 1 && this.enableNewDesignForBookingCartPage) {
      this.bookedEvents = [];
      this.bookingDisplayCart = [];
      this.noOfSeats = 0;
      this.store.dispatch(new ResetConfigurationState());
    } else {
      window.location.href = environment.PublicWebPortalUrl;
    }
  }

  onRepeatItemQuantityChange(e, index: number, upsell) {
    if(index > -1) {
      let itemIndex = this.bookingDisplayCart[index].cartItems.findIndex(i => i.id == upsell.id);
      if(itemIndex > -1) {
        this.bookingDisplayCart[index].cartItems[itemIndex].child.forEach(c => {
          c.quantity = upsell.quantity
        })
        this.onItemQuantityChange(e, index)
      }
    }
  }

  onItemQuantityChange(e, index: number) {
    if (this.buttonTriggerPriviousTime != 0) {
      let timeDiff = new Date().getTime() - this.buttonTriggerPriviousTime;
      this.buttonTriggerPriviousTime = new Date().getTime();
      if (timeDiff < 1500) {
        clearInterval(this.timer);
        this.bookingPatchTimerTriggered = false;
      }
    } else {
      this.buttonTriggerPriviousTime = new Date().getTime();
    }
    if (this.cartType == this.BOOKING_CART.EDIT_BOOKING && index != -1) {
      let bookingPackage = this.bookingDisplayCart[index];
      if (bookingPackage) {
        this.onCartValueChange(bookingPackage.id);
      }
    } else {
      this.onCartValueChange();
    }
    this.expandedBookingPackageIndex = index;
  }

  onCartValueChange(id = null) {
    this.validatedBooking = false;
    if (!this.bookingPatchTimerTriggered) {
      this.getCurrentDate(id);
      this.bookingPatchTimerTriggered = true;
    }
    this.cardUpdated = false;
    for (const bookingCart of this.bookingDisplayCart) {
      for (const bItem of bookingCart.cartItems) {
        const bookItem = this.booking.bookingItems.find(
          (x) => x.id === bItem.bookingItemId
        );
        if (bookItem && bItem.quantity !== bookItem.quantity) {
          this.cardUpdated = true;
        }
        if (bItem.availability != -1) {
          if (bookItem.quantity == bItem.quantity)
            bItem.availability = bItem.originalAvailability;
          if (bookItem && bItem.quantity !== bookItem.quantity) {
            this.cardUpdated = true;
            if (bItem.quantity > bookItem.quantity) {
              if (Number(bItem.originalAvailability) + (bookItem.quantity - bItem.quantity) >= 0)
                bItem.availability = Number(bItem.originalAvailability) + (bookItem.quantity - bItem.quantity);
              else
                bItem.availability = 0;
            }
            else if (bItem.quantity < bookItem.quantity) {
              if (Number(bItem.originalAvailability) - (bItem.quantity - bookItem.quantity) >= 0)
                bItem.availability = Number(bItem.originalAvailability) - (bItem.quantity - bookItem.quantity);
              else
                bItem.availability = 0;
            }
            bItem.backgroundColor = this.getBgColor(bItem.availability, bItem.capacity);
          }
        }
      }
      for (const bPackage of bookingCart.cartPackages) {
        const bookItem = this.bookingPackages.find(
          (x) => x.id === bPackage.bookingPackageId
        );
        if (bPackage.quantity == bookItem.quantity)
          bPackage.availability = bPackage.originalAvailability;
        if (bookItem && bPackage.quantity !== bookItem.quantity) {
          this.cardUpdated = true;
          if (bPackage.quantity > bookItem.quantity) {
            if (bPackage.originalAvailability + (bookItem.quantity - bPackage.quantity) >= 0)
              bPackage.availability = bPackage.originalAvailability + (bookItem.quantity - bPackage.quantity);
            else
              bPackage.availability = 0;
          }
          else if (bPackage.quantity < bookItem.quantity) {
            if (bPackage.originalAvailability - (bPackage.quantity - bookItem.quantity) >= 0)
              bPackage.availability = bPackage.originalAvailability - (bPackage.quantity - bookItem.quantity);
            else
              bPackage.availability = 0;
          }
          bPackage.backgroundColor = this.getBgColor(bPackage.availability, bPackage.capacity);
        }
      }
    }
    this.previousBooking = _.cloneDeep(this.booking)
  }

  // Didn't use
  // refreshBookingEditSpinner(id = null) {
  //   // get current timestamp
  //   const currentTime = new Date().getTime();
  //   this.bookingPatchTimer = currentTime + 1000;
  //   this.validatedBooking = false;
  //   // trigger on first event change
  //   if (!this.bookingPatchTimerTriggered) {
  //     this.getCurrentDate(id);
  //     this.bookingPatchTimerTriggered = true;
  //   }

  // }

  getCurrentDate(id = null) {
    this.timer = setInterval(() => {
      this.bookingPatchTimer = new Date().getTime(); // set time variable with current date
      if (this.bookingPatchTimerTriggerTimer < this.bookingPatchTimer) {
        if (this.cartType == this.BOOKING_CART.NEW_BOOKING) {
          this.checkBookingPatchPreview();
          this.buttonTriggerPriviousTime = 0;
        } else {
          this.checkBookingPatchPreview(id);
          this.buttonTriggerPriviousTime = 0;
        }
        this.bookingPatchTimerTriggered = false;
        clearInterval(this.timer);
      }
    }, 1000); // set it every one second
  }

  deletePackageWithPatchPreview(pkgId, bp: BookingCart) {
    const sessions = _.cloneDeep(this.bookingSessions);
    const bookingPackage = bp.cartPackages.find(
      (x) => x.bookingPackageId === pkgId
    );
    let deletePackage;
    const sessionStart = Utility.convertToISO(
      Utility.convertISOToDate(bp.startDate)
    );
    const sessionEnd = Utility.convertToISO(
      Utility.convertISOToDate(bp.endDate)
    );
    const index = sessions.findIndex(
      (s) =>
        Utility.convertToISO(Utility.convertISOToDate(s.startTime)) ===
        sessionStart &&
        Utility.convertToISO(Utility.convertISOToDate(s.endTime)) === sessionEnd
    );
    if (index !== -1) {
      const session = sessions[index];
      const bookingPack = session.bookingPackages.find(
        (x) => x.id === bookingPackage.bookingPackageId
      );
      if (bookingPack) {
        const packIndex = session.packages.findIndex((p) =>
          p.isHaveLinkPackage
            ? p.linkPackages.findIndex(
              (x) => x.id === bookingPack.packageId
            ) !== -1
            : p.id === bookingPack.packageId
        );
        if (packIndex !== -1 && session.packages[packIndex].isHaveLinkPackage) {
          // TODO Linked Package Validation
          this.enableSpinner = true;
          const linkedIndex = session.packages[
            packIndex
          ].linkPackages.findIndex((x) => x.id === bookingPack.packageId);
          if (linkedIndex !== -1) {
            // if (session.packages[packIndex].linkPackages[linkedIndex].linkedPackageType.adult) {
            //   deletePackage = session.packages[packIndex].linkPackages[linkedIndex];
            // }
            session.packages[packIndex].isBooked = false;
            session.packages[packIndex].linkPackages[linkedIndex].quantity = 0;
            session.packages[packIndex].linkPackages[linkedIndex].isBooked = false;
            deletePackage = session.packages[packIndex].linkPackages[linkedIndex];
          }
        } else if (
          packIndex !== -1 &&
          !session.packages[packIndex].isHaveLinkPackage
        ) {
          this.enableSpinner = true;
          session.packages[packIndex].quantity = 0;
          session.packages[packIndex].isBooked = false;
          deletePackage = session.packages[packIndex];
          if (session.packages[packIndex].addOns) {
            session.packages[packIndex].addOns.forEach(item => {
              item.isNeedToBeUpdate = true;
              item.qty = 0;
            })
          }
        }
        if (packIndex !== -1 && session.durationRange) {
          session.durationRange.packageDates.filter(pDate => pDate.items && pDate.items.length > 0 && pDate.items.find(x => x.packageID === bookingPackage.id)).forEach(pDate => {
            pDate.items.filter(x => x.packageID === bookingPackage.id).forEach(d => {
              d.item.isNeedToBeUpdate = true;
              d.item.qty = 0;
            })
          })
        }
      }
      const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(
        PBUtility.makeFullBookingSession(sessions),
        this.PBReducer,
        this.cart,
        this.bookingPackages
      );
      const bookingResult = this.bookingService.BookingPatchPreview(
        newBooking,
        this.patchPreviewId,
        bookingItemTypeNames, true
      ).safeSubscribe(this, (d) => {
        this.enableSpinner = false;
        this.validationError = [];
        if (d.isError) {
          this.validatedBooking = false;
          this.mapCardValidationError(d.errors, deletePackage, session);
        } else {
          this.validatedBooking = true;
          const bookingPackageM = this.bookingDisplayCart.find(x =>
            Utility.convertToISO(
              Utility.convertISOToDate(x.startDate)
            ) === sessionStart &&
            Utility.convertToISO(
              Utility.convertISOToDate(x.endDate)
            ) === sessionEnd
          );
          if (bookingPackageM) {
            const pack = bookingPackageM.cartPackages.find(pk => pk.id === deletePackage.id);
            if (pack) {
              pack.isDelete = true;
              pack.quantity = 0;
              this.cardUpdated = true;
            }
          }
          //this.store.dispatch(new AddBooking(d.data))
          this.setBookingAfterPatchORPatchPreview(d.data, pkgId);
        }
      });
    }
  }

  deleteItemWithPatchPreview(item, bp: BookingCart) {
    const sessions = _.cloneDeep(this.bookingSessions);
    const bookingItem = this.booking.bookingItems.find((x) => x.id === item.id);
    const sessionStart = Utility.convertToISO(
      Utility.convertISOToDate(bp.startDate)
    );
    const sessionEnd = Utility.convertToISO(
      Utility.convertISOToDate(bp.endDate)
    );
    const index = sessions.findIndex(
      (s) =>
        Utility.convertToISO(Utility.convertISOToDate(s.startTime)) ===
        sessionStart &&
        Utility.convertToISO(Utility.convertISOToDate(s.endTime)) === sessionEnd
    );
    if (index !== -1) {
      const session = sessions[index];
      const bookingPack = session.bookingPackages.find(
        (x) => x.id === bp.bookingPackageId
      );
      if (!!bookingPack) {
        const packIndex = session.packages.findIndex(
          (p) => p.id === bookingPack.packageId
        );
        if (packIndex !== -1) {
          const itemIndex = session.packages[packIndex].addOns.findIndex(
            (x) => x.id === bookingItem.item.id
          );
          if (itemIndex !== -1) {
            session.packages[packIndex].addOns[itemIndex].qty = 0;
            session.packages[packIndex].addOns[itemIndex].isNeedToBeUpdate =
              true;
          }
        }
      }
      const changeItemQuantityDelete = (itm) => {
        if (session.durationRange) {
          const itemStart = Utility.convertToISO(
            Utility.convertISOToDate(itm.startTime)
          );
          const itemEnd = Utility.convertToISO(
            Utility.convertISOToDate(itm.endTime)
          );
          const packageDate = session.durationRange.packageDates.find(x =>
            Utility.convertToISO(
              Utility.convertISOToDate(x.eventDate.startTime)
            ) === itemStart &&
            Utility.convertToISO(
              Utility.convertISOToDate(x.eventDate.endTime)
            ) === itemEnd);
          if (packageDate) {
            const selectedItem = packageDate.items.find(x => x.bookingItemID === itm.bookingItemId);
            if (selectedItem) {
              selectedItem.item.qty = 0;
              selectedItem.item.isNeedToBeUpdate = true;
            }
          }
        }
      }
      if (item.child && item.child.length > 0) {
        item.child.forEach(x => {
          changeItemQuantityDelete(x);
        })
      } else {
        changeItemQuantityDelete(item);
      }
      const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(
        sessions,
        this.PBReducer,
        this.cart,
        this.bookingPackages
      );
      this.enableSpinner = true;
      const bookingResult = this.bookingService.BookingPatchPreview(
        newBooking,
        this.patchPreviewId,
        bookingItemTypeNames, true
      ).safeSubscribe(this, (d) => {
        this.validationError = [];
        if (d.isError) {
          this.validatedBooking = false;
          this.mapCardValidationError(d.errors);
        } else {
          this.validatedBooking = true;
          // const bookingPackageM = this.bookingDisplayCart.find(x =>
          //   Utility.convertToISO(
          //     Utility.convertISOToDate(x.startDate)
          //   ) === sessionStart &&
          //   Utility.convertToISO(
          //     Utility.convertISOToDate(x.endDate)
          //   ) === sessionEnd
          // );
          // if (bookingPackageM) {
          //   const item = bookingPackageM.cartItems.find(pk => pk.bookingItemId === bookingItem.id);
          //   if (item) {
          //     item.isDelete = true;
          //     item.quantity = 0;
          //     this.cardUpdated = true;
          //   }
          // }
          this.store.dispatch(new SetBooking(d.data))
        }
        this.enableSpinner = false;
      }, (error) => {
        //show error
        this.openSnackBarError(["Your payment has been failed. Please try again"]);
        this.enableSpinner = false;
      });
      // this.store.dispatch(new SelectPackageSessions([session]));
      // this.store.dispatch(new BookingPatchPreview());
    }
  }

  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 3000,
      verticalPosition: 'top',
    });
  }

  openSnackBarSuccess(message) {
    this.snackBar.openFromComponent(SuccessMessageComponent, {
      data: message,
      duration: 3000,
      verticalPosition: 'top',
    });
  }

  continueShopping() {
    if (!this.booking?.cancelled && this.booking?.bookingPackages.length > 0 && this.bookedPackages.find(p => p.id == this.latestPackageId)?.singleDayPackage) {
      if (!this.bookingFlowOption) {
        const defaultBookingFlowOptionId = (environment.PublicBookingSetting as PublicBookingSettings).defaultBookingFlowOptionId;
        const latestPackage = this.bookedPackages.find(p => String(p.id) == String(this.latestPackageId))
        const bookingFlowOptionId = latestPackage?.publicEvent?.bookingFlowOptionId ? latestPackage?.publicEvent?.bookingFlowOptionId : (defaultBookingFlowOptionId ? defaultBookingFlowOptionId : null);
        if (bookingFlowOptionId) {
          this.bookingFlowOption = bookingFlowOptionId;
          this.store.dispatch(new SetBookingFlow(bookingFlowOptionId));
        }
      }
      this.editTicketBookingApp(this.latestPackageId, null, true);
    } else {
      this.continueShoppingWithUpdatedPackage();
    }
  }

  continueShoppingWithUpdatedPackage() {
    let bookedPackageIndex = -1;
    if (this.booking && !this.booking.cancelled) {
      this.booking.bookingPackages.forEach(pack => {
        bookedPackageIndex++;
        if (bookedPackageIndex != -1 && this.booking.bookingPackages[bookedPackageIndex].bookingItems.length == 0) {
          this.bookingService.GetBookingItemForBookingPackage(this.booking.id, pack.id, false).safeSubscribe(this, (d) => {
            if (!d.isError && d.data) {
              let data = d.data;
              this.booking.bookingPackages[bookedPackageIndex].bookingItems = d.data;
            }
            setTimeout(() => { this.enableSpinner = false; }, 1000);
          }, (error) => {
            this.openSnackBarError(["booking Item undefined for this bookingPackage"]);
            setTimeout(() => { this.enableSpinner = false; }, 1000);
          });
        }
        if (bookedPackageIndex == this.booking.bookingPackages.length - 1) {
          this.store.dispatch(new SetBooking(this.booking));
          if (this.enableNewDesignForBookingCartPage) {
            if (this.cartType === BOOKING_CART.NEW_BOOKING) {
              if (this.packageSession && this.packageSession?.length > 1) {
                if (this.bookingFlowOption == BOOKING_FLOW_OPTION.SessionPackageListVenueMap || this.bookingFlowOption == BOOKING_FLOW_OPTION.SessionVenueMapPackageList) {
                  this.router.navigate(['date-and-session/' + this.latestEventId], { queryParams: { isContinueShopping: true } });
                } else if (this.bookingFlowOption == BOOKING_FLOW_OPTION.PackageListSessionVenueMap) {
                  this.router.navigate(['package-list/' + this.latestEventId], { queryParams: { isContinueShopping: true } });
                }
              } else {
                if (this.bookingFlowOption == BOOKING_FLOW_OPTION.SessionVenueMapPackageList) {
                  this.viewAllEvents();
                } else if (this.bookingFlowOption == BOOKING_FLOW_OPTION.SessionPackageListVenueMap) {
                  if (this.allPackageSessions && this.allPackageSessions?.length == 1) {
                    this.viewAllEvents();
                  } else if (this.packageSession) {
                    const session: IDateAndSession = {
                      id: this.packageSession[0].id.toString(),
                      startTime: new Date(this.packageSession[0].startTime).toISOString(),
                      endTime: new Date(this.packageSession[0].endTime).toISOString(),
                      availability: this.packageSession[0].availability,
                      eventDate: this.packageSession[0].packageDate.eventDate,
                      packages: [],
                    };
                    this.store.dispatch(new SetSelectedSession(session));
                    this.store.dispatch(new SelectPackageSessions([session]));
                    this.router.navigate(["package-list/" + this.latestEventId], {
                      queryParams: {
                        sessionID: this.packageSession[0].id,
                      },
                    });
                  } else {
                    this.router.navigate(['date-and-session/' + this.latestEventId], { queryParams: { isContinueShopping: true } });
                  }
                } else if (this.bookingFlowOption == BOOKING_FLOW_OPTION.PackageListSessionVenueMap) {
                  this.router.navigate(['package-list/' + this.latestEventId], { queryParams: { isContinueShopping: true } });
                }
              }
            }
            else if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
              if (this.packageSession.length > 1) {
                this.router.navigate(['date-and-session/' + this.bookingDisplayCart[0].eventid], { queryParams: { isContinueShopping: true } });
              } else {
                this.router.navigate(['package-list/' + this.bookingDisplayCart[0].eventid], { queryParams: { isContinueShopping: true } });
              }
            }
          }
          else {
            this.store.dispatch(new ResetSeat());
            this.store.dispatch(new ResetBlock());
            this.store.dispatch(new ResetEventPackages());
            if (environment.PublicPortalHomeUrl.trim()) {
              window.location.href = environment.PublicPortalHomeUrl;
            } else {
              this.router.navigate(['event-category']);
            }
          }
        }
      });
    } else {
      this.store.dispatch(new ResetSeat());
      this.store.dispatch(new ResetBlock());
      this.store.dispatch(new ResetEventPackages());
      if (environment.PublicPortalHomeUrl.trim()) {
        window.location.href = environment.PublicPortalHomeUrl;
      } else {
        this.router.navigate(['event-category']);
      }
    }
  }

  viewAllEvents() {
    this.store.select(PBSelector.selectSelectedEventCategoryId).safeSubscribe(this, (id: string) => {
      if (!!id) {
        this.router.navigate(["/event-list", id]);
      } else {
        this.router.navigate(['event-category']);
      }
    });
  }

  changeSeats() {
    this.allowAllPackage = true;
    let bookedEventIds = [];
    this.bookingDisplayCart?.forEach(cart => {
      if (!this.isEditablePack(cart.cartPackages[0])) {
        bookedEventIds.push(cart.eventid);
      }
    });
    let bookedEvent = this.bookedEvents.filter(x => bookedEventIds.includes(x.id));
    if (bookedEvent.length == 1) {
      let bookingDisplayCart = this.bookingDisplayCart.find(bc => bc.eventid == bookedEvent[0].id);
      if (!bookingDisplayCart) {
        bookingDisplayCart = this.bookingDisplayCart[0];
      }
      this.editTicket(bookingDisplayCart.cartPackages[0].id, null, bookingDisplayCart);
    } else {
      const dialogRef = this.dialog.open(SelectEventComponent, {
        data: {
          bookedEvents: bookedEvent
        },
        panelClass: [],
        height: 'auto',
        width: '600px',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(eventId => {
        let xx = this.bookedPackages.filter(x => x.publicEvent.id == eventId);
        let y = this.bookingDisplayCart.filter(x => x.cartPackages.find(t => t.id == xx[0].id));
        this.editTicket(xx[0].id, null, y[0]);
      });
    }

  }

  addSpecialRequest() {
    let nBooking: Booking;
    let bookingItemTypes;
    let heading = this.optimoTranslate.transform('BOOKINGCART.Special Requests');
    let yesText = this.optimoTranslate.transform('BOOKINGCART.Save');
    let noText = this.optimoTranslate.transform('NAVMODEL.Close');
    const dialogRef = this.dialog.open(SpecialRequestsComponent, {
      data: {
        heading: heading,
        yesText: yesText,
        noText: noText
      },
      panelClass: [],
      height: 'auto',
      width: '400px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(specialNote => {
      if (specialNote) {
        if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
          const sessions = PBUtility.makeFullBookingSession(PBUtility.convertBookingIntoPBSessionData(this.booking, this.PBReducer.bookedPackages));
          const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBody(sessions, this.PBReducer, this.cart);
          nBooking = newBooking;
          bookingItemTypes = bookingItemTypeNames;
        } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
          nBooking = new Booking();
          nBooking.id = this.booking.id;
        }
        nBooking.specialNote = specialNote;
        this.tempSpecialNote = specialNote;
        if (nBooking.id) {
          let bookingPatch;
          if (this.cartType === BOOKING_CART.EDIT_BOOKING && this.IS_HAVE_TO_PAY_DUE_AMOUNT) {
            bookingPatch = this.bookingService.BookingPatchPreview(nBooking, this.patchPreviewId, bookingItemTypes)
          }
          if (this.cartType === BOOKING_CART.EDIT_BOOKING && !this.IS_HAVE_TO_PAY_DUE_AMOUNT) {
            bookingPatch = this.bookingService.BookingPatch(nBooking, [])
          } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
            bookingPatch = this.bookingService.BookingPatch(nBooking, [])
          }
          bookingPatch.safeSubscribe(this, b => {
            if (b.isError) {
              this.openSnackBarError('Unable to save Special Request...');
            }
          });
        }
      }
    });
  }

  checkMandatory() {
    let isValid = true;
    const itemBookingQuestionnaires = [];
    if (
      this.cartType === BOOKING_CART.NEW_BOOKING &&
      this.booking.bookingQuestionnaires &&
      this.booking.bookingQuestionnaires.length > 0
    ) {
      const question = this.booking.bookingQuestionnaires.filter(
        (q) =>
          q.type !== 'hostBookingQuestionnaire' &&
          q.type !== 'guestBookingQuestionnaire' &&
          q.type !== 'globalBookingQuestionnaire'
      );
      question?.forEach((bookingQuestionnaire) => {
        if (bookingQuestionnaire?.bookingQuestions)
          itemBookingQuestionnaires.push(
            ...bookingQuestionnaire?.bookingQuestions
          );
      });
      if (itemBookingQuestionnaires && itemBookingQuestionnaires.length > 0) {
        itemBookingQuestionnaires?.forEach((iqa) => {
          if (iqa.question.mandatory) {
            const isuna = this.isAnswered(iqa);
            if (!isuna) {
              isValid = false;
            }
          }
        });
      }
    }
    return isValid;
  }

  isAnswered(ques) {
    let isAnsweredForTheQuestion = false;
    switch (ques.type) {
      case 'textQuestionAnswer':
        if (ques.answer !== '' && ques.answer != undefined) {
          isAnsweredForTheQuestion = true;
        }
        break;
      case 'richTextQuestionAnswer':
        if (ques.answer !== '' && ques.answer != undefined) {
          isAnsweredForTheQuestion = true;
        }
        break;
      case 'booleanQuestionAnswer':
        if (ques.answer != undefined) {
          isAnsweredForTheQuestion = true;
        }
        break;

      case 'singleChoiceQuestionAnswer':
        if (ques.bookingAnswerChoice != undefined) {
          isAnsweredForTheQuestion = true;
        }
        break;

      case 'multipleChoiceQuestionAnswer':
        if (
          ques.bookingAnswerChoice != undefined &&
          ques.bookingAnswerChoice.length > 0
        ) {
          if (ques.question.quantityRequired) {
            isAnsweredForTheQuestion = true;
            ques.bookingAnswerChoice.forEach(function (bac) {
              if (
                bac.quantity == undefined ||
                bac.quantity == 0 ||
                bac.quantity == ''
              ) {
                isAnsweredForTheQuestion = false;
              }
            });
          } else {
            isAnsweredForTheQuestion = true;
          }
        }
        break;
      case 'dateAndTimeQuestionAnswer':
        if (
          ques.question != undefined &&
          ((ques.question.answerDateAndTime &&
            ques.date != undefined &&
            ques.time != undefined) ||
            (ques.question.answerDate && ques.date != undefined) ||
            (ques.question.answerTime && ques.time != undefined))
        ) {
          isAnsweredForTheQuestion = true;
        }
        break;
      case 'fileUploadQuestionAnswer':
        if (
          !!ques.answerUploadedFiles &&
          (ques.question as FileUploadQuestion).minCount <=
          ques.answerUploadedFiles.filter((x) => !x.isDelete).length
        ) {
          isAnsweredForTheQuestion = true;
        }
        break;
      default:
        isAnsweredForTheQuestion = true;
    }
    return isAnsweredForTheQuestion;
  }

  addAddonsForBookingPackage(pk: any) {
    let pkID;
    if (pk.cartPackages.length > 0) {
      const primePK = pk.cartPackages.filter((p) => !p.isLinkedPackage);
      pkID = primePK.length > 0 ? primePK[0].id : undefined;
      if (!pkID) {
        const linkPK = pk.cartPackages.filter(
          (p) => p.isLinkedPackage && !p.isChild
        );
        pkID = linkPK.length > 0 ? linkPK[0].id : undefined;
      }
      if (!pkID) {
        const linkPK = pk.cartPackages.filter(
          (p) => p.isLinkedPackage && p.isChild
        );
        pkID =
          linkPK.length > 0 && linkPK[0].primaryPkgId.length > 0
            ? linkPK[0].primaryPkgId
            : undefined;
      }
      if (pkID) {
        this.store.dispatch(new SetPageConfigurations([]));
        this.store.dispatch(new SetRedirectionPoint('booking-cart'));
        // this.store.dispatch(new AddPackage(pkID));
        this.router.navigate(['upsell', pkID], {
          queryParams: {
            BookingPackage: pk.id,
          },
        });
      }
    }
  }

  openPkgAttendeeList(bookingPackage: BookingPackage = null) {
    if (bookingPackage) {
      this.selectedPkgIds = [];
      this.selectedPkgIds.push(bookingPackage.id);
    }
    const refSer = this.bookingService.selectBookingHost(this.booking.id, this.selectedPkgIds, this.patchPreviewId ? this.patchPreviewId : '').subscribe(hosts => {
      if (hosts && hosts.length > 0) {
        hosts.forEach((host) => {
          const bookingPackage = this.bookingPackages.find(
            (p) => p.id == host.bookingPackage.id
          );
          if (bookingPackage) {
            const packageA = this.bookedPackages.find(x => x.id === bookingPackage.package.id)
            if (packageA && packageA.attendeeCaptureProfile) {
              bookingPackage.attendeeCaptureProfile = packageA.attendeeCaptureProfile
            }
            host.bookingPackage = bookingPackage;
          }
        });
        hosts = PBUtility.GetEligibleHosts(hosts);
        const dialogRef = this.dialog.open(AttendeeListModalComponent, {
          data: { bookingId: this.booking.id, hosts: hosts, canEdit: !this.booking.cancelled, isAttendeeEdited:this.isAttendeeEdited },
          height: 'auto',
          width: 'auto',
          maxHeight: '70%',
          panelClass: "Item-Session-Dialog-Box",
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (!!res.skipAttendeeCapture) {
            if (this.trigger_Questionnaire_AfterAttendeeCapture) {
              // this.popupQuestion();
            } else {
              // this.goToCheckout();
            }
          } else if (res?.isNewlyHost) {
            this.onAddAttendee(
              res.host,
              res.isNewlyHost,
              res.remainingGuestsCount,
              res.previousAttendees
            );
          } else if ((res.attendee, res.host)) {
            this.onEditAttendee(
              res.attendee,
              res.host,
              res.previousAttendees,
              res.remainingGuestsCount
            );
          }
        });
      }
      if (refSer) {
        refSer.unsubscribe();
      }
    });
  }

  openAttendeeList(bookingPackage: BookingPackage = null) {
    let bookingPackageIdList = this.bookingPackageIds;
    if (bookingPackage) {
      // remove other package id other than booking package
      bookingPackageIdList = this.bookingPackageIds.filter(x => x == bookingPackage.id);
    }
    const refSer = this.bookingService.selectBookingHost(this.booking.id, bookingPackageIdList, this.patchPreviewId ? this.patchPreviewId : '').subscribe(hosts => {
      if (hosts && hosts.length > 0) {
        hosts.forEach((host) => {
          const bookingPackage = this.bookingPackages.find(
            (p) => p.id == host.bookingPackage.id
          );
          if (bookingPackage) {
            const packageA = this.bookedPackages.find(x => x.id === bookingPackage.package.id)
            if (packageA && packageA.attendeeCaptureProfile) {
              bookingPackage.attendeeCaptureProfile = packageA.attendeeCaptureProfile
            }
            host.bookingPackage = bookingPackage;
          }
        });
        let HostNeedToCaptured = false;
        hosts = PBUtility.GetEligibleHosts(hosts);
        let results = _(hosts)
              .groupBy(x => x.bookingPackage && x.bookingPackage.id)
              .map((value, key) => ({ bookingPackageId: key, bookingPackageHosts: value}))
              .value();
        results.forEach(result => {
          let bookingPackageTotalAttendees = this.bookingPackages.find((p) => p.id == result.bookingPackageId)?.attendees;
          let totalAssignAttendees = result.bookingPackageHosts.reduce((accumulator, obj) => { return accumulator + obj.attendees;}, 0);
          if(bookingPackageTotalAttendees > 0 && totalAssignAttendees > 0 && bookingPackageTotalAttendees != totalAssignAttendees){
            HostNeedToCaptured = true;
          }
        })
        if (hosts && hosts.length > 0 && (hosts.find(x => x.namedHost == false) || HostNeedToCaptured)) {
          const dialogRef = this.dialog.open(AttendeeListModalComponent, {
            data: { bookingId: this.booking.id, hosts: hosts, canEdit: !this.booking.cancelled, isAttendeeEdited:this.isAttendeeEdited },
            height: 'auto',
            width: 'auto',
            maxHeight: '70%',
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (!!res.skipAttendeeCapture) {
              if (this.trigger_Questionnaire_AfterAttendeeCapture) {
                // this.popupQuestion();
              } else {
                if (this.getIsClientPaymentTermHasCredit) {
                  this.goToCheckout();
                } else {
                  this.onConfirm();
                }
              }
            } else if (res?.isNewlyHost) {
              this.onAddAttendee(
                res.host,
                res.isNewlyHost,
                res.remainingGuestsCount,
                res.previousAttendees
              );
            } else if ((res.attendee, res.host)) {
              this.onEditAttendee(
                res.attendee,
                res.host,
                res.previousAttendees,
                res.remainingGuestsCount
              );
            }
          });
        }
        else {
          if (this.getIsClientPaymentTermHasCredit) {
            this.goToCheckout();
          } else {
            this.onConfirm();
          }
        }
      }
      if (refSer) {
        refSer.unsubscribe();
      }
    });
  }

  onEditAttendee(
    attendee: Attendee,
    host: Host,
    previousAttendees: Attendee[],
    remainingGuestsCount: number
  ) {
    const findHostORGuestQuestion = (x) =>
      attendee.hostGuest
        ? x.type === 'hostBookingQuestionnaire' &&
        x.host &&
        x.host.id == host.id
        : x.type === 'guestBookingQuestionnaire' &&
        x.guest &&
        x.guest.id == attendee.id;
    let bookingQuestionnaire;
    if (this.booking.bookingQuestionnaires) {
      bookingQuestionnaire = this.booking.bookingQuestionnaires.find(
        findHostORGuestQuestion
      );
    }
    const bookingPackage: BookingPackages = this.bookingPackages.find(
      (p) => p.id == attendee.bookingPackage.id
    );
    if (bookingPackage && bookingPackage.package.id) {
      const refSer = this.packageService
        .selectPackageAttendeeCaptureProfile(bookingPackage.package.id)
        .subscribe((acp) => {

          const dialogRef = this.dialog.open(AttendeeEditModalComponent, {
            data: {
              attendee: attendee,
              host: host,
              bookingQuestionnaire: bookingQuestionnaire,
              attendeeCaptureProfile: acp,
              bookingId: this.booking.id,
              previousAttendees: previousAttendees,
              canEdit: !this.booking.cancelled,
              isAlreadyEdited: !!attendee?.auditInfo?.updatedTime ? true : false,
              remainingGuestsCount: remainingGuestsCount,
            },
            autoFocus: false,
            panelClass: "Item-Session-Dialog-Box",
            height: 'auto',
            width: 'auto',

          });
          dialogRef.afterClosed().subscribe((res) => {
            if (
              res.isChangeHostGuest &&
              res.switchedNewHost &&
              res.switchedNewHost.guests.length > 0
            ) {
              this.onEditAttendee(
                res.switchedNewHost.guests[0],
                res.switchedNewHost,
                previousAttendees,
                res.remainingGuestsCount
              );
            } else {
              if (res.isQuestionUpdate) {
                if (this.cartType === BOOKING_CART.NEW_BOOKING) {
                  this.store.dispatch(new UpdateSelectedBooking());
                }
              }
              if(res.isAttendeeEdited) {
                this.isAttendeeEdited = true;
              }
              if (this.selectedPkgIds && this.selectedPkgIds?.length > 0) {
                this.openPkgAttendeeList();
              } else {
                this.openAttendeeList();
              }
            }
          });
          if (refSer) {
            refSer.unsubscribe();
          }
        });
    }
  }

  onAddAttendee(
    host: Host,
    isNewlyHost: boolean,
    remainingGuestsCount: number,
    previousAttendees: Attendee[]
  ) {
    const bookingPackage: BookingPackages = this.bookingPackages.find(
      (p) => p.id == host.bookingPackage.id
    );
    if (bookingPackage && bookingPackage.package.id) {
      const refSer = this.packageService
      .selectPackageAttendeeCaptureProfile(bookingPackage.package.id)
      .subscribe((acp) => {
        const dialogRef = this.dialog.open(AttendeeAddModalComponent, {
          data: {
            bookingPackage: host.bookingPackage,
            packageDate: host.packageDate,
            contact: this.booking.contact,
            attendeeCaptureProfile: acp,
            bookingId: this.booking.id,
            previousAttendees: previousAttendees,
            canEdit: !this.booking.cancelled,
            isAlreadyEdited:  false,
            isNewlyHost: isNewlyHost,
            remainingGuestsCount: remainingGuestsCount,
          },
          autoFocus: false,
          panelClass: ['custom-dialog-container'],
          height: "auto",
          width: "50%",
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (
            res.isAttendeeAdded
          ) {
            if (res.isQuestionUpdate) {
              if (this.cartType === BOOKING_CART.NEW_BOOKING) {
                this.store.dispatch(new UpdateSelectedBooking());
              }
            }
            this.isAttendeeEdited = true;
            if (this.selectedPkgIds && this.selectedPkgIds?.length > 0) {
              this.openPkgAttendeeList();
            } else {
              this.openAttendeeList();
            }
          }
        });
        if (refSer) {
          refSer.unsubscribe();
        }
      });
    }
  }

  checkBookingHaveEligibleDeliveryMethod() {
    let isValid = false;
    if (this.deliveryMethods && this.deliveryMethods.length > 0) {
      if (this.deliveryMethods.find(x => x.id == "0")) // means no delivery method required \
      {
        isValid = true;
      }
      if (this.booking.deliveryMethod && this.deliveryMethods.find(x => x.id == this.booking.deliveryMethod.id)) {
        isValid = true;
      }
    }
    return isValid;
  }

  bookingConfirm() {
    if (this.booking.specialNote && this.cartType === BOOKING_CART.NEW_BOOKING) {
      this.SaveCardlessContent();
    }
    setTimeout(() => {
      if (this.cartType === BOOKING_CART.NEW_BOOKING) {
        if (this.checkBookingHaveEligibleDeliveryMethod()) {
          if (this.triggerAttendeeCapture) {
            if (this.isLoggedIn) {
              this.openAttendeeList();
            } else {
              if (this.getIsClientPaymentTermHasCredit) {
                this.onCheckout();
              } else {
                this.onConfirm();
              }
            }
          } else {
            if (this.getIsClientPaymentTermHasCredit) {
              this.onCheckout();
            } else {
              this.onConfirm();
            }
          }
        } else {
          this.openSnackBarError(['Delivery method required']);
        }
      }
    }, 500);
  }

  onConfirm() {
    let BookingConfirmMessages = (environment.PublicBookingSetting as PublicBookingSettings).toasterMessages.bookingConfirm;
    const newBooking = new Booking()
      newBooking.id = this.booking.id;
      newBooking.confirmed = true;
      newBooking.temporary = false;
      newBooking.salesChannelId = this.booking.salesChannelId;
      if (this.booking.specialNote) {
        newBooking.specialNote = this.booking.specialNote;
      }
      let bookingPatch
      this.store.dispatch(new PublicLoading());
      if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
        bookingPatch = this.bookingService.BookingPatchPreview(newBooking, this.patchPreviewId, [], false)
      } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
        bookingPatch = this.bookingService.BookingPatch(newBooking, [], undefined, undefined, false)
      }
      bookingPatch.safeSubscribe(this, b => {
        if (b.isError) {
          let unsuccessContent = BookingConfirmMessages.failedContent?.value?.length > 0 ? BookingConfirmMessages.failedContent?.value : ["Booking Request unsuccessfully."];
          this.openSnackBarError(unsuccessContent);
        } else {
          let successContent = BookingConfirmMessages.successContent?.value?.length > 0 ? BookingConfirmMessages.successContent?.value : ["Booking Request successfully created."];
          this.openSnackBarSuccess(successContent);
          setTimeout(() => {
            this.cartPanelExpanded = false;
            this.clearSession();
            this.store.dispatch(new PublicLoaded());
            var ul = environment.ApiUrl + "#/bookings";
            window.open(ul, "_self");
          }, 3000);
        }
      }, (error) => {
        let unsuccessContent = BookingConfirmMessages.failedContent?.value?.length > 0 ? BookingConfirmMessages.failedContent?.value : ["Booking Request unsuccessfully."];
        this.openSnackBarError(unsuccessContent);
      });
  }
  onCheckout() {
    //this.patchDeliveryMethod();
    //save the special request of booking for new booking
    if (this.booking.specialNote && this.cartType === BOOKING_CART.NEW_BOOKING) {
      this.SaveCardlessContent();
    }
    setTimeout(() => {
      if (this.cartType === BOOKING_CART.NEW_BOOKING) {
        if (this.checkBookingHaveEligibleDeliveryMethod()) {
          if (this.triggerAttendeeCapture) {
            if (this.isLoggedIn) {
              this.openAttendeeList();
            } else {
              this.goToCheckout();
            }
          } else {
            this.goToCheckout();
          }
        }
        else {
          this.openSnackBarError(['Delivery method required']);
        }
      } else if (this.cartType === BOOKING_CART.EDIT_BOOKING && !this.getIsPaymentContinueDisable && !this.IS_HAVE_TO_PAY_DUE_AMOUNT) {
        this.refund();
      } else {
        this.goToCheckout();
      }
    }, 500);
  }

  goToCheckout(disableMandatoryQuestion = false) {
    this.triggerPayment = false;
    // !! need to fix the Package Questionnaire validation  removed for temp
    if (this.checkMandatory() || disableMandatoryQuestion) {
      if (environment.ExternalIntegrationIdForPaymentGateway) {
        if (this.isLoggedIn) {
          // window.location.href = url;
          this.router.navigate(['/booking-checkout'], {
            queryParams: {
              bookingId: this.booking.id,
              InvoiceRef: this.booking.bookingReference
            }
          });

        } else {
          let url =
            'booking-checkout?InvoiceRef=' +
            this.booking.bookingReference;
          if (this.booking.id != undefined && this.booking.id != '') {
            url = url + '&bookingId=' + this.booking.id;
          }
          url = url + '&publicWeb=1';
          window.open(
            environment.ApiUrl + `#/client/login?DirectLoginFromPublicWeb=1&&bookingRef=${this.booking.id}&ReturnUrl=${url}`,
            '_self'
          );
        }
      }
    } else if (disableMandatoryQuestion == false) {
      const filteredBooking = _.cloneDeep(this.booking);
      filteredBooking.bookingQuestionnaires =
        filteredBooking.bookingQuestionnaires.filter(
          (q) =>
            q.type !== 'hostBookingQuestionnaire' &&
            q.type !== 'guestBookingQuestionnaire' &&
            q.type !== 'globalBookingQuestionnaire'
        );
      if (filteredBooking.bookingQuestionnaires.length > 0) {
        const dialog = this.dialog.open(PublicQuestionnaireComponent, {
          data: {
            booking: filteredBooking,
            isDisableSelect: true,
            isMandatory: true,
            enableClose: true,
          },
          panelClass: [
            'custom-dialog-container',
            'w50modal',
            'modal-width',
            'extrapop',
          ],
          height: 'auto',
          width: '50%',
        });
        dialog.afterClosed().subscribe((x) => {
          // this.store.dispatch(new UpdateSelectedBooking())
          if (x.redirect) {
            this.triggerPayment = true;
            this.goToCheckout(true);
          }
        });
      } else {
        this.goToCheckout(true);
      }
    } else {
      this.goToCheckout(true);
    }
  }

  refund() {
    const sessions = PBUtility.makeFullBookingSession(
      PBUtility.convertBookingPackageIntoPBSessionData(this.bookingPackages, this.PBReducer.bookedPackages)
    );
    const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(sessions, this.PBReducer, this.cart, this.bookingPackages);
    this.bookingService.BookingPatch(newBooking, bookingItemTypeNames, this.getDueAmount).safeSubscribe(this, (d) => {
      if (!d.isError) {
        //Payment/Success?&paidAmount=109&invoiceRef=11848&immediate=1&bookingTypeId=7
        this.goToRefund(this.getDueAmount)
      } else {
        this.openSnackBarError('Refund post fail');
      }

    })
  }

  goToRefund(amount) {
    let url =
      environment.ApiUrl +
      (environment.ApiUrl.endsWith('/') ? '' : '/') +
      'Payment/Success?'
    if (this.getDueAmount) {
      url = url + '&paidAmount=' + (amount < 0 ? (parseFloat(amount) * -1).toFixed() : amount.toFixed());
    }
    if (this.booking.id != undefined && this.booking.id != '') {
      url = url + '&invoiceRef=' + this.booking.id + '&immediate=1&bookingTypeId=7&isRefund=true';
    }
    if (this.selectedLanguage != null && this.selectedLanguage != '') {
      url = url + '&language=' + this.selectedLanguage;
    }
    const payData = {
      url: url,
      amount: amount < 0 ? (parseFloat(amount) * -1).toFixed(2) : amount.toFixed(2)
    };
    this.bookingService.setPaymentUrl(payData);
    this.router.navigate(['/make-payment']);
  }


  setQuestionnaireValue(bookingDisplayCart) {
    // bookingDisplayCart.forEach((bookingCart) => {
    //   // validate whole package questionnaire
    //   this.hasQuestionnairePkg(bookingCart);
    //   this.hasHostGuest(bookingCart);
    //   bookingCart.cartPackages.forEach((cartPackage) => {
    //     // validate package questionnaire
    //     this.hasQuestionnairePkgLevel(cartPackage);

    //   });
    //   bookingCart.cartItems.forEach((cartItem) => {
    //     // validate item questionnaire
    //     this.hasQuestionnaireItmLevel(cartItem, bookingCart);
    //   });
    // });
      const filteredBooking = _.cloneDeep(this.booking);
      bookingDisplayCart.forEach((bookingPkg) => {
        if (filteredBooking.bookingQuestionnaires && filteredBooking.bookingQuestionnaires.length > 0) {
          //hasQuestionnairePkg
          if (filteredBooking.bookingQuestionnaires.filter(
            (q) =>
              (q.type !== 'hostBookingQuestionnaire' && q.type !== 'guestBookingQuestionnaire' && q.type !== 'globalBookingQuestionnaire')
              &&
              ((q.bookingPackage && this.bookingPackages.find(pkg => pkg.id === q.bookingPackage.id))
                ||
                (q.bookingItem && bookingPkg.cartItems.find(x => x.id == q.bookingItem.id)))
              &&
              (q.bookingPackage && Utility.convertToISO(Utility.convertISOToDate(this.bookingPackages.find(pkg => pkg.id === q.bookingPackage.id).startDate)) == Utility.convertToISO(Utility.convertISOToDate(bookingPkg.startDate)))
          ).length>0){
            bookingPkg.hasQuestionnaire = true;
          }
          //hasQuestionnairePkgLevel
          bookingPkg.cartPackages.forEach((cartPackage) => {
            if (filteredBooking.bookingQuestionnaires.filter(
              (q) =>
                q.type == 'packageBookingQuestionnaire' && q.bookingPackage  && q.bookingPackage.id === cartPackage.bookingPackageId
            ).length > 0) {
              cartPackage.hasQuestionnaire = true;
            }
          });
          //hasQuestionnaireItmLevel
          bookingPkg.cartItems.forEach((cartItem) => {
            if (filteredBooking.bookingQuestionnaires.filter(
              (q) =>
                q.type == 'itemBookingQuestionnaire' && q.bookingItem && q.bookingItem.id === cartItem.id
                &&
                Utility.convertToISO(Utility.convertISOToDate(this.bookingPackages.find(pkg => pkg.id === q.bookingPackage.id).startDate)) == Utility.convertToISO(Utility.convertISOToDate(bookingPkg.startDate))
            ).length > 0) {
              cartItem.hasQuestionnaire = true;
            }
          });
        }
        //hasHostGuest
        if (this.bookingPackages && this.bookingPackages.length > 0) {
          filteredBooking.bookingPackages = this.bookingPackages.filter(
            (b) => b.package.id == bookingPkg.cartPackages[0].id && b.attendeeCaptureProfile && b.attendeeCaptureProfile.id !== '' && Number(b.attendeeCaptureProfile.id) > 0);

        if (filteredBooking.bookingPackages.length > 0) {
          bookingPkg.hasHostGuest = true;
        }
      }
    });
    return bookingDisplayCart;
  }

  checkQuestion(cartItems: CartItem[]) {
    let flag = false;
    cartItems.forEach((cartItem) => {
      if (cartItem.item && cartItem.item.itemQuestion) { flag = true; }
    });
    return flag;
  }

  mergeLinkedBookingPackages(bookingDisplayCart) {
    bookingDisplayCart.forEach((bookingPkg) => {
      if (bookingPkg.cartPackages[0].isChild) {
        const data = bookingDisplayCart.find(
          (x) =>
            x.cartPackages[0].id === bookingPkg.cartPackages[0].primaryPkgId &&
            new Date(x.startDate).getTime() ===
            new Date(bookingPkg.startDate).getTime() &&
            new Date(x.endDate).getTime() ===
            new Date(bookingPkg.endDate).getTime()
        );

        if (data) {
          data.totalPrice += bookingPkg.totalPrice;
          data.cartPackages.push(...bookingPkg.cartPackages);
          data.cartItems.push(..._.cloneDeep(bookingPkg.cartItems));
          bookingPkg.isMerged = true;

        }
      }
    });

    let i = bookingDisplayCart.length;
    while (i--) {
      if (
        bookingDisplayCart[i].cartPackages[0].isChild &&
        bookingDisplayCart[i].isMerged
      ) {
        bookingDisplayCart.splice(i, 1);
      }
    }
    bookingDisplayCart.forEach((bookingPkg) => {
      bookingPkg.quantity = 0;
      bookingPkg.cartPackages.forEach(x => {
        bookingPkg.quantity += x.quantity;
      });
    });
    return bookingDisplayCart;
  }

  hasQuestionnaire(bookingPackage) {
    const filteredBooking = _.cloneDeep(this.booking);
    if (filteredBooking.bookingQuestionnaires && filteredBooking.bookingQuestionnaires.length > 0) {
      filteredBooking.bookingQuestionnaires =
        filteredBooking.bookingQuestionnaires.filter(
          (q) =>
            q.type == 'packageBookingQuestionnaire' && q.bookingPackage && q.bookingPackage.package.id === bookingPackage.cartPackages[0].id
        );
      if (filteredBooking.bookingQuestionnaires.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getDateDiff(startDate, endDate, withText=true) {
    //calcualte days moment
    const diff = moment(endDate).diff(moment(startDate), 'days') + 1;
    if (withText) {
      let days = 'days';
      this.translate.get("BOOKINGCART." + days).safeSubscribe(this, (res: string) => {
        days = days.replace(days, res);
      });
      if (diff > 1) {
        return diff + days;
      } else {
        return '';
      }
    } else {
      return diff;
    }
  }

  isSameDate(item, type) {
    let startDate;
    let endDate;
    if (type === 'item') {
      startDate = item.startTime;
      endDate = item.endTime;
    }
    if (type === 'package') {
      startDate = item.startDate
      endDate = item.endDate
    }
    if (type === 'event') {
      startDate = item.eventDates[0].startTime
      endDate = item.eventDates[item.eventDates.length - 1].endTime
    }
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start.getDate() == end.getDate() && start.getMonth() == end.getMonth() && start.getFullYear() == end.getFullYear()) {
      return true;
    } else {
      return false;
    }
  }

  // hasQuestionnairePkg(pkg) {
  //   const filteredBooking = _.cloneDeep(this.booking);
  //   if (filteredBooking.bookingQuestionnaires && filteredBooking.bookingQuestionnaires.length > 0) {
  //     filteredBooking.bookingQuestionnaires =
  //       filteredBooking.bookingQuestionnaires.filter(
  //         (q) =>
  //           (q.type !== 'hostBookingQuestionnaire' && q.type !== 'guestBookingQuestionnaire' && q.type !== 'globalBookingQuestionnaire')
  //           &&
  //           ((q.bookingPackage && pkg.cartPackages.find(x => q.bookingPackage.package && x.id == q.bookingPackage.package.id))
  //             ||
  //             (q.bookingItem && pkg.cartItems.find(x => x.id == q.bookingItem.id)))
  //           &&
  //           (q.bookingPackage && Utility.convertToISO(Utility.convertISOToDate(q.bookingPackage.startDate)) == Utility.convertToISO(Utility.convertISOToDate(pkg.startDate)))
  //       );
  //     if (filteredBooking.bookingQuestionnaires.length > 0) {
  //       pkg.hasQuestionnaire = true;
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // hasQuestionnairePkgLevel(pkg) {
  //   const filteredBooking = _.cloneDeep(this.booking);
  //   if (filteredBooking.bookingQuestionnaires && filteredBooking.bookingQuestionnaires.length > 0) {
  //     filteredBooking.bookingQuestionnaires =
  //       filteredBooking.bookingQuestionnaires.filter(
  //         (q) =>
  //           q.type == 'packageBookingQuestionnaire' && q.bookingPackage && q.bookingPackage.package && q.bookingPackage.package.id === pkg.id
  //       );
  //     if (filteredBooking.bookingQuestionnaires.length > 0) {
  //       pkg.hasQuestionnaire = true;
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // hasQuestionnaireItmLevel(item, bookingPackage) {
  //   const filteredBooking = _.cloneDeep(this.booking);
  //   if (filteredBooking.bookingQuestionnaires && filteredBooking.bookingQuestionnaires.length > 0) {
  //     filteredBooking.bookingQuestionnaires =
  //       filteredBooking.bookingQuestionnaires.filter(
  //         (q) =>
  //           q.type == 'itemBookingQuestionnaire' && q.bookingItem && q.bookingItem.id === item.id
  //           &&
  //           Utility.convertToISO(Utility.convertISOToDate(q.bookingPackage.startDate)) == Utility.convertToISO(Utility.convertISOToDate(bookingPackage.startDate))
  //       );
  //     if (filteredBooking.bookingQuestionnaires.length > 0) {
  //       item.hasQuestionnaire = true;
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  // hasHostGuest(bookingPackage) {
  //   const filteredBooking = _.cloneDeep(this.booking);
  //   if (filteredBooking.bookingPackages && filteredBooking.bookingPackages.length > 0) {
  //     filteredBooking.bookingPackages = filteredBooking.bookingPackages.filter(
  //       (b) => b.package.id == bookingPackage.cartPackages[0].id && b.attendeeCaptureProfile && b.attendeeCaptureProfile.id !== '');

  //     if (filteredBooking.bookingPackages.length > 0) {
  //       bookingPackage.hasHostGuest = true;
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  popupQuestionPkg(bookingPackage: BookingCart) {
    let fBookingPackages = _.cloneDeep(this.freezedBookingPackages);
    let bookedPackageIndex = this.bookingPackages.findIndex(bp => bp.id == bookingPackage.bookingPackageId);
    let freezedbookedPackageIndex = fBookingPackages.findIndex(bp => bp.id == bookingPackage.bookingPackageId);
    //get bookingItems of bookingPackage
    if (bookedPackageIndex != -1 && this.bookingPackages[bookedPackageIndex].bookingItems.length == 0) {
      this.enableSpinner = true;
      this.bookingService.GetBookingItemForBookingPackage(this.booking.id, bookingPackage.bookingPackageId).safeSubscribe(this, (d) => {
        if (!d.isError && d.data) {
          let data = _.cloneDeep(d.data);
          if (this.patchItem.length > 0) {
            this.patchItem.forEach(pi => {
              let bookingItemIndex = data.findIndex(i => i.id == pi.bookingItemId)
              if (bookingItemIndex != -1) {
                data[bookingItemIndex].quantity = pi.quantity;
              }
            })
          }
          this.bookingPackages[bookedPackageIndex].bookingItems = data;
          this.bookingDisplayCart = this.createDisplayData(
            this.bookingPackages,
            this.bookedPackages
          );
          if (freezedbookedPackageIndex != -1) {
            fBookingPackages[freezedbookedPackageIndex].bookingItems = d.data;
            this.freezedBookingPackages = _.cloneDeep(fBookingPackages);
            this.freezedBookingCarts = this.createDisplayData(
              this.freezedBookingPackages,
              this.bookedPackages
            );
          }
          this.enableSeatDetails();
          this.popupQuestionPkgHeaderLevel(bookingPackage);
        }
        setTimeout(() => { this.enableSpinner = false; }, 1000);
      }, (error) => {
        this.openSnackBarError(["booking Item undefined for this bookingPackage"]);
        setTimeout(() => { this.enableSpinner = false; }, 1000);
      });
    } else {
      this.popupQuestionPkgHeaderLevel(bookingPackage);
    }
  }

  popupQuestionPkgHeaderLevel(bookingPackage: BookingCart) {
    const filteredBooking = _.cloneDeep(this.booking);
    filteredBooking.bookingQuestionnaires.forEach(bq => {
      if (bq.bookingPackage && !bq.bookingPackage?.name) {
        let bpack = this.bookingPackages.find(bp => bp.id == bq.bookingPackage.id)
        if (bpack) {
          bq.bookingPackage = bpack
        }
      }
    })
    const filteredBookingPackages = _.cloneDeep(this.bookingPackages);
    filteredBooking.bookingQuestionnaires =
      filteredBooking.bookingQuestionnaires.filter(
        (q) =>

          (q.type !== 'hostBookingQuestionnaire' && q.type !== 'guestBookingQuestionnaire' && q.type !== 'globalBookingQuestionnaire')
          &&
          ((q.bookingPackage && bookingPackage.cartPackages.find(x => x.bookingPackageId == q.bookingPackage.id))
            ||
            (q.bookingItem && bookingPackage.cartItems.find(x => x.id == q.bookingItem.id)))
          && q.bookingPackage && Utility.convertToISO(Utility.convertISOToDate(q.bookingPackage.startDate)) == Utility.convertToISO(Utility.convertISOToDate(bookingPackage.startDate)));
    const dialog = this.dialog.open(PublicQuestionnaireComponent, {
      data: { booking: filteredBooking, bookingPackages: filteredBookingPackages, enableClose: true },
      panelClass: [
        'custom-dialog-container',
        'w50modal',
        'modal-width',
        'extrapop',
      ],
      height: 'auto',
      width: '50%',
    });
  }

  popupQuestionPkgLevel(pkg, bookingPackage) {
    const filteredBooking = _.cloneDeep(this.booking);
    filteredBooking.bookingQuestionnaires.forEach(bq => {
      if (bq.bookingPackage && !bq.bookingPackage?.name) {
        let bpack = this.bookingPackages.find(bp => bp.id == bq.bookingPackage.id)
        if (bpack) {
          bq.bookingPackage = bpack
        }
      }
    })
    const filteredBookingPackages = _.cloneDeep(this.bookingPackages);
    filteredBooking.bookingQuestionnaires =
      filteredBooking.bookingQuestionnaires.filter(
        (q) =>
          q.type == 'packageBookingQuestionnaire' && q.bookingPackage && q.bookingPackage.package.id === pkg.id && Utility.convertToISO(Utility.convertISOToDate(this.bookingPackages.find(pkg => pkg.id === q.bookingPackage.id).startDate)) == Utility.convertToISO(Utility.convertISOToDate(bookingPackage.startDate)));
    const dialog = this.dialog.open(PublicQuestionnaireComponent, {
      data: { booking: filteredBooking, bookingPackages: filteredBookingPackages, enableClose: true },
      panelClass: [
        'custom-dialog-container',
        'w50modal',
        'modal-width',
        'extrapop',
      ],
      height: 'auto',
      width: '50%',
    });
  }

  // Item Level Questionnaire
  popupQuestionItmLevel(itm, bookingPackage) {
    // needs to be implemented once the item level questionnaire is available
    const filteredBooking = _.cloneDeep(this.booking);
    const filteredBookingPackages = _.cloneDeep(this.bookingPackages);
    filteredBooking.bookingQuestionnaires =
      filteredBooking.bookingQuestionnaires.filter(
        (q) =>
          q.type == 'itemBookingQuestionnaire' && q.bookingItem && q.bookingItem.id === itm.id
        // && Utility.convertToISO(Utility.convertISOToDate(q.bookingItem.startDate)) == Utility.convertToISO(Utility.convertISOToDate(bookingPackage.startDate))
      );
    const dialog = this.dialog.open(PublicQuestionnaireComponent, {
      data: { booking: filteredBooking, bookingPackages: filteredBookingPackages, enableClose: true },
      panelClass: [
        'custom-dialog-container',
        'w50modal',
        'modal-width',
        'extrapop',
      ],
      height: 'auto',
      width: '50%',
    });
  }

  patchDeliveryMethod() {
    let nBooking: Booking;
    let bookingItemTypes;
    if (this.cartType === BOOKING_CART.EDIT_BOOKING && this.isConfirmAndPayBtn) {
      const sessions = PBUtility.makeFullBookingSession(PBUtility.convertBookingPackageIntoPBSessionData(this.bookingPackages, this.bookedPackages));
      const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(sessions, this.PBReducer, this.cart, this.bookingPackages);
      nBooking = newBooking;
      bookingItemTypes = bookingItemTypeNames;
    } else {
      nBooking = new Booking();
      nBooking.id = this.booking.id;
    }
    //nBooking = new Booking();
    //nBooking.id = this.booking.id;
    //we need only deliverymethod patch didn't need to all the package details
    // let bookingItemTypes;
    // if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
    //   const sessions = PBUtility.makeFullBookingSession(PBUtility.convertBookingPackageIntoPBSessionData(this.bookingPackages, this.bookedPackages));
    //   const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(sessions, this.PBReducer, this.cart, this.bookingPackages);
    //   nBooking = newBooking;
    //   bookingItemTypes = bookingItemTypeNames;
    // } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
    //   nBooking = new Booking();
    //   nBooking.id = this.booking.id;
    // }
    if (this.booking.specialNote)
      nBooking.specialNote = this.booking.specialNote;
    if (nBooking.id) {
      if (this.selectedDeliveryMethod) {
        nBooking.deliveryMethod = new DeliveryMethod();
        nBooking.deliveryMethod.id = this.selectedDeliveryMethod.id;
      }
      else {
        nBooking.deliveryMethod = new DeliveryMethod();
        nBooking.deliveryMethod.id = '0';
      }

      this.enableSpinner = true;
      // this.enableDeliveryMethodSpinner = true;
      let bookingPatch;
      if (this.cartType === BOOKING_CART.EDIT_BOOKING && (this.booking.bookingTypeId != null || this.booking.bookingTypeId != 0)) {
        bookingPatch = this.bookingService.BookingPatchPreview(nBooking, this.patchPreviewId, [], true);
      } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
        bookingPatch = this.bookingService.BookingPatch(nBooking, [], undefined, undefined, true);
      }
      if (bookingPatch) {
        bookingPatch.safeSubscribe(this, b => {
          if (b.isError) {
            this.openSnackBarError('Delivery Method post fail');
            this.selectedDeliveryMethod = undefined
            this.selectedDeliveryMethodID = undefined
          } else {
            if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
              this.booking = this.setBooking(b.data);
              this.bookingTotalAmount = PBUtility.getBookingTotal(this.booking);
            } else {
              this.booking = this.setBooking(b.data.booking);
            }
            this.store.dispatch(new SetBooking(this.booking));

          }
          this.enableSpinner = false;
        }, (error) => {
          this.openSnackBarError(["Delivery selection failed"]);
          this.enableSpinner = false;
        });
      }
    }
    this.seatPanelExpanded = false;
    this.upsellPanelExpanded = false;
    //}
  }

  // Icon change
  sortingIconChange() {
    const icon = document.getElementById('sortingIcon');
    if (this.sortingVal) {
      icon.classList.remove('fa-sort-numeric-desc');
      icon.classList.add('fa-sort-numeric-asc');
    } else {
      icon.classList.remove('fa-sort-numeric-asc');
      icon.classList.add('fa-sort-numeric-desc');
    }
    this.sortCart(this.sortingVal);
    this.sortingVal = !this.sortingVal;
  }

  // Sorting ASC DES
  sortCart(sortingVal) {
    sortingVal
      ? this.bookingDisplayCart.sort((a, b) =>
        a.startDate < b.startDate ? 1 : -1
      )
      : this.bookingDisplayCart.sort((a, b) =>
        a.startDate > b.startDate ? 1 : -1
      );
  }

  refreshBookingEditSpinner() {
    // get current timestamp
    const currentTime = new Date().getTime();
    this.bookingPatchTimer = currentTime + 1000;
    this.validatedBooking = false;
    // trigger on first event change
    if (!this.bookingPatchTimerTriggered) {
      this.getCurrentDate();
      this.bookingPatchTimerTriggered = true;
    }

  }

  // getCurrentDate(id = null) {
  //   this.timer = setInterval(() => {
  //     this.bookingPatchTimer = new Date().getTime(); // set time variable with current date
  //     if (this.bookingPatchTimerTriggerTimer < this.bookingPatchTimer) {
  //       if (this.cartType == this.BOOKING_CART.NEW_BOOKING) {
  //         this.checkBookingPatchPreview();
  //         this.buttonTriggerPriviousTime = 0;
  //       } else {
  //         this.checkBookingPatchPreview(id);
  //         this.buttonTriggerPriviousTime = 0;
  //       }
  //       this.bookingPatchTimerTriggered = false;
  //       clearInterval(this.timer);
  //     }
  //   }, 1000); // set it every one second
  // }
  // onItemQuantityChange(e, index: number) {
  //   if (this.buttonTriggerPriviousTime != 0) {
  //     let timeDiff = new Date().getTime() - this.buttonTriggerPriviousTime;
  //     this.buttonTriggerPriviousTime = new Date().getTime();
  //     if(timeDiff < 1500) {
  //       clearInterval(this.timer);
  //       this.bookingPatchTimerTriggered = false;
  //     }
  //   } else {
  //     this.buttonTriggerPriviousTime = new Date().getTime();
  //   }
  //   if(this.cartType == this.BOOKING_CART.EDIT_BOOKING && index != -1) {
  //     let bookingPackage = this.bookingDisplayCart[index];
  //     if (bookingPackage) {
  //       this.onCartValueChange(bookingPackage.id);
  //     }
  //   } else {
  //     this.onCartValueChange();
  //   }
  //   this.expandedBookingPackageIndex = index;
  // }

  getRepeatPolicyChildTotal(item, bookingPackage) {
    let upsell: any;
    if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
      upsell = this.freezedBookingCarts?.find(cart => cart.bookingPackageId === bookingPackage.bookingPackageId)?.cartItems.find(itm => itm.bookingItemId === item.bookingItemId)
    } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
      upsell = item;
    }
    let total = 0;
    if (upsell && upsell.child && upsell.child.length > 0) {
      upsell.child.forEach(itm => {
        total = total + (itm.quantity * itm.unitPrice);
      });
      return total.toFixed(2)
    }
  }

  getRepeatDisplayPolicyChildTotal(item, bookingPackage) {
    let upsell: any;
    if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
      upsell = this.bookingDisplayCart.find(cart => cart.bookingPackageId === bookingPackage.bookingPackageId)?.cartItems.find(itm => itm.bookingItemId === item.bookingItemId)
    } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
      upsell = item;
    }
    let total = 0;
    if (upsell && upsell.child && upsell.child.length > 0) {
      upsell.child.forEach(itm => {
        total = total + (itm.quantity * itm.unitPrice);
      });
      return total.toFixed(2)
    }
  }

  //#region Popup

  //#endregion

  //Edit Mode Functions
  getPkgUnitPrice(pkg) {
    if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
      let pkgId = pkg.id;

      let previousPackage;
      this.freezedBookingCarts?.forEach(cart => {
        cart.cartPackages.forEach(cartPkg => {
          if (cartPkg.id === pkgId) {
            previousPackage = cartPkg;
          }
        })
      })

      if (previousPackage) {
        if (pkg.quantity != previousPackage.quantity) {
          return previousPackage.unitPrice * previousPackage.quantity;
        }
      }
    }
    return pkg.unitPrice * pkg.quantity
  }

  getItmUnitPrice(itm, bookingId) {
    if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
      let itmId = itm.id;
      let previousItem;
      let freezedBookingCart = this.freezedBookingCarts?.find(cart => cart.id === bookingId);
      if (freezedBookingCart) {
        this.freezedBookingCarts?.forEach(cart => {
          if (cart.bookingPackageId === bookingId) {
            cart.cartItems.forEach(cartItm => {
              if (cartItm.repeatPolicy && cartItm.repeatPolicy == this.repeatPolicies.allowIndividualDays) {
                cartItm.child.forEach(child => {
                  if (child.id === itmId) {
                    previousItem = child;
                  }
                })
              } else {
                if (cartItm.id === itmId) {
                  previousItem = cartItm;
                }
              }
            })
          }
        })

        if (previousItem) {
          if ((itm.unitPrice * itm.quantity) != (previousItem.unitPrice * previousItem.quantity)) {
            return previousItem.unitPrice * previousItem.quantity;
          }
        }
      }
    }
    return itm.unitPrice * itm.quantity
  }

  getPackageTotalPrice(bookingPackageId = null) {
    let total = 0;
    if (bookingPackageId != null) {
      let bookingPack = this.bookingPackages.find(bp => bp.id == bookingPackageId);

      let bookingPackage = this.bookingDisplayCart.find(pkg => pkg.bookingPackageId === bookingPackageId);

      let freezedPackage = this.freezedBookingCarts?.find(pkg => pkg.bookingPackageId === bookingPackageId);
      if ((bookingPackage != freezedPackage && this.cartType === BOOKING_CART.EDIT_BOOKING) || this.cartType === BOOKING_CART.NEW_BOOKING) {
        if(bookingPackage.cartPackages.find(cp => cp.unitPrice > 0) && bookingPack?.bookingItems?.length > 0){
          bookingPackage.cartPackages.forEach(pkg => {
            total += pkg.quantity * pkg.unitPrice
          })
          bookingPackage.cartItems.forEach(itm => {
            if (itm.child.length > 0) {
              itm.child.forEach(child => {
                total += child.quantity * (child.unitPrice * child.units)
              })
            } else {
              total += itm.quantity * (itm.unitPrice * itm.units)
            }
          })
        } else {
          if (this.booking.showPriceWithTax)
            total += bookingPackage.grossAmount;
          else
            total += bookingPackage.netAmount;
        }

        return total.toFixed(2);
      }
      return bookingPackage.totalPrice.toFixed(2);
    } else {
      this.bookingDisplayCart.forEach(pkg => {
        pkg.cartPackages.forEach(pkg => {
          total += pkg.quantity * pkg.unitPrice
        })
        pkg.cartItems.forEach(itm => {
          total += itm.quantity * itm.unitPrice
        })
      })
      return total;
    }
  }

  getFreezedPackageTotalPrice(bookingPackageId = null) {
    let total = 0;
    if (bookingPackageId != null) {
      let bookingPack = this.bookingPackages.find(bp => bp.id == bookingPackageId);

      let bookingPackage = this.bookingDisplayCart.find(pkg => pkg.bookingPackageId === bookingPackageId);

      let freezedPackage = this.freezedBookingCarts?.find(pkg => pkg.bookingPackageId === bookingPackageId);

      if (freezedPackage && bookingPackage != freezedPackage && this.cartType === BOOKING_CART.EDIT_BOOKING) {
        if (freezedPackage.cartPackages.find(cp => cp.unitPrice > 0) && bookingPack?.bookingItems?.length > 0) {
          freezedPackage.cartPackages.forEach(pkg => {
            total += pkg.quantity * pkg.unitPrice
          })
          freezedPackage.cartItems.forEach(itm => {
            if (itm.child.length > 0) {
              itm.child.forEach(child => {
                total += child.quantity * child.unitPrice
              })
            } else {
              total += itm.quantity * itm.unitPrice
            }
          })
        } else {
          total += this.booking.showPriceWithTax ? freezedPackage.grossAmount : freezedPackage.netAmount;
        }

        return total.toFixed(2);
      }
      return bookingPackage.totalPrice.toFixed(2);
    } else {
      this.bookingDisplayCart.forEach(pkg => {
        pkg.cartPackages.forEach(pkg => {
          total += pkg.quantity * pkg.unitPrice
        })
        pkg.cartItems.forEach(itm => {
          total += itm.quantity * itm.unitPrice
        })
      })
      return total;
    }
  }

  getUpsellData(packageID, bookingPackageID, index) {
    let searchFilter = new SearchBaseFilter();
    const baseParams = new filterParam();
    baseParams.packageID = packageID;
    searchFilter.paging.number = "1";
    searchFilter.paging.size = "10";
    if (bookingPackageID) {
      const bookingPackage = this.bookingPackages.find(
        (b) => b.id === bookingPackageID
      );
      if (bookingPackage) {
        if (searchFilter.fromDate === "")
          searchFilter.fromDate = bookingPackage.startDate.toLocaleString();
        if (searchFilter.toDate === "")
          searchFilter.toDate = bookingPackage.endDate.toLocaleString();
      }
    }
    if (this.bookedPackages[index].upsellItem.length == 0)
      this.packageService
        .searchUpsellItems(baseParams, searchFilter)
        .safeSubscribe(this, (d) => {
          this.bookedPackages[index].upsellItem = d.lst;
          this.bookingDisplayCart = this.createDisplayData(
            this.bookingPackages,
            this.bookedPackages
          );
          this.enableSeatDetails();
        }, (error) => {
        });
  }

  deletePackages(bookingPackageId, bookingPackage) {
    if (this.bookingDisplayCart.length == 1) {
      this.enableCustomCancelMessage = true
    } else {
      this.enableCustomCancelMessage = false
    }
    //Cancel Booking If there are only one package available
    let isCancelBooking = false;
    if (this.bookingDisplayCart.length == 1) {
      const cartPK = this.bookingDisplayCart[0].cartPackages.find(c => c.bookingPackageId == bookingPackageId)
      if (this.cartType === BOOKING_CART.NEW_BOOKING) {
        const dialog = this.dialog.open(BookingValidationConfirmationModalComponent, {
          data: {
            title: this.translate.instant('BOOKINGCART.Clear your Cart'),
            message: this.translate.instant('BOOKINGCART.If you remove all items from your booking, it will be cancelled. Are you sure you want to proceed?'),
            isAdultDelete: true
          },
          panelClass: [
            // 'custom-dialog-container',
            'w50modal',
            // 'modal-width',
            'extrapop',
          ],
          height: 'auto',
          width: 'auto',
        });
        dialog.afterClosed().safeSubscribe(this, res => {
          if (res == true) {
            this.isCancelModalOpen = true;
            this.cancelBooking(this.cartType);
          }
        });

      } else if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
        this.getCancellationFeeAndReasons()
      }
    }
    //End Cancel Booking If there are only one package available
    else {
      if (this.cartType === BOOKING_CART.NEW_BOOKING) {
        this.enableSpinner = true;
        //Adult child Validation
        let selectedPackageForDelete = this.bookingDisplayCart.find(cart => cart.bookingPackageId == bookingPackageId)?.cartPackages.find(pkg => pkg.bookingPackageId == bookingPackageId);
        if (selectedPackageForDelete && selectedPackageForDelete.isLinkedPackage) {
          const dialog = this.dialog.open(BookingValidationConfirmationModalComponent, {
            data: {
              title: this.translate.instant('DELETEPOPUPINFO.Delete_Head'),
              message: this.translate.instant('DELETEPOPUPINFO.Delete_Message_IMG'),
              isAdultDelete: true
            },
            panelClass: [
              'custom-dialog-container',
              'w50modal',
              'extrapop',
            ],
            height: 'auto',
            width: '50%',
            autoFocus: false
          });
          dialog.afterClosed().safeSubscribe(this, res => {
            if (res) {
              let selectedCart = this.bookingDisplayCart.find(cart => cart.bookingPackageId == bookingPackageId);
              let request = [];
              let errors = [];
              if (selectedCart.cartPackages && selectedCart.cartPackages.length > 0) {
                selectedCart.cartPackages.forEach(itm => {
                  request.push(this.bookingService.deleteBookingPackage(this.booking.id, itm.bookingPackageId, true));
                })
                this.enableSpinner = true;
                const observable = forkJoin(request);
                observable.safeSubscribe(this, (results: any[]) => {
                  const isError = false;
                  results.forEach(result => {
                    if (result.body.isError) {
                      errors.push(result.body.message);
                      this.enableSpinner = false;
                    } else {
                      this.store.dispatch(new SetBooking(result.body.data));
                      this.getDeliveryMethod()
                      this.enableSpinner = false;
                    }
                  })

                  if (errors.length > 0) {
                    // console.log(errors);
                  }
                  this.store.dispatch(new UpdateSelectedBooking());
                });
              } else {
                //need to check if more than one display cart
                this.enableSpinner = false;
              }
            }
            else {
              this.enableSpinner = false;
            }
          });
        } else
          if (selectedPackageForDelete && !selectedPackageForDelete.isLinkedPackage) {
            const dialog = this.dialog.open(BookingValidationConfirmationModalComponent, {
              data: {
                title: this.translate.instant('DELETEPOPUPINFO.Remove_Head'),
                message: this.translate.instant('DELETEPOPUPINFO.Remove_message'),
                isAdultDelete: true
              },
              panelClass: [
                // 'custom-dialog-container',
                'w50modal',
                // 'modal-width',
                'extrapop',
              ],
              height: 'auto',
              width: 'auto',
            });
            dialog.afterClosed().safeSubscribe(this, res => {
              this.enableSpinner = true;
              if (res && this.bookingDisplayCart.length != 1) {
                let selectedCart = this.bookingDisplayCart.find(cart => cart.bookingPackageId == bookingPackageId);
                let request = [];
                let errors = [];
                if (selectedCart.cartPackages && selectedCart.cartPackages.length > 0) {
                  selectedCart.cartPackages.forEach(itm => {
                    request.push(this.bookingService.deleteBookingPackage(this.booking.id, itm.bookingPackageId, true));
                  })
                  this.enableSpinner = true;
                  const observable = forkJoin(request);
                  observable.safeSubscribe(this, (results: any[]) => {
                    const isError = false;
                    results.forEach(result => {
                      if (result.body.isError) {
                        errors.push(result.body.message);
                        this.enableSpinner = false;
                      } else {
                        this.store.dispatch(new SetBooking(result.body.data));
                        this.getDeliveryMethod()
                        this.enableSpinner = false;
                      }
                    })

                    if (errors.length > 0) {
                      // console.log(errors);
                    }
                  });
                } else {
                  //need to check if more than one display cart
                  this.enableSpinner = false;
                }
              } else if (res && this.bookingDisplayCart.length == 1) {
                this.enableSpinner = false;
                document.getElementById('clearBasketBtn').click()
              } else {
                this.enableSpinner = false;
              }
            });
          } else {
            let deletePkg = this.bookingService.deleteBookingPackage(this.booking.id, bookingPackageId, true).subscribe(res => {
              if (res.body && !res.body.isError) {
                // this.store.dispatch(new UpdateSelectedBooking());
                this.store.dispatch(new SetBooking(res.body.data))
                this.enableSpinner = false;
              } else if (res.body.isError) {
                this.enableSpinner = false;
              }
            })
          }
      } else {
        this.deletePackageWithPatchPreview(bookingPackageId, bookingPackage);
      }
    }
    //deletePkg.unsubscribe();
  }

  deleteItems(item, bookingPackage) {
    if (this.cartType === BOOKING_CART.NEW_BOOKING) {
      this.enableSpinner = true;
      let request = [];
      let errors = [];
      if (item.child && item.child.length > 0) {
        item.child.forEach(itm => {
          request.push(this.bookingService.deleteBookingItem(this.booking.id, itm.id, true));
        })
        const observable = forkJoin(request);
        observable.safeSubscribe(this, (results: any[]) => {
          let isAllSuccess = results.every(result => !result.body.isError);
          if (isAllSuccess) {
            this.store.dispatch(new UpdateSelectedBooking());
          } else {
            this.openSnackBarError(["Something went wrong, please try again"]);
            this.store.dispatch(new UpdateSelectedBooking());
          }

          this.enableSpinner = false;
        });
      }
      else {
        let deleteItm = this.bookingService.deleteBookingItem(this.booking.id, item.id, true).subscribe(res => {
          if (res) {
            let result = res.body.data;
            if (result.isError) {
              this.openSnackBarError(['Item Delete Failed!'])
            } else {
              // this.store.dispatch(new UpdateSelectedBooking());
              //Add Booking
              this.booking = this.setBooking(res.body.data.data);
              this.store.dispatch(new SetBooking(this.booking))
              this.bookingDisplayCart = this.createDisplayData(
                this.booking.bookingPackages,
                this.bookedPackages
              );
              this.openExpansion(-1, this.selectedBookingPackageID);
              //this.store.dispatch(new SetBooking(res.body.data.data))

            }
            this.enableSpinner = false;
          }
        })
        //deleteItm.unsubscribe();
      }
    } else {
      this.deleteItemWithPatchPreview(item, bookingPackage);
    }
  }

  getCancellationFeeAndReasons() {
    if (this.cancellationReason.length == 0) {
      this.bookingService.getCancellationReasons().safeSubscribe(this, (res) => {
        this.cancellationReason = res
      })
    }
    this.bookingService.getCancellationFee(this.booking.id).subscribe(res => {
      if (res) {
        if (res && res.length > 0) {
          const cancellationFee = res.find(fee => fee.cancellationPolicyDetail && fee.cancellationPolicyDetail.isCurrentFee)
          this.cancellationFee = cancellationFee ? cancellationFee.cancellationChargeIncludingTax : 0;
          document.getElementById('cancelBookingModalTrigger').click();
        }
      } else {
        this.openSnackBarError(['Cancellation Fee Not Found!'])
      }

    }
    )
  }

  openDescriptionModal(bookingItem, type) {
    let item: any;
    let isUpsell = false;

    if (type == "package") {
      item = this.bookingPackages.find(pkg => pkg.id == bookingItem.id);
    } else {
      for (const pack of this.bookingPackages) {
        for (const itm of pack.bookingItems) {
          if (itm.id == bookingItem.id) {
            item = itm;
            break;
          }
        }
        if (item) {
          break;
        }
      }
      isUpsell = true;
    }

    const dialogRef = this.dialog.open(PackageDescriptionModalComponent, {
      data: {
        isUpsell: isUpsell,
        pkg: item,
        booking: bookingItem
      },
      height: "auto",
      minHeight: "60%",
      width: "50%",
      panelClass: ['description-dialog-container'],
    });

    dialogRef.afterClosed().safeSubscribe(this, (e) => {
    });
  }


  checkAvailable(bookingItem) {
    let item: any
    let allocation = false;
    item = this.bookingPackages.find(pkg => pkg.id == bookingItem.id);
    if (item?.bookingItems?.length > 0 && !allocation) {
      item.bookingItems.forEach(i => {
        if (i.type && i.type == "publicBookingSeatingItem") {
          allocation = true;
        }
      });
    }
    return allocation;
  }

  openSeatDetail(bookingItem, type) {
    this.seatDetails = [];
    let item: any
    if (type == "package" && this.booking) {
      bookingItem.cartPackages.forEach(cartPack => {
        item = this.bookingPackages.find(pkg => pkg.id == cartPack.bookingPackageId);
        if (item && item.bookingItems && item.bookingItems.length > 0) {
          item.bookingItems.forEach(i => {
            if (i.type == "publicBookingSeatingItem" && i.allocations) {
              i.allocations?.forEach(allo => {
                if (allo?.seat && allo?.seat?.eventBlock?.id) {
                  allo.seat.assignedPackage = cartPack;
                  this.seatDetails.push(allo.seat);
                }
              })
            }
          })
        }
      })
    }
    return this.seatDetails;
  }

  getDispatchStatus() {
    if (this.bookingDisplayCart && this.bookingDisplayCart.length > 0) {
      this.bookingDisplayCart.forEach(booking => {
        this.bookingIds.push(booking.id)
      })
    }
    if (!this.isRetrivedData) {
      // this.enableSpinner=true;
      this.bookingService.getDispatchStatus(this.booking.id, this.bookingIds).subscribe(res => {
        if (res) {
          this.isRetrivedData = true;
          this.dispatchStatus = res;
          // this.enableSpinner=false;
        }
      })
    }
  }

  openDispatchTable() {
    if (this.dispatchStatus && this.dispatchStatus.dispatchable) {
      const dialogRef = this.dialog.open(DispatchStatusModalComponent, {
        data: {
          dispatchStatus: this.dispatchStatus,
        },
        panelClass: [
          "custom-dialog-container",
          "w50modal",
          "modal-width",
          "extrapop",
        ],
        height: "auto",
        width: "50%",
      });
      dialogRef.afterClosed().safeSubscribe(this, (e) => {
      });
    }
  }

  goback() {
    if (this.countPendingInvoices == 1) {
      this.store.dispatch(new FacilitySearchLoading());
      this._bookingService.InvoiceView(this.booking.invoices[0].id).safeSubscribe(this,
        (res) => {
          if (res.data) {
            window.open((res.data), '_blank');
          }
        },
        err => { this.openSnackBarError(["Unable to load invoice information. Please try again."]); },
        () => { this.store.dispatch(new FacilitySearchLoaded()); });
    } else {
      const selectedbooking: ISelectBooking = {
        id: this.selectedBookingId,
        bookingpackageCount: this.bookingPackagesLength
      }
      this.store.dispatch(new SetSelectedBooking(selectedbooking));
      var ul = environment.ApiUrl + "#/bookings";
      window.open(ul, "_self");
    }
  }

  isPastBooking(eventEndDate) {
    if (eventEndDate && ((moment(eventEndDate).valueOf() - moment(new Date()).valueOf()) >= 0)) {
      return false;
    }
    else {
      return true;
    }
  }

  editTicket(id, seat = null, pack = null) {
    if (pack?.seats.length > 0) {
      this.editTicketBookingApp(id, seat);
    } else {
      let bookedPackageIndex = this.booking.bookingPackages.findIndex(bp => bp.id == pack.bookingPackageId);
      if (bookedPackageIndex != -1 && this.booking.bookingPackages[bookedPackageIndex].bookingItems.length == 0) {
        this.bookingService.GetBookingItemForBookingPackage(this.booking.id, pack.bookingPackageId).safeSubscribe(this, (d) => {
          if (!d.isError && d.data) {
            let data = d.data;
            this.booking.bookingPackages[bookedPackageIndex].bookingItems = d.data;
            this.store.dispatch(new SetBooking(this.booking));
            this.editTicketBookingApp(id, seat);
          }
          setTimeout(() => { this.enableSpinner = false; }, 1000);
        }, (error) => {
          this.openSnackBarError(["booking Item undefined for this bookingPackage"]);
          setTimeout(() => { this.enableSpinner = false; }, 1000);
        });
      }
    }
  }

  editTicketBookingApp(packageId: string, seat = null, isContinueShopping = false) {
    if (this.booking?.bookingPackages) {
      let bookedPackage
      if (packageId) {
        bookedPackage = this.booking.bookingPackages.find(bp => bp.package.id === packageId)
      } else if (isContinueShopping) {
        bookedPackage = this.booking.bookingPackages[0];
      }

      if (!bookedPackage) return

      const eventId = bookedPackage.package?.publicEvent?.id

      if (eventId) {
        this.store.dispatch(new StartBookingSession(new Date()));
        const params: SearchBaseParam.BaseParam = new SearchBaseParam.BaseParam();
        params.eventID = eventId;

        this.packageService.getPublicPackageSession(params, this.searchFilter).safeSubscribe(this, async (sessions: PackageSession[]) => {
          if (sessions && sessions.length > 0) {
            this.packageSession = sessions.filter(session => new Date(session.startTime) > new Date())
            this.packageSession.sort((s1: any, s2: any) => new Date(s2.startTime).getTime() - new Date(s1.startTime).getTime())
            this.allPackageSessions = this.packageSession;
            this.filterPackageSessions(this.packageSession);
            const bookingEventStartDate = Utility.isIsoFormat(bookedPackage.startDate) ? bookedPackage.startDate : Utility.convertToISO(bookedPackage.startDate);
            const bookingEventEndDate = Utility.isIsoFormat(bookedPackage.endDate) ? bookedPackage.endDate : Utility.convertToISO(bookedPackage.endDate);
            //const packSession = this.packageSession.find(session => (session.packageDate.eventDate.startTime === bookingEventStartDate) && (session.packageDate.eventDate.endTime === bookingEventEndDate));
            const packSession = this.packageSession.find(x =>
              Utility.convertToISO(Utility.convertISOToDate(x?.startTime))  === bookingEventStartDate&&
              Utility.convertToISO(Utility.convertISOToDate(x?.endTime)) === bookingEventEndDate);
            if (packSession) {
              const session: IDateAndSession = {
                id: packSession.id.toString(),
                startTime: Utility.convertToISO(Utility.convertISOToDate(packSession.startTime)),
                endTime: Utility.convertToISO(Utility.convertISOToDate(packSession.endTime)),
                availability: packSession.availability,
                eventDate: packSession.packageDate.eventDate,
                packages: [],
              };
              // if (isContinueShopping) {
              //   this.store.dispatch(new RemovePackageSession(session));
              // } else {
              //   this.store.dispatch(new SelectPackageSessions([session]));
              //   this.store.dispatch(new SetSelectedSession(session));
              // }

              if (this.cartType === BOOKING_CART.NEW_BOOKING) {
                this.store.dispatch(new ResetSeat());
                this.store.dispatch(new ResetBlock());
              }
              const selectedbookedPackages: IBooingPackageBookedSeat[] = [];

              const missedBookingItemIds = this.booking.bookingPackages.filter(pack => pack.bookingItems.length === 0).map(pack => pack.id)

              const request = [];
              const errors = [];
              missedBookingItemIds?.forEach(id => {
                request.push(this.bookingService.GetBookingItemForBookingPackage(this.booking.id, id, false))
              });

              if (missedBookingItemIds.length > 0) {
                await new Promise((resolve) => {
                  const observable = forkJoin(request);
                  observable.safeSubscribe(this, (results: any[]) => {
                    results.forEach((res, i) => {
                      if (res.isError) {
                        errors.push(res.message)
                      } else if (res.data.length > 0) {
                        const index = this.booking.bookingPackages.findIndex(pack => pack.id === missedBookingItemIds[i])
                        this.booking.bookingPackages[index].bookingItems = res.data;
                      }
                    })
                    resolve(results)
                  })
                })
              }

              if (errors.length > 0) {
                return this.openSnackBarError(["Package session is undefined"]);
              }

              this.booking.bookingPackages.forEach(pack => {
                const selectedbookedPackage: IBooingPackageBookedSeat = {
                  id: pack.id,
                  packageId: pack.package.id,
                  seat: seat,
                  quantity : pack.quantity,
                }
                selectedbookedPackages.push(selectedbookedPackage)
              })

              this.store.dispatch(new SetBooking(this.booking));
              this.store.dispatch(new SetSelectedBookingPackage(selectedbookedPackages));
              if (isContinueShopping) {
                this.store.dispatch(new RemovePackageSession(session));
                this.continueShoppingWithUpdatedPackage()
              } else {
                this.store.dispatch(new SelectPackageSessions([session]));
                this.store.dispatch(new SetSelectedSession(session));
                if (this.allowAllPackage) {
                  this.router.navigate(
                    ["seating-view/" + eventId],
                    { queryParams: { sessionId: session.id } }
                  )
                } else {
                  this.router.navigate(
                    ["seating-view/" + eventId],
                    { queryParams: { sessionId: session.id, packID: bookedPackage.package.primaryPackage?.id ? bookedPackage.package.primaryPackage?.id : bookedPackage.package.id } }
                  )
                }
              }
            } else {
              if (isContinueShopping) {
                this.continueShoppingWithUpdatedPackage()
              } else {
                this.openSnackBarError(["Package session is undefined"]);
              }
            }
          } else {
            this.openSnackBarError(["Package session is undefined"]);
          }
        })
      } else {
        this.openSnackBarError(["Booking package is undefined"]);
      }
    }
  }

  // editTicketBookingApp(id, seat = null) {
  //   let selectedbookedPackages: IBooingPackageBookedSeat[] = [];
  //   if (this.booking && this.booking.bookingPackages) {
  //     let bookedPackage = this.booking.bookingPackages.find(x => x.package.id == id);
  //     if (bookedPackage) {
  //       this.store.dispatch(new StartBookingSession(new Date()));
  //       const params: SearchBaseParam.BaseParam = new SearchBaseParam.BaseParam();
  //       const eventId = bookedPackage.package?.publicEvent?.id
  //       if (eventId) {
  //         params.eventID = eventId;
  //         this.packageService
  //           .getPublicPackageSession(params, this.searchFilter)
  //           .safeSubscribe(this, (sessions: PackageSession[]) => {
  //             if (sessions && sessions.length > 0) {
  //               this.packageSession = sessions;
  //               // filter started event for the day
  //               this.packageSession = this.packageSession.filter(x => new Date(x.startTime) > new Date())
  //               // sorted package session based on date
  //               this.packageSession.sort(
  //                 (a: any, b: any) =>
  //                   new Date(b.startTime).getTime() -
  //                   new Date(a.startTime).getTime()
  //               )
  //               this.filterPackageSessions(this.packageSession);
  //               let bookingEventStartDate = Utility.isIsoFormat(bookedPackage.startDate) ? bookedPackage.startDate : Utility.convertToISO(bookedPackage.startDate);
  //               let bookingEventEndDate = Utility.isIsoFormat(bookedPackage.endDate) ? bookedPackage.endDate : Utility.convertToISO(bookedPackage.endDate);
  //               const e = this.packageSession.find(PackSession => (PackSession.packageDate.eventDate.startTime === bookingEventStartDate) && (PackSession.packageDate.eventDate.endTime === bookingEventEndDate));
  //               if (e) {
  //                 const session: IDateAndSession = {
  //                   id: e.id.toString(),
  //                   startTime: e.packageDate.eventDate.startTime,
  //                   endTime: e.packageDate.eventDate.endTime,
  //                   availability: e.availability,
  //                   packages: [],
  //                 };
  //                 this.store.dispatch(new SelectSession(session));
  //                 this.store.dispatch(new SelectPackageSessions([session]));
  //                 if (this.cartType === BOOKING_CART.NEW_BOOKING) {
  //                   this.store.dispatch(new ResetSeat());
  //                 }
  //                 let bookedPackageIndex = -1;
  //                 this.booking.bookingPackages.forEach(pack => {
  //                   bookedPackageIndex++;
  //                   if (bookedPackageIndex != -1 && this.booking.bookingPackages[bookedPackageIndex].bookingItems.length == 0) {
  //                     this.bookingService.GetBookingItemForBookingPackage(this.booking.id, pack.id).safeSubscribe(this, (d) => {
  //                       if (!d.isError && d.data) {
  //                         let data = d.data;
  //                         this.booking.bookingPackages[bookedPackageIndex].bookingItems = d.data;

  //                         const selectedbookedPackage: IBooingPackageBookedSeat = {
  //                           id: pack.id,
  //                           packageId: pack.package.id,
  //                           seat: seat
  //                         }
  //                         selectedbookedPackages.push(selectedbookedPackage);

  //                         if (bookedPackageIndex == this.booking.bookingPackages.length - 1) {
  //                           this.store.dispatch(new AddBooking(this.booking));
  //                           this.store.dispatch(new SetSelectedBookingPackage(selectedbookedPackages));

  //                           if (this.allowAllPackage) {
  //                             this.allowAllPackage = false;
  //                             this.router.navigate(["seating-view/" + eventId], {
  //                               queryParams: {
  //                                 sessionId: session.id
  //                               },
  //                             });
  //                           }
  //                           else {
  //                             this.router.navigate(["seating-view/" + eventId], {
  //                               queryParams: {
  //                                 sessionId: session.id,
  //                                 packID: bookedPackage.package.id
  //                               },
  //                             });
  //                           }
  //                         }
  //                       }
  //                       setTimeout(() => { this.enableSpinner = false; }, 1000);
  //                     }, (error) => {
  //                       this.openSnackBarError(["booking Item undefined for this bookingPackage"]);
  //                       setTimeout(() => { this.enableSpinner = false; }, 1000);
  //                     });
  //                   } else if (bookedPackageIndex != -1) {
  //                     const selectedbookedPackage: IBooingPackageBookedSeat = {
  //                       id: pack.id,
  //                       packageId: pack.package.id,
  //                       seat: seat
  //                     }
  //                     selectedbookedPackages.push(selectedbookedPackage);

  //                     if (bookedPackageIndex == this.booking.bookingPackages.length - 1) {
  //                       this.store.dispatch(new AddBooking(this.booking));

  //                       this.store.dispatch(new SetSelectedBookingPackage(selectedbookedPackages));

  //                       if (this.allowAllPackage) {
  //                         this.allowAllPackage = false;
  //                         this.router.navigate(["seating-view/" + eventId], {
  //                           queryParams: {
  //                             sessionId: session.id
  //                           },
  //                         });
  //                       }
  //                       else {
  //                         this.router.navigate(["seating-view/" + eventId], {
  //                           queryParams: {
  //                             sessionId: session.id,
  //                             packID: bookedPackage.package.id
  //                           },
  //                         });
  //                       }
  //                     }
  //                   }
  //                 });
  //               }
  //               else {
  //                 this.openSnackBarError(["Package session is undefined"]);
  //               }
  //             } else {
  //               this.openSnackBarError(["Package session is undefined"]);
  //             }
  //           });
  //       }
  //       else {
  //         this.openSnackBarError(["Booking package is undefined"]);
  //       }
  //     }
  //   }
  // }

  onRemoveBookingSeat(pack: BookingCart, seat) {
    if (this.seatRemovedTriggerPriviousTime != 0) {
      let timeDiff = new Date().getTime() - this.seatRemovedTriggerPriviousTime;
      this.seatRemovedTriggerPriviousTime = new Date().getTime();
      if (timeDiff < 1500) {
        clearInterval(this.seatRemovedTimer);
        this.bookingPatchTimerTriggered = false;
      }
    } else {
      this.seatRemovedTriggerPriviousTime = new Date().getTime();
    }

    this.bookingPacksForRemoving = _.cloneDeep(this.bookingDisplayCart)
    let bookingCartIndex = this.bookingPacksForRemoving.findIndex(dCart => dCart.id == pack.id);
    const isLastSeat = this.bookingPacksForRemoving[bookingCartIndex].seats?.length === 1

    if (isLastSeat) {
      let dialogTitle = this.translate.instant('DELETEPOPUPINFO.Remove_Head')
      let dialogMessage = this.translate.instant('DELETEPOPUPINFO.Remove_message')

      if (this.bookingPacksForRemoving[bookingCartIndex]?.hasLinkedPackage) {
        dialogTitle = this.translate.instant('DELETEPOPUPINFO.Delete_Head')
        dialogMessage = this.translate.instant('DELETEPOPUPINFO.Delete_Message_IMG')
      }

      if (this.bookingPacksForRemoving?.length === 1) {
        dialogTitle = this.translate.instant('BOOKINGCART.Clear your Cart')
        dialogMessage = this.translate.instant('BOOKINGCART.If you remove all items from your booking, it will be cancelled. Are you sure you want to proceed?')
      }

      const dialog = this.dialog.open(BookingValidationConfirmationModalComponent, {
        data: {
          title: dialogTitle,
          message: dialogMessage,
          isAdultDelete: true
        },
        panelClass: [
          // 'custom-dialog-container',
          'w50modal',
          // 'modal-width',
          'extrapop',
        ],
        height: 'auto',
        width: 'auto',
      });
      dialog.afterClosed().safeSubscribe(this, res => {
        if (res == true) {
          this.isCancelModalOpen = true;
          if (bookingCartIndex != -1) {
            let seatIndex = this.bookingPacksForRemoving[bookingCartIndex].seats?.findIndex(s => s.id == seat.id);
            if (seatIndex != -1) {
              this.bookingPacksForRemoving[bookingCartIndex].seats.splice(seatIndex, 1);
              this.bookingDisplayCart[bookingCartIndex].seats.splice(seatIndex, 1);
            }
          }
          this.removedSeatsAfterTime(pack)
        }
      });
    } else {
      if (bookingCartIndex != -1) {
        let seatIndex = this.bookingPacksForRemoving[bookingCartIndex].seats?.findIndex(s => s.id == seat.id);
        if (seatIndex != -1) {
          this.bookingPacksForRemoving[bookingCartIndex].seats.splice(seatIndex, 1);
          this.bookingDisplayCart[bookingCartIndex].seats.splice(seatIndex, 1);
        }
      }
      this.removedSeatsAfterTime(pack)
    }
  }

  removedSeatsAfterTime(pack: BookingCart) {
    this.seatRemovedTimer = setInterval(() => {
      this.bookingPatchTimer = new Date().getTime(); // set time variable with current date
      if (this.bookingPatchTimerTriggerTimer < this.bookingPatchTimer) {
        this.checkBookingPatchPreviewAfterRemoveSeats(pack);
        this.seatRemovedTriggerPriviousTime = 0;
        this.bookingPatchTimerTriggered = false;
        clearInterval(this.seatRemovedTimer);
      }
    }, 1000); // set it every one second
  }

  checkBookingPatchPreviewAfterRemoveSeats(pack: BookingCart) {
    const newBooking = new Booking()
    newBooking.id = this.booking.id;
    newBooking.bookingTypeId = this.booking.bookingTypeId;
    newBooking.confirmed = this.booking.confirmed;
    newBooking.cancelled = this.booking.cancelled;
    newBooking.margin = this.booking.margin;
    newBooking.bookingPackages = [];
    newBooking.salesChannelId = this.booking.salesChannelId;
    if (
      !!this.PBReducer.selectedClient &&
      !!this.PBReducer.selectedClient
    ) {
      newBooking.contact = new BookingContact();
      newBooking.contact.id = this.PBReducer.selectedClient.contactId;
    } else if (this.isLoggedIn) {
      newBooking.contact = new BookingContact();
      newBooking.contact.id = this.booking.contact.id = this.cart.contact.id;
    }

    pack.cartPackages.forEach(cpack => {
      let availableSeatAllocation = [];
      let packageIndexInExistingBooking = this.booking.bookingPackages.findIndex(x => x.id == cpack.bookingPackageId);
      let packageIndexInBookingCart = this.bookingPacksForRemoving.findIndex(y => y.id == pack.id);
      if (packageIndexInExistingBooking != -1 && packageIndexInBookingCart != -1) {
        let bookingEventStartDate = Utility.isIsoFormat(this.booking.bookingPackages[packageIndexInExistingBooking].startDate) ? this.booking.bookingPackages[packageIndexInExistingBooking].startDate : Utility.convertToISO(this.booking.bookingPackages[packageIndexInExistingBooking].startDate);
        let bookingEventEndDate = Utility.isIsoFormat(this.booking.bookingPackages[packageIndexInExistingBooking].endDate) ? this.booking.bookingPackages[packageIndexInExistingBooking].endDate : Utility.convertToISO(this.booking.bookingPackages[packageIndexInExistingBooking].endDate);

        let bkPackages = new BookingPackages();
        bkPackages.id = this.booking.bookingPackages[packageIndexInExistingBooking].id;
        bkPackages.quantity = '0' //bkPackages.attendees.toString();
        bkPackages.attendees = 0;
        bkPackages.startDate = Utility.convertToISO(Utility.convertISOToDate(bookingEventStartDate));
        bkPackages.endDate = Utility.convertToISO(Utility.convertISOToDate(bookingEventEndDate));
        bkPackages.package = new Package();
        bkPackages.package.id = this.booking.bookingPackages[packageIndexInExistingBooking].package.id;
        bkPackages.bookingItems = [];

        const seatingPackageItems = this.booking.bookingPackages[packageIndexInExistingBooking].bookingItems.filter(x => (x.type == "publicBookingSeatingItem") && (x.item.type == "seatingItem"));
        if (seatingPackageItems && seatingPackageItems.length > 0) {
          seatingPackageItems.forEach((seatingPackageItem) => {
            if (seatingPackageItem.allocations && seatingPackageItem.allocations?.length > 0) {
              let bookingItem = new BookingItems();
              bookingItem.type = 'PublicBookingSeatingItem';
              bookingItem.quantity = 0;
              bookingItem.id = (seatingPackageItem.id).toString();
              bookingItem.name = seatingPackageItem.name;
              bookingItem.upsell = false;
              bookingItem.item.id = seatingPackageItem.item.id;
              bookingItem.item.type = seatingPackageItem.item.type;
              bookingItem.startTime = Utility.convertToISO(Utility.convertISOToDate(bookingEventStartDate));
              bookingItem.endTime = Utility.convertToISO(Utility.convertISOToDate(bookingEventEndDate));
              bookingItem.allocations = [];

              for (let i = 0; i < seatingPackageItem.allocations?.length; i++) {
                let allocation = new Allocation();
                let allo = seatingPackageItem.allocations[i];
                let allocationIndex = this.bookingPacksForRemoving[packageIndexInBookingCart]?.seats?.findIndex(s => s.id == allo.seat?.id);

                allocation.id = allo.id;
                allocation.type = AllocationType.SEAT;
                allocation.preliminary = allo.preliminary ? 1 : 0;
                allocation.reserved = allo.reserved ? 1 : 0;

                allocation.timeslot = new PublicEventFacilityTimeslot();
                allocation.timeslot.id = allo.timeslot.id;
                allocation.timeslot.startTime = Utility.convertToISO(
                  Utility.convertISOToDate(allo.timeslot.startTime)
                );
                allocation.timeslot.endTime = Utility.convertToISO(
                  Utility.convertISOToDate(allo.timeslot.endTime)
                );
                allocation.timeslot.asset = new Asset();
                allocation.timeslot.asset.id = allo.timeslot.asset.id;

                allocation.timeslot.eventConfiguration = new EventSeatingConfiguration();
                allocation.timeslot.eventConfiguration.id = allo.timeslot.eventConfiguration.id;

                if (allo.timeslot.eventDates.length > 0) {
                  allocation.timeslot.eventDates = [];
                  allocation.timeslot.eventDates.push(allo.timeslot.eventDates[0]);
                }
                allocation.seat.id = allo.seat.id;

                if (allocationIndex == -1) {
                  allocation.isDelete = true;
                } else {
                  availableSeatAllocation.push(allo)
                  bookingItem.quantity = bookingItem.quantity + 1;
                  bkPackages.quantity = (+(bkPackages.quantity) + 1).toString();
                  bkPackages.attendees = bkPackages.attendees + 1;
                }
                bookingItem.allocations.push(allocation);
              }
              //seatingPackageItem.allocations = availableSeatAllocation;
              //seatingPackageItem.quantity = availableSeatAllocation.length;
              bkPackages.bookingItems.push(bookingItem);
            }
          })
          if (bkPackages.bookingItems.length > 0) {
            newBooking.bookingPackages.push(bkPackages);
          }
        }
      }
    })

    let bookingPatch
    if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
      bookingPatch = this.bookingService.BookingPatchPreview(newBooking, this.patchPreviewId, [], false)
    } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
      bookingPatch = this.bookingService.BookingPatch(newBooking, [], undefined, undefined, false)
    }
    bookingPatch.safeSubscribe(this, b => {
      if (b.isError) {
        this.setBookingAfterSeatsPatch('remove', false, newBooking, undefined, pack.id);
        this.openSnackBarError([b.errors[0].detail]);
      } else {
        if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
          this.setBookingAfterSeatsPatch('remove', true, newBooking, b.data, pack.id);
        } else {
          this.setBookingAfterSeatsPatch('remove', true, newBooking, b.data.booking, pack.id);
        }
      }
    }, (error) => {
      this.setBookingAfterSeatsPatch('remove', false, newBooking, undefined, pack.id);
      this.openSnackBarError(["Seat updating failed"]);
    });

    this.bookingDisplayCart = this.bookingPacksForRemoving
  }

  setBookingAfterSeatsPatch(action: String, isPatchSuccess: boolean, bookingPatchBody, booking, bookingPackageId) {
    if (isPatchSuccess && booking) {
      let romovedSeatsAfterPatch: EventSeat[] = [];
      let bookingPackageIds = [];
      let removeBookingPackageIds = [];
      let totalQty = 0;
      let bookingAfterPatch = _.cloneDeep(booking)
      if (action == 'remove') {
        bookingPatchBody.bookingPackages.forEach(bp => {
          bp.bookingItems.forEach(bi => {
            bi.allocations.forEach(allo => {
              if (allo.isDelete) {
                romovedSeatsAfterPatch.push(allo.seat);
              }
            })
          })
        })
        if (romovedSeatsAfterPatch.length > 0) {
          this.store.dispatch(new RemovedSeatsAfterPatch(romovedSeatsAfterPatch));
        }
        let bookingCart = this.bookingDisplayCart.find(bd => bd.id == bookingPackageId);
        if (bookingCart) {
          bookingPackageIds = bookingCart.cartPackages.map(cp => cp.bookingPackageId);
        }
        bookingAfterPatch.bookingPackages.forEach(bpack => {
          if (bookingPackageIds.includes(bpack.id.toString())) {
            let bPackageIndex = this.booking.bookingPackages.findIndex(bp => bp.id.toString() == bpack.id.toString());
            if (bPackageIndex != -1) {
              this.booking.bookingPackages[bPackageIndex].bookingItems.forEach(bi => {
                const availableAllocation = []
                if (bi.allocations && bi.type == "publicBookingSeatingItem") {
                  bi.allocations.forEach((a: Allocation) => {
                    if (romovedSeatsAfterPatch.findIndex(seat => seat.id == a.seat.id) == -1) {
                      availableAllocation.push(a);
                    }
                  })
                  bi.allocations = availableAllocation;
                  bi.quantity = availableAllocation.length;
                }
              })
              bpack.bookingItems = this.booking.bookingPackages[bPackageIndex].bookingItems;
            }
          }
          totalQty += bpack.quantity;
          if (bpack.quantity === 0) {
            removeBookingPackageIds.push(bpack.id)
          }
        })
      } else if (action == 'swap') {
        bookingAfterPatch.bookingPackages.forEach(bpack => {
          totalQty += bpack.quantity;
          if (bpack.quantity === 0) {
            removeBookingPackageIds.push(bpack.id)
          }
        })
      }
      removeBookingPackageIds.reverse().forEach(id => {
        let index = bookingAfterPatch.bookingPackages.findIndex(dp => dp.id === id);
        if (index !== -1) {
          bookingAfterPatch.bookingPackages.splice(index, 1)
        }
      })
      if (bookingAfterPatch.bookingPackages.length === 0) {
        this.clearSession();
      } else {
        this.booking = this.setBooking(bookingAfterPatch);
        this.bookingPackages = bookingAfterPatch.bookingPackages;
        this.store.dispatch(new SetBooking(this.booking));

        this.bookingDisplayCart = this.createDisplayData(
          bookingAfterPatch.bookingPackages,
          this.bookedPackages
        );
        if (totalQty === 0) {
          this.bookedEvents = [];
        }
        if (action == 'remove') {
          this.seatPanelExpanded = true;
          this.enableSeatDetails();
        }
      }
    } else {
      this.bookingDisplayCart = this.createDisplayData(
        this.booking.bookingPackages,
        this.bookedPackages
      );
      this.enableSeatDetails();
    }
  }

  filterPackageSessions(sessions: PackageSession[]) {
    const groupedSessions$ = from(sessions).pipe(
      groupBy(
        (person) =>
          person.startTime.toISOString() && person.endTime.toISOString()
      ),
      mergeMap((group) =>
        group.pipe(
          reduce(
            (acc, cur) => {
              acc.values.push(cur);
              return acc;
            },
            { key: group.key, values: [] }
          )
        )
      ),
      toArray()
    );

    this.packageSession = [];
    groupedSessions$.forEach((element) => {
      element.forEach((value: any, key) => {
        value.values.sort(function (a, b) {
          return a.totalPriceIncludingTax - b.totalPriceIncludingTax;
        });
        this.packageSession.push(value.values[0]);
      });
    });
  }

  setEditableOverRidePackages() {
    let bookingPackageEventCategoryIDs = [];
    this.bookedPackages.forEach(bp => {
      if (bookingPackageEventCategoryIDs.length == 0) {
        bookingPackageEventCategoryIDs.push(bp?.publicEvent?.eventCategoryId);
      } else if (bookingPackageEventCategoryIDs.length > 0 && (bookingPackageEventCategoryIDs?.findIndex(id => id == bp?.publicEvent?.eventCategoryId) == -1)) {
        bookingPackageEventCategoryIDs.push(bp?.publicEvent?.eventCategoryId);
      }
    });
    let missingEventCategoryIds = bookingPackageEventCategoryIDs.filter(item => this.bookingPackageEventCategoryIds?.indexOf(item) < 0);
    if (missingEventCategoryIds.length > 0) {
      this.bookingPackageEventCategoryIds = bookingPackageEventCategoryIDs;

      let request = [];
      let errors = [];
      missingEventCategoryIds?.forEach(id => {
        request.push(this.pageLayoutService.getPageLayout(PAGE_TYPE.EVENT_LIST, undefined, id,0,0,true))
      });
      const observable = forkJoin(request);
      observable.safeSubscribe(this, (results: any[]) => {
        const isError = false;
        results.forEach(result => {
          if (result.isError) {
            errors.push(result.message);
          } else {
            if (result.data.length > 0) {
              let overRideEventCategory: OverRideEventCategory = { eventCategoryId: '', isOverRide: false };
              if (result.data[0]?.configurations?.includes("OVERRIDE_BOOKING_APP")) {
                overRideEventCategory.eventCategoryId = String(result.data[0]?.entityReferenceID);
                overRideEventCategory.isOverRide = true;
              }
              else {
                overRideEventCategory.eventCategoryId = String(result.data[0]?.entityReferenceID);
              }
              if (this.overRideEventCategoryDetails.findIndex(op => op.eventCategoryId == String(result.data[0]?.entityReferenceID)) != 1) {
                this.overRideEventCategoryDetails.push(overRideEventCategory);
              }
            }
          }
        })

        if (errors.length > 0) {
          this.openSnackBarError(errors);
        }
      });
    }
  }

  isEditablePack(pkg: CartPackage) {
    let isOverRide: boolean;
    let isEdit: boolean = true;
    let bookingPackage = this.bookingPackages?.find(bp => bp?.package?.id == pkg.id)?.package?.id;
    if (bookingPackage && this.bookedPackages) {
      let bookedPack = this.bookedPackages.find(pk => pk.id == bookingPackage);
      let bpEventCatogaryId = bookedPack?.publicEvent?.eventCategoryId;
      let bpHasSeating = bookedPack?.publicEvent?.hasSeating;
      if (bpEventCatogaryId && this.overRideEventCategoryDetails) {
        this.overRideEventCategoryDetails.forEach(oe => {
          if (oe.eventCategoryId == String(bpEventCatogaryId)) {
            isOverRide = oe.isOverRide;
          }
        });
        if (isOverRide != undefined) {
          if (isOverRide && bpHasSeating) {
            isEdit = true;
          }
          else if (!isOverRide && bpHasSeating) {
            isEdit = false;
          }
          else if (!bpHasSeating) {
            isEdit = true;
          }
        }
      }
    }
    return isEdit;
  }

  getPackageQuantityIncludeLinkendPackages(packages: CartPackage[]) {
    let totalQuatity = 0;
    if (packages.length > 0) {
      packages.forEach((pkg: CartPackage) => {
        totalQuatity = totalQuatity + pkg.quantity;
      })
    }
    return totalQuatity;
  }

  openExpansion(i: number, bookingPackageId: string, panel: string = '') {
    if (this.expandedBookingPackageIndex == i && this.selectedBookingPackageID == bookingPackageId) {
      if(this.enableNewDesignForBookingCartPage) {
        if (panel === PANEL_TYPE.SEAT_PANEL) {
          if (this.seatPanelExpanded) {
            this.seatPanelExpanded = false;
            return
          } else {
            this.seatPanelExpanded = true;
          }
        }
        if (panel === PANEL_TYPE.UPSELL_PANEL) {
          if (this.upsellPanelExpanded) {
            this.upsellPanelExpanded = false;
            return
          } else {
            this.upsellPanelExpanded = true;
          }
        }
      } else {
        if (panel === PANEL_TYPE.CART_PANEL) {
          if (this.cartPanelExpanded) {
            this.cartPanelExpanded = false;
            return
          } else {
            this.cartPanelExpanded = true;
          }
        }
      }
    } else if (i != -1 && this.selectedBookingPackageID != bookingPackageId) {
      if (panel && panel == PANEL_TYPE.SEAT_PANEL) {
        this.seatPanelExpanded = true;
        this.upsellPanelExpanded = false;
      } else if (panel && panel == PANEL_TYPE.UPSELL_PANEL) {
        this.seatPanelExpanded = false;
        this.upsellPanelExpanded = true;
      }else if (panel && panel == PANEL_TYPE.CART_PANEL) {
        this.cartPanelExpanded = true;
      }
    }
    this.expandedBookingPackageIndex = -1;
    this.selectedBookingPackageID = bookingPackageId;
    let fBookingPackages = _.cloneDeep(this.freezedBookingPackages);

    // following Commented codes are no longer required. This is required for the feature's swap seat functionality.
    // const filter: any = new BaseParam();
    // filter.eventID = +this.bookedEvents[0].id;
    // const searchBase = new SearchBaseFilter();
    // let bookingPackIndex = this.bookingPackages.findIndex(bp => bp.id == bookingPackageId);
    // if (bookingPackIndex != -1) {
    //   let bookedPackage = this.bookedPackages.find(bp => bp.id == this.bookingPackages[bookingPackIndex].package.id);
    //   searchBase.sessionFromDate = Utility.convertToISO(new Date(bookedPackage.packageSessions[0].startTime));
    //   searchBase.sessionToDate = Utility.convertToISO(new Date(bookedPackage.packageSessions[0].endTime));
    // }
    // this.packageService.getPublicPackages(filter, searchBase, true).subscribe((pack: any[]) => {
    //   this.packages = pack;
    // })
    let bookingCart = this.bookingDisplayCart.find(bc => bc.bookingPackageId == bookingPackageId);
    if (bookingCart) {
      bookingCart.cartPackages.forEach(cp => {
        bookingPackageId = cp.bookingPackageId;
        let bookedPackageIndex = this.bookingPackages.findIndex(bp => bp.id == cp.bookingPackageId);
        let freezedbookedPackageIndex = fBookingPackages.findIndex(bp => bp.id == cp.bookingPackageId);

    //get upsell details for bookedPackage for availability
    if(bookedPackageIndex != -1){
      let bookPackageId = this.bookingPackages[bookedPackageIndex]?.package?.id;
      if(bookingPackageId){
        let bookedPackIndex = this.bookedPackages.findIndex(pack => pack.id == String(bookPackageId))
        if(bookedPackIndex != -1){
          let searchFilter = new SearchBaseFilter();
          const params: SearchBaseParam.BaseParam = new SearchBaseParam.BaseParam();
          const eventId = this.bookedPackages[bookedPackIndex]?.publicEvent?.id
          if (eventId) {
            //params.eventID = +eventId; need to do IMG
            searchFilter.packageIDs = [this.bookedPackages[bookedPackIndex]?.id]
            this.packageService.getPublicPackageSession(params, searchFilter)
              .safeSubscribe(this, (session: PackageSession[]) => {
                if (session && session.length > 0) {
                  this.bookedPackages[bookedPackIndex].packageSessions = session;
                }
              })
          }
          if(this.bookedPackages[bookedPackIndex].upsellItem.length == 0){
            this.getUpsellData(bookPackageId, bookingPackageId, bookedPackIndex);
          }
        }
      }
    }

        //get bookingItems of bookingPackage
        if (bookedPackageIndex != -1 && this.bookingPackages[bookedPackageIndex].bookingItems.length == 0 && this.bookingPackages[bookedPackageIndex].quantity > 0) {
          this.enableSpinner = true;
          this.bookingService.GetBookingItemForBookingPackage(this.booking.id, bookingPackageId, (panel != '')).safeSubscribe(this, (d) => {
            if (!d.isError && d.data) {
              let data = _.cloneDeep(d.data);
              if (this.patchItem.length > 0) {
                this.patchItem.forEach(pi => {
                  let bookingItemIndex = data.findIndex(i => i.id == pi.bookingItemId)
                  if (bookingItemIndex != -1) {
                    data[bookingItemIndex].quantity = pi.quantity;
                  }
                })
              }
              this.bookingPackages[bookedPackageIndex].bookingItems = data;
              //this.store.dispatch(new AddBooking(this.booking))
              const sessions = PBUtility.convertBookingPackageIntoPBSessionData(
                this.bookingPackages,
                this.bookedPackages
              );
              if (sessions.length > 0) {
                this.store.dispatch(new AddNewSession(sessions))
              }
              this.bookingDisplayCart = this.createDisplayData(
                this.bookingPackages,
                this.bookedPackages
              );
              if (freezedbookedPackageIndex != -1) {
                fBookingPackages[freezedbookedPackageIndex].bookingItems = d.data;
                this.freezedBookingPackages = _.cloneDeep(fBookingPackages);
                this.freezedBookingCarts = this.createDisplayData(
                  this.freezedBookingPackages,
                  this.bookedPackages
                );
              }
              this.enableSeatDetails();
            }
            setTimeout(() => { this.enableSpinner = false; }, 1000);
          }, (error) => {
            this.openSnackBarError(["booking Item undefined for this bookingPackage"]);
            setTimeout(() => { this.enableSpinner = false; }, 1000);
          });
        } else if (bookedPackageIndex != -1 && this.bookingPackages[bookedPackageIndex].bookingItems.length > 0) {
          this.enableSeatDetails();
        }
      })
    }
    this.expandedBookingPackageIndex = i;
  }

  // generateBookingItem(patchItem : PatchItems, packageId){
  //   let bookingItem = new BookingItem;
  //   let pack = this.bookedPackages.find(bp => bp.id);
  //   if (pack && pack.upsellItem && pack.upsellItem?.length > 0) {
  //     let upsell = pack.upsellItem.find(ui => ui.id == patchItem.itemId);
  //     if (upsell) {
  //       bookingItem.id = patchItem.bookingItemId;
  //       bookingItem.name = upsell.name;
  //       bookingItem.quantity = patchItem.quantity;
  //       bookingItem.upsell = upsell.upsellItem;
  //       bookingItem.unitPriceIncludingTax = upsell.itemPriceGroups[0].priceIncludingTax;
  //       bookingItem.unitPriceExcludingTax = upsell.itemPriceGroups[0].priceExcludingTax;
  //       bookingItem.item = upsell;
  //       bookingItem.type = 'booking'+upsell.type;
  //       bookingItem.startTime = upsell.itemAvailability[0].sessionStart;
  //       bookingItem.endTime = upsell.itemAvailability[0].sessionEnd;
  //       if(upsell.itemAvailability[0].sessionStart && upsell.itemAvailability[0].sessionEnd) {
  //         bookingItem.units = moment(upsell.itemAvailability[0].sessionEnd).diff(moment(upsell.itemAvailability[0].sessionStart), 'days') + 1;
  //       }
  //     }
  //   }
  //   return bookingItem;
  // }

  onInvoiceView(inv) {
    var windowReference = window.open();
    if (inv.InvoiceUrl && inv.InvoiceUrl != "") {
      windowReference.location.assign(inv.InvoiceUrl);
    } else {
      this._bookingService.InvoiceView(inv.InvoiceId).safeSubscribe(this, (res) => {
        if (res.data) {
          windowReference.location.assign(res.data);
        }
      },
        err => {
          windowReference.close();
          this.openSnackBarError(["Unable to load invoice information. Please try again."]);
        });
    }
  }

  pageClickEvent(pageEvent: PageEvent) {
    //To override the style this random number is used
    if (pageEvent.pageSize == 2147483647) {
      pageEvent.pageSize = this.bookingPackagesLength;
    }
    //this.pageIndex = -1; //when doing pageClickEvent in the confirmation popup need to assign previousIndex to pageIndex for data binding
    this.enableSpinner = true;
    if (this.booking?.dueAmount != this.freezedBooking?.dueAmount) {
      for (let i = 0; i < this.bookingPackages.length; i++) {
        if (+(this.bookingPackages[i]?.id) < 0) {
          this.updatedBookingPackages.push(this.bookingPackages[i]);
        } else if (+(this.bookingPackages[i]?.id) > 0) {
          let pack = this.freezedBookingPackages.find(fbp => this.bookingPackages[i]?.id == fbp.id)
          if (pack && (pack?.attendees != this.bookingPackages[i].attendees || pack?.totalAmount != this.bookingPackages[i].totalAmount)) {
            this.bookingPackages[i].bookingItems = [];
            this.updatedBookingPackages.push(this.bookingPackages[i]);
          }
        }
      }
      if (this.updatedBookingPackages.length > 0) {
        this.store.dispatch(new SetUpdatedBookingPackages(this.updatedBookingPackages));
      }
      this.pageSize = pageEvent.pageSize;
      this.pageIndex = pageEvent.pageIndex;
      this.onBookingPackageSearch();
    } else {
      this.pageSize = pageEvent.pageSize;
      this.pageIndex = pageEvent.pageIndex;
      this.onBookingPackageSearch();
    }
  }

  getPageSizeOptions(): number[] {
    let pagesizeOption: number[] = []
    if (this.bookingPackagesLength > this.paginationSetting.minimumRequiredPageSize) {
      this.paginationSetting.pageSizeOptions.forEach(pagesize => {
        if (!pagesize.isDisable && pagesize.value <= this.bookingPackagesLength) {
          pagesizeOption.push(pagesize.value);
        }
      })
      //To override the style, this random number is used
      pagesizeOption.push(2147483647);
      return pagesizeOption;
    } else {
      return pagesizeOption;
    }
  }

  onBookingPackageSearch() {
    var searchParam = new BaseParam();
    searchParam.paging = new Paging();
    searchParam.paging.number = (this.pageIndex + 1).toString();
    if (this.bookingPackagesLength > this.paginationSetting.minimumRequiredPageSize) {
      searchParam.paging.size = this.pageSize.toString();
    } else {
      searchParam.paging.size = '0';
    }

    this.bookingService.GetBookingPackageList(this.selectedBookingId, searchParam).safeSubscribe(this, (d) => {
      if (!d.isError && d.data) {
        const param = new SearchBaseFilter();
        let packageIds = [];
        this.bookingPackagesLength = d.total;
        this.freezedBookingPackages = d.data;
        this._publicBookingSelectService.setdisplayedFreezedBookingPackages(this.freezedBookingPackages);

        let datas = _.cloneDeep(d.data);
        if (this.updatedBookingPackages && this.updatedBookingPackages.length > 0) {
          this.updatedBookingPackages.forEach(ubp => {
            let bPackageIndex = datas?.findIndex(bp => bp?.id?.toString() == ubp?.id?.toString())
            if (bPackageIndex != -1) {
              datas[bPackageIndex] = ubp;
            }
          })
        }

        this.bookingPackages = datas;
        if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
          this.noOfSeats = 0;
          this.bookingPackages.forEach(pack => {
            this.noOfSeats += pack.attendees;
          });
        }
        this._publicBookingSelectService.setValue(this.bookingPackages);
        datas.forEach(bpack => {
          if (packageIds.length == 0) {
            packageIds.push(bpack.package.id)
          } else {
            const isExist = packageIds.findIndex(p => p.id === bpack.package.id);
            if (isExist == -1) {
              packageIds.push(bpack.package.id);
            }
          }

          if (bpack.package.primaryPackage != undefined && bpack.package.primaryPackage != null && bpack.package.primaryPackage?.id) {
            const isExistPrimary = packageIds.findIndex(p => p.id === bpack.package?.primaryPackage?.id);
            if (isExistPrimary == -1) {
              packageIds.push(bpack.package?.primaryPackage?.id);
            }
          }
        })
        if (packageIds.length > 0) {
          param.packageIDs = packageIds
          this.bookedPackages = [];
          this.bookingService.getPublicPackage(new filterParam(), param).safeSubscribe(this, (packages) => {
            if (packages) {
              for (let i = 0; i < packages.length; i++) {
                this.bookedPackages.push(packages[i]);
                if (packages[i].linkedPackages && packages[i].linkedPackages.length > 0) {
                  this.bookedPackages.push(...packages[i].linkedPackages);
                }
              }
              let eventFromPackages = [...new Map(this.bookedPackages?.map(x => [x.publicEvent?.id, x.publicEvent])).values()];
              if (!eventFromPackages.includes(undefined)) {
                eventFromPackages.forEach(event => {
                  const isExist = this.bookedEvents?.findIndex(p => p.id === event.id);
                  if (isExist == -1) {
                    this.bookedEvents.push(event);
                  }
                })
              }
              //set override the packages which event layout configured a word as 'OVERRIDE_BOOKING_APP'
              this.setEditableOverRidePackages();
              this.store.dispatch(new SetBookedPackage(this.bookedPackages));
              const sessions = PBUtility.convertBookingPackageIntoPBSessionData(
                this.bookingPackages,
                this.bookedPackages
              );
              if (sessions.length > 0) {
                this.store.dispatch(new AddNewSession(sessions))
              }
              if (this.booking) {
                this.isImgLoaded = false;
                this.bookingDisplayCart = this.createDisplayData(
                  this.bookingPackages,
                  this.bookedPackages
                );

                this.freezedBookingCarts = this.createDisplayData(
                  this.freezedBookingPackages,
                  this.bookedPackages
                );
              }
            }
          })
        }
      }
      else {
        this.openSnackBarError(["Unable to load package information. Please try again."]);
      }

    })
  }

  setBookingAfterPatchORPatchPreview(booking, bookingPackageId = null) {
    let bookingPackages = [];
    booking.bookingPackages.forEach(bpack => {
      if (bookingPackageId != null) {
        if (bpack.id.toString() == bookingPackageId.toString()) {
          let bPackageIndex = this.bookingPackages.findIndex(bp => bp.id.toString() == bpack.id.toString());
          if (bPackageIndex != -1) {
            this.bookingPackages[bPackageIndex] = bpack;
          }
        }
      }
      const bpackage = {
        id: bpack.id.toString(),
        type: "bookingPackage"
      };
      bookingPackages.push(bpackage);
    })

    booking.bookingPackages = bookingPackages;
    this.booking = this.setBooking(booking);
    this.store.dispatch(new SetBooking(booking));
    this.bookingDisplayCart = this.createDisplayData(
      this.bookingPackages,
      this.bookedPackages
    );
  }

  onDownloadDocument(doc) {
    this._bookingService.DocumentDownload(this.selectedBookingId, doc)
  }

  onImgError(event) {
    event.target.src = '../dist/assets/images/no-image.png'
  }

  close() {
    this.hasEmailError = false;
    this.shippingMailAddress = this.emailAddress;
  }

  get emailControl() {
    return this.emailForm.get('email');
  }


  onSubmit() {
    let comMethod = new CommunicationMethod();
    let deliveryCommunicationMethods = [];
    if (this.emailForm.valid) {
      const data = this.emailForm.value;
      deliveryCommunicationMethods = this.booking?.deliveryCommunicationMethods ? this.booking?.deliveryCommunicationMethods : [];
      if (deliveryCommunicationMethods) {
        const index = deliveryCommunicationMethods?.findIndex(x => +x.communicationTypeID === 6)
        if (index != -1) {
          comMethod.id = deliveryCommunicationMethods[index].id;
          comMethod.value = data.email;
          comMethod.communicationTypeID = deliveryCommunicationMethods[index].communicationTypeID;
        } else {
          comMethod.id = String(-1);
          comMethod.value = data.email;
          comMethod.communicationTypeID = String(6);
        }
      }
      let uBooking: Booking;
      let bookingItemTypes
      switch (this.bookingMode) {
        case BOOKING_CART.NEW_BOOKING:
          uBooking = new Booking();
          uBooking.id = this.booking.id;
          break;
        case BOOKING_CART.EDIT_BOOKING:
          const sessions = PBUtility.makeFullBookingSession(PBUtility.convertBookingIntoPBSessionData(this.booking, this.PBReducer.bookedPackages));
          const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBody(sessions, this.PBReducer, this.cart);
          uBooking = newBooking;
          bookingItemTypes = bookingItemTypeNames;
          break;
        default:
          break;
      }
      if (!uBooking.deliveryCommunicationMethods) {
        uBooking.deliveryCommunicationMethods = [];
      }
      uBooking.deliveryCommunicationMethods.push(comMethod);
      this.enableSpinner = true;
      switch (this.bookingMode) {
        case BOOKING_CART.NEW_BOOKING:
          this.bookingService.BookingPatch(uBooking).safeSubscribe(this, res => {
            if (!res.isError && res.data.booking) {
              this.store.dispatch(new SetBooking(res.data.booking))
              const index = res.data.booking.deliveryCommunicationMethods.findIndex(x => +x.communicationTypeID === 6)
              this.shippingMailAddress = res.data.booking.deliveryCommunicationMethods[index].value;
            } else {
              const index = res.data.booking.deliveryCommunicationMethods?.findIndex(x => +x.communicationTypeID === 6)
              if (index != -1) {
                this.shippingMailAddress = res.data.booking.deliveryCommunicationMethods[index].value;
              } else {
                this.shippingMailAddress = this.cart?.contact?.email;
              }
              this.openSnackBarError(["Delivery method update failed"])
            }
            this.enableSpinner = false;
          }, (e) => {
            this.enableSpinner = false;
          })
          this.enableSpinner = false;
          break;
        case BOOKING_CART.EDIT_BOOKING:
          this.bookingService.BookingPatch(uBooking).safeSubscribe(this, res => {
            if (!res.isError && res.data) {
              this.store.dispatch(new SetBooking(res.data.booking));
              const index = res.data.booking.deliveryCommunicationMethods.findIndex(x => +x.communicationTypeID === 6)
              this.shippingMailAddress = res.data.booking.deliveryCommunicationMethods[index].value;
            } else {
              const index = res.data.booking.deliveryCommunicationMethods?.findIndex(x => +x.communicationTypeID === 6)
              if (index != -1) {
                this.shippingMailAddress = res.data.booking.deliveryCommunicationMethods[index].value;
              } else {
                this.shippingMailAddress = this.cart?.contact?.email;
              }
              this.openSnackBarError(["Delivery method update failed"])
            }
            this.enableSpinner = false;
          }, (e) => {
            this.enableSpinner = false;
          })
          break;
        default:
          break;
      }
    }
  }


  openLinkedPackageChangeModal(seat, cartPackage) {
    this.selectedSeat = seat;
    this.seatDetails = cartPackage.seats;
    this.parentRadioSelection = "";
    this.childRadioSelection = "";
    const selectedPackage = this.packages?.find(p =>
      (p.id == seat.assignedPackage.id) || !!p.linkedPackages?.find(lp => lp.id == seat.assignedPackage.id)
    )
    this.parentRadioSelection = selectedPackage.id

    if (selectedPackage?.linkedPackages) {
      this.childRadioSelection = selectedPackage.id == seat.assignedPackage.id ? selectedPackage.linkedPackageType.id : selectedPackage.linkedPackages?.find(lp => lp.id == seat.assignedPackage.id).linkedPackageType.id
    }

    this.eligiblePackages = this.packages?.filter(pack => pack?.type === "PublicPackage" && pack.packageItems.find(pi => (pi.type === 'publicPackageSeatingItem')))

    this.parentPackages = [];

    this.eligiblePackages.forEach(pack => {
      selectedPackage?.packageItems.forEach(pb => {
        const mainPackPriceBands = pack.packageItems.map(pb => pb.priceBandId)
        const isMatchPack = mainPackPriceBands.includes(pb.priceBandId)
        const isExist = this.parentPackages.find(p => pack.id == p.id)
        if (!isExist && isMatchPack) this.parentPackages.push(pack)
      })
    })

    let bookedPackageIndex = -1;
    let bookingPackageIds = this.bookingPackages.filter(bp => bp.bookingItems.length == 0).map(x => x.id);
    if (bookingPackageIds.length > 0) {
      bookingPackageIds.forEach(bpId => {
        bookedPackageIndex++;
        this.bookingService.GetBookingItemForBookingPackage(this.booking.id, bpId).safeSubscribe(this, (d) => {
          if (!d.isError && d.data) {
            let data = d.data;
            let index = this.bookingPackages.findIndex(bp => bp.id == bpId);
            if (index != -1) {
              this.bookingPackages[index].bookingItems = d.data;
            }
          }
        }, (error) => {
          this.openSnackBarError(["booking Item undefined for this bookingPackage"]);
        });
      })
    }
    if(!this.priceBands)
    {
      this.priceBandService.getPriceBands().safeSubscribe(this, (p) => {
        if (p) {
          this.priceBands = p;
        }
      });
    }

    const dialogRef = this.dialog.open(BaPackageSelectionComponent, {
      data: {
        packages: this.parentPackages,
        parentRadioSelection: this.parentRadioSelection,
        childRadioSelection: this.childRadioSelection,
        priceBands: this.priceBands
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return
      let pack = null;
      if (((!result.childPackID || result.childPackID == null) && seat.assignedPackage.id != result.parentID) || (this.parentRadioSelection == result.parentID && (result.childPackID != null && seat.assignedPackage.id != result.childPackID)) || (this.parentRadioSelection != result.parentID)) {
        const selectedPack = this.eligiblePackages.find(pk => pk.id == result.parentID);
        const selectedPacks = [];
        if (selectedPack.linkedPackages?.length > 0 && result.childPackID && result.childPackID != null) {
          pack = selectedPack.linkedPackages.find(x => x.id == result.childPackID);
          selectedPacks.push(selectedPack);
          selectedPacks.push(pack);
        } else {
          pack = selectedPack;
          selectedPacks.push(pack);
        }

        let selectedSeatsIndex = this.seatDetails.findIndex(seat => seat.id == this.selectedSeat.id);
        if (selectedSeatsIndex != -1) {
          this.seatDetails[selectedSeatsIndex].assignedPackage = new CartPackage(pack, null, this.booking.showPriceWithTax);
        }
        selectedPacks.forEach(sp => {
          let item = this.bookingPackages.find(pkg => pkg?.package?.id == sp.id);
          if (item && item.bookingItems && item.bookingItems.length > 0) {
            item.bookingItems.forEach(i => {
              if (i.type == "publicBookingSeatingItem" && i.allocations) {
                i.allocations?.forEach(allo => {
                  if (allo?.seat && allo?.seat?.eventBlock?.id) {
                    let a = _.cloneDeep(allo);
                    a.seat.assignedPackage = new CartPackage(sp, null, this.booking.showPriceWithTax);
                    if (!this.seatDetails?.find(seat => seat.id == a.seat.id)) {
                      this.seatDetails.push(a.seat);
                    }
                  }
                })
              }
            })
          }
        })
        if (this.validPackageChange()) {
          this.patchBooking();
        } else {
          this.setBookingAfterSeatsPatch('swap', false, undefined, undefined, this.selectedBookingPackageID);
        }
      }
    });
  }

  validPackageChange(): boolean {
    let selectedLinkedPackageIds = [];
    let idIndex = 0;
    this.seatDetails.forEach(s => {
      if (s.assignedPackage?.isChild && !selectedLinkedPackageIds.find(id => id == s.assignedPackage?.id)) {
        selectedLinkedPackageIds.push(s.assignedPackage?.id);
      }
    })
    if (selectedLinkedPackageIds.length > 0) {
      selectedLinkedPackageIds.forEach(lkPack => {
        const parentSeats = this.seatDetails.filter(x => !x.assignedPackage?.isChild);
        const adult = this.parentPackages.filter(x => !!x.linkedPackages?.find(lp => lp.id == lkPack));
        const linkedPack = adult[0].linkedPackages.find(x => x.id == lkPack);
        let count = 0;
        if (parentSeats.length > 0) {
          if (adult.length > 0) {
            const adultSeats = parentSeats.filter(ps => ps.assignedPackage.id == adult[0].id)
            if (adultSeats.length > 0) {
              let allchildID = [...new Set(adult[0]?.linkedPackages.map(obj => obj.id))];
              allchildID.forEach(childId => {
                count = count + this.seatDetails.filter(s => s.assignedPackage?.id == childId).length;
              });
              if (!!linkedPack) {
                if ((linkedPack?.linkedPackageType?.maximumNumberOfChildren) * adultSeats.length < count) {
                  const errorMsg = "At least one Adult ticket is required for every " + (linkedPack?.linkedPackageType?.maximumNumberOfChildren) + " Child Tickets.";
                  this.openSnackBarError([errorMsg])
                  return false;
                }
              }
            } else {
              if (linkedPack?.linkedPackageType?.adultRequired) {
                const errorMsg = "At least one Adult ticket must be selected.";
                this.openSnackBarError([errorMsg])
                return false;
              }
            }
          }
        } else {
          if (linkedPack?.linkedPackageType?.adultRequired) {
            const errorMsg = "At least one Adult ticket must be selected.";
            this.openSnackBarError([errorMsg])
            return false;
          }
        }
        idIndex += 1;
      });
      if (idIndex == selectedLinkedPackageIds.length) {
        return true;
      }
    } else {
      return true;
    }
  }

  patchBooking() {
    let seatAllocationIndex = 1;
    const newBooking = new Booking()
    newBooking.id = this.booking.id;
    newBooking.bookingTypeId = this.booking.bookingTypeId;
    newBooking.confirmed = this.booking.confirmed;
    newBooking.cancelled = this.booking.cancelled;
    newBooking.margin = this.booking.margin;
    newBooking.bookingPackages = [];
    newBooking.salesChannelId = this.booking.salesChannelId;
    if (
      !!this.PBReducer.selectedClient &&
      !!this.PBReducer.selectedClient
    ) {
      newBooking.contact = new BookingContact();
      newBooking.contact.id = this.PBReducer.selectedClient.contactId;
    } else if (this.isLoggedIn) {
      newBooking.contact = new BookingContact();
      newBooking.contact.id = this.booking.contact.id = this.cart.contact.id;
    }

    if (this.seatDetails.length > 0) {
      //generate patch body with selected seats
      this.seatDetails.forEach(s => {
        const selectedTimeslot = this.timeslots.find(x => !!x.eventConfigurationDetails && !!x.eventConfigurationDetails.find(x => x.id == s.id));

        let packageIndexInExistingBooking = this.bookingPackages.findIndex(x => x.package.id == s.assignedPackage.id);
        let bkPackages = new BookingPackages();
        let packageIndexInNewBooking = newBooking.bookingPackages.findIndex(x => x.package.id == s.assignedPackage.id);
        let bookedPackage;
        if (packageIndexInNewBooking == -1) {
          if (packageIndexInExistingBooking == -1) {
            bkPackages.id = ((newBooking.bookingPackages.length + 1) * -1).toString();
          }
          else {
            bkPackages.id = this.bookingPackages[packageIndexInExistingBooking].id;
          }
          bkPackages.bookingItems = undefined;

          if (packageIndexInExistingBooking > -1 && !(this.bookingPackages[packageIndexInExistingBooking].package.id == s.assignedPackage.id)) {
            bkPackages.attendees = this.bookingPackages[packageIndexInExistingBooking].attendees + 1;
          }
          else {
            bkPackages.attendees = 1;
          }

          bkPackages.quantity = bkPackages.attendees.toString();
          bkPackages.startDate = Utility.convertToISO(Utility.convertISOToDate(selectedTimeslot.startTime));
          bkPackages.endDate = Utility.convertToISO(Utility.convertISOToDate(selectedTimeslot.endTime));
          bkPackages.package = new Package();
          if (packageIndexInExistingBooking == -1) {
            bkPackages.package.id = s.assignedPackage.id;
          }
          else {
            bkPackages.package.id = this.bookingPackages[packageIndexInExistingBooking].package.id;
          }
        } else {
          bkPackages = newBooking.bookingPackages[packageIndexInNewBooking]
          bkPackages.attendees++;
          bkPackages.quantity = bkPackages.attendees.toString();
        }
        if (!bkPackages.bookingItems || bkPackages.bookingItems.length === 0) {
          bkPackages.bookingItems = []
        }
        if (packageIndexInExistingBooking == -1) {
          bookedPackage = this.packages.find(x => x.id == s.assignedPackage.id);
          if (!bookedPackage) {
            let pack = this.packages.find(x => (x.linkedPackages?.findIndex(lp => lp.id == s.assignedPackage.id) != -1));
            if (pack) {
              bookedPackage = pack.linkedPackages?.find(lp => lp.id == s.assignedPackage.id)
            }
          }
        } else {
          bookedPackage = this.bookedPackages.find(x => x.id == s.assignedPackage.id);
          if (!bookedPackage) {
            let pack = this.bookedPackages.find(x => (x.linkedPackages?.findIndex(lp => lp.id == s.assignedPackage.id) != -1));
            if (pack) {
              bookedPackage = pack.linkedPackages?.find(lp => lp.id == s.assignedPackage.id)
            }
          }
        }
        const seatingPackageItem = bookedPackage?.packageItems.find(x => x.type == 'publicPackageSeatingItem' && x.item.type == 'seatingItem');

        //let bookingEventStartDate = Utility.isIsoFormat(this.booking.bookingPackages[packageIndexInExistingBooking].startDate) ? this.booking.bookingPackages[packageIndexInExistingBooking].startDate : Utility.convertToISO(this.booking.bookingPackages[packageIndexInExistingBooking].startDate);
        //let bookingEventEndDate = Utility.isIsoFormat(this.booking.bookingPackages[packageIndexInExistingBooking].endDate) ? this.booking.bookingPackages[packageIndexInExistingBooking].endDate : Utility.convertToISO(this.booking.bookingPackages[packageIndexInExistingBooking].endDate);

        if (seatingPackageItem && selectedTimeslot) {
          let itemIndexInExistingBooking
          if (packageIndexInExistingBooking > -1) {
            itemIndexInExistingBooking = this.bookingPackages[packageIndexInExistingBooking].bookingItems.findIndex(x => x.item?.id == (seatingPackageItem.item.id).toString());
          }

          const itemIndexInNewBooking = bkPackages?.bookingItems?.findIndex(x => (x.item?.id == (seatingPackageItem.item?.id).toString()));
          let bookingItem = new BookingItems();
          bookingItem.type = 'PublicBookingSeatingItem';
          bookingItem.quantity = 0;
          if (itemIndexInNewBooking === -1) {
            let biCount = 0;
            newBooking.bookingPackages.forEach(
              (b) =>
                !!b.bookingItems && (biCount += b.bookingItems.length)
            );
            if (itemIndexInExistingBooking > -1) {
              bookingItem.id = (this.bookingPackages[packageIndexInExistingBooking].bookingItems[itemIndexInExistingBooking].id).toString();
            }
            else {
              bookingItem.id = ((biCount + 1) * -1).toString();
            }
            if (packageIndexInExistingBooking > -1 && !(this.bookingPackages[packageIndexInExistingBooking].package.id == s.assignedPackage.id)) {
              bookingItem.quantity = this.bookingPackages[packageIndexInExistingBooking].bookingItems[itemIndexInExistingBooking].quantity + 1;
            } else {
              bookingItem.quantity = 1;
            }
            bookingItem.name = seatingPackageItem.name;
            bookingItem.upsell = false;
            bookingItem.item.id = seatingPackageItem.item.id;
            bookingItem.item.type = seatingPackageItem.item.type;
            bookingItem.startTime = Utility.convertToISO(Utility.convertISOToDate(selectedTimeslot.startTime));
            bookingItem.endTime = Utility.convertToISO(Utility.convertISOToDate(selectedTimeslot.endTime));
            if (!bookingItem.allocations)
              bookingItem.allocations = [];
          } else {
            bookingItem = bkPackages.bookingItems[itemIndexInNewBooking];
            bookingItem.quantity++;
          }

          let allocationIndexInExistingBooking;
          if (packageIndexInExistingBooking > -1 && itemIndexInExistingBooking > -1) {
            allocationIndexInExistingBooking = this.bookingPackages[packageIndexInExistingBooking].bookingItems[itemIndexInExistingBooking].allocations?.findIndex((x: Allocation) => (x.seat.id == s.id) && (x.eventConfigurationDetailId == s.id));
          }
          let allocation = new Allocation();
          let allocationIndexInNewBooking = bookingItem.allocations.findIndex((x: Allocation) => (x.seat.id == s.id) && (x.eventConfigurationDetailId == s.id));
          if (allocationIndexInNewBooking === -1) {
            allocation = new Allocation();
            if (allocationIndexInExistingBooking > -1) {
              allocation.id = (this.bookingPackages[packageIndexInExistingBooking].bookingItems[itemIndexInExistingBooking].allocations[allocationIndexInExistingBooking].id).toString();
            }
            else {
              allocation.id = (seatAllocationIndex * -1).toString();
              seatAllocationIndex++;
            }
            allocation.type = AllocationType.SEAT;
            allocation.preliminary = 0;
            allocation.reserved = 1;
            allocation.timeslot = new PublicEventFacilityTimeslot();
            allocation.timeslot.id = selectedTimeslot.id;
            allocation.timeslot.startTime = Utility.convertToISO(
              Utility.convertISOToDate(selectedTimeslot.startTime)
            );
            allocation.timeslot.endTime = Utility.convertToISO(
              Utility.convertISOToDate(selectedTimeslot.endTime)
            );
            allocation.timeslot.asset = new Asset();
            allocation.timeslot.asset.id = selectedTimeslot.facilityId;

            allocation.timeslot.eventConfiguration = new EventSeatingConfiguration();
            allocation.timeslot.eventConfiguration.id = selectedTimeslot.configurationId;
            allocation.seat.id = s.id;
          } else {
            allocation = bookingItem.allocations[allocationIndexInNewBooking] as Allocation;
          }

          allocationIndexInNewBooking == -1 ? bookingItem.allocations.push(allocation) : bookingItem.allocations[allocationIndexInNewBooking] = allocation;
          itemIndexInNewBooking == -1 ? bkPackages.bookingItems.push(bookingItem) : bkPackages.bookingItems[itemIndexInNewBooking] = bookingItem;
          packageIndexInNewBooking == -1 ? newBooking.bookingPackages.push(bkPackages) : newBooking.bookingPackages[packageIndexInNewBooking] = bkPackages;
        }
      });

    }

    //if selectedSeats length was zero, deleted all allocation(seats) from booking for selected package
    if ((this.parentRadioSelection != "" || this.childRadioSelection != "") && this.selectedSeat) {
      let bookedPackage;
      let bookedPackageInNewBooking;
      if (this.childRadioSelection == "1") {
        bookedPackage = this.bookingPackages?.find((bp: BookingPackages) => bp?.package?.id == this.parentRadioSelection);
        bookedPackageInNewBooking = newBooking.bookingPackages?.findIndex((bp: BookingPackages) => bp?.package?.id == this.parentRadioSelection);
      } else {
        let pack
        if (this.childRadioSelection != "") {
          pack = this.packages.find(pack => pack.id == this.parentRadioSelection)?.linkedPackages.find(lp => lp.linkedPackageType.id == this.childRadioSelection);
        } else {
          pack = this.packages.find(pack => pack.id == this.parentRadioSelection);
        }
        if (pack) {
          bookedPackage = this.bookingPackages?.find((bp: BookingPackages) => bp?.package?.id == pack.id);
          bookedPackageInNewBooking = newBooking.bookingPackages?.findIndex((bp: BookingPackages) => bp?.package?.id == pack.id);
        }
      }
      if (bookedPackage && bookedPackageInNewBooking != -1) {
        const bookedItemInExitingBooking = bookedPackage.bookingItems?.find((bi: BookingItems) => bi?.type.toLowerCase() == 'publicbookingseatingitem' && bi?.item?.type.toLowerCase() == 'seatingitem');
        if (bookedItemInExitingBooking) {
          const allocationOfSelectedSeats = bookedItemInExitingBooking?.allocations.find(a => a.seat.id == this.selectedSeat.id);
          if (allocationOfSelectedSeats) {
            let allocation = new Allocation();
            allocation.isDelete = true;
            allocation.id = allocationOfSelectedSeats.id;
            allocation.type = AllocationType.SEAT;
            allocation.preliminary = allocationOfSelectedSeats.preliminary ? 1 : 0;
            allocation.reserved = allocationOfSelectedSeats.reserved ? 1 : 0;
            allocation.timeslot = new PublicEventFacilityTimeslot();

            allocation.timeslot.id = allocationOfSelectedSeats.timeslot.id;
            allocation.timeslot.startTime = Utility.convertToISO(Utility.convertISOToDate(bookedPackage.startDate));
            allocation.timeslot.endTime = Utility.convertToISO(Utility.convertISOToDate(bookedPackage.endDate));
            allocation.timeslot.asset = new Asset();
            allocation.timeslot.asset.id = allocationOfSelectedSeats.timeslot.asset.id;
            allocation.timeslot.eventConfiguration = new EventSeatingConfiguration()
            allocation.timeslot.eventConfiguration.id = allocationOfSelectedSeats.timeslot.eventConfiguration.id;
            allocation.seat.id = allocationOfSelectedSeats.seat.id;

            const bookedItemInNewBooking = newBooking.bookingPackages[bookedPackageInNewBooking].bookingItems?.findIndex((bi: BookingItems) => bi?.type.toLowerCase() == 'publicbookingseatingitem' && bi?.item?.type.toLowerCase() == 'seatingitem');
            if (bookedItemInNewBooking != -1) {
              newBooking.bookingPackages[bookedPackageInNewBooking].bookingItems[bookedItemInNewBooking].allocations.push(allocation);
            }
          }
        }
      } if (bookedPackage && (bookedPackageInNewBooking == -1)) {
        const bookedItem = bookedPackage.bookingItems?.find((bi: BookingItems) => bi?.type.toLowerCase() == 'publicbookingseatingitem' && bi?.item?.type.toLowerCase() == 'seatingitem');
        const upsellItem = bookedPackage.bookingItems?.filter((i: BookingItems) => i !== null && i.upsell && i.item)
          .map((i) => {
            const bookingItem = new BookingItems();
            bookingItem.id = i.id;
            bookingItem.type = i.type;
            bookingItem.item.id = i.item.id;
            bookingItem.item.type = i.item.type;
            bookingItem.quantity = 0;
            bookingItem.startTime = Utility.convertToISO(Utility.convertISOToDate(i.startTime));
            bookingItem.endTime = Utility.convertToISO(Utility.convertISOToDate(i.endTime));

            return bookingItem;
          });
        if (bookedItem && bookedItem.allocations.length > 0) {
          let bkPackages = new BookingPackages();
          bkPackages.id = bookedPackage.id;
          bkPackages.bookingItems = [];
          bkPackages.attendees = 0;
          bkPackages.quantity = bkPackages.attendees.toString();
          bkPackages.startDate = Utility.convertToISO(Utility.convertISOToDate(bookedPackage.startDate));
          bkPackages.endDate = Utility.convertToISO(Utility.convertISOToDate(bookedPackage.endDate));
          bkPackages.package = new Package();
          bkPackages.package.id = bookedPackage.package.id;

          let bookingItem = new BookingItems();
          bookingItem.type = 'PublicBookingSeatingItem';
          bookingItem.id = bookedItem.id;
          bookingItem.quantity = 0;
          bookingItem.name = bookedItem.name;
          bookingItem.upsell = false;
          bookingItem.item.id = bookedItem.item.id;
          bookingItem.item.type = bookedItem.item.type;
          bookingItem.startTime = Utility.convertToISO(Utility.convertISOToDate(bookedPackage.startDate));
          bookingItem.endTime = Utility.convertToISO(Utility.convertISOToDate(bookedPackage.endDate));
          if (!bookingItem.allocations)
            bookingItem.allocations = [];

          bookedItem.allocations.forEach((a: Allocation) => {
            let allocation = new Allocation();
            allocation.isDelete = true;
            allocation.id = a.id;
            allocation.type = AllocationType.SEAT;
            allocation.preliminary = a.preliminary ? 1 : 0;
            allocation.reserved = a.reserved ? 1 : 0;
            allocation.timeslot = new PublicEventFacilityTimeslot();

            allocation.timeslot.id = a.timeslot.id;
            allocation.timeslot.startTime = Utility.convertToISO(Utility.convertISOToDate(bookedPackage.startDate));
            allocation.timeslot.endTime = Utility.convertToISO(Utility.convertISOToDate(bookedPackage.endDate));
            allocation.timeslot.asset = new Asset();
            allocation.timeslot.asset.id = a.timeslot.asset.id;
            allocation.timeslot.eventConfiguration = new EventSeatingConfiguration()
            allocation.timeslot.eventConfiguration.id = a.timeslot.eventConfiguration.id;
            allocation.seat.id = a.seat.id;

            bookingItem.allocations.push(allocation);
          });
          if (upsellItem.length > 0 && bkPackages.bookingItems.length == 0) {
            bkPackages.bookingItems = upsellItem;
          }
          bkPackages.bookingItems.push(bookingItem);
          newBooking.bookingPackages.push(bkPackages);
        }
      }

    }
    let bookingPatch
    this.store.dispatch(new PublicLoading());
    if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
      bookingPatch = this.bookingService.BookingPatchPreview(newBooking, this.patchPreviewId, [], true)
    } else if (this.cartType === BOOKING_CART.NEW_BOOKING) {
      bookingPatch = this.bookingService.BookingPatch(newBooking, [], undefined, undefined, true)
    }
    bookingPatch.safeSubscribe(this, b => {
      if (b.isError) {
        this.setBookingAfterSeatsPatch('swap', false, newBooking, undefined, this.selectedBookingPackageID);
        this.openSnackBarError(["Updating seats got failed" + b.errors[0]?.detail]);
      } else {
        this.seatPanelExpanded = false;
        this.upsellPanelExpanded = false;
        if (this.cartType === BOOKING_CART.EDIT_BOOKING) {
          this.setBookingAfterSeatsPatch('swap', true, newBooking, b.data, this.selectedBookingPackageID);
        } else {
          this.setBookingAfterSeatsPatch('swap', true, newBooking, b.data.booking, this.selectedBookingPackageID);
        }
        //this.store.dispatch(new AddBooking(this.booking));
        this.store.dispatch(new PublicLoaded());
        //this.openExpansion(this.expandedBookingPackageIndex, this.selectedBookingPackageID);
      }
    }, (error) => {
      //show error
      this.setBookingAfterSeatsPatch('swap', false, newBooking, undefined, this.selectedBookingPackageID);
      this.openSnackBarError(["Updating seats got failed"]);
    });
  }

  checkIncreaseBtnDisable(cart, item) {
    let maximumInput = 9999;
    let increaseBtnDisable = false;
    let packIndex = this.bookedPackages.findIndex(x => x.id == cart.cartPackages[0].id);
    if (packIndex != -1) {
      let upsellindex = this.bookedPackages[packIndex]?.upsellItem?.findIndex(x => x.name == item?.name);
      if (upsellindex != -1) {
        if(this.bookedPackages[packIndex]?.upsellItem[upsellindex]?.availabilityStatus?.toString().toLowerCase() != 'indeterminable' && this.bookedPackages[packIndex]?.upsellItem[upsellindex]?.availabilityStatus != null) {
          maximumInput = Number(this.bookedPackages[packIndex]?.upsellItem[upsellindex]?.availabilityStatus);
          if (Number(this.bookedPackages[packIndex]?.upsellItem[upsellindex]?.availabilityStatus) <= item.quantity) {
            increaseBtnDisable = true;
          }
        }
      }
    }

    return { maximumInput, increaseBtnDisable}
  }

  RedirectToPayment(invoiceref) {
      var url = this.PaymentPortalUrl;
      window.open(url + "payment?InvoiceRef=" + invoiceref + "&fromMyBooking='1'", "_self")
  }

  //check whether package images are loaded or not
  onImgLoad() {
      this.isImgLoaded = true;
  }

  openPrivacyOrTerms() {
    const dialogRef = this.dialog.open(PrivacyTermsModalComponent, {
      data: {
        type: 'terms',
      }, panelClass: [
        "custom-dialog-container",
        "w50modal",
        "modal-width",
        "extrapop",
      ],

      height: "auto",
      width: "50%",
    });
    dialogRef.afterClosed().safeSubscribe(this, (e) => {
      console.log(e);

    });
  }

  setBooking(bookingData) {
    let booking;
    if(this.bookedPackages && this.bookedPackages?.filter(p => !!p.name).map(x => x.id).length > 0) {
      if(bookingData.bookingPackages?.filter(p => !!p.package).length > 0) {
        bookingData.bookingPackages.forEach(bp => {
          let pack = this.bookedPackages.find(p => p.id == bp.package.id);
          if (pack) {
           bp.package = pack
          }
        })
      }
      booking = bookingData;
    } else {
      booking = bookingData;
    }
    return booking;
  }

}
