<div class="mmc-modal-wrapper cancel-booking-modal Despatch-status">
  <div
    class="mmc_flex--container d-header"
    style="
      background: #00507c;
      height: 45px !important;
      justify-content: space-between;
    "
  >
    <div
      class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-h2 d-header-title"
      style="
        color: white;
        font-size: 20px;
        font-weight: bolder;
        padding-left: 30px;
      "
    >
    {{'DESPATCHSTATUSINFO.Despatch Status Information' | optimotranslate}}
    </div>
    <div
      class="close-btn"
      style="
        align-items: center;
        display: flex;
        padding-right: 32px;
        justify-content: flex-end;
      "
    >
      <mat-icon
        style="
          color: white;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: flex-end;
        "
        (click)="dialogRef.close()"
        >close</mat-icon
      >
    </div>
  </div>
  <div class="mmc-modal-body" style="padding-top: 0 !important;">
    <div class="dispatch-table">
    <table
      mat-table
      [dataSource]="dispatchItems"
      class="mat-elevation-z8"
      style="width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;
      box-shadow: none;"
    >
      <ng-container matColumnDef="Item">
        <th mat-header-cell *matHeaderCellDef>{{'DESPATCHSTATUSINFO.Item' | optimotranslate}}</th>
        <td mat-cell *matCellDef="let element">{{element.item}}</td>
      </ng-container>
      <ng-container matColumnDef="Qty">
        <th mat-header-cell *matHeaderCellDef>{{'DESPATCHSTATUSINFO.Qty' | optimotranslate}}</th>
        <td mat-cell *matCellDef="let element">{{element.qty}}</td>
      </ng-container>
      <ng-container matColumnDef="Print Status">
        <th mat-header-cell *matHeaderCellDef>{{'DESPATCHSTATUSINFO.Print Status' | optimotranslate}}</th>
        <td mat-cell *matCellDef="let element">{{element.printStatus}}</td>
      </ng-container>
      <ng-container matColumnDef="Dispatch Status">
        <th mat-header-cell *matHeaderCellDef>{{'DESPATCHSTATUSINFO.Despatch Status' | optimotranslate}}</th>
        <td mat-cell *matCellDef="let element">{{element.dispatchStatus}}</td>
      </ng-container>
      <ng-container matColumnDef="Delivery Method">
        <th mat-header-cell *matHeaderCellDef>{{'DESPATCHSTATUSINFO.Delivery Method' | optimotranslate}}</th>
        <td mat-cell *matCellDef="let element">{{element.deliveryMethod}}</td>
      </ng-container>
      <ng-container matColumnDef="Tracking No">
        <th mat-header-cell *matHeaderCellDef>{{'DESPATCHSTATUSINFO.Tracking No' | optimotranslate}}</th>
        <td mat-cell *matCellDef="let element">{{element.trackingNo}}</td>
      </ng-container>
      <ng-container matColumnDef="Despatch Note">
        <th mat-header-cell *matHeaderCellDef>{{'DESPATCHSTATUSINFO.Despatch Note' | optimotranslate}}</th>
        <td mat-cell *matCellDef="let element">{{element.despatchNote}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  </div>
</div>
