import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { BaseComponent } from "src/modules/shared/base.component";
import * as _ from "lodash";
import { MatAccordion } from "@angular/material/expansion";


@Component({
  selector: "opt-package-description-modal",
  templateUrl: "./package-description-modal.component.html",
})
export class PackageDescriptionModalComponent
  extends BaseComponent
  implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  publicPackage: any;
  bookingPackage:any;
  description:string;
  isUpsell: boolean=false;
  upSell: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PackageDescriptionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    dialogRef.disableClose = false;
    this.isUpsell=data.isUpsell;
    if(data.isUpsell){
      this.upSell=data.pkg
    }else{
      this.publicPackage = data.pkg;
      this.bookingPackage = data.booking;
    }
  }

  ngOnInit(): void {
    if(!this.isUpsell){
      if(this.publicPackage && this.publicPackage.package && this.publicPackage.package.description){
        this.description=this.publicPackage.package.description;
      }else if (this.publicPackage && this.publicPackage.package && this.publicPackage.package.webDescription){
        this.description=this.publicPackage.package.webDescription;
      }else{
        this.description=this.publicPackage.package.shortDescription;
      }
    }
    }

  ngAfterViewInit() {
    if(!this.isUpsell && this.description){
      document.getElementById('description').innerHTML = this.description;
    }
    else if (this.isUpsell && this.upSell && this.upSell.item && this.upSell.item.webLongDescription){
      document.getElementById('upSelldescription').innerHTML = this.upSell.item.webLongDescription;
    }
  }
}
