
export class ValidationError {
  type: ErrorType;
  description: string;
  isAdultDelete: boolean;
  canSwitchAdult: boolean;
}

export enum ErrorType {
  WARNING = "1",
  ERROR = "2",
  SUCCESS = "3"

}
