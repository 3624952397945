import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { QuestionSummary } from "src/modules/models/booking/question-summary";
import { MultiSessionQuestion } from "src/modules/models/public-web/item/PackageItemQuestionnaire";
import { BookingQuestion } from "src/modules/models/regular-portal/booking/question/booking-question";
import { ItemBookingQuestionnaire } from "src/modules/models/regular-portal/booking/question/booking-questionnaire";
import { PublicBookingService } from "src/modules/services/public-web/public-booking.service";
import { BaseComponent } from "src/modules/shared/base.component";
import { Store } from "src/modules/store";
import { SetBooking, UpdateSelectedBooking } from "../../../../store/public-web/public-web-actions";
import { IDateAndSession, IPBReducer } from "../../../../store/public-web/public-web-reducers";
import * as _ from "lodash";
import {
  AnswerUploadedFile,
  FileUploadQuestion,
  Question,
} from "src/modules/models/regular-portal/booking/question/question";
import { Utility } from "src/modules/utility";
import moment from "moment";
import { MatAccordion } from "@angular/material/expansion";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertMessageComponent } from "src/modules/shared/alert-message/alert-message.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as PBSelector from '../../../../store/public-web/public-web-selectors';
import { PBUtility } from "src/modules/public-web/pb-utility";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "opt-questionnaire",
  templateUrl: "./questionnaire.component.html"
})
export class PublicQuestionnaireComponent extends BaseComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  bookingQuestion: BookingQuestion[];
  questionsList: BookingQuestion[] = [];

  itemBookingquestions: ItemBookingQuestionnaire[] = [];

  answeredQuestionIds: string[] = [];
  itemBookingquestionsArray: any[] = [];

  questionCount: number;
  answeredQuestionCount: number;
  isMandatory: boolean;
  packagList: any[];
  booking: any;
  bookingPackages = [];
  currentTime: Date;
  disableButton: boolean;
  filterItem: string;
  isHidden: boolean;
  questionSummarys: QuestionSummary[] = [];
  questionToPatch: BookingQuestion[] = [];
  enableToSkip = false;
  enableClose: boolean = false;
  multiSessionQuestions: MultiSessionQuestion[];
  sessionQuestions: MultiSessionQuestion[];
  response = {
    redirect: true,
  };
  packageBookingQuestionnaires: any;
  unUpdatedBooking;
  cartType: any;
  patchPreviewId: string;
  constructor(
    public dialogRef: MatDialogRef<PublicQuestionnaireComponent>,
    private store: Store<any>,
    private router: Router,
    public snackBar: MatSnackBar,
    private _bookingService: PublicBookingService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.store.select(PBSelector.selectBooking).safeSubscribe(this, booking => {
      this.unUpdatedBooking = booking
    });
    dialogRef.disableClose = true;
    this.enableToSkip = !!data.enableSkipQue;
    this.enableClose = !!data.enableClose;
    this.bookingQuestion = [];
    this.booking = data.booking;
    this.bookingPackages = data.bookingPackages ? data.bookingPackages : [];
    this.multiSessionQuestions = [];
    this.sessionQuestions = [];
    // this.IS_MULTI_SELECTION_SESSION = !!data.IS_MULTI_SELECTION_SESSION
    //   ? data.IS_MULTI_SELECTION_SESSION
    //   : false;
    // if (this.IS_MULTI_SELECTION_SESSION) {
    //   this.mapBookingQuestionersWithSession();
    // } else {
    this.mapBookingQuestionnaire();
    // }
    // this.accordion.openAll();
    this.answeredQuestionCount = 0;
    this.isMandatory = !!data.isMandatory;
    this.isHidden = false;
  }
  getTranslate(data: any) {
    var temp=data;
    data=data.replace('AM','').replace('PM','');
    var translatedData = data.replace(/[^A-Za-z]/g, '');
    this.translate.get('MONTHS_DAYS.' + translatedData).safeSubscribe(this, (res: string) => {
      data = temp.replace(translatedData, res);
    });
    return data;
  }
  ngOnInit() {
    this.commonMethod();
    this.store.select(PBSelector.selectBooking).safeSubscribe(this, booking => {
      this.unUpdatedBooking = booking
    });
    this.store.select('PBReducer').safeSubscribe(this, (state: IPBReducer) => {
      this.cartType = state.bookingMode;
    });
    this.store.select(PBSelector.selectPatchPreviewId).safeSubscribe(this, (d) => {
      this.patchPreviewId = d;
    })
  }
  commonMethod() {
    this.filterItem = "1";
    this.disableButton = false;
    this.currentTime = new Date();
    this.isHidden = false;
    // this.isDisabledCheck();
    this.booking.itemBookingQuestionnaires = [];
    this.booking.packageBookingQuestionnaires = [];
    this.booking.bookingQuestionnaires.forEach((Questionnaires) => {
      if (Questionnaires.type == "itemBookingQuestionnaire") {
        this.booking.itemBookingQuestionnaires.push(
          ...Questionnaires.bookingQuestions
        );
      } else if (Questionnaires.type == "packageBookingQuestionnaire") {
        this.booking.packageBookingQuestionnaires.push(
          ...Questionnaires.bookingQuestions
        );
      }
    });
    this.itemBookingquestions = this.booking.itemBookingQuestionnaires;
    this.packageBookingQuestionnaires =
      this.booking.packageBookingQuestionnaires;

    //Question Count
    this.questionCount = 0;
    if (this.itemBookingquestions) {
      var itemQuestionCount = 0;
      this.itemBookingquestions.forEach(function (q, i) {
        // itemQuestionCount =
        //   itemQuestionCount + q.answerWisedBookingQuestionnaireGroups.length;
        itemQuestionCount++;
      });
      this.questionCount += itemQuestionCount;
      // //--------- package names for the Dropdown
      // let pckgNameList = [];
      // this.packagList = pckgNameList;

      // //-------- items names for the Dropdown
      // let itmNameList = [];
      // this.itemBookingquestions.forEach(function (q, i) {
      //   if (itmNameList.length == 0) {
      //     itmNameList.push(q.itemName);
      //   } else if (itmNameList.length > 0) {
      //     var _item = itmNameList.find((item) => item != q.itemName);
      //     if (_item) {
      //       itmNameList.push(q.itemName);
      //     }
      //   }
      // });

      this.itemBookingquestionsArray = this.itemBookingquestions;
    }
    if (this.packageBookingQuestionnaires) {
      var QuestionCount = 0;
      this.packageBookingQuestionnaires.forEach(function (q, i) {
        // itemQuestionCount =
        //   itemQuestionCount + q.answerWisedBookingQuestionnaireGroups.length;
        QuestionCount++;
      });
      this.questionCount += QuestionCount;
      // //--------- package names for the Dropdown
      // let pckgNameList = [];
      // this.packagList = pckgNameList;

      // //-------- items names for the Dropdown
      // let itmNameList = [];
      // this.packageBookingQuestionnaires.forEach(function (q, i) {
      //   if (itmNameList.length == 0) {
      //     itmNameList.push(q.itemName);
      //   } else if (itmNameList.length > 0) {
      //     var _item = itmNameList.find((item) => item != q.itemName);
      //     if (_item) {
      //       itmNameList.push(q.itemName);
      //     }
      //   }
      // });

      this.packageBookingQuestionnaires = this.itemBookingquestions;
    }
  }
  private allocateConditionalQuestionNo(
    bookingQuestions: BookingQuestion[],
    primaryQuestion: Question = undefined
  ) {
    bookingQuestions?.forEach((bookingQuestion) => {
      if (bookingQuestion.conditionalQuestion && !!primaryQuestion) {
        bookingQuestion.question.questionNo = `${primaryQuestion.questionNo}.${bookingQuestion.question.questionNo}`;
      }
      if (
        !!bookingQuestion.conditionalBookingQuestions &&
        bookingQuestion.conditionalBookingQuestions.length > 0
      ) {
        bookingQuestion.conditionalBookingQuestions =
          this.allocateConditionalQuestionNo(
            bookingQuestion.conditionalBookingQuestions,
            bookingQuestion.question
          );
      }
    });
    return bookingQuestions;
  }
  private deallocateConditionalQuestionNo(bookingQuestions: BookingQuestion[]) {
    bookingQuestions.forEach((bookingQuestion) => {
      if (bookingQuestion.conditionalQuestion) {
        bookingQuestion.question.questionNo = `0`;
      }
      if (
        !!bookingQuestion.conditionalBookingQuestions &&
        bookingQuestion.conditionalBookingQuestions.length > 0
      ) {
        bookingQuestion.conditionalBookingQuestions =
          this.deallocateConditionalQuestionNo(
            bookingQuestion.conditionalBookingQuestions
          );
      }
    });
    return bookingQuestions;
  }
  questionAnswerOutput(val) {
    // if (this.IS_MULTI_SELECTION_SESSION) {
    //   const filtersQS = this.itemBookingquestionsArray.filter(
    //     (i) => i.question.id === val.question.id
    //   );
    //   filtersQS.forEach((qs) => {
    //     qs.answer = val.answer;
    //     qs.answeredTime = val.answeredTime;
    //     if (this.questionsList.length <= 0) {
    //       this.questionsList.push(qs);
    //       this.incrementAnsweredQuestionCount(qs);
    //     } else {
    //       const q = this.questionsList.findIndex((ans) => ans.id === qs.id);
    //       if (q === -1) {
    //         this.questionsList.push(qs);
    //         this.incrementAnsweredQuestionCount(qs);
    //       } else {
    //         this.questionsList[q] = qs;
    //       }
    //     }
    //   });
    // } else {
    if (this.questionsList.length <= 0) {
      this.questionsList.push(val);
      this.incrementAnsweredQuestionCount(val);
    } else {
      var qs = this.questionsList.find(
        (ans) => ans.question.id == val.question.id && ans.id == val.id
      );
      if (qs == undefined) {
        this.questionsList.push(val);
        this.incrementAnsweredQuestionCount(val);
      }
    }
    // }
  }

  getSessionStartToEnd(sessions: IDateAndSession[]) {
    let start: Date, end: Date;
    sessions.forEach((s) => {
      if (!start && !end) {
        start = new Date(s.startTime);
        end = new Date(s.endTime);
      } else {
        let oldStartMin = start.getTime();
        let curStartMin = new Date(s.startTime).getTime();
        if (oldStartMin > curStartMin) {
          start = new Date(s.startTime);
        }
        let oldEndMin = end.getTime();
        let curEndMin = new Date(s.endTime).getTime();
        if (oldEndMin < curEndMin) {
          end = new Date(s.endTime);
        }
      }
    });
    let yeS = new Intl.DateTimeFormat("en", { year: "numeric" }).format(start);
    let moS = new Intl.DateTimeFormat("en", { month: "short" }).format(start);
    let daS = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(start);

    let yeE = new Intl.DateTimeFormat("en", { year: "numeric" }).format(end);
    let moE = new Intl.DateTimeFormat("en", { month: "short" }).format(end);
    let daE = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(end);
    return `${daS}-${moS}-${yeS} to ${daE}-${moE}-${yeE}`;
  }
  // Get Answered Item Question Count
  answeredQ;
  getAnsweredItemQuestionCount(questions: BookingQuestion[] = []) {
    this.answeredQ = this.getAnswerQuestion(questions, 0);
    let qCount = this.getQuestionCount(questions);
    // this.answeredQ > 0 ? document.getElementById('submitBtn').removeAttribute('style') : '';
    let outof:string= this.translate.instant('QUESTIONPOPUP.out of')
    let answered:string =this.translate.instant('QUESTIONPOPUP.answered')
    return `${this.answeredQ} ${outof} ${qCount} ${answered} `;
  }

  getQuestionCount(questions: BookingQuestion[], count = 0) {
    questions.forEach((q) => {
      if (q.conditionalQuestion) {
        if (q.canDisplayQuestion) {
          count++;
        }
      } else {
        count++;
      }

      if (
        q.conditionalBookingQuestions &&
        q.conditionalBookingQuestions.length
      ) {
        count = this.getQuestionCount(q.conditionalBookingQuestions, count);
      }
    });
    return count;
  }

  getAnswerdPackageCount() {
    let count = 0;
    let packageCount = 0;
    //  console.log(this.sessionQuestions);
    // if (this.IS_MULTI_SELECTION_SESSION) {
    //   this.multiSessionQuestions.forEach((q) => {
    //     let unAunswq = this.getUnaunsweredPackageMendQ(q);
    //     unAunswq == 0 ? packageCount++ : '';
    //   });
    // }
    // else {
    this.sessionQuestions.forEach((q) => {
      let unAunswq = this.getUnaunsweredPackageMendQ(q);
      unAunswq == 0 ? packageCount++ : '';
    });
    // }
    let outof:string= this.translate.instant('QUESTIONPOPUP.out of')
    let answered:string =this.translate.instant('QUESTIONPOPUP.answered')
    return `${packageCount} ${outof} ${this.sessionQuestions.length} ${answered} `;
  }

  get getTotalAnsweredQuestionCount() {
    let quesCount = 0;
    let answeredQ = 0;
    // if (this.IS_MULTI_SELECTION_SESSION && this.multiSessionQuestions.length > 0) {
    //   this.multiSessionQuestions.forEach(qs => {
    //     if (
    //       !!qs.bookingQuestions &&
    //       qs.bookingQuestions.length > 0
    //     ) {
    //       quesCount = this.getQuestionCount(qs.bookingQuestions, quesCount);
    //     }
    //     if (qs.bookingQuestions && qs.bookingQuestions.length > 0) {
    //       answeredQ = this.getAnswerQuestion(
    //         qs.bookingQuestions,
    //         answeredQ
    //       );
    //     }
    //     qs.items.forEach((it) => {
    //       quesCount = this.getQuestionCount(it.bookingQuestions, quesCount);
    //       answeredQ = this.getAnswerQuestion(it.bookingQuestions, answeredQ);
    //     });
    //   });
    // } else
    if (this.sessionQuestions.length > 0) {
      this.sessionQuestions.forEach(qs => {
        if (
          !!qs.bookingQuestions &&
          qs.bookingQuestions.length > 0
        ) {
          quesCount = this.getQuestionCount(qs.bookingQuestions, quesCount);
        }
        if (qs.bookingQuestions && qs.bookingQuestions.length > 0) {
          answeredQ = this.getAnswerQuestion(
            qs.bookingQuestions,
            answeredQ
          );
        }
        qs.items.forEach((it) => {
          quesCount = this.getQuestionCount(it.bookingQuestions, quesCount);
          answeredQ = this.getAnswerQuestion(it.bookingQuestions, answeredQ);
        });
      });
    }
    let outof:string= this.translate.instant('QUESTIONPOPUP.out of')
    let answered:string =this.translate.instant('QUESTIONPOPUP.answered')
    return `${answeredQ} ${outof} ${quesCount} ${answered} `;
  }

  // Get Answered Package Question Count
  getAnsweredPackageQuestionCount(qs: MultiSessionQuestion) {
    let quesCount = 0;
    let answeredQ = 0;
    if (
      !!qs.bookingQuestions &&
      qs.bookingQuestions.length > 0
    ) {
      quesCount = this.getQuestionCount(qs.bookingQuestions, quesCount);
    }
    if (qs.bookingQuestions && qs.bookingQuestions.length > 0) {
      answeredQ = this.getAnswerQuestion(
        qs.bookingQuestions,
        answeredQ
      );
    }
    qs.items.forEach((it) => {
      quesCount = this.getQuestionCount(it.bookingQuestions, quesCount);
      answeredQ = this.getAnswerQuestion(it.bookingQuestions, answeredQ);
    });
    let outof:string= this.translate.instant('QUESTIONPOPUP.out of')
    let answered:string =this.translate.instant('QUESTIONPOPUP.answered')
    return `${answeredQ} ${outof} ${quesCount} ${answered} `;
  }
  // Return un-answered Mandatory question Count
  getUnaunsweredPackageMendQ(qs: MultiSessionQuestion) {
    let unAunswq = 0;
    if (qs.package.bookingQuestions && qs.package.bookingQuestions.length > 0) {
      unAunswq = this.getUnAnswerMandotoryQuestion(
        qs.package.bookingQuestions,
        unAunswq
      );
    } else if (qs.bookingQuestions && qs.bookingQuestions.length > 0) {
      unAunswq = this.getUnAnswerMandotoryQuestion(
        qs.bookingQuestions,
        unAunswq
      );
    }
    if (qs.items.length > 0) {
      qs.items.forEach((it) => {
        unAunswq = this.getUnAnswerMandotoryQuestion(
          it.bookingQuestions,
          unAunswq
        );
      });
    }
    return unAunswq;
  }
  // Return un-answered Mandatory question Message
  unAunswqCount:number=0;
  getUnAnsweredMendQues() {
    let unAunswq = 0;
    let qsA = 0;
    let question;
    // if (this.IS_MULTI_SELECTION_SESSION) {
    //   question = this.multiSessionQuestions;
    // } else {
    question = this.sessionQuestions;
    // }
    question.forEach((qs) => {
      if (
        qs.bookingQuestions &&
        qs.bookingQuestions.length > 0
      ) {
        qs.bookingQuestions.forEach((val: any) => {
          if (val.question.mandatory) {
            qsA += 1;
            switch (val.type) {
              case "textQuestionAnswer":
                if (val.answer === "" || val.answer === undefined) {
                  unAunswq = unAunswq + 1;
                }
                break;
              case "richTextQuestionAnswer":
                if (val.answer === "" || val.answer === undefined) {
                  unAunswq = unAunswq + 1;
                }
                break;
              case "booleanQuestionAnswer":
                if (val.answer === undefined) {
                  unAunswq = unAunswq + 1;
                }
                break;
              case "singleChoiceQuestionAnswer":
                if (val.bookingAnswerChoice === undefined) {
                  unAunswq = unAunswq + 1;
                }
                break;
              case "multipleChoiceQuestionAnswer":
                if (val.bookingAnswerChoice === undefined) {
                  unAunswq = unAunswq + 1;
                }
                break;
              case "dateAndTimeQuestionAnswer":
                if (
                  val.question != undefined &&
                  ((val.question.answerDateAndTime && val.date === undefined && val.time === undefined) ||
                    (val.question.answerDate && val.date === undefined) ||
                    (val.question.answerTime && val.time === undefined))
                ) {
                  unAunswq = unAunswq + 1;
                }
                break;
              case "fileUploadQuestionAnswer":
                if (
                  (val.answerUploadedFiles === undefined || val.answerUploadedFiles?.length == 0) &&
                  (val.question as FileUploadQuestion).minCount >=
                  val.answerUploadedFiles?.filter((x: AnswerUploadedFile) => !x.isDelete).length
                ) {
                  unAunswq = unAunswq + 1;
                }
                break;
            }
          }
          if (val.conditionalBookingQuestions && val.conditionalBookingQuestions.length > 0) {
            unAunswq = this.getUnAnswerMandotoryQuestion(
              val.conditionalBookingQuestions,
              unAunswq
            );
          }
        });
      }
      if (qs.items.length > 0) {
        qs.items.forEach((it) => {
          unAunswq = this.getUnAnswerMandotoryQuestion(
            it.bookingQuestions,
            unAunswq
          );
        });
      }
    });
    this.unAunswqCount=unAunswq;
    return unAunswq !== 0
      ? unAunswq > 1
        ? `${unAunswq} mandatory questions unanswered`
        : `${unAunswq} mandatory question unanswered`
      : "";
  }
  // Updated With Conditional Question
  getUnAnswerMandotoryQuestion(
    bookingQuestions: BookingQuestion[],
    UnAnswerCount = 0,
    primaryQuestion: BookingQuestion = undefined
  ) {
    bookingQuestions.forEach((val: any) => {
      if (val) {
        if (val.question && val.question.mandatory) {
          // qsA += 1;
          switch (val.type) {
            case "textQuestionAnswer":
              if (val.conditionalQuestion) {
                if (
                  val.canDisplayQuestion &&
                  (val.answer === "" || val.answer == undefined)
                ) {
                  UnAnswerCount++;
                }
              } else if (val.answer === "" || val.answer == undefined) {
                UnAnswerCount++;
              }
              break;
            case "richTextQuestionAnswer":
              if (val.conditionalQuestion) {
                if (
                  val.canDisplayQuestion &&
                  (val.answer === "" || val.answer == undefined)
                ) {
                  UnAnswerCount++;
                }
              } else if (val.answer === "" || val.answer == undefined) {
                UnAnswerCount++;
              }
              break;
            case "booleanQuestionAnswer":
              if (val.conditionalQuestion) {
                if (val.canDisplayQuestion && val.answer === undefined) {
                  UnAnswerCount++;
                }
              } else if (val.answer === undefined) {
                UnAnswerCount++;
              }
              break;
            case "singleChoiceQuestionAnswer":
              if (val.conditionalQuestion) {
                if (
                  val.canDisplayQuestion &&
                  val.bookingAnswerChoice === undefined
                ) {
                  UnAnswerCount++;
                }
              } else if (val.bookingAnswerChoice === undefined) {
                UnAnswerCount++;
              }
              break;
            case "multipleChoiceQuestionAnswer":
              if (val.conditionalQuestion) {
                if (
                  val.canDisplayQuestion &&
                  val.bookingAnswerChoice === undefined
                ) {
                  UnAnswerCount++;
                }
              } else if (val.bookingAnswerChoice === undefined) {
                UnAnswerCount++;
              }
              break;
            case "dateAndTimeQuestionAnswer":
              if (val.conditionalQuestion) {
                if (
                  val.canDisplayQuestion &&
                  ((val.question.answerDateAndTime && val.date == undefined && val.time == undefined) ||
                    (val.question.answerDate && val.date == undefined) ||
                    (val.question.answerTime && val.time == undefined))
                ) {
                  UnAnswerCount++;
                }
              } else if (
                val.question != undefined &&
                ((val.question.answerDateAndTime && val.date == undefined && val.time == undefined) ||
                  (val.question.answerDate && val.date == undefined) ||
                  (val.question.answerTime && val.time == undefined))
              ) {
                UnAnswerCount++;
              }
              break;
            case "fileUploadQuestionAnswer":
              if (val.conditionalQuestion) {
                if (
                  val.canDisplayQuestion &&
                  !!val.answerUploadedFiles &&
                  (val.question as FileUploadQuestion).minCount >
                  val.answerUploadedFiles?.filter(
                    (x: AnswerUploadedFile) => !x.isDelete
                  ).length
                ) {
                  UnAnswerCount++;
                }
              } else if (
                !!val.answerUploadedFiles &&
                (val.question as FileUploadQuestion).minCount >
                val.answerUploadedFiles?.filter(
                  (x: AnswerUploadedFile) => !x.isDelete
                ).length
              ) {
                UnAnswerCount++;
              }
              break;
          }
        }
        if (
          val.conditionalBookingQuestions &&
          val.conditionalBookingQuestions.length > 0
        ) {
          UnAnswerCount = this.getUnAnswerMandotoryQuestion(
            val.conditionalBookingQuestions,
            UnAnswerCount,
            val
          );
        }
      }
    });
    return UnAnswerCount;
  }
  // Updated With Conditional Question
  getAnswerQuestion(bookingQuestions: BookingQuestion[], answerCount = 0) {
    bookingQuestions.forEach((val: any) => {
      switch (val.type) {
        case "textQuestionAnswer":
          if (val.conditionalQuestion) {
            if (
              val.canDisplayQuestion &&
              val.answer !== "" &&
              val.answer != undefined
            ) {
              answerCount++;
            }
          } else if (val.answer !== "" && val.answer != undefined) {
            answerCount++;
          }
          break;
        case "richTextQuestionAnswer":
          if (val.conditionalQuestion) {
            if (
              val.canDisplayQuestion &&
              val.answer !== "" &&
              val.answer != undefined
            ) {
              answerCount++;
            }
          } else if (val.answer !== "" && val.answer != undefined) {
            answerCount++;
          }
          break;
        case "booleanQuestionAnswer":
          if (val.conditionalQuestion) {
            if (val.canDisplayQuestion && val.answer !== undefined) {
              answerCount++;
            }
          } else if (val.answer !== undefined) {
            answerCount++;
          }
          break;
        case "singleChoiceQuestionAnswer":
          if (val.conditionalQuestion) {
            if (
              val.canDisplayQuestion &&
              val.bookingAnswerChoice !== undefined
            ) {
              answerCount++;
            }
          } else if (val.bookingAnswerChoice !== undefined) {
            answerCount++;
          }
          break;
        case "multipleChoiceQuestionAnswer":
          if (val.conditionalQuestion) {
            if (
              val.canDisplayQuestion &&
              val.bookingAnswerChoice !== undefined
            ) {
              answerCount++;
            }
          } else if (val.bookingAnswerChoice !== undefined) {
            answerCount++;
          }
          break;
        case "dateAndTimeQuestionAnswer":
          if (val.conditionalQuestion) {
            if (
              val.canDisplayQuestion &&
              ((val.question.answerDateAndTime && val.date != undefined && val.time != undefined) ||
                (val.question.answerDate && val.date != undefined) ||
                (val.question.answerTime && val.time != undefined))
            ) {
              answerCount++;
            }
          } else if (
            val.question != undefined &&
            ((val.question.answerDateAndTime && val.date != undefined && val.time != undefined) ||
              (val.question.answerDate && val.date != undefined) ||
              (val.question.answerTime && val.time != undefined))
          ) {
            answerCount++;
          }
          break;
        case "fileUploadQuestionAnswer":
          if (val.conditionalQuestion) {
            if (
              val.canDisplayQuestion &&
              !!val.answerUploadedFiles &&
              (val.question as FileUploadQuestion).minCount <=
              val.answerUploadedFiles?.filter(
                (x: AnswerUploadedFile) => !x.isDelete
              ).length
            ) {
              answerCount++;
            }
          } else if (
            !!val.answerUploadedFiles &&
            (val.question as FileUploadQuestion).minCount <=
            val.answerUploadedFiles?.filter(
              (x: AnswerUploadedFile) => !x.isDelete
            ).length
          ) {
            answerCount++;
          }
          break;
      }
      if (
        val.conditionalBookingQuestions &&
        val.conditionalBookingQuestions.length > 0
      ) {
        answerCount = this.getAnswerQuestion(
          val.conditionalBookingQuestions,
          answerCount
        );
      }
    });
    return answerCount;
  }
  mapBookingQuestionnaire() {
    this.booking.bookingQuestionnaires.forEach((bq) => {
      if(this.bookingPackages.length > 0) {
        let bPackage = this.bookingPackages.find(bp => bp.id.toString() == bq.bookingPackage.id.toString());
        if (bPackage) {
          bq.bookingPackage = bPackage;
        }
      }
      if(bq.type != "globalBookingQuestionnaire" && !!bq.bookingQuestions){
      let isPackageQuestion = bq.type === "packageBookingQuestionnaire";
      let bookingItemId, bookingItem;
      if (!isPackageQuestion) {
        if (bq.bookingItem) {
          bookingItemId = bq.bookingItem.id;
        }
        if (bq.bookingPackage) {
          let bookingPackageInUpdatedBooking;
          if (this.bookingPackages.length > 0 && this.bookingPackages?.find(bp => bp.id == bq.bookingPackage.id)) { //edit booking
            bookingPackageInUpdatedBooking = this.bookingPackages.find(bp => bp.id == bq.bookingPackage.id);
          } else if(this.unUpdatedBooking.bookingPackages.filter(bp => !!bp.name)?.length > 0){ //new booking
            bookingPackageInUpdatedBooking = this.unUpdatedBooking.bookingPackages.find(bp => bp.id == bq.bookingPackage.id);
          }

          if(bookingPackageInUpdatedBooking){
            bookingItem = bookingPackageInUpdatedBooking.bookingItems?.find(
              (i) => i.id === bookingItemId
            );
          }
        }
      }
      const session: IDateAndSession = {
        id: bq.bookingPackage.id,
        startTime: bq.bookingPackage.startDate,
        endTime: bq.bookingPackage.endDate,
        availability: undefined,
      };
      const mIndex = this.sessionQuestions.findIndex(
        (ms) => ms.bookingPackageID === bq.bookingPackage.id
      );
      if (mIndex !== -1) {
        if (
          !this.sessionQuestions[mIndex].sessions.find(
            (d) => d.id === session.id
          )
        ) {
          this.sessionQuestions[mIndex].sessions.push(session);
        }
        if (isPackageQuestion) {
          this.sessionQuestions[mIndex].bookingQuestions.push(
            ...this.allocateConditionalQuestionNo(bq.bookingQuestions)
          );
        } else if (bookingItem) {
          const itemIndex = this.sessionQuestions[mIndex].items.findIndex(
            (i) => i.id === bookingItem.item.id
          );
          if (itemIndex !== -1) {
            this.sessionQuestions[mIndex].items[
              itemIndex
            ].bookingQuestions.push(...bq.bookingQuestions);
          } else {
            const item = _.cloneDeep(bookingItem.item);
            item.bookingQuestions = [
              ...this.allocateConditionalQuestionNo(bq.bookingQuestions),
            ];
            // !!item.bookingQuestions ? item.bookingQuestions = [bq.bookingQuestions] : item.bookingQuestions.push(bq.bookingQuestions);
            this.sessionQuestions[mIndex].items.push(item);
          }
        }
      } else {
        // !!item.bookingQuestions ? item.bookingQuestions = [bq.bookingQuestions] : item.bookingQuestions.push(bq.bookingQuestions)
        const multiSessionQuestion = new MultiSessionQuestion();
        if (isPackageQuestion) {
          multiSessionQuestion.bookingQuestions.push(
            ...this.allocateConditionalQuestionNo(bq.bookingQuestions)
          );
        } else if (bookingItem) {
          const item = _.cloneDeep(bookingItem.item);
          item.bookingQuestions = [
            ...this.allocateConditionalQuestionNo(bq.bookingQuestions),
          ];
          multiSessionQuestion.items = [item];
        }
        multiSessionQuestion.bookingPackageID = bq.bookingPackage.id;
        multiSessionQuestion.package = bq.bookingPackage.package;
        multiSessionQuestion.sessions.push(session);
        this.sessionQuestions.push(multiSessionQuestion);
      }
    }
    });

    this.setQuestionNumber(this.sessionQuestions)

    //Set question number

  }

  setQuestionNumber(questions: MultiSessionQuestion[]) {
    let number = 1;
    //Booking Questions
    questions.forEach(ques => {
      ques.bookingQuestions.forEach(que => {
        que.question.questionNo = number.toString();
        if (que.conditionalBookingQuestions && que.conditionalBookingQuestions.length > 0) {
          let subNumberYes = 1
          let subNumberNo = 1
          que.conditionalBookingQuestions.forEach(cbq => {
            if (cbq.conditionalQuestion && cbq.bookingConditionalQuestionAnswers && cbq.bookingConditionalQuestionAnswers[0]) {
              if (cbq.bookingConditionalQuestionAnswers[0].answerYes) {
                cbq.question.questionNo = number.toString() + '.' + subNumberYes.toString();
                subNumberYes++;
              } else if (!cbq.bookingConditionalQuestionAnswers[0].answerYes) {
                cbq.question.questionNo = number.toString() + '.' + subNumberNo.toString();
                subNumberNo++;
              }
            }
          });
        }
        number++
      });

      //Item Questions
      let itemNo = 1
      ques.items.forEach(itemQue => {
        itemQue.bookingQuestions.forEach(que => {
          que.question.questionNo = itemNo.toString();
          if (que.conditionalBookingQuestions && que.conditionalBookingQuestions.length > 0) {
            let subItmNumberYes = 1
            let subItmNumberNo = 1
            que.conditionalBookingQuestions.forEach(cbq => {
              if (cbq.conditionalQuestion && cbq.bookingConditionalQuestionAnswers && cbq.bookingConditionalQuestionAnswers[0]) {
                if (cbq.bookingConditionalQuestionAnswers[0].answerYes) {
                  cbq.question.questionNo = itemNo.toString() + '.' + subItmNumberYes.toString();
                  subItmNumberYes++;
                } else if (!cbq.bookingConditionalQuestionAnswers[0].answerYes) {
                  cbq.question.questionNo = itemNo.toString() + '.' + subItmNumberNo.toString();
                  subItmNumberNo++;
                }
              }
            });
          }
          itemNo++
        });
      });
    })
  }

  mapBookingQuestionersWithSession() {
    this.booking.bookingQuestionnaires.forEach((bq) => {
      const bookingItemId = bq.bookingItem.id;
      const bookingItem = bq.bookingPackage.bookingItems.find(
        (i) => i.id === bookingItemId
      );
      const session: IDateAndSession = {
        id: bq.bookingPackage.id,
        startTime: bq.bookingPackage.startDate,
        endTime: bq.bookingPackage.endDate,
        availability: undefined,
      };
      const mIndex = this.multiSessionQuestions.findIndex(
        (ms) =>
          ms.package.id === bq.bookingPackage.package.id &&
          !!ms.items.find((i) => i.id === bookingItem.item.id)
      );
      if (mIndex !== -1) {
        if (
          !this.multiSessionQuestions[mIndex].sessions.find(
            (d) => d.id === session.id
          )
        ) {
          this.multiSessionQuestions[mIndex].sessions.push(session);
        }
        // const itemIndex = this.sessionQuestions[mIndex].items.findIndex(
        //   (i) => i.id === bookingItem.item.id
        // );
      } else {
        const item = _.cloneDeep(bookingItem.item);
        // item.bookingQuestions = [...bq.bookingQuestions];
        item.bookingQuestions = [
          ...this.allocateConditionalQuestionNo(bq.bookingQuestions),
        ];

        const multiSessionQuestion = new MultiSessionQuestion();
        multiSessionQuestion.items = [item];
        multiSessionQuestion.package = bq.bookingPackage.package;
        multiSessionQuestion.sessions.push(session);
        this.multiSessionQuestions.push(multiSessionQuestion);
      }
    });
  }
  incrementAnsweredQuestionCount(val) {
    switch (val.type) {
      case "textQuestionAnswer":
        if (val.answer !== "" && val.answer != undefined) {
          this.answeredQuestionCount = this.answeredQuestionCount + 1;
        }
        break;
      case "richTextQuestionAnswer":
        if (val.answer !== "" && val.answer != undefined) {
          this.answeredQuestionCount = this.answeredQuestionCount + 1;
        }
        break;
      case "booleanQuestionAnswer":
        if (val.answer != undefined) {
          this.answeredQuestionCount = this.answeredQuestionCount + 1;
        }
        break;
      case "singleChoiceQuestionAnswer":
        if (val.bookingAnswerChoice != undefined) {
          this.answeredQuestionCount = this.answeredQuestionCount + 1;
        }
        break;
      case "multipleChoiceQuestionAnswer":
        if (val.bookingAnswerChoice != undefined) {
          this.answeredQuestionCount = this.answeredQuestionCount + 1;
        }
        break;
      case "dateAndTimeQuestionAnswer":
        if (
          val.question != undefined &&
          ((val.question.answerDateAndTime && val.date != undefined && val.time != undefined) ||
            (val.question.answerDate && val.date != undefined) ||
            (val.question.AnswerTime && val.time != undefined))
        ) {
          this.answeredQuestionCount = this.answeredQuestionCount + 1;
        }
        break;
      case "fileUploadQuestionAnswer":
        if (
          !!val.answerUploadedFiles &&
          (val.question as FileUploadQuestion).minCount <=
          val.answerUploadedFiles.filter((x: AnswerUploadedFile) => !x.isDelete)
            .length
        ) {
          this.answeredQuestionCount = this.answeredQuestionCount + 1;
        }
        break;
    }
    if (
      !!val.conditionalBookingQuestions &&
      val.conditionalBookingQuestions.length > 0
    ) {
      val.conditionalBookingQuestions.forEach((bq) => {
        this.incrementAnsweredQuestionCount(bq);
      });
    }
  }
  disableSubmit() {
    const UpdatedQuestions = this.questionsList.filter(
      (q) => q.answeredTime !== undefined && q.answeredTime > this.currentTime
    );
    return UpdatedQuestions.length >= 0 && this.unAunswqCount >= 1;
  }
  submitData() {
    this.isHidden = false;
    if (!this.checkMandatory() && this.isMandatory) {
      this.isHidden = true;
    }
    this.deallocateConditionalQuestionNo(this.questionToPatch);
    const UpdatedQuestions = this.questionsList.filter(
      (q) => q.answeredTime !== undefined && q.answeredTime > this.currentTime
    );
    const currentTime = this.currentTime;
    if (this.itemBookingquestionsArray.length > 0) {
      this.itemBookingquestionsArray.forEach(function (ibq, i) {
        if (
          ibq.answerWisedBookingQuestionnaireGroups &&
          ibq.answerWisedBookingQuestionnaireGroups.length > 0
        ) {
          ibq.answerWisedBookingQuestionnaireGroups.forEach(function (
            awbqg,
            i
          ) {
            if (
              awbqg.questionsTobeDisplay[0].answeredTime != undefined &&
              awbqg.questionsTobeDisplay[0].answeredTime > currentTime
            ) {
              awbqg.dates.forEach(function (actbp, i) {
                UpdatedQuestions.push(actbp.actualQuestionTobePatch);
              });
            }
          });
        }
        if (ibq.type === "dateAndTimeQuestionAnswer") {
          ibq.date = Utility.convertToISO(
            Utility.convertISOToDate(moment(ibq.date).toDate())
          );
        }
      });
    }
    if (UpdatedQuestions.length > 0) {
      isHaveAnswerdQuestion = true;
      this.questionToPatch = UpdatedQuestions;
      if (this.booking && this.booking.id) {
        // super.SaveQuestions(this.questionToPatch, eventEmitter);
      }
    } else {
      var isHaveAnswerdQuestion = false;
    }
    if (isHaveAnswerdQuestion && !this.isHidden) {
      this.deallocateConditionalQuestionNo(this.questionToPatch);
      this.questionToPatch = this.questionToPatch.filter(x => !x.conditionalQuestion)
      // this.dialogRef.close();
      this.questionToPatch = this.removeDateTimeTempValue(this.questionToPatch);
      this._bookingService
      .QuestionSavePatch(this.booking.id, this.questionToPatch, this.patchPreviewId ? this.patchPreviewId : '')
        .subscribe((res) => {
          if (res) {
            this.response.redirect = true;
            this.dialogRef.close(this.response);
            //this.store.dispatch(new UpdateSelectedBooking());
            this.store.dispatch(new SetBooking(PBUtility.updateBookingWithUpdateQuestionnaireAnswer(this.unUpdatedBooking, res, this.questionToPatch)));
            this.router.navigate(["booking-cart"]);
          } else {
            this.openSnackBarError(["Question answer got failed"]);
          }

        }, (error) => {
          //show error
          this.openSnackBarError(["Question answer got failed"]);

        });
    } else if (!this.isHidden) {
      this.response.redirect = true;
      this.dialogRef.close(this.response);
      this.router.navigate(["booking-cart"]);
    }
    // return isHaveAnswerdQuestion;
  }
  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 3000,
      verticalPosition: 'top',
    });
  }
  // Check Mandatory Questions are answered or not
  checkMandatory() {
    let isValid = true;
    var thisComponent = this;
    // itemBookingquestionsArray
    if (
      this.itemBookingquestionsArray &&
      this.itemBookingquestionsArray.length > 0
    ) {
      this.itemBookingquestionsArray.forEach((iqa) => {
        if (iqa.question.mandatory) {
          let isQAnswerd = this.questionsList.findIndex((q) => q.id === iqa.id);
          if (
            isQAnswerd === -1 ||
            (isQAnswerd !== -1 &&
              !this.isAnswered(this.questionsList[isQAnswerd]))
          ) {
            isValid = false;
          }
        }
      });
    }
    return isValid;
  }
  // Find The Question Answerwd or not
  isAnswered(ques) {
    var isAnsweredForTheQuestion = false;
    switch (ques.type) {
      case "textQuestionAnswer":
        if (ques.answer !== "" && ques.answer != undefined) {
          isAnsweredForTheQuestion = true;
        }
        break;
      case "richTextQuestionAnswer":
        if (ques.answer !== "" && ques.answer != undefined) {
          isAnsweredForTheQuestion = true;
        }
        break;
      case "booleanQuestionAnswer":
        if (ques.answer != undefined) {
          isAnsweredForTheQuestion = true;
        }
        break;

      case "singleChoiceQuestionAnswer":
        if (ques.bookingAnswerChoice != undefined) {
          isAnsweredForTheQuestion = true;
        }
        break;

      case "multipleChoiceQuestionAnswer":
        if (
          ques.bookingAnswerChoice != undefined &&
          ques.bookingAnswerChoice.length > 0
        ) {
          if (ques.question.quantityRequired) {
            isAnsweredForTheQuestion = true;
            ques.bookingAnswerChoice.forEach(function (bac) {
              if (
                bac.quantity == undefined ||
                bac.quantity == 0 ||
                bac.quantity == ""
              ) {
                isAnsweredForTheQuestion = false;
              }
            });
          } else {
            isAnsweredForTheQuestion = true;
          }
        }
        break;
      case "dateAndTimeQuestionAnswer":
        if (
          ques.question != undefined &&
          ((ques.question.isAnswerDateAndTime &&
            ques.date != undefined &&
            ques.time != undefined) ||
            (ques.question.isAnswerDate && ques.date != undefined) ||
            (ques.question.isAnswerTime && ques.time != undefined))
        ) {
          isAnsweredForTheQuestion = true;
        }
        break;
      case "fileUploadQuestionAnswer":
        if (
          !!ques.answerUplodedFiles &&
          (ques.question as FileUploadQuestion).minCount <=
          ques.answerUplodedFiles.filter((x) => !x.isDelete).length
        ) {
          isAnsweredForTheQuestion = true;
        }
        break;
      default:
        isAnsweredForTheQuestion = true;
    }
    return isAnsweredForTheQuestion;
  }
  removeDateTimeTempValue(bqs: any[]) {
    bqs.forEach((bq) => {
      switch (bq.type) {
        case "dateAndTimeQuestionAnswer":
          bq.tempTime = undefined;
          break;

        default:
          break;
      }
    });
    return bqs;
  }
  close() {
    this.response.redirect = false;
    this.dialogRef.close(this.response);
  }
  goToBookingCart() {
    this.response.redirect = true;
    this.dialogRef.close(this.response);
    this.router.navigate(["booking-cart"]);
  }
}
