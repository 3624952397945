<div class="container mt-5">
  <div class="mmc_payment mmc_card payment">
    <div class="mmc_flex--container flex-column">
      <div class="mmc_flex__item main-row">
        <div class="mmc_flex--container">
          <div class="mmc_flex__item p75">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="{{'PAYMENT.Invoice Reference Number' | optimotranslate}}"
                (ngModelChange)="invoiceValueChange($event)" [(ngModel)]="ValidateInvoiceId"
                value="{{'PAYMENT.Enter Invoice Reference Number/CRN' | optimotranslate }}"
                [readonly]="!allowToDoManualInvoiceSearch">
            </mat-form-field>
          </div>

          <div class="mmc_flex__item--vcenter p25 val-sec" *ngIf="!IsValidInvoiceRef && allowToDoManualInvoiceSearch">
            <div class="">
              <button class="btn__validate font__large" (click)="ValidateInvoice()" (keydown.enter)="ValidateInvoice()"
                mat-button>{{'PAYMENT.Validate' | optimotranslate}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="mmc_flex__item main-row">
        <mat-form-field [floatLabel]="'always'" class="example-full-width pay-amount">
          <input matInput id="amountInput1" placeholder="{{'PAYMENT.Payment Amount' | optimotranslate}} ({{currency}})"
            type="text" [ngStyle]="{'text-align':'right','position':'inherit','right':'-'+currency.length*14+'px'}"
            value="{{OutStandingAmount | NumberFormat}}" [readonly]="IsDisable" *ngIf="IsDisable">
          <mat-label>{{'PAYMENT.Payment Amount' | optimotranslate}}</mat-label>
          <input matInput id="amountInput" placeholder="{{'PAYMENT.Payment Amount' | optimotranslate}}"
            [formControl]="PaymentControl" type="number"
            [ngStyle]="{'text-align':'right','position':'inherit','right':'-'+currency.length*14+'px'}"
            [(ngModel)]="OutStandingAmount" [readonly]="IsDisable" *ngIf="!IsDisable" [min]="0"
            [max]="OutStandingAmountMax">
          <span matPrefix style="position: absolute; margin-left: 1px; top:-14px">{{currency}}</span>
        </mat-form-field>
        <!-- [value]="OutStandingAmount| number:'1.2-2'" [ngModel]="OutStandingAmount|number:'1.2-2'"-->
      </div>
      <div class="mcc_flex__item main-row btn-section" *ngIf="IsValidInvoice && IsValidInvoiceRef">
        <button type="button" class="buttons-secondary btn_mobile__pay font__large font__regular font__upper"
          (click)="proceedToPay()" (keydown.enter)="proceedToPay()" [disabled]="isBillerCodeLoading">
          {{'PAYMENT.proceed to pay' | optimotranslate}}
          <mat-icon *ngIf="isBillerCodeLoading"><mat-spinner style="top: 5px;" customSpinner color="#ffffff"
              diameter="20"></mat-spinner></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="(showLoader)">
    <opt-mmc-loader></opt-mmc-loader>
  </div>
</div>
