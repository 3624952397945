import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { map } from 'rxjs/operators';
import { SearchBaseFilter } from 'src/modules/models/public-web/filter';

@Injectable({
  providedIn: 'root'
})
export class VenueService {

  constructor(private _base: BaseService) {
  }

  getVenue(filter?: SearchBaseFilter) {  // Currently name filter is in use. It returns the data from MVC cache.
    let url = `api/public/venues?`;
    if (filter)
      url = filter.addFilter(url, 'filters');
    return this._base.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data
    }));
  }
}
