import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'opt-special-requests',
  templateUrl: './special-requests.component.html',
  styleUrls: ['./special-requests.component.scss']
})
export class SpecialRequestsComponent implements OnInit {
  specialNote:string;

  constructor(public matDialogRef: MatDialogRef<SpecialRequestsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  saveRequest(){
    this.matDialogRef.close(this.specialNote);
  }

  cancelClick(){
    this.matDialogRef.close();
  }
}
