import { Button } from "protractor";
import { ButtonLayout } from "./buttonLayout.model";
import { CardListLayout } from "./cardListLayout.model";
import { DescriptionLayout } from "./descriptionLayout.model";
import { LAYOUT_TYPE } from "./enums";
import { EmptyColumn } from "./gridLayout.model";
import { HTMLLayout } from "./htmlLayout.model";
import { ImageLayout } from "./image.model";
import { LayoutObject } from "./layoutObject.model";
import { InputLayout, PackageSelectionAreaLayout } from "./main";
import { NameLayout } from "./nameLayout.model";
import { SearchBoxLayout } from "./searchboxLayout.model";

export class RowLayout extends LayoutObject {
  rowChildLayouts: any[];
  allocatedCols: any[];

  constructor(data) {
    super(data);

    this.rowChildLayouts = data.childLayout
      ? data.childLayout.map((d) => this.mapLayout(d))
      : [];
  }

  mapLayout(layout) {
    switch (layout.type) {
      case LAYOUT_TYPE.NAME:
        return new NameLayout(layout);
      case LAYOUT_TYPE.IMAGE:
        return new ImageLayout(layout);
      case LAYOUT_TYPE.DESCRIPTION:
        return new DescriptionLayout(layout);
      case LAYOUT_TYPE.BUTTON:
        if(!layout.isSingleDay){
          return new ButtonLayout(layout);
      }
      case LAYOUT_TYPE.EMPTY:
        return new EmptyColumn(layout);
      case LAYOUT_TYPE.HTML:
        return new HTMLLayout(layout);
      case LAYOUT_TYPE.ROW:
        return new RowLayout(layout);
      case LAYOUT_TYPE.SEARCHBOX:
        return new SearchBoxLayout(layout);
      case LAYOUT_TYPE.CARD_LIST:
        return new CardListLayout(layout);
      case LAYOUT_TYPE.INPUT:
        return new InputLayout(layout);
      case LAYOUT_TYPE.PACKAGE_SELECTION_AREA_LAYOUT:
        return new PackageSelectionAreaLayout(layout);
      default:
        break;
    }
  }
}
