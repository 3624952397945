<div class="flex-container">
    <div class="header-container">
        <div class="header-text-container">
            <h4>{{'BOOKINGCART.Select Event' | optimotranslate}}</h4>
        </div>
        <div class="close-btn">
            <mat-icon (click)="cancelClick()">close</mat-icon>
        </div>
    </div>
    <div class="body-container">
        <div class="select-event-modal-content">
            <select class="select-event-modal-selection" id="events" name="events" [(ngModel)]="selectedEvent"
                style="width: 100%;">
                <option value="" disabled selected hidden>{{'BOOKINGCART.Select Event' | optimotranslate}}</option>
                <option *ngFor="let event of bookedEvents" [value]="event.id">
                    {{ event.venueName + " | " + (event.name.length > 30 ? (event.name | slice:0:30) + '...' : event.name) + " | " + (event?.eventStartDate | date : 'MM/dd/yyyy')}}
                </option>
            </select>
        </div>
    </div>
    <div class="footer-container">
        <button mat-button class="continue-btn" (click)="continue()" [disabled]="!selectedEvent">{{'BOOKINGCART.Continue' | optimotranslate}}</button>
    </div>
</div>
<!-- <div class="select-event-modal-wrapper">
    <div class="header-container">
            <div class="header-text-container">
                <h4>Select Event</h4>
            </div>
            <div class="close-btn">
                <mat-icon (click)="cancelClick()">close</mat-icon>
            </div>
    </div>
    <div class="select-event-modal-content">
        <select class="select-event-modal-selection" id="events" name="events" [(ngModel)]="selectedEvent"
            style="width: 100%;">
            <option value="" disabled selected hidden>Select Event</option>
            <option *ngFor="let events of bookedEvents" [value]="events.id">{{ events.venueName + " | " + events.name + " | "
                + events.eventStartDate}}</option>
        </select>
    </div>
    <div class="select-event-modal-footer">
        <button mat-button class="continue-btn" (click)="Continue()" [disabled]="!selectedEvent">Continue</button>
    </div>
</div> -->