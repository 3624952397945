<div class="container search-container mt-5">
  <div class="row search">

    <!------------------------IsEventSearch------------------------->
    <div *ngIf="layoutAttribute.searchPanelData.IsEventSearch">
      <div class="searchContainer">
        <form [formGroup]="searchFb">
          <input class="searchbox expandright" id="searchright" formControlName="eventName" type="search" name="q"
        placeholder="{{ searchOptionFromHomePage == SEARCH_OPTION.EVENT_SEARCH ? ('FILTER_INFO.Search' | uppercase | optimotranslate) : ('FILTER_INFO.Package Search' | uppercase | optimotranslate) }}" (keyup.enter)="customSearchKey()" >
          <button class="sbutton searchbutton" for="searchright" (click)="customSearch()">
            <mat-icon>search</mat-icon>
          </button>
        </form>
      </div>
    </div>
    <div class="search-filter" *ngIf="layoutAttribute.searchPanelData.IsFilter">
      <button class="filter-btn" *ngIf="isLoading">
        <div id="loader"></div>
      </button>
      <button class="filter-btn" *ngIf="!isLoading" (click)="openFilters()">
        <mat-icon>filter_alt</mat-icon>
      </button>
    </div>

  </div>
</div>

<div class="search-box-layout container" [id]="elementID" style="margin-top: 0.5rem;"
  [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'" *ngIf="layoutAttribute.searchPanelData.IsFilter">
  <div *ngIf="canLoad" class="container-fluid filter-container-second" style="padding-right: 0;">
    <form [formGroup]="searchFb">
      <div class="row first" >

        <!------------------------IsVenueSearch------------------------->
        <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsVenueSearch" class="filter-opt w3-animate-opacity"
           [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'">
          <div class="input-group with-icon">
            <mat-icon class="icon">add_location</mat-icon>
            <!-- <mat-icon class="arrow">arrow_drop_down</mat-icon> -->
            <input type="text" class="form-control rounded" placeholder="{{'FILTER_INFO.Venue' | uppercase | optimotranslate}}" matInput formControlName="venueName"
              name="Venue Name" [matAutocomplete]="auto" (focusout)="resetOnFalseVenue()" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSubmit()">
              <mat-option *ngFor="let v of filteredOptions | async " [value]="v.name"
                (selectionChange)="selectedVenue = v" (click)="selectedVenue = v">
                {{ v.name }}
              </mat-option>
              <mat-option *ngIf="isNoResultFound">{{'FILTER_INFO.No results found'| uppercase |optimotranslate}}</mat-option>
            </mat-autocomplete>
          </div>
        </div>

        <!------------------------IsPriceRange------------------------->
        <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsPriceRange" class="filter-opt" [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'">
          <div class="input-group with-icon" style="background-color: white;"
            style="padding-left: 12px; padding-bottom: 15px;padding-right: 12px;">
            <ngx-slider class="custom-slider-style" style="display: inline-block; margin-bottom: 10px;"
              id="ngx-price-range-slider" formControlName="eventByPriceRange" [(value)]="minValue"
              [(highValue)]="maxValue" [options]="options" (userChangeStart)="userChangeStart($event)"
              (userChange)="valueChanges()" (userChangeEnd)="userChangeEnd($event)">
            </ngx-slider>
          </div>
        </div>

        <!------------------------IsDate------------------------------->
        <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsDate" class="filter-opt" [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'">
          <div class="input-group with-icon">
            <input class="form-control rounded" type="text" (input)="onInputDateRangeText($event)"
              (focusout)="resetOnFalseDate($event)" [value]="selectedValue" ngxDaterangepickerMd
                [showCustomRangeLabel]="true" [locale]="{applyLabel:apply,customRangeLabel:customRange,monthNames:months,daysOfWeek:days}"
              [alwaysShowCalendars]="true" [ranges]="ranges" [autoApply]="false" [linkedCalendars]="true"
              [isInvalidDate]="isInvalidDate" [showClearButton]="false" [customRangeDirection]="true"
              [keepCalendarOpeningWithRange]="true" (datesUpdated)="choosedDateTime($event)" placeholder="{{'FILTER_INFO.Select Date' | uppercase | optimotranslate}}"
              autocomplete="off" id="dateRangePicker" title="{{selectedValue}}" />
            <mat-icon class="icon">calendar_month</mat-icon>
          </div>
        </div>

        <!------------------------IsDays-------------------------->
        <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsDays" class="filter-opt" [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'">
          <div class="input-group with-icon">
            <input class="form-control rounded" (change)="displayDateRange ? daysChange($event) : ''" type="number"
              min="1" matInput placeholder="{{'Days' | uppercase | optimotranslate}}" formControlName="days" (keyup)="onSubmit()" name="days"
              autocomplete="off" />
            <i class="fa fa-sort-numeric-desc"></i>
          </div>
        </div>

        <!------------------------IsAgeGroup------------------------->
        <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsAgeGroup" class="filter-opt" [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'">
          <div class="input-group with-icon" (scroll)="scrollHandler()" (click)="dropdownOpenBasedOnSpace()">
            <mat-icon class="icon">group_add</mat-icon>
            <ng-multiselect-dropdown class="custom-style" placeholder="{{'FILTER_INFO.Select Age Group' | uppercase | optimotranslate}}"
              [settings]="dropdownSettings" [data]="dropdownList"
              (onSelect)="onItemSelect($event)" formControlName="ageGroupId" [settings]="dropdownSettings"
               (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="selectallItems($event)"
              (onDeSelectAll)="unselectallItems($event)">
            </ng-multiselect-dropdown>

          </div>
        </div>

        <!------------------------IsEventGroup------------------------>
        <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsEventGroup" class="filter-opt event-opt" [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'">
          <div class="input-group with-icon">
            <!-- <mat-icon class="arrow">arrow_drop_down</mat-icon> -->
            <input class="form-control rounded" matInput formControlName="eventGroup" placeholder="{{'FILTER_INFO.Event Group' | uppercase |optimotranslate}}"
              name="Event Group" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSubmit()">
              <div *ngIf="isFilter">
                <mat-option *ngFor="let v of eventGropsFilteredOptions | async " [value]="v.name"
                  (selectionChange)="selectedEventGroup = v" (click)="selectedEventGroup = v">
                  {{v.name }}
                </mat-option>
              </div>
              <mat-option *ngIf="isNoEventGropResultFound">{{'FILTER_INFO.No results found'| uppercase |optimotranslate}}</mat-option>
            </mat-autocomplete>
            <!-- <i class="fa fa-users"></i> -->
            <mat-icon class="icon">supervisor_account</mat-icon>
          </div>
        </div>

        <!------------------------IsBusinessAreaSearch------------------------>
        <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsBusinessArea" class="filter-opt" [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'">
          <div class="input-group with-icon">
            <!-- <mat-icon class="arrow">arrow_drop_down</mat-icon> -->
            <input class="form-control rounded" formControlName="businessArea" placeholder="{{'FILTER_INFO.Business Area' | uppercase | optimotranslate }}"
              name="businessArea" autocomplete="off" matInput [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSubmit()">
              <mat-option *ngFor="let v of filteredOptionsBusiArea | async " [value]="v.name"
                (selectionChange)="selectedBusinessArea = v" (click)="selectedBusinessArea = v">
                {{ v.name }}
              </mat-option>
              <mat-option *ngIf="isNoResultFoundBusiArea">{{'FILTER_INFO.No results found' | uppercase |optimotranslate}}</mat-option>
            </mat-autocomplete>
            <mat-icon class=" icon material-symbols-outlined">cases</mat-icon>
          </div>
        </div>

        <!------------------------IsEventSalesCategory------------------------->
        <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsSalesCategory" class="filter-opt" [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'">
          <div class="input-group with-icon">
            <!-- <mat-icon class="arrow">arrow_drop_down</mat-icon> -->
            <input class="form-control rounded" matInput formControlName="eventSalesCategory"
              placeholder="{{'FILTER_INFO.Event Sales Category' | uppercase |optimotranslate}}" name="Event Sales Category" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSubmit()">
              <mat-option *ngFor="let v of eventSalesCategoryFilteredOptions | async " [value]="v.name"
                (selectionChange)="selectedEventSalesCategory = v" (click)="selectedEventSalesCategory = v">
                {{v.name}}
              </mat-option>
              <mat-option *ngIf="isNoEventSalesCategoryResultFound">{{'FILTER_INFO.No results found'| uppercase |optimotranslate}}</mat-option>
            </mat-autocomplete>
            <mat-icon class="icon">fact_check</mat-icon>
          </div>
        </div>

        <div [class]="classValue+' p-0'"  class="filter-opt" [ngClass]="isFilter ? 'filter-option-open' : 'filter-option-closed'">
          <button *ngIf="IS_FORM_HAS_VALUES"  class="btn btn-block btn-primary searchBoxClear"
          style="height: 38px !important;
                    width: 100% !important;
                    background-color: rgb(75 179 250);
                    padding-left: 5px;
                    padding-right: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    border: none;
                    margin-top: 10px;
                    border-radius: 3px !important;
                  " type="button" (click)="clearFields()">
          <mat-icon style="color: #ffffff;" >delete</mat-icon>{{'FILTER_INFO.Clear Filters'| uppercase |optimotranslate}}
        </button>
        </div>

        <ng-template #elseTemplate>
          <div class="row">
            <div class="col-3 searchBoxClearCol">
              <button class="btn btn-block btn-primary p-0 searchBoxClear" style="
                      height: 36px !important;
                      min-width: 100%;
                      background-color: white !important;
                      border-color: #ab2020;
                      padding-left: 5px;
                      padding-right: 5px;
                    " type="button" (click)="clearFields()">
                <mat-icon style="color: #ab2020; margin-bottom: 5px;">delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-template>

      </div>
    </form>
  </div>
  <div class="loaderPanel" *ngIf="isLoading && isFilter">
    <div id="filterLoader"></div>
  </div>
</div>


<!-- Custom Date Picker -->
<ng-template #template style="overflow: scroll; display: flex; flex-direction: column;">
  <mat-card class="date-range-picker-card-template" style="width: max-content; padding: 17px 5px 0 5px;">
    <mat-card-content>
      <ngx-daterangepicker-material (datesUpdated)="choosedDateTime($event)" [(ngModel)]="selected"
        [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
        [showClearButton]="false" [customRangeDirection]="true" [keepCalendarOpeningWithRange]="true">
      </ngx-daterangepicker-material>
    </mat-card-content>
  </mat-card>
</ng-template>
