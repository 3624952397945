import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Package } from 'src/modules/models/public-web/Package';
export enum NUMBER_OUTPUT {
  increase,
  decrease
}
@Component({
  selector: 'opt-pw-number-input',
  templateUrl: './pw-number-input.component.html',
  styleUrls: ['./pw-number-input.scss']
})
export class PwNumberInputComponent implements OnInit {
  @ViewChild('tooltip') tooltip: MatTooltip;

  value;
  freezedValue;
  maxValue: number = 9999;
  minValue: number = 0;
  @Input("value") set setValue(v) {
    if (v) {
      if (!this.value && !this.freezedValue)
        this.freezedValue = v;
    }
    this.value = v;
  };
  @Input() height: string = '100%'
  @Input() isCart: boolean = false;
  @Input() fontSize: string = '16px';
  @Input() styleClass: any;
  @Output() valueChange = new EventEmitter();
  @Output() increase = new EventEmitter();
  @Output() decrease = new EventEmitter();
  @Input() canValueChange = true;
  @Input() disableReduceBtnD = false;
  @Input() disableIncreaseBtnD = false;
  @Input() reduceButtonTooltip = "";
  @Input() package: Package;
  @Input() disabled = false;
  @Input() disableMinus = true;
  @Input() disablePlus = false;
  @Input("min")  set  setMinValue(v){
    if(v) {
      this.minValue = v
    }
  }
  @Input("max") set setMaxValue(v) {
    if(v) {
      this.maxValue = v
    }
  }
  @HostListener('input', [ '$event' ])
  public onInput(a_Event: InputEvent): void {
    let val = parseInt(this.value);
    if(this.maxValue !== null && this.maxValue !== undefined  && val >= this.maxValue) {
      this.value = this.maxValue;
    } else if (this.minValue !== null && this.minValue !== undefined  && val <= this.minValue) {
      this.value = this.minValue;
    }
    if (this.value) {
      this.valueChange.emit(this.value)
    }
  }
  constructor() { }

  ngOnInit() {
  }
  // getMaxCount() {
  //   if(!!this.package) {
  //     this.package
  //   }
  // }
  onDecrease() {
    if (this.value > this.minValue) {
      if (!(this.disableReduceBtnD && this.freezedValue == this.value) && this.canValueChange) {
        this.value--;
      } else if(this.disableReduceBtnD && this.freezedValue == this.value){
        this.tooltip.show()
      }
      this.valueChange.emit(this.value)
      this.decrease.emit(this.value)
    }
  }
  inputChange(event) {
    // this.value = event.target.value;
    // this.valueChange.emit(this.value)
  }
  onValueChange() {
    this.valueChange.emit(this.value)
  }
  get disableTooltip() {
    return !this.disableReduceBtnD || (this.freezedValue != this.value)
  }
  get reduceBtnStyle() {
    return (this.disableReduceBtnD && this.freezedValue == this.value) || (this.disableMinus && this.value <= this.minValue)
  }
  get increaseBtnStyle() {
    return (this.value==9999)
  }
  onIncrease() {
    if (this.canValueChange) {
        this.value++;
      
    }
    this.valueChange.emit(this.value)
    this.increase.emit(this.value)
  }
  onKeyup(event){
    if((this.value==0 && this.freezedValue) || ((parseInt(this.value) < this.freezedValue) && this.disableReduceBtnD)){
      this.value = this.freezedValue;
      this.valueChange.emit(this.value)
    }
  }
}
