import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BaseParam,
  SearchBaseFilter,
} from "src/modules/models/public-web/filter";
import { PackageSession } from "src/modules/models/public-web/PackageSession";
import { PublicPackageService } from "src/modules/services/public-web/public-package.service";
import { Store } from "src/modules/store";
import {
  IDateAndSession,
  IPBReducer,
  IPublicPackage,
} from "../../../../../store/public-web/public-web-reducers";
import { groupBy, mergeMap, reduce, toArray } from "rxjs/operators";
import { from, Observable } from "rxjs";
import { BaseComponent } from "src/modules/shared/base.component";
import { Utility } from "src/modules/utility";
import moment from "moment";
import { addHours, startOfDay } from "date-fns";
import {
  AddPackage,
  PatchBooking,
  SelectPackageSessions,
  SetRedirectionPoint,
} from "../../../../../store/public-web/public-web-actions";

import { SESSION_SELECTION_MODEL_TYPE } from "../../models/enums";
import { Item } from "src/modules/models/public-web/item/item";
import * as _ from "lodash";
import { AlertMessageComponent } from "src/modules/shared/alert-message/alert-message.component";
import { Pager } from "../../models/pager.model";
import { PublicEventService } from "../../../../../services/public-web/public-event.service";
import { NUMBER_OUTPUT } from "src/modules/shared/pw-number-input/pw-number-input.component";
import { MatAccordion } from "@angular/material/expansion";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PageEvent } from "@angular/material/paginator";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';
@Component({
  selector: "opt-session-modal",
  templateUrl: "./session-modal.component.html",
  styleUrls: ["./pw-session-modal.scss"],
})
export class SessionModalComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  date = new Date("2021-05-2");
  PBReducer$: any;
  session: IDateAndSession;
  eventID: number;
  searchFilter: SearchBaseFilter;
  booking: any;
  packageSession: any[];
  eventsByDate: any;
  multiSession: any;
  calenderEvents: any;
  publicCart$: Observable<IPublicPackage[]>;
  packageID;
  sessionItems: any;
  canLoad: boolean;
  packageQuantity: number;
  publicPackage: IPublicPackage;
  type: SESSION_SELECTION_MODEL_TYPE;
  upsellItem: Item;
  selectedSessions: IDateAndSession[];
  selectSpecifigSession;
  selectAllSession;
  pager: Pager = new Pager();
  sessionPerPage;
  arrowClass = "fa fa-arrow-down";
  minPrice;
  minimumSessionPrice = 0;
  totalAmountToDisplay = 0;
  totalAmountAllToDisplay = 0;
  TotalvalueArray = [];
  itemTotalAmount = 0;
  publicPackageLength = 1;
  filteredAttendees = 0;
  modalTitle: string;
  UpsellModalTitle: string;
  get NUMBER_OUTPUT() {
    return NUMBER_OUTPUT;
  }
  get SESSION_SELECTION_MODEL_TYPE() {
    return SESSION_SELECTION_MODEL_TYPE;
  }

  constructor(
    public dialogRef: MatDialogRef<SessionModalComponent>,
    private store: Store<any>,
    private publicPackageService: PublicPackageService,
    private eventService: PublicEventService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    dialogRef.disableClose = true;
    this.PBReducer$ = this.store.select(PBSelector.selectSessionModalState);
    this.publicCart$ = this.store.select(PBSelector.selectedPublicPackageCarts);
    this.searchFilter = new SearchBaseFilter();
    this.packageID = data.packageID;
    this.type = data.type;
    this.upsellItem = data.upsellItem;
    this.session = _.cloneDeep(data.selectedSessionTime);
    this.canLoad = false;
    this.selectAllSession = true;
    this.selectSpecifigSession = false;
    this.packageQuantity = 0;
    this.sessionPerPage = 5;
    if (this.packageID) {
      this.searchFilter.packageIDs.push(this.packageID);
    }
    this.calenderEvents = [];
    this.eventsByDate = [];
    this.multiSession = {
      startDate: undefined,
      endDate: undefined,
      minimumPrice: 0,
      availability: 0,
    };
  }

  ngAfterViewInit(): void {
    this.getSessionData();
  }
  ngOnInit() {
    let getEventName = true;
    this.publicCart$.safeSubscribe(this, (cart) => {
      const p = _.cloneDeep(cart);
      if (this.packageID) {
        this.publicPackage = p.find((x) => x.id === this.packageID.toString());
      }
    });
    this.PBReducer$.safeSubscribe(this, (d: IPBReducer) => {
      if (this.publicPackage.linkPackages) {
        this.selectedSessions = _.cloneDeep(d.selectedSessions);
        this.filteredAttendees = d.packageAvailability && d.packageAvailability;
        if (this.type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION)
          this.booking = d.booking;
        let startDate = moment(this.session.startTime).toDate();
        let endDate = moment(this.session.endTime).toDate();
        this.searchFilter.sessionFromDate = Utility.convertToISO(
          Utility.convertISOToDate(startDate)
        );
        this.searchFilter.sessionToDate = Utility.convertToISO(
          Utility.convertISOToDate(endDate)
        );
        this.eventID = +d.eventId;
        if (!!this.eventID && getEventName) {
          this.eventService.selectEvent(this.eventID, true).safeSubscribe(this, (event) => {
              this.modalTitle = event.name;
              getEventName = false;
            });
        }
      }
    });
    if (!!this.publicPackage && !!this.publicPackage.linkPackages)
      this.publicPackage.linkPackages.reverse();
  }
  sessionChoices(type, ac = undefined) {
    switch (type) {
      case "Multi":
        this.selectSpecifigSession = false;
        this.selectAllSession = true;
        this.totalAmountToDisplay = 0;
        if (ac) ac.closeAll();
        this.eventsByDate.forEach((e) => {
          e.sessions.forEach((s) => {
            s.package.quantity = 0;
            s.package.itemQuantity = 0;
            if (s.package.isHaveLinkPackage && s.package.linkPackages) {
              s.package.linkPackages.forEach((pl) => {
                pl.quantity = 0;
                pl.itemQuantity = 0;
              });
            }
          });
        });
        break;
      case "Single":
        this.selectAllSession = false;
        this.selectSpecifigSession = true;
        this.totalAmountAllToDisplay = 0;
        this.publicPackage.quantity = 0;
        this.publicPackage.itemQuantity = 0;
        if (this.publicPackage.isHaveLinkPackage) {
          this.publicPackage.linkPackages.forEach((s) => {
            s.quantity = 0;
          });
        }
        break;
    }
  }

  getItemPrice() {
    return this.upsellItem.itemPriceGroups[0].priceIncludingTax;
  }
  linkedPackageMinPrice;
  linkedPackageMaxPrice;

  getMinMaxPrice(type) {
    let priceGroup = [];
    this.publicPackage.linkPackages.forEach((lp) => {
      priceGroup.push(lp.variablePriceIncludingTax);
    });
    this.linkedPackageMaxPrice = Math.max.apply(null, priceGroup);
    this.linkedPackageMinPrice = Math.min.apply(null, priceGroup);
    if (type == "max") {
      return this.linkedPackageMaxPrice;
    }
    if (type == "min") {
      return this.linkedPackageMinPrice;
    }
  }

  getMinimumPriceOfLinkedPacakgeType(LinkedPackage) {
    let minimumPrice = [];
    this.publicPackage.linkPackages.forEach((lp) => {
      if (lp.linkedPackageType.name == LinkedPackage.linkedPackageType.name) {
        minimumPrice.push(lp.variablePriceIncludingTax);
      }
    });
    return Math.min.apply(null, minimumPrice);
  }

  getSessionData() {
    if (this.packageSession && this.packageSession.length > 0) {
      // this.filterPackageSessions(this.packageSession);
      // this.packageSession.reverse();
      this.mapSessionIntoCalenderEvent(this.packageSession);
      this.canLoad = true;
    } else {
      const params: BaseParam = new BaseParam();
      params.eventID = this.eventID;
      this.searchFilter.eventId = this.eventID;
      this.searchFilter.eventData = true;
      this.publicPackageService
        .getPublicPackages(params, this.searchFilter)
        .safeSubscribe(this, (data: any[]) => {
          this.packageSession = [];
          data.forEach((pack: any) => {
            // *filter only booked session for specific package
            if (
              this.type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION
            ) {
              pack.packageSessions = pack.packageSessions.filter((session) => {
                const sessionStart = Utility.convertToISO(
                  Utility.convertISOToDate(session.startTime)
                );
                const sessionEnd = Utility.convertToISO(
                  Utility.convertISOToDate(session.endTime)
                );
                const bookingPK = this.selectedSessions.find(
                  (bp) =>
                    !!bp.packages.find(
                      (p) => p.id === this.packageID.toString()
                    ) &&
                    Utility.convertToISO(
                      Utility.convertISOToDate(bp.startTime)
                    ) === sessionStart &&
                    Utility.convertToISO(
                      Utility.convertISOToDate(bp.endTime)
                    ) === sessionEnd
                );
                return bookingPK;
              });
            }
            this.packageSession.push(...pack.packageSessions);
          });
          // this.filterPackageSessions(this.packageSession);
          this.mapSessionIntoCalenderEvent(this.packageSession);
          if (this.filteredAttendees > 0) {
            this.onselectAllSession();
          }
          this.canLoad = true;
        });
    }
  }
  changeIcon(id) {
    let eid = "id" + id;
    let element = document.getElementById(eid);
    if (element.classList.contains("fa-arrow-down")) {
      element.classList.remove("fa-arrow-down");
      element.classList.add("fa-arrow-up");
    } else if (element.classList.contains("fa-arrow-up")) {
      element.classList.remove("fa-arrow-up");
      element.classList.add("fa-arrow-down");
    }
  }

  getTotalNights(start, end) {
    let startDate = moment(start);
    let endDate = moment(end);
    this.publicPackageLength = endDate.diff(startDate, "days") + 1;
  }
  onMultiSelectionChange(value, type: NUMBER_OUTPUT, pack) {
    let quantity = pack.quantity;
    switch (type) {
      case NUMBER_OUTPUT.increase:
        quantity++;
        break;
      case NUMBER_OUTPUT.decrease:
        quantity--;
        break;
    }
    const adultPackage = this.publicPackage.linkPackages.find(
      (x) => x.linkedPackageType.id === "1"
    );
    const selectPackageIndex = this.publicPackage.linkPackages.findIndex(
      (x) => x.id === pack.id
    );
    if (adultPackage && selectPackageIndex != -1) {
      const selectPack = this.publicPackage.linkPackages[selectPackageIndex];
      if (
        selectPack.linkedPackageType.id === "2" &&
        selectPack.linkedPackageType.maximumNumberOfChildren !== 0 &&
        selectPack.linkedPackageType.adultRequired
      ) {
        const maxChildCount =
          selectPack.linkedPackageType.maximumNumberOfChildren *
          adultPackage.quantity;
        if (quantity > maxChildCount) {
          this.publicPackage.linkPackages[selectPackageIndex].quantity =
            maxChildCount;
        } else {
          this.publicPackage.linkPackages[selectPackageIndex].quantity =
            quantity;
        }
      } else if (selectPack.id === adultPackage.id) {
        for (const key in this.publicPackage.linkPackages) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.publicPackage.linkPackages,
              key
            )
          ) {
            const element = this.publicPackage.linkPackages[key];
            if (
              element.linkedPackageType.id === "2" &&
              element.linkedPackageType.maximumNumberOfChildren !== 0 &&
              element.linkedPackageType.adultRequired
            ) {
              const mxCount =
                element.linkedPackageType.maximumNumberOfChildren * quantity;
              if (element.quantity > mxCount) {
                // Update Child Package Value
                this.publicPackage.linkPackages[key].quantity = mxCount;
              }
            }
          }
        }
        this.publicPackage.linkPackages[selectPackageIndex].quantity = quantity;
      } else {
        this.publicPackage.linkPackages[selectPackageIndex].quantity = quantity;
      }
    }
    this.onselectAllSession();
  }
  OnSingleSelectionChange(value, type: NUMBER_OUTPUT, session, pack) {
    let quantity = pack.quantity;
    switch (type) {
      case NUMBER_OUTPUT.increase:
        quantity++;
        break;
      case NUMBER_OUTPUT.decrease:
        quantity--;
        break;
    }
    switch (this.type) {
      case SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION:
        session.package.itemQuantity = quantity;
        break;
      case SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION:
        for (const key in this.sessionItems) {
          if (Object.prototype.hasOwnProperty.call(this.sessionItems, key)) {
            const sessionItem = this.sessionItems[key];
            const index = sessionItem.sessions.findIndex(
              (x) => x.id === session.id
            );
            if (index !== -1) {
              const linkedPkey = sessionItem.sessions[
                index
              ].package.linkPackages.findIndex((x) => x.id === pack.id);
              if (linkedPkey !== -1) {
                const adultPackage = _.cloneDeep(
                  sessionItem.sessions[index].package.linkPackages.find(
                    (x) => x.linkedPackageType.id === "1"
                  )
                );
                const linkedPackage =
                  sessionItem.sessions[index].package.linkPackages[linkedPkey];
                if (!!adultPackage) {
                  if (
                    linkedPackage.linkedPackageType.id === "2" &&
                    linkedPackage.linkedPackageType.maximumNumberOfChildren !==
                    0 &&
                    linkedPackage.linkedPackageType.adultRequired
                  ) {
                    const maxChildCount =
                      linkedPackage.linkedPackageType.maximumNumberOfChildren *
                      adultPackage.quantity;
                    if (quantity > maxChildCount) {
                      this.sessionItems[key].sessions[
                        index
                      ].package.linkPackages[linkedPkey].quantity =
                        maxChildCount;
                      session.package.linkPackages[linkedPkey].quantity =
                        maxChildCount;
                    } else {
                      // update selected package value
                      this.sessionItems[key].sessions[
                        index
                      ].package.linkPackages[linkedPkey].quantity = quantity;
                      session.package.linkPackages[linkedPkey].quantity =
                        quantity;
                    }
                  } else if (linkedPackage.id === adultPackage.id) {
                    for (const linkKey in this.sessionItems[key].sessions[index]
                      .package.linkPackages) {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          this.sessionItems[key].sessions[index].package
                            .linkPackages,
                          linkKey
                        )
                      ) {
                        const linkedPackage =
                          this.sessionItems[key].sessions[index].package
                            .linkPackages[linkKey];
                        if (
                          linkedPackage.linkedPackageType.id === "2" &&
                          linkedPackage.linkedPackageType
                            .maximumNumberOfChildren !== 0 &&
                          linkedPackage.linkedPackageType.adultRequired
                        ) {
                          const mxCount =
                            linkedPackage.linkedPackageType
                              .maximumNumberOfChildren * quantity;
                          if (linkedPackage.quantity > mxCount) {
                            // Update Child Package Value
                            this.sessionItems[key].sessions[
                              index
                            ].package.linkPackages[linkKey].quantity = mxCount;
                            session.package.linkPackages[linkKey].quantity =
                              mxCount;
                          }
                        }
                      }
                    }
                    // update selected package value
                    this.sessionItems[key].sessions[index].package.linkPackages[
                      linkedPkey
                    ].quantity = quantity;
                    session.package.linkPackages[linkedPkey].quantity =
                      quantity;
                  } else {
                    // update selected package value
                    this.sessionItems[key].sessions[index].package.linkPackages[
                      linkedPkey
                    ].quantity = quantity;
                    session.package.linkPackages[linkedPkey].quantity =
                      quantity;
                  }
                }
              }
            }
          }
        }
        break;
      default:
        break;
    }
    this.onselectSession(session);
  }
  pushToArray(obj) {
    let index = this.TotalvalueArray.findIndex((e) => e.id === obj.id);
    if (index === -1) {
      this.TotalvalueArray.push(obj);
    } else {
      this.TotalvalueArray[index] = obj;
    }
    this.totalAmountToDisplay = 0;
    if (this.type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION) {
      this.TotalvalueArray.forEach((e) => {
        let childTotal = e.Child * e.cPrice;
        let adultTotal = e.Adult * e.aPrice;
        this.totalAmountToDisplay =
          this.totalAmountToDisplay + childTotal + adultTotal;
      });
    }
    if (this.type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION) {
      this.TotalvalueArray.forEach((e) => {
        this.totalAmountToDisplay = this.totalAmountToDisplay + e.qty * e.price;
      });
    }
  }
  onselectSession(event) {
    this.sessionChoices("Single");
    this.selectAllSession = false;
    this.selectSpecifigSession = true;
    const sessionIndex = this.selectedSessions.findIndex(
      (s) => s.id === event.id.toString()
    );
    let session: IDateAndSession;
    if (sessionIndex !== -1) {
      session = this.selectedSessions[sessionIndex];
    } else {
      session = {
        id: event.id.toString(),
        startTime: event.session.startTime,
        endTime: event.session.endTime,
        availability: event.session.availability,
        packages: [],
      };
    }
    switch (this.type) {
      case SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION:
        this.pushToArray({
          id: event.id,
          Child: event.package.linkPackages[0].quantity,
          Adult: event.package.linkPackages[1].quantity,
          cPrice: event.package.linkPackages[0].variablePriceIncludingTax,
          aPrice: event.package.linkPackages[1].variablePriceIncludingTax,
        });

        const pkIndex = session.packages.findIndex(
          (p) => p.id === event.package.id
        );
        if (pkIndex != -1) {
          session.packages[pkIndex] = event.package;
          session.packages[pkIndex].isBooked = false;
        } else {
          event.package.isBooked = false;
          session.packages.push(event.package);
        }
        // this.store.dispatch(new UpdatePackage(this.publicPackage));
        break;
      case SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION:
        this.pushToArray({
          id: event.id,
          qty: event.package.itemQuantity,
          price: this.upsellItem.itemPriceGroups[0].priceIncludingTax,
        });
        let ui = _.cloneDeep(this.upsellItem);
        ui.qty = event.package.itemQuantity;
        if (event.package.itemQuantity > 0) {
          ui.isNeedToBeUpdate = true;
        } else {
          ui.isNeedToBeUpdate = false;
        }
        const pkIndexI = session.packages.findIndex(
          (i) => i.id === this.packageID.toString()
        );
        if (pkIndexI !== -1) {
          session.packages[pkIndexI].addOns =
            session.packages[pkIndexI].addOns === undefined
              ? []
              : session.packages[pkIndexI].addOns;
          let itemIndex = session.packages[pkIndexI].addOns.findIndex(
            (i) => i.id === ui.id
          );
          if (itemIndex !== -1) {
            session.packages[pkIndexI].addOns[itemIndex] = ui;
          } else session.packages[pkIndexI].addOns.push(ui);
        } else {
          this.openSnackBarError(["package is not selected for session"]);
        }
        break;
      default:
        break;
    }
    if (sessionIndex !== -1) {
      this.selectedSessions[sessionIndex] = session;
    } else {
      this.selectedSessions.push(session);
    }
  }

  private openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: "top",
    });
  }
  calculatePrice() {
    let AdultTotal =
      this.publicPackage.linkPackages[1].quantity *
      this.publicPackage.linkPackages[1].variablePriceIncludingTax *
      this.publicPackageLength;
    let ChildTotal =
      this.publicPackage.linkPackages[0].quantity *
      this.publicPackage.linkPackages[0].variablePriceIncludingTax *
      this.publicPackageLength;
    this.totalAmountAllToDisplay = AdultTotal + ChildTotal;
  }

  onselectAllSession() {
    this.calculatePrice();
    this.sessionChoices("Multi");
    this.selectAllSession = true;
    this.selectSpecifigSession = false;
    this.packageSession.forEach((pk) => {
      const sessionIndex = this.selectedSessions.findIndex(
        (s) => s.id === pk.id.toString()
      );
      let session: IDateAndSession;
      if (sessionIndex !== -1) {
        session = this.selectedSessions[sessionIndex];
      } else {
        session = {
          packages: [],
          id: pk.id.toString(),
          startTime: pk.startTime,
          endTime: pk.endTime,
          availability: pk.session.availability,
        };
      }
      switch (this.type) {
        case SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION:
          const pk = _.cloneDeep(this.publicPackage);
          const pkIndex = session.packages.findIndex((p) => p.id === pk.id);
          if (pkIndex !== -1) {
            session.packages[pkIndex] = pk;
            if (session.packages[pkIndex].quantity > 0) {
              session.packages[pkIndex].isBooked = false;
            } else {
              session.packages[pkIndex].isBooked = true;
            }
          } else {
            if (pk.quantity > 0) {
              pk.isBooked = false;
            } else {
              pk.isBooked = true;
            }
            session.packages.push(_.cloneDeep(pk));
          }
          break;
        case SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION:
          const ui = _.cloneDeep(this.upsellItem);
          ui.qty = _.cloneDeep(this.publicPackage.itemQuantity);
          ui.isNeedToBeUpdate = true;
          if (this.publicPackage.itemQuantity > 0) {
            ui.isNeedToBeUpdate = true;
          } else {
            ui.isNeedToBeUpdate = false;
          }
          const pkIndexI = session.packages.findIndex(
            (i) => i.id === this.packageID.toString()
          );
          if (pkIndexI !== -1) {
            session.packages[pkIndexI].addOns =
              session.packages[pkIndexI].addOns === undefined
                ? []
                : session.packages[pkIndexI].addOns;
            const itemIndex = session.packages[pkIndexI].addOns.findIndex(
              (i) => i.id === ui.id
            );
            if (itemIndex !== -1) {
              session.packages[pkIndexI].addOns[itemIndex] = _.cloneDeep(ui);
            } else {
              session.packages[pkIndexI].addOns.push(_.cloneDeep(ui));
            }
          } else {
            this.openSnackBarError(["package is not selected for session"]);
          }
          break;
        default:
          break;
      }
      if (sessionIndex !== -1) {
        this.selectedSessions[sessionIndex] = session;
      } else {
        this.selectedSessions.push(session);
      }
    });
    // this.store.dispatch(new SelectPackageSessions(sessions));
  }

  addPackageIntoBooking() {
    const filteredSession = [];
    this.selectedSessions.forEach((s) => {
      let canAdd = false;
      for (const p of s.packages) {
        if (p.isHaveLinkPackage && p.linkPackages.length > 0) {
          for (const lp of p.linkPackages) {
            if (lp.quantity > 0) {
              canAdd = true;
              lp.isBooked = false;
              p.isBooked = false;
            } else {
              lp.isBooked = true;
            }
          }
        } else if (p.quantity > 0) {
          p.isBooked = false;
          canAdd = true;
          break;
        } else {
          if (p.addOns && p.addOns.length > 0) {
            for (const a of p.addOns) {
              if (a.qty > 0) {
                canAdd = true;
                break;
              }
            }
          }
        }
      }
      if (canAdd) {
        filteredSession.push(s);
      }
    });
    this.store.dispatch(new SelectPackageSessions(filteredSession));
    switch (this.type) {
      case SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION:
        this.store.dispatch(new AddPackage(this.packageID));
        break;
      case SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION:
        this.store.dispatch(new PatchBooking());
        this.store.dispatch(new SetRedirectionPoint(" "));
        break;
      default:
        break;
    }
    this.dialogRef.close();
  }

  mapSessionIntoCalenderEvent(sessions: PackageSession[]) {
    this.calenderEvents = [];
    this.eventsByDate = [];
    if (
      this.publicPackage.isHaveLinkPackage &&
      this.publicPackage.linkPackages.length > 0
    ) {
      const i = this.publicPackage.linkPackages.findIndex(
        (x) => x.linkedPackageType.id === "1"
      );
      if (i !== -1) {
        this.publicPackage.linkPackages[i].quantity = this.filteredAttendees;
      }
    } else {
      this.publicPackage.quantity = this.filteredAttendees;
    }
    sessions.forEach((session) => {
      //#region create multi selection model
      if (!this.multiSession.startDate) {
        this.multiSession.startDate = new Date(session.startTime);
      } else {
        let oldStartMin = this.multiSession.startDate.getTime();
        let curStartMin = new Date(session.startTime).getTime();
        if (oldStartMin > curStartMin) {
          this.multiSession.startDate = new Date(session.startTime);
        }
      }
      if (!this.multiSession.endDate) {
        this.multiSession.endDate = new Date(session.endTime);
      } else {
        let oldEndMin = this.multiSession.endDate.getTime();
        let curEndMin = new Date(session.endTime).getTime();
        if (oldEndMin < curEndMin) {
          this.multiSession.endDate = new Date(session.endTime);
        }
      }
      if (this.multiSession.minimumPrice === 0) {
        this.multiSession.minimumPrice = session.totalPriceIncludingTax;
      } else if (
        this.multiSession.minimumPrice > session.totalPriceIncludingTax
      ) {
        this.multiSession.minimumPrice = session.totalPriceIncludingTax;
      }
      if (this.multiSession.availability === 0) {
        this.multiSession.availability = session.availability;
      } else if (this.multiSession.availability > session.availability) {
        this.multiSession.availability = session.availability;
      }
      //#endregion

      const pk = this.mapPackageforSession(
        this.publicPackage,
        session.startTime,
        session.endTime
      );
      const event = {
        id: session.id,
        start: addHours(startOfDay(new Date(session.startTime)), 2),
        end: addHours(startOfDay(new Date(session.endTime)), 2),
        package: pk,
        isSessionSelected: false,
        session: session,
      };
      if (this.type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION) {
        let session = _.cloneDeep(
          this.selectedSessions.find((s) => s.id === event.id)
        );
        let pk = _.cloneDeep(
          session.packages.find((p) => p.id === this.publicPackage.id)
        );
        if (!!session && !!pk) event.package = pk;
      }
      this.calenderEvents.push(event);
      this.getTotalNights(
        this.multiSession.startDate,
        this.multiSession.endDate
      );

      this.publicPackage.linkPackages.forEach((lp) => {
        if (lp.variablePriceIncludingTax < this.multiSession.minimumPrice)
          this.multiSession.minimumPrice = lp.variablePriceIncludingTax;
      });
      console.log(this.multiSession.minimumPrice);
    });

    this.calenderEvents.forEach((cEvent) => {
      let d = new Date(cEvent.start);
      let index = this.eventsByDate.findIndex(
        (e) => e.date.getTime() === d.getTime()
      );
      if (index != -1) {
        this.eventsByDate[index].calendarEvents.push(cEvent);
        this.eventsByDate[index].availability += cEvent.session.availability;
      } else {
        const event: any = {
          date: d,
          sessions: [cEvent],
          availability: cEvent.session.availability,
          startTime: null,
          endTime: null,
        };
        this.eventsByDate.push(event);
      }
    });
    this.setPage(1);
    document.getElementById("session-modal-popup").style.display = "block";
  }
  mapPackageforSession(p: IPublicPackage, start, end) {
    const pk = _.cloneDeep(p);
    const sessionStart = Utility.convertToISO(Utility.convertISOToDate(start));
    const sessionEnd = Utility.convertToISO(Utility.convertISOToDate(end));
    let sessionIndex = this.selectedSessions.findIndex(
      (x) =>
        !!x.packages.find((y) => y.id === pk.id) &&
        sessionStart ===
        Utility.convertToISO(Utility.convertISOToDate(x.startTime)) &&
        sessionEnd === Utility.convertToISO(Utility.convertISOToDate(x.endTime))
    );
    if (sessionIndex !== -1) {
      const packageIndex = this.selectedSessions[
        sessionIndex
      ].packages.findIndex((x) => x.id === pk.id);
      if (packageIndex !== -1) {
        if (!!this.upsellItem && this.booking) {
          if (this.selectedSessions[sessionIndex].bookingPackages) {
            const bpID = this.selectedSessions[
              sessionIndex
            ].bookingPackages.find((x) => x.packageId === pk.id);
            if (!!bpID) {
              const bookingPack = this.booking.bookingPackages.find(
                (bp) => bp.id === bpID.id
              );
              if (bookingPack) {
                this.UpsellModalTitle = bookingPack.name;
                const bookedItem = bookingPack.bookingItems.find(
                  (bi) => bi.item && bi.item.id === this.upsellItem.id
                );
                if (bookedItem) pk.itemQuantity = bookedItem.quantity;
              }
            }
          }
        }
        if (pk.isHaveLinkPackage && pk.linkPackages.length > 0) {
          pk.linkPackages =
            this.selectedSessions[sessionIndex].packages[
              packageIndex
            ].linkPackages;
        } else {
          pk.quantity =
            +this.selectedSessions[sessionIndex].packages[packageIndex]
              .quantity;
        }
      }
    } else if (this.filteredAttendees > 0) {
      if (pk.isHaveLinkPackage && pk.linkPackages.length > 0) {
        const i = pk.linkPackages.findIndex(
          (x) => x.linkedPackageType.id === "1"
        );
        if (i !== -1) {
          pk.linkPackages[i].quantity = +this.filteredAttendees;
        }
      } else {
        pk.quantity = +this.filteredAttendees;
      }
    }
    return pk;
  }
  setPage(page: number) {
    this.eventsByDate.sort(function (a, b) {
      return a.date - b.date;
    });

    this.pager = this.getPager(
      this.eventsByDate.length,
      page,
      this.sessionPerPage
    );
    if (this.pager.endPage < page) {
      this.setPage(this.pager.endPage);
    }
    this.sessionItems = this.eventsByDate.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
  }

  onPageChange(p: PageEvent) {
    console.log(p);
    this.setPage(p.pageIndex + 1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 3) {
    const totalPages = Math.ceil(totalItems / pageSize);
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );
    const pager: Pager = {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
    return pager;
  }

  getUpsellTotal(e) {
    console.log(e);
  }
}
