import { jsonIgnore } from "json-ignore";
import { BaseResource } from "../base-resource";
import { Address, Client, CommunicationMethod } from "../client/client";
import { Contact } from "../client/contact";
import { BookingPackage } from "../regular-portal/booking/booking-package";
import { DocumentTemplate } from "../regular-portal/booking/save-booking/booking-email-data-document";
import { Booking } from "./booking";
import { BookingPackages } from "./booking-packages";
import { ATTENDEE_CAPTURE_TYPE } from "../public-web/enum";

export class Host extends BaseResource {
  constructor() {
    super();
    this.type = "Host";
  }
  hostReference: string;
  booking: Booking;
  bookingPackage: BookingPackages;
  titleId: number | null;
  titleName: string;
  firstName: string;
  lastName: string;
  displayName: string;
  companyName: string;
  eventDate: Date;
  attendees: number | null; /* No of Guests */
  cateringNotes: string;
  namedHost: boolean | null;
  specialInstruction: string;
  packageDate: any;
  client: Client;
  contact: Contact;
  guests: Attendee[];
  address: Address;
  communicationMethods: CommunicationMethod[];
}

export class AttendeeDisplay {
  id:string;
  bookingPackage: BookingPackages;
  hosts: Host[];
  remainingGuestsCount: number = 0;
  attendeeCaptureType : ATTENDEE_CAPTURE_TYPE;
}
export class Attendee extends BaseResource {
  constructor() {
    super();
    this.type = "Attendee";
  }
  titleID: number | null;
  title: Title;
  titleName: string;
  firstName: string;
  lastName: string;
  companyName: string;
  displayName: string;
  note: string;
  booking: Booking;
  specialInstruction: string;
  attendees: number | null; /* No of Guests */
  cateringNotes: string | null;
  bookingPackage: BookingPackage;
  hostGuest: boolean | null;
  namedHostGuest: boolean | null;
  auditInfo: AuditInfo;
  attendeeCommunicationMethods: CommunicationMethod[];
  primaryHostGuestId: string;
  @jsonIgnore() isSelectedForDelete:boolean;

}
export class Title {
  id: string
  name: string;
}
export interface AttendeeCaptureProfile {
  Id: string;
  name: string;
  attendeeCaptureProfileFields: AttendeeCaptureProfileField[];
  captureHost: boolean | null;
  captureGuest: boolean | null;
  guestLabel: string;
  guestLabelPlural: string;
  promptAtBooking: boolean | null;
  promptAtCheckout: boolean | null;
  promptAfterBooking: boolean | null;
  cutOffDays: number | null;
  formStyle: number | null;
  emailTemplate: DocumentTemplate;
}
export interface AttendeeCaptureProfileField {
  Id: string;
  label: string;
  displayName: string;
  internalName: string;
  mandatory: boolean;
  fieldTypeID: string;
  fieldType: string;
  maxLength: number;
  selectionOptions: Array<{ id: string, name: string }>;
}

export interface AuditInfo { 
  insertedUser: string;
  insertedTime: string;
  updatedUser: string;
  updatedTime: string;
}
