// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    IsInternalUser: false,
    ApiUrl: '',
    WebApiUrl: '',
    NotificationProjectUrl: '',
    Token: '',
    LanguageCode: '',
    Theme: '',
    RegularBookingPortalUrl: '',
    CasualBookingPortalUrl: '',
    CBClientSearchUrl: '',
    CBClientAddUrl: '',
    PaymentPortalUrl: '',
    RegularPortalInternalUrl: '',
    CasualPortalInternalUrl: '',
    OptimoHomeUrl: '',
    LoggedInUser: '',
    WasteBookingPortalUrl: '',
    WasteBookingInternalPortalUrl: '',
    WasteBookingClientSearchUrl: '',
    WasteBookingClientAddUrl: '',
    WasteBookingCashPaymentUrl: '',
    AppSettingsLoaded: false,
    RegularBookingSettingLoaded: false,
    WasteBookingSettingLoaded: false,
    PublicBookingSettingLoaded: false,
    PublicPortalHomeUrl: '',
    ConfigurationLoaded: false,
    publishFolder: 'dist',
    SalesChannel: 5,
    Currency: '$',
    DateFormat: 'dd MMM yyyy',
    TimeFormat: 'h:mm a',
    DateTimeFormat: 'dd MMM yyyy h:mm:ss a',
    PriceDisplayOption: 0, //1:min and max price, 2: defined price concession(PriceMinConsessionId,PriceMaxConsessionId)
    PriceMinConsessionId: 0,
    PriceMaxConsessionId: 0,
    DefaultBussinessAreaId: 0,
    DefaultPackageClassId: 0,
    PriceTypeId: 0,
    DefaultCurrencyId: 0,
    //PackageEntityConfigurationProfileId: '1',
    //BookingEntityConfigurationProfileId: '2',
    //ItemEntityConfigurationProfileId: '3',
    GoogleAPIKEY: "",
    BookingStatus: "",
    BookingApproveStatus: "",
    BookingExpireStatus: "",
    PackageClasses: [],
    MinimumBookingDuration: 0,
    BookingSearchDescriptionTextLimitHigh: 140,
    BookingSearchDescriptionTextLimitMedium: 80,
    BookingSearchDescriptionTextLimitLow: 40,
    IsDisableInputsInPayment: true,
    AllowToDoManualInvoiceSearch: true,
    // PrivacyPolicyLink: 'https://www.manningham.vic.gov.au/privacy',
    //TermsandConditionLink: '',
    ReCaptchaSiteKey: '',
    LatestFutureBookingDate: '',
    LatestFutureBookingDateErrorMessage: '',
    AllowToDoBookingForNonOperationalHours: false,
    BookingTermAndConditionMessage: '',
    CommunityClassAlertMessage: '',
    //HalfDayFrom: 0,
    //HalfDayTo: 0,
    //FullDayFrom: 0,
    //FullDayTo: 0,
    //SessionFrom: 0,
    AutomaticallyPickItemBasedOnFacility: false,
    IsBookingSearchByClient: false,
    IsGroupByFacilityNameInBookingView: false,
    SessionTo: 0,
    BookingStates: [],
    SortOptions: [],
    Messages: {
        BookingDetailsPage: "",
        BookingCartPageTopNavPannel: ""
    },
    IsAllowMultiplePriceConsession: false,
    LeadDaysForBooking: 0,
    DisableDatePickerForLeadDays: false,
    BookingLeadDayValidationMessage: '',
    IsShowSetupTimeInCalendar: false,
    CommunicationTypeValidation: [],
    // AgreedText: "",
    DefaultCountryId: "",
    DefaultCountryName: "",
    InsufficientSelectedTime: "",
    ShowMapView: {
        IsDefault: false,
        ShowMapView: false
    },
    ImmediateConfirmation: {
        Allow: true,
        DefaultPriceConsessionId: 0
    },
    ExternalIntegrationIdForPaymentGateway: "",
    ConfigurationSelection: {
        Enabled: false,
        DisplayMode: 0,
        DisplayBumpInOutNotification: false,
    },
    CheckAvailability: {
        EnableAutoAvailabilityCheck: false,
        EnableTimeLineAdvancedMode: false,
        TimeLineColors: [],
        DisplayPreliminarySlotAsUnavailble: false
    },
    ForgetPasswordPlaceHolder: "",
    ForgetPasswordMessage: "",
    ForgotPasswordLink: "",
    AppSetting: {},

    PriceConcessions: [],
    PriceConcessionQuestionText: "",
    PriceConcessionAnswerOptions: [],
    EnableProfileImageUpload: true,
    EnableWebPortalAccess: true,
    AutoPopulateUsernameFromEmail: true,
    DisableUsernameTextBox: true,

    RegularBookingSetting: {},
    PortalType: "",
    IsEnableToCreateAdditionalDetailsSectionForIndividualClients: false,
    IsEnableToCreateAdditionalDetailsSectionForCorporateClients: false,
    IsEnableToUpdateAdditionalDetailsSectionForIndividualClients: false,
    IsEnableToUpdateAdditionalDetailsSectionForCorporateClients: false,
    IsEnableToCreateAdditionalDocumentSectionForIndividualClients: false,
    IsEnableToCreateAdditionalDocumentSectionForCorporateClients: false,
    IsEnableToUpdateAdditionalDocumentSectionForIndividualClients: false,
    IsEnableToUpdateAdditionalDocumentSectionForCorporateClients: false,
    IsPriceConcessionQuestionRequired: false,
    SubmitEnquiryMessage: "",
    RequestToAccessReqularBookingHeaderText: "",
    RequestToAccessReqularBookingAcceptText: "",
    ReqularBookingAccessRequestHeaderText: "",
    ReqularBookingAccessRequestSubHeaderText: "",
    ReqularBookingAccessRequestReviewText: "",
    ReqularBookingAccessRequestCompleteText: "",
    ProfileImageHeaderTextForIndividualClients: "",
    ProfileImageHeaderTextForCorporateClients: "",
    FacilityFilters: [],
    ExternalIntegrationIdForAddress: 0,
    BookingExpiryInMinutes: 0,
    BookingRestrictions: [],
    messageDuration: 8000,
    addressCacheExpiryInHrs: 24,
    WasteBookingSetting: {},
    messageDurationInSeconds: 5000,
    IsEnableManualAddress: true,
    WMInitialBookingStatus: '',
    CorporateBookingUrl: '',
    PublicWebPortalUrl: "",
    PublicWebClientAddUrl: "",
    PublicWebClientSearchUrl: "",
    PublicBookingSetting: {},
    PortalLoginRedirectUrl: '',
    EnablePublicWebConcurrencySignalR: false,
    EnableCartDataLog: false,
    SystemOption: {
        SessionTimers:{
            bookingSession: {},
            globalSession: {},
            paymentSession: {}
        },
        taxLabel:"",
        defaultCallCenterContactID:"",
        defaultContactTitleID: 0,
        defaultInternetBookingStatus: 0,
        isShowPriceWithTax : false,
        isSecureInvoiceEnabled : false
    },
    UserAccessRights: {
        isAllowToChangeBookingStatus : false
    }
};
