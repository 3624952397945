import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { PriceBand } from "../models/CommonDTOs";
import { BaseService } from "src/modules/services/base/base.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PriceBandService {

  constructor(private base: BaseService) { }

  getPriceBands(): Observable<PriceBand[]> {
    const url = `/api/public/price-band`;
    return this.base.InternalWebAPIGET(url).pipe(map((response:any) => {
        return response.data?.map((p) => new PriceBand().initialize(p));
      })
    );
  }
}
