import { BaseResource } from "../base-resource";
import { Address, Client, CommunicationMethod } from "../client/client";
import { Contact } from "../client/contact";

export class Host extends BaseResource {
    constructor() {
      super();
      this.type = "Host";
    }
    hostReference: string;
    titleId: number | null;
    titleName: string;
    firstName: string;
    lastName: string;
    displayName: string;
    companyName: string;
    eventDate: Date;
    attendees: number | null; /* No of Guests */
    cateringNotes: string;
    namedHost: boolean | null;
    specialInstruction: string;
    packageDate: any;
    client: Client;
    contact: Contact;
    address: Address;
    communicationMethods: CommunicationMethod[];
}

export class Attendee extends BaseResource {
    constructor() {
      super();
      this.type = "Attendee";
    }
    titleID: number | null;
    title: Title;
    titleName: string;
    firstName: string;
    lastName: string;
    companyName: string;
    displayName: string;
    note: string;
    specialInstruction: string;
    attendees: number | null; /* No of Guests */
    cateringNotes: string | null;
    hostGuest: boolean | null;
    namedHostGuest: boolean | null;
    attendeeCommunicationMethods: CommunicationMethod[];
    primaryHostGuestId: string;
    host: Host;
  
  }
  export class Title {
    id: string
    name: string;
  }