import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  NgForm,
  PatternValidator,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import moment from "moment";
import { forkJoin, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import {
  Attendee,
  AttendeeCaptureProfile,
  AttendeeCaptureProfileField,
  Host,
  Title,
} from "src/modules/models/booking/Attendee";
import { CommunicationMethod } from "src/modules/models/client/client";
import { CommunicationType } from "src/modules/models/client/communication-type";
import { BookingQuestion } from "src/modules/models/regular-portal/booking/question/booking-question";
import {
  AnswerUploadedFile,
  FileUploadQuestion,
} from "src/modules/models/regular-portal/booking/question/question";
import { CommunicationTypeValidation } from "src/modules/models/settings/casual-portal/client-registration-config";
import { CustomerService } from "src/modules/services/customer.service";
import { PublicBookingService } from "src/modules/services/public-web/public-booking.service";
import { BaseComponent } from "src/modules/shared/base.component";
import { ICart, Store } from "src/modules/store";
import { Utility } from "src/modules/utility";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { UpdateSelectedBooking } from "../../../../store/public-web/public-web-actions";
import * as _ from "lodash";
import * as PBSelector from '../../../../store/public-web/public-web-selectors';
import { Contact } from "src/modules/models/client/contact";
import { TranslateService } from "@ngx-translate/core";
import { Booking } from "src/modules/models/booking/booking";
import { BookingPackages } from "src/modules/models/booking/booking-packages";
import { Client } from "src/modules/models/client/client";
import { PackageDate } from "src/modules/models/public-web/PackageSession";

@Component({
  selector: "opt-attendee-add-modal",
  templateUrl: "./attendee-add-modal.component.html",
})
export class AttendeeAddModalComponent
  extends BaseComponent
  implements OnInit {
  readonly MOBILE_V_PATTER = /^(\+?\d+)\d*$/;

  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) addressInputField: MatAutocompleteTrigger;

  IS_HOST_ATTENDEE = false;
  isChangeINTOHost = false;
  attendee: Attendee;
  previousattendee = new Attendee();
  selectedpreviousattendee = new FormControl()
  host: Host;
  attendeeCaptureProfile: AttendeeCaptureProfile;
  communicationTypeValidation: CommunicationTypeValidation[] = [];
  titleOptions: Title[];
  questionToPatch: BookingQuestion[] = [];
  isEditHost = false;
  bookingId: string;
  placholderSampleNumber: string;
  attendeeForm: FormGroup;
  bookingQuestionnaire: any;
  isQuestionsAvailable = false;
  answeredQuestionIds = [];
  questionsList: any;
  answeredQuestionCount: any;
  mandatoryQuestionsAreNotAnswer: boolean;
  isMandatory: boolean;
  currentTime: any;
  questionCount: number;
  canShowErrorValidation = false;
  canEdit:boolean = true;
  response = {
    isQuestionUpdate: false,
    isAttendeeAdded: false,
  };
  bookingPackage: BookingPackages;
  contact: Contact;
  packageDate: PackageDate;
  filteredAttendeeOptions: Observable<Attendee[]>;
  previousAttendees: Attendee[];
  selectedPreviousAttendee: Attendee;
  patchPreviewId: string;
  mobileValidationPattern: RegExp;
  isAlreadyEdited: boolean = false;
  isNewlyHost: boolean = false;
  remainingGuestsCount: number = 0;
  constructor(
    public dialogRef: MatDialogRef<AttendeeAddModalComponent>,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private bookingService: PublicBookingService,
    private translate: TranslateService,
  ) {
    super();
    dialogRef.disableClose = true;
    this.mandatoryQuestionsAreNotAnswer = false;
    this.questionsList = [];
    this.bookingPackage = data.bookingPackage;
    this.contact = data.contact;
    this.packageDate = data.packageDate;
    this.bookingId = data.bookingId;
    this.canEdit = data.canEdit;
    this.isAlreadyEdited = data.isAlreadyEdited;
    this.isNewlyHost = data.isNewlyHost ? data.isNewlyHost : false;
    this.remainingGuestsCount = data.remainingGuestsCount ? data.remainingGuestsCount : 0;
    this.previousAttendees = !!data.previousAttendees ? data.previousAttendees : [];
    this.attendeeCaptureProfile = data.attendeeCaptureProfile;
    this.bookingQuestionnaire = _.cloneDeep(data.bookingQuestionnaire);
    this.IS_HOST_ATTENDEE = true;
    this.isQuestionsAvailable = this.bookingQuestionnaire?.length>0 ? true : false;
    this.attendeeForm = this.toFormGroup(
      this.reArrangeFields(this.attendeeCaptureProfile.attendeeCaptureProfileFields),
      this.attendee,
      undefined
    );
    if (data.attendee != null) {
        this.contact = data;
        var attendee=new Attendee();
        attendee.firstName= this.getAttendeeName(data.attendee.firstName,data.attendee, data.host)
        attendee.lastName= this.getAttendeeName(data.attendee.lastName,data.attendee, data.host);
        attendee.displayName= data.attendee.displayName;
        attendee.specialInstruction=data.attendee.specialInstruction;
        attendee.companyName=data.attendee.companyName;

        var title=new Title();
        if(data.attendee.titleID)
        title.id=data.attendee.titleID.toString();
        title.name=data.attendee.titleName;
        attendee.title=title;
        if(!this.attendee.hostGuest)
          attendee.attendeeCommunicationMethods=this.attendee.attendeeCommunicationMethods;
        else
          attendee.attendeeCommunicationMethods=this.host.communicationMethods;
          attendee.cateringNotes = data.host.cateringNotes;
          attendee.attendees = data.host.attendees;
        this.setAttendeeDetails(attendee);
    }
  }
  changeToHost() {
    this.bookingService
      .ChangeGuestToHost(this.host.booking.id, this.attendee.id)
      .safeSubscribe(this, (host: Host) => {
        this.host = host;
        if (host.guests.length > 0) {
          this.attendee = host.guests[0];
          this.IS_HOST_ATTENDEE =
            this.attendee.hostGuest
        } else {
          this.IS_HOST_ATTENDEE = true;
        }
        this.dialogRef.close(this.response)
      });
  }
  get attendeeType() {
    return this.attendeeCaptureProfile.captureGuest &&
      this.attendeeCaptureProfile.captureHost
      ? this.attendee.hostGuest || this.isChangeINTOHost
        ? "Host"
        : "Guest"
      : this.attendeeCaptureProfile.captureGuest
        ? "Attendee"
        : this.attendeeCaptureProfile.captureHost
          ? "Host"
          : "Unknown";
  }

  getTranslate(data: any) {
    var translatedData = data.replace(/[^A-Za-z]/g, '');
    if (translatedData === 'Guest' || translatedData === 'Host') {
      this.translate.get("ATTENDEEINFO." + translatedData).safeSubscribe(this, (res: string) => {
        data = data.replace(translatedData, res);
      });
    }
    return data;
  }

  ngOnInit() {
    this.commonMethod();
    this.communicationTypeValidation = environment.CommunicationTypeValidation;
    this.customerService.getClientTiltles().safeSubscribe(this, (titles) => {
      this.titleOptions = titles.filter(t => t.name != "" && t.name != ".");
      this.attendeeCaptureProfile.attendeeCaptureProfileFields =
        this.mapACPField(
          this.attendeeCaptureProfile.attendeeCaptureProfileFields
        );
    });

    this.store.select(PBSelector.selectPatchPreviewId).safeSubscribe(this, (d) => {
      this.patchPreviewId = d;
    });
    if (this.isAttendeeOptionEnable) {
      // this.filteredAttendeeOptions = this.attendeeForm
      //   .get("txtFirstName")
      //   .valueChanges.pipe(
      //     startWith(""),
      //     map((value) => this._filter(value))
      //   );
    }
  }
  private _filter(value: string): Attendee[] {
    if(value && value !='') {
      const filterValue = value.toLowerCase();
      const filterAttendees = this.previousAttendees.filter(
        (option) => option.firstName.toLowerCase().indexOf(filterValue) === 0
      );
      if (
        filterAttendees.length === 1 &&
        filterAttendees[0].firstName.toLowerCase() === filterValue.toLowerCase()
      ) {
        this.setAttendeeDetails(filterAttendees[0]);
      }
      this.lodingAttendeeSearch = false;
      return filterAttendees;
    }else{
      this.lodingAttendeeSearch = true;
    }
  }

  get PanelState() {
    let element = document.getElementById("no-scroll-division");
    if(this.addressInputField && element){
     // set overflow hidden
     this.addressInputField.panelOpen ? element.style.setProperty('overflow', 'hidden', 'important') : element.style.setProperty('overflow', 'auto', 'important');
   }
    return true
  }
  previousattendeengmodelchange(event: any) {
    this.filteredAttendeeOptions = of(this._filter(event));
  }

  setAttendeeDetails(attendee: Attendee) {
    this.attendeeForm.patchValue({
      txtFirstName: "",
      txtMobile: "",
      txtEmail: "",
      txtCompany: "",
      txtDisplayname: "",
      txtLastName: "",
      cmbTitle: "",
      txtCateringNote: "",
      txtCovers: ""
    });
    this.attendeeCaptureProfile.attendeeCaptureProfileFields.forEach(
      (field) => {
        switch (field.internalName) {
          case "txtMobile":
            let mobileCom: CommunicationMethod;
            if ((attendee as Attendee).attendeeCommunicationMethods) {
              mobileCom = (
                attendee as Attendee
              ).attendeeCommunicationMethods.find(
                (x) => +x.communicationTypeID == 3 || +x.communicationTypeID == 1 || x.communicationType == '3' || x.communicationType == '1'
              );
              if (mobileCom &&
                ((!Object(attendee)["firstName"].toLowerCase().includes("host") &&
                !Object(attendee)["firstName"].toLowerCase().includes("guest")) || this.isAlreadyEdited)) {
                this.attendeeForm.patchValue({
                  txtMobile: mobileCom.value,
                });
              }
            }
            break;
          case "txtEmail":
            let mailCom: CommunicationMethod;
            if (
             // this.canAddAttendeeCommunication &&
              (attendee as Attendee).attendeeCommunicationMethods
            ) {
              mailCom = (
                attendee as Attendee
              ).attendeeCommunicationMethods.find(
                (x) => +x.communicationTypeID === 5 || +x.communicationTypeID === 6 || x.communicationType === '5' || x.communicationType === '6'
              );
              if (mailCom && 
                  ((!Object(attendee)["firstName"].toLowerCase().includes("host") &&
                  !Object(attendee)["firstName"].toLowerCase().includes("guest")) || this.isAlreadyEdited)) {
                this.attendeeForm.patchValue({
                  txtEmail: mailCom.value,
                });
              }
            }
            break;
          case "txtCompany":
            if (attendee.companyName) {
              this.attendeeForm.patchValue({
                txtCompany: this.getTranslate(attendee.companyName),
              });
            }
            break;
          case "txtDisplayname":
            if (attendee.displayName) {
              this.attendeeForm.patchValue({
                txtDisplayname:this.getTranslate(attendee.displayName),
              });
            }
            break;
          case "txtFirstName":
            if (attendee.firstName) {
              this.attendeeForm.patchValue({

                txtFirstName: this.getTranslate(attendee.firstName),

              });
            }
            break;
          case "txtLastName":
            if (attendee.lastName) {
              this.attendeeForm.patchValue({
                txtLastName: this.getTranslate(attendee.lastName),
              });
            }
            break;
          case "txtCateringNote":
            if (attendee.cateringNotes) {
              this.attendeeForm.patchValue({
                txtCateringNote: attendee.cateringNotes,
              });
            }
            break;
          case "txtSpecialInstruction":
            if (attendee.specialInstruction) {
              this.attendeeForm.patchValue({
                txtSpecialInstruction: attendee.specialInstruction,
              });
            }
            break;
          case "cmbTitle":
            if (attendee.title && attendee.title.id) {
              this.attendeeForm.patchValue({
                cmbTitle: this.getTranslate(attendee.title.id),
              });
            }
            break;
          case "txtCovers":
              if (attendee.attendees) {
                this.attendeeForm.patchValue({
                  txtCovers: this.remainingGuestsCount.toString(),
                });
              }
              break;
          // case 'txtEmail':
          //   break;
        }
      }
    );
  }

  isAttendeeOptionEnable(key) {
    return (
      key === "txtFirstName" &&
      !!this.previousAttendees &&
      this.previousAttendees.length > 0
    );
  }

  get displayChangeToHostBtn() {
    return (
      this.attendeeCaptureProfile.captureGuest &&
      this.attendeeCaptureProfile.captureHost &&
      !this.IS_HOST_ATTENDEE
    );
  }

  toFormGroup(
    acpf: AttendeeCaptureProfileField[],
    attendee?: Attendee,
    host?: Host
  ) {
    const group: any = {};
    if(acpf){
      acpf.forEach((field) => {
        group[field.internalName] = new FormControl(
          this.getAttribute(
            !!host ? host : attendee,
            field.internalName,
            this.IS_HOST_ATTENDEE,
            attendee
          ),
          this.getFormValidators(field)
        );
      });
    }
    return new FormGroup(group);
  }
  commonMethod() {
    this.currentTime = new Date();
    this.mandatoryQuestionsAreNotAnswer = false;
    //Question Count
    if (
      this.bookingQuestionnaire &&
      this.bookingQuestionnaire.bookingQuestions
    ) {
      var itemQuestionCount = 0;
      this.bookingQuestionnaire.bookingQuestions.forEach((q, i) => {
        itemQuestionCount++;
      });
      this.questionCount = itemQuestionCount;
    }
  }
  mapACPField(acpf: AttendeeCaptureProfileField[]) {
    acpf.forEach((field) => {
      switch (field.fieldType) {
        case "ComboBox":
          if (
            field.internalName === "cmbTitle" &&
            this.titleOptions.length > 0
          ) {
            field.selectionOptions = this.titleOptions;
          }
          break;
      }
    });
    return acpf;
  }
  
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  getFormValidators(field: AttendeeCaptureProfileField): any[] {
    let validators = [];
    const SPACE_VALIDATION_PATTERN = "/^(s+S+s*)*(?!s).*$/";
    if (field.mandatory) {
      validators = [...validators, Validators.required];
    }
    if (field.maxLength) {
      validators = [...validators, Validators.maxLength(field.maxLength)];
    }
    switch (field.internalName) {
      // case 'txtMobile':
      //   // , Validators.pattern(this.MOBILE_V_PATTER)
      //   validators = [...validators]
      //   break;
      case "txtEmail":
        validators = [...validators, Validators.email];
        break;

      case "txtCompany":
        if (field.mandatory) {
          validators = [...validators, this.noWhitespaceValidator];
        }
        break;
      case "txtFirstName":
        if (field.mandatory) {
          validators = [...validators, this.noWhitespaceValidator];
        }
        break;
      case "txtLastName":
        if (field.mandatory) {
          validators = [...validators, this.noWhitespaceValidator];
        }
        break;
      case "txtMobile":
        this.mobileValidationPattern = this.mobileNumberValidation();
        if (this.placholderSampleNumber) {
          field.displayName = this.placholderSampleNumber;
        }
        if (!this.mobileValidationPattern) {
          this.mobileValidationPattern = this.MOBILE_V_PATTER;
        }
        if (field.mandatory) {
          validators = [...validators,Validators.pattern(this.mobileValidationPattern)];
        }
        break;
      case "txtEmail":
        if (field.mandatory) {
          validators = [...validators, this.noWhitespaceValidator];
        }
        break;
      case "txtCovers":
          if (field.mandatory) {
            validators = [...validators, this.noWhitespaceValidator,Validators.max(this.remainingGuestsCount),Validators.min(1),Validators.pattern("^[0-9]*$")];
          }
          break;
      default:
        // if (field.isMandatory) {
        //   validators = [...validators, this.noWhitespaceValidator]
        // }
        break;
    }
    return validators;
  }
  getAttribute(attendee: any, key, isHost = true, attendeeCom): string {
    let value = "";
    if (
      (isHost && Object(attendee)["namedHost"]) ||
      (!isHost && Object(attendee)["namedHostGuest"])
    ) {
      switch (key) {
        case "cmbTitle":
          if (isHost) {
            value = Object(attendee)["titleId"];
          } else {
            value = Object(attendee)["titleID"];
          }
          if (!value) value = "";
          break;
        case "txtCompany":
          value = Object(attendee)["companyName"];
          break;
        case "txtSpecialInstruction":
          // if (isHost) {
          //   value = Object(attendeeCom)["specialInstruction"]
          // } else {
          value = Object(attendee)["specialInstruction"];
          // }
          break;
        case "txtCateringNote":
          value = Object(attendee)["cateringNotes"];
          break;
        case "txtCovers":
            value = Object(attendee)["attendees"];
            break;
        case "txtDisplayname":
          // if (isHost) {
          //   value = Object(attendeeCom)["displayName"]
          // } else {
          value = Object(attendee)["displayName"];
          // }
          break;
        case "txtFirstName":
          if (
            !!Object(attendee)["firstName"] &&
            !Object(attendee)["firstName"].toLowerCase().includes("host") &&
            !Object(attendee)["firstName"].toLowerCase().includes("guest")
          ) {
            value = Object(attendee)["firstName"];
          }
          break;
        case "txtLastName":
          if (
            !!Object(attendee)["lastName"] &&
            !Object(attendee)["lastName"].toLowerCase().includes("host") &&
            !Object(attendee)["firstName"].toLowerCase().includes("guest")
          ) {
            value = Object(attendee)["lastName"];
          }
          break;
        case "txtMobile":
          let mobileCom: CommunicationMethod;
          if (
            this.canAddAttendeeCommunication &&
            (attendee as Attendee).attendeeCommunicationMethods
          ) {
            mobileCom = (
              attendee as Attendee
            ).attendeeCommunicationMethods.find(
              (x) => +x.communicationTypeID == 3 || +x.communicationTypeID == 1
            );
          } else if (!this.canAddAttendeeCommunication) {
            if (this.host && (this.host as Host).communicationMethods)
              mobileCom = (this.host as Host).communicationMethods.find(
                (x) =>
                  +x.communicationTypeID == 3 || +x.communicationTypeID == 1
              );
          }
          if (mobileCom &&
            ((!Object(attendee)["firstName"].toLowerCase().includes("host") &&
            !Object(attendee)["firstName"].toLowerCase().includes("guest")) || this.isAlreadyEdited)) {
            value = mobileCom.value;
          }
          break;
        case "txtEmail":
          let mailCom: CommunicationMethod;
          if (
            this.canAddAttendeeCommunication &&
            (attendee as Attendee).attendeeCommunicationMethods
          ) {
            mailCom = (attendee as Attendee).attendeeCommunicationMethods.find(
              (x) =>
                +x.communicationTypeID === 5 || +x.communicationTypeID === 6
            );
          } else if (!this.canAddAttendeeCommunication) {
            if (this.host && (this.host as Host).communicationMethods)
              mailCom = (this.host as Host).communicationMethods.find(
                (x) =>
                  +x.communicationTypeID === 5 || +x.communicationTypeID === 6
              );
          }
          if (mailCom && 
              ((!Object(attendee)["firstName"].toLowerCase().includes("host") &&
              !Object(attendee)["firstName"].toLowerCase().includes("guest")) || this.isAlreadyEdited)) {
            value = mailCom.value;
          }
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case "cmbTitle":
          value = "";
          break;
        case "txtCovers":
            value = this.remainingGuestsCount.toString();
            break;
      }
    }
    return value;
  }

  // check whether we can add communication details from Guest data
  // if it return false Host communication details will be assign
  get canAddAttendeeCommunication() {
    return this.attendeeCaptureProfile.captureHost &&
      this.attendeeCaptureProfile.captureGuest // capture host and guest Details
      ? !this.attendee.hostGuest
      : this.attendeeCaptureProfile.captureHost // capture host only
        ? false
        : this.attendeeCaptureProfile.captureGuest // capture guest only
          ? !this.attendee.hostGuest
          : true;
  }
  isFormControlsInValid(key) {
    return (
      this.attendeeForm.controls[key].invalid &&
      this.attendeeForm.controls[key].touched
    );
  }
  formControllerError(key) {
    return this.attendeeForm.controls[key].errors;
  }
  lodingAttendeeSearch = false;
  previousAttendee: any;
  previousAttendeeSearch(e){
    this.lodingAttendeeSearch = false;
  }
  onSelectionChange() {
    console.log(this.previousAttendee);

  }
  getPlaceHolder(){
    return "Search Previous Attendee";
  }
  addErrorValidation(key) {
    if (
      this.attendeeForm.controls[key].invalid &&
      this.attendeeForm.controls[key].touched &&
      this.canShowErrorValidation
    ) {
      return "has-error";
    }
  }
  checkMandatoryTextFieldValid(value: string) {
    const trimValue = value.trim();
    return trimValue.length > 0;
  }
  onSubmit() {
    this.isEditHost = true;
    this.mandatoryQuestionsAreNotAnswer = false;
    if (this.attendeeForm.valid) {
      const attendeeForm = this.attendeeForm;
      const newAttendee = new Attendee();
      const newHost = new Host();
      newAttendee.attendeeCommunicationMethods = [];
      newHost.communicationMethods = [];
      //newAttendee.id = "-1";
      //newHost.id = "-1";
      newAttendee.namedHostGuest = true;
      newHost.namedHost = true;
      if (this.bookingPackage) {
        const bookingPackage = new BookingPackages();
        bookingPackage.id = this.bookingPackage.id
        newHost.bookingPackage = bookingPackage;
      }
      if (this.contact) {
        const contact = new Contact();
        contact.id = this.contact.id;
        newHost.contact = contact;

        if (this.contact.client) {
          const client = new Client();
          client.id = this.contact.client.id;
          newHost.client = client;
        }
      }
      if (this.bookingId) {
        const booking = new Booking();
        booking.id = this.bookingId;
        newHost.booking = booking;
      }
      if (this.packageDate) {
        const packageDate = new PackageDate();
        packageDate.id = this.packageDate.id;
        newHost.packageDate = packageDate;
      }
      newHost.bookingPackage 
      this.attendeeCaptureProfile.attendeeCaptureProfileFields.forEach(
        (field) => {
          switch (field.internalName) {
            case "cmbTitle":
              newAttendee.titleID = attendeeForm.controls["cmbTitle"].value;
              newHost.titleId = attendeeForm.controls["cmbTitle"].value;
              break;
            case "txtSpecialInstruction":
              newAttendee.specialInstruction =
                attendeeForm.controls["txtSpecialInstruction"].value;
              newHost.specialInstruction =
                attendeeForm.controls["txtSpecialInstruction"].value;
              break;
            case "txtCateringNote":
              newAttendee.cateringNotes =
                attendeeForm.controls["txtCateringNote"].value;
              newHost.cateringNotes =
                attendeeForm.controls["txtCateringNote"].value;
              break;
            case "txtCovers":
              let aaa = attendeeForm.controls["txtCovers"].value;
                newAttendee.attendees =
                  +attendeeForm.controls["txtCovers"].value;
                newHost.attendees =
                  +attendeeForm.controls["txtCovers"].value;
                break;
            case "txtCompany":
              newAttendee.companyName =
                attendeeForm.controls["txtCompany"].value;
              newHost.companyName = attendeeForm.controls["txtCompany"].value;
              break;
            case "txtDisplayname":
              newAttendee.displayName =
                attendeeForm.controls["txtDisplayname"].value;
              newHost.displayName =
                attendeeForm.controls["txtDisplayname"].value;
              break;
            case "txtFirstName":
              newAttendee.firstName =
                attendeeForm.controls["txtFirstName"].value;
              newHost.firstName = attendeeForm.controls["txtFirstName"].value;
              break;
            case "txtLastName":
              newAttendee.lastName = attendeeForm.controls["txtLastName"].value;
              newHost.lastName = attendeeForm.controls["txtLastName"].value;
              break;
            case "txtEmail":
              let Email: CommunicationMethod;
              if (
                this.canAddAttendeeCommunication &&
                (this.attendee as Attendee).attendeeCommunicationMethods
              ) {
                Email = (
                  this.attendee as Attendee
                ).attendeeCommunicationMethods.find(
                  (x) =>
                    +x.communicationTypeID === 5 || +x.communicationTypeID === 6
                );
              } else if (!this.canAddAttendeeCommunication) {
                if (this.host && (this.host as Host).communicationMethods)
                  Email = (this.host as Host).communicationMethods.find(
                    (x) =>
                      +x.communicationTypeID === 5 ||
                      +x.communicationTypeID === 6
                  );
              }
              if (!Email) {
                Email = new CommunicationMethod();
                Email.communicationTypeID = "6"; // For Personal Email type
                Email.id = `-${newAttendee.attendeeCommunicationMethods.length + 1
                  }`;
              }
              Email.value = attendeeForm.controls["txtEmail"].value;
              if (Email.value)
                newAttendee.attendeeCommunicationMethods.push(Email);
              newHost.communicationMethods.push(Email);
              break;
            case "txtMobile":
              let mobileCom: CommunicationMethod;
              if (
                this.canAddAttendeeCommunication &&
                (this.attendee as Attendee).attendeeCommunicationMethods
              ) {
                mobileCom = (
                  this.attendee as Attendee
                ).attendeeCommunicationMethods.find(
                  (x) => +x.communicationTypeID == 3
                );
              } else if (!this.canAddAttendeeCommunication) {
                if (this.host && (this.host as Host).communicationMethods)
                  mobileCom = (this.host as Host).communicationMethods.find(
                    (x) => +x.communicationTypeID == 3
                  );
              }
              if (!mobileCom) {
                mobileCom = new CommunicationMethod();
                mobileCom.communicationTypeID = "3"; // For mobile type
                mobileCom.id = `-${newAttendee.attendeeCommunicationMethods.length + 1
                  }`;
              }
              mobileCom.value = attendeeForm.controls["txtMobile"].value;
              if (mobileCom.value) {
                newAttendee.attendeeCommunicationMethods.push(mobileCom);
                newHost.communicationMethods.push(mobileCom);
              }
              break;
            default:
              break;
          }
        }
      );
      if (newAttendee.attendeeCommunicationMethods.length === 0) {
        newAttendee.attendeeCommunicationMethods = undefined;
      }
      if (newHost.communicationMethods.length === 0) {
        newHost.communicationMethods = undefined;
      }
      if (this.selectedPreviousAttendee && this.selectedPreviousAttendee.id) {
        newAttendee.primaryHostGuestId = this.selectedPreviousAttendee.id
      } else {
        newAttendee.primaryHostGuestId = "0";
      }
      const apiCalls = []

      if (this.CAN_POST_HOST) {
        if (
          (this.attendeeCaptureProfile.captureHost || (!this.attendeeCaptureProfile.captureHost && this.attendeeCaptureProfile.captureGuest)) &&
          this.IS_HOST_ATTENDEE
        ) {
          newAttendee.attendeeCommunicationMethods = undefined;
          //newHost.guests = [newAttendee];
        }
        apiCalls.push(this.bookingService
          .BookingHostPost(this.bookingId, newHost, this.patchPreviewId ? this.patchPreviewId : ''))
      } else {
        // apiCalls.push(this.bookingService
        //   .BookingAttendeePatch(this.bookingId, this.attendee.id, newAttendee, this.patchPreviewId ? this.patchPreviewId : ''))
      }
      if (apiCalls.length > 0) {
        forkJoin(apiCalls).safeSubscribe(this, (res) => {
          this.response.isAttendeeAdded = true;
          this.dialogRef.close(this.response);
        });
      } else {
        this.dialogRef.close(this.response);
      }
    } else {
      this.canShowErrorValidation = true;
    }
  }
  attendeeNameSelected(e: MatAutocompleteSelectedEvent) {
    this.selectedPreviousAttendee = this.previousAttendees.find(
      (x) => x.firstName === e.option.value
    );
    if (this.selectedPreviousAttendee) {
      this.setAttendeeDetails(this.selectedPreviousAttendee);
    }
  }
  get CAN_POST_HOST() {
    let res = false;
    if (
      this.attendeeCaptureProfile.captureGuest &&
      this.attendeeCaptureProfile.captureHost &&
      this.IS_HOST_ATTENDEE
    ) {
      res = true;
    } else if (
      !this.attendeeCaptureProfile.captureGuest &&
      this.attendeeCaptureProfile.captureHost &&
      this.IS_HOST_ATTENDEE
    ) {
      res = true;
    } else if (this.attendeeCaptureProfile.captureGuest && this.IS_HOST_ATTENDEE) {
      res = true;
    }
    return res;
  }
  mobileNumberValidation() {
    var pattern: RegExp;
    var sampleValueArray = [];
    this.communicationTypeValidation = environment.CommunicationTypeValidation;

    if (this.communicationTypeValidation.length > 0) {
      this.communicationTypeValidation.forEach((ctv) => {
        if (
          ctv.Rule != undefined &&
          ctv.Rule.length > 0 &&
          ctv.CommunicationTypeId != undefined &&
          ctv.CommunicationTypeId != ""
        ) {
          var ids = ctv.CommunicationTypeId.split(",");
          if (
            ids != undefined &&
            ids.length > 0 &&
            (ids.indexOf("3") > -1) //"3" means if xml configuration have personal phone number validation pattern then use that.
          ) {
            ctv.Rule.forEach((rule) => {
              if (environment.DefaultCountryId == rule.countryId) {
                pattern = new RegExp(rule.Value);
                if (rule.SampleNumber != "") {
                  sampleValueArray.push(rule.SampleNumber);
                }
              }
            });
            if (pattern == undefined || pattern == null) {
              var selectedRule = ctv.Rule.find((a) => a.countryId == "");
              pattern = new RegExp(selectedRule.Value);
              if (selectedRule.SampleNumber != "") {
                sampleValueArray.push(selectedRule.SampleNumber);
              }
            }
          }
        }
      });
      this.placholderSampleNumber = sampleValueArray[0];
    }
    console.log("pattern ", pattern);

    return pattern;
  }
  setCommunicationMethod(
    comm: CommunicationMethod,
    commtype: CommunicationType
  ) {
    comm.communicationTypeID = commtype.id;
    comm.communicationType = commtype.name;
    comm.pattern = this.mobileNumberValidation();
  }

  getAttendeeName(name: string, guest: Attendee, host: Host) {
    //find host of guest from guests
    if (name != "" && !!host.bookingPackage && !!this.attendeeCaptureProfile) {
      const attendeeCaptureProfile: AttendeeCaptureProfile = this.attendeeCaptureProfile;
      if (!guest.namedHostGuest || !name || (name && name.toLowerCase().includes("guest")) || (name && name.toLowerCase().includes("host"))) {
        const guestIndex = host.guests.findIndex(x => x.id === guest.id)
        if (attendeeCaptureProfile.captureGuest && !attendeeCaptureProfile.captureHost) {
          if (((name && name.toLowerCase().includes("guest")) || (name && name.toLowerCase().includes("host")))) {
            name = "Attendee " + (guestIndex + 1)
          }
        }
        if (!attendeeCaptureProfile.captureGuest && attendeeCaptureProfile.captureHost) {
          name = "Host " + (guestIndex + 1)
        }
        if (attendeeCaptureProfile.captureGuest && attendeeCaptureProfile.captureHost && !name) {
          if (guest.hostGuest) {
            name = "Host " + (guestIndex + 1)
          } else {
            name = "Guest " + (guestIndex)
          }
        }
      }
    }
    return name
  }

  reArrangeFields(fields : AttendeeCaptureProfileField[]) {
    let coverFieldIndex = fields.findIndex(f => f.internalName === "txtCovers");
    if (coverFieldIndex != -1) {
      let coverField = _.cloneDeep(fields[coverFieldIndex]);

      fields.splice(coverFieldIndex, 1);
      fields.unshift(coverField);
    }
    return fields;
  }
}
